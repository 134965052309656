import { serverAxios } from '../api';

export interface IUpdateNativeContainerFileRequest {
  taskTeamId: number;
  fileContainerRevisionId: number;
  containerFileId: number;
}

export async function updateNativeContainerFile(
  request: IUpdateNativeContainerFileRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.post('api/cms/updatenativecontainerfile', request, {
    signal: abortSignal,
  });
  return result.data;
}
