import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import Icon from '../shared/Icon';
import { Accordion, AccordionPanel, Button, Checkbox } from '@aurecon-creative-technologies/styleguide';
import TeamChartStore from './TeamChartStore';
import { ITaskTeamUserTeamChart } from './interface/TeamChart.interface';
import TaskTeamUser from './TaskTeamUser';
import { useTeamChartContext } from './TeamChartStoreContext';
import classNames from 'classnames';

const TaskTeamAccordion: FC = () => {
  const context = useTeamChartContext();
  const renderGroupControls = (taskTeam: ITaskTeamUserTeamChart) => {
    return (
      <>
        {context.showAnyoneInTaskTeam && (
          <>
            <div className={`${Style.groupSelect} ${Style.taskTeamFilterGroup}`}>
              <div className={Style.label}>Filter</div>
              <Checkbox
                cssClass={classNames({
                  [Style.disabled]: context.disableTaskTeam,
                })}
                checked={taskTeam.isFilterApprovers}
                onChange={() =>
                  !context.disableTaskTeam &&
                  TeamChartStore.setFilterApprovers(taskTeam.deliveryTeamId, taskTeam.taskTeamId)
                }
                label="Task Team Approvers"
              />
            </div>
            <div
              className={classNames(Style.groupSelect, {
                [Style.active]: TeamChartStore.activeTTAllApproversIds.has(taskTeam.taskTeamId),
              })}>
              <Icon name="people" className={Style.listIcon}></Icon>
              <Button
                type="text"
                label="All Task Team Approvers"
                cssClass={classNames({
                  [Style.disabled]: context.disableTaskTeam,
                })}
                {...(!context.disableTaskTeam && {
                  onClick: () => {
                    TeamChartStore.selectAllTaskTeamApprovers(taskTeam.deliveryTeamId, taskTeam.taskTeamId);
                  },
                })}
              />
            </div>
            <div
              className={classNames(Style.groupSelect, {
                [Style.active]: TeamChartStore.activeTTAllMembersIds.has(taskTeam.taskTeamId),
              })}>
              {' '}
              <Icon name="people" className={Style.listIcon}></Icon>
              <Button
                type="text"
                label="All Task Team Members"
                cssClass={classNames({
                  [Style.disabled]: context.disableTaskTeam,
                })}
                {...(!context.disableTaskTeam && {
                  onClick: () => {
                    TeamChartStore.selectAllTaskTeamMembers(taskTeam.deliveryTeamId, taskTeam.taskTeamId);
                    TeamChartStore.selectAllTaskTeamApprovers(taskTeam.deliveryTeamId, taskTeam.taskTeamId);
                  },
                })}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!!TeamChartStore.filteredProjectTaskTeamUsers.length && (
        <Accordion
          activePanelIds={Array.from(TeamChartStore.activeTTUserPanelIds)}
          onPanelToggle={(id) => TeamChartStore.onTTUserPanelToggle(id)}
          headingBgColour="#FAFAFA"
          panelBgColour="#FAFAFA"
          headingFontColour="#000000"
          cssClass={Style.taskTeamAccordion}>
          {TeamChartStore.filteredProjectTaskTeamUsers.map((taskTeam: ITaskTeamUserTeamChart) => (
            <AccordionPanel
              key={`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`}
              panelId={`${taskTeam.deliveryTeamId}-${taskTeam.taskTeamId}`}
              label={`${taskTeam.taskTeamTitle} (${taskTeam.taskTeamCode})`}>
              <>
                {renderGroupControls(taskTeam)}
                <TaskTeamUser taskTeamUser={taskTeam}></TaskTeamUser>
              </>
            </AccordionPanel>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default observer(TaskTeamAccordion);
