import { serverAxios } from '../api';

export interface ITaskUser {
  id: number;
  name: string;
  email: string;
}

export async function getReassignUsers(taskId: number, abortSignal?: AbortSignal): Promise<ITaskUser[]> {
  const result = await serverAxios.get<{ users: ITaskUser[] }>('api/task/reassignusers', {
    params: { taskId },
    signal: abortSignal,
  });
  return result.data.users;
}
