import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import unregister from './registerServiceWorker';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin, initAppInsights } from './utils/appInsights';
import AppStore from './stores/AppStore';
import { initAuthClient } from './authentication/instances/authClientInstance';
import { AuthProvider } from './authentication/components/AuthProvider';
import { ThemeProvider } from '@aurecon-creative-technologies/styleguide';

const container = document.getElementById('root');

//Create a root.
const root = ReactDOM.createRoot(container!);

AppStore.loadSettings().then((settings) => {
  initAuthClient(
    settings.appClientId,
    settings.appApiScope,
    settings.appAudience,
    settings.appAuthority,
    settings.appUseAuth0 ? 'auth0' : 'msal'
  )
    .then(() => {
      initAppInsights(settings.appInsightsKey);
      const script = document.createElement('script');
      script.src = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';
      script.async = true;
      document.body.appendChild(script);

      root.render(
        <React.StrictMode>
          <AppInsightsContext.Provider value={reactPlugin}>
            <AuthProvider type={settings.appUseAuth0 ? 'auth0' : 'msal'}>
              <ThemeProvider>
                <App />
              </ThemeProvider>
            </AuthProvider>
          </AppInsightsContext.Provider>
        </React.StrictMode>
      );
      unregister();
    })
    .catch((err) => {
      console.error(err);
      root.render(
        <div>
          <h3>Error loading application configuration</h3>
          <div>{JSON.stringify(err)}</div>
        </div>
      );
    });
});
