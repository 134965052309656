import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import HistoryDate from './HistoryDate';
import Style from './styles/HistoryNativeContainerFile.module.scss';
import { INativeContainerFileHistory } from '../../../api/authenticated/cms/getFileHistory';

export interface IHistoryNativeContainerFileProps {
  item: INativeContainerFileHistory;
}

const HistoryNativeContainerFile: FC<IHistoryNativeContainerFileProps> = ({ item }) => {
  return (
    <li className={Style.item}>
      <HistoryDate dateTime={item.updatedDate} />
      <div className={Style.title}>
        <div className={Style.currentNativeFileName}>{item.currentNativeFilename}</div>
        <div className={Style.nativeMessage}>
          <Icon name="edit" className={Style.icon} />
          {item.previousNativeFilename && <p className={Style.message}>Native file changed from</p>}
          {!item.previousNativeFilename && <p className={Style.message}>Native file set</p>}
        </div>

        {item.previousNativeFilename && <p className={Style.previousNativeFilename}>{item.previousNativeFilename}</p>}
      </div>
      <div className={Style.user}>{item.updatedByUserName}</div>
    </li>
  );
};

export default observer(HistoryNativeContainerFile);
