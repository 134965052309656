import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TabContent } from '@aurecon-creative-technologies/styleguide';
import { ContentSelection, FileContentTabIds, FileContainerStateNames } from './ContentSelection';
import { scrollToTop } from '../../utils/miscUtils';
import FilesStore from './FilesStore';
import TabContainer from './TabContainer';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';
import ForgeViewerModal from '../visualisation/forgeViewer/ForgeViewerModal';
import Loading from '../shared/Loading';
import ForgeViewerStore from '../visualisation/forgeViewer/ForgeViewerStore';
import { useParams } from 'react-router-dom';
import { FromModuleEnum } from '../../enums/FromModuleEnum';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import { Pages } from '../../common/constants/Pages';
import AppStore from '../../stores/AppStore';

interface OpenFromUrlParams {
  projectNumber?: string;
  taskTeamId?: number;
}

const Files: FC = () => {
  const { projectNumber, deliveryTeamCode, taskTeamCode, stateId, fileName } = useParams();
  const [selectedTabId, setSelectedTabId] = useState(FilesStore.selectedTabId);
  const [openFromUrl, setOpenFromUrl] = useState(false);
  const selectedSection = FilesStore.selectedSection;
  const selectedItem = NavBarSelectorStore.selectedItem;
  const [openFromUrlParams, setOpenFromUrlParams] = useState<OpenFromUrlParams>({});

  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights) appInsights.trackPageView({ name: AppInsightPageView.FILES, isLoggedIn: true });

    return () => {
      FilesStore.abortController?.abort();
      setOpenFromUrlParams({});
    };
  }, []);

  useEffect(() => {
    if (!projectNumber || !deliveryTeamCode || !taskTeamCode || !stateId || !fileName) {
      FilesStore.clearQueryParametersFromUrl();
      return;
    }

    setOpenFromUrl(true);
    const checkTaskTeamAvailable = FilesStore.getTaskTeamFromProjectAndTeamCode(taskTeamCode, projectNumber);
    if (!checkTaskTeamAvailable && stateId === FileContainerStateNames.WIP) {
      window.location.href = `#${Pages.Error.Route}/${401}`;
      return;
    }

    AppStore.setProjectNumber(projectNumber);
    AppStore.setDeliveryTeamCode(deliveryTeamCode);
    AppStore.setTaskTeamCode(taskTeamCode);
    AppStore.setTaskTeamId(checkTaskTeamAvailable?.id);
    NavBarSelectorStore.initSelector(false);
    FilesStore.setQueryParametersFromUrl(fileName, stateId);
    setOpenFromUrlParams({ projectNumber: projectNumber, taskTeamId: checkTaskTeamAvailable?.id });
    if (stateId) {
      const tabId = getTabIdByFileContainerState(stateId);
      if (tabId) {
        setSelectedTabId(tabId);
      }
    }
  }, [projectNumber, deliveryTeamCode, taskTeamCode, stateId, fileName]);

  useEffect(() => {
    let setTabDefault = true;
    if (openFromUrl && selectedItem?.navbarSelected) {
      setTabDefault = false;
      setOpenFromUrl(false);
    }

    FilesStore.cleanup();
    if (setTabDefault) {
      FilesStore.resetFilters();
      FilesStore.setDefaultSelectedTab();
      FilesStore.setDefaultFilter();

      setSelectedTabId(FilesStore.selectedTabId);
    }

    const effectAsync = async () => {
      if (!selectedItem?.id) return;

      if (selectedItem?.navbarSelected) FilesStore.abortController?.abort();
      if (openFromUrl) {
        FilesStore.fileContainerFilters.projectNumber = openFromUrlParams.projectNumber;
        FilesStore.fileContainerFilters.taskTeamId = openFromUrlParams.taskTeamId;
      }
      await FilesStore.loadProjectMetadata();

      FilesStore.loadTableColumns();

      await FilesStore.loadFiles();

      if (openFromUrl) {
        FilesStore.clearQueryParametersFromUrl();
        window.history.replaceState(null, '', '/#/files');
      }
    };

    effectAsync();
  }, [openFromUrl, selectedItem?.navbarSelected, selectedItem?.id, openFromUrlParams]);

  useEffect(() => {
    scrollToTop();
  }, [selectedSection]);

  const onSelectTab = async (id: number) => {
    if (projectNumber && deliveryTeamCode && taskTeamCode && stateId && fileName) {
      const tabId = getTabIdByFileContainerState(stateId);
      if (tabId && tabId !== id) {
        window.history.replaceState(null, '', '/#/files');
        FilesStore.clearQueryParametersFromUrl();
        FilesStore.loadTableColumns();
      }
    }

    FilesStore.setDefaultFilter();

    if (id === FileContentTabIds.WIP_TAB_ID) FilesStore.setSelectedSection(ContentSelection.WIP);
    if (id === FileContentTabIds.SHARED_TAB_ID) FilesStore.setSelectedSection(ContentSelection.Shared);
    if (id === FileContentTabIds.PUBLISHED_TAB_ID) FilesStore.setSelectedSection(ContentSelection.Published);

    await FilesStore.getFilesTaskTeamOriginator(FilesStore.fileContainerStateId);

    setSelectedTabId(id);
    FilesStore.setSelectedTabId(id);
  };

  const getTabIdByFileContainerState = (stateId: string) => {
    if (stateId === FileContainerStateNames.WIP) return FileContentTabIds.WIP_TAB_ID;
    if (stateId === FileContainerStateNames.SHARED) return FileContentTabIds.SHARED_TAB_ID;
    if (stateId === FileContainerStateNames.PUBLISHED) return FileContentTabIds.PUBLISHED_TAB_ID;
  };

  return (
    <>
      <PageHeading>Files</PageHeading>
      <PageTab activeTab={selectedTabId} tabs={FilesStore.tabs} onSelectTab={onSelectTab}>
        {FilesStore.tabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <TabContainer fromModule={FromModuleEnum.FILES} />
            <ForgeViewerModal
              closeModal={() => ForgeViewerStore.forgeViewerModalClose()}
              showModal={ForgeViewerStore.showForgeViewerModal}></ForgeViewerModal>
            <Loading isLoading={ForgeViewerStore.isLoading}></Loading>
          </TabContent>
        ))}
      </PageTab>
    </>
  );
};

export default observer(Files);
