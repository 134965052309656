import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import { TaskTypeText } from '../../../common/constants/TaskTypeText';
import TaskStatusIndicator from '../../shared/TaskStatusIndicator';
import { ITaskHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import HistoryDate from './HistoryDate';
import { ITaskEventGroup } from './TaskEventGroup';
import { groupBy, orderBy, maxBy } from 'lodash';
import TaskEventRejected from './TaskEventRejected';
import TaskEventApproved from './TaskEventApproved';
import TaskEventReassigned from './TaskEventReassigned';
import TaskEventCreated from './TaskEventCreated';
import Style from './styles/TaskExpanded.module.scss';
import { Pages } from '../../../common/constants/Pages';
import TaskEventAttachmentFile from './TaskEventAttachmentFile';
import TaskEventWithdrawn from './TaskEventWithdrawn';

export interface ITaskExpandedProps {
  task: ITaskHistoryItem;
}

const TaskExpanded: FC<ITaskExpandedProps> = ({ task }) => {
  // group task events by date descending
  const taskEventGroups: ITaskEventGroup[] = orderBy(
    Object.values(groupBy(task.taskEvents, (event) => event.eventDateTime.toLocaleDateString())).map((events) => {
      const sortEvent = orderBy(events, 'eventDateTime', 'desc');
      return {
        date: sortEvent[0].eventDateTime.toLocaleDateString(),
        eventDateTime: sortEvent[0].eventDateTime,
        created: events.find((event) => !!event.taskCreatedEvent)?.taskCreatedEvent,
        approved: events.filter((event) => !!event.taskApprovedEvent).map((event) => event.taskApprovedEvent!),
        rejected: events.filter((event) => !!event.taskRejectedEvent).map((event) => event.taskRejectedEvent!),
        reassigned: events.filter((event) => !!event.taskReassignedEvent).map((event) => event.taskReassignedEvent!),
        withdrawn: events.filter((event) => !!event.taskWithdrawnEvent).map((event) => event.taskWithdrawnEvent!),
        attachmentFile: maxBy(
          events.filter((event) => !!event.taskAttachmentFileEvent),
          'eventDateTime'
        )?.taskAttachmentFileEvent,
      };
    }),
    (eventGroup) => eventGroup.date,
    'desc'
  );

  return (
    <li className={Style.item}>
      <div className={Style.title}>
        <a className={Style.link} href={`#${Pages.Tasks.Route}/${task.taskId}`}>
          {TaskTypeText[task.taskTypeId]}
          <Icon name="launch" className={Style.launch} />
        </a>
        <TaskStatusIndicator taskStatusId={task.taskStatusId} displayTaskStatus={task.displayTaskStatus} />
      </div>
      <ol className={Style.events}>
        {taskEventGroups.map((eventGroup) => (
          <li key={eventGroup.eventDateTime.toISOString()}>
            <HistoryDate dateTime={eventGroup.eventDateTime} />
            <TaskEventRejected taskRejectedEvents={eventGroup.rejected} />
            <TaskEventApproved taskApprovedEvents={eventGroup.approved} />
            <TaskEventAttachmentFile taskAttachmentFileEvent={eventGroup.attachmentFile} />
            <TaskEventReassigned taskReassignedEvents={eventGroup.reassigned} />
            <TaskEventCreated taskCreatedEvent={eventGroup.created} />
            <TaskEventWithdrawn taskWithdrawnEvents={eventGroup.withdrawn} />
          </li>
        ))}
      </ol>
    </li>
  );
};

export default observer(TaskExpanded);
