import { SortTypes } from '../common/enums/SortType';

/* eslint-disable @typescript-eslint/no-explicit-any */
const sort = (array: any[], sortField: string, sortType: SortTypes): any[] => {
  if (sortField && sortType) {
    array.sort(compareValues(sortField, sortType));
  }
  return array;
};

const compareValues = (key: string, order: SortTypes): ((a: any, b: any) => number) | undefined => {
  return (a, b): number => {
    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].trim().toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].trim().toUpperCase() : b[key];

    let comparison = 0;
    if (!varA) comparison = -1;
    else if (!varB) comparison = 1;
    else if (varA > varB) comparison = 1;
    else if (varA < varB) comparison = -1;
    return order === SortTypes.DESC ? comparison * -1 : comparison;
  };
};

export { sort };
