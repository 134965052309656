import { serverAxios } from '../api';

export interface IAddTaskAttachmentFilesRequest {
  projectNumber: string;
  taskId: number;
  attachmentFiles: IAddFileRequest[];
  taskCreatedTime?: Date;
}

export interface IAddFileRequest {
  filename: string;
  totalFileSize: number;
  totalFileChunks: number;
}

export interface IAddTaskAttachmentFilesResponse {
  taskId: number;
  attachmentFiles: IAddFileResponse[];
}

export interface IAddFileResponse {
  id: number;
  filename: string;
}

export async function addTaskAttachmentFiles(
  request: IAddTaskAttachmentFilesRequest,
  abortSignal?: AbortSignal
): Promise<IAddTaskAttachmentFilesResponse> {
  const result = await serverAxios.post<IAddTaskAttachmentFilesResponse>('api/task/addAttachmentFiles', request, {
    signal: abortSignal,
  });
  return result.data;
}
