import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SecondaryButton from '../shared/SecondaryButton';
import FilesStore from './FilesStore';
import DownloadModal from '../shared/DownloadModal';
import { getFileSizeDetails } from '../../utils/miscUtils';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';
import { appInsightsTrackEvent } from '../../utils/appInsights';

const DownloadButton: FC = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(FilesStore.selectedFilesSize);

  const downloadOnClick = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_DOWNLOAD);

    if (isOverLimit) {
      setShowDownloadModal(true);
    } else {
      FilesStore.downloadSelectedFiles();
    }
  };

  return (
    <>
      <SecondaryButton disabled={FilesStore.canDownloadSelectedFiles} onClick={downloadOnClick}>
        Download
      </SecondaryButton>
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FilesStore.downloadSelectedFiles();
          setShowDownloadModal(false);
        }}
      />
    </>
  );
};

export default observer(DownloadButton);
