import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '@aurecon-creative-technologies/styleguide';
import TaskInformationStore from './TaskInformationStore';
import Style from './styles/TaskAssigneeModal.module.scss';
import UserCircle from '../shared/UserCircle';
import ModalActions from '../shared/ModalActions';
import SecondaryButton from '../shared/SecondaryButton';
import TaskUserStatusIndicator from '../shared/TaskUserStatusIndicator';

interface ITaskAssigneeModalProps {
  showing: boolean;
  onClose: () => void;
}

const TaskAssigneeModal: FC<ITaskAssigneeModalProps> = (props) => {
  const { showing, onClose } = props;
  if (TaskInformationStore.task === null) return null;

  return (
    <Modal isShowing={showing} onClose={onClose}>
      <div className={Style.assigneeModal}>
        <h1>Assigned To</h1>
        <p>Keep track of all Tasks status from approvers. (Approve, Reject, Reassign)</p>
        <div className={Style.assignBy}>
          <div className={Style.label}>Assigned By</div>
          <div className={Style.userIcon}>
            <UserCircle
              initials={TaskInformationStore.task.createdByUserInitials}
              email={TaskInformationStore.task.createdByUserEmail}
            />
            <div className={Style.fullName}>{TaskInformationStore.task.createdByUserName}</div>
          </div>
        </div>
        <div className={Style.assigneeList}>
          {TaskInformationStore.task.taskUsers.map((user) => (
            <div key={user.userId} className={Style.assignee}>
              <div className={Style.userIcon}>
                <UserCircle initials={user.initials} email={user.email} hideToolTip />
                <div className={Style.fullName}>{user.name}</div>
              </div>
              <div className={Style.status}>
                <TaskUserStatusIndicator taskUserStatusId={user.taskUserStatusId} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalActions>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      </ModalActions>
    </Modal>
  );
};

export default observer(TaskAssigneeModal);
