import { serverAxios } from '../api';

export interface IUser {
  id: number;
  name: string;
  email: string;
  nameByInitials: string;
}

export async function getDistributionListUsers(
  projectNumber: string,
  distributionListId: number,
  abortSignal?: AbortSignal
): Promise<IUser[]> {
  const result = await serverAxios.get<{ users: IUser[] }>('api/um/distributionlist/users', {
    params: { projectNumber, distributionListId },
    signal: abortSignal,
  });
  return result.data.users;
}
