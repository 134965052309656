import { EndSessionRequest, RedirectRequest, SilentRequest } from '@azure/msal-browser';

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile'],
  prompt: 'select_account',
};

export const tokenRequest: SilentRequest = {
  scopes: [],
};

export const logoutRequest: EndSessionRequest = {
  postLogoutRedirectUri: '/',
};
