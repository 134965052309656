import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow, TableCell, IHeader, Dropdown } from '@aurecon-creative-technologies/styleguide';
import SystemUsersStore from '../../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import { ITableColumn } from './TableColumn';
import ErrorModal from '../../../../shared/ErrorModal';
import { ISystemUser, IExportUserCSV } from '../../../../../api/authenticated/um/interfaces/user.interface';
import { DropdownStyle } from '../../../../../common/constants/Dropdown';
import SecondaryButton from '../../../../shared/SecondaryButton';
import Icon from '../../../../shared/Icon';
import Style from '../../../../../styles/components/settings/systemAdminSettings/systemUsers/SystemUserTable.module.scss';
import { dateTime12HFormat } from '../../../../../utils/dateUtils';
import { SystemUsersTableHeaderKeyValueFields } from '../../../../../common/enums/SystemUserTableHeader';
import DropDownMenuForTableCell from '../../../../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../../../../shared/DropDownMenuButton';
import { CSVLink } from 'react-csv';
import { ICSVData } from '../../../../../common/interfaces/csvData';
import { getUserTeamsExport } from '../../../../../api/authenticated/um/getUserTeamsExport';

export interface ISystemUserTableRowProps {
  headers: IHeader[];
  user: ISystemUser;
  tableColumns: ITableColumn[];
  onDeactiveOrActiveUser: () => void;
  onRemovingUser: () => void;
  totalRows?: number;
  rowIndex?: number;
}

const maxItemsInPage = 6;

const SystemUserTableRow: FC<ISystemUserTableRowProps> = (props) => {
  const { headers, user, tableColumns, onDeactiveOrActiveUser, onRemovingUser, totalRows, rowIndex } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [exportUserData, setExportUserData] = useState<ICSVData<IExportUserCSV> | null>(null);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const cellValue = (h: IHeader) => {
    const col = tableColumns.find((c) => c.label === h.label);
    if (!col) return '';

    return user[col.valueField];
  };
  const fetchExportData = async () => {
    if (!user.id) return;
    setLoading(true);
    setClicked(false);
    const mappingItems = await getUserTeamsExport(user.id);
    const data = SystemUsersStore.mappingCSVUserTeamData(mappingItems, user.id);
    if (data) setExportUserData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (exportUserData && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, exportUserData]);
  return (
    <>
      <TableRow key={user.id}>
        {headers.map((h, i) => {
          const def = headers[i];
          if (def.label === SystemUsersTableHeaderKeyValueFields.LastLogin.label) {
            return (
              <TableCell align={def.align} key={def.label}>
                {user.lastLoginDate && dateTime12HFormat(user.lastLoginDate)}
              </TableCell>
            );
          }
          if (def.label === SystemUsersTableHeaderKeyValueFields.Action.label) {
            return (
              <TableCell align={def.align} key={def.label}>
                <DropDownMenuForTableCell className={Style.dropdownMenuBtn}>
                  <DropDownMenuButton
                    disabled={SystemUsersStore.isDeactivatingOrReactivating}
                    onClick={() => (user.canDelete ? onRemovingUser() : onDeactiveOrActiveUser())}>
                    {user.canDelete ? 'Delete' : 'Deactivate'}
                  </DropDownMenuButton>

                  <DropDownMenuButton onClick={fetchExportData} disabled={loading}>
                    Export teams to CSV
                    {exportUserData && (
                      <CSVLink
                        headers={exportUserData?.headers ?? []}
                        filename={exportUserData?.filename}
                        data={exportUserData?.data ?? []}
                        ref={csvLinkRef}
                      />
                    )}
                  </DropDownMenuButton>
                </DropDownMenuForTableCell>
              </TableCell>
            );
          }
          if (def.label === SystemUsersTableHeaderKeyValueFields.Reactivate.label) {
            return (
              <TableCell align={def.align} key={def.label}>
                <SecondaryButton
                  disabled={SystemUsersStore.isDeactivatingOrReactivating}
                  className={Style.deactiveUserBtn}
                  onClick={onDeactiveOrActiveUser}>
                  <Icon className={Style.deactiveUserIcon} name={'how_to_reg'} />
                </SecondaryButton>
              </TableCell>
            );
          }
          if (def.label === SystemUsersTableHeaderKeyValueFields.UserRole.label) {
            return (
              <TableCell align={def.align} key={def.label}>
                <Dropdown
                  placeholder="Select Client User Role"
                  items={SystemUsersStore.clientUserRoles}
                  selectedItem={user.clientUserRoleId}
                  onSelectItem={(value) => SystemUsersStore.changeClientUserRole(user, value)}
                  optionsHeight={DropdownStyle.defaultHeight}
                  open={totalRows && totalRows > maxItemsInPage && rowIndex === totalRows - 1 ? 'up' : undefined}
                />
              </TableCell>
            );
          }
          return (
            <TableCell align={def.align} key={def.label} checkbox>
              {cellValue(h)}
            </TableCell>
          );
        })}
      </TableRow>

      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={SystemUsersStore.errorCode}
          errorMessage={SystemUsersStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(SystemUserTableRow);
