import { makeAutoObservable, runInAction } from 'mobx';
import { FileItem } from '../../../../common/interfaces/fileUpload';
import { fileSizeUnits, isHTML, validateEmail } from '../../../../utils/miscUtils';
import { FileExt } from '../../../../common/constants/FileExt';
import { v4 as uuidv4 } from 'uuid';
import {
  IValidationImportResultModel,
  IValidationImportUserModel,
  IValidationImportUserResponseModel,
} from '../../../../components/settings/shared/userUpload/models/ValidationImportUserModel';
import { dateTimeFormat } from '../../../../utils/dateUtils';
import LayoutStore from '../../../../components/layout/LayoutStore';
import { getSystemUsers } from '../../../../api/authenticated/um/getSystemUsers';
import { SystemUsersFilter } from '../../../../api/authenticated/um/SystemUsersFilterClass';
import { IValidationImportSystemUsers } from '../../../../models/importSystemUsers/validationImportSystemUsers';

export class UserImportStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public filesSizeExceededTheLimit = false;
  public selectedFile: FileItem | null = null;
  public showProgressBar = false;
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public isProcessing = false;
  public processed = false;
  public isSelectedInvalidExtension = false;
  public totalUsers = 0;
  public importProgress = 0;
  fileReader = new FileReader();
  importingUsers: IValidationImportUserModel[] = [];
  processedImportingUsers = {} as IValidationImportUserResponseModel;
  public header = 'Import Users';
  public importResults: {
    email: string;
    status: string;
    reason: string;
  }[] = [];

  // Limit 5 MB
  public limitBytes = 5 * fileSizeUnits.MB;

  public clear() {
    runInAction(() => {
      this.showProgressBar = false;
      this.selectedFile = null;
      this.filesSizeExceededTheLimit = false;
      this.isProcessing = false;
      this.importProgress = 0;
      this.processed = false;
      this.showUploadSuccess = false;
      this.header = 'Import Users';
      this.isSelectedInvalidExtension = false;
    });
  }

  private async readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  private csvFileToUsersArray(csvOutput: string) {
    csvOutput = csvOutput.replace(/\r/g, '');
    const csvHeader = csvOutput.slice(0, csvOutput.indexOf('\n')).split(',');
    if (!(csvHeader.includes('email') || csvHeader.includes('"email"'))) {
      runInAction(() => {
        this.isSelectedInvalidExtension = true;
      });
      return [];
    }

    const csvRows = csvOutput
      .slice(csvOutput.indexOf('\n') + 1)
      .split('\n')
      .filter((row) => row.trim() !== '');
    runInAction(() => {
      this.totalUsers = csvRows.length;
    });

    const array = csvRows.map((row) => {
      const values = row.split(',');
      const user: IValidationImportUserModel = {
        id: uuidv4(),
        email: values[csvHeader.indexOf('email') >= 0 ? csvHeader.indexOf('email') : csvHeader.indexOf('"email"')],
        username:
          values[
            csvHeader.indexOf('displayname') >= 0
              ? csvHeader.indexOf('displayname')
              : csvHeader.indexOf('"displayname"')
          ],
      };
      return user;
    });

    runInAction(() => {
      this.importingUsers = array;
    });
    return array;
  }

  public async validateImportingUsers(
    customUserValidation: (validUsers: IValidationImportResultModel[]) => {
      errorUsers: IValidationImportResultModel[];
      duplicateUsers: IValidationImportResultModel[];
      readyForImportUsers: IValidationImportResultModel[];
    },
    checkSystemAdmin: boolean,
    users: IValidationImportSystemUsers[] | null
  ) {
    const emailSet = new Set<string>();
    const errors: IValidationImportResultModel[] = [];
    let validUsers: IValidationImportResultModel[] = [];

    const isValidUser = (user, emailSet, validUsers) => {
      const displayName = user.username;
      const userData = { id: user.id, email: user.email, displayName: displayName };
      const results = [] as string[];

      if (!user.email || !validateEmail(user.email)) {
        results.push('Invalid email');
      }
      if (isHTML(user.email)) {
        results.push('Cannot contain special characters/tags');
      }
      if (emailSet.has(user.email)) {
        const index = validUsers.findIndex((vu) => vu.email === user.email);
        if (index > -1) validUsers.splice(index, 1);
        results.push('Duplicate email');
      }

      return results.length ? { ...userData, result: results.join(',') } : null;
    };

    const data =
      users ??
      (await getSystemUsers({} as SystemUsersFilter)).map(
        (u) =>
          ({
            id: u.id,
            email: u.email,
            username: u.name,
            active: u.active,
          } as IValidationImportSystemUsers)
      );

    const processedUsers = this.importingUsers.map((m) => {
      return {
        ...m,
        displayName: data.find((f) => f.email.toLowerCase() === m.email.toLowerCase())?.username ?? '',
      };
    });

    for (const user of processedUsers) {
      const validationResult = isValidUser(user, emailSet, validUsers);
      if (validationResult) {
        errors.push(validationResult);
      } else {
        emailSet.add(user.email);
        validUsers.push({ ...user, displayName: user.displayName, result: '' });
      }
    }

    const notFoundUsers = checkSystemAdmin
      ? validUsers.filter((f) => !data.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()))
      : [];

    const errorsNotFound = checkSystemAdmin
      ? notFoundUsers.map((user) => ({
          ...user,
          result: 'User not found in Tucana',
        }))
      : [];

    errors.push(...errorsNotFound);
    validUsers = validUsers.filter(
      (user) => !notFoundUsers.some((u) => u.email.toLowerCase() === user.email.toLowerCase())
    );

    const deactivatedUsers = checkSystemAdmin
      ? validUsers.filter(
          (f) =>
            !data
              .filter((d) => d.active)
              .map((m) => m.email.toLowerCase())
              .includes(f.email.toLowerCase())
        )
      : [];

    const errorsDeactivated = checkSystemAdmin
      ? deactivatedUsers.map((user) => ({
          ...user,
          result: 'User Deactivated',
        }))
      : [];

    errors.push(...errorsDeactivated);
    validUsers = validUsers.filter(
      (user) => !deactivatedUsers.some((u) => u.email.toLowerCase() === user.email.toLowerCase())
    );

    const { errorUsers, duplicateUsers, readyForImportUsers } = customUserValidation(validUsers);
    errors.push(...errorUsers);

    runInAction(() => {
      this.processedImportingUsers = {
        errors,
        duplicates: duplicateUsers,
        readyForImport: readyForImportUsers,
      };
      this.processed = true;
      this.header = 'Import Results';
      this.isProcessing = false;
    });
  }

  public async addFiles(
    file: FileItem,
    systemUsers: IValidationImportSystemUsers[] | null,
    checkSystemAdmin: boolean,
    customUserValidation: (validUsers: IValidationImportResultModel[]) => {
      errorUsers: IValidationImportResultModel[];
      duplicateUsers: IValidationImportResultModel[];
      readyForImportUsers: IValidationImportResultModel[];
    }
  ) {
    if (!this.validationUploadFiles(file, [FileExt.CSV])) return;
    runInAction(() => {
      this.isProcessing = true;
    });

    try {
      const content = await this.readFileContent(file);
      runInAction(() => {
        this.selectedFile = file;
      });
      const users = this.csvFileToUsersArray(content);
      if (!users.length) {
        this.removeFile();
        return;
      }
      await this.validateImportingUsers(customUserValidation, checkSystemAdmin, systemUsers);
    } catch (error) {
      LayoutStore.displayToast('error', 'Error reading file.');
    } finally {
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  }

  public removeFile() {
    runInAction(() => {
      this.selectedFile = null;
    });
  }
  private startUploadProcess() {
    runInAction(() => {
      this.importProgress = 0;
      this.showProgressBar = true;
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  public async handleImportUser(
    importUserCallback: (readyForImport: IValidationImportResultModel[]) => Promise<{
      isSuccess: boolean;
      importResults: {
        email: string;
        status: string;
        reason: string;
      }[];
    }>
  ) {
    if (this.selectedFile) {
      this.startUploadProcess();
      this.simulateProgress();

      try {
        const response = await importUserCallback(this.processedImportingUsers.readyForImport);

        runInAction(() => {
          this.importProgress = 100;
          this.showUploadSuccess = response.isSuccess;
          this.showUploadFailed = !response.isSuccess;
          this.importResults = response.importResults.map((m) => {
            return {
              email: m.email,
              status: m.status,
              reason: m.reason,
            };
          });
        });
      } catch {
        runInAction(() => {
          this.importProgress = 100;
          this.showUploadSuccess = false;
          this.showUploadFailed = true;
          this.importResults = this.processedImportingUsers.readyForImport.map((m) => {
            return {
              email: m.email,
              status: 'Failure',
              reason: 'Something went wrong',
            };
          });
        });
      }
    }
  }
  private simulateProgress() {
    if (this.importProgress < 90) {
      setTimeout(() => {
        runInAction(() => {
          this.importProgress += 1;
        });
        this.simulateProgress();
      }, 100);
    }
  }

  public getResultsCSVData() {
    return {
      filename: `Bulk_User_Import_Result_${dateTimeFormat(new Date(), 'yyyyMMdd')}.csv`,
      headers: [
        { label: 'Email', key: 'email' },
        { label: 'Import Status', key: 'status' },
        { label: 'Reason', key: 'reason' },
      ],
      data: this.importResults,
    };
  }

  private validationUploadFiles(file: File, acceptedExtension?: string[]): boolean {
    const exceedLimitation = (file.size ?? 0) > this.limitBytes;
    const isInvalidExtension = !acceptedExtension?.includes(file.name.split('.').pop()?.toLowerCase() ?? '');
    runInAction(() => {
      this.filesSizeExceededTheLimit = exceedLimitation;
      this.isSelectedInvalidExtension = isInvalidExtension;
    });

    return !(exceedLimitation || isInvalidExtension);
  }

  public getUserImportCSVTemplate() {
    return {
      filename: `User_Import_Template.csv`,
      headers: [{ label: 'email', key: 'email' }],
      data: Object.keys([]).map((email) => ({
        email,
      })),
    };
  }
}

export default new UserImportStore();
