import { TaskStatus } from '../../common/enums/TaskStatus';
import { TaskType } from '../../common/enums/TaskType';

export enum TabFilter {
  AwaitingMyAction,
  CreatedByMe,
  AllPendingTasks,
  AllTasks,
}

export enum SortOption {
  ProgrammeTitle,
  ProjectNumber,
  ProjectTitle,
  LastUpdatedDate,
  CreatedDate,
  TaskType,
  DueDate,
}

export type StatusFilter = TaskStatus | -1;

export type TypeFilter = TaskType | -1;

export type ReviewState = 'Review' | 'Shared' | 'Collaborate' | 'Published';
