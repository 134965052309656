export enum SelectorType {
  User,
  DeliveryTeam,
  TaskTeam,
  AppointingParty,
  DistributionList,
  AppointingPartyUser,
}

export const listSeparator: { key: SelectorType; value: string }[] = [
  { key: SelectorType.User, value: '-1' },
  { key: SelectorType.TaskTeam, value: '-2' },
  { key: SelectorType.DistributionList, value: '-3' },
  { key: SelectorType.AppointingPartyUser, value: '-4' },
];
