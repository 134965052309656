import { serverAxios } from '../api';

export interface IProject {
  projectNumber: string;
  title: string;
  description?: string;
  devSite: boolean;
  programmeId: number;
  programmeTitle: string;
  projectOwnerName: string;
  ProjectOwnerUserId: number;
  projectStatusId: number;
  projectStatus: string;
}

export async function getProjects(projectNumber?: string, abortSignal?: AbortSignal): Promise<IProject[]> {
  const result = await serverAxios.get<{ projects: IProject[] }>(`/api/config/projects`, {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.projects;
}
