export enum FileContainerLockReason {
  Supersede = 'supersede',
  Workflow = 'workflow',
  Other = 'other',
}

export const FileContainerLockMessage = {
  [FileContainerLockReason.Supersede]:
    'This file is locked as a user has selected it to be superseded. Should the file not unlock within 5min, please contact your Tucana administrator',
  [FileContainerLockReason.Workflow]: 'This file is locked as part of a workflow',
  [FileContainerLockReason.Other]:
    'This file is in a locked state. Should the file not unlock within 5min, please contact your Tucana administrator',
};
