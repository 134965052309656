import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamUsers.module.scss';
import classnames from 'classnames';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import { Loader, Pill, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import { IUser } from '../../shared/UserSelector';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SearchBar from '../../shared/SearchBar';
import PrimaryButton from '../../shared/PrimaryButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import AppStore from '../../../stores/AppStore';
import SingleUserSelector from '../../shared/SingleUserSelector';
import { ITeamModel } from './models/ITeamManagementModel';
import SecondaryButton from '../../shared/SecondaryButton';
import { ICSVData } from '../../../common/interfaces/csvData';
import { CSVLink } from 'react-csv';
import { IExportDeliveryTeamModel } from './models/IExportDeliveryTeamModel';
import UserImportModal from '../shared/userUpload/UserImportModal';
import { IValidationImportResultModel } from '../shared/userUpload/models/ValidationImportUserModel';
import { importDeliveryTeamUsers } from '../../../api/authenticated/um/importDeliveryTeamUsers';

export interface IDeliveryTeamUsersProps {
  projectNumber: string;
  projectName: string;
  team: ITeamModel;
}

const DeliveryTeamUsers: FC<IDeliveryTeamUsersProps> = (props) => {
  const { projectNumber, team, projectName } = props;
  const [userSelected, setUserSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addingOrDeleteUser, setAddingOrDeleteUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [searchText, setSearchText] = useState('');
  const [exportData, setExportData] = useState<ICSVData<IExportDeliveryTeamModel> | null>(null);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [clicked, setClicked] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  const userTableHeaders = [
    { label: 'User Name' },
    { label: 'User Email' },
    { label: 'User Role' },
    { label: 'Remove' },
  ];

  const fetchDataAsync = useCallback(async () => {
    if (!projectNumber || !team.id) return;

    setLoading(true);
    await TeamManagementStore.loadDeliveryTeamUsers(projectNumber, team.id);
    setLoading(false);
  }, [projectNumber, team.id]);

  useEffect(() => {
    fetchDataAsync();
  }, [fetchDataAsync]);

  useEffect(() => {
    if (!loading && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, loading]);

  const onUsersSelected = (users: IUser[]) => {
    setUserSelected(true);
    setSelectedUsers(users);
    setUserSelected(false);
  };

  const onSingleUserSelected = (user: IUser) => {
    setUserSelected(true);
    setSelectedUsers([user]);

    setUserSelected(false);
  };
  const onCloseUploadFileModal = () => {
    setShowUploadFileModal(false);
    fetchDataAsync();
  };
  const addUserToTeam = async () => {
    setAddingOrDeleteUser(true);
    await TeamManagementStore.addUserToDeliveryTeam(projectNumber, team.id, selectedUsers);
    setSelectedUsers([]);
    setAddingOrDeleteUser(false);
  };

  const removeUserFromTeam = async () => {
    setAddingOrDeleteUser(true);
    selectedUser &&
      (await TeamManagementStore.removeUserFromDeliveryTeam(projectNumber, team.id, selectedUser.id || 0));
    setShowConfirmation(false);
    setAddingOrDeleteUser(false);
    setSelectedUser(undefined);
  };

  const exportUsers = async () => {
    setLoading(true);
    setClicked(false);
    const data = await TeamManagementStore.downloadDeliveryTeamUsers(
      projectNumber,
      projectName,
      team.id,
      team.title,
      team.code
    );
    setExportData(data);
    setLoading(false);
  };

  const canInviteOrRemoveAzure = AppStore.client?.canInviteOrRemoveAzure;

  const performValidationDeliveryTeamUser = (
    validUsers: IValidationImportResultModel[]
  ): {
    errorUsers: IValidationImportResultModel[];
    duplicateUsers: IValidationImportResultModel[];
    readyForImportUsers: IValidationImportResultModel[];
  } => {
    const allUsers = [...TeamManagementStore.allTaskTeamUsers];
    const errors = validUsers.filter(
      (f) => !allUsers.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter((f) => !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()));

    const duplicates = validUsers.filter((f) =>
      TeamManagementStore.deliveryTeamUsers
        .filter((m) => m.deliveryTeamRoleIds.length)
        .map((m) => m.email.toLowerCase())
        .includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter(
      (f) =>
        !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()) &&
        !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter(
      (f) =>
        !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()) &&
        !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    const readyForImport = validUsers.filter(
      (f) => !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    return {
      errorUsers: errors.map((m) => {
        return {
          ...m,
          result: 'User not in a Task Team',
        };
      }),
      duplicateUsers: duplicates.map((m) => {
        return {
          ...m,
          result: 'User is already assigned to this team',
        };
      }),
      readyForImportUsers: readyForImport,
    };
  };

  const handleImportUser = async (readyForImport: IValidationImportResultModel[]) => {
    let response: {
      isSuccess: boolean;
      importResults: {
        email: string;
        status: string;
        reason: string;
      }[];
    } = {
      isSuccess: true,
      importResults: [],
    };
    try {
      response = await importDeliveryTeamUsers({
        deliveryTeamId: team.id,
        users: readyForImport.map((m) => {
          return {
            email: m.email,
            displayName: m.displayName,
          };
        }),
        projectNumber: projectNumber,
      });

      if (response.isSuccess) await fetchDataAsync();
    } catch (error) {
      response = {
        isSuccess: false,
        importResults: readyForImport.map((m) => {
          return {
            email: m.email,
            status: 'Failures',
            reason: 'Something went wrong',
          };
        }),
      };
    }
    return response;
  };

  if (loading) return <Loader />;

  return (
    <div className={Style.pageWrapper}>
      <div className={Style.addUserBox}>
        <div className={Style.heading}>Add Users</div>
        <div className={Style.addNewSession}>
          {!canInviteOrRemoveAzure && (
            <div
              className={classnames(
                canInviteOrRemoveAzure ? Style.halfRow : Style.row,
                Style.label,
                Style.searchBoxLabel
              )}>
              Users
            </div>
          )}
          <div
            className={classnames(Style.addUserContainer, !canInviteOrRemoveAzure ? Style.flex : '', {
              [Style.selectedUserContainer]: !!selectedUsers.length,
            })}>
            {canInviteOrRemoveAzure && (
              <div className={Style.addUserRow}>
                <div
                  className={classnames(
                    canInviteOrRemoveAzure ? Style.halfRow : Style.row,
                    Style.label,
                    Style.searchBoxLabel
                  )}>
                  User Email <span className={Style.required}>*</span>
                </div>
                <div className={classnames(Style.halfRow, Style.label, Style.searchBoxLabel, Style.displayNameLabel)}>
                  Display Name <span className={Style.required}>*</span>
                </div>
                <div className={Style.labelBtn}>Add</div>
              </div>
            )}
            {!canInviteOrRemoveAzure && (
              <div className={Style.addUserRow}>
                <UserSelectorExpandable
                  required
                  searchPlaceholder="Add user email..."
                  isMultiUser
                  className={Style.searchBox}
                  getUsers={(searchText) => TeamManagementStore.searchDeliveryTeamUsers(searchText)}
                  onSelectedUsersUpdated={onUsersSelected}
                  disabled={userSelected || addingOrDeleteUser}
                  defaultSelectedUsers={selectedUsers}
                />
                <PrimaryButton
                  className={Style.addUserButton}
                  onClick={addUserToTeam}
                  disabled={!selectedUsers.length || addingOrDeleteUser}>
                  Add
                </PrimaryButton>
              </div>
            )}
            {canInviteOrRemoveAzure && (
              <div className={Style.addUserRow}>
                <SingleUserSelector
                  className={Style.searchBox}
                  getUsers={(searchText) => TeamManagementStore.searchDeliveryTeamUsers(searchText)}
                  onSelectedUserUpdated={onSingleUserSelected}
                />
                <PrimaryButton
                  className={Style.addUserButton}
                  onClick={addUserToTeam}
                  disabled={!selectedUsers.length || addingOrDeleteUser}>
                  Add
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Style.teamUserTable}>
        <div className={Style.quickSearch}>
          <div className={Style.exportButton}>
            <SecondaryButton
              className={Style.btnMenuUploadBtn}
              disabled={loading}
              onClick={() => {
                setShowUploadFileModal(true);
              }}>
              Import Users
            </SecondaryButton>
            <SecondaryButton onClick={exportUsers}>Export to CSV</SecondaryButton>
          </div>
          <SearchBar
            searchValue={searchText}
            cssClass={Style.searchUserBox}
            onSearch={(keyword) => {
              setSearchText(keyword);
            }}
            placeHolderText="Quick search..."
          />
        </div>
        <Table headers={userTableHeaders}>
          {TeamManagementStore.filteredDeliveryTeamUsers
            .filter(
              (u) =>
                u.name.toLowerCase().includes((searchText ?? u.name).toLowerCase()) ||
                u.email.includes((searchText ?? u.email).toLowerCase())
            )
            .map((u) => (
              <TableRow key={u.id}>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>
                  <Pill colour={1} cssClass={Style.teamRolePill}>
                    {u.deliveryTeamRoleTitle}
                  </Pill>
                </TableCell>
                <TableCell>
                  <PrimaryIconButton
                    icon="delete"
                    onClick={() => {
                      setShowConfirmation(true);
                      setSelectedUser(u);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </div>

      {showUploadFileModal && (
        <UserImportModal
          onOpenning={() => TeamManagementStore.loadAllDeliveryTeamTaskTeamsUsers(projectNumber, team.id)}
          closeModal={() => onCloseUploadFileModal()}
          showModal={showUploadFileModal}
          customUserValidation={performValidationDeliveryTeamUser}
          importUserCallback={handleImportUser}
          users={TeamManagementStore.deliveryTeamUsers.map((u) => ({
            id: u.id,
            email: u.email,
            username: u.name,
            active: true,
          }))}
          checkSystemAdmin={false}
        />
      )}
      <ConfirmationModal
        showModal={showConfirmation}
        heading={`Deleting Delivery Team Authoriser?`}
        message={`This action will revoke User's access as a Delivery Team Authoriser from ${team.title}. This action does not remove User’s access as a Task Team user. Are you sure you want to delete ${selectedUser?.name}?`}
        confirmText="Yes"
        cancelText="No"
        loading={addingOrDeleteUser}
        onConfirm={removeUserFromTeam}
        onCancel={() => setShowConfirmation(false)}
      />

      {!loading && exportData && (
        <CSVLink
          headers={exportData?.headers ?? []}
          filename={exportData?.filename}
          data={exportData?.data ?? []}
          ref={csvLinkRef}
        />
      )}
    </div>
  );
};

export default observer(DeliveryTeamUsers);
