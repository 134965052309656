import { serverAxios } from '../api';
import { IUser } from './interfaces/user.interface';

export async function getUsers(
  projectNumber: string,
  excludeExternalUser?: boolean,
  abortSignal?: AbortSignal
): Promise<IUser[]> {
  const result = await serverAxios.get<{ users: IUser[] }>('api/um/users', {
    params: {
      projectNumber: projectNumber,
      excludeExternalUser: excludeExternalUser,
    },
    signal: abortSignal,
  });
  return result.data.users;
}
