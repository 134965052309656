import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useReviewStore } from './ReviewStoreContext';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import ModalActions from '../../shared/ModalActions';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';
import { ReviewTabIds } from './ReviewTabTypeModel';

interface IActionButtons {
  selectedTabId: number;
  isCollaborate: boolean;
  onSelectNextTab: () => void;
}
const ActionButtons: FC<IActionButtons> = ({ selectedTabId, isCollaborate, onSelectNextTab }) => {
  const store = useReviewStore();

  const submit = async () => {
    await store.save();
  };
  const isSubmit = (isCollaborate && selectedTabId === ReviewTabIds.USER) || selectedTabId === ReviewTabIds.ADVANCE;

  return (
    <ModalActions>
      <SecondaryButton onClick={store.closeModal} disabled={store.isSaving}>
        Cancel
      </SecondaryButton>
      {isSubmit ? (
        <ButtonSavingSpinner isSaving={store.isSaving}>
          <PrimaryButton disabled={!store.canSave} onClick={submit}>
            Submit
          </PrimaryButton>
        </ButtonSavingSpinner>
      ) : (
        <PrimaryButton onClick={() => onSelectNextTab()}>Next</PrimaryButton>
      )}
    </ModalActions>
  );
};

export default observer(ActionButtons);
