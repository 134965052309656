import { makeAutoObservable, runInAction } from 'mobx';
import { FileItem, IUploadFile } from '../../../../../common/interfaces/fileUpload';
import {
  addClientFileTemplate,
  IAddClientFileTemplateResponse,
} from '../../../../../api/authenticated/config/addClientFileTemplate';
import { uploadClientFileTemplate } from '../../../../../api/authenticated/config/uploadClientFileTemplate';
import { calculateChunks, fileSizeUnits } from '../../../../../utils/miscUtils';
import { FileExt } from '../../../../../common/constants/FileExt';
import {
  ITemplateFileNameValidationItem,
  validationTemplateFileNames,
} from '../../../../../api/authenticated/config/validationTemplateFileName';

export class FileTemplatesUploadStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private chunkSize = 3000000;
  public filesSizeExceededTheLimit = false;
  public selectedFile: FileItem | null = null;
  public showProgressBar = false;
  private fileChunks: { [filename: string]: IUploadFile } = {};
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public totalChunks = 0;
  public totalUploadedChunks = 0;
  public percentageUploaded = 0;
  public isProcessing = false;
  public isSelectedInvalidExtension = false;

  public templateFileName?: string;
  // Limit 20MB
  public limitBytes = 20 * fileSizeUnits.MB;

  public async initialUpload(templateFileName: string) {
    runInAction(() => {
      this.templateFileName = templateFileName;
    });
  }

  public clear() {
    runInAction(() => {
      this.showProgressBar = false;
      this.selectedFile = null;
      this.filesSizeExceededTheLimit = false;
      this.totalChunks = 0;
      this.totalUploadedChunks = 0;
      this.percentageUploaded = 0;
    });
  }

  public async addFiles(file: FileItem) {
    if (!this.validationUploadFiles(file, [FileExt.DOCX, FileExt.XLSX, FileExt.PPTX])) return;

    runInAction(() => {
      this.isProcessing = true;
    });

    try {
      runInAction(() => {
        this.selectedFile = file;
      });
    } finally {
      setTimeout(() => {
        runInAction(() => {
          this.isProcessing = false;
        });
      }, 500);
    }
  }

  public removeFile() {
    runInAction(() => {
      this.selectedFile = null;
    });
  }

  private startUploadProcess() {
    runInAction(() => {
      this.showProgressBar = true;
      this.fileChunks = {};
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  public async handleUploadFileTemplates() {
    if (this.selectedFile) {
      if (!this.templateFileName) return;

      this.startUploadProcess();
      this.totalChunks = 0;
      this.setCalculateFileChunks(this.selectedFile);
      this.totalChunks += this.fileChunks[this.selectedFile.name].chunks.length;

      const addFilesResponse = await addClientFileTemplate({
        templateFileName: this.templateFileName ?? '',
        originalFileName: this.selectedFile.name,
        totalFileSize: this.selectedFile.size,
        totalFileChunks: this.fileChunks[this.selectedFile.name].chunks.length,
      });

      await Promise.all([this.uploadFile(addFilesResponse)]);

      if (!this.filesFailedToUpload.length) {
        runInAction(() => {
          this.showUploadSuccess = true;
        });
      } else {
        runInAction(() => {
          this.showUploadFailed = true;
        });
      }
    }
  }

  public get filesFailedToUpload() {
    const failedFiles: string[] = [];
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      if (uploadFile.failed) failedFiles.push(filename);
    });
    return failedFiles;
  }

  private setCalculateFileChunks(file: FileItem) {
    runInAction(() => {
      this.fileChunks[file.name] = {
        numberOfChunksUploaded: 0,
        failed: false,
        chunks: calculateChunks(file.size, this.chunkSize),
      };
    });
  }

  private async uploadFile(addedFile: IAddClientFileTemplateResponse) {
    const file = this.selectedFile;
    if (!this.templateFileName || !file) return;
    try {
      await this.uploadChunks(file, this.fileChunks[file.name], addedFile.clientFileTemplateId);
    } catch (err) {
      runInAction(() => {
        this.fileChunks[file.name].failed = true;
      });
    }
  }

  private async uploadChunks(file: File, uploadFile: IUploadFile, fileTemplateId: number) {
    for (const chunk of uploadFile.chunks) {
      const blob = file.slice(chunk.start, chunk.end);
      const isLast = chunk.index === uploadFile.chunks.length - 1;
      await uploadClientFileTemplate({
        blob: blob,
        fileTemplateId: fileTemplateId,
        index: chunk.index,
        offset: chunk.start,
        isLastChunk: isLast,
      });

      runInAction(() => {
        uploadFile.numberOfChunksUploaded++;
        // calc percentage of files upload
        this.totalUploadedChunks += 1;
        this.percentageUploaded = (this.totalUploadedChunks / this.totalChunks) * 100;
      });
    }
  }

  public async checkTemplateFileNamesDuplicated(templateFileNames: ITemplateFileNameValidationItem[]) {
    if (!templateFileNames) return;

    return await validationTemplateFileNames({ templateFileNames: templateFileNames });
  }

  private validationUploadFiles(file: File, acceptedExtension?: string[]): boolean {
    const exceedLimitation = (file.size ?? 0) > this.limitBytes;
    const isInvalidExtension = !acceptedExtension?.includes(file.name.split('.').pop()?.toLowerCase() ?? '');

    runInAction(() => {
      this.filesSizeExceededTheLimit = exceedLimitation;
      this.isSelectedInvalidExtension = isInvalidExtension;
    });

    return !(exceedLimitation || isInvalidExtension);
  }
}

export default new FileTemplatesUploadStore();
