import { serverAxios } from '../api';

export interface IUploadTransmittalMessageAttachmentFileRequest {
  blob: Blob;
  projectNumber: string;
  transmittalId: number;
  transmittalMessageId: number;
  attachmentFileUploadId: number;
  index: number;
  offset: number;
  isLastChunk: boolean;
}

export async function uploadTransmittalMessageAttachmentFiles(
  request: IUploadTransmittalMessageAttachmentFileRequest,
  abortSignal?: AbortSignal
): Promise<number> {
  const formData = new FormData();
  formData.append('file', request.blob);
  formData.append('projectNumber', request.projectNumber);
  formData.append('transmittalId', request.transmittalId.toString());
  formData.append('transmittalMessageId', request.transmittalMessageId.toString());
  formData.append('attachmentFileUploadId', request.attachmentFileUploadId.toString());
  formData.append('index', request.index.toString());
  formData.append('offset', request.offset.toString());
  formData.append('isLastChunk', request.isLastChunk.toString());
  const result = await serverAxios.post<number>('api/transmittals/upload', formData, { signal: abortSignal });
  return result.data;
}
