import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import TaskInformationStore from './TaskInformationStore';
import SecondaryButton from '../shared/SecondaryButton';
import Style from './styles/TaskInformationFiles.module.scss';
import DownloadModal from '../shared/DownloadModal';
import { getFileSizeDetails, getFileSizeString } from '../../utils/miscUtils';
import DropDownMenuForTableCell from '../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { dateTime12HFormat } from '../../utils/dateUtils';
import { ITaskAttachmentFile } from '../../api/authenticated/tasks/getTask';

const TaskAttachmentFiles: FC = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(TaskInformationStore.taskAttachmentFilesSize);
  if (!TaskInformationStore.task?.attachmentFiles.length) return null;

  const handleDownload = (attachmentFile?: ITaskAttachmentFile) => {
    if (isOverLimit) {
      setShowDownloadModal(true);
      return;
    }
    TaskInformationStore.downloadTaskAttachmentFiles(attachmentFile?.id);
  };

  return (
    <div className={Style.cardWrapper}>
      <div className={Style.cardHeader}>
        <Icon outlined type="description" className={Style.cardHeaderIcon} />
        <span>Supporting Files ({TaskInformationStore.task.attachmentFiles.length})</span>
      </div>
      <Table
        headers={[
          { label: 'Filename' },
          { label: 'Added By' },
          { label: 'Date Added' },
          { label: 'totalSize' },
          { label: 'Action', align: 'right' },
        ]}
        breakpoint={10}
        hoverable>
        {TaskInformationStore.task.attachmentFiles.map((attachmentFile) => {
          return (
            <TableRow key={`${attachmentFile.id}`}>
              <TableCell>{attachmentFile.originalFileName}</TableCell>
              <TableCell>{attachmentFile.uploadedByUserName}</TableCell>
              <TableCell>{dateTime12HFormat(attachmentFile.addedDate)}</TableCell>
              <TableCell>{getFileSizeString(attachmentFile.totalSize)}</TableCell>
              <DropDownMenuForTableCell>
                <DropDownMenuButton onClick={() => handleDownload(attachmentFile)}>Download</DropDownMenuButton>
              </DropDownMenuForTableCell>
            </TableRow>
          );
        })}
      </Table>
      <div className={Style.cardFooter}>
        <SecondaryButton onClick={() => handleDownload()}>Download all files</SecondaryButton>
      </div>
      <DownloadModal
        closeModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        downloadAction={() => {
          TaskInformationStore.downloadAllTaskFiles();
          setShowDownloadModal(false);
        }}
        fileSize={totalFileSize}
      />
    </div>
  );
};

export default observer(TaskAttachmentFiles);
