import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TransmittalUserListModal.module.scss';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import Modal from '../shared/Modal';
import CloseButton from '../shared/CloseButton';
import { Accordion, AccordionPanel, Icon, Loader, Tooltip } from '@aurecon-creative-technologies/styleguide';
import ModalActions from '../shared/ModalActions';
import SecondaryButton from '../shared/SecondaryButton';
import UserCircle from '../shared/UserCircle';
import { IOpenPillProps } from './Types';
import { SelectorType } from '../../enums/TransmittalsSelectorType';
import { IBaseUser } from '../../common/interfaces/baseUser';
import { getUserNameInitials } from '../../utils/pipe';
import { SortTypes } from '../../common/enums/SortType';
import { sort } from '../../utils/sortHelper';
import { IProjectMembersAndTeams } from '../../api/authenticated/um/getProjectMembersAndTeams';
import { getDistributionListUsers } from '../../api/authenticated/um/getDistributionListUsers';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';

export interface TransmittalUserModalProps {
  item: IOpenPillProps | undefined;
  projectMembersAndTeams: IProjectMembersAndTeams | null;
  closeModal: () => void;
}

const TransmittalUserListModal: FC<TransmittalUserModalProps> = (props) => {
  const { item, projectMembersAndTeams, closeModal } = props;
  const [loading, setLoading] = useState(false);
  const [groupTitle, setGroupTitle] = useState<JSX.Element | null>(null);
  const [panelId, setPanelId] = useState<string>('');
  const [displayUsers, setDisplayUsers] = useState<IBaseUser[]>([]);

  useEffect(() => {
    if (!item || item.selectedType === SelectorType.User || item.selectedType === SelectorType.AppointingParty) return;
    setLoading(true);
    setPanelId(item.id.toString());

    if (item.selectedType === SelectorType.DistributionList) {
      const effectAsync = async () => {
        const users = await getDistributionListUsers(NavBarSelectorStore.selectedItem!.project.projectNumber, item.id);

        const distributionListUsers = users.map((x) => {
          return {
            id: x.id,
            name: x.name,
            email: x.email,
            userInitials: x.nameByInitials,
          };
        });

        distributionListUsers.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));
        const distributionTitle =
          projectMembersAndTeams?.distributionList.find((x) => x.distributionListId === item.id)
            ?.distributionListName ?? '';
        setGroupTitle(<strong>{distributionTitle}</strong>);
        setDisplayUsers([...distributionListUsers]);
      };
      effectAsync();
    } else {
      if (item.selectedType === SelectorType.DeliveryTeam) {
        const groupTitle =
          projectMembersAndTeams?.deliveryTeams.find((x) => x.deliveryTeamId === item.id)?.deliveryTeamTitle ?? '';
        setGroupTitle(<strong>{groupTitle}</strong>);
      }

      if (item.selectedType === SelectorType.TaskTeam) {
        const taskTeam = projectMembersAndTeams?.taskTeams.find((x) => x.taskTeamId === item.id);
        if (taskTeam) {
          setGroupTitle(
            <span>
              {taskTeam.deliveryTeamTitle} | <strong>{taskTeam?.taskTeamTitle}</strong>
            </span>
          );
        }
      }

      const teamUsers =
        projectMembersAndTeams?.users
          .filter(
            (x) =>
              (x.deliveryTeamId === item.id && item.selectedType === SelectorType.DeliveryTeam) ||
              (x.taskTeamId === item.id && item.selectedType === SelectorType.TaskTeam)
          )
          .map((x) => {
            return {
              id: x.userId,
              email: x.userEmail,
              name: x.userName,
              userInitials: getUserNameInitials(x.userName),
            };
          }) || [];

      const arrayUniqueByEmail = sort(
        [...new Map(teamUsers.map((item) => [item.email, item])).values()],
        'name',
        SortTypes.ASC
      );
      setDisplayUsers([...arrayUniqueByEmail]);
    }

    setLoading(false);
  }, [
    item,
    projectMembersAndTeams?.deliveryTeams,
    projectMembersAndTeams?.distributionList,
    projectMembersAndTeams?.taskTeams,
    projectMembersAndTeams?.users,
  ]);

  const onUserPanelToggle = (id: string) => {
    setPanelId(id);
  };

  if (!item || item.selectedType === SelectorType.User || item.selectedType === SelectorType.AppointingParty)
    return null;

  if (loading) return <Loader />;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <div className={Style.headerTitle}>
          <Icon type="people" className={Style.headerIcon} />
          <span>View Users</span>
        </div>
        <div className={Style.modalContainer}>
          {displayUsers && !!displayUsers.length && (
            <Accordion
              headingIconColour="#f37021"
              verticalPanelGap="24px"
              headingBgColour="#F2F2F2"
              headingFontColour="#000000"
              activePanelIds={panelId}
              onPanelToggle={(id) => onUserPanelToggle(id)}>
              <AccordionPanel
                label={
                  <Tooltip
                    show={<div className={Style.accordionPanelTitleLabelTooltip}>{groupTitle}</div>}
                    defaultUp={true}>
                    <div className={Style.accordionPanelTitle}>
                      <div className={Style.accordionPanelInnerTitleWrapper}>
                        <Icon type="people" className={Style.peopleIcon}></Icon>
                        <span className={Style.accordionPanelTitleLabel}>{groupTitle}</span>
                      </div>
                      <div className={Style.countCircle}>{displayUsers.length}</div>
                    </div>
                  </Tooltip>
                }
                panelId={item.id.toString()}
                cssHeaderClass={Style.accordianPanelHeader}>
                {displayUsers.map((user) => (
                  <div key={user.id}>
                    <div className={Style.userItem}>
                      <span className={Style.userCircle}>
                        <UserCircle initials={user.userInitials!} />
                      </span>
                      <span className={Style.displayName}>{user.name}</span>
                    </div>
                  </div>
                ))}
              </AccordionPanel>
            </Accordion>
          )}
        </div>
        <CloseButton onClick={closeModal} disabled={false} />
        <ModalActions className={Style.modalActions}>
          <SecondaryButton onClick={closeModal}>Close</SecondaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TransmittalUserListModal);
