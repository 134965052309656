import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as TucanaLogo } from '../../assets/tucana-logo-dark.svg';
import Style from './styles/PageDisabled.module.scss';
import AppStore from '../../stores/AppStore';

export interface IPageDisabledProps {
  pageName: string;
}

const PageDisabled: FC<IPageDisabledProps> = (props) => {
  return (
    <div className={Style.wrapper}>
      <div className={Style.content}>
        <TucanaLogo height={50} width={200} />
        <div className={Style.errorHeading}>{props.pageName} is disabled for this project</div>
        <p>
          Please contact your admin or{' '}
          <a href={'mailto:' + AppStore.client!.supportUrl} target="_self">
            Contact Support
          </a>{' '}
          if access is required.
        </p>
      </div>
    </div>
  );
};

export default observer(PageDisabled);
