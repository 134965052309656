import { serverAxios } from '../api';

export interface IFlaggedTransmittalResponse {
  flagged: boolean;
}

export async function setFlaggedTransmittal(
  projectNumber: string | null,
  transmittalId: number,
  flagged: boolean,
  abortSignal?: AbortSignal
): Promise<IFlaggedTransmittalResponse> {
  const result = await serverAxios.put<{ flagged: IFlaggedTransmittalResponse }>(
    'api/transmittals/flagtransmittal',
    { projectNumber, transmittalId, flagged },
    { signal: abortSignal }
  );
  return result.data.flagged;
}
