import { FileContainerState } from '../../../common/enums/FileContainerState';
import { TaskType } from '../../../common/enums/TaskType';
import { serverAxios } from '../api';

export interface IReviewer {
  userId: number;
  name: string;
  email: string;
  accessible?: boolean;
}

export interface IAcceptor {
  userId: number;
  name: string;
  email: string;
  isDeliveryTeamAcceptor: boolean;
}

export interface ISuitability {
  id: number;
  code: string;
  title: string;
  isSuitabilityRefCode: boolean | null;
}

export interface IAddTaskData {
  reviewers: IReviewer[];
  suitabilities: ISuitability[];
  acceptors: IAcceptor[];
  isRequireSingleAcceptorConfig: boolean;
}

export async function getAddData(
  taskType: TaskType,
  taskTeamId: number,
  abortSignal?: AbortSignal
): Promise<IAddTaskData> {
  const result = await serverAxios.get<IAddTaskData>('api/task/adddata', {
    params: { taskType, taskTeamId },
    signal: abortSignal,
  });
  return result.data;
}

export interface IProjectSuitabilities {
  suitabilities: ISuitability[];
}

export async function getSuitabilities(
  projectNumber: string,
  fileContainerStateId: FileContainerState,
  abortSignal?: AbortSignal
): Promise<ISuitability[]> {
  const result = await serverAxios.get<IProjectSuitabilities>('api/task/suitabilities', {
    params: { projectNumber: projectNumber, fileContainerStateId: fileContainerStateId },
    signal: abortSignal,
  });
  return result.data.suitabilities;
}
