import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, IHeader, TableRow, TableCell } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/UserImportModal.module.scss';
import { SortTypes } from '../../../../common/enums/SortType';
import { IValidationImportResultModel } from './models/ValidationImportUserModel';
import { sort } from '../../../../utils/sortHelper';
import { ITableColumn } from '../../../../common/interfaces/TableColumn';

export interface IUserImportResultTableProps {
  tableColumns: ITableColumn[];
  tableImportSystemUsers: IValidationImportResultModel[];
}

const UserImportResultTable: FC<IUserImportResultTableProps> = (props) => {
  const [tableColumns, setTableColumns] = useState<ITableColumn[]>(props.tableColumns);
  const [tableImportUsers, setTableImportUsers] = useState<IValidationImportResultModel[]>(
    props.tableImportSystemUsers
  );

  const sortColumnClickHandler = (tableColumn: ITableColumn, direction: SortTypes) => {
    applySort(tableColumn.valueField, direction);
  };

  const applySort = (columnName: string, direction: SortTypes) => {
    const sortedData = sort(tableImportUsers, columnName, direction);
    setTableImportUsers(sortedData);

    const updatedColumns = tableColumns.map((c) => {
      if (c.valueField === columnName) {
        return { ...c, sort: direction };
      }
      return c;
    });
    setTableColumns(updatedColumns);
  };

  const unSortingColumns = ['Display Name', 'Results'];
  const headers: IHeader[] = tableColumns.map((tableColumn) => {
    const isUnSortable = unSortingColumns.includes(tableColumn.label);
    const sortDirection = tableColumn.sort ? tableColumn.sort : 'none';
    return {
      label: tableColumn.label,
      sort: isUnSortable ? undefined : sortDirection,
      onSort: isUnSortable
        ? undefined
        : (sort) => sortColumnClickHandler && sortColumnClickHandler(tableColumn, sort as SortTypes),
      width: tableColumn.width,
      minWidth: tableColumn.minWidth,
    } as IHeader;
  });

  return (
    <Table cssClass={Style.systemUserImportTable} headers={headers} breakpoint={10}>
      {tableImportUsers.map((row) => (
        <TableRow key={row.id}>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.displayName}</TableCell>
          <TableCell>{row.result}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

export default observer(UserImportResultTable);
