import { serverAxios } from '../api';

export interface IDeactiveDeliveryTeamRequest {
  projectNumber: string;
  deliveryTeamId: number;
}

export async function deactiveDeliveryTeam(
  request: IDeactiveDeliveryTeamRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return await serverAxios.put('api/um/deliveryteam/deactive', request, {
    signal: abortSignal,
  });
}
