import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  ReactElement,
  useMemo,
} from 'react';
import Styles from './styles/Tree.module.scss';
import { Button, Checkbox, Icon, SearchBox, Tooltip } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import { uniq } from 'lodash';
import SecondaryIconButton from './SecondaryIconButton';
import PrimaryIconButton from './PrimaryIconButton';

type TNodeType = string | number;

interface ITreeContextData {
  expand: boolean;
  collapsed: boolean;
  selectedNodeKey: string;
}

interface TreeContextType {
  contextValue: ITreeContextData;
  setTreeViewContext: (expand: boolean, collapsed: boolean) => void;
  setSelected: (key: string) => void;
}

export interface NodeAction {
  action: string;
  actionTitle: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionData?: any;
}

export interface NodeData<TNodeType> {
  id: number | string;
  type: TNodeType;
  title: string;
  key: string;
  path: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export interface ITreeNodeData<TNodeType> {
  /**
   * node id
   */
  id: number | string;
  /**
   * tree path
   */
  path: string;
  /**
   * node title will display
   */
  title: string | React.ReactNode;
  /**
   * Node Type
   */
  type: TNodeType;
  /**
   * React key
   */
  key: string;
  /**
   * Disables the treeNode
   */
  disabled?: boolean;
  /**
   * Set whether the treeNode can be selected,
   * default = true
   * */
  selectable?: boolean;
  /**
   * Shows the icon before a TreeNode's title
   * */
  showIcon?: boolean;
  /**
   * node actions
   */
  listActions?: NodeAction[];
  /**
   * Shows a connecting line
   * default = false
   */
  showLine?: boolean;
  /**
   * Child nodes
   */
  childrenNodes: ITreeNodeData<TNodeType>[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;

  searchFound?: boolean;
  titleStr?: string;
  className?: string;
  showCheckbox?: boolean;
  onSelectCheckbox?: (id: number | string, checked: boolean, selectedCheckboxes: (number | string)[]) => void;
  customIcon?: ReactElement;
}

export interface ITreeViewProps<TNodeType> {
  /**
   * Tree view title will display on the top bar.
   */
  treeName?: string;
  /**
   * Tree view data
   */
  treeData: ITreeNodeData<TNodeType>[];
  /**
   * node actions
   */
  rootAction?: NodeAction;
  /**
   * ClassName on the root element
   */
  rootClassName?: string;
  /**
   * Callback function for when the user clicks a treeNode
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onSelect: (value: NodeData<TNodeType>) => void;
  /**
   * Callback function when action button
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onAction?: (action: NodeAction, value?: NodeData<TNodeType>) => void;

  selectedNodeKey?: string;

  showSearchFilter?: boolean;
}

export interface ITreeProps<TNodeType> {
  refs?: React.MutableRefObject<HTMLLIElement[]>;
  nodes: ITreeNodeData<TNodeType>[];
  /**
   * ClassName on the root element
   */
  level?: number;
  /**
   * Callback function for when the user clicks a treeNode
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onSelect: (value: NodeData<TNodeType>) => void;
  /**
   * Callback function for when the user clicks on action button
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onAction?: (action: NodeAction, value: NodeData<TNodeType>) => void;
  expandedKeys: string[];
  setExpandedKeys: (keys: string[]) => void;
  showSearchBox: boolean;
  clearSearchBox?: () => void;
  handleSelectedCheckboxes?: (node: ITreeNodeData<TNodeType>) => void;
  selectedCheckboxes: (number | string)[];
}

export interface ITreeNodeProps<TNodeType> {
  refs?: React.MutableRefObject<HTMLLIElement[]>;
  data: ITreeNodeData<TNodeType>;
  /**
   * Callback function for when the user clicks a node title
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onClick: (data: NodeData<TNodeType>) => void;
  /**
   * Callback function when action button
   * @param value
   * @returns value: NodeData<TNodeType>
   */
  onAction?: (action: NodeAction, value: NodeData<TNodeType>) => void;
  expandedKeys: string[];
  setExpandedKeys: (keys: string[]) => void;
  showSearchBox: boolean;
  clearSearchBox?: () => void;
  className?: string;
  selectedCheckboxes: (number | string)[];
  handleSelectedCheckboxes?: (node: ITreeNodeData<TNodeType>) => void;
}

const TreeNode: FC<ITreeNodeProps<TNodeType>> = (props) => {
  const {
    refs,
    data,
    onClick,
    onAction,
    expandedKeys,
    setExpandedKeys,
    showSearchBox,
    clearSearchBox,
    className,
    selectedCheckboxes,
    handleSelectedCheckboxes,
  } = props;
  const [showChildren, setShowChildren] = useState(false);
  const nodeLevel = data.path.split('/').length;
  const { contextValue, setTreeViewContext, setSelected } = useContext(TreeViewContext) as TreeContextType;

  const spanRefs = useRef<HTMLSpanElement[]>([]);
  const [showTooltips, setShowTooltips] = useState<string[]>([]);

  const handleShowTooltip = useCallback((label: string) => {
    const items: string[] = [];

    const element = spanRefs.current[label];
    if (element && element.scrollWidth > element.clientWidth) items.push(label);

    setShowTooltips(items);
  }, []);

  useEffect(() => {
    if (showSearchBox)
      setTimeout(() => {
        const expand = expandedKeys?.includes(data.key) ?? false;
        setShowChildren(expand);
      });
  }, [expandedKeys, showSearchBox, data]);

  useEffect(() => {
    if (contextValue.collapsed) {
      setShowChildren(false);
    } else if (contextValue.expand) {
      setShowChildren(true);
    }
  }, [contextValue]);

  const ref = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    const currentRef = ref.current!;

    const title = data.title?.toString() ?? '';
    currentRef.addEventListener('mouseover', () => handleShowTooltip(title));
    currentRef.addEventListener('mouseout', () => handleShowTooltip(title));
    return () => {
      currentRef.removeEventListener('mouseover', () => handleShowTooltip(title));
      currentRef.removeEventListener('mouseout', () => handleShowTooltip(title));
    };
  }, [data.title, handleShowTooltip]);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    if (data.disabled) return;
    const isExpanded = expandedKeys.includes(data.key);
    const newExpandedKeys = isExpanded ? expandedKeys.filter((key) => key !== data.key) : [...expandedKeys, data.key];
    setExpandedKeys(newExpandedKeys);
    setShowChildren(!showChildren);
    setTreeViewContext(false, false);
  };

  const handleSelectedNode = (node) => {
    if (!node.selectable || node.key === contextValue.selectedNodeKey) return;
    setSelected(node.key);
    onClick({ ...node, title: node?.titleStr ?? node?.title ?? '' });
  };

  const handleActionBtnClick = (action, value) => {
    setSelected('');
    onAction?.(action, value);
  };

  const renderNodeAction = (value: NodeData<TNodeType>, actions: NodeAction[]) => {
    return (
      <div className={Styles.nodeActions}>
        {actions.map((atc: NodeAction) => (
          <Button
            key={`${value.key}-${atc.action}`}
            size="small"
            label={atc.actionTitle}
            onClick={(e) => {
              e.preventDefault();
              handleActionBtnClick(atc, value);
            }}
            type="text"
            icon="add_circle"
            cssClass={Styles.buttonLink}
          />
        ))}
      </div>
    );
  };

  const hasTooltip = (label: string) => {
    return showTooltips.includes(label);
  };

  const expandOrCollapseButton = () =>
    (!!data.childrenNodes.length || data.listActions?.length) && !data.disabled ? (
      <PrimaryIconButton
        className={Styles.expandIcon}
        icon={showChildren ? 'expand_less' : 'expand_more'}
        onClick={(e) => handleExpandClick(e)}
      />
    ) : (
      <></>
    );

  const checkBoxTitle = data.title?.toString() ?? '';
  return (
    <li
      ref={(el) => {
        if (!refs) return;
        refs.current[data.key] = el;
      }}
      className={classNames(
        Styles.nodeWrapper,
        className,
        { [Styles.root]: nodeLevel === 1 },
        { [Styles.expand]: showChildren },
        { [Styles.showLine]: data.showLine },
        { [Styles.disabled]: data.disabled }
      )}>
      <button hidden id={`${data.key}-${data.title}-${data.id}`} onClick={() => handleSelectedNode(data)} />
      <label
        htmlFor={`${data.key}-${data.title}-${data.id}`}
        ref={ref}
        className={classNames(
          Styles.nodeItem,
          { [Styles.root]: nodeLevel === 1 },
          { [Styles.disabled]: data.disabled },
          { [Styles.selected]: data.key === contextValue.selectedNodeKey }
        )}>
        {data.showCheckbox ? (
          <div className={Styles.wrapCheckbox}>
            <Checkbox
              indeterminate={!!data.childrenNodes.length && isAnyChildNodeChecked(data, selectedCheckboxes)}
              checked={isCheckboxSelected(data.id, selectedCheckboxes)}
              cssClass={Styles.checkBox}
              onChange={() => {
                handleSelectedCheckboxes?.(data);
              }}
            />
            {hasTooltip(checkBoxTitle) ? (
              <Tooltip
                show={checkBoxTitle}
                cssClass={Styles.checkBoxLabel}
                defaultUp={true}
                padding={15}
                offset={{ left: -(200 + nodeLevel * 40), top: -90 }}>
                <span ref={(el) => (spanRefs.current[checkBoxTitle] = el)} className={Styles.checkBoxLabel}>
                  {checkBoxTitle}
                </span>
              </Tooltip>
            ) : (
              <div className={Styles.checkBoxLabel}>
                <span ref={(el) => (spanRefs.current[checkBoxTitle] = el)} className={Styles.checkBoxLabel}>
                  {checkBoxTitle}
                </span>
              </div>
            )}
          </div>
        ) : (
          <span className={Styles.nodeTitle}>{data.title}</span>
        )}
        {data.customIcon ? <div>{data.customIcon}</div> : expandOrCollapseButton()}
      </label>

      {(!!data.childrenNodes.length || data.listActions?.length) && showChildren && (
        <>
          {data.listActions && (
            <div
              className={classNames(Styles.actionWrapper, { [Styles.showLine]: data.showLine })}
              style={{ paddingLeft: `${(nodeLevel ?? 0) * 8}px` }}>
              {renderNodeAction(
                {
                  id: data.id,
                  key: data.key,
                  path: data.path,
                  title: data.titleStr ?? data.title?.toString() ?? '',
                  type: data.type,
                },
                data.listActions
              )}
            </div>
          )}
          <Tree
            refs={refs}
            nodes={data.childrenNodes}
            level={nodeLevel + 1}
            onSelect={(node) => handleSelectedNode(node)}
            onAction={onAction}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
            showSearchBox={showSearchBox}
            clearSearchBox={clearSearchBox}
            selectedCheckboxes={selectedCheckboxes}
            handleSelectedCheckboxes={handleSelectedCheckboxes}
          />
        </>
      )}
    </li>
  );
};

const Tree: FC<ITreeProps<TNodeType>> = (props) => {
  const {
    refs,
    nodes,
    onSelect,
    onAction,
    expandedKeys,
    showSearchBox,
    clearSearchBox,
    handleSelectedCheckboxes,
    selectedCheckboxes,
  } = props;
  const [expand] = useState(false);

  return (
    <ul
      className={classNames(Styles.treeView, { [Styles.ready]: expand })}
      style={{ paddingLeft: `${(props.level ?? 0) * 8}px` }}>
      {nodes.map((node: ITreeNodeData<TNodeType>) => (
        <TreeNode
          data={node}
          key={node.key}
          onClick={onSelect}
          onAction={onAction}
          expandedKeys={expandedKeys}
          setExpandedKeys={props.setExpandedKeys}
          showSearchBox={showSearchBox}
          clearSearchBox={clearSearchBox}
          className={node.className}
          handleSelectedCheckboxes={handleSelectedCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          refs={refs}
        />
      ))}
    </ul>
  );
};

const TreeViewContext = createContext<TreeContextType | null>(null);

const getParentKey = (key: string, tree: ITreeNodeData<TNodeType>[]): string => {
  let parentKey: string;
  for (const element of tree) {
    const node = element;
    if (node.childrenNodes) {
      if (node.childrenNodes.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.childrenNodes)) {
        parentKey = getParentKey(key, node.childrenNodes);
      }
    }
  }
  return parentKey!;
};

const getAllChildNodeIds = (node: ITreeNodeData<TNodeType>, ignoreItemHasShowCheckboxIsFalse = true) => {
  const currentId = ignoreItemHasShowCheckboxIsFalse && !node.showCheckbox ? null : node.id;
  if (!node.childrenNodes.length) return [currentId];

  const result = [...node.childrenNodes.map((t) => getAllChildNodeIds(t)), currentId].flatMap((t) => t);
  return result.filter((t) => !!t);
};

const getAllParentNodes = (treeData, id: string | number, ignoreItemHasShowCheckboxIsFalse = true) => {
  if (treeData.id === id) return [];
  if (!treeData.childrenNodes?.length && !Array.isArray(treeData)) return;

  const childrenNodes = Array.isArray(treeData) ? treeData : treeData.childrenNodes;
  for (const child of childrenNodes) {
    const result = getAllParentNodes(child, id);
    if (!result) continue;
    if (treeData.id) result.unshift(treeData);
    return ignoreItemHasShowCheckboxIsFalse ? result.filter((t) => t.showCheckbox) : result;
  }
};

const isCheckboxSelected = (id: string | number, selectedCheckboxes: (number | string)[]) => {
  return !!selectedCheckboxes.find((l) => l === id);
};

const isAnyChildNodeChecked = (node: ITreeNodeData<TNodeType>, selectedCheckboxes: (number | string)[]) => {
  const allChildNodeIds = getAllChildNodeIds(node);
  if (allChildNodeIds.every((t) => selectedCheckboxes.includes(t))) return false;

  const someChecked = allChildNodeIds.some((t) => selectedCheckboxes.includes(t));
  return someChecked;
};

const TreeView: FC<ITreeViewProps<TNodeType>> = (props) => {
  const { treeName, rootClassName, rootAction, treeData, selectedNodeKey, onSelect, onAction, showSearchFilter } =
    props;

  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [dataList, setDataList] = useState<{ key: string; title: string }[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<(string | number)[]>([]);
  const [activeSearchCount, setActiveSearchCount] = useState(0);
  const [searchTreeNodesList, setSearchTreeNodesList] = useState<{ key: number | string; nodeKey: number | string }[]>(
    []
  );
  const [treeNodesList, setTreeNodesList] = useState<ITreeNodeData<TNodeType>[]>([]);
  const refs = useRef<HTMLLIElement[]>([]);

  const setCheckboxes = (node: ITreeNodeData<TNodeType>, checked: boolean, selectedIds: (string | number)[]) => {
    setSelectedCheckboxes(selectedIds);

    if (node.onSelectCheckbox) node.onSelectCheckbox(node.id, checked, selectedIds);
  };

  const handleSelectedCheckboxes = (node: ITreeNodeData<TNodeType>) => {
    const allChildNodeIds = getAllChildNodeIds(node);
    let checked = !isCheckboxSelected(node.id, selectedCheckboxes);
    if (node.childrenNodes.length) {
      if (allChildNodeIds.every((t) => selectedCheckboxes.includes(t))) checked = false;
      else if (allChildNodeIds.some((t) => selectedCheckboxes.includes(t))) checked = true;
    }
    const parentItems = getAllParentNodes(treeData, node.id);

    if (checked) {
      let selected = uniq([...selectedCheckboxes, ...allChildNodeIds]);
      if (parentItems.length) {
        parentItems.reverse().forEach((parentItem) => {
          const childNodeIds = getAllChildNodeIds(parentItem).filter((id) => id !== parentItem.id);
          const someChildSelected = childNodeIds.some((t) => selected.includes(t));
          if (!someChildSelected) {
            selected = selected.filter((t) => t !== parentItem.id);
            return;
          }

          if (!selected.includes(parentItem.id)) selected.push(parentItem.id);
        });
      }
      setCheckboxes(node, checked, selected);

      return;
    }

    const removedIds = [node.id, ...allChildNodeIds];
    let temp = selectedCheckboxes.filter((t) => !removedIds.includes(t));

    parentItems.reverse().forEach((parentItem) => {
      const childNodeIds = getAllChildNodeIds(parentItem).filter((id) => id !== parentItem.id);
      const someChildSelected = childNodeIds.some((t) => temp.includes(t));
      if (someChildSelected) return;

      temp = temp.filter((t) => t !== parentItem.id);
    });

    setCheckboxes(node, checked, temp);
  };

  const generateList = useMemo(() => {
    const generateNewList = (data: ITreeNodeData<TNodeType>[]) => {
      const newDataList: { key: string; title: string }[] = [];
      for (const node of data) {
        const { key, title } = node;
        if (!newDataList.some((t) => t.key === key)) newDataList.push({ key, title: title?.toString() ?? '' });
        if (node.childrenNodes) {
          newDataList.push(...generateNewList(node.childrenNodes));
        }
      }
      return newDataList;
    };

    return generateNewList(treeData);
  }, [treeData]);

  const getParentOfParentKeys = useCallback(
    (parentKeys: string[]) => {
      let newExpandedKeys: string[] = [];
      const newParentOfParentKeys = uniq(
        parentKeys.map((key) => getParentKey(key, treeData)).filter((t) => t && !newExpandedKeys.includes(t))
      );

      newExpandedKeys = [...newParentOfParentKeys, ...newExpandedKeys];
      if (newParentOfParentKeys.length)
        newExpandedKeys = [...newExpandedKeys, ...getParentOfParentKeys(newParentOfParentKeys)];
      return newExpandedKeys;
    },
    [treeData]
  );
  const [allNodeKeys, setAllNodeKeys] = useState<string[]>([]);

  const getAllExpandedKeys = useMemo(
    () => () => {
      let newExpandedKeys = uniq(dataList.map((item) => getParentKey(item.key, treeData)).filter((t) => t));
      newExpandedKeys = [...newExpandedKeys, ...getParentOfParentKeys(newExpandedKeys)];
      setAllNodeKeys([...newExpandedKeys]);
      setExpandedKeys([...newExpandedKeys]);
    },
    [dataList, treeData, getParentOfParentKeys]
  );

  useEffect(() => {
    if (!showSearchFilter) return;
    setDataList(generateList);
  }, [showSearchFilter, treeData, generateList]);

  const [treeContext, setTreeContext] = useState<ITreeContextData>({
    collapsed: true,
    expand: false,
    selectedNodeKey: '',
  });

  useEffect(() => {
    if (!selectedNodeKey || treeContext.selectedNodeKey === selectedNodeKey) return;
    const newState = { ...treeContext, selectedNodeKey: selectedNodeKey };
    setTreeContext(newState);
  }, [selectedNodeKey, treeContext]);

  const generateNodeList = useCallback(
    (
      searchTextLowerCase: string,
      data: ITreeNodeData<TNodeType>[],
      key?: number | string
    ): ITreeNodeData<TNodeType>[] => {
      let matchCount = 0;

      const nodeList = data.map((item) => {
        let title = item.title;
        const strTitleLowerCase = `${title}`.toLowerCase();
        const index = strTitleLowerCase.indexOf(searchTextLowerCase);
        const searchFound = index > -1 && !!title;
        if (searchFound) {
          matchCount = matchCount + 1;
          const strTitle = title?.toString() ?? '';
          const searchTextLength = index + searchTextLowerCase.length;
          const beforeStr = strTitle.substring(0, index);
          const matchStr = strTitle.substring(index, searchTextLength);
          const afterStr = strTitle.slice(searchTextLength);

          title = (
            <span>
              {beforeStr}
              {(key && item.key === key) || (!key && matchCount === 1) ? (
                <span className={Styles.treeSearchSelectedValue}>{matchStr}</span>
              ) : (
                <span className={Styles.treeSearchValue}>{matchStr}</span>
              )}
              {afterStr}
            </span>
          );
        }

        if (item.childrenNodes) {
          return {
            ...item,
            title,
            titleStr: item.title?.toString(),
            searchFound,
            childrenNodes: generateNodeList(searchTextLowerCase, item.childrenNodes, key),
          };
        }

        return { ...item, title, titleStr: item.title?.toString(), searchFound };
      });

      return nodeList;
    },
    []
  );

  const loopDeep = useMemo(
    () => (data: ITreeNodeData<TNodeType>[], key?: number | string) => {
      return generateNodeList(`${searchText}`.toLowerCase().trim(), data, key);
    },
    [searchText, generateNodeList]
  );

  const getSearchedNodes = useCallback((data: ITreeNodeData<TNodeType>[], searchedCount: number) => {
    let searchNodesList: { key: number | string; nodeKey: number | string }[] = [];
    let matchCount = searchedCount;
    for (const node of data) {
      if (node.searchFound) {
        matchCount = matchCount + 1;
        searchNodesList.push({ key: node.id, nodeKey: node.key });
      }
      const founds = getSearchedNodes(node.childrenNodes, matchCount++);
      searchNodesList = [...searchNodesList, ...founds];
    }
    return searchNodesList;
  }, []);

  useEffect(() => {
    setActiveSearchCount(0);
    if (!searchText) {
      setTreeNodesList(treeData);
      return;
    }
    const nodeList = loopDeep(treeData);
    setTreeNodesList(nodeList);

    const searchNodes = getSearchedNodes(nodeList, 0);
    setSearchTreeNodesList(searchNodes);
  }, [searchText, treeData, loopDeep, getSearchedNodes]);

  const setTreeViewContext = useCallback(
    (c: boolean, e: boolean) => {
      const newState = { collapsed: c, expand: e, selectedNodeKey: treeContext.selectedNodeKey };
      setTreeContext(newState);
    },
    [treeContext]
  );

  const setSelected = useCallback(
    (selectedKey: string) => {
      const newState = { ...treeContext, selectedNodeKey: selectedKey };
      setTreeContext(newState);
    },
    [treeContext]
  );

  const handleSelectedNode = (node) => {
    node.title = node?.titleStr ?? node?.title ?? '';
    onSelect(node);
  };

  const checkNavigateToFirstItem = (setActiveValue: number) => {
    if (searchTreeNodesList.length < setActiveValue) {
      // if the search count max then set to back to the first item
      setActiveSearchCount(1);
      onNavigateChange(searchTreeNodesList[0].key);
    }
  };

  useEffect(() => {
    const enterKey = 'Enter';
    const handleKeyPressEnter = (e) => {
      setTimeout(() => {
        if (e.key === enterKey) {
          e.preventDefault();
          if (treeContext.collapsed || expandedKeys.length !== allNodeKeys.length) {
            getAllExpandedKeys();
            setTreeViewContext(false, true);
          }
          const setActiveValue = activeSearchCount + 1;
          if (searchTreeNodesList.length > 0) {
            if (searchTreeNodesList.length >= setActiveValue) {
              setActiveSearchCount(setActiveValue);
              onNavigateChange(searchTreeNodesList[setActiveValue - 1].key);
            }
            checkNavigateToFirstItem(setActiveValue);
          }
        }
      });
    };
    window.addEventListener('keypress', handleKeyPressEnter, false);
    return () => {
      window.removeEventListener('keypress', handleKeyPressEnter);
    };
  });

  const searchBoxRef = useCallback((node) => {
    if (node) node.focus();
  }, []);

  const renderTopControl = () => {
    const handleExpandClick = () => {
      if (treeContext.collapsed || expandedKeys.length !== allNodeKeys.length) {
        getAllExpandedKeys();
      } else {
        setExpandedKeys([]);
      }
      setTreeViewContext(!treeContext.collapsed, treeContext.collapsed);
    };

    return (
      <div className={Styles.topBar}>
        <span className={Styles.topBarTitle}>{treeName}</span>
        <div className={Styles.topBarIcon}>
          <Icon type={treeContext.collapsed ? 'expand_more' : 'expand_less'} onClick={() => handleExpandClick()} />
        </div>
      </div>
    );
  };
  const onNavigateScroll = (id: string) => {
    if (!refs.current[id]) return;
    refs.current[id].scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const onNavigateChange = (nodeId: number | string) => {
    const id = searchTreeNodesList.find((r) => r.key == nodeId)?.nodeKey;
    if (id) {
      // Replace that condition for search ()
      if (treeContext.collapsed || expandedKeys.length !== allNodeKeys.length) {
        getAllExpandedKeys();
        setTreeViewContext(false, true);
        setTimeout(() => {
          renderSearchNode(id);
          onNavigateScroll(id.toString());
        });
      } else {
        renderSearchNode(id);
        onNavigateScroll(id.toString());
      }
    }
  };

  const onChange = (value: string) => {
    if (!value) {
      clearSearchBox();
      return;
    }
    setSearchText(value);
  };

  const renderSearchControl = () => {
    return (
      <div className={Styles.topBar}>
        <SearchBox
          ref={searchBoxRef}
          defaultValue={searchText}
          hideSearchButton
          onSearch={(value) => onChange(value)}
          onClear={() => onChange('')}
          placeholder={'Search for Keyword...'}
        />
        {searchText && (
          <>
            <span className={Styles.searchCount}>
              {activeSearchCount}/{searchTreeNodesList.length}
            </span>

            <SecondaryIconButton
              disabled={!searchTreeNodesList.length}
              icon={'navigate_before'}
              className={Styles.buttonNavigate}
              onClick={() => {
                const setActiveValue = activeSearchCount - 1;
                if (setActiveValue < searchTreeNodesList.length && setActiveValue > 0) {
                  setActiveSearchCount(setActiveValue);
                  onNavigateChange(searchTreeNodesList[setActiveValue - 1].key);
                }
                if (setActiveValue <= 0) {
                  setActiveSearchCount(searchTreeNodesList.length);
                  onNavigateChange(searchTreeNodesList[searchTreeNodesList.length - 1].key);
                }
              }}
            />
            <SecondaryIconButton
              disabled={!searchTreeNodesList.length}
              icon={'navigate_next'}
              className={Styles.buttonNavigate}
              onClick={() => {
                const setActiveValue = activeSearchCount + 1;
                if (searchTreeNodesList.length >= setActiveValue) {
                  setActiveSearchCount(setActiveValue);
                  onNavigateChange(searchTreeNodesList[setActiveValue - 1].key);
                }
                checkNavigateToFirstItem(setActiveValue);
              }}
            />
          </>
        )}
      </div>
    );
  };

  const clearSearchBox = () => {
    setSearchText('');
    setActiveSearchCount(0);
  };

  const renderRootAction = () => {
    if (!rootAction) return;
    return (
      <div className={classNames(Styles.nodeActions, Styles.rootAction)}>
        <Button
          size="small"
          label={rootAction?.actionTitle}
          onClick={(e) => {
            e.preventDefault();
            onAction?.(rootAction);
          }}
          type="text"
          icon="add_circle"
          cssClass={Styles.buttonLink}
        />
      </div>
    );
  };

  const renderSearchNode = (id: number | string | undefined) => {
    if (!id) return;
    const nodeList = loopDeep(treeData, id);
    setTreeNodesList(nodeList);
  };
  return (
    <TreeViewContext.Provider
      value={useMemo(
        () => ({ contextValue: treeContext, setTreeViewContext, setSelected }),
        [treeContext, setTreeViewContext, setSelected]
      )}>
      <div className={classNames(Styles.wrapper, rootClassName)}>
        {treeName && renderTopControl()}
        {showSearchFilter && renderSearchControl()}
        {renderRootAction()}
        <Tree
          refs={refs}
          nodes={treeNodesList}
          onSelect={(node) => handleSelectedNode(node)}
          onAction={onAction}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          showSearchBox={true}
          clearSearchBox={clearSearchBox}
          handleSelectedCheckboxes={handleSelectedCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
        />
      </div>
    </TreeViewContext.Provider>
  );
};

export default TreeView;
