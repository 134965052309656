import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';

export class SupersedeFileFilter {
  title?: string;
  originalFilename?: string;
  date1?: string | null;
  date2?: string | null;
  operator?: number | null;

  setDefaultFilter(): this {
    this.operator = 1;
    return this;
  }

  setFileName(fileName?: string) {
    this.title = fileName;
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setExtraConditions(json?: any): this {
    this.title = json?.title;
    this.originalFilename = json?.originalFilename;
    this.date1 = json?.uploaded?.startDate ?? null;
    this.date2 = json?.uploaded?.endDate ?? null;
    this.operator = json?.uploaded?.operator ?? null;
    return this;
  }
}

export interface ISupersedeFileContainerBase {
  fileContainerId: number;
  fileContainerName: string;
  suitabilityId: number | null;
  suitabilityTitle: string | null;
  suitabilityCode: string | null;
  isLocked: boolean;
  fileContainerRevisionId: number;
  releasedFileContainerId: number | null;
  releasedFileContainerTitle: string | null;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
  supersedeFiles: ISupersedeFile[];
}

export interface ISupersedeFile {
  fileId: number;
  extension: string;
  originalFilename: string;
  uploadedDate: Date;
  size: number;
}

export interface ISupersedeFileResult {
  pageResult: IPagedResponse<ISupersedeFileContainerBase>;
}

export interface IGetSupersedeFile extends SupersedeFileFilter {
  taskTeamId: number;
  fileContainerStateId: number;
  uploadedFilenames: string[];
  searchKeyword?: string;
  filterByFileContainerName?: string;
  filterByOriginalFilename?: string;
  page?: number;
}

export async function getSupersedeFileContainers(
  condition: IGetSupersedeFile,
  abortSignal?: AbortSignal
): Promise<IPagedResponse<ISupersedeFileContainerBase>> {
  const result = await serverAxios.post<{ pageResult: IPagedResponse<ISupersedeFileContainerBase> }>(
    'api/cms/getSupersedeFileContainers',
    condition,
    {
      signal: abortSignal,
    }
  );
  return result.data?.pageResult ?? [];
}
