import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import Style from './styles/HistoryContainerFileUpdate.module.scss';
import { IFileContainerUpdateHistory } from '../../../api/authenticated/cms/getFileHistory';
import { FileContainerUpdateHistoryType } from '../../../common/enums/FileContainerUpdateHistoryType';

export interface IHistoryContainerFileUpdateProps {
  item: IFileContainerUpdateHistory;
}

const HistoryContainerFileUpdate: FC<IHistoryContainerFileUpdateProps> = ({ item }) => {
  return (
    <>
      <div className={Style.title}>
        <div className={Style.currentValue}>{item.currentValue}</div>
        <div className={Style.containerMessage}>
          <Icon name="edit" className={Style.icon} />
          {item.fileContainerUpdateHistoryTypeId === FileContainerUpdateHistoryType.MetadataUpdate &&
            (item.previousValue ? (
              <p className={Style.message}>{item.metadataFieldTitle} changed from</p>
            ) : (
              <p className={Style.message}>{item.metadataFieldTitle} added</p>
            ))}
          {item.fileContainerUpdateHistoryTypeId === FileContainerUpdateHistoryType.FileContainerTitleUpdate && (
            <p className={Style.message}>{item.metadataFieldTitle} FileName changed from</p>
          )}
        </div>

        {item.previousValue && <p className={Style.previousValue}>{item.previousValue}</p>}
      </div>
      <div className={Style.user}>{item.updatedByUserName}</div>
    </>
  );
};

export default observer(HistoryContainerFileUpdate);
