import { UserManagementTypeEnum } from '../../../enums/UserManagementTypeEnum';
import { serverAxios } from '../api';

export interface IGetTransmittal {
  transmittal: ITransmittal;
  navbarKey: string;
}

export interface ITransmittal {
  id: number;
  projectNumber: string;
  projectTitle: string;
  title: string;
  taskTeamId: number;
  taskTeamTitle: string;
  taskTeamCode: string;
  deliveryTeamTitle: string;
  transmittalTypeId: number;
  transmittalTypeTitle: string;
  typeNamingCode: string;
  transmittalReasonId: number;
  transmittalReasonTitle: string;
  reasonNamingCode: string;
  createdByUserId: number;
  createdByEmail: string;
  createdByName: string;
  createdByInitials: string;
  createdDate: Date;
  closed: boolean;
  closedDate: Date | null;
  dueDate: Date | null;
  flagged: boolean;
  transmittalVisibilityTaskTeamUsers: IUser[];
  transmittalVisibilityExternalUsers: IUser[];
  transmittalVisibilityAppointingPartyUsers: IAppointingPartyUser[];
  transmittalVisibilityAppointingParties: IAppointingParty[];
  transmittalVisibilityDeliveryTeams: IDeliveryTeam[];
  transmittalVisibilityTaskTeams: ITaskTeam[];
  transmittalVisibilityAllUsers: IVisibilityUsers[];
  transmittalMessages: ITransmittalMessage[];
  overdue: boolean;
  lastUpdated: Date;
  allNotifiedUsers: IUser[];
  canRespond: boolean;
}

export interface ITransmittalListItem extends ITransmittal {
  checked: boolean;
  totalVisibleUser: number;
}

export interface IAppointingParty {
  id: number;
  title: string;
  namingCode: string;
  userCount: number;
}

export interface IDeliveryTeam {
  id: number;
  title: string;
  namingCode: string;
  userCount: number;
}

export interface IVisibilityUsers {
  userId: number;
  userName: string;
  email: string;
  initials: string;
  groupId: number;
  groupCode: string;
  groupTitle: string;
  userManagementType: string;
}

export interface ITaskTeam {
  id: number;
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  deliveryTeamCode: string;
  title: string;
  namingCode: string;
  userCount: number;
}

export interface ITransmittalMessage {
  id: number;
  createdByUserId: number;
  createdByUserName: string;
  createdByUserEmail: string;
  userInitials: string;
  taskTeamId: number | null;
  taskTeamTitle: string | null;
  taskTeamTransmittalCode: string | null;
  deliveryTeamId: number | null;
  deliveryTeamTitle: string | null;
  deliveryTeamTransmittalCode: string | null;
  subject: string;
  message: string;
  createdDate: Date;
  transmittalMessageNotifyTaskTeamUsers: IUser[];
  transmittalMessageNotifyExternalUsers: IUser[];
  transmittalMessageNotifyAppointingPartyUsers: IAppointingPartyUser[];
  transmittalMessageReleasedFiles: IReleasedFile[];
  transmittalMessageAttachmentFiles: IAttachmentFile[];
  selected?: boolean;
}

export interface IReleasedFile {
  id: number;
  fileRevisionId: number;
  containerFileId: number | null;
  releasedFileId: number | null;
  title: string;
  originalFilename: string;
  extension: string;
  fileContainerStateId: number;
  uploadedSize: number;
  uploadedByUserName: string;
  uploadedDate: Date;
  createdDate: Date;
  formattedRevision: string;
  suitabilityCode: string;
  isSuitabilityRefCode: boolean | null;
  suitabilityTitle: string;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
  hasDocumentViewer: boolean;
  isForgeFile: boolean;
  deliveryTeamTitle: string;
  taskTeamTitle: string;
  containerFiles: IContainerFile[];
}

export interface ITransmittalContentFile extends IReleasedFile {
  transmittalId: number;
  transmittalMessageId: number;
}
export interface IContainerFile {
  fileContainerId: number;
  containerFileId: number;
  releasedFileId: number;
  originalFilename: string;
  uploadedSize: number;
  hasDocumentViewer: boolean;
  isForgeFile: boolean;
  fileExtension?: string;
  native: boolean | null;
  sharePointReleasedFileId: number | null;
}

export interface IAttachmentFile {
  id: number;
  title: string;
  originalFilename: string;
  uploadedSize: number;
}

export interface ITransmittalAttachmentFile extends IAttachmentFile {
  transmittalId: number;
  transmittalMessageId: number;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  initials: string;
  taskTeamId: number;
  taskTeamTitle: string;
  deliveryTeamTitle?: string;
}

export interface IAppointingPartyUser {
  id: number;
  name: string;
  email: string;
  appointingPartyId: number;
  initials: string;
}

export interface IGroupTeamUsers {
  taskTeamId: string | number;
  taskTeamTitle: string;
  users: IUser[];
  type: UserManagementTypeEnum;
  deliveryTeamTitle?: string;
  deliveryTeamCode?: string;
}
export async function getTransmittal(
  transmittalTitle: string,
  projectNumber: string | null,
  abortSignal?: AbortSignal
): Promise<IGetTransmittal | null> {
  const result = await serverAxios.get<IGetTransmittal>(`api/transmittals/${projectNumber}/${transmittalTitle}`, {
    signal: abortSignal,
  });
  return result.data;
}
