import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TransmittalVisibleModal.module.scss';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import Modal from '../shared/Modal';
import CloseButton from '../shared/CloseButton';
import Icon from '../shared/Icon';
import { IGroupTeamUsers, IUser } from '../../api/authenticated/transmittals/getTransmittal';
import { Accordion, AccordionPanel } from '@aurecon-creative-technologies/styleguide';
import UserCircle from '../shared/UserCircle';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import ModalActions from '../shared/ModalActions';
import SecondaryButton from '../shared/SecondaryButton';
import { UserManagementTypeEnum } from '../../enums/UserManagementTypeEnum';

export interface TransmittalVisibleModalProps {
  data: {
    externalUsers: IUser[];
    taskTeams: IGroupTeamUsers[];
  };
}

const TransmittalVisibleModal: FC<TransmittalVisibleModalProps> = (props) => {
  const handleCloseModalClick = () => {
    TransmittalDetailsStore.modalClose();
  };

  const externalGroup = props.data.externalUsers.length
    ? [
        {
          taskTeamId: 'ExternalGroup',
          taskTeamTitle: 'External Users',
          users: props.data.externalUsers,
          type: UserManagementTypeEnum.ExternalUser,
        } as IGroupTeamUsers,
      ]
    : [];
  const userGroups = [...props.data.taskTeams, ...externalGroup];

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <div className={Style.headerTitle}>
          <Icon name="visibility" className={Style.headerIcon} />
          <span>Visible to</span>
        </div>
        <p>Giving visibility to these users will allow them to view but no actions will be required.</p>
        <div className={Style.modalContainer}>
          <div>
            <Accordion
              verticalPanelGap="24px"
              headingBgColour="#F2F2F2"
              headingFontColour="#000000"
              panelBgColour="#FAFAFA"
              headingIconColour="#000000"
              activePanelIds={Array.from(TransmittalDetailsStore.activeTaskTeamPanelIds)}
              onPanelToggle={TransmittalDetailsStore.handleTaskTeamPanelToggle}>
              {userGroups.map((team) => (
                <AccordionPanel
                  key={team.taskTeamId}
                  label={
                    <div className={Style.accordionPanelTitle}>
                      <Icon name="people" className={Style.teamIcon}></Icon>
                      <span className={Style.accordionPanelTitleLabel}>
                        {team.type === UserManagementTypeEnum.TaskTeam && (
                          <>
                            {team.deliveryTeamTitle} {team.deliveryTeamCode} |{' '}
                          </>
                        )}
                        <strong>{team.taskTeamTitle}</strong>
                      </span>
                    </div>
                  }
                  panelId={team.taskTeamId.toString()}
                  rightLabelContents={<div className={Style.countCircle}>{team.users.length}</div>}>
                  {team.users.map((user) => (
                    <div key={user.id}>
                      <div className={Style.userItem}>
                        <span className={Style.userCircle}>
                          <UserCircle initials={user.initials} />
                        </span>
                        <span className={Style.displayName}>{user.name}</span>
                      </div>
                    </div>
                  ))}
                </AccordionPanel>
              ))}
            </Accordion>
          </div>
        </div>
        <CloseButton onClick={handleCloseModalClick} disabled={false} />
        <ModalActions>
          <SecondaryButton onClick={handleCloseModalClick}>Close</SecondaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TransmittalVisibleModal);
