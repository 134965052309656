import { serverAxios } from '../api';

interface IUpdateClientFileTemplatesRequest {
  clientFileTemplates: IClientFileTemplates[];
}

interface IClientFileTemplates {
  id: number;
  templateFileName: string;
  order: number;
}
export async function updateClientFileTemplates(
  request: IUpdateClientFileTemplatesRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.put(`api/config/client/fileTemplates/update`, request, {
    signal: abortSignal,
  });
  return result.data;
}
