import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, IHeader, Pagination, IColumnMoving } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/TransmittalTable.module.scss';
import TransmittalTableRow from './TransmittalTableRow';
import { SortType } from '../../../common/enums/SortType';
import TransmittalsStore, {
  ActionColumns,
  ITransmittalSearchListItem,
  tableHeaderKeyValueFields,
} from '../TransmittalsStore';
import ListFilter from '../../shared/ListFilter';
import UserTextFilter from '../../shared/UserTextFilter';
import DateFilter from '../../shared/DateFilter';
import { IDateRangeFilter, ITableColumn } from '../../../common/interfaces/TableColumn';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import { classNames } from '../../../utils/miscUtils';

export interface ITransmittalTableProps {
  tableColumns: ITableColumn[];
  tableTransmittals: ITransmittalSearchListItem[];
  onFilter?: (column: string, value: string | IDateRangeFilter) => void;
  onSortColumnChange?: (column: string, direction?: SortType) => void;
}

const unSortingColumns = [
  tableHeaderKeyValueFields.flagged.label,
  tableHeaderKeyValueFields.type.label,
  tableHeaderKeyValueFields.reason.label,
  tableHeaderKeyValueFields.status.label,
  tableHeaderKeyValueFields.subject.label,
  tableHeaderKeyValueFields.totalResponses.label,
  tableHeaderKeyValueFields.totalFiles.label,
];

const TransmittalTable: FC<ITransmittalTableProps> = ({
  tableColumns,
  tableTransmittals,
  onFilter,
  onSortColumnChange,
}) => {
  const [columns, setColumns] = useState(tableColumns);

  const showSelectCheckbox = TransmittalsStore.showSelectCheckbox;
  useEffect(() => {
    const filteredColumns = tableColumns.filter((f) =>
      TransmittalsStore.getAdjustableTableColumns().some((s) => s.id === f.valueField && s.visible)
    );
    setColumns(filteredColumns);
  }, [tableColumns, showSelectCheckbox]);

  const reorder = (data: IColumnMoving) => {
    if (TransmittalsStore.isLoading) {
      return;
    }

    const moveColumn = columns[data.fromIndex];
    if (moveColumn) {
      columns.splice(data.fromIndex, 1);
      columns.splice(data.toIndex, 0, moveColumn);
      setColumns([...columns]);

      const adjustableTableColumns = [...TransmittalsStore.getAdjustableTableColumns()];
      adjustableTableColumns.splice(data.fromIndex, 1);
      adjustableTableColumns.splice(data.toIndex, 0, {
        id: moveColumn.valueField,
        label: moveColumn.label,
        width: moveColumn.width!,
        visible: true,
      });

      TransmittalsStore.setAdjustableTableColumns(adjustableTableColumns);
    }
  };

  const handleResizeTableColumn = (label: string, width: number) => {
    const columns = TransmittalsStore.getAdjustableTableColumns().map((m) => {
      return { ...m, width: m.label === label ? width : m.width };
    });

    TransmittalsStore.setAdjustableTableColumns(columns);
  };

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.listFilter)
      return (
        <ListFilter
          listFilter={tableColumn.listFilter}
          key={tableColumn.valueField}
          onSelectItem={(value) => filterChangeHandler(tableColumn, value ?? '')}
          disabled={TransmittalsStore.isLoading}
        />
      );

    if (tableColumn.textFilter)
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value ?? '')}
          disabled={TransmittalsStore.isLoading}
        />
      );

    if (tableColumn.dateFilter)
      return (
        <DateFilter
          dateFilter={tableColumn.dateFilter}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
          disabled={TransmittalsStore.isLoading}
        />
      );
  };

  const headers = (): IHeader[] =>
    columns.map((tableColumn) => {
      const isUnSortable = unSortingColumns.includes(tableColumn.label);
      const sortDirection = tableColumn.sort ? tableColumn.sort : 'none';
      const isActionColumn = ActionColumns.includes(tableColumn.valueField);
      const displayCheckBox = tableColumn.onCheckbox && TransmittalsStore.showSelectCheckbox ? 'table-cell' : 'none';
      return {
        label: tableColumn.label,
        sort: isUnSortable || isActionColumn ? undefined : sortDirection,
        onSort:
          TransmittalsStore.isLoading || isUnSortable || isActionColumn
            ? undefined
            : (sort) => onSortColumnChange?.(tableColumn.label, sort as SortType),
        filter: getFilterComponent(tableColumn),
        style: {
          ...tableColumn.style,
          display: !isActionColumn || !tableColumn.onCheckbox ? 'table-cell' : displayCheckBox,
        },
        checked: tableColumn.checked,
        onCheckbox: tableColumn.onCheckbox,
        draggable: !isActionColumn,
        resizable: !isActionColumn && !TransmittalsStore.isLoading,
        onResize: (data) => {
          handleResizeTableColumn(data.label, data.width);
        },
        width: tableColumn.width,
        minWidth: tableColumn.minWidth,
      } as IHeader;
    });

  const filterChangeHandler = (tableColumn: ITableColumn, filter: string | IDateRangeFilter) => {
    if (!onFilter) return;
    appInsightsTrackEvent(AppInsightEvent.APPLY_TRANSMITTAL_FILTER, {
      [tableColumn.label]: filter ?? '',
    });
    onFilter(tableColumn.valueField, filter);
  };

  return (
    <>
      <Table
        cssClass={classNames(Style.transmittalTable, [TransmittalsStore.isLoading, Style.tableLoading])}
        headers={headers()}
        breakpoint={10}
        hoverable
        onReorderColumn={reorder}>
        {tableTransmittals.map((row) => (
          <TransmittalTableRow key={row.title} headers={headers()} transmittal={row} tableColumns={columns} />
        ))}
      </Table>

      {!TransmittalsStore.showSelectedItems &&
        TransmittalsStore.pagingMetaData &&
        TransmittalsStore.pagingMetaData.totalCount > 0 && (
          <div className={Style.paginationWrapper}>
            <div className={Style.paginationContainer}>
              <Pagination
                page={TransmittalsStore.pagingMetaData.pageNumber}
                pageCount={TransmittalsStore.pagingMetaData.pageCount}
                onChange={(page: number) => {
                  TransmittalsStore.setCurrentPage(page);
                }}
              />
              <div className={Style.paginationFlexGrow}>
                {TransmittalsStore.pagingMetaData.firstItemOnPage + 1} -{' '}
                {TransmittalsStore.pagingMetaData.lastItemOnPage + 1} of {TransmittalsStore.pagingMetaData.totalCount}{' '}
                Transmittals
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default observer(TransmittalTable);
