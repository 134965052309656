import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, Pill } from '@aurecon-creative-technologies/styleguide';

export interface ITaskTeamTagProps {
  deliveryTeamTitle: string;
  taskTeamTitle: string;
  taskTeamCode: string;
  userCount: number;
}

const TaskTeamTag: FC<ITaskTeamTagProps> = ({ deliveryTeamTitle, taskTeamTitle, taskTeamCode, userCount }) => {
  return (
    <span>
      <Icon type="people" /> {deliveryTeamTitle} |{' '}
      <b>
        {taskTeamTitle} {taskTeamCode}
      </b>{' '}
      <Pill background="light" size="mini" colour={2}>
        {userCount}
      </Pill>
    </span>
  );
};

export default observer(TaskTeamTag);
