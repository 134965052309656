import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import Style from './styles/TransmittalTabContainerActionBar.module.scss';
import ActionBarStore from './ActionBarStore';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import { CSVLink } from 'react-csv';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';
import TransmittalsStore, { ActionColumns } from './TransmittalsStore';
import DropDownMenu from '../shared/DropDownMenu';
import { TransmittalExportOption } from '../../common/enums/ExportOption';
import { TransmittalExportOptionText } from '../../common/constants/ExportOptionText';
import TransmittalsExportCSVLink from './TransmittalsExportCSVLink';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import SelectedTransmittalsLabel from './SelectedTransmittalsLabel';
import ClearFilterLink from '../shared/ClearFilterLink';
import DropDownAdjustableColumns from '../shared/DropDownAdjustableColumns';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import AppStore from '../../stores/AppStore';
import ExportByFileModal from './ExportByFileModal';
import { TabFilter } from './Types';
import { appInsightsTrackEvent } from '../../utils/appInsights';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';

const TransmittalTabContainerActionBar: FC = () => {
  const [showExportByFileModal, setShowExportByFileModal] = useState<boolean>(false);

  useEffect(() => {
    ActionBarStore.setSelectedTab();
  }, []);

  const handleClearFilter = () => {
    TransmittalsStore.resetFilterParameters();
    TransmittalsStore.resetSearchParameters();
    TransmittalsStore.fetchTransmittalsData();
  };

  const exportData = ActionBarStore.getCSVExport();
  const navigate = useNavigate();
  const handleCreateTransmittalClick = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_NEW);
    TransmittalsStore.abortRequests();
    navigate(`${Pages.Transmittals.Route}/new`);
  };

  const handleShowHideTableColumns = (items: string[]) => {
    const columns = TransmittalsStore.tableColumns.map((m) => {
      return {
        id: m.valueField,
        label: m.label,
        width: m.width!,
        visible: ActionColumns.includes(m.valueField) || items.includes(m.valueField),
      };
    });

    TransmittalsStore.setAdjustableTableColumns(columns, true);
  };

  const renderDropdownMenuItems = () => {
    return (
      <>
        {ActionBarStore.disabledExportBtn ? (
          <DropDownMenuButton disabled={true}>
            {TransmittalExportOptionText[TransmittalExportOption.ExportSelected]}
          </DropDownMenuButton>
        ) : (
          <CSVLink
            disabled={ActionBarStore.disabledExportBtn}
            data={exportData.data}
            headers={exportData.headers}
            filename={exportData.filename}
            target="_blank"
            className={Style.btnLink}>
            {TransmittalExportOptionText[TransmittalExportOption.ExportSelected]}
          </CSVLink>
        )}
        <TransmittalsExportCSVLink />
      </>
    );
  };

  return (
    <div>
      <ActionBar>
        <ActionBarSection className={Style.actionBarLabel}>
          {AppStore.isTemporaryAccess ? (
            <Tooltip
              show={'You cannot create a new transmittal from this task team. Please select another team'}
              defaultUp>
              <PrimaryButton onClick={handleCreateTransmittalClick} disabled={true}>
                New
              </PrimaryButton>
            </Tooltip>
          ) : (
            <PrimaryButton onClick={handleCreateTransmittalClick}>New</PrimaryButton>
          )}
        </ActionBarSection>
        <ActionBarSection className={Style.actionBarLabel}>
          <SecondaryButton
            disabled={TransmittalsStore.isLoading}
            onClick={() => {
              TransmittalsStore.setShowSelectCheckbox(!TransmittalsStore.showSelectCheckbox);
              if (!TransmittalsStore.showSelectCheckbox) ActionBarStore.clearSelectionTransmittals();
            }}>
            {TransmittalsStore.showSelectCheckbox ? 'Clear Selection' : 'Select'}
          </SecondaryButton>

          <div className={Style.expandBtn}>
            <DropDownMenu
              disabled={TransmittalsStore.isLoading}
              label="Export to CSV"
              icon="expand_more"
              iconClassName={Style.expandBtnIcon}
              itemsContainerClassName={Style.itemsContainer}
              menuClassName={Style.btnMenu}>
              {!TransmittalsStore.isLoading && renderDropdownMenuItems()}
            </DropDownMenu>
          </div>
          {TransmittalsStore.currentTab == TabFilter.AllDeliveryTeam && AppStore.projectAdminister && (
            <div className={Style.exportFileBtn}>
              <SecondaryButton disabled={TransmittalsStore.isLoading} onClick={() => setShowExportByFileModal(true)}>
                Export by File
              </SecondaryButton>
            </div>
          )}
          <SelectedTransmittalsLabel />
        </ActionBarSection>

        <ActionBarSection className={Style.searchBox}>
          <ClearFilterLink disabled={TransmittalsStore.isLoading} onClick={handleClearFilter} />
          <DropDownAdjustableColumns
            disabled={TransmittalsStore.isLoading}
            items={TransmittalsStore.getTableColumnsForShowHide.filter((col) => !ActionColumns.includes(col.id))}
            selectedItems={TransmittalsStore.getAdjustableTableColumns()
              .filter((col) => col.visible && !ActionColumns.includes(col.id))
              .map((m) => m.id)}
            onSelected={handleShowHideTableColumns}
          />
        </ActionBarSection>
      </ActionBar>
      {showExportByFileModal && <ExportByFileModal onCancel={() => setShowExportByFileModal(false)} />}
    </div>
  );
};

export default observer(TransmittalTabContainerActionBar);
