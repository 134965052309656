import { makeAutoObservable, runInAction } from 'mobx';
import { IFileMedataValueModel } from './models/IFileDataModel';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../../common/models/ItemType';
import { IUpdateFileMetadataError, updateFileMetadata } from '../../../api/authenticated/cms/updateFileMetadata';
import { AxiosError } from 'axios';
import FilesStore from '../FilesStore';
import { dateTimeFormat } from '../../../utils/dateUtils';

export class EditFileMetadataStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public saving = false;
  private fileMetadataValues: IFileMedataValueModel[] = [];
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public showError = false;
  public updateFileMetadataErrors: IUpdateFileMetadataError[] = [];

  public setShowError(show: boolean) {
    runInAction(() => (this.showError = show));
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
      this.showError = true;
    });
  }

  public get enabledSave(): boolean {
    return !!this.fileMetadataValues.length;
  }

  public setLoading(loading: boolean): void {
    runInAction(() => (this.saving = loading));
  }

  public async saveMetadata(): Promise<void> {
    if (
      NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam &&
      NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.Project
    )
      return;

    const taskTeamId =
      NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam
        ? NavBarSelectorStore.selectedItem.taskTeam.id
        : null;
    const projectNumber = NavBarSelectorStore.selectedItem.project.projectNumber;
    const fileContainerMedataUpdate = this.fileMetadataValues.map((f) => ({
      fileContainerId: f.fileContainerId,
      fileName: f.fileName,
      pattern1: f.pattern1,
      pattern2: f.pattern2,
      pattern3: f.pattern3,
      metadata: f.metadata
        .filter((md) => !!md.id)
        .map((md) => ({
          id: md.id,
          fieldIndex: md.fieldIndex,
          fieldValue: md.fieldValue,
        })),
    }));

    if (!fileContainerMedataUpdate) return;

    try {
      const result = await updateFileMetadata({
        projectNumber,
        taskTeamId,
        fileContainerStateId: FilesStore.fileContainerStateId,
        fileContainers: fileContainerMedataUpdate,
      });

      if (result.isError) {
        this.showError = true;
        runInAction(() => {
          this.updateFileMetadataErrors = result.updateFileMetadataErrors;
        });
      }
    } catch (err) {
      this.setError(err as AxiosError<string>);
    }
  }

  public getResultsCSVData() {
    return {
      filename: `${NavBarSelectorStore.selectedItem?.project.projectNumber}_Edit_Metadata_Result_${dateTimeFormat(
        new Date(),
        'yyyyMMdd'
      )}.csv`,
      headers: [
        { label: 'File Container Title', key: 'fileName' },
        { label: 'Status', key: 'status' },
        { label: 'Reason', key: 'reason' },
      ],
      data: this.fileMetadataValues.map((file) => {
        const err = this.updateFileMetadataErrors.find((x) => x.fileContainerId === file.fileContainerId);
        return {
          fileName: file.fileName,
          status: err ? 'Failed' : 'Success',
          reason: err?.reason,
        };
      }),
    };
  }

  public setFileMetadataValues(metadata: IFileMedataValueModel[]): void {
    runInAction(() => {
      this.fileMetadataValues = [
        ...this.fileMetadataValues.filter((x) => !metadata.some((y) => y.fileContainerId === x.fileContainerId)),
        ...metadata,
      ];
    });
  }

  public onCancel() {
    runInAction(() => {
      this.fileMetadataValues = [];
    });
  }
}

export default new EditFileMetadataStore();
