import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/UploadUnsavedModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';

interface IModalProps {
  closeModal: () => void;
  redirect: () => void;
  message: string;
}

const UploadConfirmation: FC<IModalProps> = (props) => {
  const { closeModal, redirect, message } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h2>File Review</h2>
        <p>{message}</p>
        <ModalActions>
          <SecondaryButton onClick={() => closeModal()}>No</SecondaryButton>
          <PrimaryButton onClick={() => redirect()}>Yes</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(UploadConfirmation);
