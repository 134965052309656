import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import { serverAxios } from '../api';

export interface IAddSystemUser {
  userObjectId?: string;
  email: string;
  displayName: string;
  userRoleId: ClientUserRole;
}

export async function addSystemUser(request: IAddSystemUser, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.post(`api/um/system/users/add`, request, {
    signal: abortSignal,
  });
  return result.data;
}
