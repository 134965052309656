import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Home from '@arcgis/core/widgets/Home';

import WidgetBase, { IWidgetBase } from './WidgetBase';

const HomeWidget: FC = () => {
  return WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new Home({
        visible: true,
        container: properties.container,
        view: properties.view,
      })
  );
};

export default observer(HomeWidget);
