interface IId {
  id: number | string;
}

export const getSelectedItems = <T extends IId>(
  list: T[],
  id: number | string,
  selected: boolean,
  shiftOn: boolean,
  currentSelectedItemIds: (number | string)[],
  lastSelectedIndexes: number[]
) => {
  if (shiftOn && lastSelectedIndexes.length) {
    const currentSelectedIndex = list.findIndex((t) => t.id === id);
    const lastSelectedIndex = lastSelectedIndexes[lastSelectedIndexes.length - 1];

    let newSelectedItemIds: (number | string)[] = [];
    if (currentSelectedIndex > lastSelectedIndex) {
      newSelectedItemIds = [id, ...list.slice(lastSelectedIndex, currentSelectedIndex).map((m) => m.id)];
    } else {
      newSelectedItemIds = [id, ...list.slice(currentSelectedIndex, lastSelectedIndex + 1).map((m) => m.id)];
    }

    return selected
      ? [...new Set([...currentSelectedItemIds, ...newSelectedItemIds])]
      : [...new Set([...currentSelectedItemIds.filter((f) => !newSelectedItemIds.some((s) => f === s))])];
  } else {
    return selected
      ? [...new Set([id, ...currentSelectedItemIds])]
      : [...currentSelectedItemIds.filter((f) => f !== id)];
  }
};
