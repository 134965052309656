import { serverAxios } from '../api';

interface IUpdateIProjectAppsRequest {
  projectNumber: string;
  projectApps: IProjectApps[];
}

interface IProjectApps {
  id: number;
  title: string;
  url: string;
  order: number;
  image: string;
}
export async function updateProjectApps(request: IUpdateIProjectAppsRequest, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.put(`api/config/projects/apps/update`, request, {
    signal: abortSignal,
  });
  return result.data;
}
