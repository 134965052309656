import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/AddUsers.module.scss';
import { IUser } from '../../shared/UserSelector';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import {
  Dropdown,
  FormInput,
  IDropdownItemProps,
  ISuggestion,
  SearchBox,
} from '@aurecon-creative-technologies/styleguide';
import { DropdownStyle } from '../../../common/constants/Dropdown';
import PrimaryButton from '../../shared/PrimaryButton';
import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import AppStore from '../../../stores/AppStore';

interface IAddUsersProps {
  projectNumber: string;
  roleItems: IDropdownItemProps[];
  addUserToTeam: (selectedUsers: IUser[], roleId: number) => void;
}

const AddUsers: FC<IAddUsersProps> = (props) => {
  const { projectNumber, roleItems, addUserToTeam } = props;
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [roleIds, setRoleIds] = useState<(string | number)[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<ISuggestion[]>([]);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [disableNameInput, setDisableNameInput] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');

  useEffect(() => {
    const effectAsync = async () => {
      setLoading(true);
      await TeamManagementStore.getOptionUsers(projectNumber);
      setLoading(false);
    };
    if (projectNumber) effectAsync();
  }, [projectNumber]);

  const addUser = async () => {
    try {
      setLoading(true);
      addUserToTeam && (await addUserToTeam(selectedUsers, roleIds[0] ? TaskTeamRole[roleIds[0]] : null));
      setLoading(false);
      setSelectedUsers([]);
      setEmail('');
      setName('');
      setTriggerCleanup(triggerCleanup + 1);
    } catch {
      setLoading(false);
    }
  };

  const getMatchedUsers = (searchText: string) => {
    if (disableNameInput) setName('');
    setDisableNameInput(false);
    setEmail(searchText);
    searchText && name ? setSelectedUsers([{ id: 0, email: searchText, name: name }]) : setSelectedUsers([]);
    setFilteredUsers([]);
    if (!searchText) {
      setFilteredUsers([]);
    } else {
      const selectedUserIds = selectedUsers.map((i) => i.id);
      const response = TeamManagementStore.allUsers
        .filter(
          (r) =>
            (r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
              r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1) &&
            !selectedUserIds.some((x) => x === r.id)
        )
        .map((r) => ({
          id: r.id,
          name: r.name,
          email: r.email,
          disabled: r.disabled,
        }));
      setFilteredUsers(
        response.map((x) => {
          return {
            id: x.id,
            value: x.email,
            display: x.email,
          };
        })
      );
    }
  };

  const onNameChanged = (text: string) => {
    setName(text);
    text && name ? setSelectedUsers([{ id: 0, email: email, name: text }]) : setSelectedUsers([]);
  };

  const onSelectedUsers = (selected: ISuggestion) => {
    setDisableNameInput(true);
    setFilteredUsers([]);
    setName(selected.value);
    setSelectedUsers([{ id: Number(selected.id), email: selected.value, name: selected.value }]);
  };

  const onClear = () => {
    if (disableNameInput) setName('');
    setDisableNameInput(false);
    setFilteredUsers([]);
    setSelectedUsers([]);
  };

  const isDisabled = () => {
    return loading || !selectedUsers.length;
  };

  return (
    <div className={Style.addUserBox}>
      <div className={Style.heading}>Add Users</div>
      <div className={Style.addNewSession}>
        <div className={Style.addForm}>
          <div className={Style.userEmailInput}>
            <div className={Style.label}>
              User Email <span className={Style.requiredSymbol}>*</span>
            </div>
            <SearchBox
              hideSearchButton
              disableDefaultMatching
              suggestions={filteredUsers}
              onChange={getMatchedUsers}
              onSearch={getMatchedUsers}
              onSelect={onSelectedUsers}
              onClear={onClear}
              triggerCleanup={triggerCleanup}
              disabled={loading}
            />
          </div>

          <div className={Style.displayNameInput}>
            <div className={Style.label}>
              Display Name <span className={Style.requiredSymbol}>*</span>
            </div>
            <FormInput
              disabled={disableNameInput || !AppStore.client?.canInviteOrRemoveAzure}
              onChange={onNameChanged}
              value={name}
            />
          </div>

          <div className={Style.roleInput}>
            <div className={Style.label}>Role</div>
            <Dropdown
              placeholder="Select Additional Role"
              items={roleItems}
              selectedMultipleItems={roleIds}
              onSelectMultipleItems={(value) => setRoleIds(value)}
              multiple={true}
              multipleDisplayValues={true}
              optionsHeight={DropdownStyle.defaultHeight}
              disabled={loading}
            />
          </div>

          <div>
            <div className={Style.labelBtn}>Add</div>
            <PrimaryButton className={Style.btnAdd} disabled={isDisabled()} onClick={addUser}>
              Add
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddUsers);
