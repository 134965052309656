import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import VisualisationStore from '../VisualisationStore';
import styles from './styles/LayerTreeWidget.module.scss';
import classNames from 'classnames';
import SecondaryIconButton from '../../shared/SecondaryIconButton';
import TreeView from '../../shared/TreeView';
import { ReactComponent as IconDistance } from '../images/distance.svg';

const LayerTreeWidget: FC = () => {
  const [open, setOpen] = useState(false);
  const layerIds: string[] = [];

  const generateLayerIds = (layers) => {
    layers.map((layer) => {
      if (!layerIds.some((t) => t === layer.id)) layerIds.push(layer.id);
      if (!layer?.layers) return;
      generateLayerIds(layer?.layers);
    });
  };
  generateLayerIds(VisualisationStore.mapLayers);

  const zoomToLayer = (layer) => {
    return VisualisationStore.mapView.goTo(layer.fullExtent);
  };

  const onSelectCheckbox = (_, __, selectedCheckboxes: (number | string)[]) => {
    VisualisationStore.mapView.allLayerViews
      .filter((t) => layerIds.includes(t.layer.id))
      .forEach((t) => {
        if (selectedCheckboxes.includes(t.layer.id)) {
          t.visible = true;
          return;
        }
        t.visible = false;
      });
  };

  const loopDeepMapLayers = (layers) => {
    if (!layers) return [];
    return layers.map((p) => {
      return {
        id: p.id,
        key: p.id,
        title: p.title,
        showCheckbox: true,
        onSelectCheckbox: onSelectCheckbox,
        path: `${p.id}`,
        disabled: false,
        showLine: false,
        type: '',
        childrenNodes: loopDeepMapLayers(p.layers),
        className: styles.treeNode,
        customIcon: p.fullExtent ? (
          <IconDistance className={styles.locationIcon} onClick={() => zoomToLayer(p)} />
        ) : null,
      };
    });
  };

  return (
    <div className={classNames(styles.layerTree, [open ? styles.layerTreeActive : ''])}>
      <div className={styles.topButton}>
        <SecondaryIconButton
          className={styles.iconButton}
          icon={open ? 'keyboard_arrow_left' : 'layers'}
          onClick={() => setOpen(!open)}
        />
      </div>
      <p className={styles.headerLabel}>Map Layers</p>
      <div className={styles.appContainer}>
        <TreeView treeData={loopDeepMapLayers(VisualisationStore.mapLayers)} onSelect={() => null}></TreeView>
      </div>
    </div>
  );
};

export default observer(LayerTreeWidget);
