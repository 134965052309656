import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/Sidebar.module.scss';
import SidebarButton from './SidebarButton';
import { classNames } from '../../utils/miscUtils';
import { Pages } from '../../common/constants/Pages';
import AppStore from '../../stores/AppStore';
import AppDrawer from '../displayApps/AppDrawer';
export interface ISidebarProps {
  className?: string;
}

const Sidebar: FC<ISidebarProps> = ({ className }) => {
  const [open, setOpen] = useState(false);

  const handleAppDrawer = (state: boolean) => {
    setOpen(state);
    if (!state) {
      setOpen(false);
    }
  };

  return (
    <div className={classNames(Style.sidebar, className)}>
      {!AppStore.isProjectExternalUser && (
        <>
          {AppStore.projectSettings.displayApps && (
            <div>
              <SidebarButton
                label="Apps"
                icon="interests"
                route={Pages.DisplayApps.Route}
                onClick={() => handleAppDrawer(true)}
              />
            </div>
          )}
          {AppStore.projectSettings.displayFiles && (
            <SidebarButton label="Files" icon="description" route={Pages.Files.Route} />
          )}

          {AppStore.projectSettings.displayTasks && (
            <SidebarButton label="Tasks" icon="list" route={Pages.Tasks.Route} />
          )}

          {AppStore.projectSettings.displayVisualisation && (
            <SidebarButton label="Visualisation" icon="image" route={Pages.Visualisation.Route} />
          )}
        </>
      )}
      {AppStore.projectSettings.displayTransmittals && (
        <SidebarButton label="Transmittals" icon="email" route={Pages.Transmittals.Route} />
      )}
      <AppDrawer open={open} onClose={() => setOpen(false)}></AppDrawer>
    </div>
  );
};

export default observer(Sidebar);
