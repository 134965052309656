import { ITeamModel } from '../../components/settings/teamManagement/models/ITeamManagementModel';
import { NodeTypes } from '../../components/settings/teamManagement/TeamManagement';

export const TeamManagementAction = {
  ADD_DELIVERY_TEAM: 'ADD_DELIVERY_TEAM',
  ADD_TASK_TEAM: 'ADD_TASK_TEAM',
  ADD_DISTRIBUTION_LIST: 'ADD_DISTRIBUTION_LIST',
};

export interface ITeamDetailsProps {
  projectNumber: string;
  projectName: string;
  team: ITeamModel;
  teamType: NodeTypes;
  programmeId: number | null;
}
