import { createContext, useContext } from 'react';
import { ReviewStore } from './ReviewStore';

export const ReviewStoreContext = createContext<ReviewStore | null>(null);

export const useReviewStore = () => {
  const reviewStore = useContext(ReviewStoreContext);
  if (!reviewStore) throw Error('ReviewStore is not defined');
  return reviewStore;
};
