export const ErrorCode = {
  Network_Error: '0',
  Bad_Request: '400',
  UnAuthorised: '401',
  Not_Found: '404',
  Internal_Server: '500',
  Map_Error: 'map-error',
};

export const ErrorStatusText = {
  [ErrorCode.Network_Error]: 'Network Error',
  [ErrorCode.Bad_Request]: 'Bad Request 400',
  [ErrorCode.UnAuthorised]: 'Unauthorised 401',
  [ErrorCode.Not_Found]: 'Not Found 404',
  [ErrorCode.Internal_Server]: 'Internal Server Error 500',
};
