import { serverAxios } from '../api';

export interface IEditTaskTeamRequest {
  taskTeamId: number;
  projectNumber: string;
  title: string;
}

export async function updateTaskTeam(request: IEditTaskTeamRequest, abortSignal?: AbortSignal): Promise<void> {
  return await serverAxios.put('api/um/taskteam/update', request, {
    signal: abortSignal,
  });
}
