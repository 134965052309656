import { serverAxios } from '../api';

interface ITransmittalDefaultTypesRequest {
  projectNumber: string;
  projectTransmittalTypes: ITypes[];
  projectTransmittalReasons: IReason[] | null;
}
interface IReason {
  id: number;
  namingCode: string;
  title: string;
  transmittalTypeId: number;
  order: number;
  tempId: string;
  tempTransmittalTypeId: string;
}
interface ITypes {
  id: number;
  title: string;
  namingCode: string;
  order: number;
  tempId: string;
}
export async function updateTransmittalDefaultsTypes(
  request: ITransmittalDefaultTypesRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.put(`api/config/projects/transmittalDefaults/update`, request, {
    signal: abortSignal,
  });
  return result.data;
}
