import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Slider } from '@aurecon-creative-technologies/styleguide';
import VisualisationStore, { MAP_OPAQUE, MAP_TRANSPARENT } from '../VisualisationStore';
import Style from './styles/SettingsWidget.module.scss';

const SettingsWidget: FC = () => {
  const displayPointCloudSize = true;

  const handleOpacityChange = (value: string) => {
    const percent = Number(value);
    VisualisationStore.setMapOpacity(percent);
  };

  const handlePointCloudSizeChange = (value: string) => {
    VisualisationStore.setPointCloudSize(Number(value));
  };

  const handleMapQualityChange = (value: string) => {
    VisualisationStore.setMapQuality(Number(value));
  };

  const getTransparencyLabel = (): string => {
    return (
      'Ground transparency' + (VisualisationStore.mapOpacity !== MAP_OPAQUE ? ` ${VisualisationStore.mapOpacity}%` : '')
    );
  };

  return (
    <div className={Style.settingsWidgetContainer}>
      <Slider
        label={getTransparencyLabel()}
        value={VisualisationStore.mapOpacity}
        key="mapTransparencySlider"
        min={MAP_OPAQUE}
        max={MAP_TRANSPARENT}
        step={5}
        cssClass={Style.settingsControl}
        onValueChange={handleOpacityChange}></Slider>
      {displayPointCloudSize && (
        <Slider
          label="Point Cloud Size"
          key="pointCloudSizeSlider"
          value={VisualisationStore.pointCloudSize}
          min={0}
          max={10}
          step={1}
          cssClass={Style.settingsControl}
          onValueChange={handlePointCloudSizeChange}></Slider>
      )}
      <Slider
        label="Map Quality"
        value={VisualisationStore.mapQuality}
        key="mapQualitySlider"
        min={0}
        max={2}
        step={1}
        cssClass={Style.settingsControl}
        onValueChange={handleMapQualityChange}></Slider>
    </div>
  );
};

export default observer(SettingsWidget);
