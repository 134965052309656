import { serverAxios } from '../api';

export interface IDistributionListRequest {
  projectNumber: string;
  distributionListId: number;
}

export async function deleteDistributionList(
  request: IDistributionListRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return await serverAxios.post('api/um/distributionlist/delete', request, {
    signal: abortSignal,
  });
}
