import { AxiosError } from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import { getForgeViewerAuthenticate } from '../../../api/authenticated/cms/getForgeViewerAuthenticate';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import { appInsightsTrackEvent } from '../../../utils/appInsights';

export class ForgeViewerStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  public urn = '';
  public token = '';
  public showForgeViewerModal = false;
  public isLoading = false;
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public error: AxiosError<string> | undefined;

  public async getForgeViewer(containerFileId: number, projectNumber: string) {
    appInsightsTrackEvent(AppInsightEvent.FILE_OPEN);
    try {
      this.isLoading = true;
      this.errorCode = null;
      this.errorMessage = null;
      this.error = undefined;
      const response = await getForgeViewerAuthenticate({
        request: {
          projectNumber: projectNumber,
          containerFileId: containerFileId,
        },
      });
      runInAction(() => {
        this.urn = response.urn;
        this.token = response.token;
        this.showForgeViewerModal = true;
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      this.error = err as AxiosError<string>;
      this.errorCode = (err as AxiosError<string>)?.response?.status ?? null;
      this.errorMessage = (err as AxiosError<string>)?.response?.data ?? 'Failed getting Forge file details.';
    }
  }

  public forgeViewerModalClose() {
    this.showForgeViewerModal = false;
  }
}

export default new ForgeViewerStore();
