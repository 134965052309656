import { serverAxios } from '../api';

export const deactiveSystemUser = async (userId: number, abortSignal?: AbortSignal): Promise<void> =>
  serverAxios.put(
    `api/um/system/users/deactive`,
    { userId },
    {
      signal: abortSignal,
    }
  );
