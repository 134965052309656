import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../shared/Modal';
import Style from './styles/DownloadModal.module.scss';
import CloseButton from '../shared/CloseButton';
import PrimaryButton from '../shared/PrimaryButton';
import ModalActions from '../shared/ModalActions';
import CentreOnPage from './CentreOnPage';
import Overlay from './Overlay';
import SecondaryButton from './SecondaryButton';

interface IDownloadModalProps {
  closeModal: () => void;
  downloadAction: () => void;
  showModal: boolean;
  fileSize: string;
}

const DownloadModal: FC<IDownloadModalProps> = (props) => {
  const { closeModal, downloadAction, showModal, fileSize } = props;
  if (!showModal) return null;
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={() => closeModal()} disabled={false} />
        <div className={Style.info}>
          <h1>Large Files Download</h1>
          <p>{`Total download size is ${fileSize}. Do you want to continue?`}</p>
        </div>
        <ModalActions>
          <SecondaryButton onClick={() => closeModal()}>Cancel</SecondaryButton>
          <PrimaryButton onClick={() => downloadAction()}>Download</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(DownloadModal);
