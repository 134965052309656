import { TaskType } from '../../../common/enums/TaskType';
import { addTask, IAddTaskFileContainer } from '../../../api/authenticated/tasks/addTask';
import { ISaveReviewData } from '../reviewModal/ReviewStore';

export function useSaveTask(taskTeamId: number, taskType: TaskType) {
  return async (saveReviewData: ISaveReviewData) => {
    return await addTask({
      taskTeamId,
      taskType,
      fileContainers: saveReviewData.fileContainers.map<IAddTaskFileContainer>((f) => ({
        fileContainerId: f.id,
        fileContainerRevisionId: f.fileRevisionId,
        releasedFileContainerId: f.releasedFileId,
      })),
      reviewerUserIds: saveReviewData.reviewers.map<number>((r) => r.userId),
      suitabilityId: saveReviewData.suitability!.id,
      message: saveReviewData.selectedMessage,
      isSingleApproverRequired: saveReviewData.isSingleApproverRequired,
      acceptorUserIds: saveReviewData.acceptors?.map<number>((r) => r.userId) ?? [],
      isSingleAcceptorRequired: saveReviewData.isRequiredSingleAcceptorUser ?? false,
      dueDate: saveReviewData.dueDate ?? null,
      attachmentFiles: saveReviewData.attachmentFiles,
    });
  };
}
