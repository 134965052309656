import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/HistoryContainerFile.module.scss';
import { IContainerFile, IReleasedHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import DropDownMenu from '../../shared/DropDownMenu';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import Icon from '../../shared/Icon';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import FileInformationStore from './FileInformationStore';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import getFileExtension from '../../../utils/fileUtils';
import { FileExt } from '../../../common/constants/FileExt';
import { openViewer } from '../../../utils/pdfViewerUtils';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';

export interface IHistorContainerFileProps {
  release: IReleasedHistoryItem;
  item: IContainerFile;
  setShowErrorModal: (show: boolean) => void;
}

const HistoryContainerFile: FC<IHistorContainerFileProps> = ({ release, item, setShowErrorModal }) => {
  const downloadOnClick = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_DOWNLOAD);

    FileInformationStore.download([
      {
        ...release,
        containerFileId: item.containerFileId ?? FileInformationStore.file?.containerFileId,
      } as IReleasedHistoryItem,
    ]);
  };

  const handleForgeViewer = async () => {
    FileInformationStore.busyReleasedHistoryItem[release.releasedFileContainerId] = true;
    await ForgeViewerStore.getForgeViewer(item.containerFileId, FileInformationStore.projectNumber);
    FileInformationStore.busyReleasedHistoryItem[release.releasedFileContainerId] = false;
    if (ForgeViewerStore.errorMessage && ForgeViewerStore.error) {
      FileInformationStore.setError(ForgeViewerStore.error);
      setShowErrorModal(true);
    }
  };

  return (
    <div className={Style.file}>
      <div className={Style.fileTitle}>
        {item.originalFilename}{' '}
        <Tooltip show="Native file" defaultUp>
          {item.native && <Icon name={'star_outline'} className={Style.native} />}
        </Tooltip>
      </div>
      <div className={Style.actionButton}>
        <DropDownMenu icon="more_vert" iconClassName={Style.icon}>
          <DropDownMenuButton onClick={downloadOnClick}>Download</DropDownMenuButton>
          {item.hasDocumentViewer && (
            <DropDownMenuButton
              onClick={() => FileInformationStore.openFile({ ...release, containerFileId: item.containerFileId })}>
              Open File
            </DropDownMenuButton>
          )}
          {item.isForgeFile && <DropDownMenuButton onClick={() => handleForgeViewer()}>Open File</DropDownMenuButton>}
          {!!release.fileContainerRevisionId &&
            getFileExtension(item.originalFilename)?.toLocaleLowerCase() === FileExt.PDF && (
              <DropDownMenuButton
                onClick={() => {
                  openViewer(
                    `projectNumber=${FileInformationStore.projectNumber}&id=${release.fileContainerId}&fileRevisionId=${release.fileContainerRevisionId}
                     &containerFileId=${item.containerFileId}&releasedFileId=${release.releasedFileContainerId}`
                  );
                }}>
                Open PDF File
              </DropDownMenuButton>
            )}
        </DropDownMenu>
      </div>
    </div>
  );
};

export default observer(HistoryContainerFile);
