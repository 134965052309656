import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IFileTemporaryAccessDetails {
  name: string;
  effectiveTo: Date;
}

export async function getFileContainerTemporaryAccessDetails(
  projectNumber: string,
  fileContainerId: number,
  fileContainerStateId: FileContainerState,
  releasedFileId: number | null,
  transmittalId: number | null,
  transmittalMessageId: number | null,
  abortSignal?: AbortSignal
): Promise<IFileTemporaryAccessDetails[]> {
  const params = {
    projectNumber,
    fileContainerId,
    fileContainerStateId,
    releasedFileId,
    transmittalId,
    transmittalMessageId,
  };
  const result = await serverAxios.get<{ temporaryAccessDetails: IFileTemporaryAccessDetails[] }>(
    'api/cms/fileContainerTemporaryAccessDetails',
    {
      params: params,
      signal: abortSignal,
    }
  );
  return result.data?.temporaryAccessDetails ?? [];
}
