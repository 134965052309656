import { DateFilterOperator } from '../../../common/interfaces/TableColumn';
import { serverAxios } from '../api';

export interface ITransmittalExportByFileRequest {
  projectNumber: string | null;
  deliveryTeamId: number | null;
  lastUpdatedFrom: Date | null;
  lastUpdatedTo: Date | null;
  lastUpdatedOperator: DateFilterOperator | null;
}
export interface ITransmittalExportByFileResponse {
  containerFileId: number;
  createdByEmail: string;
  createdByName: string;
  fieldValue9: string;
  lastUpdated: string;
  originalFilename: string;
  publishedDate: string;
  publishedSuitability: string;
  sharedDate: string;
  sharedSuitability: string;
  subject: string;
  suitabilityCode: string;
  taskTeamTitle: string;
  title: string;
  transmittalId: number;
  transmittalReasonTitle: string;
  transmittalSubject: string;
  transmittalTitle: string;
  transmittalTypeTitle: string;
}

export async function getTransmittalsExportByFile(
  options: ITransmittalExportByFileRequest,
  abortSignal?: AbortSignal
): Promise<ITransmittalExportByFileResponse[]> {
  const result = await serverAxios.get<{
    transmittal: ITransmittalExportByFileResponse[];
  }>('api/transmittals/getTransmittalsExportByFile', {
    params: options,
    signal: abortSignal,
  });
  return result.data.transmittal;
}
