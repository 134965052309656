import { serverAxios } from '../api';

export interface IDistributionListRequest {
  projectNumber: string;
  distributionListId: number;
  archive: boolean;
}

export async function archiveDistributionList(
  request: IDistributionListRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return await serverAxios.post('api/um/distributionlist/archive', request, {
    signal: abortSignal,
  });
}
