import { serverAxios } from '../api';

interface IImportSystemUsersRequest {
  systemUsers: IImportSystemUsers[];
}

interface IImportSystemUsers {
  email: string;
  displayName: string;
}

export interface IImportSystemUserResponse {
  isSuccess: boolean;
  importResults: {
    email: string;
    status: string;
    reason: string;
  }[];
}

export async function importSystemUsers(
  request: IImportSystemUsersRequest,
  abortSignal?: AbortSignal
): Promise<IImportSystemUserResponse> {
  const result = await serverAxios.post<IImportSystemUserResponse>(`api/um/system/ImportSystemUsers`, request, {
    signal: abortSignal,
  });
  return result.data;
}
