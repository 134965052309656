import { serverAxios } from '../api';
export interface IDeliveryTeamTaskTeamUser {
  id: number;
  name: string;
  email: string;
}
export async function getAllDeliveryTeamTaskTeamUsers(
  projectNumber: string,
  deliveryTeamId: number,
  abortSignal?: AbortSignal
): Promise<IDeliveryTeamTaskTeamUser[]> {
  const result = await serverAxios.get<IDeliveryTeamTaskTeamUser[]>('api/um/AllDeliveryTeamTaskTeamsUsers', {
    params: { projectNumber, deliveryTeamId },
    signal: abortSignal,
  });
  return result.data;
}
