import { serverAxios } from '../api';

export interface IRemoveSystemUserRequest {
  userId: number;
}

export function removeSystemUser(request: IRemoveSystemUserRequest, abortSignal?: AbortSignal): Promise<void> {
  return serverAxios.post('api/um/systemusers/remove', request, {
    signal: abortSignal,
  });
}
