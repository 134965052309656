import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useReviewStore } from './ReviewStoreContext';

const Header: FC = () => {
  const store = useReviewStore();
  return (
    <>
      <h1>{store.title}</h1>
      <p>{store.description}</p>
    </>
  );
};

export default observer(Header);
