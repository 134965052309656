import { serverAxios } from '../api';

export interface IClientTeamManagement {
  programmes: IClientProgramme[];
}

export interface IClientProgramme {
  id: number;
  title: string;
  projects: IClientProject[];
}

export interface IClientProject {
  id: number;
  projectNumber: string;
  title: string;
  deliveryTeams: IClientTeam[];
  appointingParties: IClientAppointingParty[];
  distributionList: IClientDistributionList[];
}

export interface IClientTeam {
  id: number;
  title: string;
  code: string;
  active: boolean;
  canDeactivate: boolean;
  taskTeams: IClientTeam[];
}

export interface IClientAppointingParty {
  id: string;
  title: string;
  code: string;
}
export interface IClientDistributionList {
  id: number;
  title: string;
  active: boolean;
}

export async function getClientTeams(abortSignal?: AbortSignal): Promise<IClientTeamManagement> {
  const result = await serverAxios.get<IClientTeamManagement>('/api/um/system/teams', {
    signal: abortSignal,
  });
  return result.data;
}
