import { serverAxios } from '../api';

export async function completeTask(
  taskId: number,
  message: string | null,
  isApproved: boolean,
  abortSignal?: AbortSignal
): Promise<void> {
  await serverAxios.post('api/task/complete', { taskId, message, isApproved }, { signal: abortSignal });
}
