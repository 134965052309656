import { makeAutoObservable, runInAction } from 'mobx';
import { closeTransmittal } from '../../api/authenticated/transmittals/closeTransmittal';
import { reopenTransmittal } from '../../api/authenticated/transmittals/reopenTransmittal';
import {
  getTransmittal,
  IGroupTeamUsers,
  ITransmittal,
  IUser,
} from '../../api/authenticated/transmittals/getTransmittal';
import { setFlaggedTransmittal } from '../../api/authenticated/transmittals/setFlaggedTransmittal';
import NavBarSelectorStore from '../transmittals/navBarSelector/NavBarSelectorStore';
import { UserManagementTypeEnum } from '../../enums/UserManagementTypeEnum';
import AppStore from '../../stores/AppStore';
import { orderBy } from 'lodash';
import { TaskTeamItem } from '../transmittals/navBarSelector/ItemType';
import { NavigationItemTypes } from '../../common/models/ItemType';
import { IResponseError } from '../../api/authenticated/config/models/projectModel';
import { AxiosError } from 'axios';
import LayoutStore from '../layout/LayoutStore';

export class TransmittalDetailsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public isLoadingTransmittal = false;
  public isClosingTransmittal = false;
  public isReopeningTransmittal = false;
  public transmittal: ITransmittal | null = null;
  public navbarKey: string | null = null;
  public openPanelIds = new Set<string>();
  public showNotifierModal = false;
  public showVisibleUserModal = false;
  public activeTaskTeamPanelIds = new Set<string>();
  public projectNumber?: string | null = null;

  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public showError = false;

  public async init(transmittalTitle: string, loggedInUser: string, projectNumber?: string | null) {
    if (!transmittalTitle || !loggedInUser) {
      runInAction(() => {
        this.isLoadingTransmittal = false;
        this.transmittal = null;
      });
      return;
    }

    runInAction(() => {
      this.isLoadingTransmittal = true;
      this.transmittal = null;
      this.projectNumber = projectNumber ?? NavBarSelectorStore.selectedItem?.project?.projectNumber;
      this.errorCode = null;
      this.errorMessage = null;
      this.showError = false;
    });
    try {
      const result = await getTransmittal(transmittalTitle, this.projectNumber ?? null);
      runInAction(() => {
        this.transmittal = result?.transmittal ?? null;
        if (this.transmittal) {
          const sortMessages = orderBy(this.transmittal.transmittalMessages, 'id', 'desc');
          this.transmittal.transmittalMessages = [...sortMessages];
          const selectedTaskTeam = NavBarSelectorStore.selectedItem as TaskTeamItem;
          if (
            this.transmittal.projectNumber !== NavBarSelectorStore.selectedItem?.project?.projectNumber ||
            !selectedTaskTeam?.taskTeam?.id ||
            !this.transmittal.taskTeamId ||
            selectedTaskTeam?.taskTeam?.isTemporaryAccessible ||
            Number(selectedTaskTeam?.taskTeam?.id) !== this.transmittal.taskTeamId
          ) {
            NavBarSelectorStore.setSelectedSelectorItem(this.getNavKey(this.transmittal, loggedInUser));
          }
        }
      });
    } finally {
      runInAction(() => {
        this.isLoadingTransmittal = false;
      });
    }
  }

  public async closedTransmittal(transmittalId: number) {
    runInAction(() => {
      this.isClosingTransmittal = true;
    });
    try {
      await closeTransmittal(NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null, transmittalId);
    } finally {
      runInAction(() => {
        this.isClosingTransmittal = false;
      });
    }
  }

  public async reopenTransmittal(transmittalId: number) {
    runInAction(() => {
      this.isReopeningTransmittal = true;
    });
    try {
      await reopenTransmittal(NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null, transmittalId);
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      LayoutStore.displayToast('error', 'Reopening Transmittal was unsuccessful.');
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.isReopeningTransmittal = false;
      });
    }
    return !this.showError;
  }

  public async flagTransmittal(transmittalId: number, flagged: boolean) {
    runInAction(() => {
      this.isLoadingTransmittal = true;
    });
    try {
      await setFlaggedTransmittal(
        NavBarSelectorStore.selectedItem?.project?.projectNumber ?? null,
        transmittalId,
        flagged
      );
    } finally {
      runInAction(() => {
        this.isLoadingTransmittal = false;
      });
    }
  }

  public get transmittalNotifications() {
    const sortExternalUsers = [...(this.transmittal?.allNotifiedUsers.filter((x) => x.taskTeamId === 0) || [])];
    sortExternalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    return {
      externalUsers: sortExternalUsers,
      taskTeams: this.buildTaskTeamGroup(this.transmittal?.allNotifiedUsers.filter((x) => x.taskTeamId > 0)),
    };
  }

  public get transmittalVisible() {
    const sortExternalUsers = [...(this.transmittal?.transmittalVisibilityExternalUsers || [])];
    sortExternalUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    const visibilityUsers = this.transmittal?.transmittalVisibilityAllUsers || [];
    const appointingParties =
      this.transmittal?.transmittalVisibilityAppointingParties.map((ap) => ({
        taskTeamId: ap.id,
        taskTeamTitle: ap.title,
        users: visibilityUsers
          .filter((u) => u.groupId === ap.id)
          .sort((a, b) => (a.userName.toLocaleLowerCase() > b.userName.toLocaleLowerCase() ? 1 : -1))
          .map((u) => ({
            id: u.userId,
            name: u.userName,
            initials: u.initials,
            taskTeamId: u.groupId,
            taskTeamTitle: u.groupTitle,
          })) as IUser[],
        type: UserManagementTypeEnum.AppointingParty,
      })) || [];

    const deliveryTeams =
      this.transmittal?.transmittalVisibilityDeliveryTeams.map((dt) => ({
        taskTeamId: dt.id,
        taskTeamTitle: dt.title,
        users: visibilityUsers
          .filter((u) => u.groupId === dt.id)
          .sort((a, b) => (a.userName.toLocaleLowerCase() > b.userName.toLocaleLowerCase() ? 1 : -1))
          .map((u) => ({
            id: u.userId,
            name: u.userName,
            initials: u.initials,
            taskTeamId: u.groupId,
            taskTeamTitle: u.groupTitle,
          })) as IUser[],
        type: UserManagementTypeEnum.DeliveryTeam,
      })) || [];

    const taskTeams =
      this.transmittal?.transmittalVisibilityTaskTeams.map((tt) => ({
        deliveryTeamTitle: tt.deliveryTeamTitle,
        deliveryTeamCode: tt.deliveryTeamCode,
        taskTeamId: tt.id,
        taskTeamTitle: tt.title,
        users: visibilityUsers
          .filter((u) => u.groupId === tt.id)
          .sort((a, b) => (a.userName.toLocaleLowerCase() > b.userName.toLocaleLowerCase() ? 1 : -1))
          .map((u) => ({
            id: u.userId,
            name: u.userName,
            initials: u.initials,
            taskTeamId: u.groupId,
            taskTeamTitle: u.groupTitle,
          })) as IUser[],
        type: UserManagementTypeEnum.TaskTeam,
      })) || [];

    const teams = [
      ...this.buildTaskTeamGroup(this.transmittal?.transmittalVisibilityTaskTeamUsers),
      ...appointingParties,
      ...deliveryTeams,
      ...taskTeams,
    ];

    return {
      externalUsers: sortExternalUsers,
      taskTeams: teams,
    };
  }

  private buildTaskTeamGroup(users: IUser[] = []) {
    const teams: IGroupTeamUsers[] = [];
    const sortUsers = [...users];
    if (this.transmittal && !sortUsers.some((t) => t.id === this.transmittal?.createdByUserId)) {
      sortUsers.push({
        id: this.transmittal.createdByUserId,
        name: this.transmittal.createdByName,
        email: this.transmittal.createdByEmail,
        initials: this.transmittal.createdByInitials,
        taskTeamId: this.transmittal.taskTeamId,
        taskTeamTitle: this.transmittal.taskTeamTitle ?? this.transmittal.projectTitle,
      });
    }
    sortUsers.sort((a, b) => (a.name > b.name ? 1 : -1));

    sortUsers?.forEach((element) => {
      if (!teams.some((x) => element.taskTeamId === x.taskTeamId)) {
        teams.push({
          deliveryTeamTitle: element.deliveryTeamTitle,
          taskTeamId: element.taskTeamId,
          taskTeamTitle: element.taskTeamTitle,
          users: [element],
          type: UserManagementTypeEnum.TaskTeam,
        });
      } else {
        const existed = teams.find((x) => x.taskTeamId === element.taskTeamId);
        existed?.users.push(element);
      }
    });

    return teams;
  }

  public handleTaskTeamPanelToggle(id: string) {
    const newIds = new Set<string>(this.activeTaskTeamPanelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);

    runInAction(() => {
      this.activeTaskTeamPanelIds = newIds;
    });
  }

  public modalClose() {
    runInAction(() => {
      this.showVisibleUserModal = false;
      this.showNotifierModal = false;
    });
  }

  public panelToggle(id: string) {
    runInAction(() => {
      this.openPanelIds.has(id) ? this.openPanelIds.delete(id) : this.openPanelIds.add(id);
    });
  }

  public getTransmittalMessages() {
    return this.transmittal?.transmittalMessages
      .slice()
      .sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());
  }

  public getNotifyUsersFromSelectedResponse(messageId: number) {
    const selectedResponse = this.transmittal?.transmittalMessages.find((m) => m.id === messageId);
    const users =
      selectedResponse?.transmittalMessageNotifyTaskTeamUsers.concat(
        selectedResponse.transmittalMessageNotifyExternalUsers
      ) || [];

    const appointingPartyUsers = selectedResponse?.transmittalMessageNotifyAppointingPartyUsers || [];

    const allUsers = users
      .map((m) => ({
        id: m.id,
        initials: m.initials,
        email: m.email,
        name: m.name,
      }))
      .concat(
        appointingPartyUsers.map((m) => ({
          id: m.id,
          initials: m.initials,
          email: m.email,
          name: m.name,
        }))
      );

    return [...new Map(allUsers.map((item) => [item['id'], item])).values()];
  }

  private getNavKey(transmittal: ITransmittal, loggedInUser: string) {
    AppStore.setProjectNumber(transmittal.projectNumber);
    if (AppStore.isProjectExternalUser) {
      return `projectId:${transmittal.projectNumber}`;
    }

    const notifiedUser = transmittal.allNotifiedUsers.find((s) => s.email.toLowerCase() === loggedInUser.toLowerCase());
    if (notifiedUser) return `taskTeamId:${notifiedUser.taskTeamId}`;

    const visibleUser = transmittal.transmittalVisibilityAllUsers.find(
      (s) => s.email.toLowerCase() === loggedInUser.toLowerCase()
    );

    if (visibleUser && visibleUser.userManagementType === NavigationItemTypes.TaskTeam)
      return `taskTeamId:${visibleUser.groupId}`;

    const visibilityTaskTeamUser = transmittal.transmittalVisibilityTaskTeamUsers.find(
      (s) => s.email.toLowerCase() === loggedInUser.toLowerCase()
    );
    if (visibilityTaskTeamUser) return `taskTeamId:${visibilityTaskTeamUser.taskTeamId}`;

    const initiatorUser = transmittal.createdByEmail.toLowerCase() === loggedInUser.toLowerCase();
    if (initiatorUser) return `taskTeamId:${transmittal.taskTeamId}`;

    const userTaskTeamIds = AppStore.client?.programmes
      .flatMap((m) => m.projects)
      .flatMap((m) => m.taskTeams)
      .map((m) => m.id);

    const visibilityTaskTeam = transmittal.transmittalVisibilityTaskTeams.find((s) => userTaskTeamIds?.includes(s.id));
    if (visibilityTaskTeam) return `taskTeamId:${visibilityTaskTeam.id}`;
    return `projectId:${transmittal.projectNumber}`;
  }

  public setShowErrorModal(value: boolean) {
    runInAction(() => {
      this.showError = value;
    });
  }

  public setError(error: AxiosError<IResponseError>) {
    runInAction(() => {
      if (error?.code === 'ERR_NETWORK' || error?.response?.status === 0) {
        this.errorCode = 0;
        this.errorMessage = 'You are offline. Please check your connection and try again later.';
        return;
      }
      this.errorCode = error?.response?.status ?? 500;
      if (this.errorCode === 400 && error?.response?.data) {
        if (typeof error?.response?.data === 'string') {
          this.errorMessage = error?.response?.data;
        } else {
          this.errorMessage = 'Request invalid.';
        }
      }
    });
  }
}

export default new TransmittalDetailsStore();
