import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import PageTab from '../../../../shared/PageTab';
import Style from './styles/SystemUserImportModal.module.scss';
import { IValidationImportUserResponse } from '../../../../../models/importSystemUsers/validationImportSystemUsers';
import { SortTypes } from '../../../../../common/enums/SortType';
import SystemUserImportResultTabContainer from './SystemUserImportResultTabContainer';
import { ITableColumn } from '../../../../../common/interfaces/TableColumn';
import AppStore from '../../../../../stores/AppStore';
enum ResultTabIds {
  ERRORS,
  DUPLICATES,
  READYFORIMPORT,
}

interface IProjectDetailsTabProps {
  processedUser: IValidationImportUserResponse;
}

const tableTabTitle = {
  errors: 'The user list you are trying to upload contains errors.',
  duplicates: 'The user list you are trying to upload contains duplicates.',
  readyforimport: 'Users in ready for import will be added.',
};

const ProjectDetailsTabs: FC<IProjectDetailsTabProps> = (props) => {
  const [selectedTabId, setSelectedTabId] = useState(ResultTabIds.ERRORS);
  const [selectTabLabel, setSelectTabLabel] = useState(tableTabTitle.errors);

  const resultTabs: ITab[] = useMemo(
    () => [
      {
        id: ResultTabIds.ERRORS,
        label: `Errors (${props.processedUser.errors.length})`,
        disabled: props.processedUser.errors.length === 0,
      },
      {
        id: ResultTabIds.DUPLICATES,
        label: `Duplicates (${props.processedUser.duplicates.length})`,
        disabled: props.processedUser.duplicates.length === 0,
      },
      {
        id: ResultTabIds.READYFORIMPORT,
        label: `Ready For Import (${props.processedUser.readyForImport.length})`,
        disabled: props.processedUser.readyForImport.length === 0,
      },
    ],
    [props.processedUser]
  );

  useEffect(() => {
    const activeTab = resultTabs.find((tab) => !tab.disabled)?.id ?? ResultTabIds.ERRORS;
    setSelectedTabId(activeTab);
    setSelectTabLabel(tableTabTitle[ResultTabIds[activeTab].toLowerCase()]);
  }, [resultTabs]);

  const tableColumns: ITableColumn[] = [
    {
      label: 'Email',
      valueField: 'email',
      sort: SortTypes.NONE,
      width: 230,
      minWidth: 230,
    },
    ...(AppStore.client?.canInviteOrRemoveAzure
      ? [
          {
            label: 'Display Name',
            valueField: 'displayName',
            width: 190,
            minWidth: 190,
          },
        ]
      : []),
    {
      label: 'Results',
      valueField: 'result',
      width: 190,
      minWidth: 190,
    },
  ];

  const onSelectTab = (id: number) => {
    const selectedTab = resultTabs.find((tab) => tab.id === id);
    if (selectedTab?.disabled) {
      return;
    }
    setSelectedTabId(id);
    const labelText = tableTabTitle[ResultTabIds[id].toLowerCase()];
    setSelectTabLabel(labelText);
  };

  const renderTabContent = (tabId: number) => {
    switch (tabId) {
      case ResultTabIds.ERRORS:
        return (
          <SystemUserImportResultTabContainer
            tableColumns={tableColumns}
            importSystemUsers={props.processedUser.errors}
          />
        );
      case ResultTabIds.DUPLICATES:
        return (
          <SystemUserImportResultTabContainer
            tableColumns={tableColumns}
            importSystemUsers={props.processedUser.duplicates}
          />
        );
      case ResultTabIds.READYFORIMPORT:
        return (
          <SystemUserImportResultTabContainer
            tableColumns={tableColumns}
            importSystemUsers={props.processedUser.readyForImport}
          />
        );
      default:
        return null;
    }
  };

  const renderPageTabs = () => {
    return (
      <PageTab
        activeTab={selectedTabId}
        tabs={resultTabs}
        onSelectTab={onSelectTab}
        cssClass={Style.importResultTab}
        tabMenuClass={Style.customTab}>
        {resultTabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <div className={Style.selectTabLabel}>{selectTabLabel}</div>
            <div className={Style.uploadForm}>{renderTabContent(tab.id)}</div>
          </TabContent>
        ))}
      </PageTab>
    );
  };

  return <div className={Style.importResultTabWrapper}>{renderPageTabs()}</div>;
};

export default observer(ProjectDetailsTabs);
