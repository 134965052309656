import { serverAxios } from '../api';
export interface IEditTaskAssigneesRequest {
  projectNumber: string;
  taskId: number;
  reassignedUserIds: number[];
  isSingleApproverRequired: boolean;
}
export async function reassignTaskUsers(request: IEditTaskAssigneesRequest, abortSignal?: AbortSignal): Promise<void> {
  await serverAxios.post('api/task/reassignTaskUsers', request, { signal: abortSignal });
}
