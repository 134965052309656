import { IReason } from '../../../../api/authenticated/transmittals/getTransmittalReasons';

export interface IClonedMailTypes {
  id: number;
  tempId: string;
  mailType: string;
  abbreviation: string;
  order: number;
  archived: boolean;
  type: 'ADD' | 'EDIT';
  errors: { mailType: string; abbreviation: string };
}

export interface IMailReasonModel extends IReason {
  tempId: string;
  tempTransmittalTypeId: string;
  type: 'ADD' | 'EDIT';
  errors: { title: string; namingCode: string };
}

export const TransmittalReasonFiledNames = {
  TITLE: 'title',
  NAMING_CODE: 'namingCode',
};

export const TransmittalMailTypeFiledNames = {
  MAIL_TYPE: 'mailType',
  ABBREVIATION: 'abbreviation',
};

export const TransmittalFiledLabels = {
  MAIL_TYPE_TITLE: 'Mail Type',
  MAIL_REASON_TITLE: 'Mail Reason',
  ABBREVIATION: 'Abbreviation',
};
