import { serverAxios } from '../api';

export interface IUploadTaskAttachmentFileRequest {
  blob: Blob;
  projectNumber: string;
  taskId: number;
  attachmentFileId: number;
  index: number;
  offset: number;
  isLastChunk: boolean;
}

export async function uploadTaskAttachmentFile(
  request: IUploadTaskAttachmentFileRequest,
  abortSignal?: AbortSignal
): Promise<number> {
  const formData = new FormData();
  formData.append('file', request.blob);
  formData.append('projectNumber', request.projectNumber);
  formData.append('taskId', request.taskId.toString());
  formData.append('attachmentFileId', request.attachmentFileId.toString());
  formData.append('index', request.index.toString());
  formData.append('offset', request.offset.toString());
  formData.append('isLastChunk', request.isLastChunk.toString());
  const result = await serverAxios.post<number>('api/task/uploadAttachmentFile', formData, { signal: abortSignal });
  return result.data;
}
