import React, { FC } from 'react';
import { IValidationImportResultModel } from './models/ValidationImportUserModel';
import { ITableColumn } from '../../../../common/interfaces/TableColumn';
import UserImportResultTable from './UserImportResultTable';

export interface IUserImportResultTabContainerProps {
  tableColumns: ITableColumn[];
  importSystemUsers: IValidationImportResultModel[];
}

const UserImportResultTabContainer: FC<IUserImportResultTabContainerProps> = ({ tableColumns, importSystemUsers }) => {
  return <UserImportResultTable tableColumns={tableColumns} tableImportSystemUsers={importSystemUsers} />;
};

export default UserImportResultTabContainer;
