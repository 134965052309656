import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import EditFileMetadataStore from './EditFileMetadataStore';

interface IEditFileMetadataActionBarProps {
  onCancel: (isSaving: boolean) => void;
}
const EditFileMetadataActionBar: FC<IEditFileMetadataActionBarProps> = (props) => {
  const { onCancel } = props;

  const onSave = async () => {
    EditFileMetadataStore.setLoading(true);
    await EditFileMetadataStore.saveMetadata();
    EditFileMetadataStore.setLoading(false);

    if (!EditFileMetadataStore.showError) {
      EditFileMetadataStore.onCancel();
      onCancel(true);
    }
  };
  return (
    <>
      <PrimaryButton disabled={!EditFileMetadataStore.enabledSave} onClick={onSave}>
        Save
      </PrimaryButton>
      <SecondaryButton
        onClick={() => {
          onCancel(false);
          EditFileMetadataStore.onCancel();
        }}>
        Cancel
      </SecondaryButton>
    </>
  );
};

export default observer(EditFileMetadataActionBar);
