import { format, isValid, parseISO } from 'date-fns';
import { isNumber } from './miscUtils';

export const localDueDateFormat = 'yyyy-MM-dd hh:mm:ss';
const DEFAULT_LOCALE = 'en-AU';
export const FILE_CREATED_DATE = 'yyyyMMdd';

/**
 * Identifies an ISO date in a string
 * @param value value to test
 * @returns true if string holds an ISO date, otherwise false
 */
export function isIsoDateString(value: unknown): boolean {
  if (value && typeof value === 'string' && !isNumber(value)) {
    const isoDate = parseISO(value);
    const valid = isValid(isoDate);
    return valid;
  }

  return false;
}

/**
 * Converts strings in an object or array that are ISO dates to actual dates.
 * Mutates the object.
 * @param body object to convert
 */
export function handleDates(body: unknown): void {
  if (body === null || body === undefined || typeof body !== 'object') return;

  for (const key in body) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = new Date(value);
    else if (typeof value === 'object') handleDates(value); // nested objects or arrays
  }
}

/**
 * Remove time component from given date
 * @param dateTime date object
 * @returns date object without time component
 */
export function dateOnly(dateTime: Date) {
  return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
}

/**
 * Formats date object consistently across the application
 * @param dateTime date object to format
 * @returns formatted date object
 */
export function formatDate(dateTime?: Date): string {
  if (!dateTime) return '';

  const date = dateOnly(dateTime);
  return date.toLocaleDateString(DEFAULT_LOCALE);
}

/**
 * Gets user's locale string and returns date format according to the locale
 * @returns date format string
 */
export function getDateFormatPattern(): string {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
    switch (part.type) {
      case 'day':
        return 'd'.repeat(part.value.length);
      case 'month':
        return 'M'.repeat(part.value.length);
      case 'year':
        return 'y'.repeat(part.value.length);
      case 'literal':
        return part.value;
      default:
        return '';
    }
  };

  return new Intl.DateTimeFormat(DEFAULT_LOCALE).formatToParts(new Date()).map(getPatternForPart).join('');
}

/**
 * Get date string with format
 * @param dateTime date object to format
 * @param pattern string pattern to format, by default using en-AU local
 * @returns date string with format
 */
export function dateTimeFormat(dateTime?: Date, pattern?: string): string {
  if (!dateTime) return '';

  if (!pattern) return dateTime.toLocaleDateString(DEFAULT_LOCALE);

  return format(dateTime, pattern);
}

export function dateTime12HFormat(dateTime: Date): string {
  return dateTime
    .toLocaleString(DEFAULT_LOCALE, {
      hour12: true,
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(',', '')
    .toUpperCase();
}
