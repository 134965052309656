import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/HistoryRelease.module.scss';
import Icon from '../../shared/Icon';

export interface IFileContainerRevisionLabelProps {
  formattedRevision: string;
}

const FileContainerRevisionLabel: FC<IFileContainerRevisionLabelProps> = ({ formattedRevision }) => {
  return (
    <div className={Style.revisionLabel}>
      <Icon name="upload" className={Style.uploadIcon}></Icon>
      <div>Revision {formattedRevision}</div>
    </div>
  );
};

export default observer(FileContainerRevisionLabel);
