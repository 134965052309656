import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ITransmittalMessage } from '../../api/authenticated/transmittals/getTransmittal';
import { Accordion, AccordionPanel } from '@aurecon-creative-technologies/styleguide';
import { formatDate } from '../../utils/dateUtils';
import SecondaryButton from '../shared/SecondaryButton';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import { UserManagementTypeEnum } from '../../enums/UserManagementTypeEnum';
import SeeMoreLink from '../shared/SeeMoreLink';
import ThreeUserCircles from '../shared/ThreeUserCircles';
import Icon from '../shared/Icon';
import parse from 'html-react-parser';
import Styles from './styles/TransmittalDetails.module.scss';
import convertHTMLtoText from '../../utils/convertHTMLtoText';
import UserTooltip from '../shared/UserTooltip';
import { getUserNameInitials } from '../../utils/pipe';
import { runInAction } from 'mobx';

interface ITransmittalResponseProps {
  responses: ITransmittalMessage[];
  showResponseFilesModal?: (id: number) => void;
}

const TransmittalResponse: FC<ITransmittalResponseProps> = (props) => {
  const { responses, showResponseFilesModal } = props;
  const [activePanelId, setActivePanelId] = useState<string>('');

  useEffect(() => {
    if (!responses?.length) return;
    runInAction(() => {
      responses[0].selected = true;
    });
    setActivePanelId(responses[0].id.toString());
  }, [responses]);

  const totalResponseFiles = (response: ITransmittalMessage) => {
    return (
      response.transmittalMessageReleasedFiles.flatMap((x) => x.containerFiles).length +
      response.transmittalMessageAttachmentFiles.length
    );
  };

  const handlePanelToggle = (panelId: number) => {
    responses.forEach((x) => {
      x.selected = x.id === panelId;
    });
    setActivePanelId(panelId.toString());
  };

  const totalNotificationUsers = (response: ITransmittalMessage) => {
    return TransmittalDetailsStore.getNotifyUsersFromSelectedResponse(response.id).length;
  };

  const openResponseFilesModal = (responseId) => {
    showResponseFilesModal?.(responseId);
  };

  const generatePanelLeftTitle = (msg: ITransmittalMessage) => {
    return (
      <div className={Styles.panelLabel}>
        <div className={Styles.messageSummaries}>
          <div className={Styles.leftLabel}>
            {msg.selected ? (
              <>
                <span>
                  <Icon name="reply" className={Styles.detailIcon} />
                  <UserTooltip
                    initials={getUserNameInitials(msg.createdByUserName)}
                    name={msg.createdByUserName}
                    email={msg.createdByUserEmail}
                  />
                </span>
                <ThreeUserCircles
                  users={TransmittalDetailsStore.getNotifyUsersFromSelectedResponse(msg.id).map((u) => ({
                    id: u.id,
                    type: UserManagementTypeEnum.User,
                    initials: u.initials,
                    email: u.email,
                    name: u.name,
                  }))}>
                  <SeeMoreLink
                    onClick={() => {
                      TransmittalDetailsStore.showNotifierModal = true;
                    }}
                  />
                </ThreeUserCircles>
              </>
            ) : (
              <>
                <span>
                  <Icon name="reply" className={Styles.detailIcon} />
                  <UserTooltip
                    userCircleCssClass={Styles.disabledUserCircle}
                    initials={getUserNameInitials(msg.createdByUserName)}
                  />
                </span>
                {!!totalNotificationUsers(msg) && (
                  <span>
                    <Icon name="notifications" className={Styles.detailIcon} /> {totalNotificationUsers(msg)}
                  </span>
                )}
                {!!totalResponseFiles(msg) && (
                  <span>
                    <Icon name="description" className={Styles.detailIcon} /> {totalResponseFiles(msg)}
                  </span>
                )}
              </>
            )}
          </div>
          <div className={Styles.rightLabel}>{formatDate(msg.createdDate)}</div>
        </div>
        {!msg.selected && (
          <>
            <div className={Styles.messageSubject}>
              <strong>{msg.subject}</strong>
            </div>
            <div className={Styles.messageMessage}>{convertHTMLtoText(msg.message)}</div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {!!responses.length && (
        <Accordion
          cssClass={Styles.transmittalDetailAccordion}
          activePanelIds={activePanelId}
          onPanelToggle={(id) => handlePanelToggle(Number(id))}
          headingIconColour="#f37021">
          {responses.map((msg) => (
            <AccordionPanel
              panelId={msg.id.toString()}
              key={msg.id}
              label={generatePanelLeftTitle(msg)}
              cssHeaderClass={Styles.responsePanelHeader}>
              <div className={Styles.contentSubject}>{parse(msg.subject)}</div>
              <div className={Styles.contentMessage}>{parse(msg.message)}</div>
              {!!totalResponseFiles(msg) && (
                <div className={Styles.actionPanel}>
                  <SecondaryButton onClick={() => openResponseFilesModal(msg.id)} className={Styles.btnIcon}>
                    <Icon name="description" />
                    View Files ({totalResponseFiles(msg)})
                  </SecondaryButton>
                </div>
              )}
            </AccordionPanel>
          ))}
        </Accordion>
      )}
    </>
  );
};

export default observer(TransmittalResponse);
