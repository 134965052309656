import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as RequiredAllUserIcon } from '../../assets/taskIcons/RequiredAllUserIcon.svg';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationHistoryItem.module.scss';
import { formatDate } from '../../utils/dateUtils';
import { Toggle } from '@aurecon-creative-technologies/styleguide';

export interface ITaskInformationHistoryRequiredAllUserProps {
  item: IHistoryItem;
}

const TaskInformationHistoryRequiredAllUser: FC<ITaskInformationHistoryRequiredAllUserProps> = ({ item }) => {
  return (
    <>
      <RequiredAllUserIcon />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            <strong>{item.byUser.name}</strong> has changed
            <Toggle value={!item.allApproversRequired} showOnOff={true} hideValue onLabel="" offLabel="" disabled />
            Required all users to approve
          </span>
        </div>
        <div className={Style.historyItemDetailDate}>{formatDate(item.eventDateTime)}</div>
      </div>
    </>
  );
};

export default observer(TaskInformationHistoryRequiredAllUser);
