import { serverAxios } from '../api';
import { ISystemUser } from './interfaces/user.interface';
import { SystemUsersFilter } from './SystemUsersFilterClass';

export async function getSystemUsers(condition: SystemUsersFilter, abortSignal?: AbortSignal): Promise<ISystemUser[]> {
  const result = await serverAxios.get<{ users: ISystemUser[] }>('api/um/systemusers', {
    params: condition,
    signal: abortSignal,
  });
  return result.data?.users ?? [];
}
