import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../shared/Icon';
import TeamChartStore from './TeamChartStore';
import Style from './styles/TeamChart.module.scss';
import { ITeamChartDeliveryTeamUser } from './interface/TeamChart.interface';
import { Button, Tooltip } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import UserTooltipModal from './UserTooltipModal';
import { useTeamChartContext } from './TeamChartStoreContext';

interface IDeliveryTeamUserGroupProps {
  deliveryTeamId: number;
  users: ITeamChartDeliveryTeamUser[];
}

const DeliveryTeamUser: FC<IDeliveryTeamUserGroupProps> = (props) => {
  const context = useTeamChartContext();
  const { deliveryTeamId, users } = props;
  const renderItemContent = (user) => {
    return (
      <div className={Style.itemTooltip}>
        <div>
          <div className={Style.userName}>{user.name}</div>
        </div>
        {user.deliveryTeamRoleTitle && (
          <div className={Style.deliveryTeamRoleGroup}>
            <div className={`${Style.deliveryTeamRoleTitle} ${Style.deliveryTeam}`}>
              <div className={Style.deliveryTeamRolePersonIcon}>
                <Icon name="people" className={Style.listIcon}></Icon>
              </div>
              <div>{`Delivery Team ${user.deliveryTeamRoleTitle}`}</div>
            </div>
          </div>
        )}
      </div>
    );
  };
  const renderItems = (user: ITeamChartDeliveryTeamUser) => {
    return (
      <li
        key={user.id}
        className={classNames(Style.taskTeamItem, {
          [Style.active]: user.check,
          [Style.disabled]: user.disabled,
        })}>
        <button
          id={`team-chart-delivery-team-${user.id}`}
          hidden
          onClick={() => {
            !context.disableDeliveryTeam && TeamChartStore.addOrRemoveDeliveryTeamUserById(user, props.deliveryTeamId);
          }}
        />
        <label className={Style.teamChartUserItem} htmlFor={`team-chart-delivery-team-${user.id}`}>
          {(user.disabled || user.check) && (
            <Tooltip cssClass={Style.tooltipWrapper} show={<div>User has been selected</div>} defaultUp={false}>
              {renderItemContent(user)}
            </Tooltip>
          )}
          {!user.disabled && !user.check && renderItemContent(user)}
          <div className={Style.iconInfoWrapper}>
            <Tooltip
              cssClass={Style.tooltipWrapper}
              show={
                <UserTooltipModal
                  initials={user.initials}
                  name={user.name}
                  email={user.email}
                  groupTitle={'Delivery Team User'}
                />
              }
              defaultUp={false}>
              <div className={Style.itemTooltip}>
                <div className={Style.iconInfo}>
                  <Icon name="info_outlined" className={Style.listIcon}></Icon>
                </div>
              </div>
            </Tooltip>
          </div>
        </label>
      </li>
    );
  };
  return (
    <>
      <div
        className={classNames(Style.groupSelect, {
          [Style.active]: TeamChartStore.activeDTAllMembersIds.has(deliveryTeamId),
        })}>
        {' '}
        <Icon name="people" className={Style.listIcon}></Icon>
        <Button
          cssClass={classNames({
            [Style.disabled]: context.disableDeliveryTeam,
          })}
          type="text"
          label="All Delivery Team Authorisers"
          onClick={() => !context.disableDeliveryTeam && TeamChartStore.selectAllAuthoriserUserInDT(deliveryTeamId)}
        />
      </div>
      <div>
        <ul className={Style.taskTeamWrapper}>{users.map((user: ITeamChartDeliveryTeamUser) => renderItems(user))}</ul>
      </div>
    </>
  );
};
export default observer(DeliveryTeamUser);
