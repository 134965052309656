import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Pill, Tooltip } from '@aurecon-creative-technologies/styleguide';
import { TaskType } from '../../common/enums/TaskType';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import Style from './styles/WorkflowPill.module.scss';
import { classNames } from '../../utils/miscUtils';

const TaskTypeAbbreviation = {
  [TaskType.TaskTeamReview]: 'TTR',
  [TaskType.ShareApproval]: 'SR',
  [TaskType.DeliveryTeamReview]: 'DTR',
  [TaskType.PublishAuthorise]: 'PAuth',
  [TaskType.PublishAccept]: 'PAcpt',
};

export interface IWorkflowPillProps {
  taskTypeId: TaskType | null;
}

const WorkflowPill: FC<IWorkflowPillProps> = ({ taskTypeId }) => {
  if (taskTypeId === null) return null;
  return (
    <Tooltip show={TaskTypeText[taskTypeId]} defaultUp>
      <Pill
        colour={1}
        size="small"
        cssClass={classNames(
          [taskTypeId === TaskType.DeliveryTeamReview, Style.deliveryTeamReview],
          [taskTypeId === TaskType.PublishAccept, Style.publishAccept],
          [taskTypeId === TaskType.PublishAuthorise, Style.publishAuthorise],
          [taskTypeId === TaskType.ShareApproval, Style.shareApproval],
          [taskTypeId === TaskType.TaskTeamReview, Style.taskTeamReview]
        )}>
        {TaskTypeAbbreviation[taskTypeId]}
      </Pill>
    </Tooltip>
  );
};

export default observer(WorkflowPill);
