import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import SecondaryButton from '../../shared/SecondaryButton';
import HistoryDate from './HistoryDate';
import { IHistoryItem, IReleasedHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import Style from './styles/HistoryRelease.module.scss';
import { getFileSizeDetails } from '../../../utils/miscUtils';
import DownloadModal from '../../shared/DownloadModal';
import ErrorModal from '../../shared/ErrorModal';
import HistoryContainerFile from './HistoryContainerFile';
import { Accordion, AccordionPanel, Button } from '@aurecon-creative-technologies/styleguide';
import Icon from '../../shared/Icon';
import HistoryNativeContainerFile from './HistoryNativeContainerFile';
import HistoryContainerFileUpdate from './HistoryContainerFileUpdate';
import { chain } from 'lodash';
import { formatDate } from '../../../utils/dateUtils';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import FileContainerRevisionLabel from './FileContainerRevisionLabel';

export interface IHistoryReleaseProps {
  item: IHistoryItem;
  release: IReleasedHistoryItem;
}

const HistoryRelease: FC<IHistoryReleaseProps> = ({ item, release }) => {
  const { totalFileSize, isOverLimit } = getFileSizeDetails(FileInformationStore.filesSize);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [panelIds, setPanelIds] = useState<Set<string>>(new Set<string>());
  const [panelFileDataHistoryIds, setPanelFileDataHistoryIds] = useState<Set<string>>(new Set<string>());
  const [showMorePage, setShowMorePage] = useState<number>(1);
  const showMoreFileNumber = 5;

  const releaseItemContainerFiles =
    FileInformationStore.transmittalId && FileInformationStore.transmittalMessageId
      ? item.releaseHistoryItem?.containerFiles.filter((x) =>
          FileInformationStore.file?.containerFiles?.map((m) => m.containerFileId).includes(x.containerFileId)
        )
      : item.releaseHistoryItem?.containerFiles;

  const showMorePageCount =
    releaseItemContainerFiles && releaseItemContainerFiles.length <= showMoreFileNumber
      ? 1
      : Math.ceil(releaseItemContainerFiles!.length / showMoreFileNumber);

  const onExpandRevision = (id: string) => {
    const newIds = new Set<string>(panelIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);
    setPanelIds(newIds);
  };
  const onFileDataHistory = (id: string) => {
    const newIds = new Set<string>(panelFileDataHistoryIds);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);
    setPanelFileDataHistoryIds(newIds);
  };
  const onShowMoreClick = () => {
    setShowMorePage(showMorePage + 1);
  };

  const groupedFileUpdateHistory = () => {
    return chain(item.releaseHistoryItem?.fileContainerUpdateHistory)
      .groupBy((x) => formatDate(x.updatedDate))
      .map((value, key) => ({ fileUpdateHistoryId: key, updatedDate: value[0].updatedDate, fileUpdateHistory: value }))
      .value();
  };

  const downloadAllOnClick = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_DOWNLOAD);

    if (isOverLimit) {
      setShowDownloadModal(true);
    } else {
      FileInformationStore.download(
        (release.containerFiles || []).map(
          (item) =>
            ({
              ...release,
              containerFileId: item.containerFileId ?? FileInformationStore.file?.containerFileId,
            } as IReleasedHistoryItem)
        )
      );
    }
  };
  return (
    <>
      <li>
        {item.releaseHistoryItem?.nativeContainerFileHistories.map((nativeFileHistory) => {
          return (
            <HistoryNativeContainerFile
              item={nativeFileHistory}
              key={`${nativeFileHistory.currentNativeFilename}-${nativeFileHistory.updatedDate}`}
            />
          );
        })}
      </li>
      {!!groupedFileUpdateHistory().length &&
        groupedFileUpdateHistory().map((m) => {
          return (
            <div className={Style.fileHistoryItem} key={m.fileUpdateHistoryId}>
              <HistoryDate dateTime={m.updatedDate} />
              <Accordion
                activePanelIds={Array.from(panelFileDataHistoryIds)}
                onPanelToggle={() => onFileDataHistory(m.fileUpdateHistoryId)}
                headingBgColour="#FAFAFA"
                panelBgColour="#FAFAFA"
                headingFontColour="#000000"
                cssClass={Style.taskTeamAccordion}>
                <AccordionPanel
                  key={m.fileUpdateHistoryId}
                  panelId={m.fileUpdateHistoryId}
                  label={
                    <div>
                      <Icon name="description" className={Style.icon} />
                      <span>File Data Changed</span>
                    </div>
                  }>
                  {m.fileUpdateHistory.map((fileUpdateHistory) => {
                    return (
                      <HistoryContainerFileUpdate
                        item={fileUpdateHistory}
                        key={fileUpdateHistory.fileContainerUpdateHistoryId}
                      />
                    );
                  })}
                </AccordionPanel>
              </Accordion>
            </div>
          );
        })}

      <li className={Style.item}>
        <Accordion headingFontColour="#000000" activePanelIds={Array.from(panelIds)} onPanelToggle={onExpandRevision}>
          <AccordionPanel
            panelId="exPanel"
            label={<FileContainerRevisionLabel formattedRevision={release.formattedRevision} />}>
            {release.suitabilityCode && (
              <p className={Style.suitability}>{release.suitabilityCode + ' ' + release.suitabilityTitle}</p>
            )}
            <HistoryDate dateTime={item.eventDateTime} />
            {releaseItemContainerFiles?.slice(0, showMorePage * showMoreFileNumber).map((containerFile) => {
              return (
                <HistoryContainerFile
                  item={containerFile}
                  key={containerFile.containerFileId}
                  setShowErrorModal={setShowDownloadModal}
                  release={item.releaseHistoryItem!}></HistoryContainerFile>
              );
            })}

            {showMorePage < showMorePageCount && (
              <Button type="text" onClick={onShowMoreClick} cssClass={Style.showMoreButton} label="Show More" />
            )}
            {showMorePageCount > 1 && showMorePage === showMorePageCount && (
              <Button
                type="text"
                onClick={() => setShowMorePage(1)}
                cssClass={Style.showLessButton}
                label="Show Less"
              />
            )}
            <div className={Style.downloadAll}>
              <SecondaryButton
                disabled={FileInformationStore.busyReleasedHistoryItem[release.releasedFileContainerId]}
                onClick={downloadAllOnClick}>
                Download all files
              </SecondaryButton>
            </div>
          </AccordionPanel>
        </Accordion>
      </li>
      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FileInformationStore.download([release]);
          setShowDownloadModal(false);
        }}
      />
      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={FileInformationStore.errorCode}
          errorMessage={FileInformationStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(HistoryRelease);
