import { serverAxios } from '../api';
import { IExportUserCSV } from './interfaces/user.interface';

export async function getProjectUsersExport(
  projectNumber: string,
  abortSignal?: AbortSignal
): Promise<IExportUserCSV[]> {
  const result = await serverAxios.get<{ users: IExportUserCSV[] }>('api/um/getallprojectusers', {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.users;
}
