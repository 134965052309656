import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/AddProject.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import ButtonSavingSpinner from '../../shared/ButtonSavingSpinner';
import CentreOnPage from '../../shared/CentreOnPage';
import ModalActions from '../../shared/ModalActions';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import { FormInput } from '@aurecon-creative-technologies/styleguide';
import FormControlError from '../../shared/FormControlError';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';

interface IAddProgrammeProps {
  onClose: (programmeName: string | null) => void;
}

const AddProgrammeModal: FC<IAddProgrammeProps> = (props) => {
  const [formDirty, setFormDirty] = useState(false);
  const [error, setError] = useState('');
  const [newProgrammeName, setNewProgrammeName] = useState('');
  const [saving, setSaving] = useState(false);
  const disabledCreateBtn = !formDirty || !!error || !newProgrammeName;

  const onFormInputChanged = (value) => {
    setNewProgrammeName(value);
    const programmeNameError = ProjectManagementStore.validateDuplicateProgrammeName(value);
    setError(programmeNameError);
    setFormDirty(true);
  };
  const handleCreateBtnClick = async () => {
    setSaving(true);
    await ProjectManagementStore.addProgramme(newProgrammeName);
    setSaving(false);
    props.onClose(newProgrammeName);
  };
  const handleCloseBtnClick = () => {
    props.onClose(null);
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>Create New Programme</h1>
        <div>
          <FormInput
            type="text"
            label="Enter a name for this Programme"
            placeholder="Enter Programme Name..."
            required
            value={newProgrammeName || ''}
            onChange={(value) => onFormInputChanged(value)}
          />
          <FormControlError message={error} formDirty={formDirty} showIcon={true} />
        </div>
        <ModalActions>
          <ButtonSavingSpinner isSaving={false}>
            <SecondaryButton onClick={handleCloseBtnClick}>Cancel</SecondaryButton>
          </ButtonSavingSpinner>
          <PrimaryButton
            disabled={disabledCreateBtn}
            loading={saving}
            onClick={() => {
              void (async () => {
                await handleCreateBtnClick();
              })();
            }}>
            Create
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(AddProgrammeModal);
