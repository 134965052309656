import { makeAutoObservable, runInAction } from 'mobx';
import TransmittalsStore from './TransmittalsStore';

export class ActionBarStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public setSelectedTab() {
    runInAction(() => {
      TransmittalsStore.setTabFilterCondition();
    });
  }

  public showSelectAllBtn() {
    return TransmittalsStore.searchTransmittals.some((t) => !t.checked);
  }

  public showSelectClear() {
    return !!TransmittalsStore.totalSelected;
  }

  public clearSelectionTransmittals() {
    TransmittalsStore.clearTransmittalSelected();
  }

  public selectedAllTransmittals() {
    TransmittalsStore.selectAllTransmittal();
  }

  public get getCSVExport() {
    return TransmittalsStore.getCSVData;
  }

  public get disabledExportBtn() {
    return TransmittalsStore.totalSelected === 0;
  }

  public get getTotalTransmittalSelected() {
    return TransmittalsStore.totalSelected;
  }
}

export default new ActionBarStore();
