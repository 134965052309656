import { AxiosError } from 'axios';
import { IResponseError } from '../api/authenticated/config/models/projectModel';

export function getErrorFields(error: AxiosError<IResponseError>) {
  const errorFields: { field: string; errors: string[] }[] = [];
  const responseData = error.response?.data;
  if (responseData?.errors) {
    const names = Object.getOwnPropertyNames(responseData.errors);
    if (names.length) {
      names.forEach((f) => {
        const err: string[] = [];
        if (responseData.errors[f].length) {
          responseData.errors[f].forEach((e) => {
            err.push(e.errorMessage);
          });
        }
        errorFields.push({
          field: f,
          errors: err,
        });
      });
    }
  }
  return errorFields;
}

export function getAxiosError(error: AxiosError<IResponseError>) {
  if (error?.code === 'ERR_NETWORK' || error?.response?.status === 0) {
    return {
      errorCode: 0,
      errorMessage: 'You are offline. Please check your connection and try again later.',
    };
  }
  const errorCode = error?.response?.status ?? 500;
  let errorMessage = 'Request invalid.';
  if (errorCode === 400 && error?.response?.data) {
    if (typeof error?.response?.data === 'string') {
      errorMessage = error?.response?.data;
    }
  }

  return {
    errorCode: errorCode,
    errorMessage: errorMessage,
  };
}
