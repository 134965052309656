import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import PrimaryIconButton from './PrimaryIconButton';
import Style from './styles/CloseButton.module.scss';

interface ICloseButtonProps {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
}

const CloseButton: FC<ICloseButtonProps> = ({ className, onClick, disabled }) => {
  return (
    <PrimaryIconButton
      className={classNames(Style.icon, className)}
      icon="close"
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default observer(CloseButton);
