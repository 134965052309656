import { serverAxios } from '../api';

export async function deleteMetadataField(
  projectNumber: string,
  metadataFieldId: number,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.delete(`/api/config/projects/metadata/delete`, {
    params: {
      projectNumber: projectNumber,
      metadataFieldId: metadataFieldId,
    },
    signal: abortSignal,
  });
  return result.data;
}
