import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import { formatDate } from '../../../utils/dateUtils';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import Style from './styles/Details.module.scss';
import { getFileSizeDetails, getFileSizeString } from '../../../utils/miscUtils';
import classNames from 'classnames';
import FilesStore from '../FilesStore';
import { sort } from '../../../utils/sortHelper';
import { SortTypes } from '../../../common/enums/SortType';
import PrimaryButton from '../../shared/PrimaryButton';
import ForgeViewerStore from '../../visualisation/forgeViewer/ForgeViewerStore';
import ErrorModal from '../../shared/ErrorModal';
import { FileExt } from '../../../common/constants/FileExt';
import getFileExtension from '../../../utils/fileUtils';
import DownloadModal from '../../shared/DownloadModal';
import { openViewer } from '../../../utils/pdfViewerUtils';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import { ApimsMetaDataType } from '../../../common/enums/MetadataFieldType';
import PdfViewer from '../../shared/PdfViewer';

const Details: FC = () => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showOpenFile, setShowOpenFile] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const file = FileInformationStore.file;
  useEffect(() => {
    if (!imgRef.current) return;
    const currentImageElement = imgRef.current;
    const currentDivElement = divRef.current;

    currentImageElement.addEventListener('mouseenter', () => setShowOpenFile(true));
    currentImageElement.addEventListener('mouseleave', () => setShowOpenFile(false));

    if (currentDivElement) {
      currentDivElement.addEventListener('mouseenter', () => setShowOpenFile(true));
      currentDivElement.addEventListener('mouseleave', () => setShowOpenFile(false));
    }

    return () => {
      currentImageElement.removeEventListener('mouseenter', () => setShowOpenFile(false));
      currentImageElement.removeEventListener('mouseleave', () => setShowOpenFile(false));

      if (currentDivElement) {
        currentDivElement.removeEventListener('mouseenter', () => setShowOpenFile(false));
        currentDivElement.removeEventListener('mouseleave', () => setShowOpenFile(false));
      }
    };
  });

  if (!file) return null;

  const accessibleDeliveryTeams = FileInformationStore.accessibleDeliveryTeams;
  const { totalFileSize, isOverLimit } = getFileSizeDetails(file.uploadedSize);

  const renderTemporaryAccessDetails = () => {
    const sortedDetails = sort(
      FileInformationStore.fileContainerTemporaryAccessDetails.slice(),
      'effectiveTo',
      SortTypes.DESC
    );
    const groupedDetails = sortedDetails.reduce(function (r, a) {
      r[a.effectiveTo] = r[a.effectiveTo] || [];
      r[a.effectiveTo].push(a.name);
      return r;
    }, Object.create(null));
    const mappedDetails = Object.keys(groupedDetails).map((key) => [key, groupedDetails[key]]);

    return (
      <li className={Style.field}>
        <div className={Style.fieldLabel}>Temporary Access expires on</div>
        <div className={Style.fieldValue}>
          {mappedDetails.map((group) => {
            const formattedEffectiveToDate = formatDate(new Date(group[0]));
            return (
              <div key={`${formattedEffectiveToDate}`}>
                <div className={Style.fieldValueLight}>{formattedEffectiveToDate}</div>
                {group[1].map((user: string) => {
                  return <div key={`${user}`}>{user}</div>;
                })}
              </div>
            );
          })}
        </div>
      </li>
    );
  };

  const handleForgeViewer = async (containerFileId: number | null) => {
    if (!containerFileId) return;
    await ForgeViewerStore.getForgeViewer(containerFileId, FileInformationStore.projectNumber);

    if (ForgeViewerStore.errorMessage && ForgeViewerStore.error) {
      setShowErrorModal(true);
      FilesStore.setError(ForgeViewerStore.error);
    }
  };

  const handleOpenFile = async () => {
    if (file.isForgeFile) {
      await handleForgeViewer(file.containerFileId);
      return;
    }
    openViewer(
      `projectNumber=${FileInformationStore.projectNumber}&id=${file.id}&fileRevisionId=${file.fileRevisionId}&containerFileId=${file.containerFileId}&releasedFileId=${file.releasedFileId}&transmittalId=${FileInformationStore.transmittalId}&transmittalMessageId=${FileInformationStore.transmittalMessageId}`
    );
  };

  const downloadOnClick = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_DOWNLOAD);

    if (isOverLimit) {
      setShowDownloadModal(true);
    } else {
      FilesStore.downloadFile({
        fileContainers: [
          {
            ...file,
            releasedFileId: FilesStore.fileContainerStateId === FileContainerState.Wip ? null : file.releasedFileId,
          },
        ],
        downloadAllContainerFile: true,
        fileContainerStateId: FilesStore.fileContainerStateId,
      });
    }
  };
  const renderNoPreview = () => {
    return (
      <div className={Style.previewBox}>
        <p className={Style.textPreviewBox}>No preview available</p>
        {(getFileExtension(file.originalFilename)?.toLocaleLowerCase() === FileExt.PDF || file.isForgeFile) &&
        file.fileRevisionId ? (
          <PrimaryButton className={Style.button} onClick={async () => await handleOpenFile()}>
            Open File
          </PrimaryButton>
        ) : (
          <PrimaryButton className={Style.button} onClick={downloadOnClick} disabled={FilesStore.fileBusy[file.id]}>
            Download
          </PrimaryButton>
        )}
      </div>
    );
  };

  const renderPreviewBox = () => {
    if (getFileExtension(file.originalFilename)?.toLocaleLowerCase() === FileExt.PDF)
      return (
        <div className={Style.previewBox}>
          <PdfViewer
            isOpeningPdf={false}
            file={FileInformationStore.file}
            projectNumber={FileInformationStore.projectNumber}
            handleOpenFile={handleOpenFile}
          />
        </div>
      );

    return FileInformationStore.filePreviewAvailable ? (
      <>
        {FileInformationStore.isLoadingThumbnail && <div className={Style.previewBox}>Loading...</div>}
        {!FileInformationStore.isLoadingThumbnail && FileInformationStore.thumbnail && (
          <div className={Style.previewBox}>
            <img
              ref={imgRef}
              alt={file.originalFilename}
              className={Style.thumbnailBox}
              src={FileInformationStore.thumbnail}
            />
            {showOpenFile &&
              (file.inSharePoint ? (
                <div ref={divRef} className={Style.previewButtonBox}>
                  <PrimaryButton
                    className={Style.button}
                    onClick={() =>
                      FilesStore.openFile({
                        ...file,
                        releasedFileId:
                          FilesStore.fileContainerStateId === FileContainerState.Wip ? null : file.releasedFileId,
                      })
                    }
                    disabled={FilesStore.fileBusy[file.id]}>
                    Open File
                  </PrimaryButton>
                </div>
              ) : (
                <div ref={divRef} className={Style.previewButtonBox}>
                  <PrimaryButton
                    className={Style.button}
                    onClick={downloadOnClick}
                    disabled={FilesStore.fileBusy[file.id]}>
                    Download
                  </PrimaryButton>
                </div>
              ))}
          </div>
        )}
        {!FileInformationStore.isLoadingThumbnail && !FileInformationStore.thumbnail && renderNoPreview()}
      </>
    ) : (
      renderNoPreview()
    );
  };

  return (
    <>
      {renderPreviewBox()}

      <h3 className={Style.fieldListHeading}>File Information</h3>
      <ul className={Style.fieldList}>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Filename</div>
          <div className={Style.fieldValue}>{file.title}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Original Filename</div>
          <div className={classNames([Style.fieldValue, Style.originalFilename])}>{file.originalFilename}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Uploaded By</div>
          <div className={Style.fieldValue}>{file.uploadedByUserName}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Modified</div>
          <div className={Style.fieldValue}>{formatDate(file.modifiedDate)}</div>
        </li>
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Task Team Originator</div>
          <div className={Style.fieldValue}>
            {file.deliveryTeamTitle}
            <strong> | {file.taskTeamTitle}</strong>
          </div>
        </li>
        {!!FileInformationStore.getFileContainerTemporaryAccessUsers.length && (
          <li className={Style.field}>
            <div className={Style.fieldLabel}>Temporary Access</div>
            <div className={Style.fieldValue}>
              {FileInformationStore.getFileContainerTemporaryAccessUsers.map((userName, i) => (
                <div className={Style.temporaryAccessUser} key={`${userName}-${i}`}>
                  {userName}
                </div>
              ))}
            </div>
          </li>
        )}
        {(FileInformationStore.fileContainerStateId === FileContainerState.Shared ||
          FileInformationStore.fileContainerStateId === FileContainerState.Published) && (
          <>
            <li className={Style.field}>
              <div className={Style.fieldLabel}>Suitability</div>
              <div className={Style.fieldValue}>{file.suitabilityTitle}</div>
            </li>
            <li className={Style.field}>
              <div className={Style.fieldLabel}>Revision</div>
              <div className={Style.fieldValue}>{file.formattedRevision}</div>
            </li>
          </>
        )}
        <li className={Style.field}>
          <div className={Style.fieldLabel}>Size</div>
          <div className={Style.fieldValue}>{getFileSizeString(file.uploadedSize)}</div>
        </li>
      </ul>
      <h3 className={Style.fieldListHeading}>Details</h3>
      <ul className={Style.fieldList}>
        {FileInformationStore.metadataFields.map((f) => (
          <li className={Style.field} key={f.title}>
            <div className={Style.fieldLabel}>{f.title}</div>
            <div className={Style.fieldValue}>{file[`fieldValue${f.fieldValueIndex}`]}</div>
          </li>
        ))}
        <li className={Style.field}>
          <div className={Style.fieldLabel}>
            {FileInformationStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern1)
              ?.description ?? ApimsMetaDataType.Pattern1}
          </div>
          <div className={Style.fieldValue}>{file.pattern1}</div>
        </li>
        {FilesStore.fileContainerStateId !== FileContainerState.Published && (
          <li className={Style.field}>
            <div className={Style.fieldLabel}>
              {FileInformationStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern2)
                ?.description ?? ApimsMetaDataType.Pattern2}
            </div>
            <div className={Style.fieldValue}>{file.pattern2}</div>
          </li>
        )}
        {FilesStore.fileContainerStateId === FileContainerState.Wip && (
          <li className={Style.field}>
            <div className={Style.fieldLabel}>
              {FileInformationStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern3)
                ?.description ?? ApimsMetaDataType.Pattern3}
            </div>
            <div className={Style.fieldValue}>{file.pattern3}</div>
          </li>
        )}
      </ul>
      {(FileInformationStore.fileContainerStateId == FileContainerState.Shared ||
        FileInformationStore.fileContainerStateId === FileContainerState.Published) && (
        <ul className={Style.fieldList}>
          <li className={Style.field}>
            <div className={Style.fieldLabel}>Visible to</div>
            <div className={Style.fieldValue}>
              {accessibleDeliveryTeams.length > 0 &&
                accessibleDeliveryTeams.map((f) => (
                  <div className={Style.accessibleDeliveryTeamTitle} key={f.accessibleDeliveryTeamId}>
                    {f.accessibleDeliveryTeamTitle}
                  </div>
                ))}
            </div>
          </li>
        </ul>
      )}
      {!!FileInformationStore.fileContainerTemporaryAccessDetails.length && renderTemporaryAccessDetails()}

      <DownloadModal
        fileSize={totalFileSize}
        showModal={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        downloadAction={() => {
          FilesStore.downloadFile({ fileContainers: [file] });
          setShowDownloadModal(false);
        }}
      />

      {showErrorModal && (
        <ErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={FilesStore.errorCode}
          errorMessage={FilesStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(Details);
