import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';
import { ITaskTeamUser } from './interfaces/user.interface';

export interface IDeliveryTeam {
  id: number;
  title: string;
  transmittalCode: string;
  taskTeams: ITaskTeam[];
  users: IDeliveryTeamUser[];
  isCurrentLogInUserAccessible: boolean;
}

export interface IDeliveryTeamUser {
  id: number;
  name: string;
  email: string;
  deliveryTeamRoleIds: DeliveryTeamRole[];
  deliveryTeamRoleTitle: string | null;
}

export interface ITaskTeam {
  id: number;
  title: string;
  transmittalCode: string;
  users: ITaskTeamUser[];
}

export async function getDeliveryTeamsTaskTeamsUsers(
  projectNumber: string,
  fileContainerStateId?: FileContainerState,
  taskTeamId?: number | null,
  abortSignal?: AbortSignal
): Promise<IDeliveryTeam[]> {
  const result = await serverAxios.get<{ deliveryTeams: IDeliveryTeam[] }>('api/um/deliveryteamstaskteamsusers', {
    params: {
      projectNumber: projectNumber,
      fileContainerStateId: fileContainerStateId,
      taskTeamId: taskTeamId,
    },
    signal: abortSignal,
  });
  return result.data.deliveryTeams;
}
