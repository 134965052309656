import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformationActions.module.scss';
import TaskInformationStore from './TaskInformationStore';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import { FormInput } from '@aurecon-creative-technologies/styleguide';
import TaskReassignModal from './taskReassignModal/TaskReassignModal';
import Icon from '../shared/Icon';

const TaskInformationActions: FC = () => {
  const [showReassignModal, setShowReassignModal] = useState(false);
  const approveButtonClick = () => {
    TaskInformationStore.approveTask();
  };

  const rejectButtonClick = () => {
    TaskInformationStore.rejectTask();
  };

  const reassignButtonClick = () => {
    setShowReassignModal(true);
  };

  return (
    <div className={Style.panel}>
      <div className={Style.comments}>
        <FormInput
          placeholder="Add a comment to this task (required for Rejecting)"
          value={TaskInformationStore.message ?? ''}
          onChange={(value) => TaskInformationStore.setMessage(value)}
          multiline
          multilineLimit={2000}
          cssClass={Style.commentInput}
          disabled={
            !TaskInformationStore.task?.canApprove &&
            !TaskInformationStore.task?.canReject &&
            !TaskInformationStore.task?.canReassign
          }
        />
      </div>
      <div className={Style.buttons}>
        <PrimaryButton
          className={Style.button}
          onClick={approveButtonClick}
          disabled={!TaskInformationStore.task?.canApprove}>
          Approve
        </PrimaryButton>
        <PrimaryButton
          className={Style.button}
          onClick={rejectButtonClick}
          disabled={!TaskInformationStore.task?.canReject || !TaskInformationStore.message}>
          Reject
        </PrimaryButton>
        <PrimaryButton
          className={Style.iconButton}
          onClick={approveButtonClick}
          disabled={!TaskInformationStore.task?.canApprove}>
          <Icon name={'check'} />
        </PrimaryButton>
        <PrimaryButton
          className={Style.iconButton}
          onClick={rejectButtonClick}
          disabled={!TaskInformationStore.task?.canReject || !TaskInformationStore.message}>
          <Icon name={'close'} />
        </PrimaryButton>
        <SecondaryButton
          disabled={!TaskInformationStore.task?.canReassign}
          className={Style.reassignButton}
          onClick={reassignButtonClick}>
          Reassign
        </SecondaryButton>
      </div>
      {showReassignModal && (
        <TaskReassignModal
          taskUniqueIdentifier={`#${TaskInformationStore.taskId}`}
          closeModal={() => setShowReassignModal(false)}
        />
      )}
    </div>
  );
};

export default observer(TaskInformationActions);
