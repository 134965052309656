import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ActionBar from '../../../shared/ActionBar';
import Styles from '../../../../styles/components/settings/systemAdminSettings/systemUsers/CreateSystemUser.module.scss';
import { Dropdown, FormInput, SearchBox } from '@aurecon-creative-technologies/styleguide';
import SystemUsersStore from '../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import { DropdownStyle } from '../../../../common/constants/Dropdown';
import PrimaryButton from '../../../shared/PrimaryButton';
import { debounce } from 'lodash';
import AppStore from '../../../../stores/AppStore';
import { SystemUserFieldValues } from '../../../../common/enums/SystemUsers';

const CreateSystemUser: FC = () => {
  useEffect(() => {
    return () => SystemUsersStore.clearCreateSystemUserForm();
  }, []);

  const debounceSearch = useMemo(() => debounce((value) => SystemUsersStore.searchAdUsers(value), 500), []);
  const handleSearchBoxChange = (value: string) => {
    debounceSearch(value);
  };

  if (!AppStore.client) return <></>;

  return (
    <>
      <ActionBar className={Styles.pageHeading}>
        <p className={Styles.pageHeadingLabel}>Add Users</p>
      </ActionBar>

      <div className={Styles.addForm}>
        <div className={Styles.userEmailInput}>
          <div className={Styles.label}>
            User Email <span className={Styles.requiredSymbol}>*</span>
          </div>
          {!AppStore.client.canInviteOrRemoveAzure ? (
            <SearchBox
              hideSearchButton
              disableDefaultMatching
              suggestions={SystemUsersStore.filteredUsers}
              onChange={(keyword) => handleSearchBoxChange(keyword)}
              onSearch={(keyword) => handleSearchBoxChange(keyword)}
              onSelect={(user) => SystemUsersStore.setSelectedAdUser(user)}
              triggerCleanup={SystemUsersStore.triggerCleanupSearchUser}
              onClear={() => SystemUsersStore.onChangeCreateSystemUser(SystemUserFieldValues.DISPLAY_NAME, '')}
            />
          ) : (
            <FormInput
              value={SystemUsersStore.addSystemUser.email}
              onChange={(value) => SystemUsersStore.onChangeCreateSystemUser(SystemUserFieldValues.EMAIL, value)}
              error={SystemUsersStore.addSystemUserEmailErrorMessage}
            />
          )}
        </div>

        <div className={Styles.displayNameInput}>
          <div className={Styles.label}>
            Display Name <span className={Styles.requiredSymbol}>*</span>
          </div>
          <FormInput
            value={SystemUsersStore.addSystemUser.displayName}
            onChange={(value) => SystemUsersStore.onChangeCreateSystemUser(SystemUserFieldValues.DISPLAY_NAME, value)}
            disabled={!AppStore.client?.canInviteOrRemoveAzure}
          />
        </div>

        <div className={Styles.roleInput}>
          <div className={Styles.label}>
            Role <span className={Styles.requiredSymbol}>*</span>
          </div>
          <Dropdown
            placeholder="Select Client User Role"
            items={SystemUsersStore.clientUserRoles}
            optionsHeight={DropdownStyle.defaultHeight}
            selectedItem={SystemUsersStore.addSystemUser.userRoleId}
            onSelectItem={(value) =>
              SystemUsersStore.onChangeCreateSystemUser(SystemUserFieldValues.USER_ROLE_ID, value)
            }
          />
        </div>

        <div>
          <div className={Styles.labelBtn}>Add</div>
          <PrimaryButton
            className={Styles.btnAdd}
            disabled={SystemUsersStore.disableAddButton}
            onClick={() => SystemUsersStore.onAddSystemUser()}>
            Add
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default observer(CreateSystemUser);
