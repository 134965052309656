import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import { Accordion, AccordionPanel, Button, Grid } from '@aurecon-creative-technologies/styleguide';
import TeamChartStore from './TeamChartStore';
import ExternalUser from './ExternalUser';
import DeliveryTeamUser from './DeliveryTeamUser';
import TaskTeamAccordion from './TaskTeamAccordion';
import { classNames } from '../../utils/miscUtils';
import { useTeamChartContext } from './TeamChartStoreContext';

const TeamChartContainer: FC = () => {
  const context = useTeamChartContext();
  const renderHeader = () => {
    return (
      <Grid row gap={8} cssClass={Style.rowItem}>
        <Grid item md={6}>
          <div className={Style.taskTeamHeader}>
            <span className={Style.title}>Delivery Team</span>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className={Style.taskTeamHeader}>
            <div>
              <span className={Style.title}>Task Team</span>
            </div>
            <div>
              <Button type="text" label="Expand All" onClick={TeamChartStore.expandAll} /> /{' '}
              <Button type="text" label="Collapse All" onClick={TeamChartStore.collapseAll} />
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {TeamChartStore.isShowFilterResult() && (
        <div>
          {renderHeader()}
          <Grid row gap={8} cssClass={Style.rowItem}>
            <Grid item md={6}>
              <div className={classNames(Style.leftPanel, [context.disableDeliveryTeam, Style.disablePanel])}>
                <Grid item md={12} cssClass={Style.accordianGroup}>
                  <Accordion
                    activePanelIds={Array.from(TeamChartStore.activeDTPanelIds)}
                    onPanelToggle={TeamChartStore.onDTPanelToggle}
                    headingBgColour="#FAFAFA"
                    panelBgColour="#FAFAFA"
                    headingFontColour="#000000"
                    cssClass={Style.taskTeamAccordion}>
                    {TeamChartStore.filteredProjectDeliveryTeamUsers.map((group) => (
                      <AccordionPanel
                        key={`DT_${group.deliveryTeamId}`}
                        panelId={`DT_${group.deliveryTeamId}`}
                        label={group.deliveryTeamTitle}>
                        <DeliveryTeamUser deliveryTeamId={group.deliveryTeamId} users={group.deliveryTeamUsers} />
                      </AccordionPanel>
                    ))}
                  </Accordion>
                </Grid>
              </div>
            </Grid>
            <Grid item md={6}>
              <Grid row cssClass={classNames([context.disableTaskTeam, Style.disablePanel])}>
                <Grid item md={12} cssClass={Style.accordianGroup}>
                  <TaskTeamAccordion />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!context.hideExternalSelection && (
            <Grid row gap={8} cssClass={Style.rowItem}>
              <div className={`${Style.thematicBreak} ${Style.second}`} />
              <Grid item md={6}>
                <div className={Style.leftPanel}>
                  <Grid>
                    <ExternalUser />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export default observer(TeamChartContainer);
