import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
let appInsightsInstance: ApplicationInsights;

const initAppInsights = (instrumentationKey: string): void => {
  appInsightsInstance = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsightsInstance.loadAppInsights();
};

const getAppInsightsInstance = (): ApplicationInsights => {
  if (!appInsightsInstance) {
    throw new Error("App Insights hasn't been initialized");
  }

  return appInsightsInstance;
};

const appInsightsTrackEvent = (eventName: string, customProperties?: ICustomProperties) => {
  const appInsights = getAppInsightsInstance();
  if (appInsights) appInsights.trackEvent({ name: eventName, properties: customProperties });
};

const appInsightsTrackPageView = (eventName: string, isLoggedIn = true) => {
  const appInsights = getAppInsightsInstance();
  if (appInsights) appInsights.trackPageView({ name: eventName, isLoggedIn: isLoggedIn });
};

export { initAppInsights, getAppInsightsInstance, appInsightsTrackEvent, appInsightsTrackPageView, reactPlugin };
