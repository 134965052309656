import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import UserCircle from '../shared/UserCircle';
import Icon from '../shared/Icon';
import { classNames } from '../../utils/miscUtils';

interface IUserProps {
  initials: string;
  name: string;
  email: string;
  groupTitle?: string;
}

const UserTooltipModal: FC<IUserProps> = (props) => {
  const { initials, name, email, groupTitle } = props;

  return (
    <div className={Style.initiatorUserTooltip}>
      <div>
        <UserCircle cssClass={Style.override} initials={initials} />
      </div>
      <div className={Style.userInfo}>
        <div className={Style.userName}>{name}</div>
        <div className={classNames(Style.taskTeam, Style.userEmail)}>{email}</div>
        {groupTitle && (
          <div className={Style.groupTitle}>
            <Icon name="people"></Icon>
            <span className={Style.groupTitleText}>{groupTitle}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default observer(UserTooltipModal);
