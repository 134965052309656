import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as ApprovedIcon } from '../../assets/taskIcons/ApprovedIcon.svg';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import { formatDate } from '../../utils/dateUtils';
import Style from './styles/TaskInformationHistoryItem.module.scss';

export interface ITaskInformationHistoryApprovedProps {
  item: IHistoryItem;
}

const TaskInformationHistoryApproved: FC<ITaskInformationHistoryApprovedProps> = ({ item }) => {
  return (
    <>
      <ApprovedIcon />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            <strong>Approved</strong> by <strong>{item.byUser.name}</strong>
          </span>
        </div>
        <div className={Style.historyItemDetailDate}>{formatDate(item.eventDateTime)}</div>
        {item.message && <div className={Style.historyItemDetailMessage}>{item.message}</div>}
      </div>
    </>
  );
};

export default observer(TaskInformationHistoryApproved);
