export const tableHeaderKeyValueFields = {
  field: {
    label: 'Metadata Field / Metadata Value',
    name: 'title',
  },
  abbreviation: {
    label: 'Abbreviation',
    name: 'code',
  },
  type: {
    label: 'Type',
  },
  parentCode: {
    label: 'Parent Code',
  },
  action: {
    label: 'Action',
  },
  value: {
    label: 'Metadata Value',
    name: 'title',
  },
};

export const getHeaders = (isEditMode: boolean) => {
  return [
    {
      label: tableHeaderKeyValueFields.field.label,
      style: { width: '40%', paddingLeft: 60 },
      align: 'left' as const,
    },
    {
      label: tableHeaderKeyValueFields.type.label,
      style: { width: '15%', paddingLeft: 30 },
      align: 'left' as const,
    },
    {
      label: tableHeaderKeyValueFields.abbreviation.label,
      style: { width: '25%', paddingLeft: 0 },
      align: 'left' as const,
    },
    {
      label: tableHeaderKeyValueFields.parentCode.label,
      style: { width: !isEditMode ? '22%' : '10%' },
      align: 'left' as const,
    },
    ...(isEditMode
      ? [
          {
            label: tableHeaderKeyValueFields.action.label,
            style: { width: '10%' },
            align: 'center' as const,
          },
        ]
      : []),
  ];
};

export const getFieldPaddingLeftMatrix = (label: string, isNested: boolean) => {
  const matrix = [
    { label: tableHeaderKeyValueFields.field.label, paddingLeft: isNested ? 30 : 10 },
    { label: tableHeaderKeyValueFields.type.label, paddingLeft: 15 },
    { label: tableHeaderKeyValueFields.abbreviation.label, paddingLeft: isNested ? 30 : 0 },
    { label: tableHeaderKeyValueFields.parentCode.label, paddingLeft: isNested ? 40 : 0 },
  ];

  return matrix.find((f) => f.label === label)?.paddingLeft ?? 0;
};

export const getFieldColumnWStyle = (isEditMode: boolean, column: string, isNested: boolean) => {
  const width = getHeaders(isEditMode).find((f) => f.label === column)?.style.width;
  return { width, paddingLeft: getFieldPaddingLeftMatrix(column, isNested) };
};

export const getFieldValuePaddingLeftMatrix = (label: string, isNested: boolean) => {
  const matrix = [
    { label: tableHeaderKeyValueFields.field.label, paddingLeft: 12 },
    { label: tableHeaderKeyValueFields.type.label, paddingLeft: 0 },
    { label: tableHeaderKeyValueFields.abbreviation.label, paddingLeft: isNested ? 10 : 5 },
    { label: tableHeaderKeyValueFields.parentCode.label, paddingLeft: isNested ? 32 : 0 },
  ];

  return matrix.find((f) => f.label === label)!.paddingLeft;
};

export const getFieldValueColumnWStyle = (isEditMode: boolean, column: string, isNested: boolean) => {
  const width = getHeaders(isEditMode).find((f) => f.label === column)?.style.width;
  return { width, paddingLeft: getFieldValuePaddingLeftMatrix(column, isNested) };
};
