import { isValidRegex, requiredValue } from '../../../../utils/miscUtils';

export const ProjectFields = {
  programmeId: 'programmeId',
  projectNumber: 'projectNumber',
  projectPhase: 'projectPhase',
  projectTitle: 'projectTitle',
  projectAdminUserIds: 'projectAdminUserIds',
  description: 'description',
  projectOwnerUserId: 'projectOwnerUserId',
  displayApps: 'displayApps',
  displayFiles: 'displayFiles',
  displayTasks: 'displayTasks',
  displayVisualisation: 'displayVisualisation',
  displayTransmittals: 'displayTransmittals',
  allowCollaborationOtherTaskTeams: 'allowCollaborationOtherTaskTeams',
};

export const ProjectWorkflowDefaultFields = {
  projectNumber: 'projectNumber',
  requireAllApprovers: 'requireAllApprovers',
  requireAllAuthorisers: 'requireAllAuthorisers',
  requireAllAcceptors: 'requireAllAcceptors',
};

export const AddProjectValidator = {
  [ProjectFields.projectNumber]: (value: string): string => validateProjectNumber('Project Number', value),
  [ProjectFields.projectTitle]: (value: string): string => validateProjectTitle('Project Name', value),
  [ProjectFields.projectAdminUserIds]: (value: number[]): string =>
    validateProjectAdminUsers('Project Admin Users', value),
};

export const UpdateProjectValidator = {
  [ProjectFields.projectTitle]: (value: string): string => validateProjectTitle('Project Name', value),
  [ProjectFields.projectTitle]: (value: string): string => validateProjectTitle('Project Name', value),
  [ProjectFields.projectAdminUserIds]: (value: number[]): string =>
    validateProjectAdminUsers('Project Admin Users', value),
};

const validateProjectNumber = (label: string, value: string) => {
  const requiredError = requiredValue(label, value);
  if (requiredError.length) return requiredError;
  const isValid = isValidRegex(value, { allowDashHyphen: true });
  if (!isValid) return `${label} should not contain special character`;
  return '';
};

const validateProjectTitle = (label: string, value: string) => {
  const requiredError = requiredValue(label, value);
  if (requiredError.length) return requiredError;
  const isValid = isValidRegex(value, { allowDashHyphen: true, allowMacron: true, allowSpace: true });
  if (!isValid) return `${label} should not contain special character`;
  return '';
};

const validateProjectAdminUsers = (label: string, value: number[]) => {
  if (!value.length) return `${label} required.`;
  return '';
};
