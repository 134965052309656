import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/Dot.module.scss';

export interface IDotProps {
  className?: string;
}

const Dot: FC<IDotProps> = ({ className }) => {
  return <span className={classNames(Style.dot, className)} />;
};

export default observer(Dot);
