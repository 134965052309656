import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import UserSelector, { IUserSelectorProps } from './UserSelector';
import { classNames } from '../../utils/miscUtils';
import Styles from './styles/UserSelectorExpandable.module.scss';

export type IUserSelectorExpandableProps = IUserSelectorProps & { className?: string };

const UserSelectorExpandable: FC<IUserSelectorExpandableProps> = ({ className, ...userSelectorProps }) => {
  return (
    <div className={classNames(Styles.expandable, className)}>
      <UserSelector {...userSelectorProps} />
    </div>
  );
};

export default observer(UserSelectorExpandable);
