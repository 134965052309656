import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import HistoryDate from './HistoryDate';
import { IUploadContentHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import Style from './styles/HistoryUpload.module.scss';
import { UploadContentHistoryType } from '../../../common/enums/UploadContentHistoryType';
import { dateTimeFormat } from '../../../utils/dateUtils';

interface IShareAccessBody {
  Id: number;
  Name: string;
  Email: string;
  EffectiveTo: string;
}

export interface IHistoryUploadProps {
  historyItem: IUploadContentHistoryItem;
}

const HistoryUploadContent: FC<IHistoryUploadProps> = ({ historyItem }) => {
  const getSupersedeBody = (body: string) => {
    return JSON.parse(body) as { FileName: string; RevisionNumber: string };
  };

  const getShareAccessBody = (body: string) => {
    const response = JSON.parse(body) as IShareAccessBody[];
    return response.sort((a, b) => {
      return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
    });
  };

  const renderSupersedingSection = () => (
    <div>
      <li className={Style.item}>
        <HistoryDate dateTime={historyItem.uploadedDate} />
        <div className={Style.title}>
          <strong>{historyItem.fileData ? getSupersedeBody(historyItem.fileData).FileName : ''}</strong>
        </div>
        <div className={Style.title}>
          <span>Revision {historyItem.fileData ? getSupersedeBody(historyItem.fileData).RevisionNumber : ''}</span>
        </div>
        <div className={Style.title}>
          <Icon name="check_circle" className={Style.icon} />
          <span>{historyItem.message}</span>
        </div>
        <div className={Style.title}>
          <Icon name="upload" className={Style.icon} />
          <span>Uploaded</span>
        </div>
        <div className={Style.user}>{historyItem.uploadedByUserName}</div>
      </li>
    </div>
  );

  const renderUploadSection = () => (
    <li className={Style.item}>
      <HistoryDate dateTime={historyItem.uploadedDate} />
      <div className={Style.title}>
        <Icon name="check_circle" className={Style.icon} />
        <span>{historyItem.message}</span>
      </div>

      <div className={Style.title}>
        <Icon name="upload" className={Style.icon} />
        <span>Uploaded</span>
      </div>
      <div className={Style.user}>{historyItem.uploadedByUserName}</div>
    </li>
  );

  const renderTemporaryAccessSection = () => {
    if (!historyItem.fileData) return <></>;

    const fileData = getShareAccessBody(historyItem.fileData);

    return (
      <li className={Style.item}>
        <HistoryDate dateTime={historyItem.uploadedDate} />
        {fileData.map((x) => (
          <>
            <div className={Style.title}>
              <Icon name="people" className={Style.icon} />
              <span>Temporary Access to</span>
            </div>
            <div className={Style.titleTemporaryAccess}>
              <div className={Style.listItem} key={x.Email}>
                {x.Name}
              </div>
            </div>
            {x.EffectiveTo && (
              <>
                <div className={Style.title}>
                  <Icon name="schedule" className={Style.icon} />
                  <span>Access expires on</span>
                </div>
                <div className={Style.titleTemporaryAccess}>
                  <div className={Style.listItem} key={x.EffectiveTo}>
                    {dateTimeFormat(new Date(x.EffectiveTo))}
                  </div>
                </div>
              </>
            )}
          </>
        ))}
      </li>
    );
  };

  const renderItemByType = () => {
    if (historyItem.uploadContentHistoryTypeId === UploadContentHistoryType.SupersedingForReference) {
      return renderSupersedingSection();
    }
    if (historyItem.uploadContentHistoryTypeId === UploadContentHistoryType.Upload) {
      return renderUploadSection();
    }
    if (historyItem.uploadContentHistoryTypeId === UploadContentHistoryType.TemporaryAccess) {
      return renderTemporaryAccessSection();
    }
  };

  return <>{renderItemByType()}</>;
};

export default observer(HistoryUploadContent);
