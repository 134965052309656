export const SystemUsersTableHeaderKeyValueFields = {
  UserName: {
    label: 'User Name',
    value: 'name',
  },
  UserEmail: {
    label: 'User Email',
    value: 'email',
  },
  LastLogin: {
    label: 'Last Login',
    value: 'lastLoginDate',
  },
  UserRole: {
    label: 'User Role',
    value: 'clientUserRoleId',
  },
  Action: {
    label: 'Action',
    value: 'id',
  },
  Reactivate: {
    label: 'Reactivate',
    value: 'id',
  },
};

export default { SystemUsersTableHeaderKeyValueFields };
