import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Icon from '../shared/Icon';
import Style from './styles/GoBackButton.module.scss';
import { classNames } from '../../utils/miscUtils';

interface IGoBackButtonProps {
  label?: string;
  onClick?: () => void;
  className?: string;
}

const GoBackButton: FC<IGoBackButtonProps> = (props) => {
  const { label, className, onClick } = props;
  const navigate = useNavigate();

  const goBackButtonClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    navigate(-1);
  };

  return (
    <button className={classNames(Style.goBack, className)} onClick={goBackButtonClick}>
      <Icon name="chevron_left" />
      <span>{label ?? 'Go back'}</span>
    </button>
  );
};

export default observer(GoBackButton);
