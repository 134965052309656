import { serverAxios } from '../api';

export interface ITaskAttachmentFileDownloadUrlsResponse {
  url?: string;
  errorMessage?: string;
}
export interface ITaskAttachmentFileDownloadUrlRequest {
  projectNumber: string;
  attachmentFileIds: number[];
  taskId: number | null;
}

export async function getTaskAttachmentFileDownloadUrls(
  request: ITaskAttachmentFileDownloadUrlRequest,
  abortSignal?: AbortSignal
): Promise<ITaskAttachmentFileDownloadUrlsResponse[]> {
  const result = await serverAxios.post<{ urls: ITaskAttachmentFileDownloadUrlsResponse[] }>(
    'api/task/getAttachmentDownloadUrls',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data?.urls;
}
