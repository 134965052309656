import React, { FC, useMemo, useState } from 'react';
import Styles from './styles/SingleUserSelector.module.scss';
import classNames from 'classnames';
import { FormInput, SearchBox } from '@aurecon-creative-technologies/styleguide';

export interface ISingleUserSelectorProps {
  getUsers(text: string): IUser[];
  className?: string;
  onSelectedUserUpdated(selectedUsers: IUser): void;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
}

const SingleUserSelector: FC<ISingleUserSelectorProps> = ({ getUsers, className, onSelectedUserUpdated }) => {
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [disableDisplayName, setDisableDisplayName] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');

  const addSelectedUser = (id: number) => {
    const user = users.find((u) => u.id === id);
    if (!user || user === selectedUser) return;
    setSelectedUser(user);
    setName(user.name);
    setEmail(user.email);
    setUsers([]);
    setDisableDisplayName(true);
    onSelectedUserUpdated(user);
    setTriggerCleanup(triggerCleanup + 1);
  };

  const loadUsers = (text: string) => {
    if (disableDisplayName) setName('');
    setDisableDisplayName(false);
    setEmail(text);
    setSelectedUser({ id: 0, email: text, name: name });

    if (text === '') setUsers([]);
    else setUsers(getUsers(text));

    if (name && text) onSelectedUserUpdated({ id: 0, email: email, name: text });
  };

  const onDisplayNameChanged = (text: string) => {
    setName(text);
    setSelectedUser({ id: 0, email: email, name: text });
    if (email && text) onSelectedUserUpdated({ id: 0, email: email, name: text });
  };

  const filteredUsers = useMemo(() => {
    return users.map((r) => ({
      id: r.id,
      value: `${r.name} (${r.email})`,
    }));
  }, [users]);

  return (
    <div className={classNames(Styles.expandable, className)}>
      <div className={Styles.formInput}>
        <SearchBox
          placeholder="Add user email..."
          hideSearchButton
          disableDefaultMatching
          suggestions={filteredUsers}
          onChange={(text) => loadUsers(text)}
          onSearch={(text) => loadUsers(text)}
          onSelect={(s) => addSelectedUser(Number(s.id))}
          defaultValue={email}
        />
      </div>
      <FormInput
        cssClass={classNames(Styles.formInput, Styles.secondFormInput)}
        value={name}
        placeholder="Add display name..."
        onChange={onDisplayNameChanged}
        required={!disableDisplayName}
        disabled={disableDisplayName}
      />
    </div>
  );
};

export default SingleUserSelector;
