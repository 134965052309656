export enum DeliveryTeamSettingTabIds {
  USER,
  ACCESS_PERMISSION,
  APPOINTING_PARTY,
}
export const DeliveryTeamSettingTabLabels = {
  USER: 'User',
  ACCESS_PERMISSION: 'Access Permission',
  APPOINTING_PARTY: 'Appointing Party',
};

export const HeaderTeams = {
  DELIVERY_TEAM: 'Delivery Team',
  SHARE_FILE: 'Shared File',
  PUBLISHED_FILE: 'Published File',
  REMOVE: 'Remove',
};

export const TableHeaderAlignTypes = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};
