import { AxiosError } from 'axios';
import { clone, orderBy, uniq, uniqBy } from 'lodash';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  IAddFileContainerResponse,
  IApimsMetadata,
  IProcessImportContainerFile,
  addFileContainer,
} from '../../../api/authenticated/cms/addFileContainer';
import {
  IAddSupersedeFileContainerResponse,
  addSupersedeFileContainer,
} from '../../../api/authenticated/cms/addSupersedeFile';
import { getDuplicateFiles } from '../../../api/authenticated/cms/getDuplicateFiles';
import { getProjectFileDocumentViewerUrl } from '../../../api/authenticated/cms/getProjectFileViewerUrl';
import {
  IFileMetadataFieldValue,
  IMetadataField,
  getProjectMetadata,
} from '../../../api/authenticated/cms/getProjectMetadata';
import { INamingConventionAssistance } from '../../../api/authenticated/cms/interface/namingConventionAssistance';
import { upload } from '../../../api/authenticated/cms/upload';
import { uploadFromTemplate } from '../../../api/authenticated/cms/uploadFromTemplate';
import { ClientFileTemplate, getClientFileTemplates } from '../../../api/authenticated/config/getClientFileTemplates';
import { IRevisionSettings, getSetting } from '../../../api/authenticated/config/getSetting';
import { IErrorCodeUploadFiles } from '../../../common/constants/ErrorCodeUploadFiles';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import {
  ApimsMetaDataType,
  MetaDataFieldTypeEnum,
  MetadataFieldTitle,
  MetadataFieldType,
} from '../../../common/enums/MetadataFieldType';
import { SortType, SortTypes } from '../../../common/enums/SortType';
import { UploadFileStatusEnum, UploadFileStatusText } from '../../../common/enums/UploadFileStatus';
import { IVisibilityColumn } from '../../../common/interfaces/AdjustableTableColumn';
import { ITableColumn } from '../../../common/interfaces/TableColumn';
import { IUploadFile } from '../../../common/interfaces/fileUpload';
import { NavigationItemTypes } from '../../../common/models/ItemType';
import AppStore from '../../../stores/AppStore';
import { dateTimeFormat } from '../../../utils/dateUtils';
import getFileExtension from '../../../utils/fileUtils';
import { calculateChunks, validateNumeric } from '../../../utils/miscUtils';
import { ContentSelection } from '../ContentSelection';
import FilesStore from '../FilesStore';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { FileContainerFieldNames } from './NamingConventionFields';
import { IFileMoveProps } from './SelectedFiles';
import { IUploadContainerFile } from './SupersedeFileStore';
import { getSuitabilities, ISuitability } from '../../../api/authenticated/tasks/getAddData';

export interface IMetadataSelectedValue {
  fieldValueIndex: number;
  value: string;
  title: string;
}

interface IOpenFileContainer {
  id: number;
  fileRevisionId: number;
  containerFileId: number;
  releasedFileId: number;
  sharePointReleasedFileId: number | null;
}

export interface IFileContainerMetadataValue {
  tempId: string;
  tempFileContainerSeqNo?: number;
  groupedContainerName: string | null; // this represents for Original File Name column, if a new group container is created or a supersede is added, the file container name will be set to this field, otherwise it will be original uploaded file name
  fileContainerName?: string;
  suitabilityId?: number | null;
  suitabilityTitle?: string | null;
  suitabilityCode?: string | null;
  metadata: IMetadataSelectedValue[];
  isSelected: boolean;
  isLocked?: boolean;
  fileContainerId?: number | null;
  fileContainerRevisionId?: number | null;
  files: IFileMetadataValue[];
  fileContainerSize: number;
  sequenceNumber?: string | null;
  revisionNumber?: string | null;
  apimsMetadata?: IApimsMetadata | null;
}

export interface IFileMetadataValue {
  fileId?: number;
  file?: File;
  fileName: string;
  fileSize: number;
  native?: boolean;
  statuses: UploadFileStatusEnum[];
  templateId?: number;
  isUnbranded?: boolean;
}

export interface IFileContainerMetaDataFields {
  fileContainerName?: string | null;
  suitabilityId?: number | null;
  suitabilityTitle?: string | null;
  suitabilityCode?: string | null;
  fileContainerStateId?: FileContainerState;
}

export interface IDropDownField {
  key: string;
  id: string;
  label: string;
}

export interface ISelectedDuplicateFileContainer {
  tempFileContainerId: string;
  selectedAtPage: number;
  newFiles: IUploadContainerFile[];
  selectedDuplicatedFiles: ISelectedDuplicateFile[];
  fileContainerId: number;
  fileContainerName: string;
  suitabilityId: number | null;
  suitabilityTitle: string | null;
  suitabilityCode: string | null;
  fileContainerRevisionId: number;
  releasedFileContainerId: number | null;
  releasedFileContainerTitle: string | null;
  fieldValue1: string | null;
  fieldValue2: string | null;
  fieldValue3: string | null;
  fieldValue4: string | null;
  fieldValue5: string | null;
  fieldValue6: string | null;
  fieldValue7: string | null;
  fieldValue8: string | null;
  fieldValue9: string | null;
  fieldValue10: string | null;
  fieldValue11: string | null;
  fieldValue12: string | null;
  fieldValue13: string | null;
  fieldValue14: string | null;
  fieldValue15: string | null;
  fieldValue16: string | null;
  fieldValue17: string | null;
  fieldValue18: string | null;
  fieldValue19: string | null;
  fieldValue20: string | null;
}

export interface ISelectedDuplicateFile {
  fileId: number;
  originalFileName: string;
  size: number;
}

export interface ISelectedNewFileFile {
  file: File;
  templateId?: number;
  isUnbranded?: boolean;
}

export const TextFilterField = {
  FileTitle: 'fileTitle',
  FileName: 'fileName',
  FileSize: 'fileSize',
  Status: 'status',
  Suitability: 'suitability',
  GroupedContainerName: 'groupedContainerName',
  SequenceNumber: 'sequenceNumber',
  Pattern1: 'pattern1',
  Pattern2: 'pattern2',
};

const CheckBoxColumn = 'checkBoxColumn';
const ActionColumn = 'actionColumn';

export const ActionColumns = [CheckBoxColumn, ActionColumn];

export class UploadStore {
  constructor() {
    makeAutoObservable(this, { uploadCompletePercentage: computed, filesFailedToUpload: computed }, { autoBind: true });
  }

  private chunkSize = 3000000;
  public fileMetadata: IMetadataField[] = [];
  public suitabilities: ISuitability[] = [];
  public fileContainerMetadataFields: IFileContainerMetaDataFields = {};
  public isLoadingFileDataFields = false;
  public fileDataValues: IMetadataSelectedValue[] = [];
  public filteredFileContainers: IFileContainerMetadataValue[] = [];
  public selectedFileContainers: IFileContainerMetadataValue[] = [];
  public tableColumns: ITableColumn[] = [];
  public showProgressBar = false;
  private fileChunks: { [filename: string]: IUploadFile } = {};
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public isSelectedInvalidExtension = false;
  public showMetadataWarning = false;
  public hasUnsavedChanges = false;
  public isProcessing = false;
  public isOverLimit = false;
  public openResultsModal = false;
  public fileUploadLimit: number = AppStore.client?.fileUploadLimit ?? 100;
  public namingConventionAssistance: INamingConventionAssistance | null = null;
  public moveFileContainerSelectedId?: string;
  private fileContainerSeqNumber = 1;
  private _defaultColumnWidth = 200;
  public sequenceNumberLength = 5;
  public revisionNumberLength = 2;

  public sequenceNumber?: string;
  public sequenceNumberError?: string;
  public pattern1Error?: string;
  public pattern2Error?: string;
  public pattern3Error?: string;
  public pattern1?: string;
  public pattern2?: string;
  public pattern3?: string;
  public pattern1Metadata?: IMetadataField | null;
  public pattern2Metadata?: IMetadataField | null;
  public pattern3Metadata?: IMetadataField | null;
  public revisionSettings: IRevisionSettings[] = [];
  public revisionNumber?: string;
  public revisionNumberError?: string;
  public uploadTemplates: ClientFileTemplate[] = [];
  private willOpenFiles: IOpenFileContainer[] = [];
  public openFileError: string | null = null;

  public clear() {
    runInAction(() => {
      this.fileDataValues = [];
      this.filteredFileContainers = [];
      this.selectedFileContainers = [];
      this.showProgressBar = false;
      this.hasUnsavedChanges = false;
      this.isOverLimit = false;
      this.isProcessing = false;
      this.isSelectedInvalidExtension = false;
      this.tableColumns = [];
      this.fileContainerMetadataFields = {};
      this.fileContainerSeqNumber = 1;
      this.sequenceNumber = undefined;
      this.pattern1 = undefined;
      this.pattern2 = undefined;
      this.pattern3 = undefined;
      this.pattern1Metadata = null;
      this.pattern2Metadata = null;
      this.pattern3Metadata = null;
      this.revisionNumber = undefined;
      this.willOpenFiles = [];
      this.openFileError = null;
    });
  }

  public async openFileUploadWithTemplate() {
    if (!this.willOpenFiles.length) return;
    for (const x of this.willOpenFiles) {
      try {
        const url = await getProjectFileDocumentViewerUrl({
          projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
          fileContainerId: x.id,
          fileContainerRevisionId: x.fileRevisionId,
          fileId: x.containerFileId,
          releasedFileContainerId: null,
          transmittalId: null,
          transmittalMessageId: null,
          sharePointReleasedFileId: x.sharePointReleasedFileId ?? null,
        });
        if (url) window.open(url);
      } catch {
        runInAction(() => {
          this.openFileError = `Have error when opening file container has id: ${x.id}.`;
        });
      }
    }
  }

  public async getFileData(): Promise<void> {
    runInAction(() => {
      this.isLoadingFileDataFields = true;
      this.tableColumns = [];
    });
    try {
      const task1 = getSetting(NavBarSelectorStore.selectedItem!.project.projectNumber);
      const task2 = getProjectMetadata(NavBarSelectorStore.selectedItem!.project.projectNumber);
      const task3 = getClientFileTemplates();

      let suitabilities: ISuitability[] = [];
      if (FilesStore.fileContainerStateId !== FileContainerState.Wip) {
        suitabilities = await getSuitabilities(
          NavBarSelectorStore.selectedItem!.project.projectNumber,
          FilesStore.fileContainerStateId
        );
      }

      const result = {
        settings: await task1,
        fileMetadata: await task2,
        templates: await task3,
      };
      runInAction(() => {
        if (result.settings) {
          this.chunkSize = result.settings.uploadChunkSize;
          this.sequenceNumberLength = result.settings.sequenceNumberLength;
          this.revisionSettings = result.settings.revisionSettings;
        }

        if (result.fileMetadata) {
          this.fileMetadata = result.fileMetadata
            .filter((x) => x.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField && !x.archived)
            .map((m) => {
              const m1 = clone(m);
              m1.dataType.fieldValues = [...(m1.dataType.fieldValues?.filter((v) => !v.archived) || [])];
              return m1;
            });

          if (AppStore.applyApims) {
            this.pattern1Metadata = result.fileMetadata.find((x) => x.title === ApimsMetaDataType.Pattern1);
            this.pattern2Metadata = result.fileMetadata.find((x) => x.title === ApimsMetaDataType.Pattern2);
            this.pattern3Metadata = result.fileMetadata.find((x) => x.title === ApimsMetaDataType.Pattern3);
          }
        }
        if (result.templates) {
          this.uploadTemplates = result.templates;
        }

        if (suitabilities) this.suitabilities = suitabilities;
      });
    } finally {
      runInAction(() => {
        this.isLoadingFileDataFields = false;
      });
    }
  }

  public setFileFieldValues<T>(fieldName: string, val?: T) {
    runInAction(() => {
      this.fileContainerMetadataFields[fieldName] = val;
    });
  }

  public setNativeFile(containerName: string, fileName: string) {
    runInAction(() => {
      this.selectedFileContainers.forEach((container) => {
        if (container.fileContainerName === containerName) {
          container.files.forEach((file) => (file.native = file.fileName === fileName));
        }
      });
    });
  }

  public setFileDataValues(fieldValueIndex: number, val?: string) {
    runInAction(() => {
      const value = this.fileDataValues.find((v) => v.fieldValueIndex === fieldValueIndex);
      const metadata = this.fileMetadata.find((v) => v.fieldValueIndex === fieldValueIndex);
      const childField = this.fileMetadata.find((v) => v.dataType.parentFieldValueIndex === fieldValueIndex);
      if (childField) {
        this.removeFileDataValues(childField.fieldValueIndex);
      }
      if (val) {
        if (value) {
          value.value = val;
          value.title = metadata?.dataType?.fieldValues?.find((f) => f.code === val)?.title ?? '';
        } else {
          this.fileDataValues.push({
            fieldValueIndex: fieldValueIndex,
            value: val,
            title: metadata?.dataType?.fieldValues?.find((f) => f.code === val)?.title ?? '',
          });
        }
      } else {
        this.removeFileDataValues(fieldValueIndex);
      }
      this.hasUnsavedChanges = true;
    });
  }

  public setSequenceNumber(value?: string) {
    runInAction(() => {
      this.sequenceNumber = value;
      if (value && (value.length > this.sequenceNumberLength || !validateNumeric(value))) {
        this.sequenceNumberError = 'The sequence number does not match with the naming convention.';
        return;
      }
      this.sequenceNumberError = undefined;
    });
  }

  public setPattern1(fieldType: string, value?: string) {
    this.pattern1 = value;
    if (!!value?.length && fieldType === MetadataFieldType.Numeric && !validateNumeric(value)) {
      const getPattern1Label = this.pattern1Metadata?.description ?? ApimsMetaDataType.Pattern1;
      this.pattern1Error = `The ${getPattern1Label} does not match with the naming convention.`;
      return;
    }

    this.pattern1Error = undefined;
  }

  public setPattern2(fieldType: string, value?: string) {
    runInAction(() => {
      this.pattern2 = value;
      if (value && fieldType === MetadataFieldType.Numeric && !validateNumeric(value)) {
        const getPattern2Label = this.pattern2Metadata?.description ?? ApimsMetaDataType.Pattern2;
        this.pattern2Error = `The ${getPattern2Label} does not match with the naming convention.`;
        return;
      }
      this.pattern2Error = undefined;
    });
  }

  public setPattern3(fieldType: string, value?: string) {
    runInAction(() => {
      this.pattern3 = value;
      if (value && fieldType === MetadataFieldType.Numeric && !validateNumeric(value)) {
        const getPattern3Label = this.pattern3Metadata?.description ?? ApimsMetaDataType.Pattern3;
        this.pattern3Error = `The ${getPattern3Label} does not match with the naming convention.`;
        return;
      }
      this.pattern3Error = undefined;
    });
  }

  public setRevisionNumber(value?: string) {
    runInAction(() => {
      this.revisionNumber = value;
      if (value && (value.length > this.revisionNumberLength || !validateNumeric(value))) {
        this.revisionNumberError = `The Revision Number does not match with the naming convention.`;
        return;
      }
      this.revisionNumberError = undefined;
    });
  }

  public getSelectedMetadataValueByIndex(fieldValueIndex: number) {
    return this.fileDataValues.find((v) => v.fieldValueIndex === fieldValueIndex)?.value;
  }

  public removeFileDataValues(fieldValueIndex: number) {
    const childItems = this.fileMetadata.filter((x) => x.dataType.parentFieldValueIndex === fieldValueIndex);
    if (childItems.length) {
      runInAction(() => {
        this.fileDataValues = this.fileDataValues.filter(
          (x) => !childItems.map((x) => x.fieldValueIndex).some((y) => y === x.fieldValueIndex)
        );
      });
    }

    const index = this.fileDataValues.findIndex((v) => v.fieldValueIndex === fieldValueIndex);
    if (index > -1) {
      runInAction(() => {
        this.fileDataValues.splice(index, 1);
      });
    }
  }

  public isSupersedeFileUploadDisabled(): boolean {
    return this.selectedFileContainers.length <= 0 || this.isSelectedInvalidExtension;
  }

  public isUploadDisabled(): boolean {
    if (this.selectedFileContainers.length <= 0) return true;
    if (FilesStore.fileContainerStateId !== FileContainerState.Wip) {
      return (
        this.selectedFileContainers
          .filter((x) => x.isSelected)
          .flatMap((f) => f.files)
          .some(
            (f) =>
              f.statuses.includes(UploadFileStatusEnum.MissingMetadata) ||
              f.statuses.includes(UploadFileStatusEnum.MissingFileExtension)
          ) || this.selectedFileContainers.some((f) => !f.suitabilityId)
      );
    }

    return this.selectedFileContainers
      .flatMap((f) => f.files)
      .some(
        (f) =>
          f.statuses.includes(UploadFileStatusEnum.MissingMetadata) ||
          f.statuses.includes(UploadFileStatusEnum.MissingFileExtension)
      );
  }

  public isApplyFileDataDisabled(): boolean {
    if (
      FilesStore.fileContainerStateId !== FileContainerState.Wip &&
      FilesStore.selectedSection === ContentSelection.UploadFile
    ) {
      return !this.fileContainerMetadataFields.suitabilityId;
    }

    return false;
  }

  public setShowMetadataWarning(value: boolean) {
    runInAction(() => {
      this.showMetadataWarning = value;
    });
  }

  private filterFileMetadata = (filleterColumns: ITableColumn[], metadata: IMetadataSelectedValue[]) =>
    metadata.filter((mt) => filleterColumns.some((col) => col.valueField == mt.fieldValueIndex.toString()));

  private filterFileTextMetadata = (filleterTextColumns: ITableColumn[], metadata: IMetadataSelectedValue[]) =>
    metadata.filter((mt) => filleterTextColumns.some((col) => col.valueField == mt.fieldValueIndex.toString()));

  private isListFiltered = (filteredMetadata: IMetadataSelectedValue[], filleterColumns: ITableColumn[]) => {
    let listFilter = true;
    filleterColumns.forEach((col) => {
      if (
        filteredMetadata.length < filleterColumns.length ||
        filteredMetadata.some((mt) => mt.fieldValueIndex === +col.valueField && mt.title !== col.listFilter?.filter)
      ) {
        listFilter = false;
      }
    });
    return listFilter;
  };

  private isTextFieldFiltered = (
    listFilter: boolean,
    filleterTextColumns: ITableColumn[],
    filterTextMetadata: IMetadataSelectedValue[]
  ) => {
    let isListFiltered = listFilter;
    filleterTextColumns.forEach((col) => {
      if (
        filterTextMetadata.length >= filleterTextColumns.length &&
        filterTextMetadata.some(
          (mt) =>
            mt.fieldValueIndex === +col.valueField &&
            (col.textFilter?.filter
              ? mt.value.toLowerCase().indexOf(col.textFilter?.filter.toLowerCase()) === -1
              : true)
        )
      ) {
        isListFiltered = false;
      }
    });
    return isListFiltered;
  };

  public applyFilter(tableColumn: ITableColumn | null, value?: string) {
    runInAction(() => {
      const column = this.tableColumns.find((col) => col.valueField === tableColumn?.valueField);

      if (column) {
        if (column.listFilter) column.listFilter.filter = value ?? '';
        if (column.textFilter) column.textFilter.filter = value ?? '';
      }

      if (!tableColumn) {
        this.filteredFileContainers = this.selectedFileContainers.map((f) => ({ ...f, metadata: [...f.metadata] }));
        return;
      }

      const filleterColumns = this.tableColumns.filter(
        (x) => x.listFilter?.filter && x.listFilter?.filter.trim().length > 0
      );
      const filleterTextColumns = this.tableColumns.filter(
        (x) => x.textFilter?.filter && x.textFilter?.filter.trim().length > 0
      );

      this.filteredFileContainers = [
        ...this.selectedFileContainers.filter((f) => {
          if (filleterTextColumns.some((t) => t.valueField === TextFilterField.FileName)) {
            return this.tableColumns.some((col) => {
              if (col.valueField === TextFilterField.FileName && col.textFilter) {
                const fileText = f.groupedContainerName ?? '';
                return (
                  col.textFilter.filter && fileText.toLowerCase().indexOf(col.textFilter.filter.toLowerCase()) >= 0
                );
              }
            });
          }

          if (filleterTextColumns.some((t) => t.valueField === TextFilterField.FileTitle)) {
            return this.tableColumns.some((col) => {
              if (col.valueField === TextFilterField.FileTitle && col.textFilter) {
                return (
                  col.textFilter.filter &&
                  (f.fileContainerName?.toLowerCase()?.indexOf(col.textFilter.filter?.toLowerCase()) ?? -1) >= 0
                );
              }
            });
          }

          const filterMetadata = this.filterFileMetadata(filleterColumns, f.metadata);
          const filterTextMetadata = this.filterFileTextMetadata(filleterTextColumns, f.metadata);
          let listFilter = this.isListFiltered(filterMetadata, filleterColumns);

          if (filterTextMetadata.length > 0)
            listFilter = this.isTextFieldFiltered(listFilter, filleterTextColumns, filterTextMetadata);

          return listFilter;
        }),
      ];
    });
  }

  public applySort(tableColumn: ITableColumn | null, direction: SortType) {
    runInAction(() => {
      if (!tableColumn) {
        this.filteredFileContainers = this.selectedFileContainers.map((f) => ({ ...f, metadata: [...f.metadata] }));
        return;
      }

      tableColumn.sort = direction;
      this.tableColumns
        .filter((col) => col.valueField !== tableColumn?.valueField)
        .forEach((col) => (col.sort = SortTypes.NONE));

      if (tableColumn.valueField === TextFilterField.FileTitle) {
        this.filteredFileContainers.sort((f1, f2) =>
          this.sort(f1.fileContainerName?.toLowerCase() ?? '', f2.fileContainerName?.toLowerCase() ?? '', direction)
        );
        return;
      }

      if (tableColumn.valueField === TextFilterField.FileName) {
        this.filteredFileContainers.sort((f1, f2) => {
          const s1 = f1.groupedContainerName ?? '';
          const s2 = f2.groupedContainerName ?? '';
          return this.sort(s1.toLowerCase(), s2.toLowerCase(), direction);
        });
        return;
      }

      if (tableColumn.valueField === TextFilterField.Status) {
        this.filteredFileContainers.sort((f1, f2) => {
          const status1 = f1.files[0].statuses.length ? UploadFileStatusText[f1.files[0].statuses[0]] : '';
          const status2 = f2.files[0].statuses.length ? UploadFileStatusText[f2.files[0].statuses[0]] : '';
          return this.sort(status1, status2, direction);
        });
        return;
      }

      if (tableColumn.valueField === TextFilterField.FileSize) {
        this.filteredFileContainers.sort((f1, f2) => this.sort(f1.fileContainerSize, f2.fileContainerSize, direction));
        return;
      }

      this.filteredFileContainers.sort((f1, f2) => {
        const metaDataValue1 = f1.metadata.find((mt) => `${mt.fieldValueIndex}` === tableColumn.valueField);
        const metadataValue2 = f2.metadata.find((mt) => `${mt.fieldValueIndex}` === tableColumn.valueField);
        if (tableColumn.textFilter)
          return this.sort(
            metaDataValue1?.value.toLowerCase() ?? '',
            metadataValue2?.value.toLowerCase() ?? '',
            direction
          );

        return this.sort(
          metaDataValue1?.title.toLowerCase() ?? '',
          metadataValue2?.title.toLowerCase() ?? '',
          direction
        );
      });
    });
  }

  public onSelectedFileContainers(tempId: string, isSelected: boolean) {
    const index = this.filteredFileContainers.findIndex((f) => f.tempId === tempId);
    if (index < 0) return;
    runInAction(() => {
      this.filteredFileContainers[index].isSelected = isSelected;
      const selectedFileContainer = this.selectedFileContainers.find((f) => f.tempId === tempId);
      if (selectedFileContainer) {
        selectedFileContainer.isSelected = isSelected;
      }
    });
  }

  public onSelectedAllFileContainers(isSelected: boolean) {
    this.filteredFileContainers.forEach((f) => {
      f.isSelected = isSelected;
      const selectedFileContainer = this.selectedFileContainers.find((file) => file.tempId === f.tempId);
      if (selectedFileContainer) {
        selectedFileContainer.isSelected = isSelected;
      }
    });
  }

  public async unlockFileContainers() {
    FilesStore.setShowLoading(true, 'File unlocking');
    const fileContainersToUnlock = this.selectedFileContainers.filter(
      (f) =>
        f.files.flatMap((s) => s.statuses).includes(UploadFileStatusEnum.Supersede) &&
        f.fileContainerId &&
        f.fileContainerRevisionId
    );

    await Promise.all(
      fileContainersToUnlock.map(
        async (f) =>
          f.fileContainerId &&
          f.fileContainerRevisionId &&
          FilesStore.unlockFile(f.fileContainerId, f.fileContainerRevisionId)
      )
    );

    FilesStore.setShowLoading(false, '');
  }

  public async addFiles(files: File[], multiple: boolean, acceptedExtension?: string) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;

    runInAction(() => {
      this.isProcessing = true;
      this.hasUnsavedChanges = true;
    });
    const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
    try {
      this.validationUploadFiles(files, acceptedExtension);

      if (this.isOverLimit) {
        this.isProcessing = false;
        return;
      }

      const newFiles = uniqBy(files, 'name').filter(
        (f) =>
          !this.selectedFileContainers
            .flatMap((f) => f.files)
            .some((sf) => sf.fileName.toLowerCase() === f.name.toLowerCase())
      );

      const suitability = this.suitabilities.find((f) => f.id === this.fileContainerMetadataFields.suitabilityId);

      const validatedNewContainers = await Promise.all(
        newFiles.map(async (file) => {
          const statuses = await this.getNewUploadedFileStatus(file, taskTeamId);

          return {
            tempId: uuidv4(),
            groupedContainerName: file.name,
            metadata: JSON.parse(JSON.stringify(this.fileDataValues)),
            isSelected: false,
            fileContainerName: this.fileContainerMetadataFields.fileContainerName ?? '',
            suitabilityId: this.fileContainerMetadataFields.suitabilityId,
            suitabilityCode: suitability?.code,
            suitabilityTitle: suitability?.title,
            files: [
              {
                file: file,
                fileName: file.name,
                fileSize: file.size,
                statuses: statuses,
              },
            ],
            fileContainerSize: file.size,
            sequenceNumber: this.sequenceNumber,
            apimsMetadata: this.getApimsMetadata(),
            revisionNumber: this.revisionNumber,
          };
        })
      );

      runInAction(() => {
        if (!multiple) {
          // Reset selected files when use replace file.
          this.selectedFileContainers = [];
        }
        this.selectedFileContainers = [...this.selectedFileContainers, ...validatedNewContainers];
        this.loadTableColumns();
        this.applyFilter(null);
        this.updateTableColumnFilter();
      });
    } finally {
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  }

  public async addUnbrandedFile(originalFileName: string, file: File | null, templateId?: number) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;

    runInAction(() => {
      this.isProcessing = true;
      this.hasUnsavedChanges = true;
    });
    const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
    try {
      if (this.isOverLimit) {
        this.isProcessing = false;
        return;
      }

      const suitability = this.suitabilities.find((f) => f.id === this.fileContainerMetadataFields.suitabilityId);
      let statuses: UploadFileStatusEnum[] = [];
      const filename = file ? file.name : originalFileName;
      let fileSize = file?.size ?? 0;
      if (file) {
        statuses = await this.getNewUploadedFileStatus(file, taskTeamId, true);
      } else if (taskTeamId) {
        const template = this.uploadTemplates.find((x) => x.id == templateId);
        statuses = await this.getNewUploadedFileStatusFromTemplate(originalFileName, taskTeamId, false);
        if (template) {
          fileSize = template.totalSize;
        }
      }

      const newFileContainer = {
        tempId: uuidv4(),
        groupedContainerName: filename,
        metadata: JSON.parse(JSON.stringify(this.fileDataValues)),
        isSelected: false,
        fileContainerName: this.fileContainerMetadataFields.fileContainerName ?? '',
        suitabilityId: this.fileContainerMetadataFields.suitabilityId,
        suitabilityCode: suitability?.code,
        suitabilityTitle: suitability?.title,
        files: [
          {
            file: file,
            fileName: filename,
            fileSize: fileSize,
            statuses: statuses,
            templateId: templateId,
            isUnbranded: file && !templateId,
          },
        ],
        fileContainerSize: fileSize,
        sequenceNumber: this.sequenceNumber,
        apimsMetadata: this.getApimsMetadata(),
        revisionNumber: this.revisionNumber,
      } as IFileContainerMetadataValue;

      runInAction(() => {
        this.selectedFileContainers = [...this.selectedFileContainers, newFileContainer];
        this.loadTableColumns();
        this.applyFilter(null);
        this.updateTableColumnFilter();
      });
    } finally {
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  }

  private async getNewUploadedFileStatusFromTemplate(
    originalFileName: string,
    taskTeamId: number,
    skipDuplicateCheck?: boolean
  ) {
    const statuses: UploadFileStatusEnum[] = [];
    if (
      !skipDuplicateCheck &&
      (await getDuplicateFiles(taskTeamId, FilesStore.fileContainerStateId, originalFileName)).length > 0
    )
      statuses.push(UploadFileStatusEnum.Duplicate);

    if (this.isRowMissingMetadata(this.fileDataValues)) {
      statuses.push(UploadFileStatusEnum.MissingMetadata);
      return statuses;
    }

    if (statuses.length) return statuses;

    return [UploadFileStatusEnum.Ready];
  }

  public onAutoGenerateFileNameSwitching() {
    const namingFields = this.getNamingFields();
    namingFields.forEach((f) => this.removeFileDataValues(f));

    runInAction(() => {
      const updatedContainers = [...this.selectedFileContainers].map((f) => ({
        ...f,
        metadata: JSON.parse(JSON.stringify(this.fileDataValues)),
        fileContainerName: this.fileContainerMetadataFields.fileContainerName ?? '',
        files: f.files.map((m) => {
          return {
            ...m,
            statuses: m.statuses.includes(UploadFileStatusEnum.Supersede)
              ? m.statuses
              : [UploadFileStatusEnum.MissingMetadata],
          };
        }),
      }));
      this.selectedFileContainers = updatedContainers;
      this.applyFilter(null);
    });
  }

  private async getNewUploadedFileStatus(file: File, taskTeamId: number, skipDuplicateCheck?: boolean) {
    const statuses: UploadFileStatusEnum[] = [];
    if (!file.type && !getFileExtension(file.name)) statuses.push(UploadFileStatusEnum.MissingFileExtension);
    else if (
      !skipDuplicateCheck &&
      (await getDuplicateFiles(taskTeamId, FilesStore.fileContainerStateId, file.name)).length > 0
    )
      statuses.push(UploadFileStatusEnum.Duplicate);

    if (this.isRowMissingMetadata(this.fileDataValues)) {
      statuses.push(UploadFileStatusEnum.MissingMetadata);
      return statuses;
    }

    if (statuses.length) return statuses;

    return [UploadFileStatusEnum.Ready];
  }

  public validationUploadFiles(files: File[], acceptedExtension?: string) {
    runInAction(() => {
      let isInvalidExtension = false;
      this.isOverLimit = false;
      this.fileUploadLimit = AppStore.client?.fileUploadLimit ?? 100;

      if (this.selectedFileContainers.flatMap((f) => f.files).length + files.length > this.fileUploadLimit)
        this.isOverLimit = true;
      if (acceptedExtension) {
        isInvalidExtension = files.some(
          (file) => file.name.split('.').pop()?.toLowerCase() !== acceptedExtension.toLocaleLowerCase()
        );
      }
      this.isSelectedInvalidExtension = isInvalidExtension;
    });
  }

  public async removeFileContainer(tempId: string) {
    FilesStore.setShowLoading(false, '');
    const index = this.selectedFileContainers.findIndex((f) => f.tempId === tempId);

    if (index > -1) {
      const fileContainer = this.selectedFileContainers[index];
      if (
        fileContainer.files.flatMap((f) => f.statuses).includes(UploadFileStatusEnum.Supersede) &&
        fileContainer.fileContainerId &&
        fileContainer.fileContainerRevisionId
      ) {
        FilesStore.setShowLoading(true, 'File unlocking');
        FilesStore.unlockFile(fileContainer.fileContainerId, fileContainer.fileContainerRevisionId);
        FilesStore.setShowLoading(false, '');
      }

      runInAction(() => {
        this.selectedFileContainers.splice(index, 1);
        this.applyFilter(
          this.tableColumns[0],
          this.tableColumns[0].textFilter?.filter ?? this.tableColumns[0].listFilter?.filter ?? ''
        );
      });
      this.updateTableColumnFilter();
    }
  }

  public async removeFileContainers() {
    FilesStore.setShowLoading(true, 'File unlocking');
    const fileContainersToUnlock = this.selectedFileContainers.filter(
      (f) =>
        f.files.flatMap((s) => s.statuses).includes(UploadFileStatusEnum.Supersede) &&
        f.fileContainerId &&
        f.fileContainerRevisionId &&
        f.isSelected
    );

    await Promise.all(
      fileContainersToUnlock.map(
        async (f) =>
          f.fileContainerId &&
          f.fileContainerRevisionId &&
          FilesStore.unlockFile(f.fileContainerId, f.fileContainerRevisionId)
      )
    );

    FilesStore.setShowLoading(false, '');
    runInAction(() => {
      this.selectedFileContainers = this.selectedFileContainers.filter((f) => !f.isSelected);
      this.applyFilter(
        this.tableColumns[0],
        this.tableColumns[0].textFilter?.filter ?? this.tableColumns[0].listFilter?.filter ?? ''
      );
      this.updateTableColumnFilter();
    });
  }

  public async uploadFiles() {
    if (!NavBarSelectorStore.selectedItem || NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam)
      return;
    const taskTeamId = NavBarSelectorStore.selectedItem.taskTeam.id;
    this.startUploadProcess();
    // compute chunks for all files
    this.selectedFileContainers
      .flatMap((fm) => fm.files)
      .forEach((selectedFile) => {
        this.setCalculateFileChunks(selectedFile.fileName, selectedFile.fileSize);
      });

    // build file container
    const addedUploadFileContainers: IProcessImportContainerFile[] = [];
    for (const selectedFileContainer of this.selectedFileContainers) {
      const addedFileContainer = await this.importFileContainerItem(selectedFileContainer, taskTeamId);
      addedUploadFileContainers.push(addedFileContainer);
    }

    const promiseRequests = addedUploadFileContainers.map(async (x) => {
      if (!x.isError && x.addFileContainerResponse != null) {
        if (!x.isSupersede) {
          return await this.uploadFileContainer(x);
        } else {
          return await this.uploadSupersedeFileContainer(x);
        }
      } else {
        runInAction(() => {
          this.fileChunks[x.fileContainerRequest.containerFiles[0].filename].failed = true;
          this.fileChunks[x.fileContainerRequest.containerFiles[0].filename].reason = this.getUploadErrorMessage(
            x.errorMessage
          );
        });
      }
    });

    await Promise.all([...promiseRequests]);

    // upload complete show confirmation page
    this.showUploadResult();
  }

  private async importFileContainerItem(x: IFileContainerMetadataValue, taskTeamId: number) {
    const container = {
      taskTeamId: taskTeamId,
      fileContainerId: x.fileContainerId ?? null,
      fileContainerRevisionId: x.fileContainerRevisionId,
      fileContainerName: x.fileContainerName,
      suitabilityId: x.suitabilityId,
      fileContainerStateId: FilesStore.fileContainerStateId,
      fieldValue1: this.getMetadataFieldValue(1, x.metadata),
      fieldValue2: this.getMetadataFieldValue(2, x.metadata),
      fieldValue3: this.getMetadataFieldValue(3, x.metadata),
      fieldValue4: this.getMetadataFieldValue(4, x.metadata),
      fieldValue5: this.getMetadataFieldValue(5, x.metadata),
      fieldValue6: this.getMetadataFieldValue(6, x.metadata),
      fieldValue7: this.getMetadataFieldValue(7, x.metadata),
      fieldValue8: this.getMetadataFieldValue(8, x.metadata),
      fieldValue9: this.getMetadataFieldValue(9, x.metadata),
      fieldValue10: this.getMetadataFieldValue(10, x.metadata),
      fieldValue11: this.getMetadataFieldValue(11, x.metadata),
      fieldValue12: this.getMetadataFieldValue(12, x.metadata),
      fieldValue13: this.getMetadataFieldValue(13, x.metadata),
      fieldValue14: this.getMetadataFieldValue(14, x.metadata),
      fieldValue15: this.getMetadataFieldValue(15, x.metadata),
      fieldValue16: this.getMetadataFieldValue(16, x.metadata),
      fieldValue17: this.getMetadataFieldValue(17, x.metadata),
      fieldValue18: this.getMetadataFieldValue(18, x.metadata),
      fieldValue19: this.getMetadataFieldValue(19, x.metadata),
      fieldValue20: this.getMetadataFieldValue(20, x.metadata),
      containerFiles: x.files.map((m) => {
        return {
          fileId: m.fileId ?? null,
          filename: m.fileName,
          totalFileSize: m.fileSize,
          totalFileChunks: this.fileChunks[m.fileName].chunks.length,
          native: m.native,
          file: m.file,
          templateId: m.templateId,
          isUnbranded: m.isUnbranded,
        };
      }),
      apimsMetadata: x.apimsMetadata,
      sequenceNumber: x.sequenceNumber,
      revisionNumber: x.revisionNumber,
    };

    let isError = false;
    let errorMessage = '';
    let response: IAddFileContainerResponse | IAddSupersedeFileContainerResponse | null = null;
    const isSupersededContainer = x.files.flatMap((f) => f.statuses).includes(UploadFileStatusEnum.Supersede);
    try {
      if (isSupersededContainer) {
        if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam || !container.fileContainerId) {
          isError = true;
          errorMessage =
            NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam
              ? 'Missing task team'
              : 'Need File container id when supersede file container';
        } else {
          response = await addSupersedeFileContainer({
            taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
            fileContainerId: container.fileContainerId,
            fileContainerRevisionId: container.fileContainerRevisionId,
            fileContainerStateId: FilesStore.fileContainerStateId,
            containerFiles: container.containerFiles,
          });
        }
      } else {
        response = await addFileContainer(container);
      }
    } catch (err) {
      isError = true;
      errorMessage = this.getUploadErrorMessage(err);
    }

    return {
      fileContainerRequest: container,
      addFileContainerResponse: response,
      isError: isError,
      errorMessage: errorMessage,
      isSupersede: isSupersededContainer,
    };
  }

  public getApimsMetadata() {
    if (!AppStore.applyApims) return null;

    return {
      pattern1: this.pattern1,
      pattern2: this.pattern2,
      pattern3: this.pattern3,
    } as IApimsMetadata;
  }

  private async uploadFileContainer(importContainerFileResult: IProcessImportContainerFile) {
    try {
      const promises = importContainerFileResult.addFileContainerResponse!.containerFiles.map(async (containerFile) => {
        const file = this.selectedFileContainers
          .flatMap((f) => f.files)
          .find((x) => x.fileName.toLowerCase() === containerFile.fileName.toLowerCase());
        if (file?.file) {
          await this.uploadChunks(
            importContainerFileResult.addFileContainerResponse!.fileContainerId,
            containerFile.containerFileId,
            null,
            file.file,
            this.fileChunks[containerFile.fileName]
          );
        } else if (file?.templateId) {
          await uploadFromTemplate({
            templateId: file?.templateId,
            projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
            fileContainerId: importContainerFileResult.addFileContainerResponse!.fileContainerId,
            containerFileId: containerFile.containerFileId,
            fileContainerStateId: FilesStore.fileContainerStateId,
            prevFileContainerRevisionId: null,
            isSupersede: false,
            fileName: file.fileName,
          });
        }

        if (file?.templateId || file?.isUnbranded) {
          this.willOpenFiles.push({
            id: importContainerFileResult.addFileContainerResponse!.fileContainerId,
            containerFileId: containerFile.containerFileId,
            fileRevisionId: importContainerFileResult.addFileContainerResponse!.fileContainerRevisionId,
            releasedFileId: importContainerFileResult.addFileContainerResponse!.releasedFileContainerId,
            sharePointReleasedFileId: null,
          });
        }
      });
      await Promise.all(promises);
    } catch (err) {
      runInAction(() => {
        this.fileChunks[importContainerFileResult.fileContainerRequest.containerFiles[0].filename].failed = true;
        this.fileChunks[importContainerFileResult.fileContainerRequest.containerFiles[0].filename].reason =
          this.getUploadErrorMessage(err);
      });
    }
  }

  private async uploadSupersedeFileContainer(importContainerFileResult: IProcessImportContainerFile) {
    try {
      const addSupersedeResponse =
        importContainerFileResult.addFileContainerResponse as IAddSupersedeFileContainerResponse;
      const promises = addSupersedeResponse.containerFiles.map(async (containerFile) => {
        const file = importContainerFileResult.fileContainerRequest.containerFiles.find(
          (x) => x.filename === containerFile.fileName
        );
        if (file?.file) {
          await this.uploadChunks(
            addSupersedeResponse.fileContainerId,
            containerFile.containerFileId,
            addSupersedeResponse.prevFileContainerRevisionId,
            file.file,
            this.fileChunks[containerFile.fileName],
            true
          );
        } else if (file?.templateId) {
          await uploadFromTemplate({
            templateId: file?.templateId,
            projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
            fileContainerId: addSupersedeResponse.fileContainerId,
            containerFileId: containerFile.containerFileId,
            fileContainerStateId: FilesStore.fileContainerStateId,
            prevFileContainerRevisionId: addSupersedeResponse.prevFileContainerRevisionId,
            isSupersede: true,
            fileName: file.filename,
          });
        }

        if (file?.templateId || file?.isUnbranded) {
          this.willOpenFiles.push({
            id: importContainerFileResult.addFileContainerResponse!.fileContainerId,
            containerFileId: containerFile.containerFileId,
            fileRevisionId: importContainerFileResult.addFileContainerResponse!.fileContainerRevisionId,
            releasedFileId: importContainerFileResult.addFileContainerResponse!.releasedFileContainerId,
            sharePointReleasedFileId: null,
          });
        }
      });
      await Promise.all(promises);
    } catch (err) {
      runInAction(() => {
        this.fileChunks[importContainerFileResult.fileContainerRequest.containerFiles[0].filename].failed = true;
        this.fileChunks[importContainerFileResult.fileContainerRequest.containerFiles[0].filename].reason =
          this.getUploadErrorMessage(err);
      });
    }
  }

  private getUploadErrorMessage(err) {
    const error = (err as AxiosError<IErrorCodeUploadFiles[]>)?.response?.data;
    let reason = '';
    if (Array.isArray(error)) {
      const errorLength = error.length - 1;
      error.forEach((item, index) => {
        reason += `ErrorCode: ${item.errorCode} Message: ${item.errorMessage}${index !== errorLength ? '\n' : ''}`;
      });
      return reason;
    }

    if (!error && typeof err === 'string') {
      return err;
    }

    return (err as AxiosError<string>)?.response?.data ?? '';
  }

  private async uploadChunks(
    fileContainerId: number,
    containerFileId: number,
    prevFileContainerRevisionId: number | null,
    file: File,
    uploadFile: IUploadFile,
    isSupersede?: boolean
  ) {
    // must use a for loop here to ensure each upload is complete before starting the next
    for (const chunk of uploadFile.chunks) {
      const blob = file.slice(chunk.start, chunk.end);
      const isLast = chunk.index === uploadFile.chunks.length - 1;
      await upload({
        blob: blob,
        projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileContainerId: fileContainerId,
        containerFileId: containerFileId,
        fileContainerStateId: FilesStore.fileContainerStateId,
        prevFileContainerRevisionId,
        index: chunk.index,
        offset: chunk.start,
        isLastChunk: isLast,
        isSupersede: isSupersede,
        fileName: file.name,
      });
      runInAction(() => {
        uploadFile.numberOfChunksUploaded++;
      });
    }
  }

  public setSupersedeFileContainers(fileContainers: ISelectedDuplicateFileContainer[]) {
    const supersededFileContainers = fileContainers.map((m) => {
      const keys = Object.keys(m).filter((key) => key.startsWith('fieldValue'));
      const fieldValues = keys.map((key, index) => ({
        fieldValueIndex: index + 1,
        value: m[key],
        title: m[key],
      }));

      const fileContainer: IFileContainerMetadataValue = {
        tempId: uuidv4(),
        fileContainerName: m.fileContainerName,
        groupedContainerName: m.fileContainerName,
        fileContainerId: m.fileContainerId,
        fileContainerRevisionId: m.fileContainerRevisionId,
        isSelected: false,
        metadata: fieldValues,
        suitabilityTitle: m.suitabilityTitle,
        suitabilityId: m.suitabilityId,
        suitabilityCode: m.suitabilityCode,
        files: [
          ...m.newFiles.map((s) => ({
            file: s.file,
            fileName: s.fileName,
            fileSize: s.size,
            statuses: [UploadFileStatusEnum.Supersede],
            templateId: s.templateId,
            isUnbranded: s.isUnbranded,
          })),
          ...m.selectedDuplicatedFiles.map((s) => ({
            fileId: s.fileId,
            fileName: s.originalFileName,
            fileSize: s.size,
            statuses: [UploadFileStatusEnum.Supersede],
          })),
        ],
        fileContainerSize:
          m.newFiles.reduce((sum, current) => sum + current.size, 0) +
          m.selectedDuplicatedFiles.reduce((sum, current) => sum + current.size, 0),
      };

      return fileContainer;
    });

    runInAction(() => {
      this.selectedFileContainers = [
        ...this.selectedFileContainers.filter(
          (f) => !fileContainers.map((f) => f.tempFileContainerId).includes(f.tempId)
        ),
        ...supersededFileContainers,
      ];
      this.applyFilter(null);
    });
  }

  private showUploadResult() {
    runInAction(() => {
      this.showUploadSuccess = !this.filesFailedToUpload.length;
      this.showUploadFailed = !!this.filesFailedToUpload.length;
      this.openResultsModal = true;
    });
  }

  public setOpenResultsModal(open: boolean) {
    runInAction(() => {
      this.openResultsModal = open;
    });
  }

  public applyMetadataToSelectedFiles() {
    runInAction(() => {
      this.filteredFileContainers.forEach((f) => {
        if (
          !f.isSelected ||
          (f.isSelected && f.files.flatMap((fm) => fm.statuses).includes(UploadFileStatusEnum.Supersede))
        )
          return;

        const updatedContainer = this.selectedFileContainers.find((s) => s.tempId === f.tempId);
        const suitability = this.suitabilities.find((t) => t.id === this.fileContainerMetadataFields.suitabilityId);
        f.fileContainerName = this.fileContainerMetadataFields.fileContainerName ?? '';
        f.suitabilityId = this.fileContainerMetadataFields.suitabilityId;
        f.suitabilityCode = suitability?.code;
        f.suitabilityTitle = suitability?.title;
        f.sequenceNumber = this.sequenceNumber;
        f.apimsMetadata = this.getApimsMetadata();
        f.revisionNumber = this.revisionNumber;
        if (updatedContainer) {
          updatedContainer.fileContainerName = this.fileContainerMetadataFields.fileContainerName ?? '';
          updatedContainer.suitabilityId = this.fileContainerMetadataFields.suitabilityId;
          updatedContainer.suitabilityCode = suitability?.code;
          updatedContainer.suitabilityTitle = suitability?.title;
          updatedContainer.sequenceNumber = this.sequenceNumber;
          updatedContainer.apimsMetadata = this.getApimsMetadata();
          updatedContainer.revisionNumber = this.revisionNumber;
        }

        f.metadata = this.fileDataValues.map((x) => ({ ...x }));
        const missingMetadata = this.isRowMissingMetadata(this.fileDataValues);

        f.files.forEach((fe) => {
          const statuses: UploadFileStatusEnum[] = [];
          if (missingMetadata) statuses.push(UploadFileStatusEnum.MissingMetadata);
          if (fe.statuses.includes(UploadFileStatusEnum.Duplicate)) statuses.push(UploadFileStatusEnum.Duplicate);
          if (fe.statuses.includes(UploadFileStatusEnum.MissingFileExtension))
            statuses.push(UploadFileStatusEnum.MissingFileExtension);
          fe.statuses = statuses.length ? statuses : [UploadFileStatusEnum.Ready];
        });

        if (updatedContainer) {
          updatedContainer.metadata = this.fileDataValues.map((x) => ({ ...x }));
          updatedContainer.files = f.files;
        }
      });
      this.updateTableColumnFilter();
    });
  }

  private updateTableColumnFilter() {
    let options: IMetadataSelectedValue[] = [];
    this.filteredFileContainers.forEach((element) => {
      options = options.concat(element.metadata);
    });

    this.tableColumns.forEach((clm) => {
      const metadata = this.fileMetadata.find((m) => m.fieldValueIndex.toString() === clm.valueField);
      if (metadata && metadata.dataType.fieldType === 'List') {
        const clmMetadata = options.filter((x) => x.fieldValueIndex === metadata?.fieldValueIndex);
        const fieldValues = [...new Map(clmMetadata.map((o) => [o.title, o])).values()].map((x) => x.title);
        fieldValues.sort((f1, f2) => this.sort(f1.toLowerCase(), f2.toLowerCase(), SortTypes.ASC));
        clm.listFilter = { fieldValues: fieldValues };
      }
    });
  }

  public isRowMissingMetadata(metadata: IMetadataSelectedValue[]) {
    let requiredFields: number[] = [];
    let isMissingNonWipValidationFailed = false;
    if (FilesStore.fileContainerStateId !== FileContainerState.Wip) {
      requiredFields = this.fileMetadata
        .filter(
          (x) =>
            x.dataType.required &&
            (!x.isNamingConventionField ||
              (x.dataType.parentFieldValueIndex &&
                !this.fileMetadata?.find((t) => t.fieldValueIndex === x.dataType.parentFieldValueIndex)
                  ?.isNamingConventionField))
        )
        .map((x) => x.fieldValueIndex);
      isMissingNonWipValidationFailed = !this.fileContainerMetadataFields.suitabilityId;
    } else {
      requiredFields = this.fileMetadata.filter((x) => x.dataType.required).map((x) => x.fieldValueIndex);
    }
    if (!requiredFields.length) return false;

    const metadataFieldsOfFile = metadata.map((f) => f.fieldValueIndex);
    const missingItems = requiredFields.filter((item) => metadataFieldsOfFile.indexOf(item) < 0);
    return missingItems.length > 0 || isMissingNonWipValidationFailed;
  }

  public unCheckNotMatchFileAfterFiltered() {
    runInAction(() => {
      this.selectedFileContainers.forEach((f) => {
        if (f.isSelected && !this.filteredFileContainers.some((x) => x.tempId === f.tempId)) {
          f.isSelected = false;
        }
      });
    });
  }

  public get getTableColumnsForShowHide(): IVisibilityColumn[] {
    return this.tableColumns
      .filter((x) => !ActionColumns.includes(x.valueField))
      .map((x) => {
        return {
          id: x.valueField,
          label: x.label,
        };
      });
  }

  public setVisibleSelectedFileColumns(columns: string[]) {
    runInAction(() => {
      this.tableColumns.forEach((item) => {
        item.visible = ActionColumns.includes(item.valueField) || columns.includes(item.valueField);
      });
    });
  }

  private getNamingFields(): number[] {
    const namingFiles = this.fileMetadata
      .filter(
        (x) =>
          x.isNamingConventionField ||
          (x.dataType.parentFieldValueIndex &&
            this.fileMetadata?.find((t) => t.fieldValueIndex === x.dataType.parentFieldValueIndex)
              ?.isNamingConventionField)
      )
      .map((x) => x.fieldValueIndex);
    return namingFiles;
  }

  private startUploadProcess() {
    runInAction(() => {
      this.showProgressBar = true;
      this.fileChunks = {};
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  private setCalculateFileChunks(fileName: string, fileSize: number) {
    runInAction(() => {
      this.fileChunks[fileName] = {
        numberOfChunksUploaded: 0,
        failed: false,
        chunks: calculateChunks(fileSize, this.chunkSize),
      };
    });
  }

  private getMetadataFieldValue(fieldValueIndex: number, metadataValues: IMetadataSelectedValue[]) {
    return metadataValues?.find((v) => v.fieldValueIndex === fieldValueIndex)?.value;
  }

  private sort(a: string | number, b: string | number, direction: SortType) {
    const sort = direction === SortTypes.ASC ? 1 : -1;
    return a > b ? sort : -sort;
  }

  public loadTableColumns() {
    const tableColumns: ITableColumn[] = [
      {
        label: '',
        onCheckbox: (checked) => this.onSelectedAllFileContainers(checked),
        valueField: CheckBoxColumn,
        width: 35,
        visible: true,
      },
    ];

    if (FilesStore.fileContainerStateId !== FileContainerState.Wip) {
      tableColumns.push({
        label: 'Suitability',
        valueField: TextFilterField.Suitability,
        textFilter: { filter: '' },
        minWidth: this._defaultColumnWidth,
        width: this._defaultColumnWidth,
        visible: true,
      });
      tableColumns.push({
        label: 'Filename',
        valueField: TextFilterField.FileTitle,
        textFilter: { filter: '' },
        minWidth: this._defaultColumnWidth,
        width: this._defaultColumnWidth,
        visible: true,
      });
      tableColumns.push({
        label: 'Sequence Number',
        valueField: TextFilterField.SequenceNumber,
        textFilter: { filter: '' },
        minWidth: this._defaultColumnWidth,
        width: this._defaultColumnWidth,
        visible: true,
      });
      if (AppStore.applyApims) {
        tableColumns.push({
          label: this.pattern1Metadata?.description ?? ApimsMetaDataType.Pattern1,
          valueField: TextFilterField.Pattern1,
          textFilter: { filter: '' },
          minWidth: this._defaultColumnWidth,
          width: this._defaultColumnWidth,
          visible: true,
        });
        if (FilesStore.fileContainerStateId === FileContainerState.Shared) {
          tableColumns.push({
            label: this.pattern2Metadata?.description ?? ApimsMetaDataType.Pattern2,
            valueField: TextFilterField.Pattern2,
            textFilter: { filter: '' },
            minWidth: this._defaultColumnWidth,
            width: this._defaultColumnWidth,
            visible: true,
          });
        }
      }
    }

    tableColumns.push({
      label: 'Original Filename',
      valueField: TextFilterField.GroupedContainerName,
      textFilter: { filter: '' },
      minWidth: 280,
      width: 280,
      visible: true,
    });

    tableColumns.push({
      label: 'Status',
      valueField: TextFilterField.Status,
      width: 200,
      minWidth: 200,
      visible: true,
    });

    const descMetadata = this.fileMetadata.find((m) => MetadataFieldTitle.includes(m.title));
    if (descMetadata) {
      tableColumns.push({
        label: descMetadata.title,
        valueField: `${descMetadata.fieldValueIndex}`,
        textFilter: { filter: '' },
        minWidth: this._defaultColumnWidth,
        width: this._defaultColumnWidth,
        visible: true,
      } as ITableColumn);
    }
    this.fileMetadata
      .filter((f) => !MetadataFieldTitle.includes(f.title))
      .forEach((field) => {
        const fieldValue = this.fileDataValues.find((val) => val.fieldValueIndex === field.fieldValueIndex)?.value;
        tableColumns.push({
          label: field.title,
          valueField: `${field.fieldValueIndex}`,
          listFilter:
            field.dataType?.fieldType === MetadataFieldType.List
              ? {
                  fieldValues: orderBy(
                    uniq(field.dataType.fieldValues.filter((f) => f.code === fieldValue).map((v) => v.title))
                  ),
                  filter: '',
                }
              : undefined,
          textFilter: field.dataType?.fieldType === MetadataFieldType.UserText ? { filter: '' } : undefined,
          minWidth: 164,
          width: 164,
          visible: true,
        } as ITableColumn);
      });
    tableColumns.push({
      label: 'File Size',
      valueField: TextFilterField.FileSize,
      textFilter: { filter: '' },
      minWidth: this._defaultColumnWidth,
      width: this._defaultColumnWidth,
      visible: true,
    } as ITableColumn);

    tableColumns.push({
      label: '',
      valueField: ActionColumn,
      width: 20,
      visible: true,
    });
    runInAction(() => {
      if (this.tableColumns.length) {
        tableColumns.forEach((t) => {
          const indexOldColumn = this.tableColumns.findIndex((x) => x.valueField == t.valueField);
          if (indexOldColumn > -1) {
            t.index = indexOldColumn;
            t.visible = this.tableColumns[indexOldColumn].visible;
          }
        });
      }
      this.tableColumns = orderBy(tableColumns, 'index');
    });
  }

  public get uploadCompletePercentage() {
    let total = 0;
    let uploaded = 0;
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      total += uploadFile.chunks.length;
      uploaded += uploadFile.failed ? uploadFile.chunks.length : uploadFile.numberOfChunksUploaded;
    });
    const percentage = (uploaded / total) * 100;
    return Number(percentage.toFixed(0));
  }

  public get filesFailedToUpload() {
    const failedFiles: string[] = [];
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      if (uploadFile.failed) failedFiles.push(filename);
    });
    return failedFiles;
  }

  private getParentFieldSelectedValue(field: IMetadataField): IMetadataSelectedValue | undefined {
    return this.fileDataValues.find((fv) => fv.fieldValueIndex === field.dataType.parentFieldValueIndex);
  }

  private getLinkedFieldValues(
    field: IMetadataField,
    parentFieldSelected: IMetadataSelectedValue
  ): IFileMetadataFieldValue[] | undefined {
    return field.dataType.fieldValues.filter((fv) => fv.parentCode === parentFieldSelected.value);
  }

  public showMetadataField(field: IMetadataField): boolean {
    //Ignore if it's not a sub field / linked field
    if (!field.dataType.parentFieldValueIndex) return true;

    return this.getDropdownValues(field)?.length > 0;
  }

  public getDropdownValues(field: IMetadataField): IDropDownField[] {
    let fieldValues: IFileMetadataFieldValue[] = [];

    //If it's not a linked list, just return all the values
    if (!field.dataType.parentFieldValueIndex) {
      fieldValues = field.dataType.fieldValues;
    } else {
      //Get the values linked to the parent field
      const parentFieldSelected = this.getParentFieldSelectedValue(field);

      if (parentFieldSelected) {
        fieldValues = this.getLinkedFieldValues(field, parentFieldSelected) ?? [];
      }
    }

    return fieldValues.map((fv, key) => {
      return { key: `${key}`, id: fv.code, label: fv.title };
    });
  }

  public getResultsCSVData() {
    return {
      filename: `${NavBarSelectorStore.selectedItem?.project.projectNumber}_Bulk_Upload_Result_${dateTimeFormat(
        new Date(),
        'yyyyMMdd'
      )}.csv`,
      headers: [
        { label: 'Original File Name', key: 'fileName' },
        { label: 'Upload Status', key: 'status' },
        { label: 'Reason', key: 'reason' },
      ],
      data: Object.keys(this.fileChunks).map((fileName) => ({
        fileName,
        status: this.fileChunks[fileName].failed ? 'Failed' : 'Success',
        reason: this.fileChunks[fileName].reason,
      })),
    };
  }

  public clearFileData() {
    runInAction(() => {
      this.fileDataValues = [];
      this.fileContainerMetadataFields[FileContainerFieldNames.SuitabilityId] = undefined;
      this.sequenceNumber = undefined;
      this.pattern1 = undefined;
      this.pattern2 = undefined;
      this.revisionNumber = undefined;
    });
  }

  public clearMetadataFileNameError() {
    runInAction(() => {
      this.sequenceNumberError = undefined;
      this.pattern1Error = undefined;
      this.pattern2Error = undefined;
      this.revisionNumberError = undefined;
    });
  }

  public addFileToContainer() {
    const selectedBaseFileForNewContainer = this.selectedFileContainers.filter((s) => s.isSelected)[0];
    const filesToAdd = this.selectedFileContainers
      .filter((s) => s.isSelected)
      .flatMap((f) => f.files)
      .map((m) => {
        return {
          ...m,
          statuses: [
            ...m.statuses.filter((f) => f != UploadFileStatusEnum.MissingMetadata),
            !selectedBaseFileForNewContainer.metadata.length ? UploadFileStatusEnum.MissingMetadata : null,
          ].filter((f) => f) as UploadFileStatusEnum[],
        };
      });

    const newContainer: IFileContainerMetadataValue = {
      tempId: uuidv4(),
      groupedContainerName: `Container ${this.fileContainerSeqNumber}`,
      fileContainerName: selectedBaseFileForNewContainer.fileContainerName,
      suitabilityId: selectedBaseFileForNewContainer.suitabilityId,
      suitabilityCode: selectedBaseFileForNewContainer.suitabilityCode,
      suitabilityTitle: selectedBaseFileForNewContainer.suitabilityTitle,
      metadata: selectedBaseFileForNewContainer.metadata,
      isSelected: false,
      files: filesToAdd,
      fileContainerSize: filesToAdd.reduce((sum, current) => sum + current.fileSize, 0),
    };

    runInAction(() => {
      this.selectedFileContainers = [...this.selectedFileContainers.filter((s) => !s.isSelected), newContainer];
      this.fileContainerSeqNumber = this.fileContainerSeqNumber + 1;
      this.loadTableColumns();
      this.applyFilter(null);
      this.updateTableColumnFilter();
    });
  }

  public setMoveFileContainerSelectedId(fileContainerSelectedId?: string) {
    runInAction(() => {
      this.moveFileContainerSelectedId = fileContainerSelectedId;
    });
  }

  public moveFileToAContainer(fileMove: IFileMoveProps | null) {
    if (fileMove?.tempId === this.moveFileContainerSelectedId) return;

    const newContainer = this.selectedFileContainers.find((t) => t.tempId === this.moveFileContainerSelectedId);
    const fileContainerNeedMove = this.selectedFileContainers.find((t) => t.tempId === fileMove?.tempId);
    if (!newContainer || !fileContainerNeedMove) return;

    runInAction(() => {
      const newFileContainer = [...newContainer.files, fileContainerNeedMove.files[0]];

      const newFileContainers = [
        ...this.selectedFileContainers.filter(
          (s) => s.tempId !== fileMove?.tempId && s.tempId !== this.moveFileContainerSelectedId
        ),
        {
          ...newContainer,
          files: newFileContainer,
          fileContainerSize: newFileContainer?.reduce((sum, current) => sum + current.fileSize, 0) ?? 0,
        },
      ];

      this.selectedFileContainers = newFileContainers;
      this.setMoveFileContainerSelectedId();
      this.loadTableColumns();
      this.applyFilter(null);
      this.updateTableColumnFilter();
    });
  }

  public moveFileContainerToOther(fileMove: IFileMoveProps | null) {
    if (fileMove?.tempId === this.moveFileContainerSelectedId) return;

    const oldFileContainer = this.selectedFileContainers.find((t) => t.tempId === fileMove?.tempId);
    const newContainer = this.selectedFileContainers.find((t) => t.tempId === this.moveFileContainerSelectedId);
    if (!newContainer || !oldFileContainer) return;

    const oldFile = oldFileContainer.files[fileMove?.fileIndex ?? 0];
    oldFileContainer.files.splice(fileMove?.fileIndex ?? 0, 1);
    oldFileContainer.fileContainerSize =
      oldFileContainer.files?.reduce((sum, current) => sum + current.fileSize, 0) ?? 0;

    runInAction(() => {
      const newFileContainer = [...newContainer.files, oldFile];

      const newFileContainers = [
        ...this.selectedFileContainers.filter(
          (s) => s.tempId !== fileMove?.tempId && s.tempId !== this.moveFileContainerSelectedId
        ),
        oldFileContainer.files.length > 1
          ? oldFileContainer
          : {
              ...oldFileContainer,
              tempId: uuidv4(),
              groupedContainerName: oldFileContainer.files[0].fileName,
              isSelected: false,
              files: [oldFileContainer.files[0]],
              fileContainerSize: oldFileContainer.files[0].fileSize,
            },
        {
          ...newContainer,
          files: newFileContainer,
          fileContainerSize: newFileContainer?.reduce((sum, current) => sum + current.fileSize, 0) ?? 0,
        },
      ];

      this.selectedFileContainers = newFileContainers;
      this.setMoveFileContainerSelectedId();
      this.loadTableColumns();
      this.applyFilter(null);
      this.updateTableColumnFilter();
    });
  }

  public removeContainerFile(fileContainerId: string, fileIndex: number) {
    const fileContainer = this.selectedFileContainers.find((t) => t.tempId === fileContainerId);

    if (!fileContainer) return;
    fileContainer.files.splice(fileIndex, 1);
    fileContainer.fileContainerSize = fileContainer?.files?.reduce((sum, current) => sum + current.fileSize, 0) ?? 0;

    runInAction(() => {
      const newFileContainers = [
        ...this.selectedFileContainers.filter((s) => s.tempId !== fileContainerId),
        fileContainer.files.length > 1
          ? fileContainer
          : {
              ...fileContainer,
              tempId: uuidv4(),
              groupedContainerName: fileContainer.files[0].fileName,
              isSelected: false,
              files: [fileContainer.files[0]],
              fileContainerSize: fileContainer.files[0].fileSize,
            },
      ];

      this.selectedFileContainers = newFileContainers;
      this.loadTableColumns();
      this.applyFilter(null);
      this.updateTableColumnFilter();
    });
  }

  public moveFileOutContainer(file: IFileMetadataValue, fileContainerId: string, fileIndex: number) {
    const fileContainerIndex = this.selectedFileContainers.findIndex((t) => t.tempId === fileContainerId);

    if (fileContainerIndex > -1) {
      const fileContainers = this.selectedFileContainers[fileContainerIndex];
      fileContainers?.files.splice(fileIndex, 1);
      fileContainers.fileContainerSize =
        fileContainers?.files?.reduce((sum, current) => sum + current.fileSize, 0) ?? 0;

      runInAction(() => {
        const newFileContainers = [
          ...this.selectedFileContainers.filter((s) => s.tempId !== fileContainerId),
          fileContainers.files.length > 1
            ? fileContainers
            : {
                ...fileContainers,
                tempId: uuidv4(),
                groupedContainerName: fileContainers.files[0].fileName,
                isSelected: false,
                files: [fileContainers.files[0]],
                fileContainerSize: fileContainers.files[0].fileSize,
              },
          {
            ...fileContainers,
            tempId: uuidv4(),
            groupedContainerName: file.fileName,
            isSelected: false,
            files: [file],
            fileContainerSize: file.fileSize,
          },
        ];

        this.selectedFileContainers = newFileContainers;
        this.loadTableColumns();
        this.applyFilter(null);
        this.updateTableColumnFilter();
      });
    }
  }

  public ungroupFileFromContainer() {
    const filesToUngroup = this.selectedFileContainers
      .filter((s) => s.isSelected && s.files.length > 1)
      .flatMap((f) => f.files);

    const newFileContainers: IFileContainerMetadataValue[] = [];
    for (const fileToUngroup of filesToUngroup) {
      const newFileContainer: IFileContainerMetadataValue = {
        tempId: uuidv4(),
        groupedContainerName: fileToUngroup.fileName,
        metadata: [],
        isSelected: false,
        files: [
          {
            file: fileToUngroup.file,
            fileName: fileToUngroup.fileName,
            fileSize: fileToUngroup.fileSize,
            statuses: [
              ...fileToUngroup.statuses.filter(
                (f) => f !== UploadFileStatusEnum.Ready && f != UploadFileStatusEnum.MissingMetadata
              ),
              UploadFileStatusEnum.MissingMetadata,
            ],
          },
        ],
        fileContainerSize: fileToUngroup.fileSize,
      };

      newFileContainers.push(newFileContainer);
    }

    runInAction(() => {
      this.selectedFileContainers = [...this.selectedFileContainers.filter((s) => !s.isSelected), ...newFileContainers];
      this.loadTableColumns();
      this.applyFilter(null);
      this.updateTableColumnFilter();
    });
  }
}

export default new UploadStore();
