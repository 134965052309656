import { serverAxios } from '../api';

export interface ClientFileTemplate {
  id: number;
  templateFileName: string;
  originalFileName: string;
  path: string;
  order: number;
  extension: string;
  totalSize: number;
}
export async function getClientFileTemplates(abortSignal?: AbortSignal): Promise<ClientFileTemplate[]> {
  const result = await serverAxios.get<{ clientFileTemplates: ClientFileTemplate[] }>(
    `/api/config/client/fileTemplates`,
    {
      signal: abortSignal,
    }
  );
  return result.data.clientFileTemplates;
}
