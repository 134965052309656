import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as ExpandIcon } from './images/expand.svg';
import { ITab } from './ITab';
import { classNames } from '../../utils/miscUtils';
import Icon from '../shared/Icon';
import Style from './styles/Frame.module.scss';

const TAB_BAR_HEIGHT = 55;
const FRAME_DEFAULT_HEIGHT = TAB_BAR_HEIGHT * 10;
const FRAME_MIN_HEIGHT = TAB_BAR_HEIGHT * 2;
const MIN_MAP_HEIGHT = TAB_BAR_HEIGHT * 4.5;

const clamp = (value: number, min: number, max: number): number => {
  return Math.min(max, Math.max(min, value));
};

export interface IFrameProps {
  tabs: ITab[];
}

const Frame: FC<IFrameProps> = ({ tabs }) => {
  const [dragging, setDragging] = useState(false);
  const [mouseYOffset, setMouseYOffset] = useState(0);
  const [activeTab, setActiveTab] = useState<ITab>();
  const [frameHeight, setFrameHeight] = useState(FRAME_DEFAULT_HEIGHT);
  const [frameOpen, setFrameOpen] = useState(false);

  useEffect(() => {
    const onMouseUp = () => {
      setDragging(false);
    };
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchend', onMouseUp);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('touchend', onMouseUp);
    };
  }, []);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (dragging) {
        const clamped = clamp(
          window.innerHeight - e.pageY + mouseYOffset,
          TAB_BAR_HEIGHT,
          window.innerHeight - MIN_MAP_HEIGHT
        );
        setFrameHeight(clamped);
        setFrameOpen(clamped > TAB_BAR_HEIGHT);
      }
    };
    const onTouchMove = (e: TouchEvent) => {
      if (dragging) {
        const clamped = clamp(
          window.innerHeight - e.touches[0].pageY + mouseYOffset,
          TAB_BAR_HEIGHT,
          window.innerHeight
        );
        setFrameHeight(clamped);
        setFrameOpen(clamped > TAB_BAR_HEIGHT);
      }
    };
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onTouchMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [dragging, mouseYOffset]);

  const setOffset = (btn: HTMLButtonElement, pageY: number) => {
    const rects = btn.getBoundingClientRect();
    setMouseYOffset(pageY - rects.top - TAB_BAR_HEIGHT);
    setDragging(true);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOffset(e.currentTarget, e.pageY);
  };

  const onTouchStart = (e: React.TouchEvent<HTMLButtonElement>) => {
    setOffset(e.currentTarget, e.touches[0].pageY);
  };

  const onExpand = () => {
    setFrameOpen(!frameOpen);
  };

  const onTabClick = (tab: ITab) => {
    setActiveTab(tab);
    setFrameOpen(true);
    if (frameHeight <= FRAME_MIN_HEIGHT) setFrameHeight(FRAME_DEFAULT_HEIGHT);
  };

  return (
    <div
      className={Style.frameContainer}
      style={frameOpen ? { flex: `0 0 ${frameHeight}px`, transition: dragging ? 'none' : undefined } : {}}>
      <div className={Style.tabBar}>
        <div className={Style.tabBarSection}>
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={classNames(Style.tabBarButton, [activeTab?.name === tab.name, Style.activeTabBarButton])}
              onClick={() => onTabClick(tab)}
              title={tab.title}>
              <Icon className={Style.tabBarIcon} name={tab.icon} />
              {tab.name}
            </button>
          ))}
        </div>
        <div className={Style.tabBarSection}>
          <button
            className={Style.dragButton}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            title="Adjust menu height">
            <ExpandIcon />
          </button>
          <button
            className={classNames(Style.exapandCollapseButton, [frameOpen, Style.expanded])}
            title={frameOpen ? 'Hide menu' : 'Open menu'}
            onClick={onExpand}>
            <Icon name="expand_less" />
          </button>
        </div>
      </div>
      {activeTab != null && frameOpen && (
        <div className={Style.tabContent} style={{ maxHeight: `${frameHeight - TAB_BAR_HEIGHT}px` }}>
          {activeTab.content}
        </div>
      )}
    </div>
  );
};

export default observer(Frame);
