import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as UserRemoveIcon } from '../../assets/taskIcons/UserRemoveIcon.svg';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationHistoryItem.module.scss';
import { formatDate } from '../../utils/dateUtils';
import UserCircleCollection from '../shared/UserCircleCollection';

export interface ITaskInformationHistoryTaskUserRemovedProps {
  item: IHistoryItem;
}

const TaskInformationHistoryTaskUserRemoved: FC<ITaskInformationHistoryTaskUserRemovedProps> = ({ item }) => {
  return (
    <>
      <UserRemoveIcon />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            <strong>{item.byUser.name}</strong> removed{' '}
            {item.toUsers && (
              <UserCircleCollection
                users={item.toUsers.map((u, i) => ({
                  id: i,
                  name: u.name,
                  initials: u.initials,
                }))}
              />
            )}{' '}
            as assignees
          </span>
        </div>
        <div className={Style.historyItemDetailDate}>{formatDate(item.eventDateTime)}</div>
      </div>
    </>
  );
};

export default observer(TaskInformationHistoryTaskUserRemoved);
