import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Toggle } from '@aurecon-creative-technologies/styleguide';
import { useReviewStore } from './ReviewStoreContext';
import Style from './styles/ReviewAcceptorUsers.module.scss';
import UserSelector from '../../shared/UserSelector';

const ReviewAcceptorUsers: FC = () => {
  const store = useReviewStore();
  const [showAllAppointingPartyAcceptors, setShowAllAppointingPartyAcceptors] = useState<boolean>(false);
  if (!store.showAcceptorUsers || !store.acceptors.some((x) => x.isDeliveryTeamAcceptor)) return null;

  return (
    <div className={Style.container}>
      <div className={Style.selectorWrapper}>
        <div className={Style.labelWrapper}>
          <span className={Style.label}>
            Acceptors <span className={Style.required}>*</span>
          </span>
          <Checkbox
            label="Show all acceptors from Appointing Party"
            onChange={(check) => setShowAllAppointingPartyAcceptors(check)}
            checked={showAllAppointingPartyAcceptors}
          />
        </div>
        <UserSelector
          required
          searchPlaceholder="Select acceptors"
          getUsers={(keywords) => store.getMatchedAcceptorUsers(keywords, showAllAppointingPartyAcceptors)}
          onSelectedUsersUpdated={store.onSelectedAcceptorUsersUpdated}
          disabled={store.isSaving}
          isMultiUser={true}
          defaultSelectedUsers={store.selectedAcceptorUsers?.map((x) => {
            return {
              id: x.userId,
              email: x.email,
              name: x.name,
            };
          })}
        />
      </div>
      {!store.isRequireSingleAcceptorConfig && (
        <Toggle
          label="Require all acceptors to accept?"
          labelPosition="left"
          onLabel="Yes"
          offLabel="No"
          value={store.isRequiredAllAcceptor}
          onChange={(b) => store.setIsRequiredAllAcceptorUser(b)}
          disabled={store.isSaving}
        />
      )}
    </div>
  );
};

export default observer(ReviewAcceptorUsers);
