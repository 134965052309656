import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import PageDisabled from './PageDisabled';

export interface ISystemGuardProps {
  enable: boolean;
  pageName: string;
  element: JSX.Element;
}

const SystemGuard: FC<ISystemGuardProps> = ({ enable, pageName, element }) => {
  if (!enable) return <PageDisabled pageName={pageName} />;

  return element;
};

export default observer(SystemGuard);
