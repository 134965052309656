import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import esriConfig from '@arcgis/core/config';
import WebScene from '@arcgis/core/WebScene';
import SceneView from '@arcgis/core/views/SceneView';
import AppStore from '../../../stores/AppStore';
import { BaseMaps } from '../../../common/constants/BaseMaps';
import Layer from '@arcgis/core/layers/Layer';
import Style from '../../../styles/components/settings/projectManagement/ProjectVisualisationWebSceneViewer.module.scss';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import { ArcGisAuthentication } from '../../../arcGisAuthentication/arcGisAuthentication';

interface IWebSceneViewerProps {
  webSceneId: string;
}
const ProjectVisualisationWebSceneViewer: FC<IWebSceneViewerProps> = (props) => {
  const { webSceneId } = props;
  const [arcGisIsLoggingIn, setArcGisIsLoggingIn] = useState(false);
  const sceneViewRef = useRef(null);

  const reverseMapLayers = useCallback((mapLayers) => {
    if (!mapLayers.length) return;

    mapLayers.reverse();
    mapLayers.forEach((map) => {
      if (!map.layers) return;
      reverseMapLayers(map.layers);
    });
  }, []);

  const sceneViewReady = useCallback(
    (map: WebScene, sView: SceneView) => {
      sView.when(() => {
        const mapLayers: Layer[] = [];

        map.layers.forEach((l) => {
          mapLayers.push(l);
        });

        reverseMapLayers(mapLayers);
        Promise.all(
          mapLayers.map((t) => {
            t.visible = false;
            return t.load();
          })
        );
      });
    },
    [reverseMapLayers]
  );

  useEffect(() => {
    const load = async () => {
      if (!AppStore.client?.arcGisPortalUrl) return;

      const arcGis = new ArcGisAuthentication(
        AppStore.client.arcGisUseOauth,
        AppStore.client.arcGisClientId,
        AppStore.client.arcGisApiKey,
        AppStore.client.arcGisPortalUrl
      );

      await arcGis.setupArcGis();
      setArcGisIsLoggingIn(arcGis.arcGisIsLoggingIn);

      if (!sceneViewRef.current) return;

      if (!AppStore.client.arcGisUseOauth && AppStore.client.arcGisApiKey) {
        esriConfig.apiKey = AppStore.client.arcGisApiKey;
      }

      const map = new WebScene({
        portalItem: {
          id: webSceneId,
        },
        basemap: BaseMaps.DEFAULT,
      });

      const sView = new SceneView({
        container: sceneViewRef.current,
        map: map,
        ui: {
          components: ['attribution'],
        },
      });

      sceneViewReady(map, sView);
    };

    load();
  }, [sceneViewReady, webSceneId]);

  return (
    <div className={Style.mapViewContainer}>
      {arcGisIsLoggingIn ? (
        <Loader label="Loading map settings..." />
      ) : (
        <div className={Style.mapView} ref={sceneViewRef}></div>
      )}
    </div>
  );
};

export default observer(ProjectVisualisationWebSceneViewer);
