import React, { FC } from 'react';
import AllReviewersToggle from './AllReviewersToggle';
import Suitability from './Suitability';
import Message from './Message';
import ErrorMessage from './ErrorMessage';
import Reviewers from './Reviewers';
import ReviewAcceptorUsers from './ReviewAcceptorUsers';
import AccessibleDeliveryTeamsDisplay from './AccessibleDeliveryTeamsDisplay';
import Style from './styles/ReviewModal.module.scss';
import { observer } from 'mobx-react-lite';

const UserTabReviewModal: FC = () => {
  return (
    <div className={Style.container}>
      <Reviewers />
      <AllReviewersToggle />
      <ReviewAcceptorUsers />
      <Suitability />
      <Message />
      <AccessibleDeliveryTeamsDisplay />
      <ErrorMessage />
    </div>
  );
};

export default observer(UserTabReviewModal);
