import { serverAxios } from '../api';

export interface IProgrammeDetails {
  programmeId: number | null;
  programmeTitle: string;
}

export async function updateProgramme(request: IProgrammeDetails, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.put('api/config/programme/update', request, { signal: abortSignal });
  return result.data;
}
