import * as React from 'react';
import { observer } from 'mobx-react-lite';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';
import Style from './styles/NavBarSelectorProgramme.module.scss';

export interface INavBarSelectorProgrammeProps {
  title: string;
}

const NavBarSelectorProgramme: React.FC<INavBarSelectorProgrammeProps> = ({ title }) => {
  return (
    <NavBarSelectorItemContainer className={Style.container} onClick={(e) => e.stopPropagation()}>
      <span>{title}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorProgramme);
