import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IUser {
  id: number;
  name: string;
  email: string;
  accessible?: boolean;
}

export interface IGetSharedPublishedCollaborateUserRequest {
  projectNumber: string;
  fileContainerStateId: FileContainerState;
  taskTeamId: number;
}

export async function getNonWipUsersToCollaborateWith(
  request: IGetSharedPublishedCollaborateUserRequest,
  abortSignal?: AbortSignal
): Promise<IUser[]> {
  const result = await serverAxios.post<{ users: IUser[] }>('api/cms/nonwipuserstocollaboratewith', request, {
    signal: abortSignal,
  });
  return result.data?.users;
}
