export enum SettingTabIds {
  SYSTEM_SETTINGS,
  SYSTEM_USERS,
  PROJECT_MANAGEMENT,
  TEAM_MANAGEMENT,
  TEMPORARY_ACCESS_LOG,
}
export const SettingTabLabels = {
  SYSTEM_SETTINGS: 'System Settings',
  SYSTEM_USERS: 'System Users',
  PROJECT_MANAGEMENT: 'Project Management',
  TEAM_MANAGEMENT: 'Team Management',
  TEMPORARY_ACCESS_LOG: 'Temporary Access log',
};

export default { SettingTabIds, SettingTabLabels };
