import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationFileAttachment.module.scss';
import { formatDate } from '../../utils/dateUtils';
import Icon from '../shared/Icon';
import TaskInformationStore from './TaskInformationStore';
import { getFileSizeDetails } from '../../utils/miscUtils';
import DownloadModal from '../shared/DownloadModal';
import { Button } from '@aurecon-creative-technologies/styleguide';

export interface ITaskInformationSupportFileAttachmentProps {
  item: IHistoryItem;
}

const TaskInformationFileAttachment: FC<ITaskInformationSupportFileAttachmentProps> = ({ item }) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const { totalFileSize, isOverLimit } = getFileSizeDetails(
    (item.attachmentFiles || []).reduce((total, file) => total + file.totalSize, 0)
  );

  const handleDownload = (attachmentFileId: number) => {
    if (isOverLimit) {
      setShowDownloadModal(true);
      return;
    }
    TaskInformationStore.downloadTaskAttachmentFiles(attachmentFileId);
  };

  return (
    <>
      <Icon name="description" outlined className={Style.icon} />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            Supporting file uploaded by <strong>{item.byUser.name}</strong>
          </span>
        </div>
        {item.attachmentFiles?.map((att) => {
          const fileName = `${att.originalFileName}`;
          return (
            <div key={att.id} className={Style.attachmentFileItem}>
              <Button
                type="text"
                cssClass={Style.downloadItem}
                onClick={() => handleDownload(att.id)}
                label={fileName}
              />
            </div>
          );
        })}
        <div className={Style.historyItemDetailDate}>{formatDate(item.eventDateTime)}</div>
        {item.message && <div className={Style.historyItemDetailMessage}>{item.message}</div>}
      </div>
      <DownloadModal
        closeModal={() => setShowDownloadModal(false)}
        showModal={showDownloadModal}
        downloadAction={() => {
          TaskInformationStore.downloadAllTaskFiles();
          setShowDownloadModal(false);
        }}
        fileSize={totalFileSize}
      />
    </>
  );
};

export default observer(TaskInformationFileAttachment);
