import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useReviewStore } from './ReviewStoreContext';
import { ReactComponent as ExclamationMark } from './images/ExclamationMark.svg';
import Style from './styles/ErrorMessage.module.scss';

const ErrorMessage: FC = () => {
  const store = useReviewStore();

  if (!store.errorMessage) return null;

  return (
    <div className={Style.errorMessage}>
      <ExclamationMark className={Style.errorMessageIcon} />
      {store.errorMessage}
    </div>
  );
};

export default observer(ErrorMessage);
