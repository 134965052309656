import { serverAxios } from '../api';

export interface IValidationAttachmentFilenamesRequest {
  projectNumber: string;
  taskId: number;
  filenames: string[];
}

export interface IFileNameValidationItem {
  fileName: string;
  duplicated: boolean;
}

export async function validationAttachmentFilenames(
  request: IValidationAttachmentFilenamesRequest,
  abortSignal?: AbortSignal
): Promise<IFileNameValidationItem[]> {
  const result = await serverAxios.post<{ fileNameValidationItems: IFileNameValidationItem[] }>(
    'api/task/validationFileNames',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data?.fileNameValidationItems || [];
}
