import { IDropdownItemProps, ISuggestion } from '@aurecon-creative-technologies/styleguide';
import { makeAutoObservable, runInAction } from 'mobx';
import { IUser, getAppointingPartyUsers } from '../../../api/authenticated/um/getAppointingPartyUsers';
import { getUsers } from '../../../api/authenticated/um/getUsers';
import { removeUserFromAppointingParty } from '../../../api/authenticated/um/removeUserFromAppointingParty';
import { AppointingPartyRoleText } from '../../../common/constants/AppointingPartyRoleText';
import { AppointingPartyRole } from '../../../common/enums/AppointingPartyRole';
import LayoutStore from '../../../components/layout/LayoutStore';
import { AxiosError } from 'axios';
import { SortTypes } from '../../../common/enums/SortType';
import { sort } from '../../../utils/sortHelper';
import { orderBy } from 'lodash';
import { addAppointingPartyUsers } from '../../../api/authenticated/um/addAppointingPartyUsers';
import { updateAppointingPartyUserRole } from '../../../api/authenticated/um/updateAppointingPartyUserRole';
import { IBaseUser } from '../../../common/interfaces/baseUser';
import AppStore from '../../AppStore';

export class AppointingPartyStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public isRemovingUser = false;
  public appointingPartyUsers: IUser[] = [];
  public userToBeRemoved?: IUser | null = null;
  public appointingPartyRoles: IDropdownItemProps[] = [
    {
      id: AppointingPartyRole.Acceptor,
      label: AppointingPartyRoleText[AppointingPartyRole.Acceptor],
    },
  ];
  public isShowAddMembersModal = false;
  public isEnabledAddMemberBtn = false;
  public allUsers: IBaseUser[] = [];
  public selectedUserId: number | null = null;
  public selectedRoleIds: number[] = [];

  public isError = false;
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public projectNumber: string | null = null;
  public filteredUsers?: ISuggestion[];
  public email: string | null = null;
  public displayName: string | null = null;

  public async init(projectNumber: string) {
    if (!projectNumber) return;
    runInAction(() => {
      this.projectNumber = projectNumber;
      this.loadAppointingPartyUsers();
      this.getOptionUsers();
      this.resetSelectedUsers();
    });
  }
  public getAppointingPartyUsers() {
    return this.appointingPartyUsers;
  }

  public searchAccepterUsers(searchText: string): IUser[] {
    const lowercaseText = searchText?.toLowerCase() ?? '';
    return this.appointingPartyUsers.filter(
      (u) =>
        !!u.appointingPartyRoleIds.length &&
        (u.name.toLowerCase().includes(lowercaseText) || u.email.toLowerCase().includes(lowercaseText))
    );
  }

  public async loadAppointingPartyUsers() {
    if (!this.projectNumber) return;

    this.clearError();
    try {
      const users = await getAppointingPartyUsers(this.projectNumber);

      runInAction(() => {
        this.appointingPartyUsers = users.map((x) => {
          return {
            id: x.id,
            name: x.name,
            email: x.email,
            appointingPartyRoleIds: x.appointingPartyRoleIds,
          };
        }) as IUser[];
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.appointingPartyUsers = [];
        this.isError = true;
      });
    }
  }

  public setRemoveUser(selectedUser: IUser | null) {
    runInAction(() => {
      this.userToBeRemoved = selectedUser;
    });
  }

  public async removeUserFromAppointingParty() {
    if (!this.projectNumber) return;

    const userId = this.userToBeRemoved?.id;
    if (userId == null) return;

    runInAction(() => {
      this.isRemovingUser = true;
    });

    this.clearError();
    try {
      await removeUserFromAppointingParty({
        projectNumber: this.projectNumber,
        userId: userId,
      });
      this.resetSelectedUsers();
      this.loadAppointingPartyUsers();
      this.getOptionUsers();

      LayoutStore.displayToast('success', 'User has been deleted successfully');
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.isError = true;
        LayoutStore.displayToast('error', 'User cannot be deleted. Please try again later.');
      });
    } finally {
      runInAction(() => {
        this.isRemovingUser = false;
        this.userToBeRemoved = null;
      });
    }
  }

  public async changeUserRole(selectedUser: IUser, newRoles: (string | number)[]) {
    if (!this.projectNumber || selectedUser === null) return;

    this.clearError();
    try {
      await updateAppointingPartyUserRole({
        appointingPartyRoleId: Number(newRoles[0] ?? 0),
        projectNumber: this.projectNumber,
        userId: selectedUser.id,
      });
      this.loadAppointingPartyUsers();
      LayoutStore.displayToast('success', 'User role has been changed successfully');
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.isError = true;
        LayoutStore.displayToast('error', 'User role cannot be changed. Please try again later.');
      });
    }
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public closeErrorModal() {
    runInAction(() => {
      this.isError = false;
    });
  }

  /**
   * Create the data for the select user option.
   */
  public async getOptionUsers() {
    if (!this.projectNumber) return;

    try {
      this.filteredUsers = [];
      const users = sort((await getUsers(this.projectNumber, true)) || [], 'name', SortTypes.ASC);
      runInAction(() => {
        this.allUsers = users.filter(({ id: id1 }) => !this.appointingPartyUsers.some(({ id: id2 }) => id2 === id1));
      });
    } catch {
      runInAction(() => {
        this.allUsers = [];
        this.filteredUsers = [];
      });
    }
  }

  /**
   * Filter users matched with users by keyword.
   * @param searchText
   * @returns
   */
  public getMatchedUsers(searchText: string) {
    if (searchText === null || searchText === '') {
      runInAction(() => {
        this.filteredUsers = [];
        this.isEnabledAddMemberBtn = false;
        this.displayName = null;
        this.email = null;
        this.selectedUserId = null;
      });
    } else {
      const response = this.allUsers
        .filter(
          (r) =>
            (r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
              r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1) &&
            r.id !== this.selectedUserId
        )
        .map((r) => ({
          id: r.id,
          name: r.name,
          email: r.email,
          disabled: r.disabled,
        }));
      runInAction(() => {
        this.email = searchText;
        this.selectedUserId = null;
        this.filteredUsers = response.map((x) => {
          return {
            id: x.id,
            value: x.email,
            display: x.email,
          };
        });
      });
    }
  }

  /**
   * Set selected user ids to be added.
   * @param users
   */
  public onSelectedUsersUpdated(selected: ISuggestion) {
    this.selectedUserId = null;
    const user = this.allUsers.find((r) => r.id === selected.id);
    if (user) {
      runInAction(() => {
        this.selectedUserId = user.id;
        this.displayName = user.name ?? null;
        this.email = user.email ?? null;
        this.filteredUsers = [];
      });
    }
    runInAction(() => {
      // Enabled add members button if select at least 1 user.
      this.isEnabledAddMemberBtn = !!this.selectedUserId && !!this.displayName;
    });
  }

  setDisplayName(text: string) {
    runInAction(() => {
      this.isEnabledAddMemberBtn = !!this.email && !!text && !!AppStore.client?.canInviteOrRemoveAzure;
      this.displayName = text;
    });
  }

  public setSelectedRole(role: (string | number)[]) {
    const selectedRole = this.appointingPartyRoles.filter((r) => role.includes(r.id));
    runInAction(() => {
      if (selectedRole.length) {
        this.selectedRoleIds.push(+role);
      } else {
        this.selectedRoleIds = [];
      }
    });
  }
  /**
   * Reset Add member data
   */
  public resetSelectedUsers() {
    runInAction(() => {
      this.allUsers = [];
      this.selectedUserId = null;
      this.selectedRoleIds = [];
      this.isEnabledAddMemberBtn = false;
      this.displayName = null;
      this.filteredUsers = [];
    });
  }

  /**
   * Add member to appointing party and assign party role
   * @returns
   */
  public async addMembersToAppointingParty() {
    if (!this.projectNumber) return;

    try {
      // Add user to project appointing party
      const payload = {
        projectNumber: this.projectNumber,
        userId: this.selectedUserId ?? 0,
        email: this.email ?? '',
        name: this.displayName ?? '',
        appointingPartyRoleId: this.selectedRoleIds[0],
      };
      await addAppointingPartyUsers(payload);
      this.resetSelectedUsers();
      this.loadAppointingPartyUsers();
      this.getOptionUsers();
      LayoutStore.displayToast('success', 'Users has been added successfully.');
    } catch {
      runInAction(() => {
        this.isError = true;
        LayoutStore.displayToast('error', 'User cannot be added. Please try again later.');
        this.resetSelectedUsers();
      });
    }
  }

  public applySort(columnName: string, direction: SortTypes) {
    if (direction != SortTypes.NONE) {
      if (columnName === 'role') {
        this.appointingPartyUsers = orderBy(
          this.appointingPartyUsers,
          (item) => item.appointingPartyRoleIds,
          direction
        );
        return;
      }
      this.appointingPartyUsers = sort(this.appointingPartyUsers, columnName, direction);
      return;
    }
    return this.loadAppointingPartyUsers();
  }
}

export default new AppointingPartyStore();
