import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/ProjectDetails.module.scss';
import { Grid } from '@aurecon-creative-technologies/styleguide';
import { classNames } from '../../../utils/miscUtils';
import ProjectVisualisation from './ProjectVisualisation';
import UpdateProjectVisualisation from './UpdateProjectVisualisation';
interface IProjectManagementVisualisationProps {
  displayVisualisation: boolean;
  onUpdate: () => void;
}

const ProjectManagementVisualisation: FC<IProjectManagementVisualisationProps> = ({
  displayVisualisation,
  onUpdate,
}) => {
  const [updateProjectVisualisation, setUpdateProjectVisualisation] = useState<boolean>(false);

  if (!displayVisualisation) return null;
  return (
    <>
      <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
      {updateProjectVisualisation ? (
        <UpdateProjectVisualisation
          onSave={(projectNumber) => {
            if (!projectNumber) return;
            setUpdateProjectVisualisation(false);
            onUpdate();
          }}
          onCancel={() => setUpdateProjectVisualisation(false)}
        />
      ) : (
        <ProjectVisualisation onUpdate={() => setUpdateProjectVisualisation(true)} />
      )}
    </>
  );
};

export default observer(ProjectManagementVisualisation);
