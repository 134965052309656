import { serverAxios } from '../api';

export const deleteTransmittalType = async (
  projectNumber: string | null,
  transmittalTypeId: number,
  abortSignal?: AbortSignal
): Promise<void> =>
  serverAxios.put(
    `api/transmittals/deleteTransmittalType`,
    { projectNumber, transmittalTypeId },
    { signal: abortSignal }
  );

export const deleteTransmittalMailReason = async (
  projectNumber: string | null,
  transmittalReasonId: number,
  abortSignal?: AbortSignal
): Promise<void> =>
  serverAxios.put(
    `api/transmittals/deleteTransmittalMailReason`,
    { projectNumber, transmittalReasonId },
    { signal: abortSignal }
  );
