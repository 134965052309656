import { serverAxios } from '../api';

export interface ITransmittalTypeResult {
  type: IReason[];
}

export interface IReason {
  id: number;
  namingCode: string;
  title: string;
  transmittalTypeId: number;
  order: number;
  archived: boolean;
  used: boolean | null;
}

export async function getTransmittalReasons(projectNumber: string, abortSignal?: AbortSignal): Promise<IReason[]> {
  const result = await serverAxios.get<{
    transmittalReasons: IReason[];
  }>('api/transmittals/gettransmittalreasons', {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.transmittalReasons;
}
