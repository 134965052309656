import { serverAxios } from '../api';
import { IAppointingParty, IDeliveryTeam, IDistributionList, ITaskTeam } from '../um/getProjectMembersAndTeams';

export interface IAddTransmittalMessage {
  projectNumber: string | undefined;
  transmittalId: number;
  transmittalMessageId?: number;
  transmittalTitle: string;
  message: string;
  subject: string;
  dueDate: Date | null;
  taskTeamId: number | null;
  selectedNotifyUsers: IUser[];
  selectedNotifyAppointingPartyUsers: IAppointingPartyUser[];
  selectedVisibleUsers: IUser[];
  selectedVisibleAppointingPartyUsers: IAppointingPartyUser[];
  notifyToDistributionList: IDistributionList[] | number[];
  selectedVisibleDeliveryTeamIds: number[];
  selectedVisibleTaskTeamIds: number[];
  selectedVisibleAppointingPartyIds: number[];
  contentFileIds?: IAddTransmittalFileContainer[] | number[];
  draft: boolean;
  isClosed: boolean;
  canRespond: boolean;
  visibleToTaskTeamUsers?: IUser[];
  visibleToTaskTeams?: ITaskTeam[] | number[];
  visibleToDeliveryTeams?: IDeliveryTeam[] | number[];
  visibleToAppointingParties?: IAppointingParty[] | number[];
  visibleToDistributionList: IDistributionList[] | number[];
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  taskTeamId: number;
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  taskTeamTitle: string;
  taskTeamCode: string;
  isExternal: boolean;
}

export interface IAppointingPartyUser {
  id: number;
  name: string;
  email: string;
  appointingPartyId: number;
}

export interface IAddTransmittalFileContainer {
  releasedFileContainerId: null | number;
  containerFileId: null | number;
}

export interface IAddTransmittalMessageResult {
  transmittalId: number;
  transmittalMessageId: number;
}

export async function addTransmittalMessage(
  request: IAddTransmittalMessage,
  abortSignal?: AbortSignal
): Promise<IAddTransmittalMessageResult> {
  const result = await serverAxios.post<IAddTransmittalMessageResult>(
    `api/transmittals/${request.transmittalTitle}/AddTransmittalMessage`,
    request,
    { signal: abortSignal }
  );
  return result.data;
}
