import { Grid, Icon } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import Style from '../../../styles/components/settings/projectManagement/ProjectManagement.module.scss';
import Styles from '../../../styles/components/settings/projectManagement/ProgrammeManagement.module.scss';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import { IProject } from '../../../api/authenticated/config/getProjects';
import ConfirmationModal from '../../shared/ConfirmationModal';
import Loading from '../../shared/Loading';
import { IProgramme } from '../../../api/authenticated/config/models/projectModel';
import UpdateProgrammeModal from './UpdateProgrammeModal';

interface IProgrammeDetailsProps {
  programmeId: string | number;
  programmeName: string;
  onUpdate: (programmeName: string | undefined) => void;
  onDelete: () => void;
}

const ProgrammeDetails: FC<IProgrammeDetailsProps> = (props) => {
  const { programmeId, programmeName } = props;
  const [showNoticed, setShowNoticed] = useState<boolean>(true);
  const [programme, setProgramme] = useState<IProgramme>();
  const [projectDetails, setProjectDetails] = useState<IProject[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editOrDeleteProgramme, setEditOrDeleteProgramme] = useState(false);
  const [showEditProgrammeModal, setShowEditProgrammeModal] = useState(false);

  useEffect(() => {
    if (!programmeId && !programmeName) return;
    const setData = () => {
      const programmeDetails = { programmeId: programmeId, programmeTitle: programmeName } as IProgramme;
      setProgramme(programmeDetails);
      if (programmeDetails) {
        const getProjectData = ProjectManagementStore.searchProjectsSelectedProgramme(programmeDetails);
        setProjectDetails(getProjectData);
      }
    };
    setData();
  }, [programmeId, programmeName]);

  const deleteProgramme = async () => {
    setEditOrDeleteProgramme(true);
    programme && (await ProjectManagementStore.deleteProgramme(programme));
    setShowConfirmation(false);
    props.onDelete();
    setEditOrDeleteProgramme(false);
  };
  const onClose = (newProgrammme: string | undefined) => {
    setShowEditProgrammeModal(false);
    if (newProgrammme) {
      props.onUpdate(newProgrammme);
    }
  };
  const renderEditProgrammeModal = () => {
    return (
      showEditProgrammeModal && (
        <UpdateProgrammeModal
          programmeId={programmeId}
          programmeName={programmeName}
          onClose={(programmeName) => {
            setShowEditProgrammeModal(false);
            if (programmeName) onClose(programmeName);
          }}
        />
      )
    );
  };

  if (ProjectManagementStore.loading) return <Loading isLoading={ProjectManagementStore.loading} />;
  return (
    <>
      {showNoticed && (
        <div className={Style.noticed}>
          <div className={Style.header}>
            Great Job!
            <Icon className={Style.closedBtn} type="closed" onClick={() => setShowNoticed(false)} />
          </div>
          <p>You can now create a new project by pressing the &#34;Create New Project&#34; button.</p>
        </div>
      )}
      {programme && (
        <div className={Styles.fromWrapper}>
          <Grid row={true} md={12}>
            <Grid item md={12}>
              <div className={Styles.header}>
                <span>Programme Details</span>
                <div className={Styles.actions}>
                  <Icon
                    type="edit"
                    className={classNames(Styles.actionIcon, Styles.editIcon)}
                    onClick={() => {
                      setShowEditProgrammeModal(true);
                    }}
                  />
                  {!projectDetails.length && (
                    <Icon
                      type="delete"
                      className={classNames(Styles.actionIcon, Styles.editIcon)}
                      onClick={() => setShowConfirmation(true)}
                    />
                  )}
                </div>
              </div>
              <div className={Styles.formDisplayField}>
                <div className={Styles.formControlLabel}>Programme</div>
                <div>{programme.programmeTitle}</div>
              </div>
            </Grid>
          </Grid>
          <ConfirmationModal
            showModal={showConfirmation}
            heading="Deleting Programme?"
            message={`This action will delete the programme and can not be undone. Are you sure you want to delete ${programme.programmeTitle}?`}
            confirmText="Yes"
            cancelText="No"
            loading={editOrDeleteProgramme}
            onConfirm={deleteProgramme}
            onCancel={() => setShowConfirmation(false)}
          />
          {renderEditProgrammeModal()}
        </div>
      )}
    </>
  );
};
export default observer(ProgrammeDetails);
