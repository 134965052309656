import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskApprovedEvent } from '../../../api/authenticated/cms/getFileHistory';
import TaskEvent from './TaskEvent';

export interface ITaskEventApprovedProps {
  taskApprovedEvents: ITaskApprovedEvent[];
}

const TaskEventApproved: FC<ITaskEventApprovedProps> = ({ taskApprovedEvents }) => {
  if (taskApprovedEvents.length === 0) return null;

  return (
    <TaskEvent icon="check_circle" label="Approved by">
      {taskApprovedEvents.map((event) => (
        <div key={event.approvedBy.userId}>{event.approvedBy.userName}</div>
      ))}
    </TaskEvent>
  );
};

export default observer(TaskEventApproved);
