import { serverAxios } from '../api';

export interface AddMetadataFieldRequest {
  id: number;
  title: string;
  fieldValueIndex: number;
  dataType: {
    fieldType: string;
    required: boolean;
    showCode: boolean;
    parentCode: string | null;
    parentFieldValueIndex: number | null;
    fieldValues: {
      code: string;
      title: string;
      parentCode: string;
    }[];
  };
}

export async function addMetadataField(
  projectNumber: string,
  requests: AddMetadataFieldRequest[],
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.post(
    'api/config/projects/metadata/add',
    { projectNumber: projectNumber, data: requests },
    { signal: abortSignal }
  );
  return result.data;
}
