import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import BuildingExplorer from '@arcgis/core/widgets/BuildingExplorer';
import VisualisationStore from '../VisualisationStore';

import WidgetBase, { IWidgetBase } from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';
import SceneView from '@arcgis/core/views/SceneView';

const BuildingExplorerWidget: FC = () => {
  const buildingLayers = VisualisationStore.buildingLayers;

  return WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new BuildingExplorer({
        visible: true,
        layers: buildingLayers,
        container: properties.container,
        view: properties.view as SceneView,
      }),
    Style.measurementWidgetWrapper
  );
};

export default observer(BuildingExplorerWidget);
