import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamDetails.module.scss';
import { ITeamDetailsProps } from '../../../common/constants/TeamManagementAction';
import TeamDetailsHeader from './TeamDetailsHeader';
import PageTab from '../../shared/PageTab';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import { DeliveryTeamSettingTabIds, DeliveryTeamSettingTabLabels } from './models/Types';
import DeliveryTeamUsers from './DeliveryTeamUsers';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import DeliveryTeamPermission from './DeliveryTeamPermission';
import AddingAcceptorForDeliveryTeam from './AddingAcceptorForDeliveryTeam';

const DeliveryTeamDetails: FC<ITeamDetailsProps> = (props) => {
  const { projectNumber, team, teamType, programmeId, projectName } = props;
  const [activeDeliveryTeam, setActiveDeliveryTeam] = useState<boolean>(false);
  const [selectedTabId, setSelectedTabId] = useState(DeliveryTeamSettingTabIds.USER);

  useEffect(() => {
    const deliveryTeamActiveStatus = TeamManagementStore.clientTeams?.programmes
      .flatMap((pr) => pr.projects)
      .flatMap((p) => p.deliveryTeams)
      .find((tt) => tt.id === team.id)?.active;
    setActiveDeliveryTeam(!!deliveryTeamActiveStatus);
  }, [team]);

  const settingTabs: ITab[] = [
    { id: DeliveryTeamSettingTabIds.USER, label: DeliveryTeamSettingTabLabels.USER },
    { id: DeliveryTeamSettingTabIds.ACCESS_PERMISSION, label: DeliveryTeamSettingTabLabels.ACCESS_PERMISSION },
    { id: DeliveryTeamSettingTabIds.APPOINTING_PARTY, label: DeliveryTeamSettingTabLabels.APPOINTING_PARTY },
  ];

  const onSelectTab = (id: number) => {
    setSelectedTabId(id);
  };

  const renderTabContent = (tabId) => {
    switch (tabId) {
      case DeliveryTeamSettingTabIds.USER:
        return <DeliveryTeamUsers projectNumber={projectNumber} projectName={projectName} team={team} />;
      case DeliveryTeamSettingTabIds.ACCESS_PERMISSION:
        return (
          <DeliveryTeamPermission programmeId={programmeId} projectNumber={projectNumber} deliveryTeamId={team.id} />
        );
      case DeliveryTeamSettingTabIds.APPOINTING_PARTY:
        return <AddingAcceptorForDeliveryTeam projectNumber={projectNumber} deliveryTeamId={team.id} />;
      default:
        break;
    }
  };

  return (
    <div className={Style.deliveryTeamContainer}>
      <TeamDetailsHeader team={team} teamType={teamType} projectNumber={projectNumber} />
      {team.active && (
        <PageTab
          activeTab={selectedTabId}
          tabs={settingTabs}
          onSelectTab={onSelectTab}
          cssClass={Style.deliveryTeamSettingTab}
          tabMenuClass={Style.customTab}>
          {settingTabs.map((tab) => (
            <TabContent key={tab.id} for={tab.id}>
              {activeDeliveryTeam && renderTabContent(tab.id)}
            </TabContent>
          ))}
        </PageTab>
      )}
    </div>
  );
};

export default observer(DeliveryTeamDetails);
