import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import Style from '../../../styles/components/settings/projectManagement/ProjectFileMetadata.module.scss';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { MetaDataFieldTypeEnum } from '../../../common/enums/MetadataFieldType';
import { IFileMetadataField } from '../../../models/metadataSetting/metadataSettingModels';

interface IMetadataFieldActionButtonProps {
  metadataField: IFileMetadataField;
  onArchiveMetadata: () => void;
  onDeleteMetadata: () => void;
}
const MetadataFieldActionButton: FC<IMetadataFieldActionButtonProps> = (props) => {
  const { metadataField, onArchiveMetadata, onDeleteMetadata } = props;
  let archiveWaringMessage = metadataField.archived ? 'Unarchive' : 'Archive';

  if (metadataField.metaDataTypeId === MetaDataFieldTypeEnum.APIMSMetaDataField) {
    archiveWaringMessage = `Can't archive the APIMS metadata field.`;
  } else if (metadataField.isNamingConventionField && !metadataField.archived) {
    archiveWaringMessage = `Can't archive naming convention metadata field.`;
  } else if (metadataField.dataType.required) {
    archiveWaringMessage = `Can't archive because ${metadataField.title} is required metadata field.`;
  }

  let deleteWaringMessage = 'Delete';
  if (metadataField.metaDataTypeId === MetaDataFieldTypeEnum.APIMSMetaDataField) {
    deleteWaringMessage = `Can't delete the APIMS metadata field.`;
  } else if (metadataField.dataType.required) {
    deleteWaringMessage = `Can't delete because ${metadataField.title} is required metadata filed.`;
  }

  const disabledArchivedButton =
    metadataField.metaDataTypeId === MetaDataFieldTypeEnum.APIMSMetaDataField ||
    metadataField.isNamingConventionField ||
    metadataField.dataType.required ||
    (!!metadataField.dataType.parentFieldValueIndex && metadataField.parentArchived); // disabled when child has parent archive = true

  return (
    <span className={Style.tooltipWrapper}>
      {metadataField.isUsed || metadataField.archived ? (
        <Tooltip show={<div className={Style.tooltipText}>{archiveWaringMessage}</div>} defaultUp={true}>
          <PrimaryIconButton
            icon="inventory_2"
            size="medium"
            disabled={disabledArchivedButton}
            onClick={onArchiveMetadata}></PrimaryIconButton>
        </Tooltip>
      ) : (
        <Tooltip show={<div className={Style.tooltipText}>{deleteWaringMessage}</div>} defaultUp={true}>
          <PrimaryIconButton
            icon="delete"
            size="medium"
            disabled={
              metadataField.metaDataTypeId === MetaDataFieldTypeEnum.APIMSMetaDataField ||
              metadataField.dataType.required
            }
            onClick={onDeleteMetadata}></PrimaryIconButton>
        </Tooltip>
      )}
    </span>
  );
};

export default observer(MetadataFieldActionButton);
