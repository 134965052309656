import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SearchBar.module.scss';
import { SearchBox } from '@aurecon-creative-technologies/styleguide';
import PrimaryIconButton from './PrimaryIconButton';
import { classNames } from '../../utils/miscUtils';

export interface ISearchBarProps {
  onSearch(text: string): void;
  placeHolderText?: string;
  searchValue?: string;
  loading?: boolean;
  cssClass?: string;
}
const SearchBar: FC<ISearchBarProps> = ({ onSearch, placeHolderText, searchValue, loading = false, cssClass }) => {
  const [searchText, setSearchText] = useState(searchValue || '');

  useEffect(() => {
    setSearchText(searchValue || '');
  }, [searchValue]);

  return (
    <div className={classNames(Style.container, [cssClass !== undefined, cssClass])}>
      <SearchBox
        hideSearchButton
        onSearch={(value) => onSearch(value)}
        onClear={() => onSearch('')}
        onChange={(value) => setSearchText(value)}
        placeholder={placeHolderText}
        defaultValue={searchText}
      />
      <PrimaryIconButton icon="search" loading={loading} onClick={() => onSearch(searchText)} />
    </div>
  );
};

export default observer(SearchBar);
