import { serverAxios } from '../api';

export interface IHistoryUserItem {
  name: string;
  initials: string;
}
export interface IHistoryAttachmentFileItem {
  id: number;
  extension: string;
  originalFileName: string;
  totalSize: number;
  createdDate: Date;
  uploadedByUserId: number;
  uploadedByUserName: string;
}

export enum HistoryItemType {
  AssignedBy = 1,
  ReassignedTo = 2,
  ApprovedBy = 3,
  RejectedBy = 4,
  SupportFileAttachment = 5,
  WithdrawnBy = 6,
  TaskUserRemoved = 7,
  AllApproversRequired = 8,
  AllAcceptorsRequired = 9,
}

export interface IHistoryItem {
  eventDateTime: Date;
  historyItemType: HistoryItemType;
  message?: string;
  byUser: IHistoryUserItem;
  toUsers?: IHistoryUserItem[];
  attachmentFiles?: IHistoryAttachmentFileItem[];
  allApproversRequired?: boolean;
}

export async function getTaskHistory(taskId: number, abortSignal?: AbortSignal): Promise<IHistoryItem[]> {
  const result = await serverAxios.get<{ historyItems: IHistoryItem[] }>('api/task/history', {
    params: { taskId },
    signal: abortSignal,
  });
  return result.data?.historyItems;
}
