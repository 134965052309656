import { IImportUserResponse, IImportUsersRequest } from '../../../models/api/IImportUserModel';
import { serverAxios } from '../api';

interface IImportDistributionListUsersRequest extends IImportUsersRequest {
  deliveryTeamId: number;
}

export async function importDeliveryTeamUsers(
  request: IImportDistributionListUsersRequest,
  abortSignal?: AbortSignal
): Promise<IImportUserResponse> {
  const result = await serverAxios.post<IImportUserResponse>(
    'api/um/system/importdeliveryteamusers',
    { ...request },
    {
      signal: abortSignal,
    }
  );
  return result.data;
}
