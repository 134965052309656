import React from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/ProjectDetails.module.scss';
import { Grid, Toggle } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import { classNames } from '../../../utils/miscUtils';

const ProjectWorkflowDefaults = () => {
  return (
    <>
      {ProjectManagementStore.projectDetails && (
        <div className={Style.projectDetailsWrapper}>
          <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
            <div className={Style.formGroupLabel}>Workflow defaults</div>
            <Grid gap={16} item md={12}>
              <p className={Style.settingNoticed}>
                When creating a new task, this sets the default for task assignees to complete a task. Please note, the
                task initiator will still be able to change this toggle.
              </p>
            </Grid>
            <Grid gap={16} item md={12}>
              <div className={Style.formWorkflowLabel}>Shared Workflow</div>
            </Grid>
            <Grid row md={12} gap={8}>
              <Grid item md={12}>
                <div className={Style.formGroupContent}>
                  <Toggle
                    label="Require all approvers"
                    labelPosition="left"
                    onLabel="Yes"
                    offLabel="No"
                    cssClass={Style.toggle}
                    disabled={true}
                    value={ProjectManagementStore.projectDetails.projectWorkflowDefaultSettings.requireAllApprovers}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid gap={16} item md={12}>
              <div className={Style.formWorkflowLabel}>Published Workflow</div>
            </Grid>
            <Grid row md={12} gap={8}>
              <Grid item md={12}>
                <div className={Style.formGroupContent}>
                  <Toggle
                    label="Require all authorisers to authorise"
                    labelPosition="left"
                    onLabel="Yes"
                    offLabel="No"
                    cssClass={Style.toggle}
                    disabled={true}
                    value={ProjectManagementStore.projectDetails.projectWorkflowDefaultSettings.requireAllAuthorisers}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid row md={12} gap={8}>
              <Grid item md={12}>
                <div className={Style.formGroupContent}>
                  <Toggle
                    label=" Require all acceptors to accept"
                    labelPosition="left"
                    onLabel="Yes"
                    offLabel="No"
                    cssClass={Style.toggle}
                    disabled={true}
                    value={ProjectManagementStore.projectDetails.projectWorkflowDefaultSettings.requireAllAcceptors}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default observer(ProjectWorkflowDefaults);
