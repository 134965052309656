import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from './FilesStore';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import DropDownMenu from '../shared/DropDownMenu';
import Style from './styles/FileExportButton.module.scss';
import { IExportFile } from './upload/models/ExportFileModel';
import { ICSVData } from '../../common/interfaces/csvData';
import { CSVLink } from 'react-csv';
import { FileExportOption } from '../../common/enums/ExportOption';
import { IFileContainer } from '../../api/authenticated/cms/FileContainerModel';

const FileExportButton: FC = () => {
  const [exportData, setExportData] = useState<ICSVData<IExportFile> | null>(null);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const fetchDataAsync = async (ExportOption: FileExportOption) => {
    setLoading(true);
    setClicked(false);
    let mappingItems: IFileContainer[] = [];
    if (ExportOption === FileExportOption.ExportAll) {
      mappingItems = await FilesStore.downLoadAllFiles();
    }
    if (ExportOption === FileExportOption.ExportSelected) mappingItems = FilesStore.selectedFiles;
    const data = FilesStore.mappingExportData(mappingItems);
    setExportData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, loading]);
  return (
    <div className={Style.exportBtn}>
      <DropDownMenu
        disabled={!FilesStore.selectedFiles.length}
        label=""
        icon="file_download"
        itemsContainerClassName={Style.itemsContainer}
        menuClassName={Style.btnMenu}>
        <DropDownMenuButton disabled={loading} onClick={() => fetchDataAsync(FileExportOption.ExportSelected)}>
          Export Selected
        </DropDownMenuButton>
        <DropDownMenuButton disabled={loading} onClick={() => fetchDataAsync(FileExportOption.ExportAll)}>
          Export All
        </DropDownMenuButton>
        {!loading && exportData && (
          <CSVLink
            headers={exportData?.headers ?? []}
            filename={exportData?.filename}
            data={exportData?.data ?? []}
            ref={csvLinkRef}
          />
        )}
      </DropDownMenu>
    </div>
  );
};

export default observer(FileExportButton);
