import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IFileItem {
  id: number;
  fileContainerId: number;
  originalFileName: string;
  native: boolean;
}

export interface IContainerFileRequest {
  fileContainerId: number;
  fileContainerRevisionId: number;
}

export async function getFiles(
  projectNumber: string,
  taskTeamId: number,
  fileContainerStateId: FileContainerState,
  files: IContainerFileRequest[],
  abortSignal?: AbortSignal
): Promise<IFileItem[]> {
  const result = await serverAxios.post<{ files: IFileItem[] }>(
    'api/cms/getFiles',
    {
      projectNumber,
      taskTeamId,
      fileContainerStateId,
      files,
    },
    {
      signal: abortSignal,
    }
  );
  return result.data?.files;
}
