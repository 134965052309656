import React, { FC, useRef, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/AddContent.module.scss';
import { classNames } from '../../../utils/miscUtils';

export const fileTypes = [
  {
    id: 1,
    name: 'File Upload',
  },
];

interface IAddContentActionButtonsProps {
  children: ReactNode;
  leftIcon?: ReactNode;
  onClick: () => void;
}

const AddContentActionButtons: FC<IAddContentActionButtonsProps> = ({ onClick, children, leftIcon }) => {
  const dropdownButtonRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (!dropdownButtonRef.current) return;

    const current = dropdownButtonRef.current;
    current.addEventListener('click', onClick);

    return () => current.removeEventListener('click', onClick);
  }, [onClick]);

  return (
    <ul ref={dropdownButtonRef} className={classNames(Style.dropdownMenuList, Style.dropdownSubmenuList)}>
      <li className={Style.dropdownItem}>
        <span className={Style.iconButton}>{leftIcon}</span>
        {children}
      </li>
    </ul>
  );
};

export default observer(AddContentActionButtons);
