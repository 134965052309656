import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IUploadContainerFileRequest {
  blob: Blob;
  projectNumber: string;
  fileContainerId: number;
  containerFileId: number;
  fileContainerStateId: FileContainerState;
  prevFileContainerRevisionId: number | null;
  index: number;
  offset: number;
  isLastChunk: boolean;
  isSupersede?: boolean;
  fileName: string;
}

export async function upload(request: IUploadContainerFileRequest, abortSignal?: AbortSignal): Promise<number> {
  const formData = new FormData();
  formData.append('file', request.blob, request.fileName);
  formData.append('projectNumber', request.projectNumber);
  formData.append('containerFileId', request.containerFileId.toString());
  formData.append('fileContainerId', request.fileContainerId.toString());
  formData.append('fileContainerStateId', request.fileContainerStateId.toString());

  formData.append('index', request.index.toString());
  formData.append('offset', request.offset.toString());
  formData.append('isLastChunk', request.isLastChunk.toString());
  formData.append('isSupersede', request.isSupersede !== undefined && request.isSupersede ? 'true' : 'false');

  if (request.prevFileContainerRevisionId)
    formData.append('prevFileContainerRevisionId', request.prevFileContainerRevisionId.toString());

  const result = await serverAxios.post<number>('api/cms/upload', formData, { signal: abortSignal });
  return result.data;
}
