import { makeAutoObservable, runInAction } from 'mobx';

const defaultLoadingMessage = 'System settings loading';
export class SystemSettingsStore {
  constructor() {
    makeAutoObservable(this);
  }

  public isLoading = false;
  public loadingLabel = defaultLoadingMessage;

  public setShowLoading(showLoading: boolean, loadingMessage?: string) {
    runInAction(() => {
      this.isLoading = showLoading;
      this.loadingLabel = showLoading && loadingMessage ? loadingMessage : defaultLoadingMessage;
    });
  }
}

export default new SystemSettingsStore();
