import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskReassignedEvent } from '../../../api/authenticated/cms/getFileHistory';
import TaskEvent from './TaskEvent';

export interface ITaskEventReassignedProps {
  taskReassignedEvents: ITaskReassignedEvent[];
}

const TaskEventReassigned: FC<ITaskEventReassignedProps> = ({ taskReassignedEvents }) => {
  if (taskReassignedEvents.length === 0) return null;

  return (
    <>
      <TaskEvent icon="group" label="Reassigned to">
        {taskReassignedEvents.map((event) => (
          <div key={event.reassignedTo.userId}>{event.reassignedTo.userName}</div>
        ))}
      </TaskEvent>
      <TaskEvent icon="rate_review" label="Reassigned by">
        {taskReassignedEvents.map((event) => (
          <div key={event.reassignedBy.userId}>{event.reassignedBy.userName}</div>
        ))}
      </TaskEvent>
    </>
  );
};

export default observer(TaskEventReassigned);
