import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getSplashPageContent } from '../../api/authenticated/config/getSplashPageContent';
import Loading from './Loading';
import Style from './styles/SplashPage.module.scss';
import DOMPurify from 'dompurify';

const SplashPage: FC = () => {
  const [splashPageContent, setSplashPageContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchSplashPageContent = async () => {
      const content = await getSplashPageContent();
      const cleanHTMLContent = DOMPurify.sanitize(content);
      setSplashPageContent(cleanHTMLContent);
    };

    setLoading(true);
    fetchSplashPageContent();
    setLoading(false);
  }, []);

  return (
    <div>
      <div className={Style.content} dangerouslySetInnerHTML={{ __html: splashPageContent }} />
      <Loading isLoading={loading} label={'Loading splash page'}></Loading>
    </div>
  );
};

export default observer(SplashPage);
