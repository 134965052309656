import { IGetProjectFileDocumentViewer } from '../../../models/api/IGetProjectFileDocumentViewer';
import { serverAxios } from '../api';

export async function getProjectFileDocumentViewerUrl(
  data: IGetProjectFileDocumentViewer,
  abortSignal?: AbortSignal
): Promise<string | null> {
  const result = await serverAxios.get<{ url: string | null }>('api/cms/projectfile/documentviewerurl', {
    params: {
      projectNumber: data.projectNumber,
      fileContainerId: data.fileContainerId,
      fileContainerRevisionId: data.fileContainerRevisionId,
      fileId: data.fileId,
      releasedFileContainerId: data.releasedFileContainerId,
      sharePointReleasedFileId: data.sharePointReleasedFileId,
      transmittalId: data.transmittalId,
      transmittalMessageId: data.transmittalMessageId,
    },
    signal: abortSignal,
  });
  return result.data?.url;
}
