import { serverAxios } from '../api';

export interface IEditDeliveryTeamRequest {
  deliveryTeamId: number;
  projectNumber: string;
  title: string;
}

export async function editDeliveryTeam(request: IEditDeliveryTeamRequest, abortSignal?: AbortSignal): Promise<void> {
  return await serverAxios.post('api/um/deliveryteam/edit', request, {
    signal: abortSignal,
  });
}
