import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import SystemUserList from './SystemUserList';
import CreateSystemUser from './AddSystemUser';
import Style from '../../../../styles/components/settings/systemAdminSettings/systemUsers/TabContainer.module.scss';
import SystemUsersStore from '../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import ErrorModal from '../../../shared/ErrorModal';

const SystemUsers: FC = () => {
  return (
    <div className={Style.wrapContainer}>
      <CreateSystemUser />
      <SystemUserList />

      {SystemUsersStore.showError && (
        <ErrorModal
          closeModal={() => SystemUsersStore.setShowAlreadyExists(false)}
          errorCode={SystemUsersStore.errorCode}
          errorMessage={SystemUsersStore.errorMessage}
        />
      )}
    </div>
  );
};

export default observer(SystemUsers);
