export enum SystemSettingKeys {
  General,
  THEME,
  LOGINPAGE,
  METADATA,
  MAILSERVER,
  SUPPORT,
  FILE,
  TASK,
  VISUALISATION,
  METADATA_TRANSMITTALS,
  TABLELIST,
  BULKUPLOAD,
  WORKINPROGRESS,
  SHARED,
  PUBLISHED,
  FAQ,
  USERGUIDE,
}
export const SystemSettingLabels = {
  [SystemSettingKeys.General]: 'General',
  [SystemSettingKeys.THEME]: 'Theme',
  [SystemSettingKeys.LOGINPAGE]: 'Login Page',
  [SystemSettingKeys.METADATA]: 'Metadata',
  [SystemSettingKeys.MAILSERVER]: 'Mail Server',
  [SystemSettingKeys.SUPPORT]: 'Support',
  [SystemSettingKeys.FILE]: 'File',
  [SystemSettingKeys.TASK]: 'Task',
  [SystemSettingKeys.VISUALISATION]: 'Visualisation',
  [SystemSettingKeys.METADATA_TRANSMITTALS]: 'Transmittals',
  [SystemSettingKeys.TABLELIST]: 'Table List',
  [SystemSettingKeys.BULKUPLOAD]: 'Bulk Upload',
  [SystemSettingKeys.WORKINPROGRESS]: 'Work In Progress',
  [SystemSettingKeys.SHARED]: 'Shared',
  [SystemSettingKeys.PUBLISHED]: 'Published',
  [SystemSettingKeys.FAQ]: 'FAQ',
  [SystemSettingKeys.USERGUIDE]: 'User Guide',
};

export default { SystemSettingKeys, SystemSettingLabels };
