import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../shared/Modal';
import CloseButton from '../shared/CloseButton';
import PrimaryButton from '../shared/PrimaryButton';
import ModalActions from '../shared/ModalActions';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import Style from './styles/ExportByFileModal.module.scss';
import SecondaryButton from '../shared/SecondaryButton';
import DateFilter from '../shared/DateFilter';
import { DateFilterOperator, IDateRangeFilter } from '../../common/interfaces/TableColumn';
import { CSVLink } from 'react-csv';
import TransmittalsStore from './TransmittalsStore';
import { ITransmittalExportByFileResponse } from '../../api/authenticated/transmittals/getTransmittalsExportByFile';
import { endOfToday } from 'date-fns/esm';

export interface ITransmittalExportByFileCSVData {
  filename: string;
  headers: { label: string; key: string }[];
  data: ITransmittalExportByFileResponse[];
}

interface IExportByFileModalProps {
  onCancel: () => void;
}

const ExportByFileModal: FC<IExportByFileModalProps> = ({ onCancel }) => {
  const endDate = new Date();
  const startDate = new Date(new Date().setDate(endDate.getDate() - 30));
  const [invalidDateFilter, setInvalidDateFilter] = useState<boolean>(false);

  const [dateFilter, setDateFilter] = useState<IDateRangeFilter>({
    startDate: startDate,
    endDate: endDate,
    operator: DateFilterOperator.BETWEEN,
  });
  const [csvData, setCsvData] = useState<ITransmittalExportByFileCSVData | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [clicked, setClicked] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const fetchDataAsync = async () => {
    setClicked(false);
    await TransmittalsStore.getExportByFileTransmittals(dateFilter.startDate, dateFilter.endDate, dateFilter.operator);
    const data = TransmittalsStore.mappingCSVExportByFile(TransmittalsStore.exportByFileTransmittals);
    setCsvData(data);
    setLoaded(true);
  };

  useEffect(() => {
    if (loaded && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
      setLoaded(false);
      onCancel();
    }
  }, [clicked, loaded, onCancel]);

  const isInvalidDateFilter = (date: Date | null) => {
    const todayEnd = endOfToday();
    return !!date && date > todayEnd;
  };

  const onDateFilterChange = (value: IDateRangeFilter) => {
    if (value.operator !== DateFilterOperator.BETWEEN) value.endDate = null;
    setDateFilter(value);
    setInvalidDateFilter(isInvalidDateFilter(value.startDate) || isInvalidDateFilter(value.endDate));
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.exportByFileModal}>
        <CloseButton onClick={onCancel} />
        <h1 className={Style.title}>Export by File</h1>
        <div>
          <span className={Style.label}>Last Updated</span>
          <DateFilter
            dateFilter={dateFilter}
            onChange={(value) => onDateFilterChange(value)}
            size={'medium'}
            className={Style.wrapperDateFilter}
            datePickerClassName={Style.datePicker}
          />
          {invalidDateFilter && <span className={Style.inputError}>The date cannot be greater than today</span>}
        </div>

        <ModalActions className={Style.modalActions}>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={!dateFilter.startDate || !!invalidDateFilter}
            onClick={fetchDataAsync}
            loading={loaded}>
            Export
          </PrimaryButton>
        </ModalActions>
      </Modal>
      {loaded && (
        <CSVLink
          headers={csvData?.headers ?? []}
          filename={csvData?.filename}
          data={csvData?.data ?? []}
          ref={csvLinkRef}
        />
      )}
    </CentreOnPage>
  );
};

export default observer(ExportByFileModal);
