import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import FileInformationStore from './FileInformationStore';
import HistoryRelease from './HistoryRelease';
import HistoryUpload from './HistoryUpload';
import HistoryTask from './HistoryTask';
import Style from './styles/FileInformationHistory.module.scss';
import HistoryUploadContent from './HistoryUploadContent';

const History: FC = () => {
  if (FileInformationStore.file === null) return null;

  if (FileInformationStore.isLoadingHistory) return <div>Loading...</div>;

  return (
    <ol className={Style.historyItems}>
      {FileInformationStore.historyItems.map((item, i) => {
        if (item.releaseHistoryItem) {
          return (
            <HistoryRelease
              key={`release:${item.releaseHistoryItem.releasedFileContainerId}`}
              item={item}
              release={item.releaseHistoryItem}
            />
          );
        }
        if (item.uploadHistoryItem) {
          return <HistoryUpload key="upload" item={item} upload={item.uploadHistoryItem} />;
        }
        if (item.taskHistoryItem) {
          return <HistoryTask key={`task:${item.taskHistoryItem.taskId}`} item={item} task={item.taskHistoryItem} />;
        }

        if (item.uploadContentHistoryItem) {
          const key = `upload-content:${item.uploadContentHistoryItem.id}-${i}`;
          return <HistoryUploadContent key={key} historyItem={item.uploadContentHistoryItem} />;
        }
        return null;
      })}
    </ol>
  );
};

export default observer(History);
