import { serverAxios } from '../api';
import {
  IAddDeliveryTeamAcceptorUsers,
  IDeliveryTeamAcceptorUsersResponse,
} from './interfaces/deliveryTeamPermission.interface';

export async function getDeliveryTeamAcceptorUsers(
  projectNumber: string,
  deliveryTeamId: number,
  abortSignal?: AbortSignal
): Promise<IDeliveryTeamAcceptorUsersResponse[]> {
  const result = await serverAxios.get<{ acceptorUsers: IDeliveryTeamAcceptorUsersResponse[] }>(
    'api/um/deliveryteam/getAcceptorUsers',
    {
      params: {
        projectNumber: projectNumber,
        deliveryTeamId: deliveryTeamId,
      },
      signal: abortSignal,
    }
  );
  return result.data.acceptorUsers ?? [];
}

export function addDeliveryTeamAcceptorUsers(
  request: IAddDeliveryTeamAcceptorUsers,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/deliveryteam/addAcceptorUsers', request, {
    signal: abortSignal,
  });
}
