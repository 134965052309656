import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import BasemapToggle from '@arcgis/core/widgets/BasemapToggle';

import WidgetBase, { IWidgetBase } from './WidgetBase';
import VisualisationStore from '../VisualisationStore';
import { BaseMaps } from '../../../common/constants/BaseMaps';

const BaseMapToggleWidget: FC = () => {
  return WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new BasemapToggle({
        visible: true,
        nextBasemap: VisualisationStore.projectArcGisSettings.toggleMap ?? BaseMaps.TOGGLE,
        container: properties.container,
        view: properties.view,
      })
  );
};
export default observer(BaseMapToggleWidget);
