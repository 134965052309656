import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  IFileMetadataField,
  IFileMetadataValue,
  IRemoveArchiveMetadataValueModel,
  MetadataFieldValueActionRef,
} from '../../../models/metadataSetting/metadataSettingModels';
import Style from '../../../styles/components/settings/projectManagement/ProjectFileMetadata.module.scss';
import ConfirmationModal from '../../shared/ConfirmationModal';
import PrimaryIconButton from '../../shared/PrimaryIconButton';

interface IMetadataFieldValueActionButtonProps {
  metadataFieldValue: IFileMetadataValue;
  metadataField: IFileMetadataField;
  isSaving: boolean;
  onConfirmArchiveMetadataFieldValue: (archiveValue: IRemoveArchiveMetadataValueModel) => void;
  onConfirmDeleteMetadataFieldValue: (deleteValue: IRemoveArchiveMetadataValueModel) => void;
}

const MetadataFieldValueActionButton = forwardRef<MetadataFieldValueActionRef, IMetadataFieldValueActionButtonProps>(
  (props, ref) => {
    const { metadataFieldValue } = props;
    let archiveWaringMessage = metadataFieldValue.archived ? 'Unarchive' : 'Archive';
    let deleteWaringMessage = 'Delete';
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
    const [actionModel, setActionModel] = useState<IRemoveArchiveMetadataValueModel | null>(null);
    const modalHeading = metadataFieldValue.isUsed ? `${archiveWaringMessage} metadata value` : 'Delete metadata field';

    const modalContentMessage = metadataFieldValue.isUsed
      ? `This action will ${archiveWaringMessage.toLocaleLowerCase()} this metadata value. Are you sure you want to ${archiveWaringMessage.toLocaleLowerCase()} ${
          metadataFieldValue.title
        }?`
      : `This action will delete this metadata value and can not be undone. Are you sure you want to delete ${metadataFieldValue.title}?`;

    // Expose specific function to parent
    useImperativeHandle(ref, () => ({
      closeModal: () => {
        setOpenConfirmModal(false);
      },
    }));

    const notLatestItem = props.metadataField.dataType.fieldValues.filter((x) => !x.archived).length > 1;
    if (!notLatestItem) {
      deleteWaringMessage = `[${props.metadataField.title}] needs at least one available value.`;
      archiveWaringMessage = `[${props.metadataField.title}] needs at least one available value.`;
    }

    return (
      <>
        <span className={Style.tooltipWrapper}>
          {metadataFieldValue.isUsed ? (
            <Tooltip show={<div className={Style.tooltipText}>{archiveWaringMessage}</div>} defaultUp={true}>
              <PrimaryIconButton
                icon="inventory_2"
                size="medium"
                disabled={props.metadataField.archived || !notLatestItem}
                onClick={() => {
                  setActionModel({
                    metadataField: props.metadataField,
                    metadataValue: metadataFieldValue,
                  });
                  setOpenConfirmModal(true);
                }}></PrimaryIconButton>
            </Tooltip>
          ) : (
            <Tooltip show={<div className={Style.tooltipText}>{deleteWaringMessage}</div>} defaultUp={true}>
              <PrimaryIconButton
                icon="delete"
                size="medium"
                disabled={props.metadataField.archived || !notLatestItem}
                onClick={() => {
                  setActionModel({
                    metadataField: props.metadataField,
                    metadataValue: metadataFieldValue,
                  });
                  setOpenConfirmModal(true);
                }}></PrimaryIconButton>
            </Tooltip>
          )}
        </span>

        {actionModel && (
          <ConfirmationModal
            showModal={openConfirmModal}
            heading={modalHeading}
            message={modalContentMessage}
            confirmText="Yes"
            cancelText="No"
            loading={props.isSaving}
            onConfirm={() =>
              metadataFieldValue.isUsed
                ? props.onConfirmArchiveMetadataFieldValue(actionModel)
                : props.onConfirmDeleteMetadataFieldValue(actionModel)
            }
            onCancel={() => {
              setActionModel(null);
              setOpenConfirmModal(false);
            }}></ConfirmationModal>
        )}
      </>
    );
  }
);

MetadataFieldValueActionButton.displayName = 'MetadataFieldValueActionButton';
export default observer(MetadataFieldValueActionButton);
