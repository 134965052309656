import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableRow, TableCell, Checkbox, IHeader } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/EditFileMetadata.module.scss';
import { getFileSizeString } from '../../../utils/miscUtils';
import { IFileDataModel } from './models/IFileDataModel';
import { orderBy } from 'lodash';
import { SortTypes } from '../../../common/enums/SortType';
import UploadStore from '../upload/UploadStore';
import { ApimsMetaDataType, MetadataFieldTitle } from '../../../common/enums/MetadataFieldType';
import AppStore from '../../../stores/AppStore';

interface IEditableFilesTableProps {
  files: IFileDataModel[];
  onFileSelected: (fileIds: number[]) => void;
}

const EditableFilesTable: FC<IEditableFilesTableProps> = (props) => {
  const { files, onFileSelected } = props;
  const [selectedFileIds, setSelectedFileIds] = useState<number[]>([]);

  const handleFileSelected = (fileIds: number[], checked: boolean) => {
    let newSelectedFileIds = [...selectedFileIds];
    fileIds.forEach((id) => {
      const idx = newSelectedFileIds.findIndex((f) => f === id);
      if (!checked && idx > -1) {
        newSelectedFileIds.splice(idx, 1);
        return;
      }

      newSelectedFileIds = idx > -1 ? newSelectedFileIds : [...newSelectedFileIds, id];
    });

    setSelectedFileIds(newSelectedFileIds);
    onFileSelected(newSelectedFileIds);
  };

  const headers = (): IHeader[] => {
    let headers: IHeader[] = [
      {
        label: '',
        onCheckbox: (checked) =>
          handleFileSelected(
            files.map((f) => f.id),
            checked
          ),
        checked: files.length === selectedFileIds.length,
      },
      {
        label: 'File Name',
      },
      {
        label: 'Original File Name',
      },
      ...UploadStore.fileMetadata
        .filter((metaData) => MetadataFieldTitle.includes(metaData.title))
        .map((f) => ({
          label: f.title,
        })),
      ...orderBy(
        UploadStore.fileMetadata.filter((metaData) => !MetadataFieldTitle.includes(metaData.title)),
        (item) => item.fieldValueIndex,
        SortTypes.ASC
      ).map((f) => ({
        label: f.title,
      })),
      {
        label: 'File Size',
      },
    ];

    if (AppStore.isSystemAdmin || AppStore.projectAdminister) {
      headers = [
        ...headers,
        {
          label: UploadStore.pattern1Metadata?.description ?? ApimsMetaDataType.Pattern1,
        },
        {
          label: UploadStore.pattern2Metadata?.description ?? ApimsMetaDataType.Pattern2,
        },
        {
          label: UploadStore.pattern3Metadata?.description ?? ApimsMetaDataType.Pattern3,
        },
      ];
    }

    return headers;
  };

  return (
    <div className={Style.files}>
      <div className={Style.filesContainer}>
        <div className={Style.fileHeading}>
          <div className={Style.numberFiles}>Files to Edit ({files.length})</div>
        </div>
        <Table cssClass={Style.filesTable} headers={headers()} hoverable>
          {files.map((f) => (
            <TableRow key={f.id}>
              <TableCell>
                <Checkbox
                  onChange={(checked) => handleFileSelected([f.id], checked)}
                  checked={selectedFileIds.some((fileId) => fileId === f.id)}
                />
              </TableCell>
              <TableCell>{f.fileName}</TableCell>
              <TableCell>{f.originalFileName}</TableCell>

              {f.metadata
                .filter((m) =>
                  UploadStore.fileMetadata.some(
                    (fm) => fm.fieldValueIndex === m.fieldIndex && MetadataFieldTitle.includes(fm.title)
                  )
                )
                .map((m) => (
                  <TableCell key={m.fieldIndex}>{m.fieldValue}</TableCell>
                ))}
              {orderBy(
                f.metadata.filter((m) =>
                  UploadStore.fileMetadata.some(
                    (fm) => fm.fieldValueIndex === m.fieldIndex && !MetadataFieldTitle.includes(fm.title)
                  )
                ),
                (item) => item.fieldIndex,
                SortTypes.ASC
              ).map((m) => (
                <TableCell key={m.fieldIndex}>{m.fieldValue}</TableCell>
              ))}
              <TableCell>{getFileSizeString(f.fileSize)}</TableCell>
              {(AppStore.isSystemAdmin || AppStore.projectAdminister) && AppStore.applyApims && (
                <>
                  <TableCell>{f.pattern1}</TableCell>
                  <TableCell>{f.pattern2}</TableCell>
                  <TableCell>{f.pattern3}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default observer(EditableFilesTable);
