import { serverAxios } from '../api';

export interface IClientFileTemplateDownloadUrlsResponse {
  url?: string;
  errorMessage?: string;
}
export interface IClientFileTemplateDownloadUrlRequest {
  clientFileTemplateFileIds: number[];
}

export async function getClientFileTemplateDownloadUrls(
  request: IClientFileTemplateDownloadUrlRequest,
  abortSignal?: AbortSignal
): Promise<IClientFileTemplateDownloadUrlsResponse[]> {
  const result = await serverAxios.post<{ urls: IClientFileTemplateDownloadUrlsResponse[] }>(
    'api/config/getClientFileTemplateDownloadUrls',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data?.urls;
}
