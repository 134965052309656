import { serverAxios } from '../api';
import { IArchiveMetadataFieldRequest } from './models/metadataFieldModel';

export async function archiveMetadataField(
  request: IArchiveMetadataFieldRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.put('api/config/projects/metadata/archive', request, {
    signal: abortSignal,
  });
  return result.data;
}
