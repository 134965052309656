import { serverAxios } from '../api';

export interface IAppVersion {
  version: string;
}

export async function getAppVersion(abortSignal?: AbortSignal): Promise<IAppVersion> {
  const result = await serverAxios.get<IAppVersion>('api/config/app/version', { signal: abortSignal });
  return result.data;
}
