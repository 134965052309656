import Icon from '../../shared/Icon';
import * as React from 'react';
import { observer } from 'mobx-react-lite';
import NavBarSelectorItemContainer from '../../shared/NavBarSelectorItemContainer';
import Style from './styles/NavBarSelectorProject.module.scss';
import { IItem } from './NavBarSelectorStore';

export interface INavBarSelectorProjectProps {
  item: IItem;
}

const NavBarSelectorProject: React.FC<INavBarSelectorProjectProps> = ({ item }) => {
  return (
    <NavBarSelectorItemContainer className={Style.projectItem}>
      <Icon name="folder" className={Style.icon} />
      <span>{item.project?.programmeTitle}</span>
      <span>|</span>
      <span>{item.project?.title}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorProject);
