import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { FormInput } from '@aurecon-creative-technologies/styleguide';
import { useReviewStore } from './ReviewStoreContext';
import Style from './styles/Message.module.scss';

const Message: FC = () => {
  const store = useReviewStore();
  return (
    <div className={Style.container}>
      <FormInput
        label="Message"
        placeholder="Message"
        value={store.selectedMessage}
        onChange={(s) => store.setSelectedMessage(s)}
        multiline
        multilineLimit={2000}
      />
    </div>
  );
};

export default observer(Message);
