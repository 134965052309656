import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskRejectedEvent } from '../../../api/authenticated/cms/getFileHistory';
import TaskEvent from './TaskEvent';

export interface ITaskEventRejectedProps {
  taskRejectedEvents: ITaskRejectedEvent[];
}

const TaskEventRejected: FC<ITaskEventRejectedProps> = ({ taskRejectedEvents }) => {
  if (taskRejectedEvents.length === 0) return null;

  return (
    <TaskEvent icon="cancel" label="Rejected by">
      {taskRejectedEvents.map((event) => (
        <div key={event.rejectedBy.userId}>{event.rejectedBy.userName}</div>
      ))}
    </TaskEvent>
  );
};

export default observer(TaskEventRejected);
