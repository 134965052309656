import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import Icon from './Icon';
import Style from './styles/SecondaryIconButton.module.scss';

export interface ISecondaryIconButtonProps {
  className?: string;
  icon: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SecondaryIconButton: FC<ISecondaryIconButtonProps> = ({ className, icon, disabled, active, onClick }) => {
  return (
    <button
      className={classNames(Style.iconButton, [!!active, Style.active], className)}
      disabled={disabled}
      onClick={onClick}>
      <Icon name={icon} />
    </button>
  );
};

export default observer(SecondaryIconButton);
