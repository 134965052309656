import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';
import PrimaryButton from '../../shared/PrimaryButton';
import ModalActions from '../../shared/ModalActions';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import { ComboBox, IOption } from '@aurecon-creative-technologies/styleguide';
import UploadStore from './UploadStore';
import Style from './styles/MoveFileContainerModal.module.scss';
import { IFileMoveProps } from './SelectedFiles';
import SecondaryButton from '../../shared/SecondaryButton';
import { ContainerFileMoveType } from '../../../common/enums/ContainerFileMoveType';

interface IMoveFileContainerModalProps {
  onCancel: () => void;
  fileMove: IFileMoveProps | null;
}

const MoveFileContainerModal: FC<IMoveFileContainerModalProps> = ({ onCancel, fileMove }) => {
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.moveFileContainerModal}>
        <CloseButton onClick={onCancel} />
        <ComboBox
          label="Move this file to"
          placeholder="- Select other container -"
          showIcon={false}
          selected={UploadStore.moveFileContainerSelectedId}
          onSelect={(value) => value && UploadStore.setMoveFileContainerSelectedId(value.id.toString())}
          onClear={() => UploadStore.setMoveFileContainerSelectedId()}
          options={UploadStore.selectedFileContainers
            .filter((t) => t.groupedContainerName && t.files.length > 1 && t.tempId !== fileMove?.tempId)
            .map<IOption>((s) => {
              return {
                id: s.tempId,
                value: `${s.groupedContainerName}`,
              };
            })}
          cssClass={Style.comboBox}
        />
        <ModalActions>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={!UploadStore.moveFileContainerSelectedId}
            onClick={() => {
              if (UploadStore.moveFileContainerSelectedId) {
                onCancel();
                fileMove?.type === ContainerFileMoveType.MoveToOtherContainer
                  ? UploadStore.moveFileContainerToOther(fileMove)
                  : UploadStore.moveFileToAContainer(fileMove);
              }
            }}>
            Move file
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(MoveFileContainerModal);
