import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ReviewStoreContext } from './ReviewStoreContext';
import { IReviewStoreParams, ReviewStore } from './ReviewStore';
import ReviewModalContainer from './ReviewModalContainer';

type IReviewModalProps = IReviewStoreParams;

const ReviewModal: FC<IReviewModalProps> = (props) => {
  return (
    <ReviewStoreContext.Provider value={useMemo(() => new ReviewStore(props), [props])}>
      <ReviewModalContainer />
    </ReviewStoreContext.Provider>
  );
};

export default observer(ReviewModal);
