import { serverAxios } from '../api';

export interface ISettings {
  uploadChunkSize: number;
  sequenceNumberLength: number;
  revisionSettings: IRevisionSettings[];
}

export interface IRevisionSettings {
  namingConventionId: number;
  fileStateId: number;
  code: number;
}

export async function getSetting(projectNumber: string, abortSignal?: AbortSignal): Promise<ISettings> {
  const result = await serverAxios.get<ISettings>(`/api/config/setting`, {
    params: { projectNumber },
    signal: abortSignal,
  });
  return result.data;
}
