import { serverAxios } from '../api';
import { IProjectDetails } from './models/projectModel';

export async function getProjectDetails(projectNumber: string, abortSignal?: AbortSignal): Promise<IProjectDetails> {
  const result = await serverAxios.get<{ project: IProjectDetails }>(`/api/config/projects/details`, {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.project;
}
