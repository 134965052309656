import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Slice from '@arcgis/core/widgets/Slice';

import WidgetBase, { IWidgetBase } from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';
import SceneView from '@arcgis/core/views/SceneView';

const SliceWidget: FC = () => {
  return WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new Slice({
        visible: true,
        container: properties.container,
        view: properties.view as SceneView,
      }),
    Style.sliceWidgetWrapper
  );
};

export default observer(SliceWidget);
