import { serverAxios } from '../api';
import { IRevokeTemporaryAccessRequest } from './interface/getTemporaryAccessLogRequest';

export async function revokeTemporaryAccess(
  request: IRevokeTemporaryAccessRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return await serverAxios.post('api/cms/revokeTemporaryAccess', request, {
    signal: abortSignal,
  });
}
