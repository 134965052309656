import { serverAxios } from '../api';
import { IAccessibleDeliveryTeamResponse } from './interfaces/deliveryTeamPermission.interface';

export async function getAccessibleDeliveryTeams(
  projectNumber: string,
  deliveryTeamId: number,
  abortSignal?: AbortSignal
): Promise<IAccessibleDeliveryTeamResponse[]> {
  const result = await serverAxios.get<{ accessibleDeliveryTeams: IAccessibleDeliveryTeamResponse[] }>(
    'api/um/deliveryteam/accessibledeliveryteams',
    {
      params: {
        projectNumber: projectNumber,
        deliveryTeamId: deliveryTeamId,
      },
      signal: abortSignal,
    }
  );
  return result.data.accessibleDeliveryTeams ?? [];
}
