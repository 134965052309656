import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface ILockFileRequest {
  taskTeamId: number;
  fileContainerId: number;
  fileContainerRevisionId: number;
  fileContainerStateId: FileContainerState;
}

export interface ILockFileResponse {
  message: string;
}

export async function lockFile(request: ILockFileRequest, abortSignal?: AbortSignal): Promise<ILockFileResponse> {
  const result = await serverAxios.post('api/cms/lockfile', request, {
    signal: abortSignal,
  });
  return result.data;
}
