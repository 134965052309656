import React, { FC, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IColumnMoving, IHeader, Loader, Pagination, Table } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/FileTable.module.scss';
import FileTableRow from './FileTableRow';
import FilesStore, { APIMS_COLUMNS, ActionColumns } from '../FilesStore';
import { IDateRangeFilter, IOptionListFilter, ITableColumn } from '../../../common/interfaces/TableColumn';
import DateFilter from '../../shared/DateFilter';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import { SortType } from '../../../common/enums/SortType';
import ErrorModal from '../../shared/ErrorModal';
import ListFilter from '../../shared/ListFilter';
import UserTextFilter from '../../shared/UserTextFilter';
import AppStore from '../../../stores/AppStore';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import classNames from 'classnames';

export interface IFileTableProps {
  tableColumns: ITableColumn[];
  tableFiles: IFileContainer[];
  onFilter?: (column: string, value?: string | string[] | IDateRangeFilter) => void;
  onSortColumnChange?: (column: string, direction?: SortType) => void;
  onEdit: (fileId: number) => void;
}

const FileTable: FC<IFileTableProps> = ({ tableColumns, tableFiles, onFilter, onSortColumnChange, onEdit }) => {
  const [columns, setColumns] = useState([...tableColumns]);

  useLayoutEffect(() => {
    setColumns(
      tableColumns.filter((f) => FilesStore.getAdjustableTableColumns().some((s) => s.id === f.valueField && s.visible))
    );
  }, [tableColumns]);

  const filterChangeHandler = (tableColumn: ITableColumn, filter?: string | string[] | IDateRangeFilter) => {
    if (!onFilter) return;
    appInsightsTrackEvent(AppInsightEvent.APPLY_FILE_FILTER, {
      [tableColumn.label]: filter ?? '',
    });

    onFilter(tableColumn.valueField, filter);
  };

  const sortColumnClickHandler = (tableColumn: ITableColumn, direction: SortType) => {
    if (FilesStore.isLoading) return;
    onSortColumnChange?.(tableColumn.valueField, direction);
  };

  const getFilterComponent = (tableColumn: ITableColumn) => {
    if (tableColumn.listFilter && tableColumn.valueField === 'deliveryTeamTitle')
      return (
        <ListFilter
          onSelectItems={(values) => filterChangeHandler(tableColumn, values)}
          listFilter={tableColumn.listFilter}
          customListFilter={FilesStore.taskTeamFilter.map<IOptionListFilter>((o) => {
            return {
              id: o.taskTeamId,
              value: `${o.deliveryTeamTitle} | ${o.taskTeamTitle}`,
              display: (
                <span>
                  {o.deliveryTeamTitle} | <b>{o.taskTeamTitle}</b>
                </span>
              ),
            };
          })}
          disabled={FilesStore.showSelectedFiles || FilesStore.isLoading}
          multiple
          className={Style.tableListFilter}
          key={tableColumn.valueField}
        />
      );
    if (tableColumn.listFilter)
      return (
        <ListFilter
          onSelectItems={(values) => {
            filterChangeHandler(tableColumn, values);
          }}
          listFilter={tableColumn.listFilter}
          disabled={FilesStore.showSelectedFiles || FilesStore.isLoading}
          className={Style.tableListFilter}
          multiple
          key={tableColumn.valueField}
        />
      );

    if (tableColumn.textFilter)
      return (
        <UserTextFilter
          textFilter={tableColumn.textFilter}
          disabled={FilesStore.showSelectedFiles || FilesStore.isLoading}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
        />
      );

    if (tableColumn.dateFilter)
      return (
        <DateFilter
          dateFilter={tableColumn.dateFilter}
          disabled={FilesStore.showSelectedFiles || FilesStore.isLoading}
          key={tableColumn.valueField}
          onChange={(value) => filterChangeHandler(tableColumn, value)}
        />
      );
  };

  const isAPIMS = AppStore.applyApims;
  const headers = columns
    .filter((c) => isAPIMS || (!isAPIMS && !APIMS_COLUMNS.includes(c.valueField)))
    .map((tableColumn) => {
      const sortDirection = tableColumn.sort ? tableColumn.sort : 'none';
      const isActionColumn = ActionColumns.includes(tableColumn.valueField);
      const isResizable = !isActionColumn && !FilesStore.isLoading;
      return {
        label: tableColumn.label,
        sort: isActionColumn ? undefined : sortDirection,
        onSort: isActionColumn ? undefined : (sort) => sortColumnClickHandler(tableColumn, sort as SortType),
        filter: getFilterComponent(tableColumn),
        style: isActionColumn ? undefined : { position: 'relative' },
        resizable: isResizable,
        onResize: (data) => {
          handleResizeTableColumn(data.label, data.width);
        },
        draggable: !isActionColumn,
        width: tableColumn.width,
        minWidth: tableColumn.minWidth,
        checked: FilesStore.areAllFilesSelected(),
        onCheckbox: tableColumn.onCheckbox,
      } as IHeader;
    });

  const reorder = (data: IColumnMoving) => {
    if (FilesStore.isLoading) {
      return;
    }
    const moveColumn = columns[data.fromIndex];
    if (moveColumn) {
      columns.splice(data.fromIndex, 1);
      columns.splice(data.toIndex, 0, moveColumn);
      setColumns([...columns]);

      const adjustableTableColumns = [...FilesStore.getAdjustableTableColumns()];
      const movingAdjustableTableColumnWidth =
        adjustableTableColumns.find((a) => a.id === moveColumn.valueField)?.width ?? moveColumn.width;

      adjustableTableColumns.splice(data.fromIndex, 1);
      adjustableTableColumns.splice(data.toIndex, 0, {
        id: moveColumn.valueField,
        label: moveColumn.label,
        width: movingAdjustableTableColumnWidth!,
        visible: true,
      });

      FilesStore.setAdjustableTableColumns(adjustableTableColumns);
    }
  };

  const renderFileTableRow = () => {
    const simplifiedHeaders = headers.map((m) => {
      return {
        label: m.label,
        onCheckbox: m.onCheckbox,
      };
    });

    return tableFiles.map((row) => (
      <FileTableRow
        key={FilesStore.getFileKey(row)}
        headers={simplifiedHeaders}
        file={row}
        tableColumns={columns}
        onEdit={onEdit}
      />
    ));
  };

  const handleResizeTableColumn = (label: string, width: number) => {
    const adjustableColumns = FilesStore.getAdjustableTableColumns().map((m) => {
      return { ...m, width: m.label === label ? width : m.width };
    });
    const stateColumns = columns.map((m) => {
      return { ...m, width: m.label === label ? width : m.width! };
    });

    setColumns([...stateColumns]);
    FilesStore.setAdjustableTableColumns(adjustableColumns);
  };

  const pagiNation = () => {
    if (!FilesStore.showSelectedFiles && FilesStore.pagingMetaData && !!FilesStore.pagingMetaData.totalCount)
      return (
        <div className={Style.paginationWrapper}>
          <div className={Style.paginationContainer}>
            <Pagination
              cssClass={classNames({ [Style.disabled]: FilesStore.isLoading })}
              page={FilesStore.pagingMetaData.pageNumber}
              pageCount={FilesStore.pagingMetaData.pageCount}
              onChange={(page: number) => {
                FilesStore.setCurrentPage(page);
              }}
            />
            <div className={Style.paginationFlexGrow}>
              {FilesStore.pagingMetaData.firstItemOnPage + 1} - {FilesStore.pagingMetaData.lastItemOnPage + 1} of{' '}
              {FilesStore.pagingMetaData.totalCount} Files
            </div>
          </div>
        </div>
      );
    return null;
  };

  return (
    <>
      {FilesStore.isLoading && (
        <div>
          <Loader cssClass={Style.fileLoadingSpinner} size="small" label={FilesStore.loadingLabel} />
        </div>
      )}
      {
        <Table
          cssClass={FilesStore.isLoading ? Style.fileTableLoading : Style.fileTable}
          headers={headers}
          breakpoint={10}
          hoverable
          onReorderColumn={reorder}>
          {renderFileTableRow()}
        </Table>
      }

      {pagiNation()}
      {FilesStore.isLockSupersedeFileError && (
        <ErrorModal
          closeModal={() => {
            FilesStore.closeLockSupersedeFileErrorModal();
          }}
          errorMessage={FilesStore.errorMessage ?? ''}
          errorCode={FilesStore.errorCode}
        />
      )}
    </>
  );
};

export default observer(FileTable);
