import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchBox, Pill, Icon, ISuggestion } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/AddTransmittalMessageNotifySelector.module.scss';
import {
  IAppointingPartyUser,
  IDistributionList,
  IProjectNotifyList,
  IUser,
} from '../../../api/authenticated/um/getProjectMembersAndTeams';
import DistributionListTag from '../../shared/DistributionListTag';
import { listSeparator, SelectorType } from '../../../enums/TransmittalsSelectorType';
import { IOpenPillProps, IPillSelectedItem } from '../../transmittals/Types';
import CreateTransmittalStore from '../../transmittals/CreateTransmittalStore';

export interface IItemSelectorProps {
  label?: string;
  required?: boolean;
  searchPlaceholder: string;
  disabled?: boolean;
  isMultiUser?: boolean;
  selectedNotifyItems?: IProjectNotifyList;
  getUsers(text: string): IProjectNotifyList;
  openSelectPillModal(selectPillItem: IOpenPillProps | undefined): void;
  onSelectedUserUpdated(setSelectedItems: IProjectNotifyList): void;
}

const AddTransmittalMessageNotifySelector: FC<IItemSelectorProps> = ({
  label,
  required,
  searchPlaceholder,
  disabled,
  isMultiUser,
  selectedNotifyItems,
  getUsers,
  openSelectPillModal,
  onSelectedUserUpdated,
}) => {
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedItems, setSelectedItems] = useState<IPillSelectedItem[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [distributionListData, setDistributionListData] = useState<IDistributionList[]>([]);
  const [selectedDistributionList, setSelectedDistributionList] = useState<IDistributionList[]>([]);
  const [appointingPartyUsers, setAppointingPartyUsers] = useState<IAppointingPartyUser[]>([]);
  const [selectedAppointingPartyUsers, setSelectedAppointingPartyUsers] = useState<IAppointingPartyUser[]>([]);

  useEffect(() => {
    if (!selectedNotifyItems?.users) return;
    setSelectedItems([]);
    if (
      !CreateTransmittalStore.createTransmittal?.notifyToUsers.length &&
      !CreateTransmittalStore.createTransmittal?.notifyToAppointingPartyUsers.length &&
      !CreateTransmittalStore.createTransmittal?.notifyToDistributionList.length
    ) {
      setSelectedDistributionList([]);
      setSelectedAppointingPartyUsers([]);
    }
    setSelectedUsers(selectedNotifyItems?.users);
  }, [selectedNotifyItems?.users]);

  const userPill = (user: IUser): IPillSelectedItem => ({
    id: `${SelectorType.User}/${user.userId}/${user.taskTeamId}/${user.deliveryTeamId}`,
    value: (
      <span>
        <Icon type="person" /> {user.userName} <b>{user.isExternal ? 'Ext.' : user.taskTeamCode}</b>
      </span>
    ),
    selectedType: SelectorType.User,
  });

  const appointingPartyUserPill = (user: IAppointingPartyUser): IPillSelectedItem => ({
    id: `${SelectorType.AppointingPartyUser}/${user.userId}/${user.appointingPartyId}`,
    value: (
      <span>
        <Icon type="person" /> {user.userName} <b>APP</b>
      </span>
    ),
    selectedType: SelectorType.AppointingPartyUser,
  });

  const distributionListPill = (distributionList: IDistributionList): IPillSelectedItem => ({
    id: `${SelectorType.DistributionList}/${distributionList.distributionListId}`,
    value: (
      <DistributionListTag
        distributionListName={distributionList.distributionListName}
        userCount={distributionList.userCount}
      />
    ),
    selectedType: SelectorType.DistributionList,
  });

  const selectedVisibleToItems = selectedNotifyItems
    ? [
        ...(selectedNotifyItems.users?.map(userPill) || []),
        ...(selectedNotifyItems.appointingPartyUsers?.map(appointingPartyUserPill) || []),
        ...(selectedNotifyItems.distributionList?.map(distributionListPill) || []),
      ]
    : [];

  const removeSelectedItem = (item: IPillSelectedItem) => {
    if (disabled) return;

    setSelectedItems(selectedItems.filter((r) => r.id !== item.id));
    const selectedType = Number(item.id.split('/')[0]);

    if (selectedType === SelectorType.User) {
      const userId = Number(item.id.split('/')[1]);
      const updatedSelectedUsers = selectedUsers.filter((u) => u.userId !== userId);
      setSelectedUsers(updatedSelectedUsers);
      onSelectedUserUpdated({
        users: updatedSelectedUsers,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.DistributionList) {
      const listId = Number(item.id.split('/')[1]);
      const updatedSelectedDistributionList = selectedDistributionList.filter((u) => u.distributionListId !== listId);
      setSelectedDistributionList(updatedSelectedDistributionList);
      onSelectedUserUpdated({
        users: selectedUsers,
        distributionList: updatedSelectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (selectedType === SelectorType.AppointingPartyUser) {
      const listIds = item.id.split('/');
      const userId = Number(listIds[1]);
      const appointingPartyId = Number(listIds[2]);
      const updatedSelectedAppointingPartyUsers = selectedAppointingPartyUsers.filter(
        (u) => u.userId !== userId && u.appointingPartyId !== appointingPartyId
      );
      setSelectedAppointingPartyUsers(updatedSelectedAppointingPartyUsers);
      onSelectedUserUpdated({
        users: selectedUsers,
        distributionList: selectedDistributionList,
        appointingPartyUsers: updatedSelectedAppointingPartyUsers,
      });
    }
  };

  const onSelectPillItem = (item: IPillSelectedItem) => {
    if (!item) return;
    const id = Number(item.id.split('/')[1]);
    openSelectPillModal({ id: id, selectedType: item.selectedType });
  };

  const addSelectedItem = (id: string) => {
    if (disabled || listSeparator.some((x) => x.value === id)) {
      setUsers([]);
      setDistributionListData([]);
      setAppointingPartyUsers([]);
      setTriggerCleanup(triggerCleanup + 1);
      return;
    }

    const type = Number(id.split('/')[0]);

    if (type === SelectorType.User) {
      const userId = Number(id.split('/')[1]);
      const taskTeamId = Number(id.split('/')[2]);
      const deliveryTeamId = Number(id.split('/')[3]);
      const user = users.find(
        (u) => u.userId === userId && u.taskTeamId === taskTeamId && u.deliveryTeamId === deliveryTeamId
      );
      if (!user || selectedUsers.some((u) => u.userId === userId)) return;
      const updatedSelectedUsers = [...selectedUsers, user];
      setSelectedUsers(updatedSelectedUsers);

      setSelectedItems([...selectedItems, userPill(user)]);
      onSelectedUserUpdated({
        users: updatedSelectedUsers,
        distributionList: selectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.DistributionList) {
      const listId = Number(id.split('/')[1]);
      const distributionList = distributionListData.find((t) => t.distributionListId === listId);
      if (!distributionList || selectedDistributionList.some((t) => t.distributionListId === listId)) return;
      const updatedSelectedDistributionList = [...selectedDistributionList, distributionList];
      setSelectedDistributionList(updatedSelectedDistributionList);

      setSelectedItems([...selectedItems, distributionListPill(distributionList)]);
      onSelectedUserUpdated({
        users: selectedUsers,
        distributionList: updatedSelectedDistributionList,
        appointingPartyUsers: selectedAppointingPartyUsers,
      });
    } else if (type === SelectorType.AppointingPartyUser) {
      const listIds = id.split('/');
      const userId = Number(listIds[1]);
      const appointingPartyId = Number(listIds[2]);
      const user = appointingPartyUsers.find((u) => u.userId === userId && u.appointingPartyId === appointingPartyId);
      if (
        !user ||
        selectedAppointingPartyUsers.some((u) => u.userId === userId && u.appointingPartyId === appointingPartyId)
      )
        return;
      const updatedSelectedAppointingPartyUsers = [...selectedAppointingPartyUsers, user];
      setSelectedAppointingPartyUsers(updatedSelectedAppointingPartyUsers);

      setSelectedItems([...selectedItems, appointingPartyUserPill(user)]);
      onSelectedUserUpdated({
        users: selectedUsers,
        distributionList: selectedDistributionList,
        appointingPartyUsers: updatedSelectedAppointingPartyUsers,
      });
    }
    setUsers([]);
    setDistributionListData([]);
    setAppointingPartyUsers([]);
    setTriggerCleanup(triggerCleanup + 1);

    if (!isMultiUser) setDisableSearchBox(true);
  };

  const loadItems = (text: string) => {
    if (disabled) return;
    if (!text) {
      setUsers([]);
      setDistributionListData([]);
      setAppointingPartyUsers([]);
    } else {
      const items = getUsers(text);
      setUsers(items.users);
      setDistributionListData(items.distributionList);
      setAppointingPartyUsers(items.appointingPartyUsers);
    }
  };

  const filteredItems = useMemo(() => {
    let dropdownList = [] as ISuggestion[];
    const userList = users
      .filter(
        (user) =>
          !selectedUsers.find(({ userId, taskTeamId, deliveryTeamId }) => {
            return user.userId === userId && user.taskTeamId === taskTeamId && user.deliveryTeamId === deliveryTeamId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.User}/${r.userId}/${r.taskTeamId}/${r.deliveryTeamId}`,
        value: `${r.userName} (${r.userEmail})`,
        display: (
          <>
            <span>
              {r.userName} ({r.userEmail})
            </span>
            <span className={Style.userIcon}>
              <Icon type="people" /> {r.isExternal && <b>External User</b>}
              {!r.isExternal && (
                <>
                  {r.deliveryTeamTitle} | <b>{r.taskTeamTitle}</b>
                </>
              )}
            </span>
          </>
        ),
      }));
    const userSeparator = listSeparator.find((x) => x.key === SelectorType.User)?.value;
    if (userList.length && userSeparator)
      dropdownList = [
        ...dropdownList,
        { id: userSeparator, value: 'Users', display: <span className={Style.disable}>Users</span> },
        ...userList,
      ];

    const distributionList = distributionListData
      .filter(
        (list) =>
          !selectedDistributionList.some(({ distributionListId }) => {
            return list.distributionListId === distributionListId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.DistributionList}/${r.distributionListId}`,
        value: `${r.distributionListName}`,
        display: (
          <span>
            <Icon type="people" /> {r.distributionListName}
            <span className={Style.userCount}>
              <Pill background="light" size="mini" colour={2}>
                {r.userCount}
              </Pill>
            </span>
          </span>
        ),
      }));
    const distributionListSeparator = listSeparator.find((x) => x.key === SelectorType.DistributionList)?.value;

    if (distributionList.length && distributionListSeparator)
      dropdownList = [
        ...dropdownList,
        {
          id: distributionListSeparator,
          value: 'DistributionList',
          display: <span className={Style.listSeparator}>Distribution List</span>,
        },
        ...distributionList,
      ];

    const appointingPartyUserList = appointingPartyUsers
      .filter(
        (user) =>
          !selectedAppointingPartyUsers.find(({ userId, appointingPartyId }) => {
            return user.userId === userId && user.appointingPartyId === appointingPartyId;
          })
      )
      .map((r) => ({
        id: `${SelectorType.AppointingPartyUser}/${r.userId}/${r.appointingPartyId}`,
        value: `${r.userName} (${r.userEmail})`,
        display: (
          <span>
            {r.userName} ({r.userEmail})
          </span>
        ),
      }));

    const appointingPartyUserListSeparator = listSeparator.find(
      (x) => x.key === SelectorType.AppointingPartyUser
    )?.value;

    if (appointingPartyUserList.length && appointingPartyUserListSeparator)
      dropdownList = [
        ...dropdownList,
        {
          id: appointingPartyUserListSeparator ?? '',
          value: 'AppointingPartyUserList',
          display: <span className={Style.listSeparator}>Appointing Party</span>,
        },
        ...appointingPartyUserList,
      ];

    return dropdownList;
  }, [
    users,
    distributionListData,
    appointingPartyUsers,
    selectedUsers,
    selectedDistributionList,
    selectedAppointingPartyUsers,
  ]);

  return (
    <>
      {label && (
        <label className={Style.label}>
          {label}
          {required && <span className={Style.required}>*</span>}
        </label>
      )}
      <div className={Style.searchBoxContainer}>
        {(selectedVisibleToItems.length ? selectedVisibleToItems : selectedItems).map((i) => {
          if (!i) return null;
          const selectedType = Number(i.id.split('/')[0]);
          if (selectedType === SelectorType.DistributionList) {
            return (
              <Pill
                key={i.id}
                colour={1}
                onClick={() => onSelectPillItem(i)}
                onClose={() => removeSelectedItem(i)}
                cssClass={Style.userPill}>
                {i.value}
              </Pill>
            );
          }
          return (
            <Pill key={i.id} colour={1} onClose={() => removeSelectedItem(i)} cssClass={Style.userPill}>
              {i.value}
            </Pill>
          );
        })}
        <SearchBox
          placeholder={searchPlaceholder}
          hideSearchButton
          disableDefaultMatching
          suggestions={filteredItems}
          onChange={loadItems}
          onSearch={loadItems}
          onSelect={(s) => addSelectedItem(String(s.id))}
          triggerCleanup={triggerCleanup}
          disabled={disabled || disableSearchBox}
          suggestionLength={filteredItems?.length ?? 0}
          cssClass={Style.searchBox}
        />
      </div>
    </>
  );
};

export default observer(AddTransmittalMessageNotifySelector);
