import { makeAutoObservable, runInAction } from 'mobx';
import { archiveDistributionList } from '../../../api/authenticated/um/archiveDistrbutionList';
import { deleteDistributionList } from '../../../api/authenticated/um/deleteDistributionList';
import { editDistributionList } from '../../../api/authenticated/um/editDistributionList';
import { getClientTeams } from '../../../api/authenticated/um/getClientTeams';
import LayoutStore from '../../../components/layout/LayoutStore';
import TeamManagementStore from './TeamManagementStore';

export class DistributionListStore {
  constructor() {
    makeAutoObservable(this);
  }
  public loading = false;

  private async reloadTeams(): Promise<void> {
    const teams = await getClientTeams();
    runInAction(() => {
      TeamManagementStore.clientTeams = teams;
    });
    TeamManagementStore.getClientData();
  }
  public async editListName(listId: number, projectNumber: string, title: string): Promise<boolean> {
    try {
      await editDistributionList({
        listId,
        projectNumber,
        title,
      });

      await this.reloadTeams();
      LayoutStore.displayToast('success', 'Distribution List has been edited successfully.');
      return true;
    } catch {
      LayoutStore.displayToast('error', 'Distribution List cannot be edited. Please try again later.');
      return false;
    }
  }

  public async deleteDistributionList(projectNumber: string, distributionListId: number) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await deleteDistributionList({ projectNumber, distributionListId });
      await this.reloadTeams();
      LayoutStore.displayToast('success', `Distribution List has been successfully deleted.`);
      this.loading = false;
      return true;
    } catch {
      LayoutStore.displayToast('error', `Deleting distribution list was unsuccessful.`);
      this.loading = false;
      return false;
    }
  }
  public async archiveUnarchiveList(projectNumber: string, distributionListId: number, archive: boolean) {
    const archiveText = archive ? 'Unarchived' : 'Archived';
    runInAction(() => {
      this.loading = true;
    });
    try {
      await archiveDistributionList({ projectNumber, distributionListId, archive });
      await TeamManagementStore.loadDistributionListUsers(projectNumber, distributionListId);
      this.loading = false;
      await this.reloadTeams();
      LayoutStore.displayToast('success', `Distribution List has been successfully ${archiveText}.`);
    } catch {
      LayoutStore.displayToast('error', `${archiveText} distribution list was unsuccessful.`);
      this.loading = false;
    }
  }
}
export default new DistributionListStore();
