import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ComboBox, IOption } from '@aurecon-creative-technologies/styleguide';
import { useReviewStore } from './ReviewStoreContext';
import Style from './styles/Suitability.module.scss';

const Suitability: FC = () => {
  const store = useReviewStore();
  if (!store.showSuitability) return null;
  return (
    <div className={Style.container}>
      <ComboBox
        required
        label="Suitability"
        placeholder="Select Suitability"
        showIcon={false}
        selected={store.selectedSuitability?.id.toString()}
        onSelect={(item) => store.setSelectedSuitability(Number(item?.id))}
        onClear={() => store.setSelectedSuitability()}
        options={store.suitabilities.map<IOption>((s) => {
          return {
            id: s.id.toString(),
            value: `${s.code} ${s.title}`,
          };
        })}
        disabled={store.isSaving}></ComboBox>
    </div>
  );
};

export default observer(Suitability);
