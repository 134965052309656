import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ReactComponent as WithdrawnIcon } from '../../assets/taskIcons/WithdrawnIcon.svg';
import { IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import Style from './styles/TaskInformationHistoryItem.module.scss';
import { formatDate } from '../../utils/dateUtils';

export interface ITaskInformationHistoryWithdrawnProps {
  item: IHistoryItem;
}

const TaskInformationHistoryWithdrawn: FC<ITaskInformationHistoryWithdrawnProps> = ({ item }) => {
  return (
    <>
      <WithdrawnIcon />
      <div className={Style.historyItemDetail}>
        <div className={Style.historyItemDetailTitle}>
          <span>
            <strong>Task withdrawn</strong> by <strong>{item.byUser.name}</strong>
          </span>
        </div>
        <div className={Style.historyItemDetailDate}>Withdrawn date ({formatDate(item.eventDateTime)})</div>
        {item.message && <div className={Style.historyItemDetailMessage}>{item.message}</div>}
      </div>
    </>
  );
};

export default observer(TaskInformationHistoryWithdrawn);
