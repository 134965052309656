import { serverAxios } from '../api';

export interface IAddExternalUserRequest {
  projectNumber: string;
  userId: number;
  email: string;
  name: string;
}

export function addExternalUsers(request: IAddExternalUserRequest, abortSignal?: AbortSignal): Promise<void> {
  return serverAxios.post('api/um/system/externalusers/add', request, {
    signal: abortSignal,
  });
}
