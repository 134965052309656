import React, { FC, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/UpdateProjectVisualisation.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import { FormInput, Grid } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import { IUpdateProjectVisualisationModel } from '../../../api/authenticated/config/models/projectModel';
import FormControlError from '../../shared/FormControlError';
import { UpdateProjectVisualisationValidator, VisualisationFields } from './Models/updateProjectVisualisationValidator';
import Loading from '../../shared/Loading';
import ProjectVisualisationWebSceneViewer from './ProjectVisualisationWebSceneViewer';
import WebScene from '@arcgis/core/WebScene';
import Layer from '@arcgis/core/layers/Layer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

interface IUpdateProjectVisualisationProps {
  onSave: (newProjectNumber: string) => void;
  onCancel: () => void;
}

const UpdateProjectVisualisation: FC<IUpdateProjectVisualisationProps> = (props) => {
  const [formDirty, setFormDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [invalidWebSceneError, setInvalidWebSceneError] = useState('');
  const [invalidChainageItemError, setInvalidChainageItemError] = useState('');
  const [saving, setSaving] = useState(false);
  const [previewWebSceneId, setPreviewWebSceneId] = useState(
    ProjectManagementStore.projectDetails?.visualisationSettings.webSceneId ?? ''
  );
  const [previewError, setPreviewError] = useState(false);
  const [updateProjectVisualisationModel, setUpdateProjectVisualisationModel] =
    useState<IUpdateProjectVisualisationModel>({
      projectNumber: ProjectManagementStore.projectDetails?.projectNumber ?? '',
      webSceneId: ProjectManagementStore.projectDetails?.visualisationSettings.webSceneId ?? '',
      chainageItemId: ProjectManagementStore.projectDetails?.visualisationSettings.chainageItemId ?? '',
    });

  useLayoutEffect(() => {
    const validate = async () => {
      const [webSceneError, chainageItemError] = await validateWebScene(
        updateProjectVisualisationModel.webSceneId,
        updateProjectVisualisationModel.chainageItemId
      );

      setInvalidWebSceneError(webSceneError);
      setInvalidChainageItemError(updateProjectVisualisationModel.chainageItemId ? chainageItemError : '');
    };

    validate();
  }, [errors, updateProjectVisualisationModel.webSceneId, updateProjectVisualisationModel.chainageItemId]);

  const handleUpdateBtnClick = async () => {
    setSaving(true);
    await ProjectManagementStore.updateProjectVisualisation(updateProjectVisualisationModel);
    if (!ProjectManagementStore.errorMessage) props.onSave(updateProjectVisualisationModel.projectNumber);
    setSaving(false);
  };

  const disabledSave =
    !formDirty ||
    (errors && (errors[VisualisationFields.webSceneId] || errors[VisualisationFields.chainageItemId])) ||
    invalidWebSceneError ||
    invalidChainageItemError;

  const onFormInputChanged = async (fieldName: string, value) => {
    const updateProjectVisualisation = { ...updateProjectVisualisationModel, [fieldName]: value };
    const errorMessage = validateForm(updateProjectVisualisation);
    setErrors({ ...errorMessage });
    setUpdateProjectVisualisationModel(updateProjectVisualisation);
    setFormDirty(true);
  };

  const validateWebScene = async (webSceneId: string, chainageItemId: string) => {
    let chainageItemError = '';

    if (!webSceneId && chainageItemId) {
      chainageItemError = 'Invalid Chainage Item ID';
    }

    if (webSceneId) {
      const featureLayers: Layer[] = [];
      const map = new WebScene({
        portalItem: {
          id: webSceneId,
        },
      });

      try {
        await map.load();
        map.layers.forEach((l) => {
          if (l.type && l.type === 'feature') featureLayers.push(l);
        });

        if (chainageItemId) {
          const chainageItem = featureLayers.find((l) => (l as FeatureLayer).portalItem.id === chainageItemId);
          if (!chainageItem) {
            chainageItemError = 'Invalid Chainage Item ID';
          }
        }
      } catch {
        return ['Invalid Web Scene ID', 'Invalid Chainage Item ID'];
      }
    }
    return ['', chainageItemError];
  };

  const validateForm = (updateProject: IUpdateProjectVisualisationModel) => {
    const errorMessage = { ...errors };
    Object.keys(UpdateProjectVisualisationValidator).forEach(
      (key) => (errorMessage[key] = UpdateProjectVisualisationValidator[key](updateProject[key]))
    );
    return errorMessage;
  };

  const handlePreview = () => {
    if (invalidWebSceneError) {
      setPreviewError(true);
    } else {
      setPreviewError(false);
    }

    setPreviewWebSceneId(updateProjectVisualisationModel.webSceneId);
  };

  const renderWebScene = () => {
    if (!previewWebSceneId || previewError)
      return (
        <div className={Style.notConfiguredPanel}>
          <p>Map data not configured</p>
        </div>
      );

    return <ProjectVisualisationWebSceneViewer webSceneId={previewWebSceneId} />;
  };

  if (saving) return <Loading isLoading={saving} />;

  return (
    <>
      {ProjectManagementStore.projectDetails && (
        <div className={Style.fromWrapper}>
          <Grid row={true} md={12}>
            <Grid item md={12}>
              <div className={Style.header}>
                <span>Visualisation Configurations</span>
                <div className={Style.updateActions}>
                  <SecondaryButton onClick={() => props.onCancel()}>Cancel</SecondaryButton>
                  <PrimaryButton
                    disabled={disabledSave}
                    loading={saving}
                    onClick={() => {
                      void (async () => {
                        await handleUpdateBtnClick();
                      })();
                    }}>
                    Update
                  </PrimaryButton>
                </div>
              </div>
            </Grid>
            <Grid item md={12}>
              <p className={Style.settingNoticed}>
                To load a WebScene below, you need to simply enter the WebScene ID to set the project scene.
              </p>
            </Grid>

            <Grid item gap={8}>
              <Grid item md={12}>
                <div className={Style.webSceneWrapper}>
                  <FormInput
                    label="Web Scene ID"
                    placeholder="Enter Web Scene ID..."
                    required
                    disabled={saving}
                    value={updateProjectVisualisationModel.webSceneId || ''}
                    onChange={(value) => onFormInputChanged(VisualisationFields.webSceneId, value)}
                  />
                  <SecondaryButton onClick={() => handlePreview()}>Preview</SecondaryButton>
                </div>

                <FormControlError
                  message={errors[VisualisationFields.webSceneId] || invalidWebSceneError}
                  formDirty={formDirty}
                  showIcon={true}
                />
              </Grid>
              <Grid item md={12}>
                {renderWebScene()}
              </Grid>
              <Grid item md={12}>
                <FormInput
                  label="Chainage Item ID"
                  placeholder="Enter Chainage Item ID..."
                  disabled={saving}
                  value={updateProjectVisualisationModel.chainageItemId || ''}
                  onChange={(value) => onFormInputChanged(VisualisationFields.chainageItemId, value)}
                />
                <FormControlError
                  message={errors[VisualisationFields.chainageItemId] || invalidChainageItemError}
                  formDirty={formDirty}
                  showIcon={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default observer(UpdateProjectVisualisation);
