import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FormControlError.module.scss';
import { classNames } from '../../utils/miscUtils';
import Icon from './Icon';

export interface IFormControlErrorProps {
  message: string;
  formDirty: boolean;
  className?: string;
  showIcon?: boolean;
}

const FormControlError: FC<IFormControlErrorProps> = (props) => {
  const { message, formDirty, showIcon, className } = props;
  if (!message?.length || !formDirty) return null;

  return (
    <div className={classNames(Style.errorMessage, className)}>
      {showIcon && <Icon name="error" className={Style.errorIcon} />}
      {message}
    </div>
  );
};

export default observer(FormControlError);
