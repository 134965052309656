import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import DistanceMeasurement2D from '@arcgis/core/widgets/DistanceMeasurement2D';
import DirectLineMeasurement3D from '@arcgis/core/widgets/DirectLineMeasurement3D';
import VisualisationStore from '../VisualisationStore';

import WidgetBase, { IWidgetBase } from './WidgetBase';

import Style from './styles/WidgetBase.module.scss';
import MapView from '@arcgis/core/views/MapView';
import SceneView from '@arcgis/core/views/SceneView';

const MeasurementLineWidget: FC = () => {
  return VisualisationStore.mapView.type === '2d'
    ? WidgetBase(
        (properties: Partial<IWidgetBase>) =>
          new DistanceMeasurement2D({
            visible: true,
            unit: 'meters',
            container: properties.container,
            view: properties.view as MapView,
          }),
        Style.measurementWidgetWrapper
      )
    : WidgetBase(
        (properties: Partial<IWidgetBase>) =>
          new DirectLineMeasurement3D({
            visible: true,
            unit: 'meters',
            container: properties.container,
            view: properties.view as SceneView,
          }),
        Style.measurementWidgetWrapper
      );
};

export default observer(MeasurementLineWidget);
