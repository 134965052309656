import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from '../../../../styles/components/settings/systemAdminSettings/systemSettings/GeneralSettings.module.scss';
import _ from 'lodash';
import { updateConfig } from '../../../../api/authenticated/config/updateConfig';
import { SystemConfigs } from '../../../../common/constants/SystemConfigs';
import SystemSettingsStore from '../../../../stores/settings/systemAdminSettings/systemSettings/SystemSettingsStore';
import AppStore from '../../../../stores/AppStore';
import TransmittalsSettings from './TransmittalSettings';
import UsersSettings from './UsersSettings';
import SplashPageSettings from './SplashPageSettings';
import LayoutStore from '../../../layout/LayoutStore';
import { getSplashPageContent } from '../../../../api/authenticated/config/getSplashPageContent';
import FileTemplatesSettings from './FileTemplatesSettings';
import ErrorModal from '../../../shared/ErrorModal';
import { getAxiosError } from '../../../../utils/errorUtils';
import { AxiosError } from 'axios';
import { IResponseError } from '../../../../api/authenticated/config/models/projectModel';

const GeneralSettings: FC = () => {
  const [settings, setSettings] = useState<{
    enableSupportingFiles: boolean;
    allowEnableDisableProjectSupportingFiles: boolean;
    canSyncAADUser: boolean;
    enableSplashPage: boolean;
  }>({
    enableSupportingFiles: AppStore.clientEnableSupportingFiles ?? false,
    allowEnableDisableProjectSupportingFiles: AppStore.clientAllowEnableDisableProjectSupportingFiles ?? false,
    canSyncAADUser: AppStore.clientCanSyncAADUser ?? false,
    enableSplashPage: AppStore.clientEnableSplashPage ?? false,
  });
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [splashPageContent, setSplashPageContent] = useState<string>('');
  const [splashPageContentChanged, setSplashPageContentChanged] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState<{ errorCode: number; errorMessage: string } | null>(null);

  useEffect(() => {
    const fetchSplashPageContent = async () => {
      const content = await getSplashPageContent();
      setSplashPageContent(content);
    };

    SystemSettingsStore.setShowLoading(true, 'Saving settings');
    fetchSplashPageContent();
    SystemSettingsStore.setShowLoading(false);
  }, []);

  const [toastMessages, setToastMessages] = useState<{
    splashPage: string;
  } | null>(null);

  const onSettingsChange = (fieldName, value) => {
    const setting = _.clone(settings);
    setting[fieldName] = value;
    setSettings(setting);
  };

  const onSplashPageContentChanged = (value: string) => {
    setSplashPageContent(value);
    setSplashPageContentChanged(true);
  };

  const handleSave = async () => {
    setDisableSave(true);
    setError(null);
    SystemSettingsStore.setShowLoading(true, 'Saving settings');
    try {
      await updateConfig({
        configs: [
          {
            key: SystemConfigs.EnableSupportingFiles,
            value: settings.enableSupportingFiles.toString(),
          },
          {
            key: SystemConfigs.AllowEnableDisableProjectSupportingFiles,
            value: settings.allowEnableDisableProjectSupportingFiles.toString(),
          },
          {
            key: SystemConfigs.EnableSplashPage,
            value: settings.enableSplashPage.toString(),
          },
        ],
        splashPageContent,
      });
      if (settings.enableSplashPage !== AppStore.clientEnableSplashPage) {
        LayoutStore.displayToast(
          'success',
          settings.enableSplashPage ? 'The Splash has been turned on' : 'The Splash has been turned off'
        );
      }
      await AppStore.forceRefresh();
      setSplashPageContentChanged(false);
    } catch (err) {
      setError(getAxiosError(err as AxiosError<IResponseError>));
      setShowErrorModal(true);
    } finally {
      setDisableSave(false);
      SystemSettingsStore.setShowLoading(false);
    }
  };

  const checkDisableSave = () => {
    const enableSupportingFiles = AppStore.clientEnableSupportingFiles;
    const allowEnableDisableProjectSupportingFiles = AppStore.clientAllowEnableDisableProjectSupportingFiles;
    const enableSplashPage = AppStore.clientEnableSplashPage;
    return (
      disableSave ||
      (enableSupportingFiles === settings.enableSupportingFiles &&
        allowEnableDisableProjectSupportingFiles === settings.allowEnableDisableProjectSupportingFiles &&
        enableSplashPage === settings.enableSplashPage &&
        !splashPageContentChanged)
    );
  };

  return (
    <div className={Styles.pageWrapper}>
      <TransmittalsSettings
        defaultValue={{
          enableSupportingFiles: settings.enableSupportingFiles,
          allowEnableDisableProjectSupportingFiles: settings.allowEnableDisableProjectSupportingFiles,
        }}
        onSettingsChanged={onSettingsChange}
      />
      <div className={Styles.divider} />
      <UsersSettings defaultValue={settings.canSyncAADUser} />
      <div className={Styles.divider} />
      <SplashPageSettings
        defaultValue={settings.enableSplashPage}
        splashPageContent={splashPageContent}
        saveDisabled={checkDisableSave()}
        onSettingsChanged={(value) => {
          settings.enableSplashPage !== AppStore.clientEnableSplashPage
            ? setToastMessages({
                ...toastMessages,
                splashPage: settings.enableSplashPage
                  ? 'The Splash has been turned on'
                  : 'The Splash has been turned off',
              })
            : setToastMessages({
                ...toastMessages,
                splashPage: '',
              });
          onSettingsChange(SystemConfigs.EnableSplashPage, value);
        }}
        onSplashPageContentChanged={onSplashPageContentChanged}
        onSaveClick={handleSave}
      />

      <div className={Styles.divider} />
      <FileTemplatesSettings />
      <div className={Styles.divider} />
      {showErrorModal && error && (
        <ErrorModal
          closeModal={() => {
            setShowErrorModal(false);
            setError(null);
          }}
          errorCode={error?.errorCode}
          errorMessage={error?.errorMessage}
        />
      )}
    </div>
  );
};

export default observer(GeneralSettings);
