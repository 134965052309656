export const FileExt = {
  PDF: 'pdf',
  DOC: 'doc',
  DOCX: 'docx',
  XLSX: 'xlsx',
  XLSM: 'xlsm',
  CSV: 'csv',
  PPT: 'ppt',
  PPTX: 'pptx',
  JPEG: 'jpeg',
  PNG: 'png',
  JPG: 'jpg',
  GIF: 'gif',
  SVG: 'svg',
  WEBP: 'webp',
  BMP: 'bmp',
};
