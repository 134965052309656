import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/CreateTransmittalErrorModal.module.scss';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import Modal from '../shared/Modal';
import ModalActions from '../shared/ModalActions';
import PrimaryButton from '../shared/PrimaryButton';
import { ErrorStatusText } from '../../common/constants/ErrorStatusText';

interface IErrorModalProps {
  errorCode?: number;
  errorMessage?: string;
  closeModal: () => void;
}

const CreateTransmittalErrorModal: FC<IErrorModalProps> = (props) => {
  const { errorCode, errorMessage, closeModal } = props;
  const getErrorHeader = () => {
    if (errorCode) {
      return ErrorStatusText[errorCode];
    }
    return 'Something went wrong';
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>{getErrorHeader()}</h1>
        {!errorMessage && <div>Transmittal cannot be created. Please try again later.</div>}
        {errorMessage && <div>{errorMessage}</div>}
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(CreateTransmittalErrorModal);
