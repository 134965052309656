import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DateFilterOperator, IDateRangeFilter } from '../../common/interfaces/TableColumn';
import { DateInput, Dropdown, IDateInputDates, IDropdownItemProps } from '@aurecon-creative-technologies/styleguide';
import { dateOnly, getDateFormatPattern } from '../../utils/dateUtils';
import Style from './styles/DateFilter.module.scss';
import { DropdownStyle } from '../../common/constants/Dropdown';
import classNames from 'classnames';

export interface IDateFilterProps {
  dateFilter: IDateRangeFilter;
  disabled?: boolean;
  onChange?: (value: IDateRangeFilter) => void;
  size?: 'extra small' | 'small' | 'medium' | 'large' | 'extra large';
  className?: string;
  datePickerClassName?: string;
}

const DateFilter: FC<IDateFilterProps> = ({
  dateFilter,
  disabled,
  onChange,
  size = 'extra small',
  className,
  datePickerClassName,
}) => {
  const [rangeType, setRangeType] = useState<boolean>(dateFilter.operator === DateFilterOperator.BETWEEN);
  const [value, setValue] = useState<IDateRangeFilter>({
    operator: dateFilter.operator ?? DateFilterOperator.EQUAL_TO,
    endDate: null,
    startDate: null,
  });
  const [selectedOption, setSelectedOption] = useState<string | number>(value.operator ?? DateFilterOperator.EQUAL_TO);

  const options: IDropdownItemProps[] = [
    { id: DateFilterOperator.EQUAL_TO, label: 'On' },
    { id: DateFilterOperator.LESS_THAN, label: 'Before' },
    { id: DateFilterOperator.GREATER_THAN, label: 'After' },
    { id: DateFilterOperator.BETWEEN, label: 'Between' },
  ];

  useEffect(() => {
    if (dateFilter)
      setValue({
        operator: dateFilter.operator,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
      });
  }, [dateFilter]);

  const onDateRangeChangeHandler = (val: IDateInputDates) => {
    const newValue = { ...value, startDate: val.startDate, endDate: val.endDate };
    setValue(newValue);
    onChange?.(newValue);
  };

  const handleOnSelectItem = (id: string | number): void => {
    setSelectedOption(id);
    setRangeType(id === DateFilterOperator.BETWEEN);
    const newValue = {
      ...value,
      startDate: value.startDate ? dateOnly(value.startDate) : null,
      endDate: value.endDate ? dateOnly(value.endDate) : null,
      operator: id as number,
    };
    setValue(newValue);
    onChange?.(newValue);
  };

  return (
    <div className={classNames(Style.wrapper, className)}>
      <Dropdown
        cssClass={Style.dropdown}
        placeholder="-"
        size={size}
        disabled={disabled}
        items={options}
        selectedItem={selectedOption}
        onSelectItem={handleOnSelectItem}
        optionsHeight={DropdownStyle.defaultHeight}
      />
      <DateInput
        cssClass={classNames(Style.datePicker, datePickerClassName)}
        dates={dateFilter}
        onDateChange={onDateRangeChangeHandler}
        rangeType={rangeType}
        disabled={disabled}
        format={getDateFormatPattern()}
      />
    </div>
  );
};

export default observer(DateFilter);
