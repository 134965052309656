import { makeAutoObservable, runInAction } from 'mobx';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import { IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import { IMetadataField, getProjectMetadata } from '../../../api/authenticated/cms/getProjectMetadata';
import { getProjectFileThumbnailUrl } from '../../../api/authenticated/cms/getProjectFileThumbnailUrl';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { orderBy } from 'lodash';
import { getFileHistory, IHistoryItem, IReleasedHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import { getProjectFileDownloadUrl } from '../../../api/authenticated/cms/getProjectFileDownloadUrl';
import { getProjectFileDocumentViewerUrl } from '../../../api/authenticated/cms/getProjectFileViewerUrl';
import { AxiosError } from 'axios';
import AppStore from '../../../stores/AppStore';
import FilesStore from '../FilesStore';
import { sort } from '../../../utils/sortHelper';
import { SortTypes } from '../../../common/enums/SortType';
import { DeliveryTeamAccessPermissions } from '../../../common/enums/DeliveryTeamSharePermission';
import { getSharedAndPublishedDeliveryTeamAccess } from '../../../api/authenticated/um/getSharedAndPublishedDeliveryTeamAccess';
import { updateNativeContainerFile } from '../../../api/authenticated/cms/updateNativeContainerFile';
import {
  IFileTemporaryAccessDetails,
  getFileContainerTemporaryAccessDetails,
} from '../../../api/authenticated/cms/getFileContainerTemporaryAccessDetails';
import { MetaDataFieldTypeEnum } from '../../../common/enums/MetadataFieldType';
import { FileExt } from '../../../common/constants/FileExt';
import getFileExtension from '../../../utils/fileUtils';

export class FileInformationStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public abortController?: AbortController;
  public file: IFileContainer | null = null;
  public metadataFields: IMetadataField[] = [];
  public apimsMetadataFields: IMetadataField[] = [];
  public fileContainerStateId: FileContainerState | null = null;
  public isLoadingThumbnail = false;
  public thumbnail: string | null = null;
  public isLoadingHistory = false;
  public historyItems: IHistoryItem[] = [];
  public busyReleasedHistoryItem: { [id: number]: boolean } = {};
  public urn = '';
  public token = '';
  public projectNumber = '';
  public transmittalId: number | null = null;
  public transmittalMessageId: number | null = null;
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public accessibleDeliveryTeams: {
    accessibleDeliveryTeamId: number | null;
    accessibleDeliveryTeamTitle: string | null;
  }[] = [];
  public fileContainerTemporaryAccessUsers: string[] = [];
  public fileContainerTemporaryAccessDetails: IFileTemporaryAccessDetails[] = [];
  public filePreviewAvailable = false;
  public previewImageTypes = [
    FileExt.JPEG,
    FileExt.PNG,
    FileExt.JPG,
    FileExt.BMP,
    FileExt.SVG,
    FileExt.GIF,
    FileExt.WEBP,
  ];

  public async init(
    file: IFileContainer,
    fileContainerStateId: FileContainerState,
    projectNumber: string | null,
    transmittalId: number | null,
    transmittalMessageId: number | null
  ) {
    this.abortController?.abort();

    const prjNumber =
      projectNumber ?? NavBarSelectorStore.selectedItem?.project?.projectNumber ?? AppStore.projectNumber ?? '';
    const fileMetaDataFields = await getProjectMetadata(prjNumber);
    runInAction(() => {
      this.abortController = new AbortController();
      this.file = file;
      if (this.file.containerFileId === 0) {
        this.file.containerFileId = this.getFirstContainerFileId(file);
      }
      this.metadataFields = fileMetaDataFields.filter(
        (x) => x.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField
      );
      this.apimsMetadataFields = fileMetaDataFields.filter(
        (x) => x.metaDataTypeId === MetaDataFieldTypeEnum.APIMSMetaDataField
      );
      this.fileContainerStateId = fileContainerStateId;
      this.isLoadingThumbnail = false;
      this.filePreviewAvailable = false;
      this.thumbnail = null;
      this.isLoadingHistory = false;
      this.historyItems = [];
      this.busyReleasedHistoryItem = {};
      this.projectNumber = prjNumber;
      this.transmittalId = transmittalId ?? null;
      this.transmittalMessageId = transmittalMessageId ?? null;
      this.accessibleDeliveryTeams = [];
      this.fileContainerTemporaryAccessUsers = [];
      this.fileContainerTemporaryAccessDetails = [];
    });

    await this.loadFileThumbnail();
    await this.loadFileHistory();
    await this.fetchFileContainerTemporaryAccessDetails();
    await this.getAccessibleDeliveryTeams();
  }

  private getFirstContainerFileId = (file: IFileContainer) => {
    if (!file.containerFiles?.length) return 0;

    const nativeFile = file.containerFiles?.find((x) => x.native);
    if (nativeFile) return nativeFile.containerFileId as number;

    return file.containerFiles[0].containerFileId as number;
  };

  public close() {
    this.abortController?.abort();

    runInAction(() => {
      this.file = null;
    });
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public async loadFileThumbnail() {
    if (!this.file) return;
    const fileExtention = getFileExtension(this.file.originalFilename)?.toLocaleLowerCase();
    const isImage = this.previewImageTypes.some((item) => item === fileExtention);
    if (!(this.file?.inSharePoint || isImage)) return;

    runInAction(() => {
      this.isLoadingThumbnail = true;
      this.filePreviewAvailable = true;
    });

    const abortController = this.abortController;
    this.clearError();
    try {
      if (this.file.inSharePoint) {
        const imageBase64 = await getProjectFileThumbnailUrl(
          this.projectNumber,
          this.file.id,
          this.file.fileRevisionId,
          this.file.containerFileId,
          this.file.fileContainerStateId === FileContainerState.Wip && this.file.inSharePoint
            ? null
            : this.file.releasedFileId,
          abortController?.signal
        );
        if (imageBase64 != null) {
          runInAction(() => {
            this.thumbnail = 'data:image/png;base64,' + imageBase64;
          });
        }
      }
      if (isImage) {
        const response = await getProjectFileDownloadUrl(
          {
            projectNumber: this.projectNumber
              ? this.projectNumber
              : NavBarSelectorStore.selectedItem!.project.projectNumber,
            transmittalId: null,
            transmittalMessageId: null,
            downloadFiles: [
              {
                fileContainerId: this.file.id,
                fileRevisionId: this.file.fileRevisionId,
                containerFileId: this.file.containerFileId,
                releasedFileId: this.file.releasedFileId,
              },
            ],
          },
          abortController?.signal
        );

        if (response[0]?.url && !response[0].errorMessage) {
          this.thumbnail = response[0].url;
          if (fileExtention === FileExt.SVG) {
            this.imageUrlToBase64(response[0].url).then((dataUrl) => {
              this.thumbnail = 'data:image/svg+xml;base64,' + dataUrl;
            });
          }
        }
      }
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      if (!abortController?.signal.aborted) {
        runInAction(() => {
          this.isLoadingThumbnail = false;
        });
      }
    }
  }
  public async imageUrlToBase64(url) {
    const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64String = getBase64StringFromDataURL(reader.result);
        resolve(base64String);
      };
      reader.onerror = reject;
    });
  }
  public async loadFileHistory() {
    if (!this.file || !this.fileContainerStateId || AppStore.isProjectExternalUser) return;

    runInAction(() => {
      this.isLoadingHistory = true;
    });

    const abortController = this.abortController;
    this.clearError();
    try {
      let historyItems: IHistoryItem[] = [];
      historyItems = await getFileHistory(
        this.projectNumber,
        this.file.id,
        this.file.fileContainerStateId ?? this.fileContainerStateId,
        this.file.releasedFileId,
        this.transmittalId,
        this.transmittalMessageId,
        abortController?.signal
      );

      historyItems = orderBy(historyItems, (item) => item.eventDateTime, 'desc');

      runInAction(() => {
        this.historyItems = historyItems;
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      if (!abortController?.signal.aborted) {
        runInAction(() => {
          this.isLoadingHistory = false;
        });
      }
    }
  }

  public async download(releasedHistoryItems: IReleasedHistoryItem[]) {
    releasedHistoryItems.forEach((file) => {
      if (this.busyReleasedHistoryItem[file.releasedFileContainerId]) return;
      runInAction(() => {
        this.busyReleasedHistoryItem[file.releasedFileContainerId] = true;
      });
    });

    const downloadFiles = releasedHistoryItems.map((file) => ({
      fileContainerId: file.fileContainerId,
      containerFileId: file.containerFileId,
      fileRevisionId: file.fileContainerRevisionId,
      releasedFileId: file.releasedFileContainerId,
    }));

    const abortController = this.abortController;
    this.clearError();
    try {
      const response = await getProjectFileDownloadUrl(
        {
          projectNumber: this.projectNumber
            ? this.projectNumber
            : NavBarSelectorStore.selectedItem!.project.projectNumber,
          transmittalId: null,
          transmittalMessageId: null,
          downloadFiles: downloadFiles,
        },
        abortController?.signal
      );
      if (response)
        response.forEach((res) => {
          if (res.url && !res.errorMessage) window.open(res.url);
        });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      if (!abortController?.signal.aborted) {
        releasedHistoryItems.forEach((file) => {
          runInAction(() => {
            this.busyReleasedHistoryItem[file.releasedFileContainerId] = false;
          });
        });
      }
    }
  }

  public get filesSize(): number {
    if (!this.file) return 0;
    return this.file.uploadedSize;
  }

  public async updateNativeFile(
    projectNumber: string,
    taskTeamId: number,
    fileContainerRevisionId: number,
    containerFileId: number
  ) {
    await updateNativeContainerFile({
      taskTeamId,
      fileContainerRevisionId,
      containerFileId,
    });
    await FilesStore.loadFiles();

    if (this.file === null) return;
    const refreshFileContainer = FilesStore.files.find((m) => m.id === this.file?.id);
    if (refreshFileContainer === undefined) return;

    FilesStore.showFileInformation(refreshFileContainer, projectNumber);
  }

  public async openFile(releasedHistoryItem: IReleasedHistoryItem) {
    if (this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileContainerId]) return;

    runInAction(() => {
      this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileContainerId] = true;
    });

    const abortController = this.abortController;
    this.clearError();
    try {
      const url = await getProjectFileDocumentViewerUrl(
        {
          projectNumber: this.projectNumber
            ? this.projectNumber
            : NavBarSelectorStore.selectedItem!.project.projectNumber,
          fileContainerId: null,
          fileContainerRevisionId: null,
          fileId: releasedHistoryItem.containerFileId ?? null,
          releasedFileContainerId: releasedHistoryItem.releasedFileContainerId,
          sharePointReleasedFileId: null,
          transmittalId: null,
          transmittalMessageId: null,
        },
        abortController?.signal
      );
      if (url) window.open(url);
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      if (!abortController?.signal.aborted) {
        runInAction(() => {
          this.busyReleasedHistoryItem[releasedHistoryItem.releasedFileContainerId] = false;
        });
      }
    }
  }
  public async getAccessibleDeliveryTeams() {
    if (!this.projectNumber || !this.file) return;
    if (
      this.projectNumber &&
      this.file.deliveryTeamId &&
      (this.file.fileContainerStateId == FileContainerState.Shared ||
        this.file.fileContainerStateId === FileContainerState.Published)
    ) {
      const accessibleDeliveryTeamsList = await getSharedAndPublishedDeliveryTeamAccess(
        this.projectNumber,
        this.file.deliveryTeamId
      );
      const deliveryTeamAccessPermission = this.getDeliveryTeamAccessPermissionType(this.file.fileContainerStateId);
      if (deliveryTeamAccessPermission === DeliveryTeamAccessPermissions.CAN_ACCESS_SHARED_FILES) {
        this.accessibleDeliveryTeams = accessibleDeliveryTeamsList.sharedDeliveryTeams.map((x) => {
          return {
            accessibleDeliveryTeamId: x.deliveryTeamId,
            accessibleDeliveryTeamTitle: x.deliveryTeamTitle,
          };
        });
      }
      if (deliveryTeamAccessPermission === DeliveryTeamAccessPermissions.CAN_ACCESS_PUBLISHED_FILE) {
        this.accessibleDeliveryTeams = accessibleDeliveryTeamsList.publishedDeliveryTeams.map((x) => {
          return {
            accessibleDeliveryTeamId: x.deliveryTeamId,
            accessibleDeliveryTeamTitle: x.deliveryTeamTitle,
          };
        });
      }
      runInAction(() => {
        this.accessibleDeliveryTeams = this.accessibleDeliveryTeams
          ? sort(this.accessibleDeliveryTeams, 'accessibleDeliveryTeamTitle', SortTypes.ASC)
          : [];
      });
    }
  }
  public getDeliveryTeamAccessPermissionType(fileContainerStateId: FileContainerState) {
    switch (fileContainerStateId) {
      case FileContainerState.Shared:
        return DeliveryTeamAccessPermissions.CAN_ACCESS_SHARED_FILES;
      case FileContainerState.Published:
        return DeliveryTeamAccessPermissions.CAN_ACCESS_PUBLISHED_FILE;
      default:
        return;
    }
  }

  public get getFileContainerTemporaryAccessUsers() {
    return this.fileContainerTemporaryAccessUsers;
  }

  public async fetchFileContainerTemporaryAccessDetails() {
    if (!this.file?.fileContainerStateId) return;
    const temporaryAccessDetails = await getFileContainerTemporaryAccessDetails(
      this.projectNumber,
      this.file.id,
      this.file.fileContainerStateId,
      this.file.releasedFileId,
      this.transmittalId,
      this.transmittalMessageId
    );

    runInAction(() => {
      this.fileContainerTemporaryAccessDetails = temporaryAccessDetails;
      this.fileContainerTemporaryAccessUsers = temporaryAccessDetails
        .map((x) => x.name)
        .sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
    });
  }
}

export default new FileInformationStore();
