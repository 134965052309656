import { serverAxios } from '../api';
import { IAppointingParty, IDeliveryTeam, ITaskTeam, IDistributionList } from '../um/getProjectMembersAndTeams';

export interface ICreateTransmittal {
  projectNumber: string;
  type: number;
  reason: number;
  message: string;
  subject: string;
  dueDate: Date | null;
  initiatorTaskTeamId: number | null;
  notifyToUsers: IUserDetail[];
  notifyToAppointingPartyUsers: IAppointingPartyUserDetail[];
  notifyToDistributionList: IDistributionList[] | number[];
  visibleToTaskTeamUsers: IUserDetail[];
  visibleToAppointingPartyUsers: IAppointingPartyUserDetail[];
  visibleToTaskTeams: ITaskTeam[] | number[];
  visibleToDeliveryTeams: IDeliveryTeam[] | number[];
  visibleToAppointingParties: IAppointingParty[] | number[];
  visibleToDistributionList: IDistributionList[] | number[];
  contentFileIds?: IAddTransmittalFileContainer[] | number[];
  draft: boolean;
  transmittalId?: number;
  transmittalMessageId?: number;
  transmittalTitle?: string;
}

export interface IUserDetail {
  userId: number;
  name: string;
  email: string;
  taskTeamId: number;
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  taskTeamTitle: string;
  taskTeamCode: string;
  isExternal: boolean;
}

export interface IAppointingPartyUserDetail {
  userId: number;
  appointingPartyId: number;
  name: string;
  email: string;
}
export interface IAddTransmittalFileContainer {
  releasedFileContainerId: null | number;
  containerFileId: null | number;
}

export interface ICreateTransmittalResult {
  transmittalTitle: string;
  transmittalId: number;
  transmittalMessageId: number;
}

export async function createTransmittal(
  request: ICreateTransmittal,
  abortSignal?: AbortSignal
): Promise<ICreateTransmittalResult> {
  const result = await serverAxios.post<ICreateTransmittalResult>(`api/transmittals/createTransmittal`, request, {
    signal: abortSignal,
  });
  return result.data;
}
