import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Footer from './Footer';
import NavBar from './NavBar';
import Sidebar from './Sidebar';
import { classNames } from '../../utils/miscUtils';
import { useToast } from '@aurecon-creative-technologies/styleguide';

import LayoutStore from './LayoutStore';
import AppStore from '../../stores/AppStore';

import Style from './styles/Layout.module.scss';
import { Outlet } from 'react-router';
import { Pages } from '../../common/constants/Pages';
import { useLocation } from 'react-router-dom';

const Layout: FC = () => {
  const { addToast } = useToast();
  const location = useLocation();
  const projectNumber = AppStore.projectNumber;

  useEffect(() => {
    if (!projectNumber) return;
    let setUrl: string | undefined = '';
    if (
      AppStore.isProjectExternalUser &&
      !location.pathname.includes(Pages.Transmittals.Route) &&
      !location.pathname.includes(Pages.CookiePolicy.Route) &&
      !location.pathname.includes(Pages.PrivacyPolicy.Route) &&
      !location.pathname.includes(Pages.TermsConditions.Route)
    ) {
      setUrl = Pages.Transmittals.Route;
    }
    const disabledLocationPath = AppStore.getLocationPathState(location.pathname);
    if (disabledLocationPath) {
      const nextRouteState = AppStore.getNextRouteState();
      setUrl = nextRouteState;
    }

    if (setUrl && !location.pathname.includes(Pages.Apps.Route)) {
      window.location.href = `#${setUrl}`;
    }
  }, [location.pathname, projectNumber]);

  LayoutStore.setAddToast(addToast);
  const hideSideBar = AppStore.hasClientInfoError || !AppStore.projects.length;

  return (
    <div className={classNames(Style.layout, [LayoutStore.showSidebar, Style.sidebarOpen])}>
      <NavBar className={Style.navbar} onClick={() => !hideSideBar && LayoutStore.toggleSidebar()} />
      {!hideSideBar && <Sidebar className={Style.sidebar} />}
      <main className={Style.pageContent}>
        <Outlet></Outlet>
      </main>
      <Footer className={Style.footer} />
    </div>
  );
};

export default observer(Layout);
