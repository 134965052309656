export enum SystemUsersTabIds {
  ACTIVE,
  DEACTIVE,
}
export const SystemUsersTabLabels = {
  ACTIVE: 'Active Users',
  DEACTIVE: 'Deactivated Users',
};

export default { SystemUsersTabIds, SystemUsersTabLabels };
