import { serverAxios } from '../api';

export const closeTransmittal = async (
  projectNumber: string | null,
  transmittalId: number,
  abortSignal?: AbortSignal
): Promise<void> =>
  serverAxios.put(`api/transmittals/close/${projectNumber}/${transmittalId}`, {
    signal: abortSignal,
  });
