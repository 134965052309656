import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/Modal.module.scss';

interface IModalProps {
  className?: string;
  children?: React.ReactNode;
}

const Modal: FC<IModalProps> = ({ className, children }) => {
  return <div className={classNames(Style.modal, className)}>{children}</div>;
};

export default observer(Modal);
