import './styles/App.scss';
import './styles/StyleGuideOverrides.scss';
import './styles/EsriOverrides.scss';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader, Container } from '@aurecon-creative-technologies/styleguide';
import AppRouting from './AppRouting';
import { getAppInsightsInstance } from './utils/appInsights';
import AppStore from './stores/AppStore';

import config from './config';
import packageJson from '../package.json';
import { getAppVersion } from './api/unauthenticated/config/getAppVersion';
import { useAuth } from './authentication/hooks/useAuth';

const App: FC = () => {
  const { isInProgress, isAuthenticated, account } = useAuth();

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const appVersion = await getAppVersion();
      if (appVersion && appVersion.version !== packageJson.version) {
        window.location.reload();
      }
    }, config.versionTimeInterval);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isAuthenticated && account) {
      getAppInsightsInstance().setAuthenticatedUserContext(account.userId ?? '', undefined, true);
      AppStore.init();
    }
  }, [isAuthenticated, account]);

  if (isInProgress || (isAuthenticated && !AppStore.hasInit && !AppStore.hasClientInfoError))
    return (
      <Container cssClass="app-container" fluid>
        <Loader label="Loading..." />
      </Container>
    );

  return <AppRouting isAuthenticated={isAuthenticated} />;
};

export default observer(App);
