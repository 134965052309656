import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IUnlockFileRequest {
  taskTeamId: number;
  fileContainerId: number;
  fileContainerRevisionId: number;
  fileContainerStateId: FileContainerState;
}

export async function unlockFile(request: IUnlockFileRequest, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.post('api/cms/unlockfile', request, {
    signal: abortSignal,
  });
  return result.data;
}
