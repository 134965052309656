import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, TableRow, TableCell } from '@aurecon-creative-technologies/styleguide';
import { useReviewStore } from './ReviewStoreContext';
import FilesStore from '../FilesStore';
import Icon from '../../shared/Icon';
import Style from './styles/FileList.module.scss';
import { IFileItem, getFiles } from '../../../api/authenticated/cms/getFiles';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../../common/models/ItemType';

const FileList: FC = () => {
  const store = useReviewStore();
  const [files, setFiles] = useState<IFileItem[]>([]);

  useEffect(() => {
    const effectAsync = async () => {
      if (!NavBarSelectorStore.selectedItem) return;
      const taskTeamId =
        NavBarSelectorStore.selectedItem.type === NavigationItemTypes.TaskTeam
          ? NavBarSelectorStore.selectedItem.taskTeam.id
          : undefined;

      const selectedFileContainers = store.selectedFiles.map((f) => ({
        fileContainerId: f.id,
        fileContainerRevisionId: f.fileRevisionId,
      }));

      const data = await getFiles(
        NavBarSelectorStore.selectedItem.project.projectNumber,
        taskTeamId ?? 0,
        FilesStore.fileContainerStateId,
        selectedFileContainers
      );
      setFiles(data);
    };

    effectAsync();
  }, [store]);

  return (
    <Table headers={[{ label: 'Files' }]} breakpoint={10} hoverable cssClass={Style.fileTables}>
      {store.selectedFiles.map((fileContainer) => (
        <>
          <TableRow key={FilesStore.getFileKey(fileContainer)}>
            <TableCell>
              <div className={Style.filenameCell}>
                <span>{fileContainer.title}</span>
                <button
                  className={Style.filenameRemove}
                  onClick={() => store.removeSelectedFile(fileContainer.id)}
                  disabled={store.isSaving}>
                  <Icon className={Style.filenameRemoveIcon} name="close" />
                </button>
              </div>
            </TableCell>
          </TableRow>
          {files
            .filter((f) => f.fileContainerId === fileContainer.id)
            .map((fi) => (
              <TableRow key={fi.id}>
                <TableCell>
                  <div className={Style.fileCell}>
                    <Icon name="shortcut" className={Style.fileIcon} />
                    {fi.originalFileName}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </>
      ))}
    </Table>
  );
};

export default observer(FileList);
