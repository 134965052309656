import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { ReactComponent as AureconLogo } from './../../assets/footer-logo.svg';
import Style from './styles/Footer.module.scss';
import { classNames } from '../../utils/miscUtils';
import { Pages } from '../../common/constants/Pages';
import packageJson from '../../../package.json';
export interface IFooterProps {
  className?: string;
}

const Footer: FC<IFooterProps> = ({ className }) => {
  return (
    <div className={classNames(Style.pageFooter, className)}>
      <div className={Style.logo}>
        <AureconLogo />
      </div>
      <div className={Style.footerContent}>
        <Link to={Pages.TermsConditions.Route}>Terms & Conditions</Link>
        <span className={Style.divider}>|</span>
        <Link to={Pages.PrivacyPolicy.Route}>Privacy Policy</Link>
        <span className={Style.divider}>|</span>
        {`${new Date().getFullYear()} © Tucana version ${packageJson.version}`}
      </div>
    </div>
  );
};

export default observer(Footer);
