import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DeliveryTeamDetails.module.scss';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import { Dropdown, IHeader, Loader, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import ConfirmationModal from '../../shared/ConfirmationModal';
import SearchBar from '../../shared/SearchBar';
import TeamDetailsHeader from './TeamDetailsHeader';
import { ITeamDetailsProps } from '../../../common/constants/TeamManagementAction';
import AddUsers from './AddUsers';
import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { TaskTeamRoleText } from '../../../common/constants/TaskTeamRoleText';
import { DropdownStyle } from '../../../common/constants/Dropdown';
import { SortTypes } from '../../../common/enums/SortType';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { ITaskTeamUser, ITaskTeamUserCSV } from '../../../api/authenticated/um/interfaces/user.interface';
import SecondaryButton from '../../shared/SecondaryButton';
import { ICSVData } from '../../../common/interfaces/csvData';
import { CSVLink } from 'react-csv';
import UserImportModal from '../shared/userUpload/UserImportModal';
import { IValidationImportResultModel } from '../shared/userUpload/models/ValidationImportUserModel';
import ExternalUserStore from '../../../stores/settings/teamManagement/ExternalUserStore';
import { ImportTaskTeamUsers } from '../../../api/authenticated/um/importTaskTeamUsers';
const RoleItems = [{ id: TaskTeamRole.Approver, label: TaskTeamRoleText[TaskTeamRole.Approver] }];

const TaskTeamDetails: FC<ITeamDetailsProps> = (props) => {
  const { projectNumber, team, teamType } = props;
  const [loading, setLoading] = useState(false);
  const [deletingUser, setDeletingUser] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ITaskTeamUser>();
  const [searchText, setSearchText] = useState('');
  const [sortedField, setSortedField] = useState('');
  const [sortDirection, setSortDirection] = useState<SortTypes>();
  const [activeTaskTeam, setActiveTaskTeam] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<ICSVData<ITaskTeamUserCSV> | null>(null);
  const [clicked, setClicked] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const effectAsync = useCallback(async () => {
    if (!projectNumber || !team.id) return;

    setLoading(true);
    await TeamManagementStore.loadTaskTeamUsers(projectNumber, team.id);
    setLoading(false);
  }, [team.id, projectNumber]);

  useEffect(() => {
    effectAsync();
  }, [effectAsync]);

  useEffect(() => {
    if (!loading && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, loading]);

  useEffect(() => {
    const taskActiveStatus = TeamManagementStore.clientTeams?.programmes
      .flatMap((pr) => pr.projects)
      .flatMap((p) => p.deliveryTeams)
      .flatMap((p) => p.taskTeams)
      .find((tt) => tt.id === team.id)?.active;
    setActiveTaskTeam(!!taskActiveStatus);
  }, [team]);

  const removeUserFromTeam = async () => {
    setDeletingUser(true);
    selectedUser && (await TeamManagementStore.removeUserFromTaskTeam(projectNumber, team.id, selectedUser.id || 0));
    setShowConfirmation(false);
    setDeletingUser(false);
    setSelectedUser(undefined);
  };
  const onCloseUploadFileModal = () => {
    setShowUploadFileModal(false);
    effectAsync();
  };
  const onSortSelected = (columnName: string, sortDirection: SortTypes) => {
    setSortedField(columnName);
    setSortDirection(sortDirection);
    TeamManagementStore.applySort(columnName, sortDirection);
  };
  const headers: IHeader[] = [
    {
      label: 'User Name',
      sort: sortedField === 'name' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('name', sort as SortTypes),
    },
    {
      label: 'User Email',
      sort: sortedField === 'email' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('email', sort as SortTypes),
    },
    {
      label: 'User Role',
      sort: sortedField === 'role' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('role', sort as SortTypes),
    },
    {
      label: 'Remove',
    },
  ];

  const fetchDataAsync = async () => {
    setLoading(true);
    setClicked(false);
    await TeamManagementStore.loadTaskTeamUsers(projectNumber, team.id);
    await TeamManagementStore.getProjectDetails(projectNumber);
    const mappingItems = TeamManagementStore.taskTeamUsers;
    const data = TeamManagementStore.mappingCSVData(mappingItems, projectNumber, team.code, team.title);
    setCsvData(data);
    setLoading(false);
  };

  const performValidationTaskTeamUser = (
    validUsers: IValidationImportResultModel[]
  ): {
    errorUsers: IValidationImportResultModel[];
    duplicateUsers: IValidationImportResultModel[];
    readyForImportUsers: IValidationImportResultModel[];
  } => {
    const errors = validUsers.filter((f) =>
      ExternalUserStore.getExternalUsersList()
        .map((m) => m.email.toLowerCase())
        .includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter((f) => !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()));

    const duplicates = validUsers.filter((f) =>
      TeamManagementStore.taskTeamUsers.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter(
      (f) =>
        !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()) &&
        !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );
    const readyForImport = validUsers.filter(
      (f) => !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    return {
      errorUsers: errors.map((m) => {
        return {
          ...m,
          result: 'User is the external user',
        };
      }),
      duplicateUsers: duplicates.map((m) => {
        return {
          ...m,
          result: 'User is already assigned to this team',
        };
      }),
      readyForImportUsers: readyForImport,
    };
  };
  const handleImportUser = async (readyForImport: IValidationImportResultModel[]) => {
    let response: {
      isSuccess: boolean;
      importResults: {
        email: string;
        status: string;
        reason: string;
      }[];
    };
    try {
      response = await ImportTaskTeamUsers({
        taskTeamId: team.id,
        users: readyForImport.map((m) => {
          return {
            email: m.email,
            displayName: m.displayName,
          };
        }),
        projectNumber: projectNumber,
      });

      if (response.isSuccess) await effectAsync();
    } catch (error) {
      response = {
        isSuccess: false,
        importResults: readyForImport.map((m) => {
          return {
            email: m.email,
            status: 'Failures',
            reason: 'Something went wrong',
          };
        }),
      };
    }
    return response;
  };

  if (loading) return <Loader />;

  return (
    <div className={Style.deliveryTeamContainer}>
      <TeamDetailsHeader team={team} teamType={teamType} projectNumber={projectNumber} />
      {activeTaskTeam && (
        <>
          <AddUsers
            projectNumber={projectNumber}
            roleItems={RoleItems}
            addUserToTeam={(selectedUsers, roleId) =>
              TeamManagementStore.addUserToTaskTeam(projectNumber, team.id, selectedUsers, roleId)
            }
          />
          <div className={Style.teamUserTable}>
            <div className={Style.actionButtonRow}>
              <div className={Style.actionButtonColumn}>
                <SecondaryButton
                  className={Style.btnMenuUploadBtn}
                  disabled={loading}
                  onClick={() => {
                    setShowUploadFileModal(true);
                  }}>
                  Import Users
                </SecondaryButton>
                <SecondaryButton
                  className={Style.btnMenuUploadBtn}
                  disabled={!TeamManagementStore.taskTeamUsers.length}
                  onClick={fetchDataAsync}>
                  Export to CSV
                  {csvData && (
                    <CSVLink
                      headers={csvData?.headers ?? []}
                      filename={csvData?.filename}
                      data={csvData?.data ?? []}
                      ref={csvLinkRef}
                    />
                  )}
                </SecondaryButton>
              </div>
              <div className={Style.quickSearch}>
                <SearchBar
                  searchValue={searchText}
                  cssClass={Style.searchUserBox}
                  onSearch={(keyword) => {
                    setSearchText(keyword);
                  }}
                  placeHolderText="Quick search..."
                />
              </div>
            </div>
            <Table headers={headers}>
              {TeamManagementStore.filteredTaskTeamUsers
                .filter(
                  (u) =>
                    u.name.toLowerCase().includes(searchText.toLowerCase() || u.name.toLowerCase()) ||
                    u.email.includes(searchText.toLowerCase() || u.email.toLowerCase())
                )
                .map((u) => (
                  <TableRow key={u.id}>
                    <TableCell>{u.name}</TableCell>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>
                      <Dropdown
                        placeholder="Select Additional Role"
                        items={RoleItems}
                        selectedMultipleItems={u.taskTeamRoleIds}
                        onSelectMultipleItems={(value) =>
                          TeamManagementStore.changeTaskTeamUserRole(team, u, value, projectNumber)
                        }
                        multiple={true}
                        multipleDisplayValues={true}
                        optionsHeight={DropdownStyle.defaultHeight}
                        disabled={!activeTaskTeam}
                      />
                    </TableCell>
                    <TableCell>
                      <PrimaryIconButton
                        icon="delete"
                        disabled={!activeTaskTeam}
                        onClick={() => {
                          setShowConfirmation(true);
                          setSelectedUser(u);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </div>
        </>
      )}
      {showUploadFileModal && (
        <UserImportModal
          closeModal={() => onCloseUploadFileModal()}
          showModal={showUploadFileModal}
          customUserValidation={performValidationTaskTeamUser}
          importUserCallback={handleImportUser}
          users={null}
          checkSystemAdmin={true}
        />
      )}
      <ConfirmationModal
        showModal={showConfirmation}
        heading={
          selectedUser?.taskTeamRoleIds.includes(TaskTeamRole.Approver)
            ? `Deleting Task Team Approver?`
            : `Deleting Task Team User?`
        }
        message={
          selectedUser?.taskTeamRoleIds.includes(TaskTeamRole.Approver) ? (
            <>
              This action will revoke user’s access as a task team approver from {team.title} and also as a task team
              user. Are you sure you want to delete {selectedUser?.name}?
            </>
          ) : (
            <>
              This action will revoke user’s access as a task team user from {team.title}.
              <br />
              <br />
              Please note that deleting a task team user will also revoke the user’s access as a delivery team
              authoriser. Are you sure you want to delete {selectedUser?.name} from both teams?
            </>
          )
        }
        confirmText="Yes"
        cancelText="No"
        loading={deletingUser}
        onConfirm={removeUserFromTeam}
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};

export default observer(TaskTeamDetails);
