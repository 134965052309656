import { TaskType } from '../../../common/enums/TaskType';
import { serverAxios } from '../api';
import { IAddFileRequest, IAddFileResponse } from './addTaskAttachmentFiles';

export interface IAddTaskFileContainer {
  fileContainerId: number;
  fileContainerRevisionId: number;
  releasedFileContainerId: null | number;
}

export interface IAddTaskRequest {
  taskTeamId: number;
  taskType: TaskType;
  suitabilityId: number;
  isSingleApproverRequired: boolean;
  message: string;
  fileContainers: IAddTaskFileContainer[];
  reviewerUserIds: number[];
  acceptorUserIds: number[];
  isSingleAcceptorRequired: boolean;
  dueDate: Date | null;
  attachmentFiles: IAddFileRequest[];
}

export interface IAddTaskResponse {
  taskId: number;
  projectNumber: string;
  attachmentFiles: IAddFileResponse[];
}

export async function addTask(request: IAddTaskRequest, abortSignal?: AbortSignal): Promise<IAddTaskResponse> {
  const result = await serverAxios.post<IAddTaskResponse>('api/task/add', request, { signal: abortSignal });
  return result.data;
}
