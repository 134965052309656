import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/ForgeViewerModal.module.scss';
import ForgeViewer from './ForgeViewer';
import ForgeViewerStore from './ForgeViewerStore';
import CentreOnPage from '../../shared/CentreOnPage';
import CloseButton from '../../shared/CloseButton';
interface IForgeViewerModalProps {
  closeModal: () => void;
  showModal: boolean;
}

const ForgeViewerModal: FC<IForgeViewerModalProps> = (props) => {
  if (!props.showModal) return null;
  return (
    <CentreOnPage>
      <Modal isShowing={props.showModal} onClose={() => props.closeModal()} size="large" cssClass={Style.modal}>
        <CloseButton onClick={() => props.closeModal()} />
        <div className={Style.modalBody}>
          <ForgeViewer token={ForgeViewerStore.token} urn={ForgeViewerStore.urn}></ForgeViewer>
        </div>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(ForgeViewerModal);
