import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

interface IFileFieldValueModel {
  id: number;
  fieldIndex: number;
  fieldValue: string | null;
}

export interface IFileMedataRequest {
  fileContainerId: number;
  metadata: IFileFieldValueModel[];
  pattern1: string | null;
  pattern2: string | null;
  pattern3: string | null;
}

export interface IUpdateFileMedataRequest {
  projectNumber: string;
  taskTeamId: number | null;
  fileContainerStateId: FileContainerState;
  fileContainers: IFileMedataRequest[];
}

export interface IUpdateFileMetadataResponse {
  isError: boolean;
  updateFileMetadataErrors: IUpdateFileMetadataError[];
}

export interface IUpdateFileMetadataError {
  fileContainerId: number;
  reason: string;
}

export const updateFileMetadata = async (
  request: IUpdateFileMedataRequest,
  abortSignal?: AbortSignal
): Promise<IUpdateFileMetadataResponse> => {
  const result = await serverAxios.put('api/cms/updatefilemetadata', request, { signal: abortSignal });
  return result.data;
};
