import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/UserCircle.module.scss';
import { classNames } from '../../utils/miscUtils';

export interface IUserCircleProps {
  initials: string;
  name?: string;
  email?: string;
  cssClass?: string;
  hideToolTip?: boolean;
}

const UserCircle: FC<IUserCircleProps> = ({ initials, name, email, cssClass, hideToolTip }) => {
  return (
    <div className={classNames(Style.user, [cssClass !== undefined, cssClass])}>
      {initials}
      {(name || email) && !hideToolTip && (
        <div className={Style.userTooltip}>
          {name && <div>{name}</div>}
          {email && <div className={Style.userEmail}>{email}</div>}
        </div>
      )}
    </div>
  );
};

export default observer(UserCircle);
