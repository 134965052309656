import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Toggle } from '@aurecon-creative-technologies/styleguide';
import { useReviewStore } from './ReviewStoreContext';
import Style from './styles/AllReviewersToggle.module.scss';

const AllReviewersToggle: FC = () => {
  const store = useReviewStore();

  if (!store.allApproversLabel) return null;

  return (
    <div className={Style.container}>
      <Toggle
        label={store.allApproversLabel}
        labelPosition="left"
        onLabel="Yes"
        offLabel="No"
        value={!store.isSingleApproverRequired}
        onChange={(b) => store.setIsSingleApproverRequired(!b)}
        disabled={store.isSaving}
      />
    </div>
  );
};

export default observer(AllReviewersToggle);
