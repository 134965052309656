import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import Style from './styles/CentreOnPage.module.scss';

interface ICentreOnPageProps {
  children?: React.ReactNode;
}

const CentreOnPage: FC<ICentreOnPageProps> = ({ children }) => {
  return ReactDOM.createPortal(<div className={Style.centreOnPage}>{children}</div>, document.getElementById('modal')!);
};

export default observer(CentreOnPage);
