import { createContext, useContext } from 'react';

interface TeamChartStoreContextProps {
  showAnyoneInTaskTeam: boolean;
  showAnyoneInDeliveryTeam: boolean;
  disableTaskTeam: boolean;
  disableDeliveryTeam: boolean;
  hideExternalSelection: boolean;
}
export const TeamChartStoreContext = createContext<TeamChartStoreContextProps>({
  showAnyoneInTaskTeam: true,
  showAnyoneInDeliveryTeam: true,
  disableTaskTeam: false,
  disableDeliveryTeam: false,
  hideExternalSelection: false,
});

export const useTeamChartContext = () => {
  return useContext(TeamChartStoreContext);
};
