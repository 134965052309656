import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface IUploadFromTemplateRequest {
  templateId: number;
  projectNumber: string;
  fileContainerId: number;
  containerFileId: number;
  fileContainerStateId: FileContainerState;
  prevFileContainerRevisionId: number | null;
  isSupersede?: boolean;
  fileName: string;
}

export async function uploadFromTemplate(
  request: IUploadFromTemplateRequest,
  abortSignal?: AbortSignal
): Promise<number> {
  const result = await serverAxios.post<number>('api/cms/uploadFromTemplate', request, {
    signal: abortSignal,
  });
  return result.data;
}
