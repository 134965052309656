import { serverAxios } from '../api';

export interface IAddClientFileTemplateRequest {
  templateFileName: string;
  originalFileName: string;
  totalFileSize: number;
  totalFileChunks: number;
}
export interface IAddClientFileTemplateResponse {
  clientFileTemplateId: number;
}

export async function addClientFileTemplate(
  request: IAddClientFileTemplateRequest,
  abortSignal?: AbortSignal
): Promise<IAddClientFileTemplateResponse> {
  const result = await serverAxios.post<IAddClientFileTemplateResponse>('api/config/addClientFileTemplate', request, {
    signal: abortSignal,
  });
  return result.data;
}
