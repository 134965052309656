import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { ISuggestion, SearchBox } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import Style from './styles/TopNavBarSelector.module.scss';
import Icon from './Icon';
import classNames from 'classnames';
import _ from 'lodash';

interface INavBarSelectorProp {
  disable: boolean;
  handleOnSelectItem: (id: string | number) => void;
  suggestions: ISuggestion[];
  buildSelectedRenderItem?: (selected) => string | JSX.Element;
  onChangeSearchBox: (text) => void;
  selectedItemId?: string;
  defaultHeight?: boolean;
}

const TopNavBarSelector: React.FC<INavBarSelectorProp> = ({
  disable,
  handleOnSelectItem,
  suggestions,
  buildSelectedRenderItem,
  selectedItemId,
  onChangeSearchBox,
  defaultHeight,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [triggerCleanup, setTriggerCleanup] = useState(0);
  const [searchText, setSearchText] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const currentRef = ref.current;
    const onClickHandler = () => !disable && setShowSuggestions(true);
    currentRef.addEventListener('click', onClickHandler);
    return () => {
      currentRef.removeEventListener('click', onClickHandler);
    };
  });

  const onSelectItem = (id: string | number): void => {
    handleOnSelectItem(id);
    onClearSearchBox();
  };

  const onClearSearchBox = (showSuggestionsDropdown = false) => {
    setTriggerCleanup(triggerCleanup + 1);
    setShowSuggestions(showSuggestionsDropdown);
    onChangeSearchBox('');
  };

  const searchBoxRef = useCallback(
    (node) => {
      if (!node || !showSuggestions) return;

      node.click();
      node.focus();
      node.parentNode?.childNodes?.forEach((t) => {
        if (t.textContent?.includes('search')) t.className = 'searchbox-search-icon material-icons';
        if (t.textContent?.includes('expand')) t.className = 'searchbox-expand-icon material-icons';
        if (searchText && t.textContent?.includes('clear')) t.className = 'searchbox-clear-icon material-icons';
      });
    },
    [searchText, showSuggestions]
  );

  const suggestionsData = useMemo(
    () =>
      suggestions.map((t) =>
        t.id !== selectedItemId
          ? t
          : {
              ...t,
              display: <div className={Style.active}>{t.display}</div>,
            }
      ),
    [suggestions, selectedItemId]
  );

  const debounceChangeSearchBox = _.debounce(function (showSuggestionsDropdown = false) {
    onClearSearchBox(showSuggestionsDropdown);
  }, 200);

  return (
    <div className={Style.navBarSelector}>
      {showSuggestions ? (
        <SearchBox
          ref={searchBoxRef}
          disableDefaultMatching={true}
          hideSearchButton={true}
          suggestionLength={suggestionsData?.length}
          suggestions={suggestionsData}
          type="dropdown"
          onChange={(value) => {
            setSearchText(value);
            onChangeSearchBox(value);
          }}
          onSearch={(value) => onChangeSearchBox(value)}
          onBlur={debounceChangeSearchBox}
          cssClass={classNames([Style.searchSuggestions, defaultHeight ? Style.defaultHeight : null])}
          onSelect={(selected) => onSelectItem(selected.id)}
          triggerCleanup={triggerCleanup}
          disabled={disable}
          onClear={() => debounceChangeSearchBox(true)}
        />
      ) : (
        <div ref={ref} className="dropdown-wrapper is-medium">
          <div className="dropdown-content is-down">
            <div className={classNames('dropdown-selected', Style.selectedItem)}>
              {buildSelectedRenderItem && selectedItemId
                ? buildSelectedRenderItem(suggestions.find((t) => t.id === selectedItemId))
                : suggestions.find((t) => t.id === selectedItemId)?.display ?? ''}

              <Icon name="expand_more" className="dropdown-down-icon" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(TopNavBarSelector);
