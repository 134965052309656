import {
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { updateLastLoginDate } from '../../api/authenticated/um/updateLastLoginDate';
import { AuthType } from '../models/AuthType';
import { logoutRequest, tokenRequest } from '../configs/MsalConfig';
import { IAuthInstance } from '../models/IAuthInstance';

export class MsalInstance implements IAuthInstance {
  private authority: string;
  private clientId: string;
  private instance: PublicClientApplication | null;

  constructor(authority: string, clientId: string) {
    this.authority = authority;
    this.clientId = clientId;
    this.instance = null;
  }

  getType(): AuthType {
    return 'msal';
  }

  async init(): Promise<void> {
    const instance = new PublicClientApplication({
      auth: {
        clientId: this.clientId,
        authority: this.authority,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    });

    // Default to using the first account if no account is active on page load
    if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      instance.setActiveAccount(instance.getAllAccounts()[0]);
    }

    // This will update account state if a user signs in from another tab or window
    instance.enableAccountStorageEvents();

    (instance as IPublicClientApplication).addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        instance.setActiveAccount(account);
        updateLastLoginDate();
      }
    });

    this.instance = instance;
  }

  async getInstance<T>(): Promise<T> {
    return this.instance as T;
  }

  getConfig<T>(): T {
    return {
      authority: this.authority,
      clientId: this.clientId,
    } as T;
  }

  async getTokenSilently(scope: string): Promise<string> {
    if (!this.instance) throw new Error('Auth instance is not initialized.');
    const tokenReq = {
      ...tokenRequest,
      scopes: [...tokenRequest.scopes, ...[scope ?? '']],
    };
    try {
      const result = await this.instance.acquireTokenSilent(tokenReq);
      return result.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        this.instance.logoutRedirect(logoutRequest);
      }

      return '';
    }
  }
}
