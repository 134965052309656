import { serverAxios } from '../api';

export interface IFileContainer {
  fileContainerId: number;
  fileContainerRevisionId: number;
  releasedFileId: number | null;
}

export interface ISendWipCollaborationNotificationRequest {
  taskTeamId: number | null;
  userIds: number[];
  fileContainers: IFileContainer[];
  message?: string;
  temporaryAccessExpiredOn: Date | null;
}

export function sendWipCollaborationNotification(
  request: ISendWipCollaborationNotificationRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/cms/sendWipCollaborationNotification', request, {
    signal: abortSignal,
  });
}
