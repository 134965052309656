import { makeAutoObservable, runInAction } from 'mobx';
import { ITreeNodeData, NodeData } from '../../../components/shared/TreeView';
import { NodeActions } from '../../../components/settings/projectManagement/ProjectManagement';
import { NodeType, NodeTypes } from '../../../components/settings/teamManagement/TeamManagement';
import { IUser } from '../../../components/shared/UserSelector';
import { SystemUsersFilter } from '../../../api/authenticated/um/SystemUsersFilterClass';
import { getSystemUsers } from '../../../api/authenticated/um/getSystemUsers';
import { addNewProject } from '../../../api/authenticated/config/addNewProject';
import { AxiosError } from 'axios';
import LayoutStore from '../../../components/layout/LayoutStore';
import { IProject, getProjects } from '../../../api/authenticated/config/getProjects';
import { chain } from 'lodash';
import { getProjectDetails } from '../../../api/authenticated/config/getProjectDetails';
import {
  IProgramme,
  IProjectDetails,
  IResponseError,
  IUpdateProjectVisualisationModel,
} from '../../../api/authenticated/config/models/projectModel';
import { updateProject } from '../../../api/authenticated/config/updateProject';
import AppStore from '../../AppStore';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import { IProgrammeDetails, updateProgramme } from '../../../api/authenticated/config/UpdateProgramme';
import { deleteProgramme } from '../../../api/authenticated/config/deleteProgramme';
import { addProgramme } from '../../../api/authenticated/config/addNewProgramme';
import { archiveOrUnArchiveProject } from '../../../api/authenticated/config/archiveProject';
import { ProjectStatus } from '../../../common/enums/ProjectStatus';
import { deleteProject } from '../../../api/authenticated/config/deleteProject';
import { sort } from '../../../utils/sortHelper';
import { SortTypes } from '../../../common/enums/SortType';
import { ICSVData } from '../../../common/interfaces/csvData';
import { IExportUserCSV } from '../../../api/authenticated/um/interfaces/user.interface';
import { updateProjectVisualisation } from '../../../api/authenticated/config/updateProjectVisualisation';

export class ProjectManagementStore {
  constructor() {
    makeAutoObservable(this);
  }

  public treeData: ITreeNodeData<NodeTypes>[] = [];
  public systemUsers: IUser[] = [];
  public systemUserFilters: SystemUsersFilter = new SystemUsersFilter();
  public projectDetails: IProjectDetails | null = null;
  public loading = false;
  public loadingSystemUsers = false;
  public saving = false;
  public projects: IProject[] = [];
  public programmes: IProgramme[] = [];

  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public showError = false;

  public async fetchProjectData() {
    try {
      runInAction(() => {
        this.loading = true;
      });

      const res = await getProjects();
      const programmes = chain(res)
        .groupBy((x) => x.programmeId)
        .map((value, key) => ({ programmeId: key, programmeTitle: value[0].programmeTitle, projects: value }))
        .value();
      const treeData: ITreeNodeData<NodeTypes>[] =
        programmes.map((p) => {
          return {
            id: p.programmeId,
            key: `${p.programmeId}`,
            title: p.programmeTitle,
            type: NodeType.Programme,
            path: `${p.programmeId}`,
            selectable: true,
            showLine: true,
            listActions:
              AppStore.client?.user.clientUserRole === ClientUserRole.SystemAdmin
                ? [
                    {
                      action: NodeActions.ADD_PROJECT,
                      actionTitle: 'Create New Project',
                    },
                  ]
                : undefined,
            childrenNodes: p.projects.map((pr) => {
              return {
                id: pr.projectNumber,
                key: `${p.programmeId}/${pr.projectNumber}`,
                title: pr.title,
                type: NodeType.Project,
                path: `${p.programmeId}/${pr.projectNumber}`,
                childrenNodes: [],
                selectable: true,
                disabled: pr.projectStatusId === ProjectStatus.Inactive,
              };
            }),
          };
        }) ?? [];
      runInAction(() => {
        this.treeData = sort(treeData, 'title', SortTypes.ASC);
        this.projects = res.filter((p) => p.projectNumber);
        this.programmes = programmes.map((p) => ({
          programmeId: Number(p.programmeId),
          programmeTitle: p.programmeTitle,
        }));
      });
    } catch (err) {
      runInAction(() => {
        this.treeData = [];
        this.projects = [];
        this.programmes = [];
      });
    } finally {
      setTimeout(() => {
        runInAction(() => {
          this.loading = false;
        });
      }, 500);
    }
  }

  public cleanUp() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
      this.showError = false;
    });
  }

  public async getAllSystemUsers() {
    runInAction(() => {
      this.loadingSystemUsers = true;
    });
    const requestParam = this.systemUserFilters;
    requestParam.active = true;
    const data = await getSystemUsers(requestParam);
    runInAction(() => {
      this.systemUsers = data;
      this.loadingSystemUsers = false;
    });
  }

  public async getProjectDetails(projectNumber: string) {
    runInAction(() => {
      this.loading = true;
    });
    const data = await getProjectDetails(projectNumber);
    runInAction(() => {
      this.projectDetails = data;
      this.loading = false;
    });
  }

  public searchUsers(searchText: string): IUser[] {
    const lowercaseText = searchText?.toLowerCase() ?? '';
    return this.systemUsers.filter(
      (u) => u.name.toLowerCase().includes(lowercaseText) || u.email.toLowerCase().includes(lowercaseText)
    );
  }

  public setError(error: AxiosError<IResponseError>) {
    runInAction(() => {
      if (error?.code === 'ERR_NETWORK' || error?.response?.status === 0) {
        this.errorCode = 0;
        this.errorMessage = 'You are offline. Please check your connection and try again later.';
        return;
      }
      this.errorCode = error?.response?.status ?? 500;
      if (this.errorCode === 400 && error?.response?.data) {
        const errorDetails = this.getErrorFields(error.response.data);
        if (errorDetails.length) {
          this.errorMessage = errorDetails[0].errors.join(', ');
        } else {
          this.errorMessage = 'Request invalid.';
        }
      }
    });
  }

  private getErrorFields(responseData: IResponseError) {
    const errorFields: { field: string; errors: string[] }[] = [];
    if (responseData.errors) {
      const names = Object.getOwnPropertyNames(responseData.errors);
      if (names.length) {
        names.forEach((f) => {
          const err: string[] = [];
          if (responseData.errors[f].length) {
            responseData.errors[f].forEach((e) => {
              err.push(e.errorMessage);
            });
          }
          errorFields.push({
            field: f,
            errors: err,
          });
        });
      }
    }
    return errorFields;
  }

  public async addNewProject(project) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await addNewProject({ ...project });
      await this.fetchProjectData();
      await this.getProjectDetails(project.projectNumber);
      LayoutStore.displayToast('success', 'Project has been successfully created.');
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      if (this.errorMessage) {
        LayoutStore.displayToast('error', this.errorMessage);
      }
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async updateProject(project) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await updateProject({ ...project });
      await this.fetchProjectData();
      await this.getProjectDetails(project.projectNumber);
      await AppStore.forceRefresh();
      LayoutStore.displayToast('success', 'Project details has been successfully saved.');
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      if (this.errorMessage) {
        LayoutStore.displayToast('error', 'Project details cannot be saved. Please try again later.');
      }
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async updateProjectVisualisation(data: IUpdateProjectVisualisationModel) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await updateProjectVisualisation(data);
      await this.fetchProjectData();
      await this.getProjectDetails(data.projectNumber);
      await AppStore.forceRefresh();
      LayoutStore.displayToast('success', 'Visualisation has been successfully configured.');
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      if (this.errorMessage) {
        LayoutStore.displayToast('error', 'Visualisation cannot be configured. Please try again later.');
      }
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async archiveProject(projectNumber: string, archive: boolean) {
    runInAction(() => {
      this.loading = true;
    });

    const archivedText = archive ? 'archived' : 'unarchived';
    try {
      await archiveOrUnArchiveProject({ projectNumber, archive });
      await this.getProjectDetails(projectNumber);
      await this.fetchRelatedData();
      LayoutStore.displayToast('success', `Project has been successfully ${archivedText}.`);
    } catch (err) {
      runInAction(() => {
        const error = err as AxiosError<string>;
        this.errorMessage = error?.response?.data ?? null;
      });

      LayoutStore.displayToast('error', `Project has been unsuccessfully ${archivedText}.`);
      this.setShowErrorModal(true);
    }

    runInAction(() => {
      this.loading = false;
    });
  }

  public async deleteProject(projectNumber: string) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      await deleteProject(projectNumber);
      await this.fetchRelatedData();
      LayoutStore.displayToast('success', `Project has been successfully deleted.`);
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      LayoutStore.displayToast('error', `Deleting project was unsuccessful.`);
      this.setShowErrorModal(true);
    }

    runInAction(() => {
      this.loading = false;
      if (!this.showError) {
        this.projectDetails = null;
      }
    });
  }

  private async fetchRelatedData() {
    await this.fetchProjectData();
    await AppStore.forceRefresh();
  }

  public setShowErrorModal(value) {
    runInAction(() => {
      this.showError = value;
    });
  }

  public validateDuplicateProjectTitle(projectTitle: string, projectNumber?: string) {
    if (
      this.projects
        .filter((x) => x.projectNumber !== projectNumber)
        .some((x) => x.title.trim().toLowerCase() === projectTitle.trim().toLowerCase())
    ) {
      return `Project Name already exists`;
    }
    return '';
  }

  public validateDuplicateProjectNumber(value) {
    if (this.projects.some((x) => x.projectNumber.toLowerCase() === value.toLowerCase()))
      return `Project Number already exists`;
    return '';
  }
  //Programme Validate
  public validateDuplicateProgrammeName(programmeName: string) {
    if (this.programmes.some((x) => x.programmeTitle.trim().toLowerCase() === programmeName.trim().toLowerCase())) {
      return `Programme Name already exists`;
    }
    return '';
  }
  //Programme Create
  public async addProgramme(programmeName: string) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      if (!programmeName) return;
      await addProgramme(programmeName);
      await this.fetchProjectData();
      LayoutStore.displayToast('success', 'Programme has been successfully created.');
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      if (this.errorMessage) {
        LayoutStore.displayToast('error', this.errorMessage);
      }
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
  //Programme Update
  public async updateProgramme(programme: IProgrammeDetails | undefined) {
    runInAction(() => {
      this.loading = true;
    });
    try {
      if (!programme) return;
      await updateProgramme(programme);
      await this.fetchProjectData();
      LayoutStore.displayToast('success', 'Programme name has been changed successfully.');
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      if (this.errorMessage) {
        LayoutStore.displayToast('error', this.errorMessage);
      }
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
  //Check programme has project
  public searchProjectsSelectedProgramme(selectProgramme: IProgramme): IProject[] {
    return this.projects.filter(
      (u) =>
        u.programmeId === selectProgramme.programmeId ||
        u.programmeTitle.trim().toLowerCase() === selectProgramme.programmeTitle.trim().toLowerCase()
    );
  }
  //delete Programme

  public async deleteProgramme(selectedProgramme: IProgramme | undefined) {
    if (!selectedProgramme) return;
    runInAction(() => {
      this.loading = true;
    });
    try {
      await deleteProgramme(selectedProgramme);
      await this.fetchProjectData();
      LayoutStore.displayToast('success', 'Programme has been deleted successfully');
    } catch (err) {
      this.setError(err as AxiosError<IResponseError>);
      if (this.errorMessage) {
        LayoutStore.displayToast('error', this.errorMessage);
      }
      this.setShowErrorModal(true);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
  public buildNodeSelected(projectNumber: string) {
    const project = this.projects.find((x) => x.projectNumber === projectNumber);
    if (project) {
      return {
        id: project.projectNumber,
        key: `${project.programmeId}/${project.projectNumber}`,
        title: project.title,
        type: NodeType.Project,
        path: `${project.programmeId}/${project.projectNumber}`,
        childrenNodes: [],
        selectable: true,
        data: null,
      } as NodeData<NodeTypes>;
    }
    return undefined;
  }
  public buildNodeSelectedProgramme(programmeName: string) {
    const programme = this.programmes.find((x) => x.programmeTitle === programmeName);
    if (programme) {
      return {
        id: programme.programmeId,
        key: `${programme.programmeId}`,
        title: programme.programmeTitle,
        type: NodeType.Programme,
        path: `${programme.programmeId}`,
        childrenNodes: [],
        selectable: true,
        data: null,
      } as NodeData<NodeTypes>;
    }
    return undefined;
  }
  public mappingCSVProjectUserData(users: IExportUserCSV[], projectName: string) {
    return {
      filename: `${projectName}-users.csv`,
      headers: [
        { label: 'User Name', key: 'userName' },
        { label: 'User Email', key: 'userEmail' },
        { label: 'Project', key: 'project' },
        { label: 'Team', key: 'team' },
        { label: 'Role', key: 'role' },
      ],
      data: users,
    } as ICSVData<IExportUserCSV>;
  }
}

export default new ProjectManagementStore();
