import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from './Modal';
import Style from './styles/ErrorModal.module.scss';
import PrimaryButton from './PrimaryButton';
import ModalActions from './ModalActions';
import CentreOnPage from './CentreOnPage';
import Overlay from './Overlay';
import { ErrorStatusText } from '../../common/constants/ErrorStatusText';

interface IErrorModalProps {
  errorCode: number | null;
  header?: string | null;
  errorMessage?: string | JSX.Element | null;
  closeModal: () => void;
}

const ErrorModal: FC<IErrorModalProps> = (props) => {
  const { closeModal, errorMessage = <p>Please try again later. Contact support if the error persists.</p> } = props;

  const getErrorHeader = () => {
    return (
      props.header ??
      (props.errorCode || props.errorCode === 0 ? ErrorStatusText[props.errorCode] : 'Something went wrong')
    );
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h1>{getErrorHeader()}</h1>
        <p className={Style.message}>{errorMessage}</p>
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(ErrorModal);
