import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchBox } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/UserTextFilter.module.scss';
import { ITextFilter } from '../../common/interfaces/TableColumn';

export interface IUserTextFilterProps {
  textFilter: ITextFilter;
  disabled?: boolean;
  onChange?: (value?: string) => void;
}

const UserTextFilter: FC<IUserTextFilterProps> = ({ textFilter, disabled, onChange }) => {
  const valueChangedHandler = (val?: string) => {
    onChange?.(val);
  };
  return (
    <SearchBox
      cssClass={Style.searchBox}
      hideSearchButton
      placeholder="Search"
      defaultValue={textFilter.filter}
      onSearch={(value) => valueChangedHandler(value.toString())}
      onClear={() => valueChangedHandler()}
      disabled={disabled}
    />
  );
};

export default observer(UserTextFilter);
