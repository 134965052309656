import { IAddToastPros, ToastTypes } from '@aurecon-creative-technologies/styleguide';
import { makeAutoObservable, runInAction } from 'mobx';

export class LayoutStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _addToast: ((toastProps: IAddToastPros) => void) | null = null;

  public showSidebar = true;

  public toggleSidebar() {
    runInAction(() => {
      this.showSidebar = !this.showSidebar;
    });
  }

  public displayToast(toastType: ToastTypes, message: string, timeout?: number) {
    if (!this._addToast) return;
    this._addToast({
      type: toastType,
      message: message,
      timeout: timeout ?? 5000,
    });
  }

  public setAddToast(value: (toastProps: IAddToastPros) => void) {
    runInAction(() => {
      this._addToast = value;
    });
  }
}

export default new LayoutStore();
