import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import GoBackButton from '../shared/GoBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import PageNotFound from '../shared/PageNotFound';
import TransmittalDetailsActions from './TransmittalDetailsActions';
import TransmittalDetailsFilesModal from './TransmittalDetailsFilesModal';
import TransmittalNotifiersModal from './TransmittalNotifiersModal';
import FilesStore from '../files/FilesStore';
import { Pages } from '../../common/constants/Pages';
import TransmittalHeader from './TransmittalHeader';
import TransmittalResponse from './TransmittalResponse';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import { useAuth } from '../../authentication/hooks/useAuth';

const TransmittalDetails: FC = () => {
  const { account } = useAuth();
  const [openDetailFileModal, setOpenDetailFileModal] = useState<boolean>(false);
  const [openFileOfMessageId, setOpenFileOfMessageId] = useState<number | null>(null);
  const params = useParams();
  const navigate = useNavigate();

  const transmittal = TransmittalDetailsStore.transmittal;

  useEffect(() => {
    const init = async () => {
      await TransmittalDetailsStore.init(
        params.transmittalTitle ?? '',
        account?.userName ?? '',
        params.projectNumber ?? null
      );
    };

    init();

    const appInsights = getAppInsightsInstance();
    if (appInsights)
      appInsights.trackPageView({
        name: AppInsightPageView.TRANSMITTAL_DETAILS,
        isLoggedIn: true,
        uri: `${Pages.Transmittals.Route}/${params?.transmittalTitle}`,
      });
  }, [account?.userName, params]);

  useEffect(() => {
    if (!transmittal) return;
    const effectAsync = async () => {
      await FilesStore.loadProjectMetadataByProjectNumber(transmittal.projectNumber);
    };

    effectAsync();
  }, [transmittal]);

  const onClose = async () => {
    if (!transmittal) return;
    await TransmittalDetailsStore.closedTransmittal(transmittal.id);
    await TransmittalDetailsStore.init(params.transmittalTitle ?? '', account?.userName ?? '');
  };

  const onReopen = async () => {
    if (!transmittal) return;
    const reopenTransmittalSuccess = await TransmittalDetailsStore.reopenTransmittal(transmittal.id);
    if (reopenTransmittalSuccess) {
      await TransmittalDetailsStore.init(params.transmittalTitle ?? '', account?.userName ?? '');
    }
  };

  const onFlagged = async (flagged: boolean) => {
    if (!transmittal) return;
    await TransmittalDetailsStore.flagTransmittal(transmittal.id, flagged);
    await TransmittalDetailsStore.init(params.transmittalTitle ?? '', account?.userName ?? '');
  };

  const handleShowResponseFilesModal = (messageId: number) => {
    setOpenFileOfMessageId(messageId);
    setOpenDetailFileModal(true);
  };

  if (TransmittalDetailsStore.isLoadingTransmittal) return <Loader />;
  if (!transmittal) return <PageNotFound />;

  return (
    <>
      <GoBackButton onClick={() => navigate(Pages.Transmittals.Route)} />
      <>
        {transmittal && (
          <>
            <TransmittalHeader
              transmittal={transmittal}
              subject={transmittal.transmittalMessages.slice(-1)[0]?.subject}
            />
            <TransmittalDetailsActions
              initiator={transmittal.createdByEmail}
              closed={transmittal.closed}
              flagged={transmittal.flagged}
              canRespond={transmittal.canRespond}
              onClose={onClose}
              onReopen={onReopen}
              onFlagged={onFlagged}
            />
            <TransmittalResponse
              responses={transmittal.transmittalMessages}
              showResponseFilesModal={handleShowResponseFilesModal}
            />
          </>
        )}
      </>
      {TransmittalDetailsStore.showNotifierModal && (
        <TransmittalNotifiersModal
          data={TransmittalDetailsStore.transmittalNotifications}
          closeModal={() => (TransmittalDetailsStore.showNotifierModal = false)}></TransmittalNotifiersModal>
      )}
      {openDetailFileModal && openFileOfMessageId && (
        <TransmittalDetailsFilesModal
          onClose={() => {
            setOpenDetailFileModal(false);
            setOpenFileOfMessageId(null);
          }}
          messageId={openFileOfMessageId}
        />
      )}
    </>
  );
};

export default observer(TransmittalDetails);
