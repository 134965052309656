import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useReviewStore } from './ReviewStoreContext';
import Style from './styles/AccessibleDeliveryTeamsDisplay.module.scss';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Icon from '../../shared/Icon';
import Dot from '../../shared/Dot';
import { classNames } from '../../../utils/miscUtils';

const AccessibleDeliveryTeamsDisplay: FC = () => {
  const store = useReviewStore();

  if (!store.showAccessibleDeliveryTeams || store.state === 'Collaborate') return null;
  const getAccessibleDeliveryTeamsTooltip = () => {
    return (
      <>
        {store.accessibleDeliveryTeams.map((f) => (
          <>
            {f.IsCurrentDeliveryTeam && <Dot className={Style.indicator} />}
            <div
              className={classNames(Style.teamVisible, [!f.IsCurrentDeliveryTeam, Style.nonIndicator])}
              key={f.accessibleDeliveryTeamId}>
              {f.accessibleDeliveryTeamTitle} ({f.accessibleDeliveryTeamCode})
            </div>
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <div className={Style.container}>
      Visible to teams{' '}
      <Tooltip show={getAccessibleDeliveryTeamsTooltip()} defaultUp>
        <Icon className={Style.icon} outlined name="info" />
      </Tooltip>
    </div>
  );
};

export default observer(AccessibleDeliveryTeamsDisplay);
