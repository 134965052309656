import { AppointingPartyRole } from '../../../common/enums/AppointingPartyRole';
import { serverAxios } from '../api';

export interface IUpdateAppointingPartyUserRoleRequest {
  projectNumber: string;
  userId: number;
  appointingPartyRoleId: AppointingPartyRole;
}

export function updateAppointingPartyUserRole(
  request: IUpdateAppointingPartyUserRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/appointingparty/updateuserrole', request, {
    signal: abortSignal,
  });
}
