export const AppInsightEvent = {
  FILE_ADD_WIP: 'File Add WIP',
  FILE_ADD_SHARE: 'File Add Share',
  FILE_ADD_PUBLISHED: 'File Add Published',
  FILE_UPLOAD_CANCEL: 'File Upload Cancel',
  APPLY_FILE_FILTER: 'Apply File Filter',
  FILE_REVIEW: 'File Review',
  FILE_SHARE: 'File Share',
  FILE_PUBLISHED: 'File Published',
  FILE_COLLABORATE: 'File Collaborate',
  FILE_DOWNLOAD: 'File Download',
  FILE_OPEN: 'File Open',
  FILE_COPY_LINK: 'File Copy Link',
  APPLY_TASK_FILTER: 'Apply Task Filter',
  TASK_AWAITING_MY_ACTION_TAB: 'Task Awaiting My Action Tab',
  TASK_INITIATED_BY_ME_TAB: 'Task Initiated By Me Tab',
  TASK_ALL_PENDING_TASKS_TAB: 'Task All Pending Tasks Tab',
  TASK_ALL_TASKS_TAB: 'Task All Tasks Tab',
  TRANSMITTALS_NEW: 'Transmittals New',
  TRANSMITTALS_MY_NOTIFICATIONS_TAB: 'Transmittals My Notifications Tab',
  TRANSMITTALS_INITIATED_BY_ME_TAB: 'Transmittals Initiated By Me Tab',
  TRANSMITTALS_VISIBLE_TO_ME_TAB: 'Transmittals Visible To Me Tab',
  TRANSMITTALS_ALL_DELIVERY_TEAM_TAB: 'Transmittals All Delivery Team Tab',
  APPLY_TRANSMITTAL_FILTER: 'Apply Transmittal Filter',
  TRANSMITTALS_EXPORT_TO_CSV: 'Transmittals Export to CSV',
  TRANSMITTALS_TEAM_CHART: 'Transmittals Team Chart',
  TRANSMITTALS_ADD_CONTENT_SUPPORTING_FILE: 'Transmittals Add Content file or Supporting file',
  TRANSMITTAL_RESPONSE: 'Transmittal Response',
  TRANSMITTALS_CANCEL: 'Transmittals Cancel',
};
