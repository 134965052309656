import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import AppStore from '../../stores/AppStore';
import PageDisabled from './PageDisabled';

export interface IProjectGuardProps {
  settingName: string;
  pageName: string;
  element: JSX.Element;
}

const ProjectGuard: FC<IProjectGuardProps> = ({ settingName, pageName, element }) => {
  if (!AppStore.projectSettings[settingName]) return <PageDisabled pageName={pageName} />;

  return element;
};

export default observer(ProjectGuard);
