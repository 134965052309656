import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FileUpload.module.scss';
import FilesStore from '../FilesStore';
import Icon from '../../shared/Icon';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';

const UploadVisibleToTeams: FC = () => {
  useEffect(() => {
    FilesStore.getUploadDeliveryteamAccess();
  }, []);

  return (
    <div className={Style.visibleToTeams}>
      Visible to Teams
      <Tooltip
        cssClass={Style.visibleToTeamsTooltip}
        defaultUp
        show={
          <div className={Style.visibleToTeamsTooltipContent}>
            {FilesStore.uploadSharedDeliveryTeams && FilesStore.uploadSharedDeliveryTeams.length > 0 && (
              <div className={Style.shared}>
                <p className={Style.title}>Shared</p>
                {FilesStore.uploadSharedDeliveryTeams.map((team, index) => {
                  return (
                    <p key={team.deliveryTeamId}>
                      {team.deliveryTeamTitle} ({team.deliveryTeamCode}){' '}
                      {index === 0 && <Icon name="circle" className={Style.currentDeliveryTeamIcon} />}
                    </p>
                  );
                })}
              </div>
            )}
            {FilesStore.uploadPublishedDeliveryTeams && FilesStore.uploadPublishedDeliveryTeams.length > 0 && (
              <div
                className={classNames(Style.published, [
                  FilesStore.uploadSharedDeliveryTeams.length > 0,
                  Style.publishedMarginTop,
                ])}>
                <p className={Style.title}>Published</p>
                {FilesStore.uploadPublishedDeliveryTeams.map((team, index) => {
                  return (
                    <p key={team.deliveryTeamId}>
                      {team.deliveryTeamTitle} ({team.deliveryTeamCode}){' '}
                      {index === 0 && <Icon name="circle" className={Style.currentDeliveryTeamIcon} />}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        }>
        <Icon name={'info'} outlined className={Style.visibleToTeamsIcon} />
      </Tooltip>
    </div>
  );
};

export default observer(UploadVisibleToTeams);
