import React, { FC, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, FormInput, Grid, Table, Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from '../../../styles/components/settings/projectManagement/ProjectApps.module.scss';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import { getProjectApps, ProjectApp } from '../../../api/authenticated/config/getProjectApps';
import { v4 as uuidv4 } from 'uuid';
import getFileExtension from '../../../utils/fileUtils';
import { FileExt } from '../../../common/constants/FileExt';
import { classNames } from '../../../utils/miscUtils';
import { updateProjectApps } from '../../../api/authenticated/config/updateProjectApps';
import LayoutStore from '../../layout/LayoutStore';
import { sort } from '../../../utils/sortHelper';
import { SortType, SortTypes } from '../../../common/enums/SortType';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import Icon from '../../shared/Icon';

const defaultIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnCAYAAACMo1E1AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARoSURBVHgB7Zi5SiVBFIZ75gF8A9/IzAU1dAMDRXNB0EgDfQMVDVQ01ScwEAMV9wURFNz3Hbcz9R04l7K7+nZfGQaD+ZO63V116q+z1/0lDtEPxe/oB+M/ue8iN7nHx0cdb25udHx+ftbx6urqy7PNe3p60vH+/j445oJkYHR0VM7Pz6W9vV1OT0+loaFBbm9vpa2tTa6vr6WyslLe3t6ktbVV7u7upKamRtfV19fruubmZnl4eNB1m5ub0t3dre+Hh4eztpZUckNDQ3JyciLr6+u6+d+E077s7OzI7u6u7lMyORaioVJwcHBQ0nzko4A0BH1uZGQE0lFZWZn+vri4SMxxpom2t7fVt+rq6qKPj49ocHBQR55578xY8EEfyEMu8i8vL6OxsbH8PodP4D/g7OxM3IZyeHhY+L63t6f+hz8Ww+TkpOzv78vS0pJ8fn7qOzSFPPYA7+/vhd9xJMi5aFKn9YHAvr4+/e1OKj09PVIKnEbFaUt/I8c/KCDYCJpMcoCoCqGqqkpP+h28vr7q+hCwQghBzRH2PjADJ2eDpqamghbyguhsbGxUOWg+DvYjLWWSA3EVLy8vq2lsIwThdwBfmp+fV41CgOfV1VV5eXnR70dHRzrfDtTb21tYa0jLCglyBEJLS8sXoji1DzacnZ3VTdHE1NSUvneRqSPPrnIoIebFyWBG35T4nItuySQH/Ohx6UImJiYSc9i4o6NDioHvIXMR5cg1hOYEyXF6So//HIerowXfqaioSFQQTMx7wDxLI/F9DJTAkC8GNecLcwk18R2fMlNChFK0srKiz1tbW/pshJm3sLCQkFFbW1v4nVYeg9HqhzwRVow8wESLi4v6ntGIFpPhv6NpyO1zVh2olZ2dnYnvRGXc3BDr6upKELeOJA7kWi22/TLJcQJOYggl3fg7NOVrDtPGiReTQZuVu0L4PhDyOXxoenq6MJcOxghBlGczGz6Hj8bh+1waEuSIGt/nQtGKJvxojWsSwhat5DtLyD58uSggtE9Qc37eQQuh7oM5efJcqNSRN/08l7srwedowQ20TPHCTMYn87NxWoXgPQdg3vHx8Zf1VBxfU7TyoaAIai5e6yBjbRKE2JQSBjDZ2tqa+hhRiYnxSQsC1jLfZFKjqdU+QsEQJIcgUkUckIIA3yj+pYC1rKOrCVUMDpVbc2n9FYHCBt8BGk3r59L6x2CFoEvwQefa39+vvzn5wMCAlAJcwgIDOfFLDZ13bs0RPZYe7A5hAvmNz+DU4+PjUgxEOcHgt0wc1L9D4Iu5o9WEcl8FXH5Di3Fi0gEjeYrDkDoYSbBEI2mIw4UOb5dqmoS0C3bqvRW/+xf3VsilIfW/kpmZmcgRjNzpI+cPUR6Ul5fnmsfddmNjI3JpKJqbm0ufKBkws+K0RBVhj8lInLzHpKSG6upqdWqaBsoXDSRpiYSOhliHNQg21mXdeUEmOYNFU3y0TG+J1Poyq7323UpiWsIN4Zcy/KH4/8/md/Gjyf0BHgt8f6ccKBEAAAAASUVORK5CYII=';

const maxFileSizeInMb = 5;
const maxAppNameLength = 200;
const acceptedFileTypes = [FileExt.JPEG, FileExt.PNG, FileExt.JPG];

const tableHeaderKeyValueFields = {
  displayName: {
    label: 'Display Name',
  },
  url: {
    label: 'URL',
  },
  action: {
    label: 'Action',
  },
  icon: {
    label: 'Icon',
  },
};

interface IClonedProjectApp {
  id: number;
  tempId: string;
  displayName: string;
  url: string;
  order: number;
  icon: string;
  type: 'ADD' | 'EDIT';
  errors: { displayName: string; url: string; icon: string };
}

interface IProjectAppsProps {
  projectNumber: string;
}

const ProjectApps: FC<IProjectAppsProps> = ({ projectNumber }) => {
  const [editMode, setEditMode] = useState(false);
  const [apps, setApps] = useState<ProjectApp[]>([]);
  const [clonedApps, setClonedApps] = useState<IClonedProjectApp[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [displayNameSort, setDisplayNameSort] = useState<SortType>('none');

  const loadApps = useCallback(async () => {
    if (!projectNumber) return;

    const result = await getProjectApps(projectNumber);
    setApps(result);
    setClonedApps([
      ...result.map((m) => {
        return {
          id: m.id,
          tempId: uuidv4(),
          displayName: m.title,
          url: m.url,
          order: m.order,
          icon: m.image,
          type: 'EDIT' as const,
          errors: { displayName: '', url: '', icon: '' },
        };
      }),
    ]);
  }, [projectNumber]);

  useEffect(() => {
    loadApps();
  }, [loadApps]);

  const getHeaders = () => {
    return [
      {
        label: tableHeaderKeyValueFields.displayName.label,
        style: { width: editMode ? '30%' : '25%' },
        ...(editMode ? { sort: displayNameSort, onSort: (sort) => handleSortApps(sort as SortType) } : {}),
      },
      {
        label: tableHeaderKeyValueFields.url.label,
        style: { width: editMode ? '50%' : '60%' },
      },
      {
        label: tableHeaderKeyValueFields.icon.label,
        style: { width: editMode ? '10%' : '15%' },
      },
      ...(editMode
        ? [
            {
              label: tableHeaderKeyValueFields.action.label,
              style: { width: '10%' },
            },
          ]
        : []),
    ];
  };

  const getColumnWidth = (column: string) => {
    return getHeaders().find((f) => f.label === column)?.style.width;
  };

  const toBase64 = async (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const handleCancel = () => {
    setClonedApps([
      ...apps.map((m) => {
        return {
          id: m.id,
          tempId: uuidv4(),
          displayName: m.title,
          url: m.url,
          order: m.order,
          icon: m.image,
          type: 'EDIT' as const,
          errors: { displayName: '', url: '', icon: '' },
        };
      }),
    ]);
    setDisplayNameSort('none');
    setEditMode(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateProjectApps({
        projectNumber: projectNumber,
        projectApps: [
          ...clonedApps.map((m) => {
            return {
              id: m.type === 'ADD' ? 0 : m.id,
              title: m.displayName,
              url: m.url,
              order: m.order,
              image: m.icon,
            };
          }),
        ],
      });
      setDisplayNameSort('none');
      await loadApps();
      LayoutStore.displayToast('success', 'Apps have been successfully saved.');
    } catch {
      LayoutStore.displayToast('error', 'Apps can not be saved.');
    }

    setIsSaving(false);
    setEditMode(false);
  };

  const handleSetAppData = (value: string, tempId: string, fieldName: string, validationCallback: () => string) => {
    const current = clonedApps.find((f) => f.tempId === tempId);
    if (current) {
      current[fieldName] = value;
      current.errors[fieldName] = validationCallback();
      setClonedApps([...clonedApps]);
    }
  };

  const handleAddNewApp = () => {
    const sorted = sort([...clonedApps], 'order', SortTypes.DESC);
    const maxOrder = sorted.length ? sorted[0].order : 0;
    const newApp: IClonedProjectApp = {
      id: 0,
      tempId: uuidv4(),
      displayName: '',
      url: '',
      icon: defaultIcon,
      type: 'ADD' as const,
      errors: {
        displayName: 'Please input the app’s name.',
        url: 'Please input app’s URL.',
        icon: 'Please upload the app’s icon.',
      },
      order: maxOrder + 1,
    };
    clonedApps.push(newApp);
    setClonedApps([...clonedApps]);
  };

  const hasErrors = () => {
    return clonedApps.some((s) => s.errors.displayName || s.errors.url || s.errors.icon);
  };

  const handleUploadIcon = async (file: File, tempId: string) => {
    const maxFileSize = maxFileSizeInMb * 1024 * 1024;
    const result =
      file.size <= maxFileSize &&
      acceptedFileTypes.some((s) => s.toLowerCase() === getFileExtension(file.name)?.toLowerCase())
        ? await toBase64(file)
        : '';

    handleSetAppData(result as string, tempId, 'icon', () => {
      if (!acceptedFileTypes.some((s) => s.toLowerCase() === getFileExtension(file.name)?.toLowerCase()))
        return 'Support image file only. Please choose another file to upload.';

      if (file.size > maxFileSize)
        return `The icon only supports ${maxFileSizeInMb}MB. Please choose another file to upload`;

      return !result ? 'Please input the app’s icon.' : '';
    });
  };

  const renderActions = () => {
    return (
      <>
        {editMode ? (
          <div className={Style.actionButtonsWrapper}>
            <SecondaryButton disabled={isSaving} onClick={handleCancel}>
              Cancel
            </SecondaryButton>
            <PrimaryButton disabled={hasErrors()} loading={isSaving} onClick={async () => await handleSave()}>
              Save
            </PrimaryButton>
          </div>
        ) : (
          <PrimaryIconButton
            icon="edit"
            size="medium"
            className={Style.actionButton}
            onClick={() => setEditMode(true)}></PrimaryIconButton>
        )}
      </>
    );
  };

  const renderFileInput = (m: IClonedProjectApp) => {
    return (
      <label className={Style.iconWrapper}>
        {editMode && m.errors.icon ? (
          <Tooltip defaultUp={false} cssClass={Style.tooltipError} show={m.errors.icon}>
            <img
              className={classNames(Style.icon, Style.cursor)}
              role="presentation"
              src={m.icon}
              alt={m.displayName}
            />
          </Tooltip>
        ) : (
          <img
            className={classNames(Style.icon, [editMode, Style.cursor])}
            role="presentation"
            src={m.icon}
            alt={m.displayName}
          />
        )}
        {editMode && (
          <input
            accept={acceptedFileTypes.map((m) => `.${m.toLowerCase()}`).join(',')}
            className={Style.fileSelection}
            type="file"
            onChange={async (event) => {
              const file = event.target.files?.item(0);
              if (!file) return;
              await handleUploadIcon(file, m.tempId);
            }}
          />
        )}
      </label>
    );
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const source = clonedApps[result.source.index];
    const destination = clonedApps[result.destination.index];

    const sourceOrder = source.order;
    const destinationOrder = destination.order;

    source.order = destinationOrder;
    destination.order = sourceOrder;

    const sorted = sort([...clonedApps], 'order', SortTypes.ASC);
    setClonedApps([...sorted]);
  };

  const handleSortApps = (sortType: SortType) => {
    setDisplayNameSort(sortType);
    const sorted = sort(
      [...clonedApps],
      'displayName',
      sortType === 'asc' ? SortTypes.ASC : SortTypes.DESC
    ) as IClonedProjectApp[];
    sorted.forEach((f, i) => (f.order = +i));
    setClonedApps([...sorted]);
  };

  const renderAppRow = (m: IClonedProjectApp) => {
    return (
      <>
        <div
          style={{ width: getColumnWidth(tableHeaderKeyValueFields.displayName.label) }}
          className={classNames(Style.row, [!editMode, Style.textOverflow])}>
          {editMode ? (
            <>
              <Icon name="drag_indicator" />
              <FormInput
                value={m.displayName}
                placeholder="Enter display name..."
                error={m.errors.displayName}
                onChange={(value) => {
                  handleSetAppData(value, m.tempId, 'displayName', () => {
                    if (!value) return 'Please input the app’s name.';
                    if (value.length > maxAppNameLength)
                      return `App’s name can not exceed ${maxAppNameLength} characters.`;
                    return '';
                  });
                }}
              />
            </>
          ) : (
            m.displayName
          )}
        </div>
        <div
          style={{ width: getColumnWidth(tableHeaderKeyValueFields.url.label) }}
          className={classNames(Style.row, [!editMode, Style.textOverflow])}>
          {editMode ? (
            <FormInput
              value={m.url}
              placeholder="Enter URL..."
              error={m.errors.url}
              onChange={(value) => {
                handleSetAppData(value, m.tempId, 'url', () => {
                  if (!value) return 'Please input the app’s URL.';
                  return '';
                });
              }}
            />
          ) : (
            m.url
          )}
        </div>
        <div style={{ width: getColumnWidth(tableHeaderKeyValueFields.icon.label) }} className={Style.row}>
          {renderFileInput(m)}
        </div>
        {editMode && (
          <div style={{ width: getColumnWidth(tableHeaderKeyValueFields.action.label) }} className={Style.row}>
            <PrimaryIconButton
              icon="delete"
              onClick={() => {
                setClonedApps([...clonedApps.filter((f) => f.tempId !== m.tempId)]);
              }}
              disabled={!editMode}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Grid row md={12} cssClass={Style.gridGroupWrapper}>
        <div className={classNames(Style.header, Style.settingGroup)}>
          <span>Project Apps</span>
          {renderActions()}
        </div>
        <p>Create custom shortcut app which can be linked to any URL.</p>
      </Grid>
      <Grid item md={12} cssClass={Style.gridTableWrapper}>
        <Table headers={getHeaders()} />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" isDropDisabled={!editMode}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={Style.droppableWrapper}>
                {clonedApps.map((m, i) => (
                  <Draggable draggableId={m.tempId} index={i} key={m.tempId} isDragDisabled={!editMode}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={Style.appRowWrapper}>
                        {renderAppRow(m)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {editMode && (
          <Button
            type="text"
            cssClass={Style.addNewAppButton}
            icon="add"
            label="Add New App"
            onClick={handleAddNewApp}
          />
        )}
      </Grid>
    </>
  );
};

export default observer(ProjectApps);
