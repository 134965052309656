import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskTeamUserTeamChart } from './interface/TeamChart.interface';
import TaskTeamUserItem from './TaskTeamUserItem';

interface ITaskTeamUserProps {
  taskTeamUser: ITaskTeamUserTeamChart;
}

const TaskTeamUser: FC<ITaskTeamUserProps> = (props) => {
  const { taskTeamUser } = props;
  const [taskTeamUsers, setTaskTeamUsers] = useState(taskTeamUser.taskTeamUsers);

  useEffect(() => {
    if (taskTeamUser.isFilterApprovers) {
      const filterApprovers = [...taskTeamUser.taskTeamUsers.filter((y) => !!y.taskTeamRoleIds.length)];
      setTaskTeamUsers(filterApprovers);
    } else {
      setTaskTeamUsers(taskTeamUser.taskTeamUsers);
    }
  }, [taskTeamUser.isFilterApprovers, taskTeamUser.taskTeamUsers]);

  return (
    <div>
      {taskTeamUsers.map((user) => (
        <TaskTeamUserItem
          key={`TTU-${taskTeamUser.deliveryTeamId}-${taskTeamUser.taskTeamId}-${user.id}`}
          deliveryTeamId={taskTeamUser.deliveryTeamId}
          taskTeamId={taskTeamUser.taskTeamId}
          user={user}
        />
      ))}
    </div>
  );
};
export default observer(TaskTeamUser);
