import { serverAxios } from '../api';

export interface IEditDistributionListRequest {
  listId: number;
  projectNumber: string;
  title: string;
}

export async function editDistributionList(
  request: IEditDistributionListRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return await serverAxios.post('api/um/distributionlist/update', request, {
    signal: abortSignal,
  });
}
