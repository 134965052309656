import React, { useEffect, useState } from 'react';
import { ISuggestion } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import NavBarSelectorStore, { IItem } from './NavBarSelectorStore';
import NavBarSelectorProject from './NavBarSelectorProject';
import NavBarSelectorProgramme from './NavBarSelectorProgramme';
import { sortBy } from 'lodash';
import TopNavBarSelector from '../../shared/TopNavBarSelector';

interface INavBarSelectorProp {
  disable: boolean;
}

const getItemsSearchBox = (items: IItem[]): ISuggestion[] => {
  return items.map((item) => {
    if (item.programme)
      return { id: item.id, value: item.id, display: <NavBarSelectorProgramme title={item.programme.title} /> };
    return { id: item.id, value: item.id, display: <NavBarSelectorProject item={item} /> };
  });
};

const NavBarSelector: React.FC<INavBarSelectorProp> = (props) => {
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);

  useEffect(() => {
    NavBarSelectorStore.initSelector();
    setSuggestions(getItemsSearchBox(NavBarSelectorStore.items));
  }, []);

  const handleOnSelectItem = (id: string | number): void => {
    NavBarSelectorStore.setSelectedSelectorItem(id.toString());
  };

  const onChangeSearchBox = (searchText = '') => {
    searchText = searchText.toLowerCase();
    if (!searchText) {
      setSuggestions(getItemsSearchBox(NavBarSelectorStore.items));
      return;
    }
    const programme = NavBarSelectorStore.items.filter(
      (prm) =>
        prm.programme &&
        (prm?.programme?.title?.toLowerCase()?.includes(searchText) ||
          prm?.programme?.projects.some((prj) =>
            `${prj.projectNumber} ${prj.title}`?.toLowerCase()?.includes(searchText)
          ))
    );

    const projects = NavBarSelectorStore.items.filter(
      (prj) =>
        prj.project &&
        `${prj?.project?.programmeTitle} ${prj?.project?.projectNumber} ${prj?.project?.title}`
          ?.toLowerCase()
          ?.includes(searchText)
    );

    const data = getItemsSearchBox(sortBy([...programme, ...projects]));
    setSuggestions(data);
  };

  return (
    <TopNavBarSelector
      disable={props.disable}
      handleOnSelectItem={handleOnSelectItem}
      suggestions={suggestions}
      onChangeSearchBox={onChangeSearchBox}
      selectedItemId={NavBarSelectorStore.selectedItem?.id}
    />
  );
};

export default observer(NavBarSelector);
