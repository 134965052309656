import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import { FileContainerLockMessage, FileContainerLockReason } from '../../../common/enums/FileContainerLock';
import Icon from '../../shared/Icon';

export interface IFileContainerLockIconProps {
  isLocked: boolean;
  lockedReason: string;
  isLockedByUnknownReason: boolean;
  className?: string;
  tootTipClassName?: string;
}

const FileContainerLockIcon: FC<IFileContainerLockIconProps> = ({
  lockedReason,
  isLocked,
  isLockedByUnknownReason,
  className,
  tootTipClassName,
}) => {
  if (!isLocked) return null;

  const lockedReasonMessage = (lockedReason: string, lockedByUnknownReason: boolean) => {
    if (lockedReason && FileContainerLockMessage[lockedReason.toLocaleLowerCase()])
      return FileContainerLockMessage[lockedReason.toLocaleLowerCase()];

    if (!isNaN(parseInt(lockedReason)) && !lockedByUnknownReason)
      return FileContainerLockMessage[FileContainerLockReason.Workflow];

    if (lockedByUnknownReason) return FileContainerLockMessage[FileContainerLockReason.Other];
    return '';
  };

  return (
    <Tooltip
      cssClass={tootTipClassName}
      show={lockedReasonMessage(lockedReason ?? '', isLockedByUnknownReason ?? false)}>
      <Icon className={className} name="lock" />
    </Tooltip>
  );
};

export default observer(FileContainerLockIcon);
