import { serverAxios } from '../api';

export async function deleteProject(projectNumber: string, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.delete(`/api/config/projects/delete`, {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data;
}
