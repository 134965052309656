import { serverAxios } from '../api';
import { IUpdateProjectVisualisationModel } from './models/projectModel';

export async function updateProjectVisualisation(
  request: IUpdateProjectVisualisationModel,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.put('api/config/projects/updatevisualisation', request, {
    signal: abortSignal,
  });
  return result.data;
}
