import * as React from 'react';
import classNames from 'classnames';

export interface ITextProps {
  /** Type of the text element */
  type?:
    | 'display1'
    | 'display2'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 's1'
    | 's2'
    | 'n1'
    | 'n2'
    | 'cell'
    | 'caption'
    | 'meta'
    | 'p';

  /** Custom css class */
  cssClass?: string;

  /** Style property to be passed to the component */
  style?: React.CSSProperties;

  /** Child elements */
  children?: React.ReactNode;
}

/**
 * Usage:
 * Fewer options are often better, less is more or as Dieter Rams would say 'less but better'. The Aurecon Design Kit embodies this ethos. The size and weight of text styles are predetermined to ensure readability at all sizes. You should find every style you need in this guide.
 */
const Text: React.FC<ITextProps> = (props) => {
  const textClass = classNames({
    [`${props.type}`]: props.type,
    [`${props.cssClass}`]: props.cssClass,
  });

  return (
    <p className={textClass} style={props.style}>
      {props.children}
    </p>
  );
};

export default Text;
