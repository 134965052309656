import { serverAxios } from '../api';

export interface IReactiveTaskTeamRequest {
  taskTeamId: number;
  projectNumber: string;
}

export async function reactiveTaskTeam(request: IReactiveTaskTeamRequest, abortSignal?: AbortSignal): Promise<void> {
  return await serverAxios.put('api/um/taskteam/reactive', request, {
    signal: abortSignal,
  });
}
