import { IPagedResponse } from '../../../../common/models/IPagedResponse';

export class GetTemporaryAccessLogRequest {
  projectNumber?: string;
  page?: number;
  pageSize?: number;
  title?: string;
  sortColumn?: string | null;
  sortDirection?: string | null;

  setConditions(page: number, pageSize: number, projectNumber?: string, title?: string): this {
    this.projectNumber = projectNumber;
    this.page = page;
    this.pageSize = pageSize;
    this.title = title;
    return this;
  }
  setSort(sortColumn?: string | null, sortDirection?: string | null): this {
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
    return this;
  }
}

export interface ITemporaryFileAccessLog {
  id: number;
  fileContainerId: number;
  fileContainerTitle: string;
  sharedToUser: string;
  taskTeamOriginator: string;
  deliveryTeamTitle: string;
  fileContainerState: string;
  grantedByUser: string;
  grantedDate: Date;
  expiryDate: Date;
  status: boolean;
  totalRecords: number;
}

export interface ITemporaryFileAccessLogResult {
  pageResult: IPagedResponse<ITemporaryFileAccessLog>;
}

export interface IRevokeTemporaryAccessRequest {
  projectNumber: string;
  id: number;
}
