const getFileExtension = (fileName: string | string[]) => {
  if (!Array.isArray(fileName)) {
    const checkExtension = fileName.split('.').length > 1;
    return checkExtension ? fileName.split('.').pop() : null;
  }

  for (const f of fileName) {
    const checkExtension = f.split('.').length > 1;
    return checkExtension ? f.split('.').pop() : null;
  }
};

export default getFileExtension;
