import { serverAxios } from '../api';

export interface IReactiveDeliveryTeamRequest {
  projectNumber: string;
  deliveryTeamId: number;
}

export async function reactiveDeliveryTeam(
  request: IReactiveDeliveryTeamRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return await serverAxios.put('api/um/deliveryteam/reactive', request, {
    signal: abortSignal,
  });
}
