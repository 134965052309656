import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/UserCircleCollection.module.scss';
import UserTooltip from './UserTooltip';

export interface IUserCircleCollectionProps {
  users: { id: number; initials: string; name?: string; email?: string }[];
}

const UserCircleCollection: FC<IUserCircleCollectionProps> = ({ users }) => {
  return (
    <span className={Style.users}>
      {users.map((user) => (
        <UserTooltip key={user.id} initials={user.initials} name={user.name} email={user.email} />
      ))}
    </span>
  );
};

export default observer(UserCircleCollection);
