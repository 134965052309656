import { TabContent } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import GoBackButton from '../shared/GoBackButton';
import PageHeading from '../shared/PageHeading';
import Styles from '../transmittals/styles/CreateTransmittal.module.scss';
import FilesStore from '../files/FilesStore';
import PageTab from '../shared/PageTab';
import TabContainer from '../files/TabContainer';
import { ContentSelection } from '../files/ContentSelection';
import AppStore from '../../stores/AppStore';
import { IFileContainer } from '../../api/authenticated/cms/FileContainerModel';
import { FromModuleEnum } from '../../enums/FromModuleEnum';

export interface ICreateTransmittalFileProps {
  isOpenFiles: boolean;
  setIsOpenFiles: (value: boolean) => void;
  onAddFilesToTransmittal: (files: IFileContainer[]) => void;
  onCancelAddFilesToTransmittal: () => void;
}

const CreateTransmittalFile: FC<ICreateTransmittalFileProps> = (props) => {
  useEffect(() => {
    FilesStore.setSelectedTabId(2);
  }, []);

  const handleSetIsOpenFiles = (value: boolean): void => {
    props.setIsOpenFiles(value);
  };

  const onAddFilesToTransmittal = (files: IFileContainer[]) => {
    props.onAddFilesToTransmittal(files);
  };

  const onCancelAddFilesToTransmittal = () => {
    props.onCancelAddFilesToTransmittal();
  };

  const onSelectTab = async (id: number) => {
    let contentSelectionId = 0;

    if (id === 2) contentSelectionId = ContentSelection.Shared;
    else if (id === 3) contentSelectionId = ContentSelection.Published;

    FilesStore.applyFileFilterForTransmittals(
      contentSelectionId,
      AppStore.selectedProjectNumber,
      AppStore.selectedTaskTeamId
    );

    FilesStore.setSelectedSection(contentSelectionId);
    await FilesStore.getFilesTaskTeamOriginator(FilesStore.fileContainerStateId);

    FilesStore.setSelectedTabId(id);
  };

  if (!props.isOpenFiles) return null;

  return (
    <>
      <GoBackButton
        label="Edit Transmittal"
        onClick={() => {
          handleSetIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
        }}
      />
      <div className={Styles.heading}>
        <PageHeading>Content Files</PageHeading>
      </div>
      <PageTab
        activeTab={FilesStore.selectedTabId}
        tabs={[
          { id: 2, label: 'Shared' },
          { id: 3, label: 'Published' },
        ]}
        onSelectTab={onSelectTab}>
        {FilesStore.tabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <TabContainer
              fromModule={FromModuleEnum.TRANSMITTALS}
              isOpenFiles={props.isOpenFiles}
              onAddFilesToTransmittal={onAddFilesToTransmittal}
              onCancelAddFilesToTransmittal={onCancelAddFilesToTransmittal}
            />
          </TabContent>
        ))}
      </PageTab>
    </>
  );
};

export default observer(CreateTransmittalFile);
