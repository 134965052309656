import { serverAxios } from '../api';

export async function deleteClientFileTemplate(id: number, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.delete(`/api/config/client/fileTemplate/delete`, {
    params: {
      id: id,
    },
    signal: abortSignal,
  });
  return result.data;
}
