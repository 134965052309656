import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/Overlay.module.scss';

interface IOverlayProps {
  className?: string;
}

const Overlay: FC<IOverlayProps> = ({ className }) => {
  return <div className={classNames(Style.overlay, className)} />;
};

export default observer(Overlay);
