import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/PdfViewer.module.scss';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import { useLocation } from 'react-router-dom';
import { RenderViewer, Viewer, Worker, Plugin } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { IFileContainer } from '../../api/authenticated/cms/FileContainerModel';
import PrimaryButton from './PrimaryButton';

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props;
  return {
    renderViewer: (renderProps: RenderViewer) => {
      const { slot } = renderProps;

      if (!slot.subSlot) return slot;

      slot.children = PageThumbnail;

      // Reset the sub slot
      slot.subSlot.attrs = {};
      slot.subSlot.children = <></>;

      return slot;
    },
  };
};

interface IPdfViewerProps {
  isOpeningPdf: boolean;
  projectNumber?: string;
  file?: IFileContainer | null;
  handleOpenFile?: () => void;
}

const PdfViewer: FC<IPdfViewerProps> = ({ isOpeningPdf, file, projectNumber, handleOpenFile }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fileUrl, setFileUrl] = useState<string>('');

  const thumbnailPluginInstance = thumbnailPlugin({ thumbnailWidth: 300 });
  const { Cover } = thumbnailPluginInstance;

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const transmittalId = isOpeningPdf ? params.get('transmittalId') : '';
    const transmittalMessageId = isOpeningPdf ? params.get('transmittalMessageId') : '';
    const prjNumber = isOpeningPdf ? params.get('projectNumber') ?? '' : projectNumber ?? '';
    const fileContainerId = isOpeningPdf ? (Number(params.get('id')) as number | null) ?? 0 : file?.id;
    const fileRevisionId = isOpeningPdf
      ? (Number(params.get('fileRevisionId')) as number | null) ?? 0
      : file?.fileRevisionId;
    const containerFileId = isOpeningPdf
      ? (Number(params.get('containerFileId')) as number | null) ?? 0
      : file?.containerFileId;
    const releasedFileId = isOpeningPdf
      ? (Number(params.get('releasedFileId')) as number | null) ?? 0
      : file?.releasedFileId;

    const downloadFile = async () => {
      setIsLoading(true);

      const response = await getProjectFileDownloadUrl({
        projectNumber: prjNumber,
        transmittalId: transmittalId ? (Number(transmittalId) as number | null) : null,
        transmittalMessageId: transmittalMessageId ? (Number(transmittalMessageId) as number | null) : null,
        downloadFiles: [
          {
            fileContainerId: fileContainerId ?? 0,
            fileRevisionId: fileRevisionId ?? 0,
            containerFileId: containerFileId ?? 0,
            releasedFileId: releasedFileId ?? 0,
          },
        ],
      });

      if (response[0]?.url && !response[0].errorMessage) setFileUrl(response[0].url);
      setIsLoading(false);
    };

    downloadFile();
  }, [location, isOpeningPdf, file, projectNumber]);

  const pageThumbnailPluginInstance = pageThumbnailPlugin({
    PageThumbnail: <Cover getPageIndex={() => 0} />,
  });

  const renderLoader = () => <div className={Style.previewLoader}>Loading...</div>;
  const renderSystemLoader = () => (
    <div className={Style.loader}>
      <Loader label="Loading PDF file..." />
    </div>
  );

  const renderContent = () => {
    if (isLoading || !fileUrl) {
      return isOpeningPdf ? renderSystemLoader() : renderLoader();
    }

    return (
      <Worker workerUrl="/pdf.worker.min.js">
        {!isOpeningPdf && (
          <PrimaryButton className={Style.button} onClick={handleOpenFile}>
            Open File
          </PrimaryButton>
        )}
        <Viewer
          fileUrl={fileUrl}
          transformGetDocumentParams={(options) => ({ ...options, isEvalSupported: false })}
          plugins={
            isOpeningPdf ? [defaultLayoutPluginInstance] : [thumbnailPluginInstance, pageThumbnailPluginInstance]
          }
        />
      </Worker>
    );
  };

  return (
    <div className={`${Style.modalBody} ${!isOpeningPdf ? Style.noScrollbars : ''}`}>
      <div className={Style.pdfContainer}>{renderContent()}</div>
    </div>
  );
};

export default observer(PdfViewer);
