import { serverAxios } from '../api';
import { ITaskTeamUserByDeliveryTeam } from './interfaces/user.interface';

export async function getTaskTeamUsersDeliveryTeam(
  projectNumber: string,
  deliveryTeamId: number,
  abortSignal?: AbortSignal
): Promise<ITaskTeamUserByDeliveryTeam[]> {
  const result = await serverAxios.get<{ users: ITaskTeamUserByDeliveryTeam[] }>(
    'api/um/system/taskteamusersbydeliveryteam',
    {
      params: { projectNumber, deliveryTeamId },
      signal: abortSignal,
    }
  );
  return result.data.users;
}
