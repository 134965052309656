import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from './styles/AddTransmittalMessageActions.module.scss';
import ActionBar from '../../shared/ActionBar';
import ActionBarSection from '../../shared/ActionBarSection';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import AddTransmittalMessageStore from './AddTransmittalMessageStore';
import LayoutStore from '../../layout/LayoutStore';
import AddTransmittalErrorModal from './AddTransmittalErrorModal';
import AddTransmittalUnsavedModal from './AddTransmittalUnsavedModal';
import { Pages } from '../../../common/constants/Pages';
import { useNavigate } from 'react-router-dom';
import DropDownMenu from '../../shared/DropDownMenu';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import AppStore from '../../../stores/AppStore';
import { ContentSelection } from '../../files/ContentSelection';
import FilesStore from '../../files/FilesStore';
import NavBarSelectorStore from '../../transmittals/navBarSelector/NavBarSelectorStore';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';

const AddTransmittalMessageActions: FC = () => {
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);

  const handleTransmit = async () => {
    await AddTransmittalMessageStore.onSubmitTransmittalResponse();
    if (AddTransmittalMessageStore.errorMessage !== null) setShowErrorModal(true);
    else {
      LayoutStore.displayToast(
        'success',
        `Transmittal ${AddTransmittalMessageStore.transmittalResponse?.transmittalTitle} has been responded to successfully.`
      );
      navigate(
        `${Pages.Transmittals.Route}/${AddTransmittalMessageStore.transmittal?.title}/${NavBarSelectorStore.selectedItem?.project?.projectNumber}`
      );
    }
  };

  const handleCancel = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_CANCEL);

    if (!AddTransmittalMessageStore.transmittal) {
      setShowErrorModal(true);
    } else if (AddTransmittalMessageStore.hasUnsavedChanges) setShowUnsavedModal(true);
    else
      navigate(
        `${Pages.Transmittals.Route}/${AddTransmittalMessageStore.transmittal.title}/${NavBarSelectorStore.selectedItem?.project?.projectNumber}`
      );
  };

  const addSupportingFiles = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_ADD_CONTENT_SUPPORTING_FILE);
    AddTransmittalMessageStore.toggleUploadFileModal(true);
  };

  const addContentFiles = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_ADD_CONTENT_SUPPORTING_FILE);

    AddTransmittalMessageStore.setIsOpenFiles(true);
    AppStore.setDisableNavigation(true);
    FilesStore.cleanup();
    FilesStore.applyFileFilterForTransmittals(
      ContentSelection.Shared,
      AppStore.selectedProjectNumber,
      AppStore.selectedTaskTeamId
    );
    FilesStore.setSelectedSection(ContentSelection.Shared);
  };

  return (
    <>
      <ActionBar className={Styles.actionBar}>
        <ActionBarSection>
          <PrimaryButton onClick={handleTransmit} disabled={AddTransmittalMessageStore.disableTransmitButton}>
            Transmit
          </PrimaryButton>
          {(!AppStore.isProjectExternalUser || AppStore.clientEnableSupportingFiles) && (
            <div className={Styles.expandBtn}>
              <DropDownMenu
                label="Add"
                disabled={AddTransmittalMessageStore.disableTransmitButton}
                icon="expand_more"
                iconClassName={Styles.expandBtnIcon}
                itemsContainerClassName={Styles.itemsContainer}
                menuClassName={Styles.btnMenu}>
                {!AppStore.isProjectExternalUser && (
                  <DropDownMenuButton
                    disabled={AddTransmittalMessageStore.disableTransmitButton}
                    onClick={addContentFiles}>
                    Content Files
                  </DropDownMenuButton>
                )}
                {AppStore.clientEnableSupportingFiles && (
                  <DropDownMenuButton
                    disabled={AddTransmittalMessageStore.disableTransmitButton}
                    className={Styles.btnMenuUploadBtn}
                    onClick={addSupportingFiles}>
                    Supporting Files
                  </DropDownMenuButton>
                )}
              </DropDownMenu>
            </div>
          )}
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        </ActionBarSection>
      </ActionBar>

      {showErrorModal && (
        <AddTransmittalErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorMessage={AddTransmittalMessageStore.errorMessage ?? undefined}
        />
      )}
      {showUnsavedModal && <AddTransmittalUnsavedModal closeModal={() => setShowUnsavedModal(false)} />}
    </>
  );
};

export default observer(AddTransmittalMessageActions);
