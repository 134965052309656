import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from './styles/CreateTransmittalActions.module.scss';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import CreateTransmittalStore from './CreateTransmittalStore';
import LayoutStore from '../layout/LayoutStore';
import CreateTransmittalErrorModal from './CreateTransmittalErrorModal';
import { Pages } from '../../common/constants/Pages';
import { useNavigate } from 'react-router-dom';
import FilesStore from '../files/FilesStore';
import { ContentSelection } from '../files/ContentSelection';
import AppStore from '../../stores/AppStore';
import DropDownMenu from '../shared/DropDownMenu';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import ConfirmationModal from '../shared/ConfirmationModal';
import SupportingFilesUploadStore from './supportingFilesUpload/SupportingFilesUploadStore';
import { FromModuleEnum } from '../../enums/FromModuleEnum';
import { appInsightsTrackEvent } from '../../utils/appInsights';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';

const CreateTransmittalActions: FC = () => {
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const handleTransmit = async () => {
    await CreateTransmittalStore.onSavingTransmittal();
    if (CreateTransmittalStore.errorMessage) setShowErrorModal(true);
    else {
      LayoutStore.displayToast(
        'success',
        `Transmittal ${CreateTransmittalStore.transmittalTitle} has been created successfully.`
      );
      navigate(`${Pages.Transmittals.Route}`);
    }
  };

  const handleCancel = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_CANCEL);
    setOpenWarningModal(true);
  };

  const addSupportingFiles = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_ADD_CONTENT_SUPPORTING_FILE);
    CreateTransmittalStore.toggleUploadFileModal(true);
  };

  const addContentFiles = async () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_ADD_CONTENT_SUPPORTING_FILE);

    CreateTransmittalStore.setIsOpenFiles(true);
    AppStore.setDisableNavigation(true);
    FilesStore.cleanup();
    FilesStore.applyFileFilterForTransmittals(
      ContentSelection.Shared,
      AppStore.selectedProjectNumber,
      AppStore.selectedTaskTeamId
    );
    FilesStore.setSelectedSection(ContentSelection.Shared);
    FilesStore.setFromModule(FromModuleEnum.TRANSMITTALS);

    await FilesStore.getFilesTaskTeamOriginator(FilesStore.fileContainerStateId);
  };

  return (
    <>
      <ActionBar className={Styles.actionBar}>
        <ActionBarSection>
          <PrimaryButton onClick={handleTransmit} disabled={CreateTransmittalStore.disableTransmitButton}>
            Transmit
          </PrimaryButton>
          {(!AppStore.isProjectExternalUser || AppStore.clientEnableSupportingFiles) && (
            <div className={Styles.expandBtn}>
              <DropDownMenu
                label="Add"
                disabled={CreateTransmittalStore.disableTransmitButton}
                icon="expand_more"
                iconClassName={Styles.expandBtnIcon}
                itemsContainerClassName={Styles.itemsContainer}
                menuClassName={Styles.btnMenu}>
                {!AppStore.isProjectExternalUser && (
                  <DropDownMenuButton disabled={CreateTransmittalStore.disableTransmitButton} onClick={addContentFiles}>
                    Content Files
                  </DropDownMenuButton>
                )}
                {AppStore.clientEnableSupportingFiles && (
                  <DropDownMenuButton
                    disabled={CreateTransmittalStore.disableTransmitButton}
                    className={Styles.btnMenuUploadBtn}
                    onClick={addSupportingFiles}>
                    Supporting Files
                  </DropDownMenuButton>
                )}
              </DropDownMenu>
            </div>
          )}
          <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
        </ActionBarSection>
      </ActionBar>
      <ConfirmationModal
        showModal={openWarningModal}
        heading="Cancel Transmittal"
        message="You have unsaved changes. Are you sure you want to leave this page?"
        confirmText="Yes"
        cancelText="No"
        onCancel={() => setOpenWarningModal(false)}
        onConfirm={() => {
          setOpenWarningModal(false);
          SupportingFilesUploadStore.clear();
          CreateTransmittalStore.clear();

          navigate(`${Pages.Transmittals.Route}`);
        }}
      />
      {showErrorModal && (
        <CreateTransmittalErrorModal
          closeModal={() => setShowErrorModal(false)}
          errorCode={CreateTransmittalStore.errorCode ?? undefined}
          errorMessage={CreateTransmittalStore.errorMessage ?? undefined}
        />
      )}
    </>
  );
};

export default observer(CreateTransmittalActions);
