import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/UserTooltip.module.scss';
import UserCircle from '../shared/UserCircle';

export interface ITransmittalInitiatorProps {
  initials: string;
  name?: string;
  email?: string;
  userCircleCssClass?: string;
}

const UserTooltip: FC<ITransmittalInitiatorProps> = (props) => {
  return (
    <>
      {!props.name && !props.email ? (
        <div className={Style.initiatorTooltipItem}>
          <UserCircle cssClass={props.userCircleCssClass} initials={props.initials} />
        </div>
      ) : (
        <Tooltip
          show={
            <div className={Style.initiatorTooltip}>
              {props.name && <div className={Style.userName}>{props.name}</div>}
              {props.email && <div className={Style.userEmail}>{props.email}</div>}
            </div>
          }
          defaultUp={false}>
          <div className={Style.initiatorTooltipItem}>
            <UserCircle cssClass={props.userCircleCssClass} initials={props.initials} />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default observer(UserTooltip);
