export const SystemSettingsPath = {
  File: 'metadata/file',
  TableList: 'metadata/file/tablelist',
  BulkUpload: 'metadata/file/bulkupload',
  WorkInprogress: 'metadata/file/tablelist/workinprogress',
  Shared: 'metadata/file/tablelist/shared',
  Published: 'metadata/file/tablelist/published',
  Visualisation: 'metadata/file/visualisation',
  Transmittals: 'metadata/file/transmittals',
  Task: 'metadata/file/task',
  MetadataTransmittals: 'metadata/file/metadata_transmittals',
  Faq: 'support/faq',
  UserGuide: 'support/userguide',
};
