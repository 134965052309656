import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';
import { FileContainerFilter } from './FileContainerFilterClass';
import { IFileContainer } from './FileContainerModel';

export async function getWipFileContainers(
  condition: FileContainerFilter,
  abortSignal?: AbortSignal
): Promise<IPagedResponse<IFileContainer>> {
  const result = await serverAxios.post<{ files: IPagedResponse<IFileContainer> }>(
    'api/cms/wipFileContainers',
    condition,
    {
      signal: abortSignal,
    }
  );
  return result.data?.files ?? [];
}
