import { serverAxios } from '../api';

export interface IDuplicateFile {
  id: number;
}

export async function getDuplicateFiles(
  taskTeamId: number,
  fileContainerStateId: number,
  uploadedFileName: string,
  abortSignal?: AbortSignal
): Promise<IDuplicateFile[]> {
  const result = await serverAxios.get<{ duplicateFiles: IDuplicateFile[] }>('api/cms/getduplicatefiles', {
    params: {
      taskTeamId,
      fileContainerStateId,
      uploadedFileName,
    },
    signal: abortSignal,
  });
  return result.data?.duplicateFiles;
}
