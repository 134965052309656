import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/SeeMoreLink.module.scss';

export interface ISeeMoreLinkProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customizedStyle?: string;
  label?: string;
}

const SeeMoreLink: FC<ISeeMoreLinkProps> = ({ onClick, customizedStyle, label }) => {
  return (
    <Button cssClass={customizedStyle ?? Style.button} onClick={onClick} type="text" label={label ?? 'See More'} />
  );
};

export default observer(SeeMoreLink);
