import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SystemUserTable from './systemUserTable/SystemUserTable';
import Style from '../../../../styles/components/settings/systemAdminSettings/systemUsers/TabContainer.module.scss';
import { SystemUsersTabIds } from '../../../../common/enums/SystemUserTabs';
import SystemUsersStore from '../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import Loading from '../../../shared/Loading';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import { ISystemUser } from '../../../../api/authenticated/um/interfaces/user.interface';

export interface ITabContainerProps {
  tabId: SystemUsersTabIds;
}

const UserNameVariableText = 'UserName';
const modalTexts = {
  [SystemUsersTabIds.ACTIVE]: {
    heading: 'Deactivate User?',
    message: `This action will revoke the User’s access temporarily on Tucana. Are you sure you want to deactivate ${UserNameVariableText}?`,
  },
  [SystemUsersTabIds.DEACTIVE]: {
    heading: 'Reactivate User?',
    message: `This action will grant User’s access as a new user on Tucana where Admin will have to add them into projects and teams again. Are you sure you want to reactivate ${UserNameVariableText}?`,
  },
};

const removeUserModalText = {
  heading: 'Delete User?',
  message: `This action will revoke the User’s access permanently on Tucana. Are you sure you want to delete ${UserNameVariableText}?`,
};

const TabContainer: FC<ITabContainerProps> = (props) => {
  const { tabId } = props;
  const [openWarningDeactiveOrActiveUser, setOpenWarningDeactiveOrActiveUser] = useState(false);
  const [openWarningRemoveUser, setOpenWarningRemoveUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ISystemUser>();

  const handleOnConfirm = async () => {
    if (openWarningRemoveUser) {
      selectedUser && (await SystemUsersStore.RemoveUser(selectedUser.id));
      setOpenWarningRemoveUser(false);
    } else {
      selectedUser &&
        (await SystemUsersStore.deactiveOrReactiveUser(selectedUser.id, tabId === SystemUsersTabIds.ACTIVE));
      setOpenWarningDeactiveOrActiveUser(false);
    }
  };

  useEffect(() => {
    SystemUsersStore.loadTableColumns();
    SystemUsersStore.loadSystemUsers();

    return () => {
      SystemUsersStore.cleanup();
    };
  }, []);

  return (
    <div className={Style.container}>
      <Loading isLoading={SystemUsersStore.isLoading} label={SystemUsersStore.loadingLabel}></Loading>

      <div className={Style.content}>
        <SystemUserTable
          tableColumns={SystemUsersStore.getTableColumns}
          tableSystemUsers={SystemUsersStore.filteredSystemUsers}
          onDeactiveOrActiveUser={(user) => {
            setSelectedUser(user);
            setOpenWarningDeactiveOrActiveUser(true);
          }}
          onRemovingUser={(user) => {
            setSelectedUser(user);
            setOpenWarningRemoveUser(true);
          }}
          onSortColumnChange={(column, direction) => {
            SystemUsersStore.applySort(column, direction);
          }}
        />
      </div>
      <ConfirmationModal
        showModal={openWarningRemoveUser || openWarningDeactiveOrActiveUser}
        heading={openWarningRemoveUser ? removeUserModalText.heading : modalTexts[tabId].heading}
        message={
          openWarningRemoveUser
            ? removeUserModalText.message.replace(UserNameVariableText, selectedUser?.name || selectedUser?.email || '')
            : modalTexts[tabId].message.replace(UserNameVariableText, selectedUser?.name || selectedUser?.email || '')
        }
        confirmText="Yes"
        cancelText="No"
        loading={SystemUsersStore.isRemovingUser || SystemUsersStore.isDeactivatingOrReactivating}
        onConfirm={handleOnConfirm}
        onCancel={() =>
          openWarningRemoveUser ? setOpenWarningRemoveUser(false) : setOpenWarningDeactiveOrActiveUser(false)
        }
      />
    </div>
  );
};

export default observer(TabContainer);
