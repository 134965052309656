import { makeAutoObservable, runInAction } from 'mobx';
import { DownloadFile, getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import { getProjectFileDocumentViewerUrl } from '../../api/authenticated/cms/getProjectFileViewerUrl';
import { IMetadataField } from '../../api/authenticated/cms/getProjectMetadata';
import {
  IAttachmentFile,
  IReleasedFile,
  ITransmittalContentFile,
} from '../../api/authenticated/transmittals/getTransmittal';
import { getTransmittalFileDownloadUrl } from '../../api/authenticated/transmittals/getTransmittalFileDownloadUrl';
import { FileContainerState } from '../../common/enums/FileContainerState';
import { ContentSelection } from '../files/ContentSelection';
import FileInformationStore from '../files/fileInformation/FileInformationStore';
import NavBarSelectorStore from '../transmittals/navBarSelector/NavBarSelectorStore';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import { AxiosError } from 'axios';
import AppStore from '../../stores/AppStore';
import { IContainerFile, IFileContainer } from '../../api/authenticated/cms/FileContainerModel';
import FilesStore from '../files/FilesStore';

export class TransmittalDetailsFilesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public metadataFields: IMetadataField[] = [];
  public selectedSection: ContentSelection = ContentSelection.WIP;
  public fileBusy: { [filedId: number]: boolean } = {};

  public errorCode: number | null = null;
  public errorMessage: string | null = null;

  public mapToFile = (releasedFile: IReleasedFile, file?: IContainerFile) => {
    return {
      id: releasedFile.id,
      releasedFileId: releasedFile.releasedFileId,
      title: releasedFile.title,
      extension: file?.fileExtension ?? releasedFile.extension,
      originalFilename: file?.originalFilename ?? releasedFile.originalFilename,
      fileContainerStateId: releasedFile.fileContainerStateId,
      fieldValue1: releasedFile.fieldValue1,
      fieldValue2: releasedFile.fieldValue2,
      fieldValue3: releasedFile.fieldValue3,
      fieldValue4: releasedFile.fieldValue4,
      fieldValue5: releasedFile.fieldValue5,
      fieldValue6: releasedFile.fieldValue6,
      fieldValue7: releasedFile.fieldValue7,
      fieldValue8: releasedFile.fieldValue8,
      fieldValue9: releasedFile.fieldValue9,
      fieldValue10: releasedFile.fieldValue10,
      fieldValue11: releasedFile.fieldValue11,
      fieldValue12: releasedFile.fieldValue12,
      fieldValue13: releasedFile.fieldValue13,
      fieldValue14: releasedFile.fieldValue14,
      fieldValue15: releasedFile.fieldValue15,
      fieldValue16: releasedFile.fieldValue16,
      fieldValue17: releasedFile.fieldValue17,
      fieldValue18: releasedFile.fieldValue18,
      fieldValue19: releasedFile.fieldValue19,
      fieldValue20: releasedFile.fieldValue20,
      pattern1: null,
      pattern2: null,
      pattern3: null,
      isAPIMS: false,
      containerFiles: releasedFile.containerFiles,
      containerFileId: file?.containerFileId ?? releasedFile.containerFileId,
      taskTeamId: 0,
      uploadedSize: releasedFile.uploadedSize,
      uploadedByUserName: releasedFile.uploadedByUserName,
      modifiedDate: releasedFile.uploadedDate,
      createdDate: releasedFile.createdDate,
      formattedRevision: releasedFile.formattedRevision,
      suitabilityCode: releasedFile.suitabilityCode,
      isSuitabilityRefCode: releasedFile.isSuitabilityRefCode,
      suitabilityTitle: releasedFile.suitabilityTitle,
      fileRevisionId: releasedFile.fileRevisionId,
      inSharePoint: null,
      isLocked: false,
      isLockedByUnknownReason: false,
      lockedReason: null,
      isForgeFile: file?.isForgeFile,
      taskTypeId: null,
      uploadedByUserId: 0,
      canTaskTeamReview: false,
      canShareReview: false,
      canDeliveryTeamReview: false,
      canPublishReview: false,
      canCollaborate: false,
      canSubmitForge: file?.canSubmitForge,
      canDelete: false,
      canPublished: false,
      hasDocumentViewer: file?.hasDocumentViewer,
      lastModifiedUserName: null,
      releasedDate: null,
      revision: null,
      suitabilityId: null,
      deliveryTeamId: null,
      deliveryTeamTitle: releasedFile.deliveryTeamTitle,
      taskTeamTitle: releasedFile.taskTeamTitle,
      inWorkflow: false,
      sharePointReleasedFileId: file?.sharePointReleasedFileId,
    } as IFileContainer;
  };

  public async showFileInformation(fileContainer: IFileContainer) {
    if (!fileContainer.fileContainerStateId) return;

    const contentSelection = FilesStore.getContentSelection(fileContainer.fileContainerStateId);
    FilesStore.setSelectedSection(contentSelection);

    let fileContainerStateId: FileContainerState | null = null;

    if (contentSelection) this.selectedSection = contentSelection;
    switch (this.selectedSection) {
      case ContentSelection.WIP:
        fileContainerStateId = FileContainerState.Wip;
        break;
      case ContentSelection.Shared:
        fileContainerStateId = FileContainerState.Shared;
        break;
      case ContentSelection.Published:
        fileContainerStateId = FileContainerState.Published;
        break;
    }

    if (!fileContainerStateId || !TransmittalDetailsStore.transmittal) return;
    FileInformationStore.init(
      fileContainer,
      fileContainerStateId,
      NavBarSelectorStore.selectedItem!.project.projectNumber ?? AppStore.projectNumber,
      TransmittalDetailsStore.transmittal.id,
      TransmittalDetailsStore.transmittal.transmittalMessages[0].id
    );
  }

  public async downloadFile(
    transmittalId: number | null,
    transmittalMessageId: number | null,
    file: ITransmittalContentFile
  ) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });
    const fileDownload = {
      fileContainerId: file.id,
      fileRevisionId: file.fileRevisionId,
      releasedFileId: file.releasedFileId,
      containerFileId: file.containerFileId,
    };
    await this.getDownloadFileUrlForProjectUser(transmittalId, transmittalMessageId, [fileDownload]);
  }

  private async getAttachmentDownloadUrls(
    attachmentFileIds: number[],
    transmittalId: number | null,
    transmittalMessageId: number | null
  ) {
    this.clearError();
    try {
      const projectNumber = NavBarSelectorStore.selectedItem!.project.projectNumber;
      const urls = await getTransmittalFileDownloadUrl({
        projectNumber,
        attachmentFileIds,
        transmittalId,
        transmittalMessageId,
      });

      if (urls)
        urls.forEach((url) => {
          if (!url.errorMessage) window.open(url.url);
        });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        attachmentFileIds.forEach((id) => {
          this.fileBusy[id] = false;
        });
      });
    }
  }

  private async getDownloadFileUrlForProjectUser(
    transmittalId: number | null,
    transmittalIMessaged: number | null,
    files: DownloadFile[]
  ) {
    this.clearError();
    try {
      const response = await getProjectFileDownloadUrl({
        projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
        transmittalId: transmittalId,
        transmittalMessageId: transmittalIMessaged,
        downloadFiles: files,
      });
      if (response)
        response.forEach((res) => {
          if (res.url && !res.errorMessage) window.open(res.url);
        });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        files.forEach((f) => {
          if (f.fileContainerId === null) return;
          this.fileBusy[f.fileContainerId] = false;
        });
      });
    }
  }

  public async downloadFiles(transmittalId: number, transmittalMessageId: number, files: ITransmittalContentFile[]) {
    if (!files?.length) return;
    const allFiles = files
      .flatMap((m) => m.containerFiles)
      .map((file) => ({
        fileContainerId: file.fileContainerId,
        fileRevisionId: files.find((x) => x.id === file.fileContainerId)?.fileRevisionId ?? null,
        releasedFileId: file.releasedFileId,
        containerFileId: file.containerFileId,
      }));

    await this.getDownloadFileUrlForProjectUser(transmittalId, transmittalMessageId, allFiles);
  }

  public async downloadAttachmentFile(transmittalId: number, transmittalMessageId: number, file: IAttachmentFile) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });

    await this.getAttachmentDownloadUrls([file.id], transmittalId, transmittalMessageId);
  }

  public async downloadAttachmentFiles(transmittalId: number, transmittalMessageId: number, files: IAttachmentFile[]) {
    if (!files?.length) return;
    await this.getAttachmentDownloadUrls(
      files.map((file) => file.id),
      transmittalId,
      transmittalMessageId
    );
  }

  public async openFile(file: ITransmittalContentFile) {
    if (this.fileBusy[file.id]) return;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });
    this.getFileDocumentViewerUrlForProjectUser(file);
  }

  private async getFileDocumentViewerUrlForProjectUser(file: ITransmittalContentFile) {
    this.clearError();
    try {
      const url = await getProjectFileDocumentViewerUrl({
        projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileContainerId: file.id,
        fileContainerRevisionId: file.fileRevisionId,
        fileId: file.containerFileId,
        releasedFileContainerId: file.releasedFileId,
        transmittalId: file.transmittalId,
        transmittalMessageId: file.transmittalMessageId,
      });
      if (url) window.open(url);
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        this.fileBusy[file.id] = false;
      });
    }
  }

  public getTransmittalReleasedFiles(transmittalId: number, messageId: number) {
    const selectedResponse = TransmittalDetailsStore.transmittal?.transmittalMessages.find((x) => x.id === messageId);
    return (
      selectedResponse?.transmittalMessageReleasedFiles.map((f) => ({
        ...f,
        transmittalId: transmittalId,
        transmittalMessageId: messageId,
      })) || []
    );
  }

  public getTransmittalAttachmentFiles(transmittalId: number, messageId: number) {
    const selectedResponse = TransmittalDetailsStore.transmittal?.transmittalMessages.find((x) => x.id === messageId);

    return (
      selectedResponse?.transmittalMessageAttachmentFiles.map((f) => ({
        ...f,
        transmittalId: transmittalId,
        transmittalMessageId: messageId,
      })) || []
    );
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }
}

export default new TransmittalDetailsFilesStore();
