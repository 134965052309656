import React, { FC, useRef, useEffect, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BaseMapToggleWidget from './widgets/BaseMapToggleWidget';
import HomeWidget from './widgets/HomeWidget';
import ZoomWidget from './widgets/ZoomWidget';
import CompassWidget from './widgets/CompassWidget';
import FullScreenWidget from './widgets/FullScreenWidget';
import VisualisationStore from './VisualisationStore';
import esriConfig from '@arcgis/core/config';
import WebScene from '@arcgis/core/WebScene';
import SceneView from '@arcgis/core/views/SceneView';
import Layer from '@arcgis/core/layers/Layer';
import Style from './styles/Visualisation.module.scss';
import AppStore from '../../stores/AppStore';
import LayerTreeWidget from './widgets/LayerTreeWidget';
import { BaseMaps } from '../../common/constants/BaseMaps';
import SearchChainageWidget from './widgets/SearchChainageWidget';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { IChainageAttributes } from './models/IChainageAttributes';

const WebSceneViewer: FC = () => {
  const sceneViewRef = useRef(null);
  const [loadingMode, setLoadingMode] = useState(false);

  const reverseMapLayers = useCallback((mapLayers) => {
    if (!mapLayers.length) return;

    mapLayers.reverse();
    mapLayers.forEach((map) => {
      if (!map.layers) return;
      reverseMapLayers(map.layers);
    });
  }, []);

  const loadFeatureLayerData = async (layer: Layer) => {
    const featureLayer = new FeatureLayer({ ...layer });
    await featureLayer.load();
    const query = featureLayer.createQuery();

    let start = 0;
    const chunk = 2000; //max features chunk allowed by esri
    const chainageAttributes: IChainageAttributes[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      try {
        query.start = 0 + start * chunk;
        query.num = chunk;
        const res = await featureLayer.queryFeatures(query);

        if (res.features.length) {
          const fieldNames = res.fields.map((c) => {
            return { label: c.name, type: c.type };
          });
          const searchField = fieldNames.filter((s) => s.type === 'string').map((e) => e.label)[0];
          const attributes = res.features.map((f) => {
            return {
              label: f.attributes[searchField],
              id: f.attributes[fieldNames.filter((s) => s.type === 'oid').map((e) => e.label)[0]],
              graphicData: f,
            };
          });

          chainageAttributes.push(...attributes);
        } else {
          break;
        }
        start++;
      } catch (error) {
        break;
      }
    }
    VisualisationStore.setChainageLayerData(chainageAttributes);
  };

  const loadChainageLayerData = useCallback(async (featureLayers: Layer[]) => {
    if (!VisualisationStore.projectArcGisSettings.chainageLayerId) return;

    const layer = featureLayers.find(
      (l) => (l as FeatureLayer).portalItem.id === VisualisationStore.projectArcGisSettings.chainageLayerId
    );

    if (!layer) return;

    setLoadingMode(true);
    await loadFeatureLayerData(layer);
    setLoadingMode(false);
  }, []);

  const sceneViewReady = useCallback(
    async (map: WebScene) => {
      const mapLayers: Layer[] = [];
      const buildingLayers: Layer[] = [];
      const pointCloudLayers: Layer[] = [];
      const featureLayers: Layer[] = [];

      map.layers.forEach((l) => {
        mapLayers.push(l);

        if (l.type && l.type === 'building-scene') buildingLayers.push(l);
        else if (l.type && l.type === 'point-cloud') pointCloudLayers.push(l);
        else if (l.type && l.type === 'feature') featureLayers.push(l);
      });

      reverseMapLayers(mapLayers);
      await Promise.all(
        mapLayers.map((t) => {
          t.visible = false;
          return t.load();
        })
      );

      setTimeout(() => {
        VisualisationStore.setMapLayers(mapLayers);
        VisualisationStore.setBuildingLayers(buildingLayers);
        VisualisationStore.setPointCloudLayers(pointCloudLayers);
        loadChainageLayerData(featureLayers);
      });
    },
    [loadChainageLayerData, reverseMapLayers]
  );

  useEffect(() => {
    if (!sceneViewRef.current) return;

    if (!AppStore.client?.arcGisUseOauth) {
      esriConfig.apiKey = VisualisationStore.arcGisApiKey;
    }

    const map = new WebScene({
      portalItem: {
        id: VisualisationStore.projectArcGisSettings.webSceneId!,
      },
      basemap: VisualisationStore.projectArcGisSettings.baseMap ?? BaseMaps.DEFAULT,
    });

    const sView = new SceneView({
      container: sceneViewRef.current,
      map: map,
      ui: {
        components: ['attribution'],
      },
    });

    VisualisationStore.setWebScene(map);
    VisualisationStore.setMapView(sView);

    sView.when(() => sceneViewReady(map));
  }, [sceneViewReady]);

  return (
    <div className={Style.mapViewContainer}>
      {VisualisationStore.projectArcGisSettings.chainageLayerId && <SearchChainageWidget isLoading={loadingMode} />}
      <div className={Style.mapView} ref={sceneViewRef}>
        <div className={Style.rightTopWidgets}>
          <FullScreenWidget />
          <CompassWidget />
          <BaseMapToggleWidget />
          <HomeWidget />
          <ZoomWidget />
        </div>
        <div className={Style.leftTopWidgets}>
          <LayerTreeWidget />
        </div>
      </div>
    </div>
  );
};

export default observer(WebSceneViewer);
