import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import UserSelector, { IUser } from '../../shared/UserSelector';
import { useReviewStore } from './ReviewStoreContext';
import SecondaryButton from '../../shared/SecondaryButton';
import Styles from './styles/Reviewers.module.scss';
import TeamChart from '../../TeamChart/TeamChart';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import AppStore from '../../../stores/AppStore';
import FilesStore from '../FilesStore';
import { ContentSelection } from '../ContentSelection';
import { ITeamChartResult } from '../../TeamChart/interface/TeamChart.interface';
import { TeamChartTitle } from '../../../common/constants/TeamChartTitle';
import { Checkbox, DateInput } from '@aurecon-creative-technologies/styleguide';
import { useAuth } from '../../../authentication/hooks/useAuth';

const Reviewers: FC = () => {
  const { account } = useAuth();
  const store = useReviewStore();
  const [showTeamChart, setShowTeamChart] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [clearSuggestion, setClearSuggestion] = useState(false);

  const selectedReviewers = store.selectedReviewers;
  useEffect(() => {
    if (!selectedReviewers.length) return;

    setSelectedUsers(
      selectedReviewers.map((m) => {
        return {
          id: m.userId,
          name: m.name,
          email: m.email,
        };
      })
    );
  }, [selectedReviewers]);

  const handleShowTeamChart = () => {
    setShowTeamChart(true);
  };

  const showAnyoneInTaskTeam = () => {
    if (
      FilesStore.selectedSection === ContentSelection.Published ||
      FilesStore.selectedSection === ContentSelection.Shared
    )
      return true;
    if (
      FilesStore.selectedSection === ContentSelection.WIP &&
      (store.state === 'Review' || store.state === 'Collaborate')
    )
      return true;

    return false;
  };

  const showAnyoneInDeliveryTeam = () => {
    if (
      FilesStore.selectedSection === ContentSelection.Published ||
      FilesStore.selectedSection === ContentSelection.WIP
    )
      return true;

    if (
      FilesStore.selectedSection === ContentSelection.Shared &&
      (store.state === 'Review' || store.state === 'Collaborate')
    )
      return true;

    return false;
  };

  const onTeamChartUserSelected = (teamChartData: ITeamChartResult) => {
    const users: IUser[] = [];
    if (teamChartData.deliveryUsers.length)
      users.push(
        ...teamChartData.deliveryUsers.map((m) => {
          return {
            id: m.id,
            name: m.name,
            email: m.email,
          };
        })
      );

    if (teamChartData.taskTeamUsers.length)
      users.push(
        ...teamChartData.taskTeamUsers.map((m) => {
          return {
            id: m.id,
            name: m.name,
            email: m.email,
          };
        })
      );

    setSelectedUsers(users);
    store.onSelectedUsersUpdated(users);
    setShowTeamChart(false);
  };

  const handleAllowSelectUserFromAnotherTeams = (check) => {
    store.toggleShowUserFromAnotherTeam(check);
    setClearSuggestion(!clearSuggestion);
  };

  const renderTeamChartLabel = () => {
    switch (store.state) {
      case 'Published':
        return 'Publish';
      case 'Shared':
        return 'Share';
      default:
        return store.state;
    }
  };

  const isCollaborate = store.state === 'Collaborate';
  const isSharePublishCollaborate =
    (FilesStore.selectedSection === ContentSelection.Shared ||
      FilesStore.selectedSection === ContentSelection.Published) &&
    isCollaborate;

  return (
    <>
      <div className={Styles.requiredField}>
        <span>* is a required field</span>
      </div>
      <div className={Styles.container}>
        <div className={Styles.selectorWrapper}>
          {isCollaborate && (
            <>
              <label className={Styles.label}>
                {store.approversLabel}
                <span className={Styles.required}>*</span>
              </label>
              {NavBarSelectorStore.selectedItem?.project?.allowCollaborationOtherTaskTeams && (
                <Checkbox
                  cssClass={Styles.collaborateCheckbox}
                  label="Select recipients from other teams"
                  onChange={handleAllowSelectUserFromAnotherTeams}
                  checked={store.allowSelectAnotherProjectTeam}
                  disabled={store.isSelectUserFromAnotherTeam}
                />
              )}
            </>
          )}
          <UserSelector
            label={isCollaborate ? '' : store.approversLabel}
            required
            searchPlaceholder={store.approversSearchLabel}
            getUsers={store.getMatchedUsers}
            onSelectedUsersUpdated={store.onSelectedUsersUpdated}
            disabled={store.isSaving}
            isMultiUser={true}
            defaultSelectedUsers={selectedUsers}
            refreshSuggestion={clearSuggestion ?? undefined}
          />
        </div>
        <div className={Styles.buttonWrapper}>
          <SecondaryButton
            onClick={() => {
              handleShowTeamChart();
            }}>
            Team Chart
          </SecondaryButton>
        </div>
      </div>
      {showTeamChart && NavBarSelectorStore?.selectedItem?.project.projectNumber && (
        <TeamChart
          closeModal={() => setShowTeamChart(false)}
          headerTitle={TeamChartTitle.fileTitle}
          projectNumber={NavBarSelectorStore.selectedItem.project.projectNumber}
          taskTeamId={isSharePublishCollaborate ? undefined : AppStore.taskTeamId}
          deliveryTeamId={isSharePublishCollaborate ? undefined : AppStore.deliveryTeamId}
          fileContainerStateId={FilesStore.fileContainerStateId}
          onSelect={onTeamChartUserSelected}
          hideExternalSelection={true}
          showAnyoneInTaskTeam={showAnyoneInTaskTeam()}
          showAnyoneInDeliveryTeam={showAnyoneInDeliveryTeam()}
          disableTaskTeam={FilesStore.selectedSection === ContentSelection.Shared && store.state !== 'Collaborate'}
          disableDeliveryTeam={FilesStore.selectedSection === ContentSelection.WIP}
          excludedUsers={account?.userName ? [account?.userName] : []}
          allowSelectAnotherProjectTeam={store.allowSelectAnotherProjectTeam}
          accessibleDeliveryTeams={
            isSharePublishCollaborate ? store.accessibleDeliveryTeams.map((m) => m.accessibleDeliveryTeamId) : []
          }
          isCollaborateSharePublish={isSharePublishCollaborate}
          label={renderTeamChartLabel()}
        />
      )}
      {store.allowSelectAnotherProjectTeam && (
        <DateInput
          label="Access expires on"
          cssClass={Styles.accessExpires}
          required={true}
          onDateChange={store.setTemporaryAccessExpiredOn}
          error={store.temporaryAccessExpiredOnErrorMessage}
          dates={{
            startDate: store.temporaryAccessExpiredOn,
            endDate: null,
          }}
        />
      )}
    </>
  );
};

export default observer(Reviewers);
