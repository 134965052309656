import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { serverAxios } from '../api';
export interface IDeliveryTeamUser {
  id: number;
  name: string;
  email: string;
  deliveryTeamRoleIds: DeliveryTeamRole[];
  deliveryTeamRoleTitle: string | null;
}
export async function getDeliveryTeamUsers(
  projectNumber: string,
  deliveryTeamId: number,
  abortSignal?: AbortSignal
): Promise<IDeliveryTeamUser[]> {
  const result = await serverAxios.get<{ users: IDeliveryTeamUser[] }>('api/um/system/deliveryteamusers', {
    params: { projectNumber, deliveryTeamId },
    signal: abortSignal,
  });
  return result.data.users;
}
