import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import Style from './styles/SidebarButton.module.scss';
import Icon from '../shared/Icon';
import { classNames } from '../../utils/miscUtils';
import { useLocation } from 'react-router';
import LayoutStore from './LayoutStore';
import AppStore from '../../stores/AppStore';
import { Pages } from '../../common/constants/Pages';

export interface ISidebarButtonProps {
  label: string;
  route: string;
  icon: string;
  onClick?: () => void;
}

const SidebarButton: React.FC<ISidebarButtonProps> = ({ label, route, icon, onClick }) => {
  const location = useLocation();

  return (
    <NavLink
      onClick={(e) => {
        if (onClick) onClick();
        if (!AppStore.disableNavigation) return;
        e.preventDefault();
      }}
      to={route !== Pages.DisplayApps.Route ? route || '/' : location.pathname}
      className={classNames(Style.button, [
        location.pathname.includes(route) && route !== Pages.DisplayApps.Route,
        Style.isActive,
      ])}>
      <Icon name={icon} />
      {LayoutStore.showSidebar && label}
    </NavLink>
  );
};

export default observer(SidebarButton);
