import { serverAxios } from '../api';

export interface IAddTransmittalMessageAttachmentFileRequest {
  projectNumber: string;
  transmittalId: number;
  transmittalTitle: string;
  transmittalMessageId: number;
  filename: string;
  totalFileSize: number;
  totalFileChunks: number;
}

export interface IAddTransmittalMessageAttachmentFileResponse {
  attachmentFileId: number;
  attachmentFileUploadId: number;
}

export async function addTransmittalMessageAttachmentFile(
  request: IAddTransmittalMessageAttachmentFileRequest,
  abortSignal?: AbortSignal
): Promise<IAddTransmittalMessageAttachmentFileResponse> {
  const result = await serverAxios.post<IAddTransmittalMessageAttachmentFileResponse>(
    'api/transmittals/addFile',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data;
}
