import axios, { AxiosRequestTransformer } from 'axios';
import config from '../../config';
import AppStore from '../../stores/AppStore';
import { requestAccessToken } from '../../utils/accessTokenHandler';
import { dateTimeFormat, localDueDateFormat, handleDates } from '../../utils/dateUtils';
import { Pages } from '../../common/constants/Pages';

const dateTransformer = (data) => {
  if (data instanceof FormData) return data;

  if (data instanceof Date) {
    return data ? dateTimeFormat(new Date(data), localDueDateFormat) : null;
  }

  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val));
  }

  if (typeof data === 'object' && data) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
  }

  return data;
};

export const serverAxios = axios.create({
  baseURL: config.serverUri,
  transformRequest: [dateTransformer, ...(axios.defaults.transformRequest as AxiosRequestTransformer[])],
});

serverAxios.interceptors.request.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (c: any) => {
    const token = await requestAccessToken(AppStore.settings?.appApiScope ?? '');
    return {
      ...c,
      headers: {
        ...c?.headers,
        Authorization: `Bearer ${token}`,
        ClientRequestTime: new Date(),
      },
    };
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

serverAxios.interceptors.response.use(
  (response) => {
    handleDates(response.data);
    return response;
  },
  (error) => {
    if ([401, 404, 500].includes(error.response.status)) {
      window.location.href = `#${Pages.Error.Route}/${error.response.status}`;
    }
    // Do something with request error
    return Promise.reject(error);
  }
);
