import { TaskStatus } from '../enums/TaskStatus';

export const TaskStatusText = {
  [TaskStatus.NotStarted]: 'Not Started',
  [TaskStatus.AwaitingReview]: 'Awaiting Review',
  [TaskStatus.InProgress]: 'In Progress',
  [TaskStatus.Approved]: 'Approved',
  [TaskStatus.Rejected]: 'Rejected',
  [TaskStatus.Overdue]: 'Overdue',
  [TaskStatus.Withdrawn]: 'Withdrawn',
};
