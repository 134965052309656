import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformationAssignedBy.module.scss';
import UserCircle from '../shared/UserCircle';
import TaskInformationStore from './TaskInformationStore';

const TaskInformationAssignedBy: FC = () => {
  if (TaskInformationStore.task === null) return null;

  return (
    <>
      <UserCircle
        initials={TaskInformationStore.task.createdByUserInitials}
        email={TaskInformationStore.task.createdByUserEmail}
      />
      <div className={Style.name}>{TaskInformationStore.task.createdByUserName}</div>
    </>
  );
};

export default observer(TaskInformationAssignedBy);
