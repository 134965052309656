import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from '../FilesStore';
import ReviewModal from '../reviewModal/ReviewModal';
import SecondaryButton from '../../shared/SecondaryButton';
import { TaskType } from '../../../common/enums/TaskType';
import { useSaveTask } from '../customHooks/useSaveTask';
import { useGetAddTaskData } from '../customHooks/GetAddTaskDataHook';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';

interface TaskTeamReviewButtonProps {
  taskTeamId: number;
}

const TaskTeamReviewButton: FC<TaskTeamReviewButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);
  const saveTask = useSaveTask(taskTeamId, TaskType.TaskTeamReview);
  const getAddTaskData = useGetAddTaskData(TaskType.TaskTeamReview, taskTeamId);

  const showModal = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_REVIEW);
    setIsShowing(true);
  };

  return (
    <>
      <SecondaryButton disabled={!FilesStore.canTaskTeamReviewOnSelectedFiles || taskTeamId === 0} onClick={showModal}>
        Review
      </SecondaryButton>
      {isShowing && (
        <ReviewModal
          title="Task Team Review"
          description="If the 'Task Team Review' workflow is approved, files will be assigned the suitability code selected below."
          closeModal={() => setIsShowing(false)}
          approversLabel="Reviewers"
          approversSearchLabel="Add Reviewers"
          allApproversLabel="Require all reviewers to approve?"
          isSingleApproverRequired={!NavBarSelectorStore.selectedItem?.project?.requireAllApprovers}
          showSuitability
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Review"
        />
      )}
    </>
  );
};

export default observer(TaskTeamReviewButton);
