import { AppointingPartyRole } from '../../../common/enums/AppointingPartyRole';
import { serverAxios } from '../api';

export interface IUser {
  id: number;
  name: string;
  email: string;
  appointingPartyRoleIds: AppointingPartyRole[];
}

export async function getAppointingPartyUsers(projectNumber: string, abortSignal?: AbortSignal): Promise<IUser[]> {
  const result = await serverAxios.get<{ users: IUser[] }>('api/um/appointingparty/users', {
    params: { projectNumber },
    signal: abortSignal,
  });
  return result.data.users;
}
