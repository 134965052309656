import { Accordion, AccordionPanel, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import Styles from '../transmittals/styles/TransmittalSupportingFiles.module.scss';
import { ReactComponent as FileIcon } from './images/FileIcon.svg';
import { IAttachmentFile } from '../../api/authenticated/transmittals/getTransmittal';
import DropDownMenuForTableCell from '../shared/DropDownMenuForTableCell';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import { getFileSizeDetails } from '../../utils/miscUtils';

export interface ITransmittalSupportingFilesProps {
  openPanelIds: Set<string>;
  supportingFiles: IAttachmentFile[];
  onPanelToggle: (value: string) => void;
}

const TransmittalSupportingFiles: FC<ITransmittalSupportingFilesProps> = (props) => {
  const handlePanelToggle = (value: string): void => {
    props.onPanelToggle(value);
  };

  if (!props.supportingFiles.length) return null;

  return (
    <div className={Styles.supportingFiles}>
      <Accordion
        activePanelIds={Array.from(props.openPanelIds)}
        onPanelToggle={handlePanelToggle}
        cssClass={Styles.supportingFilesAccordion}
        verticalPanelGap="24px"
        headingBgColour="#FAFAFA"
        headingFontColour="#121212"
        panelBgColour="#FAFAFA"
        headingIconColour="#F37021">
        <AccordionPanel
          key="support-files"
          label={
            <div className={Styles.accordionHeading}>
              <FileIcon />
              {`Support Files (${props.supportingFiles.length})`}
            </div>
          }
          panelId="support-files">
          <Table
            headers={[{ label: 'Filename' }, { label: 'File Size' }, { label: 'Action', align: 'right' }]}
            breakpoint={10}
            hoverable>
            {props.supportingFiles.map((attachmentFile) => (
              <TableRow key={attachmentFile.id}>
                <TableCell>{attachmentFile.originalFilename}</TableCell>
                <TableCell>{getFileSizeDetails(attachmentFile.uploadedSize).totalFileSize}</TableCell>
                <DropDownMenuForTableCell>
                  <DropDownMenuButton>Download</DropDownMenuButton>
                  <DropDownMenuButton>Open File</DropDownMenuButton>
                </DropDownMenuForTableCell>
              </TableRow>
            ))}
          </Table>
        </AccordionPanel>
      </Accordion>
    </div>
  );
};

export default observer(TransmittalSupportingFiles);
