import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide';
import VisualisationStore from '../VisualisationStore';
import MeasurementLineWidget from './MeasurementLineWidget';
import MeasurementAreaWidget from './MeasurementAreaWidget';

const MeasurementWidget: FC = () => {
  const onSelectTab = (id: number) => {
    VisualisationStore.setSelectedMeasurementType(id);
  };

  return (
    <Container fluid>
      <TabMenu
        type="line"
        tabs={[
          { id: 1, label: 'Line' },
          { id: 2, label: 'Area' },
        ]}
        onSelectTab={onSelectTab}>
        <TabContent for={1}>
          <MeasurementLineWidget />
        </TabContent>
        <TabContent for={2}>
          <MeasurementAreaWidget />
        </TabContent>
      </TabMenu>
    </Container>
  );
};

export default observer(MeasurementWidget);
