import { SortType, SortTypes } from '../../../common/enums/SortType';

export class SystemUsersFilter {
  name?: string;
  email?: string;
  active?: boolean;
  sortColumn?: string | null;
  sortDirection?: string | null;

  constructor() {
    this.setDefaultSort();
    this.setDefaultFilter();
  }

  setDefaultFilter(name = undefined, email = undefined, active = undefined): this {
    this.name = name;
    this.email = email;
    this.active = active;

    return this;
  }

  setSort(column?: string, direction?: SortType): this {
    this.sortColumn = column ?? null;
    if (!direction || direction === 'none') {
      this.sortDirection = null;
    } else {
      this.sortDirection = direction;
    }

    return this;
  }

  setDefaultSort() {
    const defaultColumn = 'name';
    this.setSort(defaultColumn, SortTypes.ASC);
  }
}
