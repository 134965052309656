import { DeliveryTeamRole } from '../../../common/enums/DeliveryTeamRole';
import { serverAxios } from '../api';

export interface IRemoveUserFromDeliveryTeamRoleRequest {
  projectNumber: string;
  deliveryTeamId: number;
  userId: number;
  deliveryTeamRoleId: DeliveryTeamRole;
}

export function removeUserFromDeliveryTeamRole(
  request: IRemoveUserFromDeliveryTeamRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/deliveryteam/users/removerole', request, {
    signal: abortSignal,
  });
}
