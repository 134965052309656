import { serverAxios } from '../api';
import { IUser } from './interfaces/user.interface';

export async function getSystemExternalUser(projectNumber: string, abortSignal?: AbortSignal): Promise<IUser[]> {
  const result = await serverAxios.get<{ users: IUser[] }>('api/um/system/externalusers', {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.users;
}
