import { serverAxios } from '../api';

export interface IAddUserToDistributionListRequest {
  projectNumber: string;
  distributionListId: number;
  userId: number;
}

export function addUserToDistributionList(
  request: IAddUserToDistributionListRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/distributionlist/users/add', request, {
    signal: abortSignal,
  });
}
