import { makeAutoObservable, runInAction } from 'mobx';
import { TabStore } from './TabStore';
import { TabFilter } from './Types';

export class TasksStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }
  public searchText = '';
  public awaitingMyActionStore = new TabStore(TabFilter.AwaitingMyAction);
  public createdByMeStore = new TabStore(TabFilter.CreatedByMe);
  public allPendingTasksStore = new TabStore(TabFilter.AllPendingTasks);
  public allTasksStore = new TabStore(TabFilter.AllTasks);

  public currentTab: TabFilter = TabFilter.AwaitingMyAction;

  public setCurrentTab(value: TabFilter) {
    runInAction(() => {
      this.currentTab = value;
    });
  }
  public setSearchValue(value: string) {
    runInAction(() => {
      this.searchText = value;
      this.awaitingMyActionStore.setSearchText(this.searchText);
      this.createdByMeStore.setSearchText(this.searchText);
      this.allPendingTasksStore.setSearchText(this.searchText);
      this.allTasksStore.setSearchText(this.searchText);
    });
  }
  public refreshTasks() {
    this.awaitingMyActionStore.abortController?.abort();
    this.createdByMeStore.abortController?.abort();
    this.allPendingTasksStore.abortController?.abort();
    this.allTasksStore.abortController?.abort();

    this.awaitingMyActionStore.loadTasks();
    this.createdByMeStore.loadTasks();
    this.allPendingTasksStore.loadTasks();
    this.allTasksStore.loadTasks();
  }
}

export default new TasksStore();
