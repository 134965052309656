import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from '@aurecon-creative-technologies/styleguide';

export interface IDeliveryTeamTagProps {
  deliveryTeamTitle: string;
  deliveryTeamCode: string;
}

const DeliveryTeamTag: FC<IDeliveryTeamTagProps> = ({ deliveryTeamTitle, deliveryTeamCode }) => {
  return (
    <span>
      <Icon type="people" /> {deliveryTeamTitle} <b>({deliveryTeamCode})</b>
    </span>
  );
};

export default observer(DeliveryTeamTag);
