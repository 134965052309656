import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SelectedTransmittalsLabel.module.scss';
import TransmittalsStore from './TransmittalsStore';
import ActionBarStore from './ActionBarStore';
import { Button } from '@aurecon-creative-technologies/styleguide';

const SelectedTransmittalsLabel: FC = () => {
  const selectLabel = () => {
    return (
      !!ActionBarStore.getTotalTransmittalSelected && (
        <div className={Style.totalSelected}>{ActionBarStore.getTotalTransmittalSelected} items selected</div>
      )
    );
  };
  return (
    <span className={Style.label}>
      {selectLabel()}{' '}
      {(!!ActionBarStore.getTotalTransmittalSelected || TransmittalsStore.showSelectedItems) && (
        <Button
          type="text"
          cssClass={Style.linkLabel}
          onClick={() => TransmittalsStore.toggleShowSelectedItems()}
          label={!TransmittalsStore.showSelectedItems ? 'Show Selected' : 'Show All'}
        />
      )}
    </span>
  );
};

export default observer(SelectedTransmittalsLabel);
