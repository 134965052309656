import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskAttachmentFileEvent } from '../../../api/authenticated/cms/getFileHistory';
import TaskEvent from './TaskEvent';

export interface ITaskEventAttachmentFileProps {
  taskAttachmentFileEvent?: ITaskAttachmentFileEvent;
}

const TaskEventAttachmentFile: FC<ITaskEventAttachmentFileProps> = ({ taskAttachmentFileEvent }) => {
  if (!taskAttachmentFileEvent) return null;

  return (
    <TaskEvent icon="description" label="Supporting files attached">
      <div key={taskAttachmentFileEvent.createdBy.userId}>{taskAttachmentFileEvent.createdBy.userName}</div>
    </TaskEvent>
  );
};

export default observer(TaskEventAttachmentFile);
