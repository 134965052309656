import { isValidRegex, requiredValue } from '../../../../utils/miscUtils';

const MaxLength = 250;
export const VisualisationFields = {
  webSceneId: 'webSceneId',
  azureGroupId: 'azureGroupId',
  chainageItemId: 'chainageItemId',
};

export const UpdateProjectVisualisationValidator = {
  [VisualisationFields.webSceneId]: (value: string): string => validateString('Web Scene ID', value, true),
  [VisualisationFields.chainageItemId]: (value: string): string => validateString('Chainage Item ID', value, false),
};

const validateString = (label: string, value: string, isRequired: boolean) => {
  const requiredError = isRequired ? requiredValue(label, value) : '';
  if (requiredError.length) return requiredError;
  if (!isValidRegex(value, {})) return `${label} can not contain special characters/tags`;
  if (value && value.length > MaxLength) return `${label} exceeded max length`;
  return '';
};
