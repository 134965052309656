import { serverAxios } from '../api';

export interface ITransmittalDeliveryTeamRequest {
  projectNumber: string | null;
  deliveryTeamId: number | null;
}
export interface ITransmittalDetails {
  id: number;
  ProjectId: number;
  title: string;
  transmittalTypeTitle: string;
  transmittalReasonTitle: string;
  closed: boolean;
  closedDate: Date | null;
  dueDate: Date | null;
  createdByEmail: string;
  createdByName: string;
  createdDate: Date;
  lastUpdated: Date | null;
  projectNumber: string;
  flagged: boolean;
  totalAttachmentFiles: number;
  totalReleasedFiles: number;
  totalMessage: number;
  lastMessage: string;
  lastSubject: string;
  notifyToUserNames: string;
  visibleUserNames: string;
  overdue: boolean;
}

export async function getAllTransmittalDeliveryTeam(
  options: ITransmittalDeliveryTeamRequest,
  abortSignal?: AbortSignal
): Promise<ITransmittalDetails[]> {
  const result = await serverAxios.get<{
    transmittal: ITransmittalDetails[];
  }>('api/transmittals/getAllTransmittalsDeliveryTeam', {
    params: options,
    signal: abortSignal,
  });
  return result.data.transmittal;
}
