import { serverAxios } from '../api';
import { IExportUserCSV } from './interfaces/user.interface';

export async function getUserTeamsExport(userId: number, abortSignal?: AbortSignal): Promise<IExportUserCSV[]> {
  const result = await serverAxios.get<{ users: IExportUserCSV[] }>('api/um/getuserteams', {
    params: {
      userId: userId,
    },
    signal: abortSignal,
  });
  return result.data.users;
}
