import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TeamChart.module.scss';
import Icon from '../shared/Icon';
import { Grid } from '@aurecon-creative-technologies/styleguide';
import TeamChartStore from './TeamChartStore';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import ModalActions from '../shared/ModalActions';
import Modal from '../shared/Modal';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import CloseButton from '../shared/CloseButton';
import { ITeamChartResult } from './interface/TeamChart.interface';
import TeamChartContainer from './TeamChartContainer';
import TeamChartFilter from './TeamChartFilter';
import TeamChartSelectedUsers from './TeamChartSelectedUsers';
import { IUserDetail } from '../../api/authenticated/transmittals/createTransmittal';
import { TeamChartStoreContext } from './TeamChartStoreContext';
import { FileContainerState } from '../../common/enums/FileContainerState';

interface ITeamChartProps {
  projectNumber: string;
  closeModal: () => void;
  onSelect: (value: ITeamChartResult) => void;
  selectedUsers?: IUserDetail[];
  deliveryTeamId?: number;
  taskTeamId?: number;
  showAnyoneInTaskTeam?: boolean;
  showAnyoneInDeliveryTeam?: boolean;
  disableTaskTeam?: boolean;
  disableDeliveryTeam?: boolean;
  hideExternalSelection?: boolean;
  excludedUsers?: string[];
  headerTitle: string;
  fileContainerStateId?: FileContainerState;
  allowSelectAnotherProjectTeam?: boolean;
  accessibleDeliveryTeams?: (number | null)[];
  isCollaborateSharePublish?: boolean;
  label?: string;
}

const TeamChart: FC<ITeamChartProps> = (props) => {
  const {
    closeModal,
    onSelect,
    projectNumber,
    selectedUsers,
    deliveryTeamId,
    taskTeamId,
    hideExternalSelection,
    showAnyoneInTaskTeam,
    showAnyoneInDeliveryTeam,
    disableTaskTeam,
    disableDeliveryTeam,
    excludedUsers,
    headerTitle,
    fileContainerStateId,
    allowSelectAnotherProjectTeam,
    accessibleDeliveryTeams,
    isCollaborateSharePublish,
    label,
  } = props;

  useEffect(() => {
    TeamChartStore.resetForm();
  }, []);

  useEffect(() => {
    const bindingData = async () => {
      TeamChartStore.resetForm();
      TeamChartStore.setInputSelected(selectedUsers);
      await TeamChartStore.getData({
        projectNumber: projectNumber,
        deliveryTeamId: deliveryTeamId ?? null,
        taskTeamId: taskTeamId ?? null,
        anyoneInTaskTeam: showAnyoneInTaskTeam ?? true,
        anyoneInDeliverTeam: showAnyoneInDeliveryTeam ?? true,
        excludedUsers: excludedUsers ?? [],
        fileContainerStateId: fileContainerStateId,
        allowSelectAnotherProjectTeam: allowSelectAnotherProjectTeam,
        isCollaborateSharePublish: isCollaborateSharePublish,
      });

      if (accessibleDeliveryTeams?.length && !allowSelectAnotherProjectTeam) {
        TeamChartStore.deliveryTeamOptions = TeamChartStore.deliveryTeamOptions.filter((f) =>
          accessibleDeliveryTeams.includes(Number(f.id))
        );
        TeamChartStore.allProjectTaskTeams = TeamChartStore.allProjectTaskTeams.filter((f) =>
          accessibleDeliveryTeams.includes(f.deliveryTeamId)
        );
      }
      if (deliveryTeamId) TeamChartStore.setSelectedDeliveryId(deliveryTeamId);
      if (taskTeamId) TeamChartStore.setSelectedTaskTeamId(taskTeamId);
      if (deliveryTeamId || taskTeamId) TeamChartStore.filterData();
    };

    bindingData();
  }, [
    projectNumber,
    selectedUsers,
    deliveryTeamId,
    taskTeamId,
    showAnyoneInTaskTeam,
    showAnyoneInDeliveryTeam,
    excludedUsers,
    fileContainerStateId,
    allowSelectAnotherProjectTeam,
    accessibleDeliveryTeams,
    isCollaborateSharePublish,
  ]);

  const handleSaveBtnClick = () => {
    const data = TeamChartStore.getSelectedData();
    onSelect(data);
  };

  const handleClosedModal = () => {
    TeamChartStore.resetForm();
    closeModal();
  };

  return (
    <TeamChartStoreContext.Provider
      value={useMemo(
        () => ({
          showAnyoneInDeliveryTeam: showAnyoneInDeliveryTeam ?? true,
          showAnyoneInTaskTeam: showAnyoneInTaskTeam ?? true,
          disableTaskTeam: disableTaskTeam ?? false,
          disableDeliveryTeam: disableDeliveryTeam ?? false,
          hideExternalSelection: hideExternalSelection ?? false,
        }),
        [showAnyoneInDeliveryTeam, showAnyoneInTaskTeam, disableTaskTeam, disableDeliveryTeam, hideExternalSelection]
      )}>
      <CentreOnPage>
        <Overlay />
        <Modal className={Style.modal}>
          <div className={Style.container}>
            <CloseButton onClick={() => handleClosedModal()} />
            <div className={Style.header}>
              <span>
                <Icon name="notifications" className={Style.headerIcon} />
              </span>
              <span>{label ?? 'Notify'} </span>
              <span className={Style.require}>*</span>
            </div>
            <TeamChartFilter
              disableFilter={!allowSelectAnotherProjectTeam && !!deliveryTeamId && !!taskTeamId}
              headerTitle={headerTitle}
            />
            {TeamChartStore.isShowFilterResult() && <div className={Style.thematicBreak} />}
            {TeamChartStore.isShowSelectedUser && (
              <Grid row gap={8} cssClass={Style.rowItem}>
                <Grid item md={12}>
                  <TeamChartSelectedUsers />
                </Grid>
              </Grid>
            )}
            <TeamChartContainer />
          </div>
          <div className={Style.modalActions}>
            <ModalActions>
              <SecondaryButton onClick={() => handleClosedModal()}>Cancel</SecondaryButton>
              <PrimaryButton disabled={!TeamChartStore.isShowSelectedUser} onClick={handleSaveBtnClick}>
                Save
              </PrimaryButton>
            </ModalActions>
          </div>
        </Modal>
      </CentreOnPage>
    </TeamChartStoreContext.Provider>
  );
};

export default observer(TeamChart);
