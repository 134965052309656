import classNames from 'classnames';
import React, { FC } from 'react';
import { UploadFileStatusEnum, UploadFileStatusText } from '../../../common/enums/UploadFileStatus';
import Style from './styles/FileStatus.module.scss';

interface IFileStatusProps {
  statuses: UploadFileStatusEnum[];
}
const FileStatus: FC<IFileStatusProps> = (props) => {
  const { statuses } = props;

  // Prioritize displaying error statuses other than Ready For Upload status if the list of statuses contains any error status.
  const prioritizedStatuses = () => {
    const distinctStatuses = [...new Set(statuses)];
    if (distinctStatuses.every((e) => e === UploadFileStatusEnum.Ready)) return distinctStatuses;
    return distinctStatuses.filter((f) => f !== UploadFileStatusEnum.Ready);
  };

  return (
    <>
      {prioritizedStatuses().map((status) => (
        <span
          key={status}
          className={classNames(Style.status, {
            [Style.ready]: status === UploadFileStatusEnum.Ready,
            [Style.supersede]: status === UploadFileStatusEnum.Supersede,
            [Style.duplicate]: status === UploadFileStatusEnum.Duplicate,
            [Style.missingMetadata]: status === UploadFileStatusEnum.MissingMetadata,
            [Style.missingFileExtension]: status === UploadFileStatusEnum.MissingFileExtension,
          })}>
          {UploadFileStatusText[status]}
        </span>
      ))}
    </>
  );
};

export default FileStatus;
