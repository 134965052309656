import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/UserImportModal.module.scss';
import CentreOnPage from '../../../shared/CentreOnPage';
import Overlay from '../../../shared/Overlay';
import Modal from '../../../shared/Modal';
import CloseButton from '../../../shared/CloseButton';
import ModalActions from '../../../shared/ModalActions';
import SecondaryButton from '../../../shared/SecondaryButton';
import PrimaryButton from '../../../shared/PrimaryButton';
import FileDropZone from '../../../shared/FileDropZone';
import UserImportStore from '../../../../stores/settings/shared/userUpload/UserImportStore';
import Icon from '../../../shared/Icon';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import UserImportResultTab from './UserImportResultTab';
import ProgressBar from './ProgressBar';
import UnsavedModal from './UnsavedModal';
import { CSVLink } from 'react-csv';
import { IValidationImportResultModel } from './models/ValidationImportUserModel';
import { IValidationImportSystemUsers } from '../../../../models/importSystemUsers/validationImportSystemUsers';

interface IUserImportModalProps {
  closeModal: () => void;
  showModal: boolean;
  customUserValidation: (validUsers: IValidationImportResultModel[]) => {
    errorUsers: IValidationImportResultModel[];
    duplicateUsers: IValidationImportResultModel[];
    readyForImportUsers: IValidationImportResultModel[];
  };
  importUserCallback: (readyForImport: IValidationImportResultModel[]) => Promise<{
    isSuccess: boolean;
    importResults: {
      email: string;
      status: string;
      reason: string;
    }[];
  }>;
  onOpenning?: () => void;
  browseToUrl?: string;
  users: IValidationImportSystemUsers[] | null;
  checkSystemAdmin: boolean;
}

const UserImportModal: FC<IUserImportModalProps> = (props) => {
  const {
    closeModal,
    showModal,
    browseToUrl,
    users,
    checkSystemAdmin,
    customUserValidation,
    importUserCallback,
    onOpenning,
  } = props;
  const [showUploadUnsavedModal, setShowUploadUnsavedModal] = useState(false);
  const [showImportUserModal, setShowImportUserModal] = useState(showModal);

  useEffect(() => {
    if (showUploadUnsavedModal) {
      setShowImportUserModal(false);
    }
  }, [showUploadUnsavedModal]);

  const closeTabEvent = (ev) => {
    if (!UserImportStore.selectedFile) return;

    ev.preventDefault();
    ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
    };
  }, []);

  useEffect(() => {
    onOpenning?.();
  }, [onOpenning]);

  const handleCancelButtonClick = () => {
    if (
      UserImportStore.selectedFile &&
      (UserImportStore.isProcessing || UserImportStore.processed) &&
      !showUploadUnsavedModal
    ) {
      setShowUploadUnsavedModal(true);
      return;
    }
    setShowUploadUnsavedModal(false);
    closeModal?.();
    UserImportStore.clear();
  };

  const onUploadButtonClick = async () => {
    const importUsers = UserImportStore.processedImportingUsers.readyForImport;
    if (!importUsers.length) return;

    setShowImportUserModal(false);
    await UserImportStore.handleImportUser(importUserCallback);
  };

  const handleOnDropzoneChange = (acceptedFiles) => {
    UserImportStore.addFiles(acceptedFiles, users, checkSystemAdmin, customUserValidation);
  };

  const handleConfirmUpload = () => {
    closeModal?.();
    UserImportStore.clear();
  };

  const renderLoader = () => (
    <div className={Style.loaderPadding}>
      <Loader
        size="extra small"
        label={!UserImportStore.totalUsers ? 'Processing' : `Processing ${UserImportStore.totalUsers} rows`}
      />
    </div>
  );

  const renderResultTab = () => <UserImportResultTab processedUser={UserImportStore.processedImportingUsers} />;

  const renderFileDropZone = () => (
    <div className={Style.uploadForm}>
      <FileDropZone
        className={Style.dropzone}
        label={
          <div>
            <Icon className={Style.noteAddIcon} name="note_add" />
            <div className={Style.uploadMessage}>
              <p>
                Drag & drop or click to <span className={Style.highLight}>browse</span> for files
              </p>
              <p>File supported: .csv</p>
              <p>Maximum size: 5MB</p>
            </div>
          </div>
        }
        multiple={false}
        onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles[0])}
      />
      {(UserImportStore.filesSizeExceededTheLimit || UserImportStore.isSelectedInvalidExtension) && (
        <p className={Style.errorSummary}>
          {UserImportStore.isSelectedInvalidExtension
            ? 'Invalid file. Please download and use the template below.'
            : 'File is larger than the maximum size supported'}
        </p>
      )}
    </div>
  );

  const renderContent = () => {
    if (UserImportStore.isProcessing) {
      return renderLoader();
    }
    if (UserImportStore.processed) {
      return renderResultTab();
    }
    return renderFileDropZone();
  };

  const csvImportTemplate = UserImportStore.getUserImportCSVTemplate();

  return (
    <>
      {showImportUserModal && (
        <CentreOnPage>
          <Overlay />
          <Modal className={Style.container}>
            <CloseButton onClick={handleCancelButtonClick} disabled={false} />
            <p className={Style.header}>{UserImportStore.header}</p>
            <div>
              {renderContent()}
              {!UserImportStore.processed && (
                <div className={Style.downloadTemplate}>
                  <CSVLink
                    className={Style.linkLabel}
                    data={csvImportTemplate.data}
                    headers={csvImportTemplate.headers}
                    filename={csvImportTemplate.filename}
                    target="_blank">
                    Download Template
                  </CSVLink>
                </div>
              )}
            </div>
            <ModalActions>
              <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
              <PrimaryButton
                disabled={
                  !UserImportStore.selectedFile ||
                  (UserImportStore.processedImportingUsers.readyForImport &&
                    !UserImportStore.processedImportingUsers.readyForImport.length)
                }
                onClick={onUploadButtonClick}>
                Continue
              </PrimaryButton>
            </ModalActions>
          </Modal>
        </CentreOnPage>
      )}
      {showUploadUnsavedModal && (
        <UnsavedModal
          closeModal={() => {
            setShowUploadUnsavedModal(false);
            setShowImportUserModal(true);
          }}
          redirect={handleCancelButtonClick}
          buttonContinueText="Yes"
          buttonCancelText="No"
        />
      )}
      {UserImportStore.showProgressBar && (
        <ProgressBar
          completed={UserImportStore.importProgress}
          uploadSuccess={UserImportStore.showUploadSuccess}
          uploadFailed={UserImportStore.showUploadFailed}
          browseToUrl={browseToUrl}
          handleConfirmCallback={handleConfirmUpload}
        />
      )}
    </>
  );
};

export default observer(UserImportModal);
