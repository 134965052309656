import { makeAutoObservable, runInAction } from 'mobx';
import { FileItem } from '../../../../../common/interfaces/fileUpload';
import { fileSizeUnits, isHTML, isValidRegex, validateEmail } from '../../../../../utils/miscUtils';
import { FileExt } from '../../../../../common/constants/FileExt';
import { v4 as uuidv4 } from 'uuid';
import {
  IValidationImportResult,
  IValidationImportSystemUsers,
  IValidationImportUserResponse,
} from '../../../../../models/importSystemUsers/validationImportSystemUsers';
import AppStore from '../../../../../stores/AppStore';
import { dateTimeFormat } from '../../../../../utils/dateUtils';
import { getAdUsers } from '../../../../../api/authenticated/um/getAdUsers';
import LayoutStore from '../../../../layout/LayoutStore';
import { getSystemUsers } from '../../../../../api/authenticated/um/getSystemUsers';
import { SystemUsersFilter } from '../../../../../api/authenticated/um/SystemUsersFilterClass';
import { ISystemUser } from '../../../../../api/authenticated/um/interfaces/user.interface';

export class SystemUserUploadStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public filesSizeExceededTheLimit = false;
  public selectedFile: FileItem | null = null;
  public showProgressBar = false;
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public isProcessing = false;
  public processed = false;
  public isSelectedInvalidExtension = false;
  public totalUsers = 0;
  public importProgress = 0;
  public allSystemUsers: ISystemUser[] = [];
  fileReader = new FileReader();
  importingUsers: IValidationImportSystemUsers[] = [];
  processedImportingUsers = {} as IValidationImportUserResponse;
  public header = 'Import Users';
  public importResults: {
    email: string;
    status: string;
    reason: string;
  }[] = [];

  // Limit 5 MB
  public limitBytes = 5 * fileSizeUnits.MB;

  public clear() {
    runInAction(() => {
      this.showProgressBar = false;
      this.selectedFile = null;
      this.filesSizeExceededTheLimit = false;
      this.isProcessing = false;
      this.importProgress = 0;
      this.processed = false;
      this.showUploadSuccess = false;
      this.header = 'Import Users';
      this.isSelectedInvalidExtension = false;
    });
  }

  private async readFileContent(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  private csvFileToUsersArray(csvOutput: string) {
    csvOutput = csvOutput.replace(/\r/g, '');
    const csvHeader = csvOutput.slice(0, csvOutput.indexOf('\n')).split(',');
    if (
      !(csvHeader.includes('email') || csvHeader.includes('"email"')) ||
      (!(csvHeader.includes('displayname') || csvHeader.includes('"displayname"')) &&
        AppStore.client?.canInviteOrRemoveAzure)
    ) {
      runInAction(() => {
        this.isSelectedInvalidExtension = true;
      });
      return [];
    }

    const csvRows = csvOutput
      .slice(csvOutput.indexOf('\n') + 1)
      .split('\n')
      .filter((row) => row.trim() !== '');
    runInAction(() => {
      this.totalUsers = csvRows.length;
    });

    const array = csvRows.map((row) => {
      const values = row.split(',');
      const user: IValidationImportSystemUsers = {
        id: uuidv4(),
        email: values[csvHeader.indexOf('email') >= 0 ? csvHeader.indexOf('email') : csvHeader.indexOf('"email"')],
        username:
          values[
            csvHeader.indexOf('displayname') >= 0
              ? csvHeader.indexOf('displayname')
              : csvHeader.indexOf('"displayname"')
          ],
        active: true,
      };
      return user;
    });

    runInAction(() => {
      this.importingUsers = array;
    });
    return array;
  }

  public async validateImportingUsers() {
    const emailSet = new Set<string>();
    const errors: IValidationImportResult[] = [];
    const duplicates: IValidationImportResult[] = [];
    const readyForImport: IValidationImportResult[] = [];
    let validUsers: IValidationImportResult[] = [];

    const isValidUser = (user, emailSet, validUsers) => {
      const displayName = user.username;
      const userData = { id: user.id, email: user.email, displayName: displayName };
      const results = [] as string[];

      if (!user.email) {
        results.push('No Email');
      }
      if (!validateEmail(user.email)) {
        results.push('Invalid email format');
      }
      if (isHTML(user.email)) {
        results.push('Cannot contain special characters/tags');
      }
      if (emailSet.has(user.email)) {
        const index = validUsers.findIndex((vu) => vu.email === user.email);
        if (index > -1) validUsers.splice(index, 1);
        results.push('Duplicate email');
      }
      if (!displayName && AppStore.client?.canInviteOrRemoveAzure) {
        results.push('No display name');
      }
      if (
        displayName &&
        AppStore.client?.canInviteOrRemoveAzure &&
        !isValidRegex(displayName, { allowSpace: true, allowMacron: true })
      ) {
        results.push('Invalid display name');
      }
      return results.length ? { ...userData, result: results.join(',') } : null;
    };

    for (const user of this.importingUsers) {
      const validationResult = isValidUser(user, emailSet, validUsers);
      if (validationResult) {
        errors.push(validationResult);
      } else {
        emailSet.add(user.email);
        validUsers.push({ ...user, displayName: user.username, result: '' });
      }
    }

    if (!AppStore.client?.canInviteOrRemoveAzure) {
      const usersNotInAAD = await this.checkIfUserInAAD(validUsers);

      const errorsFromAAD = usersNotInAAD.map((user) => ({
        ...user,
        result: 'Email not exist in Azure AD',
      }));

      errors.push(...errorsFromAAD);
      validUsers = validUsers.filter(
        (user) => !usersNotInAAD.some((u) => u.email.toLowerCase() === user.email.toLowerCase())
      );
    }

    const { duplicatedUsers, reactivatingUsers } = this.checkDuplicate(validUsers);

    duplicates.push(
      ...duplicatedUsers.map((user) => ({
        ...user,
        result: 'Already active user',
      }))
    );

    readyForImport.push(
      ...reactivatingUsers.map((user) => ({
        ...user,
        result: 'User will be reactivated',
      }))
    );

    const duplicatedAndReactivatingUsersEmail = new Set(
      [...duplicatedUsers, ...reactivatingUsers].map((user) => user.email)
    );
    readyForImport.push(...validUsers.filter((user) => !duplicatedAndReactivatingUsersEmail.has(user.email)));

    runInAction(() => {
      this.processedImportingUsers = {
        errors,
        duplicates,
        readyForImport,
      };
      this.processed = true;
      this.header = 'Import Results';
      this.isProcessing = false;
    });
  }
  public async checkIfUserInAAD(users: IValidationImportResult[]) {
    const allAdUsers = await getAdUsers('');
    const notFoundUsers = users.filter(
      (user) => !allAdUsers.some((u) => u.mail.toLowerCase() === user.email.toLowerCase())
    );
    return notFoundUsers;
  }

  private checkDuplicate(users: IValidationImportResult[]): {
    duplicatedUsers: IValidationImportResult[];
    reactivatingUsers: IValidationImportResult[];
  } {
    const duplicatedUsers = users.filter((user) =>
      this.allSystemUsers.some((u) => u.email.toLocaleLowerCase() === user.email.toLocaleLowerCase() && u.active)
    );

    const reactivatingUsers = users.filter((user) =>
      this.allSystemUsers.some((u) => u.email.toLocaleLowerCase() === user.email.toLocaleLowerCase() && !u.active)
    );

    return { duplicatedUsers, reactivatingUsers };
  }

  public async addFiles(file: FileItem) {
    if (!this.validationUploadFiles(file, [FileExt.CSV])) return;
    const data = await getSystemUsers({} as SystemUsersFilter);
    this.allSystemUsers = data;
    runInAction(() => {
      this.isProcessing = true;
    });

    try {
      const content = await this.readFileContent(file);
      runInAction(() => {
        this.selectedFile = file;
      });
      const users = this.csvFileToUsersArray(content);
      if (!users.length) {
        this.removeFile();
        return;
      }
      await this.validateImportingUsers();
    } catch (error) {
      LayoutStore.displayToast('error', 'Error reading file.');
    } finally {
      runInAction(() => {
        this.isProcessing = false;
      });
    }
  }

  public removeFile() {
    runInAction(() => {
      this.selectedFile = null;
    });
  }
  private startUploadProcess() {
    runInAction(() => {
      this.importProgress = 0;
      this.showProgressBar = true;
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  public async handleImportSytemUser(
    importSystemUserCallback: (readyForImport: IValidationImportResult[]) => Promise<{
      isSuccess: boolean;
      importResults: {
        email: string;
        status: string;
        reason: string;
      }[];
    }>
  ) {
    if (this.selectedFile) {
      this.startUploadProcess();
      this.simulateProgress();
      try {
        const response = await importSystemUserCallback(this.processedImportingUsers.readyForImport);

        runInAction(() => {
          this.importProgress = 100;
          this.showUploadSuccess = response.isSuccess;
          this.showUploadFailed = !response.isSuccess;
          this.importResults = response.importResults.map((m) => {
            return {
              email: m.email,
              status: m.status,
              reason: m.reason,
            };
          });
        });
      } catch {
        runInAction(() => {
          this.importProgress = 100;
          this.showUploadSuccess = false;
          this.showUploadFailed = true;
          this.importResults = this.processedImportingUsers.readyForImport.map((m) => {
            return {
              email: m.email,
              status: 'Failure',
              reason: 'Something went wrong',
            };
          });
        });
      }
    }
  }

  private simulateProgress() {
    if (this.importProgress < 90) {
      setTimeout(() => {
        runInAction(() => {
          this.importProgress += 1;
        });
        this.simulateProgress();
      }, 100);
    }
  }

  public getResultsCSVData() {
    return {
      filename: `Bulk_User_Import_Result_${dateTimeFormat(new Date(), 'yyyyMMdd')}.csv`,
      headers: [
        { label: 'Email', key: 'email' },
        { label: 'Import Status', key: 'status' },
        { label: 'Reason', key: 'reason' },
      ],
      data: this.importResults,
    };
  }

  private validationUploadFiles(file: File, acceptedExtension?: string[]): boolean {
    const exceedLimitation = (file.size ?? 0) > this.limitBytes;
    const isInvalidExtension = !acceptedExtension?.includes(file.name.split('.').pop()?.toLowerCase() ?? '');
    runInAction(() => {
      this.filesSizeExceededTheLimit = exceedLimitation;
      this.isSelectedInvalidExtension = isInvalidExtension;
    });

    return !(exceedLimitation || isInvalidExtension);
  }

  public getSystemUserImportCSVTemplate() {
    return {
      filename: `User_Import_Template.csv`,
      headers: AppStore.client?.canInviteOrRemoveAzure
        ? [
            { label: 'email', key: 'email' },
            { label: 'displayname', key: 'displayName' },
          ]
        : [{ label: 'email', key: 'email' }],
      data: AppStore.client?.canInviteOrRemoveAzure
        ? Object.keys([]).map((email, displayName) => ({
            email,
            displayName,
          }))
        : Object.keys([]).map((email) => ({
            email,
          })),
    };
  }
}

export default new SystemUserUploadStore();
