import { serverAxios } from '../api';

export interface IProjectMembersAndTeams {
  users: IUser[];
  deliveryTeams: IDeliveryTeam[];
  taskTeams: ITaskTeam[];
  appointingParties: IAppointingParty[];
  distributionList: IDistributionList[];
  appointingPartyUsers: IAppointingPartyUser[];
}

export interface IProjectNotifyList {
  users: IUser[];
  distributionList: IDistributionList[];
  appointingPartyUsers: IAppointingPartyUser[];
}
export interface IUser {
  userId: number;
  userName: string;
  userEmail: string;
  taskTeamId: number;
  taskTeamTitle: string;
  taskTeamCode: string;
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  isExternal: boolean;
}

export interface IDeliveryTeam {
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  deliveryTeamCode: string;
}

export interface ITaskTeam {
  taskTeamId: number;
  taskTeamTitle: string;
  taskTeamCode: string;
  deliveryTeamTitle: string;
  userCount: number;
}

export interface IAppointingParty {
  appointingPartyId: number;
  appointingPartyTitle: string;
  appointingPartyCode: string;
  userCount: number;
}
export interface IDistributionList {
  distributionListId: number;
  distributionListName: string;
  userCount: number;
}

export interface IAppointingPartyUser {
  userId: number;
  appointingPartyId: number;
  userName: string;
  userEmail: string;
}

export async function getProjectMembersAndTeams(
  projectNumber: string | undefined,
  abortSignal?: AbortSignal
): Promise<IProjectMembersAndTeams> {
  const result = await serverAxios.get<{
    users: IUser[];
    deliveryTeams: IDeliveryTeam[];
    taskTeams: ITaskTeam[];
    appointingParties: IAppointingParty[];
    distributionLists: IDistributionList[];
    appointingPartyUsers: IAppointingPartyUser[];
  }>('api/um/projectmembersandteams', {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return {
    users: result.data.users,
    deliveryTeams: result.data.deliveryTeams,
    taskTeams: result.data.taskTeams,
    appointingParties: result.data.appointingParties,
    distributionList: result.data.distributionLists,
    appointingPartyUsers: result.data.appointingPartyUsers,
  };
}
