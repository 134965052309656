import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../shared/Icon';
import TeamChartStore from './TeamChartStore';
import Style from './styles/TeamChart.module.scss';
import classNames from 'classnames';
import { Accordion, AccordionPanel, Tooltip } from '@aurecon-creative-technologies/styleguide';
import UserTooltipModal from './UserTooltipModal';

const ExternalUser: FC = () => {
  const renderItemContent = (user) => {
    return (
      <div className={Style.itemTooltip}>
        <div>
          <span className={Style.userName}>{user.name}</span>
        </div>
        <div className={Style.deliveryTeamRoleGroup}>
          <div className={`${Style.deliveryTeamRoleTitle} ${Style.externalTeam}`}>
            <div className={Style.deliveryTeamRolePersonIcon}>
              <Icon name="people" className={Style.listIcon}></Icon>
            </div>
            <div>External User</div>
          </div>
        </div>
      </div>
    );
  };
  const renderItems = (user) => {
    return (
      <li
        key={user.id}
        className={classNames(Style.taskTeamItem, {
          [Style.active]: user.check,
        })}>
        <button
          id={`team-chart-external-user-${user.id}`}
          hidden
          onClick={() => TeamChartStore.addOrRemoveExUserById(user.id)}
        />
        <label className={Style.teamChartUserItem} htmlFor={`team-chart-external-user-${user.id}`}>
          {user.check && (
            <Tooltip cssClass={Style.tooltipWrapper} show={<div>User has been selected</div>} defaultUp={false}>
              {renderItemContent(user)}
            </Tooltip>
          )}
          {!user.check && renderItemContent(user)}
          <div className={Style.iconInfoWrapper}>
            <Tooltip
              cssClass={Style.tooltipWrapper}
              show={
                <UserTooltipModal
                  initials={user.initials}
                  name={user.name}
                  email={user.email}
                  groupTitle={'External User'}
                />
              }
              defaultUp={false}>
              <div className={Style.itemTooltip}>
                <div className={Style.iconInfo}>
                  <Icon name="info_outlined" className={Style.listIcon}></Icon>
                </div>
              </div>
            </Tooltip>
          </div>
        </label>
      </li>
    );
  };
  return (
    <>
      {!!TeamChartStore.filteredExternalUsers.length && (
        <Accordion
          activePanelIds={Array.from(TeamChartStore.activeExUserPanelIds)}
          onPanelToggle={TeamChartStore.onExUserPanelToggle}
          headingBgColour="#FAFAFA"
          panelBgColour="#FAFAFA"
          headingFontColour="#000000">
          <AccordionPanel panelId="exPanel" label="External Users">
            {TeamChartStore.filteredExternalUsers.map((user) => (
              <ul key={user.id} className={Style.taskTeamWrapper}>
                {renderItems(user)}
              </ul>
            ))}
          </AccordionPanel>
        </Accordion>
      )}
    </>
  );
};
export default observer(ExternalUser);
