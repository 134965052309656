import { Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import Styles from '../transmittals/styles/CreateTransmittalContentFile.module.scss';
import Icon from '../shared/Icon';
import PrimaryIconButton from '../shared/PrimaryIconButton';
import { getFileSizeString } from '../../utils/miscUtils';
import { IFileContainer } from '../../api/authenticated/cms/FileContainerModel';
import FilesStore from '../files/FilesStore';
import { TransmittalFileType, TransmittalFileTypeText } from './Types';

export interface IContentFileProps {
  contentFiles: IFileContainer[];
  onRemoveContentFile: (id: number, filetype: TransmittalFileType) => Promise<IFileContainer[]>;
}

const CreateTransmittalContentFile: FC<IContentFileProps> = ({ contentFiles, onRemoveContentFile }) => {
  const [files, setFiles] = useState<IFileContainer[]>([]);

  useEffect(() => {
    setFiles(contentFiles);
  }, [contentFiles]);

  const handleDeleteFile = async (id: number, filetype: TransmittalFileType): Promise<void> => {
    setFiles([]);
    const updatedContentFiles = await onRemoveContentFile(id, filetype);
    setFiles(updatedContentFiles);
  };

  return (
    <div className={Styles.contentFiles}>
      <div className={Styles.contentFilesHeading}>
        <Icon name="description" />
        <span className={Styles.headingText}>Content Files ({files.flatMap((x) => x.containerFiles).length})</span>
      </div>
      <Table
        headers={[
          {
            label: 'Filename',
          },
          {
            label: 'Original Filename',
          },
          {
            label: 'File Size',
          },
          {
            label: 'Actions',
          },
        ]}>
        {!!files &&
          files.map((file) => (
            <>
              <TableRow key={FilesStore.getFileKey(file)}>
                <TableCell>{file.title}</TableCell>
                <TableCell>{file.originalFilename}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <PrimaryIconButton
                    icon="delete"
                    onClick={() => handleDeleteFile(file.id, TransmittalFileTypeText.FILE_CONTAINER)}
                  />
                </TableCell>
              </TableRow>
              {!!file.containerFiles &&
                file.containerFiles
                  ?.filter((x) => x.fileContainerId === file.id)
                  .map((conFile) => (
                    <TableRow key={conFile.fileContainerId}>
                      <TableCell></TableCell>
                      <TableCell>
                        {' '}
                        <div className={Styles.fileCell}>
                          <Icon name="shortcut" className={Styles.fileIcon} />
                          {conFile.originalFilename}
                          {conFile.native && <Icon name="star_outline" className={Styles.native} />}
                        </div>
                      </TableCell>
                      <TableCell>{getFileSizeString(conFile.uploadedSize)}</TableCell>
                      <TableCell>
                        <PrimaryIconButton
                          icon="delete"
                          onClick={() =>
                            handleDeleteFile(conFile.containerFileId ?? 0, TransmittalFileTypeText.CONTAINER_FILE)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
            </>
          ))}
      </Table>
    </div>
  );
};

export default observer(CreateTransmittalContentFile);
