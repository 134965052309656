import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from './FilesStore';
import SecondaryButton from '../shared/SecondaryButton';
import ReviewModal from './reviewModal/ReviewModal';
import { getWipUsersToCollaborateWith } from '../../api/authenticated/cms/getWipUsersToCollaborateWith';
import { getNonWipUsersToCollaborateWith } from '../../api/authenticated/cms/getNonWipUsersToCollaborateWith';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { ContentSelection } from './ContentSelection';
import { FileContainerState } from '../../common/enums/FileContainerState';
import { IAddTaskData } from '../../api/authenticated/tasks/getAddData';
import { sendWipCollaborationNotification } from '../../api/authenticated/cms/sendWipCollaborationNotification';
import { sendNonWipCollaborationNotification } from '../../api/authenticated/cms/sendNonWipCollaborationNotification';
import { ISaveReviewData } from './reviewModal/ReviewStore';
import { appInsightsTrackEvent } from '../../utils/appInsights';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';

interface CollaborateButtonProps {
  taskTeamId: number;
}

const CollaborateButton: FC<CollaborateButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);

  let fileContainerStateId = FileContainerState.Wip;
  if (FilesStore.selectedSection === ContentSelection.Shared) fileContainerStateId = FileContainerState.Shared;
  if (FilesStore.selectedSection === ContentSelection.Published) fileContainerStateId = FileContainerState.Published;

  const getAddTaskData = async (): Promise<IAddTaskData> => {
    if (!NavBarSelectorStore.selectedItem)
      return { reviewers: [], suitabilities: [], acceptors: [], isRequireSingleAcceptorConfig: true };
    const users =
      fileContainerStateId === FileContainerState.Wip
        ? await getWipUsersToCollaborateWith(taskTeamId)
        : await getNonWipUsersToCollaborateWith({
            projectNumber: NavBarSelectorStore.selectedItem.project.projectNumber,
            fileContainerStateId: fileContainerStateId,
            taskTeamId: taskTeamId,
          });
    return {
      reviewers: users.map((u) => ({ userId: u.id, name: u.name, email: u.email, accessible: u.accessible })),
      suitabilities: [],
      acceptors: [],
      isRequireSingleAcceptorConfig: true,
    };
  };

  const saveTask = async (taskData: ISaveReviewData) => {
    if (!NavBarSelectorStore.selectedItem) return;
    if (fileContainerStateId === FileContainerState.Wip) {
      await sendWipCollaborationNotification({
        taskTeamId: taskTeamId,
        fileContainers: taskData.fileContainers.map((f) => ({
          fileContainerId: f.id,
          fileContainerRevisionId: f.fileRevisionId,
          releasedFileId: f.releasedFileId,
        })),
        userIds: taskData.reviewers.map((r) => r.userId),
        message: taskData.selectedMessage,
        temporaryAccessExpiredOn: taskData.temporaryAccessExpiredOn,
      });
    } else {
      await sendNonWipCollaborationNotification({
        projectNumber: NavBarSelectorStore.selectedItem.project.projectNumber,
        releasedFileIds: taskData.fileContainers.map((f) => f.releasedFileId!),
        fileContainerStateId,
        userIds: taskData.reviewers.map((r) => r.userId),
        message: taskData.selectedMessage,
        temporaryAccessExpiredOn: taskData.temporaryAccessExpiredOn,
      });
    }

    return null;
  };

  const showModal = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_COLLABORATE);
    setIsShowing(true);
  };

  return (
    <>
      <SecondaryButton disabled={!FilesStore.canCollaborateOnSelectedFiles || taskTeamId === 0} onClick={showModal}>
        Collaborate
      </SecondaryButton>
      {isShowing && (
        <ReviewModal
          title="Collaborate"
          description="Recipients will receive an email notification with a link to view the files."
          closeModal={() => setIsShowing(false)}
          approversLabel="Recipients"
          approversSearchLabel="Add Recipients"
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Collaborate"
        />
      )}
    </>
  );
};

export default observer(CollaborateButton);
