export enum FileContainerState {
  Wip = 1,
  Shared = 2,
  Published = 3,
}

export const FileContainerStateName = {
  [FileContainerState.Wip]: 'Work in Progress',
  [FileContainerState.Shared]: 'Shared',
  [FileContainerState.Published]: 'Published',
};
