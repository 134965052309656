import { serverAxios } from '../api';

interface IThumbnailUrl {
  url: string | null;
}
export async function getProjectFileThumbnailUrl(
  projectNumber: string,
  fileContainerId: number | null,
  fileContainerRevisionId: number | null,
  containerFileId: number | null,
  releasedFileContainerId: number | null,
  abortSignal?: AbortSignal
): Promise<string | null> {
  const result = await serverAxios.get<IThumbnailUrl>('api/cms/projectfile/thumbnailurl', {
    params: {
      projectNumber,
      fileContainerId,
      fileContainerRevisionId,
      containerFileId,
      releasedFileContainerId,
    },
    signal: abortSignal,
  });
  return result.data?.url;
}
