import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface DownloadFile {
  fileContainerId: number | null;
  containerFileId?: number | null;
  fileRevisionId: number | null;
  releasedFileId: number | null;
  sharePointReleasedFileId?: number | null;
}

export interface IGetProjectFileDownloadUrlRequest {
  projectNumber: string;
  fileContainerStateId?: FileContainerState | null;
  transmittalId: number | null;
  transmittalMessageId: number | null;
  downloadFiles: DownloadFile[];
}

export interface FileDownloadUrlResponse {
  url?: string;
  errorMessage?: string;
}

export async function getProjectFileDownloadUrl(
  request: IGetProjectFileDownloadUrlRequest,
  abortSignal?: AbortSignal
): Promise<FileDownloadUrlResponse[]> {
  const result = await serverAxios.post<{ urls: FileDownloadUrlResponse[] }>(
    'api/cms/projectfile/downloadurl',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data?.urls;
}
