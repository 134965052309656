import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';
import { IAddSupersedeFileContainerResponse } from './addSupersedeFile';

export interface IAddFileContainerRequest {
  fileContainerId?: number | null;
  fileContainerName?: string;
  suitabilityId?: number | null;
  suitabilityTitle?: string | null;
  suitabilityCode?: string | null;
  fileContainerRevisionId?: number | null;
  fileContainerStateId?: FileContainerState;
  autoGenerateFileContainerName?: boolean;
  taskTeamId: number;
  fieldValue1?: string;
  fieldValue2?: string;
  fieldValue3?: string;
  fieldValue4?: string;
  fieldValue5?: string;
  fieldValue6?: string;
  fieldValue7?: string;
  fieldValue8?: string;
  fieldValue9?: string;
  fieldValue10?: string;
  fieldValue11?: string;
  fieldValue12?: string;
  fieldValue13?: string;
  fieldValue14?: string;
  fieldValue15?: string;
  fieldValue16?: string;
  fieldValue17?: string;
  fieldValue18?: string;
  fieldValue19?: string;
  fieldValue20?: string;
  containerFiles: IContainerFile[];
  sequenceNumber?: string | null;
  revisionNumber?: string | null;
  apimsMetadata?: IApimsMetadata | null;
}

export interface IProcessImportContainerFile {
  fileContainerRequest: IAddFileContainerRequest;
  addFileContainerResponse: IAddFileContainerResponse | IAddSupersedeFileContainerResponse | null;
  isError: boolean;
  errorMessage?: string;
  isSupersede: boolean;
}

export interface IContainerFile {
  fileId: number | null;
  filename: string;
  totalFileSize: number;
  totalFileChunks: number;
  native?: boolean;
  file?: File;
  templateId?: number;
  isUnbranded?: boolean;
}

export interface IAddFileContainerResponse {
  fileContainerId: number;
  containerFiles: IAddingContainerFile[];
  fileContainerRevisionId: number;
  releasedFileContainerId: number;
}

export interface IApimsMetadata {
  pattern1?: string;
  pattern2?: string;
  pattern3?: string;
}

export interface IAddingContainerFile {
  containerFileId: number;
  fileName: string;
}

export async function addFileContainer(
  request: IAddFileContainerRequest,
  abortSignal?: AbortSignal
): Promise<IAddFileContainerResponse> {
  const result = await serverAxios.post<IAddFileContainerResponse>('api/cms/addFileContainer', request, {
    signal: abortSignal,
  });
  return result.data;
}
