import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';
import { ReactComponent as AureconLogo } from '../../assets/aurecon-logo-dark.svg';
import { ReactComponent as TucanaLogo } from '../../assets/tucana-logo-dark.svg';
import { ReactComponent as AureconALogo } from '../../assets/aurecon-a-light.svg';
import Style from './styles/Error.module.scss';
import AppStore from '../../stores/AppStore';
import PrimaryButton from '../shared/PrimaryButton';
import { classNames } from '../../utils/miscUtils';
import { ErrorCode } from '../../common/constants/ErrorStatusText';

const ErrorPage: FC = () => {
  const params = useParams();

  const getErrorMessage = () => {
    switch (params.errorCode) {
      case ErrorCode.UnAuthorised:
        return {
          primaryErrorMessage: 'Access Denied',
          secondaryErrorMessage: "You don't have access.",
          subErrorMessage: '',
          showTucanaLogo: false,
          showBackButton: true,
          initialContactMessage: 'Please',
        };
      case ErrorCode.Not_Found:
        return {
          primaryErrorMessage: '404',
          secondaryErrorMessage: 'Error not found',
          subErrorMessage: 'The resource is not found.',
        };
      case ErrorCode.Internal_Server:
        return {
          primaryErrorMessage: '500',
          secondaryErrorMessage: 'Internal server error',
          subErrorMessage: 'The system is encountering some issues or having time out.',
        };
      case ErrorCode.Map_Error:
        return {
          primaryErrorMessage: 'Page error',
          secondaryErrorMessage: 'Something went wrong',
          showTucanaLogo: false,
          showBackButton: true,
        };
      default:
        return {
          primaryErrorMessage: params.errorCode,
          secondaryErrorMessage: 'Something went wrong.',
        };
    }
  };

  const {
    primaryErrorMessage,
    secondaryErrorMessage,
    subErrorMessage = '',
    showTucanaLogo = true,
    showBackButton = false,
    initialContactMessage = 'Please contact your admin or',
  } = getErrorMessage();

  const handleBackPress = () => {
    window.location.href = `/`;
  };

  return (
    <div className={Style.container}>
      <div
        className={classNames(Style.aureconLogo, [showBackButton, Style.hasHoverState])}
        {...(showBackButton && { onClick: () => handleBackPress() })}>
        <AureconLogo height={25} width={100} />
      </div>
      <div className={Style.aureconBottomLogo}>
        <AureconALogo />
      </div>
      <div className={Style.verticalCenter}>
        <div className={Style.inner}>
          {showTucanaLogo && <TucanaLogo height={50} width={200} />}
          <h1>{primaryErrorMessage}</h1>
          <div className={Style.subHeader}>{secondaryErrorMessage}</div>
          <p>{subErrorMessage}</p>
          <p>
            {initialContactMessage}{' '}
            <a href={'mailto:' + AppStore.client!.supportUrl} target="_self">
              Contact Support
            </a>{' '}
            for any further assistance.
          </p>
          {showBackButton && <PrimaryButton onClick={handleBackPress}>Take me home</PrimaryButton>}
        </div>
      </div>
    </div>
  );
};

export default observer(ErrorPage);
