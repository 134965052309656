import { FileContainerState } from '../../../common/enums/FileContainerState';
import { TaskStatus } from '../../../common/enums/TaskStatus';
import { TaskType } from '../../../common/enums/TaskType';
import { serverAxios } from '../api';
import { UploadContentHistoryType } from '../../../common/enums/UploadContentHistoryType';
import { FileContainerUpdateHistoryType } from '../../../common/enums/FileContainerUpdateHistoryType';

export interface IContainerFile {
  containerFileId: number;
  originalFilename: string;
  native: boolean;
  hasDocumentViewer: boolean;
  isForgeFile: boolean;
}

export interface IReleasedHistoryItem {
  releasedFileContainerId: number;
  fileContainerId: number;
  fileContainerRevisionId: number;
  containerFiles: IContainerFile[];
  nativeContainerFileHistories: INativeContainerFileHistory[];
  formattedRevision: string;
  suitabilityCode?: string;
  suitabilityTitle?: string;
  containerFileId?: number;
  fileContainerUpdateHistory: IFileContainerUpdateHistory[];
}

export interface INativeContainerFileHistory {
  updatedDate: Date;
  currentNativeFilename: string;
  previousNativeFilename?: string;
  updatedByUserName: string;
}
export interface IFileContainerUpdateHistory {
  fileContainerUpdateHistoryId: number;
  fileContainerUpdateHistoryTypeId: FileContainerUpdateHistoryType;
  metadataFieldTitle: string;
  currentValue: string;
  previousValue?: string;
  updatedByUserName: string;
  updatedDate: Date;
}
export interface IUploadHistoryItem {
  userName: string;
}

export interface ITaskUser {
  userId: number;
  userName: string;
}

export interface ITaskCreatedEvent {
  createdBy: ITaskUser;
  reviewers: ITaskUser[];
}

export interface ITaskAttachmentFileEvent {
  createdBy: ITaskUser;
}

export interface ITaskApprovedEvent {
  approvedBy: ITaskUser;
}

export interface ITaskRejectedEvent {
  rejectedBy: ITaskUser;
}

export interface ITaskReassignedEvent {
  reassignedBy: ITaskUser;
  reassignedTo: ITaskUser;
}

export interface ITaskWithdrawnEvent {
  withdrawnBy: ITaskUser;
}
export interface ITaskEvent {
  eventDateTime: Date;
  taskCreatedEvent?: ITaskCreatedEvent;
  taskApprovedEvent?: ITaskApprovedEvent;
  taskRejectedEvent?: ITaskRejectedEvent;
  taskReassignedEvent?: ITaskReassignedEvent;
  taskAttachmentFileEvent?: ITaskAttachmentFileEvent;
  taskWithdrawnEvent?: ITaskWithdrawnEvent;
}

export interface ITaskHistoryItem {
  taskId: number;
  taskTypeId: TaskType;
  taskStatusId: TaskStatus;
  displayTaskStatus: string;
  taskEvents: ITaskEvent[];
}

export interface IUploadContentHistoryItem {
  id: number;
  fileContainerRevisionId: number;
  fileData: string | null;
  message: string;
  uploadedByUserName: string;
  uploadedDate: Date;
  uploadContentHistoryTypeId: UploadContentHistoryType;
}

export interface IHistoryItem {
  eventDateTime: Date;
  releaseHistoryItem?: IReleasedHistoryItem;
  uploadHistoryItem?: IUploadHistoryItem;
  taskHistoryItem?: ITaskHistoryItem;
  uploadContentHistoryItem?: IUploadContentHistoryItem;
}

export async function getFileHistory(
  projectNumber: string,
  fileContainerId: number,
  fileContainerStateId: FileContainerState,
  releasedFileId: number | null,
  transmittalId: number | null,
  transmittalMessageId: number | null,
  abortSignal?: AbortSignal
): Promise<IHistoryItem[]> {
  const result = await serverAxios.get<{ historyItems: IHistoryItem[] }>('api/cms/history', {
    params: {
      projectNumber,
      fileContainerId,
      fileContainerStateId,
      releasedFileId,
      transmittalId,
      transmittalMessageId,
    },
    signal: abortSignal,
  });
  return result.data?.historyItems ?? [];
}
