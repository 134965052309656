import { serverAxios } from '../api';
import { IDeliveryTeam, ITaskTeam } from './getDeliveryTeamsTaskTeamsUsers';
import { ITaskTeamUser } from './interfaces/user.interface';

export interface IRemoveUserFromTaskTeamRequest {
  projectNumber: string;
  taskTeamId: number;
  userId: number;
}

export interface IRemoveTaskTeamUser {
  deliveryTeam: IDeliveryTeam | null;
  taskTeam: ITaskTeam | null;
  taskTeamUser: ITaskTeamUser | null;
}

export function removeUserFromTaskTeam(
  request: IRemoveUserFromTaskTeamRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/taskteam/users/remove', request, {
    signal: abortSignal,
  });
}
