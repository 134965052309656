import { serverAxios } from '../api';

export interface FileDownloadUrlResponse {
  url?: string;
  errorMessage?: string;
}
export interface ITransmittalFileDownloadUrlRequest {
  projectNumber: string;
  attachmentFileIds: number[];
  transmittalId: number | null;
  transmittalMessageId: number | null;
}

export async function getTransmittalFileDownloadUrl(
  request: ITransmittalFileDownloadUrlRequest,
  abortSignal?: AbortSignal
): Promise<FileDownloadUrlResponse[]> {
  const result = await serverAxios.post<{ urls: FileDownloadUrlResponse[] }>(
    'api/transmittals/getAttachmentDownloadUrl',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data?.urls;
}
