import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from '../../../../styles/components/settings/systemAdminSettings/systemSettings/GeneralSettings.module.scss';
import { Toggle } from '@aurecon-creative-technologies/styleguide';

interface IUsersSettingsProps {
  defaultValue: boolean;
}
const UsersSettings: FC<IUsersSettingsProps> = (props) => {
  const { defaultValue } = props;

  const [syncUserFromADSetting, setSyncUserFromADSetting] = useState<boolean>(defaultValue);

  return (
    <div className={Styles.settingsContainer}>
      <p className={Styles.headingLabel}>Users Database</p>
      <div>
        <Toggle
          label="Sync users with Azure Database"
          labelPosition="top"
          onLabel="Yes"
          offLabel="No"
          disabled
          value={syncUserFromADSetting}
          onChange={setSyncUserFromADSetting}
        />
      </div>
    </div>
  );
};

export default observer(UsersSettings);
