import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { SearchBox, Pill } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/UserSelector.module.scss';

export interface IUser {
  id: number;
  name: string;
  email: string;
}

export interface IUserSelectorProps {
  label?: string;
  required?: boolean;
  searchPlaceholder: string;
  disabled?: boolean;
  isMultiUser?: boolean;
  getUsers(text: string): IUser[];
  onSelectedUsersUpdated(selectedUsers: IUser[]): void;
  defaultSelectedUsers?: IUser[];
  refreshSuggestion?: boolean;
}

const UserSelector: FC<IUserSelectorProps> = ({
  label,
  required,
  searchPlaceholder,
  disabled,
  isMultiUser,
  getUsers,
  onSelectedUsersUpdated,
  defaultSelectedUsers,
  refreshSuggestion,
}) => {
  const [disableSearchBox, setDisableSearchBox] = useState(false);
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [keyword, setKeyword] = useState<string>('');

  useEffect(() => {
    setSelectedUsers(defaultSelectedUsers ?? []);
  }, [defaultSelectedUsers]);

  useEffect(() => {
    if (refreshSuggestion === undefined) return;

    setUsers(getUsers(keyword));
  }, [refreshSuggestion, keyword, getUsers]);

  const removeSelectedUser = (id: number) => {
    if (disabled) return;

    const updatedSelectedUsers = selectedUsers.filter((u) => u.id !== id);
    setSelectedUsers(updatedSelectedUsers);
    onSelectedUsersUpdated(updatedSelectedUsers);

    if (updatedSelectedUsers.length === 0 && disableSearchBox) setDisableSearchBox(false);
  };

  const addSelectedUser = (id: number) => {
    if (disabled) return;

    const user = users.find((u) => u.id === id);
    const exists = selectedUsers.find((u) => u.id === id);
    if (!user || exists) return;

    const updatedSelectedUsers = [...selectedUsers, user];
    setSelectedUsers(updatedSelectedUsers);
    onSelectedUsersUpdated(updatedSelectedUsers);

    setUsers([]);
    setTriggerCleanup(triggerCleanup + 1);
    setKeyword('');
    if (!isMultiUser) setDisableSearchBox(true);
  };

  const loadUsers = (text: string) => {
    if (disabled) return;

    setKeyword(text);

    if (text === '') setUsers([]);
    else setUsers(getUsers(text));
  };

  const filteredUsers = useMemo(() => {
    if (!keyword) return;

    return users
      .filter((user) => !selectedUsers.some(({ id }) => user.id === id))
      .map((r) => ({
        id: r.id,
        value: `${r.name} (${r.email})`,
      }));
  }, [selectedUsers, users, keyword]);

  return (
    <>
      {label && (
        <label className={Style.label}>
          {label}
          {required && <span className={Style.required}>*</span>}
        </label>
      )}
      {selectedUsers.map((u) => (
        <Pill key={u.id} colour={1} onClose={() => removeSelectedUser(u.id)} cssClass={Style.userPill}>
          {u.name}
        </Pill>
      ))}
      <div className={Style.searchBoxContainer}>
        <SearchBox
          placeholder={searchPlaceholder}
          hideSearchButton
          disableDefaultMatching
          suggestions={filteredUsers}
          onChange={(text) => loadUsers(text)}
          onSearch={(text) => loadUsers(text)}
          onSelect={(s) => addSelectedUser(Number(s.id))}
          triggerCleanup={triggerCleanup}
          disabled={disabled || disableSearchBox}
        />
      </div>
    </>
  );
};

export default observer(UserSelector);

export function getUsersByNameAndEmail(users: IUser[], text): IUser[] {
  const lowercaseText = text?.toLowerCase() ?? '';
  return users.filter(
    (u) => u.name.toLowerCase().includes(lowercaseText) || u.email.toLowerCase().includes(lowercaseText)
  );
}
