import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../shared/Modal';
import Style from './styles/SuccessModal.module.scss';
import CloseButton from '../shared/CloseButton';
import PrimaryButton from '../shared/PrimaryButton';
import Icon from '../shared/Icon';
import ModalActions from '../shared/ModalActions';
import CentreOnPage from './CentreOnPage';
import Overlay from './Overlay';

interface ISuccessModalProps {
  closeModal: () => void;
  title: string;
  info: string;
}

const SuccessModal: FC<ISuccessModalProps> = (props) => {
  const { closeModal, info, title } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={() => closeModal()} disabled={false} />
        <div className={Style.info}>
          <Icon name="check_circle" className={Style.icon} />
          <h3>{title}</h3>
          <p>{info}</p>
        </div>
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Done</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(SuccessModal);
