import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../shared/Modal';
import CloseButton from '../shared/CloseButton';
import PrimaryButton from '../shared/PrimaryButton';
import ModalActions from '../shared/ModalActions';
import CentreOnPage from '../shared/CentreOnPage';
import Overlay from '../shared/Overlay';
import SecondaryButton from '../shared/SecondaryButton';
import Style from './styles/TaskWithdrawModal.module.scss';

interface ITaskWithdrawModalProps {
  closeModal: () => void;
  withdrawAction: () => void;
  showModal: boolean;
}

const TaskWithdrawModal: FC<ITaskWithdrawModalProps> = (props) => {
  const { closeModal, withdrawAction, showModal } = props;

  if (!showModal) return null;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={() => closeModal()} />
        <div className={Style.info}>
          <h1>Withdraw the task</h1>
          <p>
            Withdrawing the task will change the status to &quot;withdrawn&quot; and unlock the files. Are you sure you
            want continue?
          </p>
        </div>
        <ModalActions>
          <SecondaryButton onClick={() => closeModal()}>No</SecondaryButton>
          <PrimaryButton onClick={() => withdrawAction()}>Yes</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TaskWithdrawModal);
