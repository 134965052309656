import axios from 'axios';
import config from '../../config';
import { handleDates } from '../../utils/dateUtils';

export const serverAxios = axios.create({
  baseURL: config.serverUri,
});

serverAxios.interceptors.response.use((response) => {
  handleDates(response.data);
  return response;
});
