import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskWithdrawnEvent } from '../../../api/authenticated/cms/getFileHistory';
import TaskEvent from './TaskEvent';

export interface ITaskEventWithdrawnProps {
  taskWithdrawnEvents: ITaskWithdrawnEvent[];
}

const TaskEventWithdrawn: FC<ITaskEventWithdrawnProps> = ({ taskWithdrawnEvents }) => {
  if (!taskWithdrawnEvents.length) return null;

  return (
    <TaskEvent icon="cancel" label="Withdrawn by">
      <div key={taskWithdrawnEvents[0].withdrawnBy.userId}>{taskWithdrawnEvents[0].withdrawnBy.userName}</div>
    </TaskEvent>
  );
};

export default observer(TaskEventWithdrawn);
