import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TaskInformationDetails.module.scss';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { formatDate } from '../../utils/dateUtils';
import TaskInformationStore from './TaskInformationStore';
import TaskInformationAssignedBy from './TaskInformationAssignedBy';
import TaskInformationAssignedTo from './TaskInformationAssignedTo';
import classNames from 'classnames';
import WorkflowPill from '../shared/WorkflowPill';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Icon from '../shared/Icon';

const TaskInformationDetails: FC = () => {
  if (TaskInformationStore.task === null) return null;

  return (
    <ul className={Style.panel}>
      <li className={Style.message}>{TaskInformationStore.task.message}</li>
      <div className={Style.detailPanel}>
        <li className={Style.column}>
          <div className={Style.label}>Workflow</div>
          <div className={Style.workflowText}>{TaskTypeText[TaskInformationStore.task.taskTypeId]}</div>
          <div className={Style.workflowPill}>
            <WorkflowPill taskTypeId={TaskInformationStore.task.taskTypeId} />
          </div>
        </li>
        <li className={Style.column}>
          <div className={Style.label}>Suitability</div>
          <div className={Style.columnValue}>{TaskInformationStore.task.suitabilityTitle}</div>
        </li>
        <li className={classNames(Style.divider, Style.fullWidthDivider)}></li>
        <li className={Style.column}>
          <div className={Style.label}>Start Date</div>
          <div className={Style.columnValue}>{formatDate(TaskInformationStore.task.createdDate)}</div>
        </li>
        <li className={Style.column}>
          <div className={Style.label}>Last Updated</div>
          <div className={Style.columnValue}>{formatDate(TaskInformationStore.task.lastUpdatedDate)}</div>
        </li>
        {TaskInformationStore.task.dueDate && (
          <li className={Style.column}>
            <div className={Style.label}>Due Date</div>
            <div className={Style.columnValue}>{formatDate(TaskInformationStore.task.dueDate)}</div>
          </li>
        )}
      </div>
      <li className={Style.divider}></li>
      <div className={Style.assignPanel}>
        <li className={Style.column}>
          <div className={Style.label}>Assigned by</div>
          <TaskInformationAssignedBy />
        </li>
        <li className={Style.column}>
          <div className={Style.assignedToWrapper}>
            <div className={Style.assignedToLabel}>
              Assigned to
              {!TaskInformationStore.task.isSingleApproverRequired && (
                <div className={Style.requiredAllToolTipIcon}>
                  <Tooltip
                    show={<span className={Style.requiredAllToolTipText}>Required all users to approve</span>}
                    defaultUp={false}>
                    <Icon className={Style.requiredIcon} name="check_circle" />
                  </Tooltip>
                </div>
              )}
            </div>
            <TaskInformationAssignedTo />
          </div>
        </li>
      </div>
    </ul>
  );
};

export default observer(TaskInformationDetails);
