import { serverAxios } from '../api';

export interface IAddDistributionListRequest {
  projectNumber: string;
  title: string;
}

export async function addDistributionList(
  request: IAddDistributionListRequest,
  abortSignal?: AbortSignal
): Promise<number> {
  const result = await serverAxios.post<{ distributionListId: number }>('api/um/distributionlist/add', request, {
    signal: abortSignal,
  });
  return result.data.distributionListId;
}
