export const AppInsightPageView = {
  LOGIN_PAGE: 'Login Page',
  PROJECT_INFO: 'Project Info',
  PROJECT_SETTINGS: 'Project Settings',
  USER_MANAGEMENT: 'User Management',
  PRIVACY_POLICY: 'Privacy Policy',
  TERM_CONDITION: 'Term Condition',
  TASK_DETAILS: 'Task Details',
  TASK_LIST: 'Task List',
  TRANSMITTAL_RESPONSE: 'Respond to Transmittals',
  TRANSMITTAL_DETAILS: 'Transmittal Details',
  CREATE_NEW_TRANSMITTAL: 'Create Transmittals',
  TRANSMITTALS_LIST: 'Transmittals List',
  VISUALISATION: 'Visualisation',
  FILES: 'File Page',
  SYSTEM_SETTINGS: 'System Settings',
  FILE_UPLOAD: 'File Upload',
};
