import { serverAxios } from '../api';

export interface ISharedAndPublishedDeliveryTeamAccessResponse {
  sharedDeliveryTeams: DeliveryTeam[];
  publishedDeliveryTeams: DeliveryTeam[];
}

export interface DeliveryTeam {
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  deliveryTeamCode: string;
}

export async function getSharedAndPublishedDeliveryTeamAccess(
  projectNumber: string,
  deliveryTeamId: number,
  abortSignal?: AbortSignal
): Promise<ISharedAndPublishedDeliveryTeamAccessResponse> {
  const result = await serverAxios.get<ISharedAndPublishedDeliveryTeamAccessResponse>(
    'api/um/deliveryteam/getsharedandpublisheddeliveryteamaccess',
    {
      params: {
        projectNumber: projectNumber,
        deliveryTeamId: deliveryTeamId,
      },
      signal: abortSignal,
    }
  );
  return result.data;
}
