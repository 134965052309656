export enum MetadataFieldType {
  UserText = 'UserText',
  List = 'List',
  Numeric = 'Numeric',
  Alphabet = 'Alphabet',
}

export enum ApimsMetaDataType {
  Pattern1 = 'Pattern 1',
  Pattern2 = 'Pattern 2',
  Pattern3 = 'Pattern 3',
}

export const MetadataFieldTitle = ['Description', 'Title'];

export enum MetadataFieldIndex {
  Discipline = 1,
  ProjectPhase = 2,
  DocumentType = 3,
  DocumentSubtype = 4,
  Zone = 5,
  Area = 6,
  Asset = 7,
  Workstream = 8,
  Description = 9,
}

export enum MetaDataFieldTypeEnum {
  TucanaMetaDataField = 1,
  APIMSMetaDataField = 2,
}
