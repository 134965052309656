import { serverAxios } from '../api';

export interface IUploadClientFileTemplateRequest {
  blob: Blob;
  fileTemplateId: number;
  index: number;
  offset: number;
  isLastChunk: boolean;
}

export async function uploadClientFileTemplate(
  request: IUploadClientFileTemplateRequest,
  abortSignal?: AbortSignal
): Promise<number> {
  const formData = new FormData();
  formData.append('file', request.blob);
  formData.append('fileTemplateId', request.fileTemplateId.toString());
  formData.append('index', request.index.toString());
  formData.append('offset', request.offset.toString());
  formData.append('isLastChunk', request.isLastChunk.toString());
  const result = await serverAxios.post<number>('api/config/uploadClientFileTemplate', formData, {
    signal: abortSignal,
  });
  return result.data;
}
