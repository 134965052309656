import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/AddingAcceptorForDeliveryTeam.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import { IUser } from '../../shared/UserSelector';
import AppointingPartyStore from '../../../stores/settings/teamManagement/AppointingPartyStore';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import { Loader, Tooltip } from '@aurecon-creative-technologies/styleguide';
import Icon from '../../shared/Icon';

interface IAddingAcceptorForDeliveryTeamProps {
  projectNumber: string;
  deliveryTeamId: number;
}

const AddingAcceptorForDeliveryTeam: FC<IAddingAcceptorForDeliveryTeamProps> = (props) => {
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const effectAsync = async () => {
      setLoading(true);
      await TeamManagementStore.fetchDeliveryTeamAcceptorUsers(props.projectNumber, props.deliveryTeamId);
      await AppointingPartyStore.init(props.projectNumber);
      setLoading(false);
    };

    effectAsync();
  }, [props.projectNumber, props.deliveryTeamId]);

  const onUsersSelected = (users: IUser[]) => {
    TeamManagementStore.setDeliveryTeamAcceptorUsers(users);
  };

  const handleCancelEditPermission = async () => {
    setEditMode(false);
  };

  const save = async () => {
    setSaving(true);
    await TeamManagementStore.addDeliveryTeamAcceptorUsers(props.projectNumber, props.deliveryTeamId);
    setEditMode(false);
    setSaving(false);
  };

  if (loading) return <Loader />;

  return (
    <div className={Style.pageWrapper}>
      <div className={Style.grantingAccessBox}>
        <div className={Style.headingWrapper}>
          <div className={Style.heading}>Adding acceptors</div>
          <div className={Style.save}>
            {editMode ? (
              <>
                <SecondaryButton onClick={handleCancelEditPermission}>Cancel</SecondaryButton>
                <PrimaryButton onClick={save} loading={saving}>
                  Save
                </PrimaryButton>
              </>
            ) : (
              <SecondaryButton onClick={() => setEditMode(true)}>Edit</SecondaryButton>
            )}
          </div>
        </div>
        <div className={Style.deliveryTeamContainer}>
          <div className={Style.addAcceptor}>
            <span className={Style.addAcceptorLabel}>
              Preferred Appointing Party{''}
              <span className={Style.tooltipIcon}>
                <Tooltip
                  show={
                    <div className={Style.tooltipText}>
                      Add acceptors from Appointing Party to accept files before appearing in Published
                    </div>
                  }
                  defaultUp={true}>
                  <Icon name="info_outlined" className={Style.listIcon}></Icon>
                </Tooltip>
              </span>
            </span>

            <div className={Style.addUserRow}>
              <UserSelectorExpandable
                required
                searchPlaceholder="Add acceptors..."
                isMultiUser
                className={Style.searchBox}
                disabled={!editMode}
                getUsers={(keyword) => AppointingPartyStore.searchAccepterUsers(keyword)}
                onSelectedUsersUpdated={onUsersSelected}
                defaultSelectedUsers={TeamManagementStore.deliveryTeamAcceptorUsers}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddingAcceptorForDeliveryTeam);
