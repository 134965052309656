import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/UploadUnsavedModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import PrimaryButton from '../../shared/PrimaryButton';

interface IModalProps {
  closeModal: () => void;
  redirect: () => void;
}

const UploadUnsavedModal: FC<IModalProps> = (props) => {
  const { closeModal, redirect } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h2>Cancel Uploads</h2>
        <p>Your progress will not be saved and the Files wil not be uploaded. Do you want to continue?</p>
        <ModalActions>
          <PrimaryButton onClick={() => redirect()}>Ok</PrimaryButton>
          <PrimaryButton onClick={() => closeModal()}>Cancel</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(UploadUnsavedModal);
