import { makeAutoObservable, runInAction } from 'mobx';
import { getTemporaryAccessLogs } from '../../../api/authenticated/cms/getTemporaryAccessLogs';
import { IPageMetaData, IPagedResponse } from '../../../common/models/IPagedResponse';
import {
  GetTemporaryAccessLogRequest,
  ITemporaryFileAccessLog,
} from '../../../api/authenticated/cms/interface/getTemporaryAccessLogRequest';
import config from '../../../config';
import { ITableColumn } from '../../../common/interfaces/TableColumn';
import { SortType, SortTypes } from '../../../common/enums/SortType';
import { sort } from '../../../utils/sortHelper';
import { flatMap } from 'lodash';
import { IItem } from '../../../components/tasks/navBarSelector/NavBarSelectorStore';
import { PagingArray } from '../../../utils/pagingArray';
import { revokeTemporaryAccess } from '../../../api/authenticated/cms/revokeTemporaryAccess';
import { AxiosError } from 'axios';
import { IProject, getProjects } from '../../../api/authenticated/config/getProjects';

const ActionColumn = 'actionColumn';
export const tableHeaderKeyValueFields = {
  Filename: {
    label: 'Filename',
    value: 'fileContainerTitle',
  },
  sharedToUser: {
    label: 'User name',
    value: 'sharedToUser',
  },
  taskTeamOriginator: {
    label: 'Task Team Originator',
    value: 'taskTeamOriginator',
  },
  fileContainerState: {
    label: 'State',
    value: 'fileContainerState',
  },
  grantedByUser: {
    label: 'Granted by',
    value: 'grantedByUser',
  },
  grantedDate: {
    label: 'Granted Date',
    value: 'grantedDate',
  },
  expiryDate: {
    label: 'Expiry Date',
    value: 'expiryDate',
  },
  status: {
    label: 'Active',
    value: 'status',
  },
  actionColumn: {
    label: 'Revoke',
    value: ActionColumn,
  },
};

export const ActionColumns = [ActionColumn];

export class TemporaryAccessLogStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public showError = false;
  public loading = false;
  public loadingLabel = 'Temporary access logs loading';
  public selectProjectNumber?: string | number;
  public selectProjectItemId?: string | number;
  public pagingMetaData: IPageMetaData = {
    pageNumber: 0,
    pageCount: 0,
  } as IPageMetaData;
  public pageNumber = 1;
  public pageSize = config.defaultPageSize;
  public accessLogFilters: GetTemporaryAccessLogRequest = new GetTemporaryAccessLogRequest();
  public tableColumns: ITableColumn[] = [];
  public fileName?: string;

  public data: ITemporaryFileAccessLog[] = [];

  public items: IItem[] = [];

  public initData() {
    this.loadTableColumns();

    runInAction(async () => {
      this.items = await this.buildOptionItems();
      this.accessLogFilters.setConditions(this.pageNumber, this.pageSize, this.selectProjectNumber?.toString());
    });
  }

  public onSortColumnChange(column: string, direction?: SortType) {
    runInAction(() => {
      this.data = [];
      this.accessLogFilters.sortColumn = column;
      this.accessLogFilters.sortDirection = direction;

      this.tableColumns.forEach((c) => {
        if (c.valueField === column) {
          c.sort = direction;
        } else {
          c.sort = SortTypes.NONE;
        }
      });
    });

    this.loadTemporaryAccessLogs();
  }

  public async changeProject(id: string | number) {
    const selectedItem = this.items.find((item) => item.id === id);
    if (!selectedItem || selectedItem.programme) return;

    runInAction(() => {
      this.data = [];
      this.selectProjectItemId = selectedItem.id;
      this.selectProjectNumber = selectedItem.project?.projectNumber.toString();
      this.accessLogFilters.projectNumber = selectedItem.project?.projectNumber.toString();
    });
    await this.loadTemporaryAccessLogs();
  }

  public async revokeTemporaryAccess(item: ITemporaryFileAccessLog) {
    if (!this.selectProjectNumber) return;
    runInAction(() => {
      this.loading = true;
      this.loadingLabel = 'Revoking temporary access';
      this.showError = false;
    });
    try {
      await revokeTemporaryAccess({ id: item.id, projectNumber: this.selectProjectNumber.toString() });
      await this.loadTemporaryAccessLogs();
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        this.loading = false;
        this.loadingLabel = '';
      });
    }
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
      this.showError = true;
    });
  }

  public async setCurrentPage(page) {
    if (page === this.pageNumber) return;
    runInAction(() => {
      this.pageNumber = page;
      this.accessLogFilters.projectNumber = this.selectProjectNumber?.toString();
      this.accessLogFilters.pageSize = config.defaultPageSize;
      this.accessLogFilters.page = page;
      this.data = [];
    });
    await this.loadTemporaryAccessLogs();
  }

  public async filterChange(tableColumn: ITableColumn, value?: string) {
    if (tableColumn.label !== tableHeaderKeyValueFields.Filename.label) return;
    runInAction(() => {
      this.pageNumber = 1;
      this.accessLogFilters.projectNumber = this.selectProjectNumber?.toString();

      this.accessLogFilters.pageSize = config.defaultPageSize;
      this.accessLogFilters.page = 1;
      this.accessLogFilters.title = value?.trim();
      this.data = [];
    });
    await this.loadTemporaryAccessLogs();
  }

  private loadTableColumns() {
    const tableColumns: ITableColumn[] = [
      {
        label: tableHeaderKeyValueFields.Filename.label,
        valueField: tableHeaderKeyValueFields.Filename.value,
        textFilter: { filter: this.fileName },
      },
      {
        label: tableHeaderKeyValueFields.sharedToUser.label,
        valueField: tableHeaderKeyValueFields.sharedToUser.value,
      },
      {
        label: tableHeaderKeyValueFields.taskTeamOriginator.label,
        valueField: tableHeaderKeyValueFields.taskTeamOriginator.value,
      },
      {
        label: tableHeaderKeyValueFields.fileContainerState.label,
        valueField: tableHeaderKeyValueFields.fileContainerState.value,
        width: 70,
      },
      {
        label: tableHeaderKeyValueFields.grantedByUser.label,
        valueField: tableHeaderKeyValueFields.grantedByUser.value,
      },
      {
        label: tableHeaderKeyValueFields.grantedDate.label,
        valueField: tableHeaderKeyValueFields.grantedDate.value,
        sort: SortTypes.DESC,
        width: 148,
      },
      {
        label: tableHeaderKeyValueFields.expiryDate.label,
        valueField: tableHeaderKeyValueFields.expiryDate.value,
        width: 120,
      },
      {
        label: tableHeaderKeyValueFields.status.label,
        valueField: tableHeaderKeyValueFields.status.value,
        width: 67,
      },
      {
        label: tableHeaderKeyValueFields.actionColumn.label,
        valueField: tableHeaderKeyValueFields.actionColumn.value,
        width: 74,
      },
    ];

    runInAction(() => {
      this.tableColumns = tableColumns;
    });
  }

  private async loadTemporaryAccessLogs() {
    runInAction(() => {
      this.loading = true;
      this.loadingLabel = 'Temporary access logs loading';
    });
    const res = await getTemporaryAccessLogs(this.accessLogFilters);
    this.setPagingMetaData(res);

    runInAction(() => {
      this.data = res.data;
      this.loading = false;
    });
  }

  private setPagingMetaData(res: IPagedResponse<ITemporaryFileAccessLog>) {
    const paginatedListObject = new PagingArray(this.pageSize, this.pageNumber, res.totalCount, res.pageCount);
    runInAction(() => {
      this.pagingMetaData = paginatedListObject.getMetaData();
    });
  }

  private async buildOptionItems() {
    const res = await getProjects();
    const programmes: { id: number; title: string; projects: IProject[] }[] = [];

    res.forEach((project) => {
      const existingProgramme = programmes.find((a) => a.id === project.programmeId);

      if (existingProgramme) {
        existingProgramme.projects = [...existingProgramme.projects, project];
      } else {
        programmes.push({ id: project.programmeId, title: project.programmeTitle, projects: [project] });
      }
    });

    return [
      ...flatMap(sort(programmes || [], 'title', SortTypes.ASC), (programme) => [
        {
          id: `programmeId:${programme.id}`,
          programme,
          key: `${programme.title}`,
        },
        ...sort(programme.projects || [], 'title', SortTypes.ASC).map((project) => ({
          id: `projectNumber:${project.projectNumber}`,
          project: { ...project, programmeId: programme.id, programmeTitle: programme.title },
          key: `${programme.title}-${project.title}`,
        })),
      ]),
    ];
  }

  public cleanUp() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
      this.showError = false;
    });
  }
}

export default new TemporaryAccessLogStore();
