import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/FileOverLimitErrorModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Modal from '../../shared/Modal';
import Overlay from '../../shared/Overlay';
import ModalActions from '../../shared/ModalActions';
import PrimaryButton from '../../shared/PrimaryButton';
import UploadStore from './UploadStore';

interface IErrorModalProps {
  closeModal: () => void;
}

const FileOverLimitErrorModal: FC<IErrorModalProps> = (props) => {
  const { closeModal } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <h2>Upload limit of {UploadStore.fileUploadLimit} Files</h2>
        <p>Please select {UploadStore.fileUploadLimit} Files or fewer to upload.</p>
        <ModalActions>
          <PrimaryButton onClick={() => closeModal()}>Ok</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(FileOverLimitErrorModal);
