import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import ClientLogo from '../../assets/client-logo.svg';
import { ReactComponent as TucanaLogo } from '../../assets/tucana-logo.svg';
import { ReactComponent as TucanaFooterLogo } from '../../assets/tucana-footer-logo.svg';
import { ReactComponent as AureconLogo } from '../../assets/aurecon-logo.svg';
import { classNames } from '../../utils/miscUtils';
import { Links } from '../../common/constants/Links';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';

import Style from './styles/Login.module.scss';
import { useAuth } from '../../authentication/hooks/useAuth';
import { Pages } from '../../common/constants/Pages';
import { useLocation } from 'react-router-dom';

interface ILocationModel {
  from: IFromStateModel;
}
interface IFromStateModel {
  hash: string;
  pathname: string;
  search: string;
  state: string;
}

const Login: FC = () => {
  const { login } = useAuth();
  const location = useLocation();

  const handleButtonClick = (url: string): void => {
    window.open(url, '_blank', 'noopener');
  };

  const handleLogin = (): void => {
    login(getRedirectStartPageUrl());
  };

  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights) appInsights.trackPageView({ name: AppInsightPageView.LOGIN_PAGE, isLoggedIn: true });
  }, []);

  const getRedirectStartPageUrl = () => {
    const locationModel = location.state as ILocationModel;
    return locationModel && !locationModel.from.pathname.includes(Pages.Error.Route)
      ? '/#' + locationModel.from.pathname
      : '/';
  };

  return (
    <div className={classNames(Style.loginPage, 'login')}>
      <div className={Style.leftGradient} />
      <div className={Style.loginContainer}>
        <div className={Style.header}>
          <img className={Style.logo} src={ClientLogo} alt="" />
        </div>
        <div className={Style.content}>
          <TucanaLogo />
          <p>Bringing project teams together through automation, collaboration, and visualisation</p>
          <SecondaryButton onClick={handleLogin}>Login</SecondaryButton>
        </div>
        <div className={Style.footer}>
          <div className={Style.tucanaLogo}>
            <TucanaFooterLogo height={25} />
            <AureconLogo height={40} />
          </div>
          <PrimaryButton className={Style.textOnly} onClick={() => handleButtonClick(Links.contactPortal)}>
            Contact Us
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default observer(Login);
