import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { ComboBox } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/ListFilter.module.scss';
import { IListFilter, IOptionListFilter } from '../../common/interfaces/TableColumn';
import classNames from 'classnames';

export interface IListFilterProps {
  listFilter: IListFilter;
  customListFilter?: IOptionListFilter[];
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
  onSelectItem?: (value: string) => void;
  onSelectItems?: (values: string[]) => void;
}

const ListFilter: FC<IListFilterProps> = ({
  listFilter,
  customListFilter,
  disabled,
  className,
  multiple,
  onSelectItem,
  onSelectItems,
}) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    !multiple ? setSelectedItem(listFilter.filter ?? '') : setSelectedItems(listFilter.filters ?? []);
  }, [listFilter, multiple]);

  const comboboxRef = useRef<HTMLInputElement>(null);
  const handleWindowClosed = useCallback(
    (e: MouseEvent) => {
      if (!comboboxRef.current || !selected) return;
      const target = e.target as Node;
      let parentElement = comboboxRef.current.parentElement;
      while (parentElement) {
        if (parentElement?.classList?.contains('combobox-wrapper')) {
          break;
        }
        parentElement = parentElement.parentElement;
      }
      if (!parentElement || parentElement?.contains(target) || target.parentNode === null) return;
      setSelected(false);
      if (multiple) onSelectItems?.(selectedItems);
    },
    [onSelectItems, selectedItems, selected, multiple]
  );
  useEffect(() => {
    window.addEventListener('click', handleWindowClosed);
    return () => {
      window.removeEventListener('click', handleWindowClosed);
    };
  }, [handleWindowClosed]);

  const handleSelectedItem = (value) => {
    setSelectedItem(value);
    onSelectItem?.(value);
  };

  const handleSelectedItems = (values) => {
    setSelectedItems(values);
    setSelected(true);
  };

  const handleOnClear = () => {
    setSelectedItem('');
    onSelectItem?.('');
  };

  return (
    <ComboBox
      ref={comboboxRef}
      cssClass={classNames(Style.list, className)}
      placeholder="-"
      toggleAllLabel="Select All"
      options={
        customListFilter?.length
          ? customListFilter.map((v) => ({ id: v.id, value: v.value ?? '', display: v.display }))
          : listFilter.fieldValues.map((v) => ({ id: v, value: v ?? '' }))
      }
      selected={selectedItem}
      selectedMultipleItems={selectedItems}
      showIcon={false}
      onSelect={(value) => handleSelectedItem(value?.id.toString())}
      onSelectMultipleItems={(values) => handleSelectedItems(values.map((m) => m.id))}
      onClear={handleOnClear}
      multiple={multiple}
      disabled={disabled}></ComboBox>
  );
};

export default observer(ListFilter);
