import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/NavBarSelectorProject.module.scss';
import { ProjectItem } from '../../common/models/ItemType';
import NavBarSelectorItemContainer from './NavBarSelectorItemContainer';
import Icon from './Icon';

export interface INavBarSelectorProjectProps {
  item: ProjectItem;
}

const NavBarSelectorProject: React.FC<INavBarSelectorProjectProps> = ({ item }) => {
  return (
    <NavBarSelectorItemContainer className={`${Style.container} ${item.disabled ? Style.disabled : Style.enabled}`}>
      <Icon className={Style.icon} name="folder" />
      <span>{`${item.project.projectNumber} ${item.project.title}`}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorProject);
