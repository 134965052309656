import React, { FC } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Pages } from '../common/constants/Pages';
import Login from '../components/login/Login';

const UnauthenticatedRoutes: FC = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route key="login" path={Pages.Home.Route} element={<Login />} />
      <Route
        path="*"
        element={
          <Navigate
            to={{
              pathname: Pages.Home.Route,
            }}
            state={{ from: location }}
          />
        }
      />
    </Routes>
  );
};

export default UnauthenticatedRoutes;
