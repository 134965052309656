import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Styles from '../../../styles/components/tasks/taskReassign/TaskReassignAdminModal.module.scss';
import CloseButton from '../../shared/CloseButton';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import ModalActions from '../../shared/ModalActions';
import TaskReassignStore from './TaskReassignStore';
import TeamChart from '../../TeamChart/TeamChart';
import NavBarSelectorStore from '../../tasks/navBarSelector/NavBarSelectorStore';
import FilesStore from '../../files/FilesStore';
import { ITeamChartResult } from '../../TeamChart/interface/TeamChart.interface';
import { Toggle } from '@aurecon-creative-technologies/styleguide';
import TaskInformationStore from '../TaskInformationStore';
import { useAuth } from '../../../authentication/hooks/useAuth';
import { TaskType } from '../../../common/enums/TaskType';
import LayoutStore from '../../layout/LayoutStore';
import TaskUserSelector, { IUser } from './TaskUserSelector';
import { TaskTypeText } from '../../../common/constants/TaskTypeText';
import { TaskUserStatus } from '../../../common/enums/TaskUserStatus';

interface ITaskReassignAdminModalProps {
  closeModal: () => void;
  taskUniqueIdentifier: string;
}

const TaskReassignAdminModal: FC<ITaskReassignAdminModalProps> = (props) => {
  const { closeModal, taskUniqueIdentifier } = props;
  const { account } = useAuth();
  const [showTeamChart, setShowTeamChart] = useState(false);
  const [isAllUserRequiredChanged, setIsAllUserRequiredChanged] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (!TaskInformationStore.task) return;
    TaskReassignStore.loadTaskAssignees();
    const defaultUsers = TaskInformationStore.task.taskUsers
      .filter((tu) => tu.taskUserStatusId !== TaskUserStatus.Reassigned)
      .map((user) => {
        return {
          id: user.userId,
          name: user.name,
          email: user.email,
          taskUserStatusId: user.taskUserStatusId,
        } as IUser;
      });
    TaskReassignStore.onSelectedAssignees(defaultUsers);
    setSelectedUsers(defaultUsers);
    TaskReassignStore.setIsRequiredAllApprovers(TaskInformationStore.task.isSingleApproverRequired);
  }, []);

  const handleSubmit = async () => {
    await TaskReassignStore.assignTaskUsers();

    if (TaskReassignStore.hasErrorMessage)
      LayoutStore.displayToast('error', 'There was an issue assigning the task. Please try again later.');
    else
      LayoutStore.displayToast(
        'success',
        'The assigned person will be notified of this action and it will be visible on history'
      );
    closeModal();
  };
  const handleShowTeamChart = () => {
    setShowTeamChart(true);
  };
  const onTeamChartUserSelected = (teamChartData: ITeamChartResult) => {
    const users: IUser[] = [];
    if (teamChartData.deliveryUsers.length)
      users.push(
        ...teamChartData.deliveryUsers.map((m) => {
          return {
            id: m.id,
            name: m.name,
            email: m.email,
          };
        })
      );

    if (teamChartData.taskTeamUsers.length)
      users.push(
        ...teamChartData.taskTeamUsers.map((m) => {
          return {
            id: m.id,
            name: m.name,
            email: m.email,
          };
        })
      );

    const addUsers = users
      .filter((u) => u.id !== TaskInformationStore.task?.createdByUserId)
      .filter(
        (u) =>
          !TaskInformationStore.task?.taskUsers.some(
            (tu) => tu.userId === u.id && tu.taskUserStatusId === TaskUserStatus.Reassigned
          )
      )
      .filter((i) => !selectedUsers.map((r) => r.id).includes(i.id));
    selectedUsers.push(...addUsers);
    TaskReassignStore.onSelectedAssignees(selectedUsers);
    setShowTeamChart(false);
  };
  const renderToggleLabel = () => {
    if (!TaskInformationStore.task) return;
    switch (TaskInformationStore.task.taskTypeId) {
      case TaskType.TaskTeamReview:
        return 'Require all reviewers to approve?';
      case TaskType.ShareApproval:
        return 'Require all approvers?';
      case TaskType.DeliveryTeamReview:
        return 'Require all reviewers to approve?';
      case TaskType.PublishAuthorise:
        return 'Require all authorisers to authorise?';
      case TaskType.PublishAccept:
        return 'Require all acceptors to accept?';
      default:
        return 'Require all approvers to approve?';
    }
  };
  const onUserSelected = (users: IUser[]) => {
    setSelectedUsers(users);
    TaskReassignStore.onSelectedAssignees(users);
  };
  const onChangeAllUserRequired = (value: boolean) => {
    if (!TaskInformationStore.task) return;
    const isAllApproverRequired =
      selectedUsers.length > 1 &&
      TaskInformationStore.task &&
      value !== TaskInformationStore.task.isSingleApproverRequired;
    setIsAllUserRequiredChanged(isAllApproverRequired);
    TaskReassignStore.setIsRequiredAllApprovers(value);
  };

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Styles.modal}>
        <CloseButton onClick={() => closeModal()} disabled={false} />
        <h3>Edit Assignees</h3>
        <p>
          <strong>Task Unique Identifier </strong> {taskUniqueIdentifier}
        </p>
        <div className={Styles.container}>
          <div className={Styles.selectorWrapper}>
            <TaskUserSelector
              label="Assignees"
              required
              requiredLabel="* is a required field (at least one user is required)"
              searchPlaceholder={'Add assignee'}
              getUsers={(text) => TaskReassignStore.getMatchedAssignees(text, account?.userName)}
              onSelectedUsersUpdated={onUserSelected}
              disabled={TaskReassignStore.isSaving}
              isMultiUser={true}
              defaultSelectedUsers={selectedUsers}
            />
          </div>
          {TaskInformationStore.task && TaskInformationStore.task.taskTypeId !== TaskType.PublishAccept && (
            <div className={Styles.buttonWrapper}>
              <SecondaryButton
                onClick={() => {
                  handleShowTeamChart();
                }}>
                Team Chart
              </SecondaryButton>
            </div>
          )}
        </div>
        <div className={Styles.containerToggle}>
          <Toggle
            label={renderToggleLabel()}
            labelPosition="left"
            onLabel="Yes"
            offLabel="No"
            value={!TaskReassignStore.isRequiredAllApprovers}
            onChange={(b) => onChangeAllUserRequired(!b)}
            disabled={TaskReassignStore.isSaving}
          />
        </div>
        {showTeamChart && TaskInformationStore.task && NavBarSelectorStore?.selectedItem?.project?.projectNumber && (
          <TeamChart
            closeModal={() => setShowTeamChart(false)}
            headerTitle={TaskTypeText[TaskInformationStore.task.taskTypeId]}
            projectNumber={NavBarSelectorStore.selectedItem.project.projectNumber}
            taskTeamId={TaskInformationStore.task.taskTeamId}
            deliveryTeamId={TaskInformationStore.task.deliveryTeamId}
            fileContainerStateId={FilesStore.fileContainerStateId}
            onSelect={onTeamChartUserSelected}
            hideExternalSelection={true}
            showAnyoneInTaskTeam={TaskInformationStore.task.taskTypeId === TaskType.TaskTeamReview}
            showAnyoneInDeliveryTeam={
              TaskInformationStore.task && TaskInformationStore.task.taskTypeId === TaskType.DeliveryTeamReview
            }
            disableTaskTeam={
              TaskInformationStore.task &&
              !(
                TaskInformationStore.task.taskTypeId === TaskType.TaskTeamReview ||
                TaskInformationStore.task.taskTypeId === TaskType.ShareApproval
              )
            }
            disableDeliveryTeam={
              TaskInformationStore.task &&
              (TaskInformationStore.task.taskTypeId === TaskType.TaskTeamReview ||
                TaskInformationStore.task.taskTypeId === TaskType.ShareApproval)
            }
            excludedUsers={account?.userName ? [account?.userName] : []}
            label={TaskTypeText[TaskInformationStore.task.taskTypeId]}
          />
        )}
        <ModalActions>
          <SecondaryButton
            toolTip={'Cancel'}
            onClick={() => {
              closeModal();
            }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            disabled={TaskReassignStore.isSaving || (!isAllUserRequiredChanged && !TaskReassignStore.canAssign)}
            onClick={handleSubmit}>
            Assign
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(TaskReassignAdminModal);
