import { serverAxios } from '../api';

export interface IProjectArcGisSettings {
  webSceneId: string;
  baseMap: string;
  toggleMap: string;
  chainageLayerId: string;
}

export async function getProjectArcGisSettings(
  projectNumber: string,
  abortSignal?: AbortSignal
): Promise<IProjectArcGisSettings> {
  const result = await serverAxios.get<IProjectArcGisSettings>(`/api/config/arcgissettings`, {
    params: { projectNumber },
    signal: abortSignal,
  });
  return result.data;
}
