import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';

export interface IIconProps {
  className?: string;
  outlined?: boolean;
  name: string;
}

const Icon: FC<IIconProps> = ({ className, name, outlined }) => {
  return (
    <i className={classNames([!outlined, 'material-icons'], [!!outlined, 'material-icons-outlined'], className)}>
      {name}
    </i>
  );
};

export default observer(Icon);
