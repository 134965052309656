import { serverAxios } from '../api';

interface IUpdateConfigRequest {
  configs: IConfig[];
  splashPageContent: string;
}

interface IConfig {
  key: string;
  value: string;
}
export async function updateConfig(request: IUpdateConfigRequest, abortSignal?: AbortSignal): Promise<void> {
  const result = await serverAxios.put(`api/config/setting/update`, request, {
    signal: abortSignal,
  });
  return result.data;
}
