import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import { serverAxios } from '../api';

export interface IUpdateClientUserRoleRequest {
  userId: number;
  clientUserRoleId: ClientUserRole;
}

export function updateClientUserRole(request: IUpdateClientUserRoleRequest, abortSignal?: AbortSignal): Promise<void> {
  return serverAxios.put('api/um/user/role', request, {
    signal: abortSignal,
  });
}
