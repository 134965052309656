import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FormInput, ComboBox } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/NewFileFromTemplate.module.scss';
import UploadStore from './UploadStore';
import { ApimsMetaDataType, MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import FilesStore from '../FilesStore';

const UpdateApimsMetaDataFields: FC = () => {
  useEffect(() => {
    UploadStore.clearMetadataFileNameError();
  }, []);

  const renderPattern1Control = () => {
    return (
      <>
        {UploadStore.pattern1Metadata && (
          <div className={Style.formControl}>
            <label className={Style.label}>
              <span className={Style.labelText}>
                {FilesStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern1)?.description ??
                  ApimsMetaDataType.Pattern1}
              </span>
            </label>
            {UploadStore.pattern1Metadata.dataType.fieldType === MetadataFieldType.Alphabet && (
              <ComboBox
                showIcon={false}
                selected={UploadStore.pattern1}
                onSelect={(value) => {
                  UploadStore.setPattern1(MetadataFieldType.Alphabet, value?.value);
                }}
                options={UploadStore.getDropdownValues(UploadStore.pattern1Metadata).map((x) => {
                  return {
                    id: x.id,
                    value: x.label,
                  };
                })}
                placeholder="- Select an option -"
                cssClass={Style.comboBox}
              />
            )}
            {UploadStore.pattern1Metadata.dataType.fieldType === MetadataFieldType.Numeric && (
              <FormInput
                cssClass={Style.input}
                value={UploadStore.pattern1}
                placeholder="Enter text"
                error={UploadStore.pattern1Error}
                onChange={(value) => UploadStore.setPattern1(MetadataFieldType.Numeric, value)}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const renderPattern2Control = () => {
    return (
      <>
        {UploadStore.pattern2Metadata && (
          <div className={Style.formControl}>
            <label className={Style.label}>
              <span className={Style.labelText}>
                {FilesStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern2)?.description ??
                  ApimsMetaDataType.Pattern2}
              </span>
            </label>
            {UploadStore.pattern2Metadata.dataType.fieldType === MetadataFieldType.Alphabet && (
              <ComboBox
                showIcon={false}
                selected={UploadStore.pattern2}
                onSelect={(value) => {
                  UploadStore.setPattern2(MetadataFieldType.Alphabet, value?.value);
                }}
                options={UploadStore.getDropdownValues(UploadStore.pattern2Metadata).map((x) => {
                  return {
                    id: x.id,
                    value: x.label,
                  };
                })}
                placeholder="- Select an option -"
                cssClass={Style.comboBox}
              />
            )}
            {UploadStore.pattern2Metadata.dataType.fieldType === MetadataFieldType.Numeric && (
              <FormInput
                cssClass={Style.input}
                value={UploadStore.pattern2}
                placeholder="Enter text"
                error={UploadStore.pattern2Error}
                onChange={(value) => UploadStore.setPattern2(MetadataFieldType.Numeric, value)}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const renderPattern3Control = () => {
    return (
      <div className={Style.formControl}>
        <label className={Style.label}>
          <span className={Style.labelText}>
            {FilesStore.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern3)?.description ??
              ApimsMetaDataType.Pattern3}
          </span>
        </label>
        <FormInput
          cssClass={Style.input}
          value={UploadStore.pattern3}
          placeholder="Enter text"
          error={UploadStore.pattern3Error}
          onChange={(value) => UploadStore.setPattern3(MetadataFieldType.Numeric, value)}
        />
      </div>
    );
  };

  return (
    <>
      {renderPattern1Control()}
      {renderPattern2Control()}
      {renderPattern3Control()}
    </>
  );
};

export default observer(UpdateApimsMetaDataFields);
