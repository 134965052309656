import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/ProjectDetails.module.scss';
import { Checkbox, Grid, Tooltip } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { ProjectStatus, ProjectStatusDisplayText } from '../../../common/enums/ProjectStatus';
import ConfirmationModal from '../../shared/ConfirmationModal';
import Dot from '../../shared/Dot';
import AppStore from '../../../stores/AppStore';
import { classNames } from '../../../utils/miscUtils';
import SecondaryButton from '../../shared/SecondaryButton';
import { CSVLink } from 'react-csv';
import { ICSVData } from '../../../common/interfaces/csvData';
import { getProjectUsersExport } from '../../../api/authenticated/um/getProjectUsersExport';
import { IExportUserCSV } from '../../../api/authenticated/um/interfaces/user.interface';
import UpdateProject from './UpdateProject';
import ProjectWorkflowDefaults from './ProjectWorkflowDefaults';
import ProjectManagementVisualisation from './ProjectManagementVisualisation';
interface IProjectDetailsProps {
  onUpdate: () => void;
  onDelete: (programmeName: string) => void;
}

const ProjectName = 'ProjectName';
const modalTexts = {
  [ProjectStatus.Active]: {
    heading: 'Archive Project?',
    message: `This action will archive this project. Are you sure you want to archive ${ProjectName}?`,
  },
  [ProjectStatus.Inactive]: {
    heading: 'Unarchive Project?',
    message: `This action will unarchive the project. Are you sure you want to unarchive ${ProjectName}?`,
  },
};

const ProjectDetails: FC<IProjectDetailsProps> = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [exportUserData, setExportUserData] = useState<ICSVData<IExportUserCSV> | null>(null);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [updateProject, setUpdateProject] = useState<boolean>(false);

  const onConfirm = async () => {
    const archive = ProjectManagementStore.projectDetails?.projectStatusId !== ProjectStatus.Inactive;
    ProjectManagementStore.projectDetails &&
      (await ProjectManagementStore.archiveProject(ProjectManagementStore.projectDetails.projectNumber, archive));

    setOpenModal(false);
  };

  const onConfirmDelete = async () => {
    if (ProjectManagementStore.projectDetails) {
      const programmeTitle = ProjectManagementStore.projectDetails.programmeTitle;
      await ProjectManagementStore.deleteProject(ProjectManagementStore.projectDetails.projectNumber);
      setOpenDeleteModal(false);
      if (!ProjectManagementStore.showError) {
        props.onDelete(programmeTitle);
      }
    }
  };

  const onCancelDelete = () => {
    setOpenDeleteModal(false);
  };

  const onCancel = () => {
    setOpenModal(false);
  };
  const fetchExportData = async () => {
    if (!ProjectManagementStore.projectDetails) return;
    setLoading(true);
    setClicked(false);
    const mappingItems = await getProjectUsersExport(ProjectManagementStore.projectDetails.projectNumber);
    const data = ProjectManagementStore.mappingCSVProjectUserData(
      mappingItems,
      ProjectManagementStore.projectDetails.title
    );
    setExportUserData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (exportUserData && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, exportUserData]);

  const statusDisplayArchiveButtons = [ProjectStatus.Active, ProjectStatus.Inactive];
  const archive = ProjectManagementStore.projectDetails?.projectStatusId !== ProjectStatus.Inactive;

  const renderProjectDetails = () => {
    return (
      <>
        {ProjectManagementStore.projectDetails && (
          <div className={Style.projectDetailsWrapper}>
            {updateProject ? (
              <UpdateProject
                onSave={(projectNumber) => {
                  if (!projectNumber) return;
                  setUpdateProject(false);
                  props.onUpdate();
                }}
                onCancel={() => setUpdateProject(false)}
              />
            ) : (
              <>
                <Grid item md={12}>
                  <div className={Style.header}>
                    <span>Project Details</span>
                    <div className={Style.actions}>
                      {ProjectManagementStore.projectDetails.projectStatusId !== ProjectStatus.Inactive && (
                        <PrimaryIconButton
                          icon="edit"
                          size="medium"
                          className={Style.actionButton}
                          disabled={ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Inactive}
                          onClick={() => setUpdateProject(true)}></PrimaryIconButton>
                      )}
                      {!ProjectManagementStore.projectDetails.hasOpenTasks &&
                        statusDisplayArchiveButtons.includes(ProjectManagementStore.projectDetails.projectStatusId) && (
                          <Tooltip
                            show={<div className={Style.tooltipText}>{archive ? 'Archive' : 'Unarchive'}</div>}
                            defaultUp={true}>
                            <PrimaryIconButton
                              icon="inventory_2"
                              size="medium"
                              disabled={ProjectManagementStore.projectDetails.hasOpenTasks}
                              className={Style.actionButton}
                              onClick={() => setOpenModal(true)}></PrimaryIconButton>
                          </Tooltip>
                        )}
                      <Tooltip
                        show={
                          ProjectManagementStore.projectDetails.canDelete ? (
                            <div className={Style.tooltipText}>Delete</div>
                          ) : (
                            <div className={Style.tooltipText}>Cannot delete project, the project is not empty</div>
                          )
                        }
                        defaultUp={true}>
                        <PrimaryIconButton
                          icon="delete"
                          size="medium"
                          disabled={!ProjectManagementStore.projectDetails.canDelete}
                          className={classNames(Style.actionButton, [
                            !ProjectManagementStore.projectDetails.canDelete,
                            Style.nonePointerEvents,
                          ])}
                          onClick={() => setOpenDeleteModal(true)}></PrimaryIconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>

                <Grid item gap={16}>
                  <Grid item md={6}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Programme</div>
                      <div>{ProjectManagementStore.projectDetails.programmeTitle}</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Project Name</div>
                      <div className={Style.value}>{ProjectManagementStore.projectDetails.title}</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Project Number</div>
                      <div>{ProjectManagementStore.projectDetails.projectNumber}</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Project Status</div>
                      <div className={Style.projectStatusWrapper}>
                        <Dot
                          className={classNames(
                            Style.projectStatusDot,
                            [
                              ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Draft ||
                                ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.InProgress,
                              Style.inProgress,
                            ],
                            [
                              ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Active,
                              Style.active,
                            ],
                            [
                              ProjectManagementStore.projectDetails.projectStatusId ===
                                ProjectStatus.ProvisioningFailed,
                              Style.failed,
                            ],
                            [
                              ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.Inactive,
                              Style.inactive,
                            ]
                          )}
                        />
                        <small className={Style.projectStatusText}>
                          {ProjectStatusDisplayText[ProjectManagementStore.projectDetails.projectStatusId]}
                        </small>
                        {ProjectManagementStore.projectDetails.projectStatusId === ProjectStatus.ProvisioningFailed && (
                          <p className={Style.statusFailedMessage}>
                            Please contact your admin or{' '}
                            <a href={'mailto:' + AppStore.client!.supportUrl} target="_self">
                              Contact Support
                            </a>{' '}
                            if access is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Project Admins</div>
                      <div>{ProjectManagementStore.projectDetails.projectAdminUsers.map((x) => x.name).join(', ')}</div>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Project Owner</div>
                      <div>{ProjectManagementStore.projectDetails.projectOwnerName}</div>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Description</div>
                      <div>{ProjectManagementStore.projectDetails.description}</div>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div className={Style.formDisplayField}>
                      <div className={Style.formControlLabel}>Temporary Access</div>
                      <Grid item md={12}>
                        <Checkbox
                          label="Allow collaboration with other task teams"
                          checked={ProjectManagementStore.projectDetails.allowCollaborationOtherTaskTeams || false}
                          cssClass={Style.checkbox}
                          disabled={true}
                        />
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div>
                      <SecondaryButton className={Style.btnMenuUploadBtn} disabled={loading} onClick={fetchExportData}>
                        Export Users to CSV
                        {exportUserData && (
                          <CSVLink
                            headers={exportUserData?.headers ?? []}
                            filename={exportUserData?.filename}
                            data={exportUserData?.data ?? []}
                            ref={csvLinkRef}
                          />
                        )}
                      </SecondaryButton>
                    </div>
                  </Grid>
                </Grid>

                <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
                  <div className={Style.formGroupLabel}>Feature display</div>
                  <Grid gap={16} item md={12}>
                    <p className={Style.settingNoticed}>
                      By default, left menu bar displays 5 main features and allows user to switch features from
                      anywhere. Administrators can hide features and it will not be available for users.
                    </p>
                  </Grid>
                  <Grid row md={12} gap={8}>
                    <Grid item md={12}>
                      <Checkbox
                        label="Display Apps"
                        checked={ProjectManagementStore.projectDetails.displayApps}
                        cssClass={Style.checkbox}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Checkbox
                        label="Display Files"
                        checked={ProjectManagementStore.projectDetails.displayFiles}
                        cssClass={Style.checkbox}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Checkbox
                        label="Display Task"
                        cssClass={Style.checkbox}
                        checked={ProjectManagementStore.projectDetails.displayTasks}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Checkbox
                        disabled={true}
                        label="Display Visualisation"
                        cssClass={Style.checkbox}
                        checked={ProjectManagementStore.projectDetails.displayVisualisation}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Checkbox
                        disabled={true}
                        label="Display Transmittals"
                        cssClass={Style.checkbox}
                        checked={ProjectManagementStore.projectDetails.displayTransmittals}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <ProjectWorkflowDefaults />
              </>
            )}

            <ProjectManagementVisualisation
              displayVisualisation={ProjectManagementStore.projectDetails.displayVisualisation}
              onUpdate={props.onUpdate}
            />

            {modalTexts[ProjectManagementStore.projectDetails.projectStatusId] && (
              <ConfirmationModal
                showModal={openModal}
                heading={modalTexts[ProjectManagementStore.projectDetails.projectStatusId].heading.replace(
                  ProjectName,
                  ProjectManagementStore.projectDetails.title
                )}
                message={modalTexts[ProjectManagementStore.projectDetails.projectStatusId].message.replace(
                  ProjectName,
                  ProjectManagementStore.projectDetails.title
                )}
                confirmText="Yes"
                cancelText="No"
                onConfirm={onConfirm}
                onCancel={onCancel}></ConfirmationModal>
            )}
            {modalTexts[ProjectManagementStore.projectDetails.projectStatusId] && (
              <ConfirmationModal
                showModal={openModal}
                heading={modalTexts[ProjectManagementStore.projectDetails.projectStatusId].heading.replace(
                  ProjectName,
                  ProjectManagementStore.projectDetails.title
                )}
                message={modalTexts[ProjectManagementStore.projectDetails.projectStatusId].message.replace(
                  ProjectName,
                  ProjectManagementStore.projectDetails.title
                )}
                confirmText="Yes"
                cancelText="No"
                onConfirm={onConfirm}
                onCancel={onCancel}></ConfirmationModal>
            )}

            <ConfirmationModal
              showModal={openDeleteModal}
              heading="Delete Project?"
              message={`This action will delete the project and can not be undone. Are you sure you want to delete ${ProjectManagementStore.projectDetails.title}?`}
              confirmText="Yes"
              cancelText="No"
              loading={ProjectManagementStore.loading}
              onConfirm={onConfirmDelete}
              onCancel={onCancelDelete}></ConfirmationModal>
          </div>
        )}
      </>
    );
  };

  return <>{renderProjectDetails()}</>;
};

export default observer(ProjectDetails);
