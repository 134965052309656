import { serverAxios } from '../api';

export interface ProjectApp {
  id: number;
  title: string;
  url: string;
  order: number;
  image: string;
}
export async function getProjectApps(projectNumber: string, abortSignal?: AbortSignal): Promise<ProjectApp[]> {
  const result = await serverAxios.get<{ projectApps: ProjectApp[] }>(`/api/config/projects/apps`, {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.projectApps;
}
