import { computed, makeAutoObservable, runInAction } from 'mobx';
import { getReassignUsers } from '../../../api/authenticated/tasks/getReassignUsers';
import { reassignTask } from '../../../api/authenticated/tasks/reassignTask';
import { IUser } from '../../../api/authenticated/um/interfaces/user.interface';
import TaskInformationStore from '../TaskInformationStore';
import { AxiosError } from 'axios';
import { reassignTaskUsers } from '../../../api/authenticated/tasks/reassignTaskUsers';
import { TaskUserStatus } from '../../../common/enums/TaskUserStatus';

export class TaskReassignStore {
  constructor() {
    makeAutoObservable(this, { canSubmit: computed }, { autoBind: true });
  }
  public isSaving = false;
  public taskUser: IUser[] = [];
  public taskAssignees: IUser[] = [];
  public selectedUser: IUser[] = [];
  public selectedAssignees: IUser[] = [];
  public selectedUserId = 0;
  public selectedMessage = '';
  public hasErrorMessage = false;
  public hasUsers = false;

  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public isRequiredAllApprovers = false;

  public loadUsers() {
    getReassignUsers(TaskInformationStore.taskId).then((res) => {
      runInAction(() => {
        this.taskUser = res;
        this.hasUsers = true;
      });
    });
  }

  public getMatchedUsers(searchText: string) {
    return this.taskUser
      .filter(
        (r) =>
          r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      )
      .map((r) => ({
        id: r.id,
        name: r.name,
        email: r.email,
      }));
  }

  public onSelectedUsersUpdated(users: IUser[]) {
    if (this.isSaving) return;
    if (!users.length) {
      runInAction(() => {
        this.selectedUser = [];
        this.selectedUserId = 0;
      });
      return;
    }

    users.forEach((u) => {
      const user = this.taskUser.find((r) => r.id === u.id);
      if (user) {
        runInAction(() => {
          this.selectedUser.push(user);
          this.selectedUserId = user.id;
        });
      }
    });
  }

  public setSelectedMessage(message: string) {
    if (this.isSaving) return;
    runInAction(() => {
      this.selectedMessage = message;
    });
  }

  public get canSubmit(): boolean {
    return !!this.selectedUser.length;
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public async submit() {
    if (this.isSaving) return;
    runInAction(() => {
      this.isSaving = true;
      this.hasErrorMessage = false;
    });
    this.clearError();
    try {
      await reassignTask({
        taskId: TaskInformationStore.taskId,
        reassignedUserId: this.selectedUserId,
        message: this.selectedMessage,
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.hasErrorMessage = true;
      });
    } finally {
      runInAction(() => {
        if (!this.hasErrorMessage) {
          TaskInformationStore.loadTask();
          TaskInformationStore.setIsRefresh(true);
        }
        this.isSaving = false;
      });
    }
  }

  public async loadTaskAssignees() {
    if (!TaskInformationStore.task) return;
    await getReassignUsers(TaskInformationStore.taskId).then((res) => {
      runInAction(() => {
        this.taskAssignees = res;
        if (TaskInformationStore.task)
          this.taskAssignees.push(
            ...TaskInformationStore.task.taskUsers
              .filter((tu) => tu.taskUserStatusId !== TaskUserStatus.Reassigned)
              .map((m) => {
                return {
                  id: m.userId,
                  name: m.name,
                  email: m.email,
                };
              })
          );
      });
    });
  }
  public setIsRequiredAllApprovers(value: boolean) {
    if (this.isSaving) return;
    runInAction(() => {
      this.isRequiredAllApprovers = value;
    });
  }
  public onSelectedAssignees(users: IUser[]) {
    if (this.isSaving) return;
    this.selectedAssignees = [];
    users.forEach((u) => {
      const user = this.taskAssignees.find((r) => r.id === u.id);
      if (user) {
        runInAction(() => {
          this.selectedAssignees.push(user);
        });
      }
    });
  }
  public getMatchedAssignees(searchText: string, loggedUserEmail?: string) {
    return this.taskAssignees
      .filter(
        (r) =>
          r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          (r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1 &&
            r.email.toLocaleLowerCase() !== loggedUserEmail?.toLocaleLowerCase())
      )
      .map((r) => ({
        id: r.id,
        name: r.name,
        email: r.email,
      }));
  }

  public get canAssign(): boolean {
    if (TaskInformationStore.task)
      return (
        this.selectedAssignees.length > 0 &&
        !(
          TaskInformationStore.task.taskUsers.length === this.selectedAssignees.length &&
          TaskInformationStore.task.taskUsers.every((t) => this.selectedAssignees.map((r) => r.id).includes(t.userId))
        )
      );
    return false;
  }
  public async assignTaskUsers() {
    if (this.isSaving) return;
    runInAction(() => {
      this.isSaving = true;
      this.hasErrorMessage = false;
    });
    this.clearError();
    try {
      await reassignTaskUsers({
        projectNumber: TaskInformationStore.task!.projectNumber,
        taskId: TaskInformationStore.taskId,
        reassignedUserIds: this.selectedAssignees.map((x) => x.id),
        isSingleApproverRequired: this.isRequiredAllApprovers,
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);

      runInAction(() => {
        this.hasErrorMessage = true;
      });
    } finally {
      runInAction(() => {
        if (!this.hasErrorMessage) {
          TaskInformationStore.loadTask();
          TaskInformationStore.loadHistory();
          TaskInformationStore.setIsRefresh(true);
        }
        this.isSaving = false;
      });
    }
  }
}

export default new TaskReassignStore();
