import { uniqBy } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import { FileItem, IUploadFile } from '../../../common/interfaces/fileUpload';
import getFileExtension from '../../../utils/fileUtils';
import { calculateChunks, fileSizeUnits } from '../../../utils/miscUtils';
import { IAddFileResponse, addTaskAttachmentFiles } from '../../../api/authenticated/tasks/addTaskAttachmentFiles';
import {
  IFileNameValidationItem,
  validationAttachmentFilenames,
} from '../../../api/authenticated/tasks/validationAttachmentFilenames';
import { uploadTaskAttachmentFile } from '../../../api/authenticated/tasks/uploadTaskAttachmentFile';

export class SupportingFilesUploadStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private chunkSize = 3000000;
  public isCheckingDuplicated = false;
  public isSearchedOrFiltered = false;
  public filesSizeExceededTheLimit = false;
  public isDuplicatedFiles = false;
  public missingFileExtension = false;
  public duplicatedFiles: File[] = [];
  public missingExtensionFiles: File[] = [];
  public selectedFiles: FileItem[] = [];
  public showProgressBar = false;
  private fileChunks: { [filename: string]: IUploadFile } = {};
  public showUploadSuccess = false;
  public showUploadFailed = false;
  public totalChunks = 0;
  public totalUploadedChunks = 0;
  public percentageUploaded = 0;
  public openUploadError = false;
  public isProcessing = false;

  public projectNumber?: string;
  public taskId?: number;
  // Limit 1GB
  public limitBytes = fileSizeUnits.GB;
  public errorMessage?: string;

  public async initialUpload(projectNumber?: string, taskId?: number) {
    this.clear();
    runInAction(() => {
      this.projectNumber = projectNumber;
      this.taskId = taskId;
    });
  }

  public clear() {
    runInAction(() => {
      this.showProgressBar = false;
      this.selectedFiles = [];
      this.filesSizeExceededTheLimit = false;
      this.isDuplicatedFiles = false;
      this.missingFileExtension = false;
      this.totalChunks = 0;
      this.totalUploadedChunks = 0;
      this.percentageUploaded = 0;
      this.openUploadError = false;
      this.errorMessage = undefined;
      this.missingExtensionFiles = [];
    });
  }

  public setOpenUploadError(open: boolean) {
    this.openUploadError = open;
  }

  public setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  public async addFiles(files: FileItem[]) {
    runInAction(() => {
      this.isProcessing = true;
    });

    try {
      const exceedLimitation =
        this.sizeOfUploadFiles(this.selectedFiles) + this.sizeOfUploadFiles(files) > this.limitBytes;

      const duplicatedFiles = files.filter((f) => this.selectedFiles.some((nf) => nf.name === f.name));

      const missingExtensionFiles = files.filter((f) => !f.type && !getFileExtension(f.name));

      runInAction(() => {
        this.openUploadError = exceedLimitation || !!duplicatedFiles.length || !!missingExtensionFiles.length;
        this.duplicatedFiles = duplicatedFiles;
        this.missingExtensionFiles = missingExtensionFiles;
        this.missingFileExtension = !!missingExtensionFiles.length;
        this.filesSizeExceededTheLimit = exceedLimitation;
        this.isDuplicatedFiles = !!duplicatedFiles.length;

        this.selectedFiles = uniqBy(
          [...this.selectedFiles, ...files.filter((f) => !duplicatedFiles.some((df) => df.name === f.name))],
          'name'
        );

        this.selectedFiles = this.selectedFiles.filter((f) => !missingExtensionFiles.some((df) => df.name === f.name));
      });

      await this.validationUploadFiles();
    } finally {
      setTimeout(() => {
        runInAction(() => {
          this.isProcessing = false;
        });
      }, 500);
    }
  }

  public get uploadFiles() {
    return this.selectedFiles;
  }

  public removeFile(filename: string) {
    const index = this.selectedFiles.findIndex((f) => f.name === filename);
    if (index > -1) {
      runInAction(() => {
        this.selectedFiles.splice(index, 1);
      });
      this.validationUploadFiles();
    }
  }

  private startUploadProcess() {
    runInAction(() => {
      this.showProgressBar = true;
      this.fileChunks = {};
      this.showUploadSuccess = false;
      this.showUploadFailed = false;
    });
  }

  public async handleUploadSupportingFiles() {
    if (this.selectedFiles.length) {
      if (this.errorMessage || !this.taskId || !this.projectNumber) return;

      this.startUploadProcess();
      this.totalChunks = 0;
      this.selectedFiles.forEach((file) => {
        this.setCalculateFileChunks(file);
        this.totalChunks += this.fileChunks[file.name].chunks.length;
      });

      const addFilesResponse = await addTaskAttachmentFiles({
        projectNumber: this.projectNumber,
        taskId: this.taskId,
        attachmentFiles: this.selectedFiles.map((file) => {
          return {
            filename: file.name,
            totalFileSize: file.size,
            totalFileChunks: this.fileChunks[file.name].chunks.length,
          };
        }),
      });

      await Promise.all(
        addFilesResponse.attachmentFiles.map(async (addedFile) => {
          return this.uploadFile(addedFile);
        })
      );

      if (!this.filesFailedToUpload.length) {
        runInAction(() => {
          this.showUploadSuccess = true;
        });
      } else {
        runInAction(() => {
          this.showUploadFailed = true;
        });
      }
    }
  }

  public get filesFailedToUpload() {
    const failedFiles: string[] = [];
    Object.keys(this.fileChunks).forEach((filename) => {
      const uploadFile = this.fileChunks[filename];
      if (uploadFile.failed) failedFiles.push(filename);
    });
    return failedFiles;
  }

  private setCalculateFileChunks(file: FileItem) {
    runInAction(() => {
      this.fileChunks[file.name] = {
        numberOfChunksUploaded: 0,
        failed: false,
        chunks: calculateChunks(file.size, this.chunkSize),
      };
    });
  }

  private async uploadFile(addedFile: IAddFileResponse) {
    const file = this.selectedFiles.find((f) => f.name === addedFile.filename);
    if (!this.projectNumber || !this.taskId || !file) return;
    try {
      await this.uploadChunks(this.projectNumber, file, this.fileChunks[file.name], addedFile.id, this.taskId);
    } catch (err) {
      runInAction(() => {
        this.fileChunks[file.name].failed = true;
      });
    }
  }

  private async uploadChunks(
    projectNumber: string,
    file: File,
    uploadFile: IUploadFile,
    fileUploadId: number,
    taskId: number
  ) {
    for (const chunk of uploadFile.chunks) {
      const blob = file.slice(chunk.start, chunk.end);
      const isLast = chunk.index === uploadFile.chunks.length - 1;
      await uploadTaskAttachmentFile({
        blob: blob,
        projectNumber: projectNumber,
        taskId: taskId,
        attachmentFileId: fileUploadId,
        index: chunk.index,
        offset: chunk.start,
        isLastChunk: isLast,
      });

      runInAction(() => {
        uploadFile.numberOfChunksUploaded++;
        // calc percentage of files upload
        this.totalUploadedChunks += 1;
        this.percentageUploaded = (this.totalUploadedChunks / this.totalChunks) * 100;
      });
    }
  }

  public handleFileListChange(files: FileItem[]) {
    runInAction(() => {
      this.selectedFiles = [];
    });
    this.addFiles(files);
  }

  public async validationUploadFiles() {
    if (!this.projectNumber || !this.taskId) return;

    let duplicatedCheckingResults: IFileNameValidationItem[] = [];
    if (!this.filesSizeExceededTheLimit) {
      runInAction(() => (this.isCheckingDuplicated = false));
      duplicatedCheckingResults = await validationAttachmentFilenames({
        projectNumber: this.projectNumber,
        taskId: this.taskId,
        filenames: [...this.selectedFiles.map((x) => x.name)],
      });
    }

    const duplicatedFiles = duplicatedCheckingResults.filter((x) => x.duplicated);
    const missingExtensionFiles = this.selectedFiles.filter((f) => !f.type && !getFileExtension(f.name));
    runInAction(() => {
      this.selectedFiles.forEach(
        (x) => (x.duplicated = duplicatedFiles.some((y) => y.fileName === x.name || y.fileName === x['path']))
      );
      this.missingExtensionFiles = missingExtensionFiles;
      this.duplicatedFiles = this.selectedFiles.filter((f) => f.duplicated);
      if (missingExtensionFiles.length) {
        this.selectedFiles = this.selectedFiles.filter((f) => missingExtensionFiles.some((df) => df.name === f.name));
      }
      this.missingFileExtension = !!missingExtensionFiles.length;
      this.isDuplicatedFiles = !!duplicatedFiles.length;
      this.openUploadError = this.isDuplicatedFiles || this.filesSizeExceededTheLimit || this.missingFileExtension;
      this.isCheckingDuplicated = false;
    });
  }

  /**
   * Get size of upload support files.
   * @returns number
   */
  public sizeOfUploadFiles(files: File[]) {
    return files.reduce((size, file) => size + file.size, 0);
  }
}

export default new SupportingFilesUploadStore();
