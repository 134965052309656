import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone';
import Style from './styles/FileDropZone.module.scss';
import AppStore from '../../stores/AppStore';
import { Loader } from '@aurecon-creative-technologies/styleguide';
interface IFileDropZoneProps {
  label?: string | JSX.Element;
  multiple: boolean;
  disabled?: boolean;
  loading?: boolean;
  onDropzoneChange: (acceptedFiles: File[]) => void;
  className?: string;
}
const FileDropZone: FC<IFileDropZoneProps> = (props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      props.onDropzoneChange(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: props.multiple,
    disabled: props.disabled,
  });

  const renderContent = () => {
    if (props.label) return <>{props.label}</>;
    return (
      <div className={Style.dropzoneInfo}>
        <p>
          <b>Choose files</b>
        </p>
        <p>
          Drag & drop or click to browse for files <br />
          (Up to {AppStore.client?.fileUploadLimit} files)
        </p>
      </div>
    );
  };
  return (
    <div
      {...getRootProps({ className: `${Style.dropzone} ${props.disabled ? Style.disabled : ''} ${props.className}` })}>
      <input {...getInputProps()} />
      {props.loading ? <Loader cssClass={Style.loadingSpinner} size="small" /> : renderContent()}
    </div>
  );
};

export default observer(FileDropZone);
