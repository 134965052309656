import { serverAxios } from '../api';

export interface IAdUser {
  type: string;
  id: string;
  displayName: string;
  mail: string;
}

export async function getAdUsers(keyword: string, abortSignal?: AbortSignal): Promise<IAdUser[]> {
  const result = await serverAxios.get<{ users: IAdUser[] }>('api/um/system/adusers', {
    params: {
      keyword: keyword,
    },
    signal: abortSignal,
  });
  return result.data.users || [];
}
