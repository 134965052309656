import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ActionBar from '../shared/ActionBar';
import ActionBarSection from '../shared/ActionBarSection';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';
import Styles from './styles/TransmittalDetailsActions.module.scss';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../common/constants/Pages';
import TransmittalDetailsStore from './TransmittalDetailsStore';
import ConfirmationModal from '../shared/ConfirmationModal';
import LayoutStore from '../layout/LayoutStore';
import AppStore from '../../stores/AppStore';
import ErrorModal from '../shared/ErrorModal';
import TransmittalsStore from '../transmittals/TransmittalsStore';
import { TabFilter } from '../transmittals/Types';
import { appInsightsTrackEvent } from '../../utils/appInsights';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';
import { useAuth } from '../../authentication/hooks/useAuth';

interface ITransmittalDetailsActionsProps {
  closed: boolean;
  flagged: boolean;
  canRespond: boolean;
  initiator: string;
  onClose: () => void;
  onReopen: () => void;
  onFlagged: (boolean) => void;
}
const TransmittalDetailsActions: FC<ITransmittalDetailsActionsProps> = (props) => {
  const { account } = useAuth();

  const { initiator, closed, flagged, canRespond, onClose, onReopen, onFlagged } = props;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showReopenConfirmation, setShowReopenConfirmation] = useState(false);
  const navigate = useNavigate();
  const handleAddTransmittalResponseClick = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTAL_RESPONSE);
    navigate(`${Pages.Transmittals.Route}/${TransmittalDetailsStore.transmittal?.title}/AddResponse`);
  };

  const handleFlaggedTransmittal = () => {
    onFlagged(!flagged);
  };

  const handleAddTransmittalCopyLinkClick = () => {
    const url = location.href;
    if (url) {
      navigator.clipboard.writeText(url);
      LayoutStore.displayToast('success', 'Link copied to clipboard!');
    }
  };

  const enabledCloseButton =
    AppStore.isSystemAdmin ||
    AppStore.projectAdminister ||
    initiator?.toLocaleLowerCase() === account?.userName.toLocaleLowerCase();

  const enabledReopenButton =
    (AppStore.isSystemAdmin || AppStore.projectAdminister) &&
    closed &&
    TransmittalsStore.currentTab === TabFilter.AllDeliveryTeam;

  const renderReOpenAndCloseButtons = () => {
    if (enabledReopenButton)
      return <SecondaryButton onClick={() => setShowReopenConfirmation(true)}>Reopen</SecondaryButton>;
    return enabledCloseButton ? (
      <SecondaryButton disabled={closed} onClick={() => setShowConfirmation(true)}>
        Close Out
      </SecondaryButton>
    ) : (
      <></>
    );
  };
  return (
    <>
      <ActionBar className={Styles.actionBar}>
        <ActionBarSection>
          {canRespond && (
            <PrimaryButton
              disabled={closed}
              className={Styles.responseButton}
              onClick={handleAddTransmittalResponseClick}>
              Respond
            </PrimaryButton>
          )}
          <SecondaryButton onClick={() => handleFlaggedTransmittal()}>{flagged ? 'Unflag' : 'Flag'}</SecondaryButton>
          {renderReOpenAndCloseButtons()}
          <SecondaryButton onClick={handleAddTransmittalCopyLinkClick}>Copy Link</SecondaryButton>
        </ActionBarSection>
      </ActionBar>
      <ConfirmationModal
        showModal={showConfirmation}
        cancelText="No"
        confirmText="Yes"
        heading="Close Transmittal"
        message="Once the Transmittal is closed, you cannot respond or make any changes to it. This action cannot be reverted. Are you sure you want to close Transmittal?"
        onConfirm={() => {
          setShowConfirmation(false);
          onClose();
        }}
        onCancel={() => setShowConfirmation(false)}
      />
      <ConfirmationModal
        showModal={showReopenConfirmation}
        cancelText="No"
        confirmText="Yes"
        heading="Reopen Transmittal"
        message=""
        htmlMessage="Reopening a transmittal will
        <ul>
          <li>Allow recipients to respond to the transmittal</li>
          <li>Notify the initiator when a due date is up</li>
        </ul>
        Are you sure you want to continue?"
        onConfirm={() => {
          setShowReopenConfirmation(false);
          onReopen();
        }}
        onCancel={() => setShowReopenConfirmation(false)}
      />
      {TransmittalDetailsStore.showError && (
        <ErrorModal
          closeModal={() => TransmittalDetailsStore.setShowErrorModal(false)}
          errorCode={TransmittalDetailsStore.errorCode}
          errorMessage={TransmittalDetailsStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(TransmittalDetailsActions);
