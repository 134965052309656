import { SortType } from '../../../common/enums/SortType';
import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';
import { ISearchTransmittal } from './searchTransmittal';

export interface ISearchTransmittalResult {
  pageResult: IPagedResponse<ISearchTransmittal>;
  allDeliveryTeamSummary: number;
}

export interface ISearchTransmittalListItem extends ISearchTransmittal {
  checked: boolean;
}
export interface ISearchTransmittalRequest {
  transmittalTitle?: string | null;
  transmittalTypeIds?: number[] | null;
  transmittalReasonIds?: number[] | null;
  closed?: boolean | null;
  flagged?: boolean | null;
  overdue?: boolean | null;
  lastUpdatedFrom?: Date | null;
  lastUpdatedTo?: Date | null;
  lastUpdatedOperator?: number | null;
  dueDateFrom?: Date | null;
  dueDateTo?: Date | null;
  dueDateOperator?: number | null;
  initiatedDateFrom?: Date | null;
  initiatedDateTo?: Date | null;
  initiatedDateOperator?: number | null;
  initiator?: string | null;
  taskTeamOriginator?: string | null;
  sortColumn?: string | null;
  sortDirection?: SortType;
  projectNumber: string | null;
  deliveryTeamId: number | null;
  subject?: string | null;
  page: number;
  pageSize: number;
}

export async function getTransmittalDeliveryTeam(
  options: ISearchTransmittalRequest,
  abortSignal?: AbortSignal
): Promise<ISearchTransmittalResult> {
  const result = await serverAxios.post<{
    totalAllDeliveryTeam: number;
    pageResult: IPagedResponse<ISearchTransmittal>;
  }>(`api/transmittals/getTransmittalDeliveryTeam`, options, {
    signal: abortSignal,
  });
  return {
    pageResult: result.data?.pageResult || [],
    allDeliveryTeamSummary: result.data?.totalAllDeliveryTeam || 0,
  };
}
