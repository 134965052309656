import { serverAxios } from '../api';

export interface IValidationTemplateFileNameRequest {
  templateFileNames: ITemplateFileNameValidationItem[];
}
export interface ITemplateFileNameValidationItem {
  templateName: string;
  id: number | null;
}

export interface IValidationTemplateFileNameResponse {
  duplicated: boolean;
  templateName: string;
  id: number | null;
}

export async function validationTemplateFileNames(
  request: IValidationTemplateFileNameRequest,
  abortSignal?: AbortSignal
): Promise<IValidationTemplateFileNameResponse[]> {
  const result = await serverAxios.post<{ templateNameValidationItems: IValidationTemplateFileNameResponse[] }>(
    'api/config/validationTemplateFileNames',
    request,
    {
      signal: abortSignal,
    }
  );
  return result.data?.templateNameValidationItems || [];
}
