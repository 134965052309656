import React, { FC, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { ITabMenuProps, TabMenu } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/PageTab.module.scss';
import classnames from 'classnames';

export interface ICustomTabMenuProps extends ITabMenuProps {
  tabMenuClass?: string;
}

const PageTab: FC<ICustomTabMenuProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  // 60 is the height of the navbar
  const top = ref.current ? ref.current.offsetTop - 60 : 0;
  return (
    <div style={{ height: `calc(100% - ${top}px)` }} ref={ref} className={props.cssClass}>
      <TabMenu {...props} cssClass={classnames(Style.tab, Style.tabMenuClass)} type="line">
        {props.children}
      </TabMenu>
    </div>
  );
};

export default observer(PageTab);
