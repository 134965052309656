import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';
import { IAddingContainerFile, IContainerFile } from './addFileContainer';

export interface IAddSupersedeFileContainerRequest {
  taskTeamId: number;
  fileContainerId: number;
  fileContainerRevisionId?: number | null;
  fileContainerStateId: FileContainerState;
  containerFiles: IContainerFile[];
}

export interface IAddSupersedeFileContainerResponse {
  fileContainerId: number;
  fileContainerRevisionId: number;
  prevFileContainerRevisionId: number | null;
  containerFiles: IAddingContainerFile[];
  releasedFileContainerId: number;
}

export async function addSupersedeFileContainer(
  request: IAddSupersedeFileContainerRequest,
  abortSignal?: AbortSignal
): Promise<IAddSupersedeFileContainerResponse> {
  const result = await serverAxios.post<IAddSupersedeFileContainerResponse>('api/cms/supersede', request, {
    signal: abortSignal,
  });
  return result.data;
}
