import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/ProjectDetailsTabs.module.scss';
import { Grid, ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import Loading from '../../shared/Loading';
import ErrorModal from '../../shared/ErrorModal';
import { classNames } from '../../../utils/miscUtils';
import ProjectApps from './ProjectApps';
import ProjectTransmittalDefaults from './ProjectTransmittalDefaults';
import PageTab from '../../shared/PageTab';
import ProjectDetails from './ProjectDetails';
import ProjectFileMetadata from './ProjectFileMetadata';

enum SettingTabIds {
  DETAILS,
  APPS,
  METADATA,
}

interface IProjectDetailsTabProps {
  projectNumber: string;
  onUpdate: () => void;
  onDelete: (programmeName: string) => void;
}

const settingTabs: ITab[] = [
  { id: SettingTabIds.DETAILS, label: 'Project Details' },
  { id: SettingTabIds.APPS, label: 'Project Apps' },
  { id: SettingTabIds.METADATA, label: 'Metadata' },
];

const ProjectDetailsTabs: FC<IProjectDetailsTabProps> = (props) => {
  const [selectedTabId, setSelectedTabId] = useState(SettingTabIds.DETAILS);

  useEffect(() => {
    const fetchProjectAsync = async () => {
      await ProjectManagementStore.getProjectDetails(props.projectNumber);
    };
    fetchProjectAsync();
  }, [props.projectNumber]);

  if (ProjectManagementStore.loading) return <Loading isLoading={ProjectManagementStore.loading} />;

  const onSelectTab = (id: number) => {
    setSelectedTabId(id);
  };

  const renderTabContent = (tabId) => {
    if (!ProjectManagementStore.projectDetails) return null;
    switch (tabId) {
      case SettingTabIds.DETAILS:
        return <ProjectDetails onUpdate={props.onUpdate} onDelete={props.onDelete} />;
      case SettingTabIds.APPS:
        return (
          <>
            {ProjectManagementStore.projectDetails.displayApps && (
              <>
                <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
                <ProjectApps projectNumber={props.projectNumber} />
              </>
            )}
          </>
        );
      case SettingTabIds.METADATA:
        return (
          <>
            <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
            <ProjectFileMetadata projectNumber={props.projectNumber} />
            <Grid row md={12} cssClass={classNames(Style.breakLine)}></Grid>
            <ProjectTransmittalDefaults projectNumber={props.projectNumber} />
          </>
        );
      default:
        break;
    }
  };

  const renderPageTabs = () => {
    return (
      <PageTab
        activeTab={selectedTabId}
        tabs={settingTabs}
        onSelectTab={onSelectTab}
        cssClass={Style.projectDetailsSettingTab}
        tabMenuClass={Style.customTab}>
        {settingTabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            {renderTabContent(tab.id)}
          </TabContent>
        ))}
      </PageTab>
    );
  };
  return (
    <>
      {ProjectManagementStore.projectDetails && (
        <div className={Style.projectDetailsTabWrapper}>
          {renderPageTabs()}

          {ProjectManagementStore.showError && (
            <ErrorModal
              closeModal={() => ProjectManagementStore.setShowErrorModal(false)}
              errorCode={ProjectManagementStore.errorCode}
              errorMessage={ProjectManagementStore.errorMessage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default observer(ProjectDetailsTabs);
