import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import HistoryDate from './HistoryDate';
import { IHistoryItem, IUploadHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import Style from './styles/HistoryUpload.module.scss';

export interface IHistoryUploadProps {
  item: IHistoryItem;
  upload: IUploadHistoryItem;
}

const HistoryUpload: FC<IHistoryUploadProps> = ({ item, upload }) => {
  return (
    <li className={Style.item}>
      <HistoryDate dateTime={item.eventDateTime} />
      <div className={Style.title}>
        <Icon name="upload" className={Style.icon} />
        <span>Uploaded</span>
      </div>
      <div className={Style.user}>{upload.userName}</div>
    </li>
  );
};

export default observer(HistoryUpload);
