import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/UpdateProject.module.scss';
import { Grid, Toggle } from '@aurecon-creative-technologies/styleguide';
import classNames from 'classnames';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import Loading from '../../shared/Loading';
import { ProjectWorkflowDefaultFields } from './Models/addNewProjectValidator';
import { IUpdateProjectWorkflowDefaultModel } from '../../../api/authenticated/config/models/projectModel';

interface IUpdateProjectWorkflowDefaultProps {
  saving: boolean;
  isEditMode: boolean;
  onFieldChanged: (propIds: IUpdateProjectWorkflowDefaultModel) => void;
}

const UpdateProjectWorkflowDefaults: FC<IUpdateProjectWorkflowDefaultProps> = (props) => {
  const { onFieldChanged, isEditMode } = props;

  const getRequireAllApprovers = () => {
    return !isEditMode
      ? true
      : ProjectManagementStore.projectDetails?.projectWorkflowDefaultSettings.requireAllApprovers ?? true;
  };
  const getRequireAuthorisers = () => {
    return !isEditMode
      ? true
      : ProjectManagementStore.projectDetails?.projectWorkflowDefaultSettings.requireAllAuthorisers ?? true;
  };
  const getRequireAcceptors = () => {
    return !isEditMode
      ? true
      : ProjectManagementStore.projectDetails?.projectWorkflowDefaultSettings.requireAllAcceptors ?? true;
  };
  const [projectWorkflowDefault, setProjectWorkflowDefault] = useState<IUpdateProjectWorkflowDefaultModel>({
    requireAllApprovers: getRequireAllApprovers(),
    requireAllAuthorisers: getRequireAuthorisers(),
    requireAllAcceptors: getRequireAcceptors(),
  });

  const onFormInputChanged = (fieldName: string, value) => {
    const newProjectWorkflowDefault = { ...projectWorkflowDefault, [fieldName]: value };
    setProjectWorkflowDefault(newProjectWorkflowDefault);
    onFieldChanged(newProjectWorkflowDefault);
  };

  if (ProjectManagementStore.loadingSystemUsers)
    return <Loading isLoading={ProjectManagementStore.loadingSystemUsers} />;

  return (
    <div className={Style.fromWrapper}>
      <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
        <div className={Style.formGroupLabel}>Workflow Defaults</div>
        <Grid gap={16} item md={12}>
          <p className={Style.settingNoticed}>
            When creating a new task, this sets the default for task assignees to complete a task. Please note, task
            initiator will still be able to change this toggle.
          </p>
        </Grid>
        <Grid gap={16} item md={12}>
          <div className={Style.formWorkflowLabel}>Shared Workflow</div>
        </Grid>
        <Grid row md={12} gap={8}>
          <Grid item md={12}>
            <div className={Style.formGroupContent}>
              <Toggle
                label="Require all approvers"
                labelPosition="left"
                onLabel="Yes"
                offLabel="No"
                cssClass={Style.toggle}
                disabled={props.saving}
                value={projectWorkflowDefault.requireAllApprovers}
                onChange={() =>
                  onFormInputChanged(
                    ProjectWorkflowDefaultFields.requireAllApprovers,
                    !projectWorkflowDefault.requireAllApprovers
                  )
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid gap={16} item md={12}>
          <div className={Style.formWorkflowLabel}>Published Workflow</div>
        </Grid>
        <Grid row md={12} gap={8}>
          <Grid item md={12}>
            <div className={Style.formGroupContent}>
              <Toggle
                label="Require all authorisers to authorise"
                labelPosition="left"
                onLabel="Yes"
                offLabel="No"
                cssClass={Style.toggle}
                disabled={props.saving}
                value={projectWorkflowDefault.requireAllAuthorisers}
                onChange={() =>
                  onFormInputChanged(
                    ProjectWorkflowDefaultFields.requireAllAuthorisers,
                    !projectWorkflowDefault.requireAllAuthorisers
                  )
                }
              />
            </div>
          </Grid>
        </Grid>
        <Grid row md={12} gap={8}>
          <Grid item md={12}>
            <div className={Style.formGroupContent}>
              <Toggle
                label="Require all acceptors to accept"
                labelPosition="left"
                onLabel="Yes"
                offLabel="No"
                cssClass={Style.toggle}
                disabled={props.saving}
                value={projectWorkflowDefault.requireAllAcceptors}
                onChange={() =>
                  onFormInputChanged(
                    ProjectWorkflowDefaultFields.requireAllAcceptors,
                    !projectWorkflowDefault.requireAllAcceptors
                  )
                }
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(UpdateProjectWorkflowDefaults);
