import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DistributionListDetails.module.scss';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import DistributionListUsers from './DistributionListUsers';
import EditDistributionListModal from './EditDistributionListModal';
import ConfirmationModal from '../../shared/ConfirmationModal';
import DistributionListStore from '../../../stores/settings/teamManagement/DistributionListStore';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';

interface IDisributionListDetailsProps {
  distributionListId: number;
  distributionListName: string;
  projectNumber: string;
  onDelete: () => void;
}

const DisributionListDetails: FC<IDisributionListDetailsProps> = (props) => {
  const { distributionListId, distributionListName, projectNumber, onDelete } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [listName, setListName] = useState(distributionListName);
  const listStatus =
    TeamManagementStore.clientTeams?.programmes
      .flatMap((pr) => pr.projects.filter((x) => x.projectNumber === projectNumber))
      .flatMap((p) => p.distributionList)
      .find((dt) => dt.id === distributionListId)?.active ?? false;
  useEffect(() => {
    const effectAsync = async () => {
      setListName(distributionListName);
    };
    effectAsync();
  }, [distributionListName, distributionListId]);

  const renderEditListModal = () => {
    const params = {
      listId: distributionListId,
      projectNumber: projectNumber,
      listName: listName,
      onSave: (updatedListName) => {
        setListName(updatedListName);
        setShowEditModal(false);
      },
      onCancel: () => setShowEditModal(false),
    };
    return showEditModal && <EditDistributionListModal {...params} />;
  };

  const onConfirmDelete = async () => {
    const result = await DistributionListStore.deleteDistributionList(projectNumber, distributionListId);
    setShowDeleteModal(false);
    if (result) onDelete();
  };

  const onCancelDelete = () => {
    setShowDeleteModal(false);
  };
  const onCancelArchive = () => {
    setShowArchiveModal(false);
  };
  const onConfirmArchive = async () => {
    await DistributionListStore.archiveUnarchiveList(projectNumber, distributionListId, !listStatus);
    if (!DistributionListStore.loading) setShowArchiveModal(false);
  };

  const archiveListModalText = {
    heading: 'Archive List?',
    message: `This action will archive this distribution list. Are you sure you want to archive ${listName}?`,
  };

  const unarchiveListModalText = {
    heading: 'Unarchive List?',
    message: `This action will unarchive this distribution list. Are you sure you want to unarchive ${listName}?`,
  };
  return (
    <div className={Style.distributionListContainer}>
      <div className={Style.header}>
        <div className={Style.heading}>
          <div className={Style.title}>{listName}</div>
          <div className={Style.buttons}>
            <div className={Style.buttonsIcon}>
              <PrimaryIconButton
                icon="edit"
                size="medium"
                className={Style.actionButton}
                disabled={!listStatus}
                onClick={() => setShowEditModal(true)}></PrimaryIconButton>
              <Tooltip
                show={<div className={Style.tooltipText}>{listStatus ? 'Archive' : 'Unarchive'}</div>}
                defaultUp={true}>
                <PrimaryIconButton
                  icon="inventory_2"
                  size="medium"
                  className={Style.actionButton}
                  onClick={() => setShowArchiveModal(true)}></PrimaryIconButton>
              </Tooltip>
              <PrimaryIconButton
                icon="delete"
                size="medium"
                className={Style.actionButton}
                onClick={() => setShowDeleteModal(true)}></PrimaryIconButton>
            </div>
          </div>
        </div>
      </div>
      {listStatus && <DistributionListUsers projectNumber={projectNumber} distributionListId={distributionListId} />}
      {renderEditListModal()}
      <ConfirmationModal
        showModal={showDeleteModal}
        heading="Delete List?"
        message={`This action will delete this distribution list. Are you sure you want to delete ${listName}?`}
        confirmText="Yes"
        cancelText="No"
        loading={DistributionListStore.loading}
        onConfirm={onConfirmDelete}
        onCancel={onCancelDelete}></ConfirmationModal>

      <ConfirmationModal
        showModal={showArchiveModal}
        heading={!listStatus ? unarchiveListModalText.heading : archiveListModalText.heading}
        message={!listStatus ? unarchiveListModalText.message : archiveListModalText.message}
        confirmText="Yes"
        cancelText="No"
        loading={DistributionListStore.loading}
        onConfirm={onConfirmArchive}
        onCancel={onCancelArchive}></ConfirmationModal>
    </div>
  );
};

export default observer(DisributionListDetails);
