import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../../utils/dateUtils';
import Style from './styles/HistoryDate.module.scss';

export interface IHistoryDateProps {
  dateTime: Date;
}

const HistoryDate: FC<IHistoryDateProps> = ({ dateTime }) => {
  return <div className={Style.dateWithLine}>{formatDate(dateTime)}</div>;
};

export default observer(HistoryDate);
