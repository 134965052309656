import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import CentreOnPage from '../../../shared/CentreOnPage';
import Overlay from '../../../shared/Overlay';
import Modal from '../../../shared/Modal';
import Style from './styles/UserImportModal.module.scss';
import ModalActions from '../../../shared/ModalActions';
import PrimaryButton from '../../../shared/PrimaryButton';
import SecondaryButton from '../../../shared/SecondaryButton';

interface IModalProps {
  closeModal: () => void;
  redirect: () => void;
  buttonContinueText?: string;
  buttonCancelText?: string;
}

const UnsavedModal: FC<IModalProps> = (props) => {
  const { closeModal, redirect, buttonContinueText, buttonCancelText } = props;

  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.unSaveModal}>
        <p className={Style.headerWeight}>Cancel uploads</p>
        <div className={Style.messageText}>
          <p>Your progress will not be saved and nothing will not be uploaded. Are you sure you want to continue?</p>
        </div>
        <ModalActions>
          <SecondaryButton onClick={() => redirect()}>{buttonContinueText}</SecondaryButton>
          <PrimaryButton onClick={() => closeModal()}>{buttonCancelText}</PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(UnsavedModal);
