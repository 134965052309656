import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import WebSceneViewer from './WebsceneViewer';
import WebmapViewer from './WebmapViewer';
import Frame from './Frame';
import { ITab } from './ITab';
import BuildingExplorerWidget from './widgets/BuildingExplorerWidget';
import MeasurementWidget from './widgets/MeasurementWidget';
import SettingsWidget from './widgets/SettingsWidget';
import VisualisationStore from './VisualisationStore';
import SliceWidget from './widgets/SliceWidget';
import Style from './styles/Frame.module.scss';
import HelpWidget from './widgets/HelpWidget';

const MapViewer: FC = () => {
  const frameTabs: ITab[] = [
    {
      name: 'Building',
      icon: 'compare',
      content: <BuildingExplorerWidget />,
      title: 'Building',
    },
    {
      name: 'Measurement',
      icon: 'straighten',
      content: <MeasurementWidget />,
      title: 'Measure Tools',
    },
    {
      name: 'Slice',
      icon: 'layers_clear',
      content: <SliceWidget />,
      title: 'Slice',
    },
    {
      name: 'Settings',
      icon: 'settings',
      content: <SettingsWidget />,
      title: 'Settings',
    },
    {
      name: 'Help',
      icon: 'help',
      content: <HelpWidget />,
      title: 'Help',
    },
  ];

  return (
    <div className={Style.mapViewer}>
      {VisualisationStore.isWebScene ? <WebSceneViewer /> : <WebmapViewer />}
      {frameTabs && <Frame tabs={frameTabs}></Frame>}
    </div>
  );
};

export default observer(MapViewer);
