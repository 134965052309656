import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import FilesStore from '../FilesStore';
import SecondaryButton from '../../shared/SecondaryButton';
import ReviewModal from '../reviewModal/ReviewModal';
import { TaskType } from '../../../common/enums/TaskType';
import { useSaveTask } from '../customHooks/useSaveTask';
import { useGetAddTaskData } from '../customHooks/GetAddTaskDataHook';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from '../styles/TabContainer.module.scss';
import AppStore from '../../../stores/AppStore';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';

interface PublishButtonProps {
  taskTeamId: number;
}

const PublishButton: FC<PublishButtonProps> = ({ taskTeamId }) => {
  const [isShowing, setIsShowing] = useState(false);
  const saveTask = useSaveTask(taskTeamId, TaskType.PublishAuthorise);
  const getAddTaskData = useGetAddTaskData(TaskType.PublishAuthorise, taskTeamId);

  const showModal = () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_PUBLISHED);
    setIsShowing(true);
  };

  return (
    <>
      {FilesStore.hasSelectedReferenceFiles && (
        <Tooltip show={'Reference Files selected.'} defaultUp={false} cssClass={Style.toolTipBtn}>
          <SecondaryButton disabled={!FilesStore.canPublishReviewOnSelectedFiles || !taskTeamId} onClick={showModal}>
            Publish
          </SecondaryButton>
        </Tooltip>
      )}
      {!FilesStore.hasSelectedReferenceFiles && FilesStore.canPublishReviewOnSelectedFiles && (
        <SecondaryButton
          disabled={
            !FilesStore.canPublishReviewOnSelectedFiles ||
            !taskTeamId ||
            !FilesStore.canDeliveryTeamReviewOnSelectedFiles
          }
          onClick={showModal}>
          Publish
        </SecondaryButton>
      )}
      {!FilesStore.hasSelectedReferenceFiles && !FilesStore.canPublishReviewOnSelectedFiles && AppStore.applyApims && (
        <Tooltip show={'This file has been published'} defaultUp={false} cssClass={Style.toolTipBtn}>
          <SecondaryButton disabled={!FilesStore.canPublishReviewOnSelectedFiles || !taskTeamId}>
            Publish
          </SecondaryButton>
        </Tooltip>
      )}

      {isShowing && (
        <ReviewModal
          title="Publish"
          description="If the 'Publish' workflow is authorised, the information will be automatically forwarded to the Appointing Party for Acceptance before appearing in the Published Content State."
          closeModal={() => setIsShowing(false)}
          approversLabel="Authorisers"
          approversSearchLabel="Add Authorisers"
          allApproversLabel="Require all authorisers to authorise?"
          isSingleApproverRequired={!NavBarSelectorStore.selectedItem?.project?.requireAllAuthorisers}
          showSuitability
          showAcceptorUsers
          getData={getAddTaskData}
          saveReview={saveTask}
          state="Published"
        />
      )}
    </>
  );
};

export default observer(PublishButton);
