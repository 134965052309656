import IdentityManager from '@arcgis/core/identity/IdentityManager';
import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
import Portal from '@arcgis/core/portal/Portal';
import esriConfig from '@arcgis/core/config';

export class ArcGisAuthentication {
  public arcGisIsLoggingIn = false;
  public arcGisErrorLoggingIn = false;
  public arcGisIsAuthenticated = false;
  private esriId = IdentityManager;
  private arcGisUseOauth = false;
  private arcGisApiKey: string | null;
  private arcGisPortalUrl: string;
  private arcGisClientId: string;

  constructor(arcGisUseOauth: boolean, arcGisClientId: string, arcGisApiKey: string | null, arcGisPortalUrl: string) {
    this.arcGisUseOauth = arcGisUseOauth;
    this.arcGisApiKey = arcGisApiKey;
    this.arcGisPortalUrl = arcGisPortalUrl;
    this.arcGisClientId = arcGisClientId;
  }

  private checkUserDetails = async (arcGisPortalUrl: string) => {
    this.setArcGisLoginStatus(false, true, false);

    const portal = new Portal();
    portal.url = arcGisPortalUrl;
    portal.authMode = 'immediate';

    await portal.load();
  };

  private login = async (arcGisPortalUrl: string) => {
    try {
      const credentialResult = await this.esriId.getCredential(arcGisPortalUrl + '/sharing', {
        oAuthPopupConfirmation: false,
      });

      this.setArcGisLoginStatus(false, true, false);

      return credentialResult;
    } catch {
      this.setArcGisLoginStatus(false, false, true);
      return null;
    }
  };

  private initAuth = async () => {
    try {
      await this.esriId.checkSignInStatus(this.arcGisPortalUrl + '/sharing');
      await this.checkUserDetails(this.arcGisPortalUrl);
    } catch {
      try {
        const loginResult = await this.login(this.arcGisPortalUrl);

        if (loginResult) await this.checkUserDetails(this.arcGisPortalUrl);
      } catch {
        this.setArcGisLoginStatus(false, false, true);
      }
    }
  };

  setupArcGis = async () => {
    if (this.arcGisErrorLoggingIn || this.arcGisIsAuthenticated) {
      return;
    }

    this.setArcGisLoginStatus(true, false, false);

    esriConfig.portalUrl = this.arcGisPortalUrl;

    if (!this.arcGisUseOauth && this.arcGisApiKey) {
      esriConfig.apiKey = this.arcGisApiKey;
      this.setArcGisLoginStatus(false, true, false);
    } else {
      const info = new OAuthInfo({
        appId: this.arcGisClientId,
        portalUrl: this.arcGisPortalUrl,
        popup: true,
        popupCallbackUrl: `${window.location.origin}/esri/oauth-callback.html`,
      });

      this.esriId.registerOAuthInfos([info]);

      await this.initAuth();
    }
  };

  private setArcGisLoginStatus = (isLoggingIn, isAuthenticated, errorLoggingIn) => {
    this.arcGisIsLoggingIn = isLoggingIn;
    this.arcGisIsAuthenticated = isAuthenticated;
    this.arcGisErrorLoggingIn = errorLoggingIn;
  };
}
