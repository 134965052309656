import { IPageMetaData } from '../common/models/IPagedResponse';

export class PagingArray {
  private _itemPerPage: number;
  private _currentPage: number;
  private _totalCount: number;
  private _totalPage: number;

  constructor(perPage: number, page: number, totalCount: number, totalPage: number) {
    this._currentPage = page;
    this._itemPerPage = perPage;
    this._totalCount = totalCount;
    this._totalPage = totalPage;
  }

  /**
   * number of elements displayed per page
   */
  perPage = (): number => this._itemPerPage;

  /**
   * index of the current page, count start from 1 for the first page, -1 if there is no result
   */
  currentPage = (): number => this._currentPage;

  /**
   * total number of elements
   */
  totalCount = (): number => this._totalCount;

  /**
   * total number of elements
   */
  totalPages = (): number => this._totalPage;

  /**
   * Calculate the first index for a given page
   * @param page starts from 1
   */
  public getFirstIndex(page: number): number {
    return (page - 1) * this.perPage();
  }

  /**
   * Calculate the last index for a given page
   * @param page starts from 1
   */
  public getLastIndex(page: number): number {
    return Math.min(page * this.perPage(), this._totalCount) - 1;
  }

  /**
   * Whether a given page is the last one
   * @param page starts from 1
   */
  public isLastPage(page: number): boolean {
    return this.getFirstIndex(page) <= this._totalCount - 1 && this._totalCount - 1 <= this.getLastIndex(page);
  }

  /**
   * Whether a given page is the last one
   * @param page starts from 1
   */
  public isFirstPage(page: number): boolean {
    return this.getFirstIndex(page) === 0;
  }

  private hasPreviousPage(page: number): boolean {
    return !this.isFirstPage(page);
  }

  private hasNextPage(page: number): boolean {
    return !this.isLastPage(page);
  }
  /**
   * Whether the pagination is empty
   */
  public isEmpty(): boolean {
    return this._itemPerPage === 0;
  }

  /**
   * Get the paging meta data object
   */
  public getMetaData(): IPageMetaData {
    return {
      pageCount: this._totalPage,
      totalCount: this._totalCount,
      pageNumber: this._currentPage,
      pageSize: this._itemPerPage,
      hasPreviousPage: this.hasPreviousPage(this._currentPage),
      hasNextPage: this.hasNextPage(this._currentPage),
      isFirstPage: this.isFirstPage(this._currentPage),
      isLastPage: this.isLastPage(this._currentPage),
      firstItemOnPage: this.getFirstIndex(this._currentPage),
      lastItemOnPage: this.getLastIndex(this._currentPage),
    } as IPageMetaData;
  }
}
