import { TransmittalType } from '../../common/enums/TransmittalType';
import { TransmittalIssueReason } from '../../common/enums/TransmittalIssueReason';
import { TransmittalSortOption } from '../../common/enums/TransmittalSortOption';
import { SelectorType } from '../../enums/TransmittalsSelectorType';

export enum TabFilter {
  MyNotifications,
  InitiatedByMe,
  VisibleToMe,
  AllDeliveryTeam,
}

export const TabFilterText = {
  [TabFilter.MyNotifications]: 'MyNotifications',
  [TabFilter.InitiatedByMe]: 'InitiatedByMe',
  [TabFilter.VisibleToMe]: 'VisibleToMe',
  [TabFilter.AllDeliveryTeam]: 'AllDeliveryTeam',
};

export type TypeFilter = TransmittalType | -1;
export type IssueReasonFilter = TransmittalIssueReason | -1;
export type SortColumn = TransmittalSortOption | -1;

export enum SupportFileMode {
  CreateTransmittal,
  CreateResponse,
}

export type TransmittalTeamChartType = 'Notify' | 'Visible' | '';

export enum TransmittalFileTypeText {
  FILE_CONTAINER = 'FileContainer',
  CONTAINER_FILE = 'ContainerFile',
}

export type TransmittalFileType = 'FileContainer' | 'ContainerFile';

export interface IOpenPillProps {
  id: number;
  selectedType: SelectorType;
}

export interface IPillSelectedItem {
  id: string;
  value: JSX.Element;
  selectedType: SelectorType;
}
