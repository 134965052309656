import { serverAxios } from '../api';

export interface IRemoveUserFromAppointingPartyRequest {
  projectNumber: string;
  userId: number;
}

export function removeUserFromAppointingParty(
  request: IRemoveUserFromAppointingPartyRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/appointingparty/users/remove', request, {
    signal: abortSignal,
  });
}
