import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TableRow, TableCell, IHeader, Checkbox, Tooltip } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/TransmittalTableRow.module.scss';
import { dateTime12HFormat, formatDate } from '../../../utils/dateUtils';
import Icon from '../../shared/Icon';
import Dot from '../../shared/Dot';
import TransmittalsStore, { ITransmittalSearchListItem, tableHeaderKeyValueFields } from '../TransmittalsStore';
import { Pages } from '../../../common/constants/Pages';
import { ITableColumn } from '../../../common/interfaces/TableColumn';
import classNames from 'classnames';

export interface ITransmittalTableRowProps {
  headers: IHeader[];
  transmittal: ITransmittalSearchListItem;
  tableColumns: ITableColumn[];
}
const ShiftKey = 'Shift';
const BodyTagName = 'body';

const TransmittalTableRow: FC<ITransmittalTableRowProps> = ({ headers, transmittal, tableColumns }) => {
  const [shiftOn, setShiftOn] = useState(false);
  const [showTooltips, setShowTooltips] = useState<string[]>([]);
  const refs = useRef<HTMLTableCellElement[]>([]);

  useEffect(() => {
    const handleKeyPressDown = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== BodyTagName) return;
      if (e.key === ShiftKey) {
        window?.getSelection()?.removeAllRanges();
        setShiftOn(true);
      }
    };
    const handleKeyPressUp = (e) => {
      if (e.srcElement.tagName.toLowerCase() !== BodyTagName) return;
      if (e.key === ShiftKey) {
        window?.getSelection();
        setShiftOn(false);
      }
    };
    window.addEventListener('keydown', handleKeyPressDown, false);
    window.addEventListener('keyup', handleKeyPressUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyPressUp);
      window.removeEventListener('keydown', handleKeyPressDown);
    };
  }, []);

  const handleTableCellClick = () => {
    window.location.href = `#${Pages.Transmittals.Route}/${transmittal.title}/${transmittal.projectNumber}`;
  };

  const handleShowTooltip = (label: string) => {
    const items: string[] = [];

    const element = refs.current[label];
    if (element && element.scrollWidth > element.clientWidth) items.push(label);

    setShowTooltips(items);
  };

  const hasTooltip = (label: string) => {
    return showTooltips.includes(label);
  };

  const cellValue = (h: IHeader, i: number) => {
    let value = '';
    const col = tableColumns.find((c) => c.label === h.label);
    const def = headers[i];
    if (col && transmittal[col.valueField]) {
      if (transmittal[col.valueField] instanceof Date) value = dateTime12HFormat(transmittal[col.valueField] as Date);
      else value = transmittal[col.valueField].toString() ?? '';
    }

    return (
      <TableCell
        onClick={handleTableCellClick}
        align={def.align}
        key={h.label}
        onHover={() => {
          handleShowTooltip(def.label);
        }}>
        {hasTooltip(h.label) && (
          <Tooltip show={<div className={Style.wordWrap}>{value}</div>}>
            <div ref={(el) => (refs.current[def.label] = el)} className={Style.overFlow}>
              {value}
            </div>
          </Tooltip>
        )}
        {!hasTooltip(def.label) && (
          <div ref={(el) => (refs.current[def.label] = el)} className={classNames(Style.overFlow)}>
            {value}
          </div>
        )}
      </TableCell>
    );
  };

  return (
    <TableRow key={transmittal.title} rowClass={Style.transmittalRow}>
      {headers.map((h, i) => {
        const def = headers[i];

        if (def.onCheckbox) {
          return (
            <TableCell
              key={def.label}
              style={{ display: TransmittalsStore.showSelectCheckbox ? 'table-cell' : 'none' }}>
              <Checkbox
                onChange={(checked) => TransmittalsStore.setSelectedTransmittal(transmittal, checked, shiftOn)}
                checked={transmittal.checked}
              />
            </TableCell>
          );
        }

        if (h.label === tableHeaderKeyValueFields.flagged.label) {
          return (
            <TableCell key={def.label} onClick={handleTableCellClick}>
              {transmittal.flag && <Icon className={Style.flagged} name="flag" />}
            </TableCell>
          );
        }

        if (h.label === tableHeaderKeyValueFields.subject.label) {
          return (
            <TableCell
              key={def.label}
              onClick={handleTableCellClick}
              onHover={() => {
                handleShowTooltip(def.label);
              }}>
              {hasTooltip(h.label) && (
                <Tooltip show={<div className={Style.wordWrap}>{transmittal.subject}</div>}>
                  <div ref={(el) => (refs.current[def.label] = el)} className={Style.overFlow}>
                    {transmittal.subject}
                  </div>
                </Tooltip>
              )}
              {!hasTooltip(def.label) && (
                <div ref={(el) => (refs.current[def.label] = el)} className={classNames(Style.overFlow)}>
                  {transmittal.subject}
                </div>
              )}
            </TableCell>
          );
        }
        if (h.label === tableHeaderKeyValueFields.dueDate.label) {
          return (
            <TableCell key={def.label} onClick={handleTableCellClick}>
              {transmittal.dueDate ? formatDate(transmittal.dueDate) : null}
            </TableCell>
          );
        }
        if (h.label === tableHeaderKeyValueFields.status.label) {
          return (
            <TableCell key={def.label} onClick={handleTableCellClick}>
              {transmittal.closed && (
                <>
                  <Dot className={Style.closed} />
                  <small>Closed</small>
                </>
              )}
              {!transmittal.closed && (
                <>
                  <Dot
                    className={classNames({
                      [Style.overdue]: transmittal.overdue,
                      [Style.open]: !transmittal.overdue,
                    })}
                  />
                  <small>{transmittal.overdue ? 'Overdue' : 'Open'}</small>
                </>
              )}
            </TableCell>
          );
        }

        if (h.label === tableHeaderKeyValueFields.totalResponses.label) {
          return (
            <TableCell key={def.label} onClick={handleTableCellClick}>
              {transmittal.totalResponses}
            </TableCell>
          );
        }

        if (h.label === tableHeaderKeyValueFields.totalFiles.label) {
          return (
            <TableCell key={def.label} onClick={handleTableCellClick}>
              {transmittal.totalFiles}
            </TableCell>
          );
        }

        return cellValue(h, i);
      })}
    </TableRow>
  );
};

export default observer(TransmittalTableRow);
