import { IImportUserResponse, IImportUsersRequest } from '../../../models/api/IImportUserModel';
import { serverAxios } from '../api';

export async function importExternalUsers(
  request: IImportUsersRequest,
  abortSignal?: AbortSignal
): Promise<IImportUserResponse> {
  const result = await serverAxios.post<IImportUserResponse>('api/um/system/importExternalUsers', request, {
    signal: abortSignal,
  });
  return result.data;
}
