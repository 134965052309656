import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/ForgeViewer.module.scss';
interface IForgeViewerProps {
  token: string;
  urn: string;
}

const ForgeViewer: FC<IForgeViewerProps> = (props) => {
  useEffect(() => {
    const options = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',
      getAccessToken: (onTokenReady) => {
        const token = props.token;
        const timeInSeconds = 3600;
        onTokenReady(token, timeInSeconds);
      },
    };

    const htmlDiv = document.getElementById('forgeViewer')!;
    Autodesk.Viewing.Initializer(options, function onInitialized() {
      const viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, {});
      const documentId = 'urn:' + props.urn;
      viewer.start();
      const onDocumentLoadSuccess = (viewerDocument) => {
        const defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        viewer.loadDocumentNode(viewerDocument, defaultModel);
      };
      const onDocumentLoadFailure = (errorCode, message) => {
        console.error('Failed fetching forge manifest: ', errorCode, message);
      };
      Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
    });
  });

  return <div id="forgeViewer" className={Style.forgeViewer}></div>;
};

export default observer(ForgeViewer);
