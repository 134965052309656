import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Compass from '@arcgis/core/widgets/Compass';

import WidgetBase, { IWidgetBase } from './WidgetBase';

const CompassWidget: FC = () => {
  return WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new Compass({
        visible: true,
        container: properties.container,
        view: properties.view,
      })
  );
};

export default observer(CompassWidget);
