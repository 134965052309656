import { serverAxios } from '../api';

export interface IForgeViewerAuthenticateRequest {
  projectNumber: string;
  containerFileId: number | null;
}

export interface IForgeViewerAuthenticateResponse {
  urn: string;
  token: string;
}

export async function getForgeViewerAuthenticate({
  request,
  abortSignal,
}: {
  request: IForgeViewerAuthenticateRequest;
  abortSignal?: AbortSignal;
}): Promise<IForgeViewerAuthenticateResponse> {
  const result = await serverAxios.get<IForgeViewerAuthenticateResponse>('api/cms/forgeviewerauthenticate', {
    params: request,
    signal: abortSignal,
  });
  return result.data;
}
