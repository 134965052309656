import {
  DeliveryTeamAccessPermissionLabels,
  DeliveryTeamAccessPermissions,
} from '../../../../common/enums/DeliveryTeamSharePermission';

export interface IDeliveryTeamAccessed {
  accessibleDeliveryTeamId: number;
  accessibleDeliveryTeamTitle: string;
  permissions: ISharePermission[];
}

export interface ISharePermission {
  permissionId: DeliveryTeamAccessPermissions;
  title: string;
  canAccess: boolean;
}

export const defaultSharePermissions = [
  {
    permissionId: DeliveryTeamAccessPermissions.CAN_ACCESS_SHARED_FILES,
    title: DeliveryTeamAccessPermissionLabels[DeliveryTeamAccessPermissions.CAN_ACCESS_SHARED_FILES],
    canAccess: false,
  },
  {
    permissionId: DeliveryTeamAccessPermissions.CAN_ACCESS_PUBLISHED_FILE,
    title: DeliveryTeamAccessPermissionLabels[DeliveryTeamAccessPermissions.CAN_ACCESS_PUBLISHED_FILE],
    canAccess: false,
  },
] as ISharePermission[];
