import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/NavBarSelectorTaskTeam.module.scss';
import { TaskTeamItem } from '../../common/models/ItemType';
import NavBarSelectorItemContainer from './NavBarSelectorItemContainer';
import Icon from './Icon';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';

export interface INavBarSelectorTaskTeamProps {
  item: TaskTeamItem;
}

const NavBarSelectorTaskTeam: React.FC<INavBarSelectorTaskTeamProps> = ({ item }) => {
  return (
    <div className={Style.wrapContainer}>
      <NavBarSelectorItemContainer className={Style.container}>
        {item.taskTeam.isTemporaryAccessible ? (
          <Icon className={Style.icon} name="group_off" outlined />
        ) : (
          <Icon className={Style.icon} name="people" />
        )}
        <span>{item.taskTeam.deliveryTeamTitle}</span>
        <span>|</span>
        <span className={Style.taskTeamTitle}>{item.taskTeam.title}</span>
      </NavBarSelectorItemContainer>
      {item.taskTeam.isTemporaryAccessible && (
        <Tooltip show={`Temporary Access`} cssClass={Style.tooltipIconSchedule} defaultUp>
          <Icon className={Style.icon} name="schedule" outlined />
        </Tooltip>
      )}
    </div>
  );
};

export default observer(NavBarSelectorTaskTeam);
