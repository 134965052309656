import { serverAxios } from '../api';

export interface ITransmittalMessageAttachmentFile {
  id: number;
  fileRevisionId: number | null;
  releasedFileId: number | null;
  isForgeFile: boolean;
  title: string;
  originalFilename: string;
  uploadedSize: number;
}

export async function getTransmittalMessageAttachmentFiles(
  projectNumber: string,
  transmittalId: number,
  transmittalMessageId: number,
  abortSignal?: AbortSignal
): Promise<ITransmittalMessageAttachmentFile[]> {
  const result = await serverAxios.get<{
    messageSupportingFiles: ITransmittalMessageAttachmentFile[];
  }>('api/transmittals/getTransmittalSupportingFiles', {
    params: {
      projectNumber: projectNumber,
      transmittalId: transmittalId,
      transmittalMessageId: transmittalMessageId,
    },
    signal: abortSignal,
  });
  return result.data.messageSupportingFiles;
}
