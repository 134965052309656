import { serverAxios } from '../api';

export interface IAddDeliveryTeamRequest {
  projectNumber: string;
  title: string;
  transmittalCode: string;
}

export async function addDeliveryTeam(request: IAddDeliveryTeamRequest, abortSignal?: AbortSignal): Promise<number> {
  const result = await serverAxios.post<{ deliveryTeamId: number }>('api/um/deliveryteam/add', request, {
    signal: abortSignal,
  });
  return result.data.deliveryTeamId;
}
