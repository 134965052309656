import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Fullscreen from '@arcgis/core/widgets/Fullscreen';

import WidgetBase, { IWidgetBase } from './WidgetBase';

const FullscreenWidget: FC = () => {
  return WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new Fullscreen({
        visible: true,
        container: properties.container,
        view: properties.view,
      })
  );
};

export default observer(FullscreenWidget);
