import { serverAxios } from '../api';

export const reactiveSystemUser = async (userId: number, abortSignal?: AbortSignal): Promise<void> =>
  serverAxios.put(
    `api/um/system/users/reactive`,
    { userId },
    {
      signal: abortSignal,
    }
  );
