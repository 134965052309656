import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IHistoryItem, ITaskHistoryItem } from '../../../api/authenticated/cms/getFileHistory';
import TaskCollapsed from './TaskCollapsed';
import TaskExpanded from './TaskExpanded';

export interface IHistoryTaskProps {
  item: IHistoryItem;
  task: ITaskHistoryItem;
}

const HistoryTask: FC<IHistoryTaskProps> = ({ item, task }) => {
  const [expanded, setExpanded] = useState(false);

  if (!expanded) {
    return <TaskCollapsed item={item} task={task} onClick={() => setExpanded(true)} />;
  }

  return <TaskExpanded task={task} />;
};

export default observer(HistoryTask);
