import { TaskTeamRole } from '../../../common/enums/TaskTeamRole';
import { serverAxios } from '../api';

export interface IUpdateUserTaskTeamRoleRequest {
  projectNumber: string;
  taskTeamId: number;
  userId: number;
  taskTeamRoleId: TaskTeamRole;
  isAddNew: boolean;
}

export function updateUserTaskTeamRole(
  request: IUpdateUserTaskTeamRoleRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.put('api/um/taskteam/users/role', request, {
    signal: abortSignal,
  });
}
