import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import VisualisationStore from './VisualisationStore';
import MapViewer from './MapViewer';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import Style from '../../styles/components/visualisation/Visualisation.module.scss';
import { Pages } from '../../common/constants/Pages';
import { ErrorCode } from '../../common/constants/ErrorStatusText';
import { ArcGisAuthentication } from '../../arcGisAuthentication/arcGisAuthentication';
import AppStore from '../../stores/AppStore';

const Visualisation: FC = () => {
  const project = NavBarSelectorStore.selectedProject;
  const [arcGisIsLoggingIn, setArcGisIsLoggingIn] = useState(false);
  const [arcGisErrorLoggingIn, setArcGisErrorLoggingIn] = useState(false);
  const [arcGisIsAuthenticated, setArcGisIsAuthenticated] = useState(false);

  useEffect(() => {
    const appInsights = getAppInsightsInstance();
    if (appInsights) appInsights.trackPageView({ name: AppInsightPageView.VISUALISATION, isLoggedIn: true });
  }, []);

  useEffect(() => {
    const load = async () => {
      if (!project || !AppStore.client?.arcGisPortalUrl) return;
      VisualisationStore.loadProjectArcGisSettings(project);
      const arcGis = new ArcGisAuthentication(
        AppStore.client.arcGisUseOauth,
        AppStore.client.arcGisClientId,
        AppStore.client.arcGisApiKey,
        AppStore.client.arcGisPortalUrl
      );

      await arcGis.setupArcGis();
      setArcGisIsLoggingIn(arcGis.arcGisIsLoggingIn);
      setArcGisErrorLoggingIn(arcGis.arcGisErrorLoggingIn);
      setArcGisIsAuthenticated(arcGis.arcGisIsAuthenticated);
    };

    load();
  }, [project]);

  const missingConfig =
    !VisualisationStore.projectArcGisSettings.webSceneId && !VisualisationStore.isLoadingArcGisProjectSettings;

  if (VisualisationStore.isLoadingArcGisProjectSettings || arcGisIsLoggingIn)
    return <Loader label="Loading map settings..." />;

  if (!missingConfig && arcGisIsAuthenticated) return <MapViewer />;

  if (missingConfig)
    return (
      <div className={Style.errorPanel}>
        <p>Map data not configured</p>
      </div>
    );

  if (arcGisErrorLoggingIn) {
    window.location.href = `#${Pages.Error.Route}/${ErrorCode.Map_Error}`;
    return null;
  }
  return null;
};

export default observer(Visualisation);
