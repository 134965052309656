import { IPagedResponse } from '../../../common/models/IPagedResponse';
import { serverAxios } from '../api';
import { GetTemporaryAccessLogRequest, ITemporaryFileAccessLog } from './interface/getTemporaryAccessLogRequest';

export async function getTemporaryAccessLogs(
  condition: GetTemporaryAccessLogRequest,
  abortSignal?: AbortSignal
): Promise<IPagedResponse<ITemporaryFileAccessLog>> {
  const result = await serverAxios.get<{ temporaryFileAccessLogs: IPagedResponse<ITemporaryFileAccessLog> }>(
    'api/cms/temporaryAccessLogs',
    {
      params: condition,
      signal: abortSignal,
    }
  );
  return result.data?.temporaryFileAccessLogs ?? [];
}
