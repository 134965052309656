import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import Styles from './styles/ButtonSavingSpinner.module.scss';

export interface IButtonSavingSpinnerProps {
  isSaving: boolean;
  children?: React.ReactNode;
}

const ButtonSavingSpinner: FC<IButtonSavingSpinnerProps> = ({ isSaving, children }) => {
  if (isSaving) return <Loader cssClass={Styles.savingSpinner} size="extra small" />;
  return <>{children}</>;
};

export default observer(ButtonSavingSpinner);
