import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/ProgressBar.module.scss';
import CentreOnPage from '../../../shared/CentreOnPage';
import Overlay from '../../../shared/Overlay';
import Modal from '../../../shared/Modal';
import CloseButton from '../../../shared/CloseButton';
import { Icon } from '@aurecon-creative-technologies/styleguide';
import ModalActions from '../../../shared/ModalActions';
import { CSVLink } from 'react-csv';
import UserImportStore from '../../../../stores/settings/shared/userUpload/UserImportStore';
import PrimaryButton from '../../../shared/PrimaryButton';

interface IProgressBarProps {
  completed: number;
  uploadSuccess?: boolean;
  uploadFailed?: boolean;
  browseToUrl?: string;
  handleConfirmCallback?: () => void;
}

const ProgressBar: FC<IProgressBarProps> = (props) => {
  const { completed, uploadSuccess, uploadFailed, browseToUrl, handleConfirmCallback } = props;
  const exportData = UserImportStore.getResultsCSVData();

  const onConfirm = () => {
    UserImportStore.clear();
    handleConfirmCallback?.();
  };

  return (
    <CentreOnPage>
      <Overlay className={Style.modalOverlay} />
      <div className={Style.modal}>
        {completed <= 100 && !uploadFailed && !uploadSuccess && (
          <>
            <h1>
              List is currently uploading
              <br />
              Don&apos;t close the browser.
            </h1>
            <p>{`${completed.toFixed(1)}%`}</p>
            <div className={Style.container}>
              <div className={Style.filler} style={{ width: `${completed}%` }}>
                <span className={Style.label}> </span>
              </div>
            </div>
            <p>To continue using Tucana, open a new browser window</p>
            <PrimaryButton onClick={() => window.open(browseToUrl ? `#${browseToUrl}` : '/', '_blank')}>
              Open New Browser
            </PrimaryButton>
          </>
        )}
        {(uploadSuccess || uploadFailed) && (
          <CentreOnPage>
            <Overlay />
            <Modal className={Style.resultsModal}>
              <CloseButton onClick={onConfirm} />
              <h1>Upload Results</h1>
              <div className={Style.message}>
                {uploadSuccess ? (
                  <Icon cssClass={Style.success} type="check_circle" />
                ) : (
                  <Icon cssClass={Style.warning} type="warning" />
                )}
                <p>
                  {uploadSuccess
                    ? 'User list has been imported successfully. Please download the CSV file to view the upload results.'
                    : 'User list cannot be imported. Please download the CSV file to view the upload results.'}
                </p>
              </div>
              <CSVLink
                className={Style.exportButton}
                data={exportData.data}
                headers={exportData.headers}
                filename={exportData.filename}
                target="_blank">
                Export as CSV
              </CSVLink>
              <ModalActions>
                <PrimaryButton onClick={onConfirm}>Ok</PrimaryButton>
              </ModalActions>
            </Modal>
          </CentreOnPage>
        )}
      </div>
    </CentreOnPage>
  );
};

export default observer(ProgressBar);
