import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/projectManagement/ProjectVisualisation.module.scss';
import { Grid } from '@aurecon-creative-technologies/styleguide';
import ProjectManagementStore from '../../../stores/settings/projectManagement/ProjectManagementStore';
import { classNames } from '../../../utils/miscUtils';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import ProjectVisualisationWebSceneViewer from './ProjectVisualisationWebSceneViewer';

interface IProjectVisualisationProps {
  onUpdate: () => void;
}

const ProjectVisualisation: FC<IProjectVisualisationProps> = (props) => {
  return (
    <>
      {ProjectManagementStore.projectDetails && (
        <div className={Style.projectVisualisationWrapper}>
          <Grid row md={12} cssClass={classNames(Style.gridGroup)}>
            <div className={classNames(Style.header, Style.settingGroup)}>
              <span>Visualisation Configurations</span>
              <div className={Style.actions}>
                <PrimaryIconButton
                  icon="edit"
                  size="medium"
                  className={Style.actionButton}
                  onClick={() => props.onUpdate()}></PrimaryIconButton>
              </div>
            </div>
            <Grid gap={16} item md={12}>
              <p className={Style.settingNoticed}>
                To load a WebScene below, you need to simply enter the WebScene ID to set the project scene.
              </p>
            </Grid>
            <Grid item gap={16}>
              <Grid item md={12}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Web Scene ID</div>
                  <div className={Style.value}>
                    {ProjectManagementStore.projectDetails.visualisationSettings.webSceneId || '-'}
                  </div>
                </div>
              </Grid>
              <Grid item md={12}>
                {!ProjectManagementStore.projectDetails.visualisationSettings.webSceneId ? (
                  <div className={Style.notConfiguredPanel}>
                    <p>Map data not configured</p>
                  </div>
                ) : (
                  <ProjectVisualisationWebSceneViewer
                    webSceneId={ProjectManagementStore.projectDetails.visualisationSettings.webSceneId}
                  />
                )}
              </Grid>
              <Grid item md={12}>
                <div className={Style.formDisplayField}>
                  <div className={Style.formControlLabel}>Chainage ID</div>
                  <div className={Style.value}>
                    {ProjectManagementStore.projectDetails.visualisationSettings.chainageItemId}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default observer(ProjectVisualisation);
