import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface ISendNonWipCollaborationNotificationRequest {
  projectNumber: string;
  fileContainerStateId: FileContainerState;
  userIds: number[];
  releasedFileIds: number[];
  message?: string;
  temporaryAccessExpiredOn: Date | null;
}

export function sendNonWipCollaborationNotification(
  request: ISendNonWipCollaborationNotificationRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/cms/sendnonwipcollaborationnotification', request, {
    signal: abortSignal,
  });
}
