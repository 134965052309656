export enum ContentSelection {
  UploadFile = 1,
  AddBlankDocument = 2,
  AddTemplateDocument = 3,
  WIP = 4,
  Shared = 5,
  Published = 6,
  EditMetadata = 7,
}

export enum FileContainerStateNames {
  WIP = 'Wip',
  SHARED = 'Shared',
  PUBLISHED = 'Published',
}

export enum FileContentTabIds {
  WIP_TAB_ID = 1,
  SHARED_TAB_ID = 2,
  PUBLISHED_TAB_ID = 3,
}
