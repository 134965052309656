import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon, Pill } from '@aurecon-creative-technologies/styleguide';

export interface IDistributionListTagProps {
  distributionListName: string;
  userCount: number;
}

const DistributionListTag: FC<IDistributionListTagProps> = ({ distributionListName, userCount }) => {
  return (
    <span>
      <Icon type="people" /> {distributionListName}{' '}
      <Pill background="light" size="mini" colour={2}>
        {userCount}
      </Pill>
    </span>
  );
};

export default observer(DistributionListTag);
