import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import { serverAxios } from '../api';

export interface IClient {
  clientTitle: string;
  fileBatchLimit: number;
  fileUploadLimit: number;
  uploadChunkSize: number;
  arcGisApiKey: string;
  arcGisUseOauth: boolean;
  arcGisClientId: string;
  arcGisPortalUrl: string;
  faqUrl: string;
  userGuideUrl: string;
  supportUrl: string;
  namingCodeMaximumLength: number;
  canInviteOrRemoveAzure: boolean;
  canSyncAADUser: boolean;
  enableSupportingFiles: boolean;
  allowEnableDisableProjectSupportingFiles: boolean;
  applyLimitedTimeToEditFileMetadata: boolean;
  limitedTimeToEditFileMetadata: number;
  defaultTemporaryAccessExpiredOnNextDays: number;
  user: IUser;
  programmes: IProgramme[];
  enableSplashPage: boolean;
  joditKey: string;
}

export interface IProgramme {
  id: number;
  title: string;
  projects: IProject[];
}

export interface IProject {
  programmeId: number;
  programmeTitle: string;
  projectNumber: string;
  title: string;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  devSite: boolean;
  canAdminister: boolean;
  isProjectExternalUser: boolean;
  taskTeams: ITaskTeam[];
  appointingPartyUser: boolean;
  appointingParties: IAppointingParty[];
  displayApps: boolean;
  displayFiles: boolean;
  displayTasks: boolean;
  displayTransmittals: boolean;
  displayVisualisation: boolean;
  allowCollaborationOtherTaskTeams: boolean;
  applyApims: boolean;
  requireAllApprovers: boolean;
  requireAllAuthorisers: boolean;
  requireAllAcceptors: boolean;
}

export interface ITaskTeam {
  projectNumber: string;
  id: number;
  title: string;
  code: string;
  deliveryTeamId: number;
  deliveryTeamTitle: string;
  deliveryTeamCode: string;
  canDelete: boolean;
  isTemporaryAccessible: boolean;
}

export interface IUser {
  name: string;
  email: string;
  clientUserRole: ClientUserRole;
}

export interface IAppointingParty {
  id: string;
  title: string;
  transmittalCode: string;
}

export async function getClientInfo(abortSignal?: AbortSignal): Promise<IClient | undefined> {
  const result = await serverAxios.get<IClient>('/api/config/client', { signal: abortSignal });
  return result.data;
}
