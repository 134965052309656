import { TransmittalSortOption } from '../enums/TransmittalSortOption';

export const TransmittalSortOptionText = {
  [TransmittalSortOption.LastUpdated]: 'Last Updated',
  [TransmittalSortOption.InitiatedDate]: 'Initiated Date',
  [TransmittalSortOption.DueDate]: 'Due Date',
  [TransmittalSortOption.TransmittalId]: 'Transmittal ID',
  [TransmittalSortOption.Initiator]: 'Initiator',
  [TransmittalSortOption.TaskTeamOriginator]: 'Task Team Originator',
};
