import { serverAxios } from '../api';

export interface IRemoveExternalUserRequest {
  projectNumber: string;
  userId: number;
}

export function removeExternalUser(request: IRemoveExternalUserRequest, abortSignal?: AbortSignal): Promise<void> {
  return serverAxios.post('api/um/system/externalusers/remove', request, {
    signal: abortSignal,
  });
}
