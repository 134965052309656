import { serverAxios } from '../api';
import { IGrantingAccessToOtherTeamModel } from './interfaces/deliveryTeamPermission.interface';

export function grantingAccessToOtherTeam(
  request: IGrantingAccessToOtherTeamModel,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/deliveryteam/grantingaccesstootherteam', request, {
    signal: abortSignal,
  });
}
