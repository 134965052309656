import { IImportUserResponse, IImportUsersRequest } from '../../../models/api/IImportUserModel';
import { serverAxios } from '../api';

interface IImportTaskTeamUsersRequest extends IImportUsersRequest {
  taskTeamId: number;
}

export async function ImportTaskTeamUsers(
  request: IImportTaskTeamUsersRequest,
  abortSignal?: AbortSignal
): Promise<IImportUserResponse> {
  const result = await serverAxios.post<IImportUserResponse>(
    'api/um/system/importTaskTeamUsers',
    { ...request },
    {
      signal: abortSignal,
    }
  );
  return result.data;
}
