import { makeAutoObservable, runInAction } from 'mobx';
import AppStore from '../../../stores/AppStore';
import { flatMap } from 'lodash';
import { IProgramme, IProject } from '../../../api/authenticated/config/getClientInfo';
import { SortTypes } from '../../../common/enums/SortType';
import { sort } from '../../../utils/sortHelper';

const localStorageSelectorKey = 'tasks-selector-id';

export interface IItem {
  id: string;
  programme?: IProgramme;
  project?: IProject;
  key?: string;
}

export class NavBarSelectorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public items: IItem[] = [];
  public selectedItem?: IItem;

  public initSelector() {
    runInAction(() => {
      this.items = [
        ...flatMap(sort(AppStore.client?.programmes || [], 'title', SortTypes.ASC), (programme) => [
          {
            id: `programmeId:${programme.id}`,
            programme,
            key: `${programme.title}`,
          },
          ...sort(programme.projects || [], 'title', SortTypes.ASC).map((project) => ({
            id: `projectNumber:${project.projectNumber}`,
            project: { ...project, programmeId: programme.id, programmeTitle: programme.title },
            key: `${programme.title}-${project.title}`,
          })),
        ]),
      ];

      this.setDefaultItemSelected();
    });
  }

  private setDefaultItemSelected() {
    let id: string | null = null;
    if (AppStore.selectedProgrammeId && AppStore.selectedProjectNumber) {
      const globalItem = this.items.find(
        (x) =>
          (x.project?.programmeId === AppStore.selectedProgrammeId &&
            x.project?.projectNumber === AppStore.selectedProjectNumber) ||
          x.project?.projectNumber === AppStore.selectedProjectNumber
      );
      if (globalItem) id = globalItem?.id ?? null;
    }
    if (!id) {
      id = localStorage.getItem(localStorageSelectorKey) ?? this.items[0]?.id;
    }
    runInAction(() => {
      this.selectedItem = this.items.find((item) => item.id === id);
      if ((!this.selectedItem && this.items.length) || this.selectedItem?.programme) {
        this.selectedItem = this.items.filter((x) => x.project)[0];
      }
      if (this.selectedItem) {
        this.updateGlobalNavItemSelected(this.selectedItem);
      }
    });
  }

  public setSelectedSelectorItem(id: string) {
    const selectedItem = this.items.find((item) => item.id === id);
    if (!selectedItem || selectedItem.programme) return;

    this.updateGlobalNavItemSelected(selectedItem);
    runInAction(() => {
      this.selectedItem = selectedItem;
    });
  }

  private updateGlobalNavItemSelected(item: IItem) {
    AppStore.setProgrammeId(item.project?.programmeId);
    AppStore.setProjectNumber(item.project?.projectNumber);
    AppStore.setDeliveryTeamId();
    AppStore.setTaskTeamId();
    this.setProjectSettings(item.project);
    localStorage.setItem(localStorageSelectorKey, item.id);
  }

  private setProjectSettings(project?: IProject) {
    AppStore.setProjectSettings({
      displayApps: project?.displayApps ?? false,
      displayFiles: project?.displayFiles ?? false,
      displayTasks: project?.displayTasks ?? false,
      displayVisualisation: project?.displayVisualisation ?? false,
      displayTransmittals: project?.displayTransmittals ?? false,
      requireAllApprovers: project?.requireAllApprovers ?? false,
      requireAllAuthorisers: project?.requireAllAuthorisers ?? false,
      requireAllAcceptors: project?.requireAllAcceptors ?? false,
    });
  }
}

export default new NavBarSelectorStore();
