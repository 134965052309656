import { serverAxios } from '../api';

export interface IRemoveUserFromDistributionListRequest {
  projectNumber: string;
  distributionListId: number;
  userId: number;
}

export function removeUserFromDistributionList(
  request: IRemoveUserFromDistributionListRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  return serverAxios.post('api/um/distributionlist/users/remove', request, {
    signal: abortSignal,
  });
}
