import * as React from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/NavBarSelectorProgramme.module.scss';
import NavBarSelectorItemContainer from './NavBarSelectorItemContainer';
import { ProgrammeItem } from '../../common/models/ItemType';

export interface INavBarSelectorProgrammeProps {
  item: ProgrammeItem;
}

const NavBarSelectorProgramme: React.FC<INavBarSelectorProgrammeProps> = ({ item }) => {
  return (
    <NavBarSelectorItemContainer className={Style.container}>
      <span>{item.programme.title}</span>
    </NavBarSelectorItemContainer>
  );
};

export default observer(NavBarSelectorProgramme);
