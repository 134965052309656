import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from '../../../styles/components/settings/teamManagement/DistributionListUserDetails.module.scss';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';
import {
  FormInput,
  IHeader,
  ISuggestion,
  Loader,
  SearchBox,
  Table,
  TableCell,
  TableRow,
} from '@aurecon-creative-technologies/styleguide';
import { IUser } from '../../shared/UserSelector';
import SearchBar from '../../shared/SearchBar';
import PrimaryButton from '../../shared/PrimaryButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { SortTypes } from '../../../common/enums/SortType';
import SecondaryButton from '../../shared/SecondaryButton';
import { IValidationImportResultModel } from '../shared/userUpload/models/ValidationImportUserModel';
import UserImportModal from '../shared/userUpload/UserImportModal';
import { importDistributionListUsers } from '../../../api/authenticated/um/importDistributionUsers';
import { Pages } from '../../../common/constants/Pages';
import { SettingTabIds } from '../Types';

export interface IDeliveryTeamUsersProps {
  projectNumber: string;
  distributionListId: number;
}

const DistributionListUsers: FC<IDeliveryTeamUsersProps> = (props) => {
  const { projectNumber, distributionListId } = props;
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [sortDirection, setSortDirection] = useState<SortTypes>();
  const [sortedField, setSortedField] = useState('');
  const [searchText, setSearchText] = useState<string>('');
  const [triggerCleanup, setTriggerCleanup] = useState<number>(1);
  const [filteredUsers, setFilteredUsers] = useState<ISuggestion[]>([]);
  const [displayName, setDisplayName] = useState<string>('');
  const [showImportUserModal, setShowImportUserModal] = useState(false);

  const fetchDataAsync = useCallback(async () => {
    if (!projectNumber || !distributionListId) return;

    setLoading(true);
    await TeamManagementStore.getAllUsersList(projectNumber);
    await TeamManagementStore.loadDistributionListUsers(projectNumber, distributionListId);
    setLoading(false);
  }, [projectNumber, distributionListId]);

  useEffect(() => {
    fetchDataAsync();
  }, [fetchDataAsync]);

  useEffect(() => {
    if (!projectNumber || !distributionListId) return;

    const effectAsync = async () => {
      setLoading(true);
      await TeamManagementStore.getAllUsersList(projectNumber);
      await TeamManagementStore.loadDistributionListUsers(projectNumber, distributionListId);
      setLoading(false);
    };
    effectAsync();
  }, [projectNumber, distributionListId]);

  const removeUserFromList = async (selectedUser: IUser) => {
    selectedUser &&
      (await TeamManagementStore.removeUserFromDistributionList(
        projectNumber,
        distributionListId,
        selectedUser.id || 0
      ));
  };
  const addSelectedUser = async () => {
    await TeamManagementStore.addUserToDistributionList(projectNumber, distributionListId, selectedUser?.id ?? 0);
    setTriggerCleanup(triggerCleanup + 1);
    onUserClear();
  };
  const onSortSelected = (columnName: string, sort: SortTypes) => {
    setSortedField(columnName);
    setSortDirection(sort);
    TeamManagementStore.applySortDistributionList(columnName, sort);
  };

  const headers: IHeader[] = [
    {
      label: 'User Name',
      sort: sortedField === 'name' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('name', sort as SortTypes),
    },
    {
      label: 'User Email',
      sort: sortedField === 'email' ? sortDirection : 'none',
      onSort: (sort) => onSortSelected('email', sort as SortTypes),
    },
    {
      label: 'Remove',
    },
  ];

  const onUserClear = () => {
    setFilteredUsers([]);
    setSelectedUser(null);
    setDisplayName('');
  };
  const getMatchedUsers = (searchText: string) => {
    setFilteredUsers([]);
    if (!searchText) {
      setFilteredUsers([]);
    } else {
      const selectedUserIds = TeamManagementStore.distributionListUsers.map((i) => i.id);
      const response = TeamManagementStore.distributionOptionUsers
        .filter(
          (r) =>
            (r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
              r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1) &&
            !selectedUserIds.some((x) => x === r.id)
        )
        .map((r) => ({
          id: r.id,
          name: r.name,
          email: r.email,
          disabled: r.disabled,
        }));
      setFilteredUsers(
        response.map((x) => {
          return {
            id: x.id,
            value: x.email,
            display: x.email,
          };
        })
      );
    }
  };
  const onSelectedUsers = (selected: ISuggestion) => {
    setFilteredUsers([]);
    const user = TeamManagementStore.distributionOptionUsers.find((r) => r.id === selected.id) ?? null;
    if (!user) return;
    setDisplayName(user.name);
    setSelectedUser({ id: Number(selected.id), email: user.email, name: user.name });
  };
  const isDisabled = () => {
    return loading || !selectedUser;
  };

  const onCloseUploadFileModal = () => {
    setShowImportUserModal(false);
    fetchDataAsync();
  };

  const performValidationDistributionListUser = (
    validUsers: IValidationImportResultModel[]
  ): {
    errorUsers: IValidationImportResultModel[];
    duplicateUsers: IValidationImportResultModel[];
    readyForImportUsers: IValidationImportResultModel[];
  } => {
    const allUsers = [...TeamManagementStore.distributionOptionUsers];
    const errors = validUsers.filter(
      (f) => !allUsers.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter((f) => !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()));

    const duplicates = validUsers.filter((f) =>
      TeamManagementStore.distributionListUsers.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    validUsers = validUsers.filter(
      (f) =>
        !errors.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase()) &&
        !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );
    const readyForImport = validUsers.filter(
      (f) => !duplicates.map((m) => m.email.toLowerCase()).includes(f.email.toLowerCase())
    );

    return {
      errorUsers: errors.map((m) => {
        return {
          ...m,
          result: 'User not found in Project',
        };
      }),
      duplicateUsers: duplicates.map((m) => {
        return {
          ...m,
          result: 'User is already assigned to this team',
        };
      }),
      readyForImportUsers: readyForImport,
    };
  };

  const handleImportUser = async (readyForImport: IValidationImportResultModel[]) => {
    let response: {
      isSuccess: boolean;
      importResults: {
        email: string;
        status: string;
        reason: string;
      }[];
    };
    try {
      response = await importDistributionListUsers({
        distributionListId,
        users: readyForImport.map((m) => {
          return {
            email: m.email,
            displayName: m.displayName,
          };
        }),
        projectNumber: projectNumber,
      });

      if (response.isSuccess) await fetchDataAsync();
    } catch (error) {
      response = {
        isSuccess: false,
        importResults: readyForImport.map((m) => {
          return {
            email: m.email,
            status: 'Failures',
            reason: 'Something went wrong',
          };
        }),
      };
    }
    return response;
  };

  if (loading) return <Loader />;

  return (
    <div className={Style.Container}>
      <div className={Style.addUserBox}>
        <div className={Style.heading}>Add User</div>
        <div className={Style.addForm}>
          <div className={Style.userEmailInput}>
            <div className={Style.label}>
              User Email <span className={Style.requiredSymbol}>*</span>
            </div>
            <SearchBox
              hideSearchButton
              disableDefaultMatching
              suggestions={filteredUsers}
              onChange={getMatchedUsers}
              onSearch={getMatchedUsers}
              onSelect={onSelectedUsers}
              onClear={onUserClear}
              triggerCleanup={triggerCleanup}
            />
          </div>

          <div className={Style.displayNameInput}>
            <div className={Style.label}>
              Display Name <span className={Style.requiredSymbol}>*</span>
            </div>
            <FormInput disabled={true} value={displayName} />
          </div>

          <div>
            <div className={Style.labelBtn}>Add</div>
            <PrimaryButton className={Style.btnAdd} disabled={isDisabled()} onClick={addSelectedUser}>
              Add
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={Style.UserTable}>
        <div className={Style.actionContainer}>
          <div className={Style.actionHeader}>
            <SecondaryButton
              className={Style.btnMenuUploadBtn}
              disabled={loading}
              onClick={() => {
                setShowImportUserModal(true);
              }}>
              Import Users
            </SecondaryButton>
          </div>
          <div className={Style.actionHeader}>
            <SearchBar
              searchValue={searchText}
              cssClass={Style.searchUserBox}
              onSearch={(keyword) => {
                setSearchText(keyword);
              }}
              placeHolderText="Quick search..."
            />
          </div>
        </div>
        <Table headers={headers} breakpoint={10} hoverable>
          {TeamManagementStore.distributionListUsers
            .filter(
              (u) =>
                u.name.toLowerCase().includes(searchText.toLowerCase() || u.name.toLowerCase()) ||
                u.email.includes(searchText.toLowerCase() || u.email.toLowerCase())
            )
            .map((u) => (
              <TableRow key={u.id}>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>
                  <PrimaryIconButton
                    icon="delete"
                    onClick={() => {
                      removeUserFromList(u);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </div>
      {showImportUserModal && (
        <UserImportModal
          closeModal={onCloseUploadFileModal}
          showModal={showImportUserModal}
          customUserValidation={performValidationDistributionListUser}
          importUserCallback={handleImportUser}
          browseToUrl={`${Pages.Settings.Route}?tabid=${SettingTabIds.TEAM_MANAGEMENT}`}
          users={TeamManagementStore.distributionOptionUsers.map((u) => ({
            id: u.id,
            email: u.email,
            username: u.name,
            active: true,
          }))}
          checkSystemAdmin={false}
        />
      )}
    </div>
  );
};

export default observer(DistributionListUsers);
