import { Auth0Instance } from './auth0Instance';
import { AuthType } from '../models/AuthType';
import { IAuthInstance } from '../models/IAuthInstance';
import { MsalInstance } from './msalInstance';

let authClientInstance: IAuthInstance;

const initAuthClient = async (
  clientId: string,
  scope: string,
  audience: string,
  authority: string,
  type: AuthType
): Promise<void> => {
  if (type === 'msal') authClientInstance = new MsalInstance(authority, clientId);
  else authClientInstance = new Auth0Instance(authority, clientId, audience, scope);
  await authClientInstance.init();
};

const getAuthClientInstance = (): IAuthInstance => {
  if (!authClientInstance) {
    throw new Error('Auth client is not initialized.');
  }

  return authClientInstance;
};

export { initAuthClient, getAuthClientInstance };
