import { FileContainerState } from '../../../common/enums/FileContainerState';
import { serverAxios } from '../api';

export interface ITaskTeamOriginatorResponse {
  taskTeamOriginator: ITaskTeamOriginator[];
}
export interface ITaskTeamOriginator {
  taskTeamId: number;
  taskTeamTitle: string;
  taskTeamCode: string;
  deliveryTeamTitle: string;
}

export async function getFilesTaskTeamOriginator(
  projectNumber: string,
  taskTeamId: number | undefined,
  fileContainerStateId: FileContainerState,
  abortSignal?: AbortSignal
): Promise<ITaskTeamOriginator[]> {
  const result = await serverAxios.get<ITaskTeamOriginatorResponse>('api/cms/filesTaskTeamOriginator', {
    params: { projectNumber: projectNumber, taskTeamId: taskTeamId, fileContainerStateId: fileContainerStateId },
    signal: abortSignal,
  });
  return result.data.taskTeamOriginator;
}
