import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import SystemUsers from './systemUsers/SystemUsers';
import AppStore from '../../../stores/AppStore';
import { ClientUserRole } from '../../../common/enums/ClientUserRole';
import SystemSettings from './systemSettings/SystemSettings';
import { SettingTabIds } from '../Types';

interface SystemAdminSettingsProps {
  tabId: SettingTabIds;
}

const SystemAdminSettings: FC<SystemAdminSettingsProps> = (props) => {
  const { tabId } = props;

  if (AppStore.client?.user.clientUserRole !== ClientUserRole.SystemAdmin) return null;
  return (
    <>
      {tabId === SettingTabIds.SYSTEM_SETTINGS && <SystemSettings />}
      {tabId === SettingTabIds.SYSTEM_USERS && <SystemUsers />}
    </>
  );
};

export default observer(SystemAdminSettings);
