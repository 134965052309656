import { serverAxios } from '../api';

export const archiveTransmittalType = async (
  projectNumber: string | null,
  transmittalTypeId: number,
  isArchived: boolean,
  abortSignal?: AbortSignal
): Promise<void> =>
  serverAxios.put(
    `api/transmittals/archiveTransmittalType`,
    { projectNumber, transmittalTypeId, isArchived },
    { signal: abortSignal }
  );

export const archiveTransmittalReason = async (
  projectNumber: string | null,
  transmittalReasonId: number,
  isArchived: boolean,
  abortSignal?: AbortSignal
): Promise<void> =>
  serverAxios.put(
    `api/transmittals/archiveTransmittalMailReason`,
    { projectNumber, transmittalReasonId, isArchived },
    { signal: abortSignal }
  );
