import { getAddData } from '../../../api/authenticated/tasks/getAddData';
import { TaskType } from '../../../common/enums/TaskType';

export function useGetAddTaskData(taskType: TaskType, taskTeamId: number) {
  const getAddTaskData = () => {
    return getAddData(taskType, taskTeamId);
  };

  return getAddTaskData;
}
