import React, { FC } from 'react';
import SystemUserImportResultTable from './SystemUserImportResultTable';
import { IValidationImportResult } from '../../../../../models/importSystemUsers/validationImportSystemUsers';
import { ITableColumn } from '../../../../../common/interfaces/TableColumn';

export interface ISystemUserImportResultTabContainerProps {
  tableColumns: ITableColumn[];
  importSystemUsers: IValidationImportResult[];
}

const SystemUserImportResultTabContainer: FC<ISystemUserImportResultTabContainerProps> = ({
  tableColumns,
  importSystemUsers,
}) => {
  return <SystemUserImportResultTable tableColumns={tableColumns} tableImportSystemUsers={importSystemUsers} />;
};

export default SystemUserImportResultTabContainer;
