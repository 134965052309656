import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, FormInput, Grid, Modal } from '@aurecon-creative-technologies/styleguide';
import DistributionListStore from '../../../stores/settings/teamManagement/DistributionListStore';
import Style from '../../../styles/components/settings/teamManagement/DistributionListDetails.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import TeamManagementStore from '../../../stores/settings/teamManagement/TeamManagementStore';

interface IEditListModalProps {
  listId: number;
  listName: string;
  projectNumber: string;
  onSave: (updatedListName: string) => void;
  onCancel: () => void;
}

const EditDistributionListModal: FC<IEditListModalProps> = ({ listId, projectNumber, listName, onSave, onCancel }) => {
  const [newNameError, setNewNameError] = useState<string>('');
  const [updatedName, setUpdatedName] = useState(listName);
  const [saving, setSaving] = useState(false);

  const validateDistributionListName = useCallback(() => {
    const maxCharacterCount = 200;
    let error = '';
    if (!updatedName) error = 'Team name is required';
    if (
      TeamManagementStore.clientTeams?.programmes
        .flatMap((x) => x.projects)
        .some(
          (p) =>
            p.projectNumber === projectNumber &&
            p.distributionList.some((dt) => dt.title.toLowerCase().trim() === updatedName.toLowerCase().trim())
        )
    )
      error = 'The list name already exists in this project';

    if (updatedName.length >= maxCharacterCount) error = `List name is longer than ${maxCharacterCount} characters`;

    setNewNameError(error);
  }, [updatedName, projectNumber]);

  useEffect(() => {
    if (listName !== updatedName) validateDistributionListName();
  }, [validateDistributionListName, listName, updatedName]);

  const onListSave = async () => {
    setSaving(true);

    const result = await DistributionListStore.editListName(listId, projectNumber, updatedName);

    setSaving(false);
    if (result) onSave(updatedName);
  };

  return (
    <Modal shouldOverlayClose={false} isShowing={true} onClose={onCancel} cssClass={Style.editListModal}>
      <>
        <h1>Edit List</h1>
        <p>Rename this distribution list.</p>
        <Grid row>
          <Grid md={12}>
            <FormInput
              type="text"
              label="List Name"
              placeholder="Enter list name..."
              required
              cssClass={Style.cellInputLeft}
              error={newNameError}
              value={updatedName}
              onChange={setUpdatedName}
            />
          </Grid>
        </Grid>
        <div className={Style.modalActions}>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          {!saving && (
            <PrimaryButton disabled={saving || listName === updatedName || !!newNameError} onClick={onListSave}>
              Edit
            </PrimaryButton>
          )}
          {saving && <Button label="Edit" loading={saving} />}
        </div>
      </>
    </Modal>
  );
};

export default observer(EditDistributionListModal);
