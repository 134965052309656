import { serverAxios } from '../api';

export interface IAddTaskTeamRequest {
  projectNumber: string;
  deliveryTeamId: number;
  title: string;
  transmittalCode: string;
}

export async function addTaskTeam(request: IAddTaskTeamRequest, abortSignal?: AbortSignal): Promise<number> {
  const result = await serverAxios.post<{ taskTeamId: number }>('api/um/taskteam/add', request, {
    signal: abortSignal,
  });
  return result.data.taskTeamId;
}
