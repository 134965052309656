import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/LimitedCharactersLeft.module.scss';
import { classNames } from '../../utils/miscUtils';

export interface ILimitedCharactersLeftProps {
  value?: string;
  maxLength: number;
  className?: string;
}

const LimitedCharactersLeft: FC<ILimitedCharactersLeftProps> = ({ value, maxLength, className }) => {
  return (
    <div className={classNames(Style.charactersLeft, className)}>
      {maxLength - (value ? value.length : 0)} characters left.
    </div>
  );
};

export default observer(LimitedCharactersLeft);
