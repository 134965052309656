export enum ProjectStatus {
  Draft = 1,
  InProgress = 2,
  ProvisioningFailed = 3,
  Active = 4,
  Inactive = 5,
}

export const ProjectStatusDisplayText = {
  [ProjectStatus.Draft]: 'In Progress',
  [ProjectStatus.InProgress]: 'In Progress',
  [ProjectStatus.ProvisioningFailed]: 'Failed',
  [ProjectStatus.Active]: 'Active',
  [ProjectStatus.Inactive]: 'Inactive',
};
