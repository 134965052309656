export enum ReviewTabIds {
  FILES,
  USER,
  ADVANCE,
}
export const ReviewTabLabels = {
  FILES: 'Review Files',
  USER: 'Choose Users',
  ADVANCE: 'Advance',
};
