import { ISuggestion } from '@aurecon-creative-technologies/styleguide';
import { makeAutoObservable, runInAction } from 'mobx';
import LayoutStore from '../../../components/layout/LayoutStore';
import { AxiosError } from 'axios';
import { SortTypes } from '../../../common/enums/SortType';
import { getExternalUsers } from '../../../api/authenticated/um/getExternalUsers';
import { getSystemExternalUser } from '../../../api/authenticated/um/getSystemExternalUser';
import { addExternalUsers } from '../../../api/authenticated/um/addExternalUsers';
import { removeExternalUser } from '../../../api/authenticated/um/removeExternalUser';
import { IUser } from '../../../api/authenticated/um/interfaces/user.interface';
import { sort } from '../../../utils/sortHelper';
import { IBaseUser } from '../../../common/interfaces/baseUser';
import AppStore from '../../../stores/AppStore';
import { getAppointingPartyUsers } from '../../../api/authenticated/um/getAppointingPartyUsers';
import { getTaskTeamUsers } from '../../../api/authenticated/um/getTaskTeamUsers';

export class ExternalUserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public email = '';
  public isRemovingUser = false;
  public externalUsers: IUser[] = [];
  public taskTeamUser: IUser[] = [];
  public appointingPartyUser: IUser[] = [];
  public userToBeRemoved?: IUser | null = null;
  public isShowAddMembersModal = false;
  public isEnabledAddMemberBtn = false;
  public allUsers: IBaseUser[] = [];
  public selectedUserId: number | null = null;
  public selectedRoleIds: number[] = [];

  public isError = false;
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public projectNumber: string | null = null;
  public filteredUsers?: ISuggestion[];
  public displayName: string | null = null;

  public async init(projectNumber: string) {
    if (!projectNumber) return;
    runInAction(() => {
      this.projectNumber = projectNumber;
      this.loadExternalUsers();
      this.loadTaskTeamUsers();
      this.loadAppointingPartyUsers();
      this.getOptionUsers();
      this.resetSelectedUsers();
    });
  }
  public getExternalUsersList() {
    return this.externalUsers;
  }

  public getTaskTeamUsersList() {
    return this.taskTeamUser;
  }

  public getAppointingPartyUsersList() {
    return this.appointingPartyUser;
  }

  public async loadExternalUsers() {
    if (!this.projectNumber) return;

    this.clearError();
    try {
      const exUsers = await getExternalUsers(this.projectNumber);
      runInAction(() => {
        this.externalUsers = exUsers.map((x) => {
          return {
            id: x.id,
            name: x.name,
            email: x.email,
          };
        }) as IUser[];
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.externalUsers = [];
        this.isError = true;
      });
    }
  }

  public async loadTaskTeamUsers() {
    if (!this.projectNumber) return;

    this.clearError();
    try {
      const taskTeamUsers = await getTaskTeamUsers(this.projectNumber);
      runInAction(() => {
        this.taskTeamUser = taskTeamUsers.map((x) => {
          return {
            id: x.id,
            name: x.name,
            email: x.email,
          };
        }) as IUser[];
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.taskTeamUser = [];
        this.isError = true;
      });
    }
  }

  public async loadAppointingPartyUsers() {
    if (!this.projectNumber) return;

    this.clearError();
    try {
      const appointingPartyUsers = await getAppointingPartyUsers(this.projectNumber);
      runInAction(() => {
        this.appointingPartyUser = appointingPartyUsers.map((x) => {
          return {
            id: x.id,
            name: x.name,
            email: x.email,
          };
        }) as IUser[];
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.appointingPartyUser = [];
        this.isError = true;
      });
    }
  }

  getAppointingPartyUsers;

  public setEmail(value: string) {
    runInAction(() => {
      this.email = value;
    });
  }

  public setRemoveUser(selectedUser: IUser | null) {
    runInAction(() => {
      this.userToBeRemoved = selectedUser;
    });
  }

  setDisplayName(text: string) {
    runInAction(() => {
      this.isEnabledAddMemberBtn = !!this.email && !!text && !!AppStore.client?.canInviteOrRemoveAzure;
      this.displayName = text;
    });
  }

  public async removeExternalUserFromList() {
    if (!this.projectNumber) return;

    const userId = this.userToBeRemoved?.id;
    if (userId == null) return;

    runInAction(() => {
      this.isRemovingUser = true;
    });

    this.clearError();
    try {
      await removeExternalUser({
        projectNumber: this.projectNumber,
        userId: userId,
      });

      this.loadExternalUsers();
      this.getOptionUsers();

      LayoutStore.displayToast('success', 'User has been deleted successfully');
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.isError = true;
        LayoutStore.displayToast('error', 'User cannot be deleted. Please try again later.');
      });
    } finally {
      runInAction(() => {
        this.isRemovingUser = false;
        this.userToBeRemoved = null;
      });
    }
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public closeErrorModal() {
    runInAction(() => {
      this.isError = false;
    });
  }

  /**
   * Create the data for the select user option.
   */
  public async getOptionUsers() {
    if (!this.projectNumber) return;

    try {
      runInAction(() => {
        this.allUsers = [];
        this.filteredUsers = [];
      });
      const users = await getSystemExternalUser(this.projectNumber);
      users.sort((a, b) => (a.name > b.name ? 1 : -1));

      runInAction(() => {
        this.allUsers = users;
      });
    } catch {
      runInAction(() => {
        this.allUsers = [];
        this.filteredUsers = [];
      });
    }
  }

  /**
   * Filter users matched with users by keyword.
   * @param searchText
   * @returns
   */
  public getMatchedUsers(searchText: string) {
    this.setEmail(searchText);
    if (searchText === null || searchText === '') {
      runInAction(() => {
        this.isEnabledAddMemberBtn = !!searchText && !!this.displayName && !!AppStore.client?.canInviteOrRemoveAzure;
        this.filteredUsers = [];
        this.isEnabledAddMemberBtn = false;
        this.displayName = null;
        this.selectedUserId = null;
      });
    } else {
      const response = this.allUsers
        .filter(
          (r) =>
            (r.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
              r.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1) &&
            this.selectedUserId !== r.id
        )
        .map((r) => ({
          id: r.id,
          name: r.name,
          email: r.email,
          disabled: r.disabled,
        }));
      runInAction(() => {
        this.isEnabledAddMemberBtn = !!searchText && !!this.displayName && !!AppStore.client?.canInviteOrRemoveAzure;
        this.selectedUserId = null;
        this.filteredUsers = response.map((x) => {
          return {
            id: x.id,
            value: x.email,
            display: x.email,
          };
        });
      });
    }
  }

  /**
   * Set selected user ids to be added.
   * @param users
   */
  public onSelectedUsersUpdated(selected: ISuggestion) {
    runInAction(() => {
      this.selectedUserId = null;
    });
    this.setEmail(selected.value);
    const user = this.allUsers.find((r) => r.id === selected.id);
    if (user) {
      runInAction(() => {
        this.selectedUserId = user.id;
        this.displayName = user.name ?? null;
        this.filteredUsers = [];
      });
    }
    runInAction(() => {
      // Enabled add members button if select at least 1 user.
      this.isEnabledAddMemberBtn = !!this.selectedUserId && !!this.displayName;
    });
  }
  /**
   * Reset Add member data
   */
  public resetSelectedUsers() {
    runInAction(() => {
      this.allUsers = [];
      this.selectedUserId = null;
      this.selectedRoleIds = [];
      this.isEnabledAddMemberBtn = false;
      this.displayName = null;
      this.filteredUsers = [];
    });
  }

  /**
   * Add member to External User
   * @returns
   */
  public async addMembersToExternalUser() {
    if (!!AppStore.client?.canInviteOrRemoveAzure && this.selectedUserId === null) {
      runInAction(() => {
        this.selectedUserId = 0;
      });
    }

    if (!this.projectNumber || this.selectedUserId === null) return;

    try {
      // Add External user
      const payload = {
        projectNumber: this.projectNumber,
        userId: this.selectedUserId,
        email: this.email ?? '',
        name: this.displayName ?? '',
      };
      await addExternalUsers(payload);

      this.resetSelectedUsers();
      this.loadExternalUsers();
      this.getOptionUsers();
      LayoutStore.displayToast('success', 'Users has been added successfully.');
    } catch {
      runInAction(() => {
        this.isError = true;
        LayoutStore.displayToast('error', 'User cannot be added. Please try again later.');
        this.resetSelectedUsers();
      });
    }
  }

  public applySort(columnName: string, direction: SortTypes) {
    if (direction != SortTypes.NONE) {
      this.externalUsers = sort(this.externalUsers, columnName, direction);
      return;
    }
    return this.loadExternalUsers();
  }
}

export default new ExternalUserStore();
