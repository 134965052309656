import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Style from '../../../styles/components/tasks/taskReassign/TaskReassignModal.module.scss';
import CloseButton from '../../shared/CloseButton';
import UserSelectorExpandable from '../../shared/UserSelectorExpandable';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import ModalActions from '../../shared/ModalActions';
import SuccessModal from '../../shared/SuccessModal';
import ErrorModal from '../../shared/ErrorModal';
import TaskReassignStore from './TaskReassignStore';
import TaskInformationStore from '../TaskInformationStore';
import TasksStore from '../../tasks/TasksStore';
import { Pages } from '../../../common/constants/Pages';
import { useNavigate } from 'react-router-dom';
import { TaskType } from '../../../common/enums/TaskType';

interface ITaskReassignModalProps {
  closeModal: () => void;
  taskUniqueIdentifier: string;
}

const TaskReassignModal: FC<ITaskReassignModalProps> = (props) => {
  const { closeModal, taskUniqueIdentifier } = props;
  const [taskReassignedSuccess, setTaskReassignedSuccess] = useState(false);
  const [taskReassignedFail, setTaskReassignedFail] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    TaskReassignStore.loadUsers();
  }, []);

  const handleSubmit = async () => {
    await TaskReassignStore.submit();
    if (!TaskReassignStore.hasErrorMessage) setTaskReassignedSuccess(true);
    else setTaskReassignedFail(true);
  };

  return (
    <>
      {!taskReassignedSuccess && !taskReassignedFail && (
        <CentreOnPage>
          <Overlay />
          <Modal className={Style.modal}>
            <CloseButton onClick={() => closeModal()} disabled={false} />
            <h3>Reassign Task</h3>
            <p>
              <strong>Task Unique Identifier</strong> {taskUniqueIdentifier}
            </p>
            <UserSelectorExpandable
              label="Reassign to"
              required
              getUsers={(text) => TaskReassignStore.getMatchedUsers(text)}
              onSelectedUsersUpdated={(user) => TaskReassignStore.onSelectedUsersUpdated(user)}
              isMultiUser={false}
              disabled={false}
              searchPlaceholder={'Add Approvers'}
            />
            <ModalActions>
              <SecondaryButton
                toolTip={'Cancel'}
                onClick={() => {
                  closeModal();
                }}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                disabled={!TaskReassignStore.canSubmit}
                onClick={handleSubmit}
                loading={TaskReassignStore.isSaving}>
                Submit
              </PrimaryButton>
            </ModalActions>
          </Modal>
        </CentreOnPage>
      )}
      {taskReassignedSuccess && (
        <SuccessModal
          title={'This task has been reassigned!'}
          info={'The assignee will be notified of this action and it will be visible on history'}
          closeModal={() => {
            if (TaskInformationStore.task?.taskTypeId === TaskType.PublishAccept) {
              if (TaskInformationStore.isRefresh) {
                TaskInformationStore.setIsRefresh(false);
                TasksStore.refreshTasks();
              }

              navigate(Pages.Tasks.Route);
            }

            closeModal();
          }}
        />
      )}
      {taskReassignedFail && (
        <ErrorModal
          closeModal={() => closeModal()}
          errorCode={TaskReassignStore.errorCode}
          errorMessage={TaskReassignStore.errorMessage}
        />
      )}
    </>
  );
};

export default observer(TaskReassignModal);
