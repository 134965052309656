import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from './Modal';
import CloseButton from './CloseButton';
import PrimaryButton from './PrimaryButton';
import ModalActions from './ModalActions';
import CentreOnPage from './CentreOnPage';
import Overlay from './Overlay';
import SecondaryButton from './SecondaryButton';
import Style from './styles/ConfirmationModal.module.scss';
import { Button } from '@aurecon-creative-technologies/styleguide';

interface IConfirmationModalProps {
  showModal: boolean;
  heading: string;
  message: string | ReactNode;
  htmlMessage?: string;
  cancelText?: string;
  confirmText: string;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  showModal,
  heading,
  message,
  htmlMessage,
  cancelText,
  confirmText,
  loading,
  onCancel,
  onConfirm,
}) => {
  if (!showModal) return null;
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.modal}>
        <CloseButton onClick={onCancel} />
        <h1>{heading}</h1>
        {htmlMessage ? <p dangerouslySetInnerHTML={{ __html: htmlMessage }}></p> : <p>{message}</p>}
        <ModalActions>
          {cancelText && <SecondaryButton onClick={onCancel}>{cancelText}</SecondaryButton>}
          {!loading && <PrimaryButton onClick={onConfirm}>{confirmText} </PrimaryButton>}
          {loading && <Button label={confirmText} loading={loading} />}
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(ConfirmationModal);
