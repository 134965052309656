import { serverAxios } from '../api';

export interface ITermConditionAgreementResponse {
  termConditionAgreements: {
    userId: number;
    acceptedDate: Date;
  }[];
}

export async function getTermConditionAgreement(abortSignal?: AbortSignal): Promise<ITermConditionAgreementResponse> {
  const result = await serverAxios.get<ITermConditionAgreementResponse>('api/um/tcagreement', {
    signal: abortSignal,
  });
  return result.data;
}
