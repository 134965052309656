import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SystemUserImportModal.module.scss';
import CentreOnPage from '../../../../shared/CentreOnPage';
import Overlay from '../../../../shared/Overlay';
import Modal from '../../../../shared/Modal';
import CloseButton from '../../../../shared/CloseButton';
import ModalActions from '../../../../shared/ModalActions';
import SecondaryButton from '../../../../shared/SecondaryButton';
import PrimaryButton from '../../../../shared/PrimaryButton';
import FileDropZone from '../../../../shared/FileDropZone';
import SystemUserImportStore from './SystemUserImportStore';
import Icon from '../../../../shared/Icon';
import { Loader } from '@aurecon-creative-technologies/styleguide';
import SystemUserImportResultTab from './SystemUserImportResultTab';
import ProgressBar from './ProgressBar';
import UnsavedModal from './UnsavedModal';
import { CSVLink } from 'react-csv';
import { IValidationImportResult } from '../../../../../models/importSystemUsers/validationImportSystemUsers';

interface ISystemUserImportModalProps {
  closeModal: (uploadSucceeded: boolean, errorMessage?: string) => void;
  showModal: boolean;
  importSystemUserCallback: (readyForImport: IValidationImportResult[]) => Promise<{
    isSuccess: boolean;
    importResults: {
      email: string;
      status: string;
      reason: string;
    }[];
  }>;
  systemUserUrl?: string;
}

const SystemUserImportModal: FC<ISystemUserImportModalProps> = (props) => {
  const { closeModal, showModal, importSystemUserCallback, systemUserUrl } = props;
  const [showUploadUnsavedModal, setShowUploadUnsavedModal] = useState(false);
  const [showImportSystemUserModal, setShowImportSystemUserModal] = useState(showModal);

  useEffect(() => {
    if (showUploadUnsavedModal) {
      setShowImportSystemUserModal(false);
    }
  }, [showUploadUnsavedModal]);

  const closeTabEvent = (ev) => {
    if (!SystemUserImportStore.selectedFile) return;

    ev.preventDefault();
    ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
    };
  }, []);

  const handleCancelButtonClick = () => {
    if (
      SystemUserImportStore.selectedFile &&
      (SystemUserImportStore.isProcessing || SystemUserImportStore.processed) &&
      !showUploadUnsavedModal
    ) {
      setShowUploadUnsavedModal(true);
      return;
    }
    setShowUploadUnsavedModal(false);
    closeModal?.(SystemUserImportStore.showUploadSuccess);
    SystemUserImportStore.clear();
  };

  const onUploadButtonClick = async () => {
    const importUsers = SystemUserImportStore.processedImportingUsers.readyForImport;
    if (!importUsers.length) return;

    setShowImportSystemUserModal(false);
    await SystemUserImportStore.handleImportSytemUser(importSystemUserCallback);
  };

  const handleOnDropzoneChange = (acceptedFiles) => {
    SystemUserImportStore.addFiles(acceptedFiles);
  };

  const handleConfirmUpload = () => {
    closeModal?.(SystemUserImportStore.showUploadSuccess);
    SystemUserImportStore.clear();
  };

  const renderLoader = () => (
    <div className={Style.loaderPadding}>
      <Loader
        size="extra small"
        label={
          SystemUserImportStore.totalUsers === 0 ? 'Processing' : `Processing ${SystemUserImportStore.totalUsers} rows`
        }
      />
    </div>
  );

  const renderResultTab = () => (
    <SystemUserImportResultTab processedUser={SystemUserImportStore.processedImportingUsers} />
  );

  const renderFileDropZone = () => (
    <div className={Style.uploadForm}>
      <FileDropZone
        className={Style.dropzone}
        label={
          <div>
            <Icon className={Style.noteAddIcon} name="note_add" />
            <div className={Style.uploadMessage}>
              <p>
                Drag & drop or click to <span className={Style.highLight}>browse</span> for files
              </p>
              <p>File supported: .csv</p>
              <p>Maximum size: 5MB</p>
            </div>
          </div>
        }
        multiple={false}
        onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles[0])}
      />
      {(SystemUserImportStore.filesSizeExceededTheLimit || SystemUserImportStore.isSelectedInvalidExtension) && (
        <p className={Style.errorSummary}>
          {SystemUserImportStore.isSelectedInvalidExtension
            ? 'Invalid file. Please download and use the template below.'
            : 'File is larger than the maximum size supported'}
        </p>
      )}
    </div>
  );

  const renderContent = () => {
    if (SystemUserImportStore.isProcessing) {
      return renderLoader();
    }
    if (SystemUserImportStore.processed) {
      return renderResultTab();
    }
    return renderFileDropZone();
  };

  const csvImportTemplate = SystemUserImportStore.getSystemUserImportCSVTemplate();

  return (
    <>
      {showImportSystemUserModal && (
        <CentreOnPage>
          <Overlay />
          <Modal className={Style.container}>
            <CloseButton onClick={handleCancelButtonClick} disabled={false} />
            <p className={Style.header}>{SystemUserImportStore.header}</p>
            <div>
              {renderContent()}
              {!SystemUserImportStore.processed && (
                <div className={Style.downloadTemplate}>
                  <CSVLink
                    className={Style.linkLabel}
                    data={csvImportTemplate.data}
                    headers={csvImportTemplate.headers}
                    filename={csvImportTemplate.filename}
                    target="_blank">
                    Download Template
                  </CSVLink>
                </div>
              )}
            </div>
            <ModalActions>
              <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
              <PrimaryButton
                disabled={
                  !SystemUserImportStore.selectedFile ||
                  (SystemUserImportStore.processedImportingUsers.readyForImport &&
                    !SystemUserImportStore.processedImportingUsers.readyForImport.length)
                }
                onClick={onUploadButtonClick}>
                Continue
              </PrimaryButton>
            </ModalActions>
          </Modal>
        </CentreOnPage>
      )}
      {showUploadUnsavedModal && (
        <UnsavedModal
          closeModal={() => {
            setShowUploadUnsavedModal(false);
            setShowImportSystemUserModal(true);
          }}
          redirect={handleCancelButtonClick}
          buttonContinueText="Yes"
          buttonCancelText="No"
        />
      )}
      {SystemUserImportStore.showProgressBar && (
        <ProgressBar
          completed={SystemUserImportStore.importProgress}
          uploadSuccess={SystemUserImportStore.showUploadSuccess}
          uploadFailed={SystemUserImportStore.showUploadFailed}
          systemUserUrl={systemUserUrl}
          handleConfirmCallback={handleConfirmUpload}
        />
      )}
    </>
  );
};

export default observer(SystemUserImportModal);
