export const Pages = {
  Home: { Route: '/' },
  Apps: { Route: '/apps' },
  Login: { Route: '/login' },
  TermsConditions: { Route: '/terms-conditions' },
  PrivacyPolicy: { Route: '/privacy-policy' },
  Files: { Route: '/files' },
  Tasks: { Route: '/tasks' },
  Visualisation: { Route: '/visualisation' },
  ProjectSettings: { Route: '/project-settings' },
  Project: { Route: '/project' },
  Transmittals: { Route: '/transmittals' },
  Error: { Route: '/error' },
  Settings: { Route: '/settings' },
  SupportHub: { Route: '/support' },
  PdfViewer: { Route: '/pdf-viewer' },
  CookiePolicy: { Route: '/cookies' },
  DisplayApps: { Route: '/apps' },
};
