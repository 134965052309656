import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { ITaskCreatedEvent } from '../../../api/authenticated/cms/getFileHistory';
import TaskEvent from './TaskEvent';

export interface ITaskEventCreatedProps {
  taskCreatedEvent?: ITaskCreatedEvent;
}

const TaskEventCreated: FC<ITaskEventCreatedProps> = ({ taskCreatedEvent }) => {
  if (!taskCreatedEvent) return null;

  return (
    <>
      <TaskEvent icon="group" label="Assigned to">
        {taskCreatedEvent.reviewers.map((reviewer) => (
          <div key={reviewer.userId}>{reviewer.userName}</div>
        ))}
      </TaskEvent>
      <TaskEvent icon="find_in_page" label="Requested by">
        <div>{taskCreatedEvent.createdBy.userName}</div>
      </TaskEvent>
    </>
  );
};

export default observer(TaskEventCreated);
