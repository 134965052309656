import { ComboBox, FormInput } from '@aurecon-creative-technologies/styleguide';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import Style from '../../../styles/components/settings/projectManagement/ProjectFileMetadata.module.scss';
import { classNames } from '../../../utils/miscUtils';
import { getFieldColumnWStyle, tableHeaderKeyValueFields } from '../../../utils/projectFileMetadataUtils';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import { IFileMetadataValue } from '../../../models/metadataSetting/metadataSettingModels';

interface IAddProjectFileMetadataValueProps {
  value: IFileMetadataValue;
  isEditMode: boolean;
  isNested: boolean;
  fieldValues: IFileMetadataValue[] | undefined;
  onMetadataValueChanged: (fieldValue: IFileMetadataValue) => void;
  onMetadataValueRemoved: (fieldValue: IFileMetadataValue) => void;
}

const maxFieldValueTitleLength = 200;
const maxFieldValueCodeLength = 5;

const AddProjectFileMetadataValue: FC<IAddProjectFileMetadataValueProps> = (props) => {
  const { value, isEditMode, isNested, fieldValues, onMetadataValueChanged, onMetadataValueRemoved } = props;
  const [fieldMetadataValue, setFieldMetadataValue] = useState<IFileMetadataValue>(value);
  const [parentFieldValues, setParentFieldValues] = useState<{ id: string; value: string }[]>([]);

  useEffect(() => {
    if (!fieldValues) return;
    const parentMetadataValues = fieldValues.map((m) => {
      return {
        id: m.tempId,
        value: m.code,
      };
    });
    setParentFieldValues(parentMetadataValues);
  }, [fieldValues]);
  const handleSetMetadataValueTitle = (value: string) => {
    const cloneValue = _.clone(fieldMetadataValue);
    cloneValue.title = value;

    setFieldMetadataValue(cloneValue);
    onMetadataValueChanged(cloneValue);
  };

  const setMetadataChildParentCode = (value: string) => {
    const cloneValue = _.clone(fieldMetadataValue);
    if (!value) cloneValue.errors.parentCode = `* is a required field`;
    cloneValue.parentCode = value;
    setFieldMetadataValue(cloneValue);
    onMetadataValueChanged(cloneValue);
  };
  const handleSetMetadataValueCode = (value: string) => {
    const cloneValue = _.clone(fieldMetadataValue);
    cloneValue.code = value;

    setFieldMetadataValue(cloneValue);
    onMetadataValueChanged(cloneValue);
  };

  const renderMetadataValuesActionButtons = (metadataValue: IFileMetadataValue) => {
    return metadataValue.isUsed ? (
      <span className={Style.tooltipWrapper}>
        <PrimaryIconButton icon="inventory_2" size="medium" />
      </span>
    ) : (
      <span className={Style.tooltipWrapper}>
        <PrimaryIconButton
          icon="delete"
          size="medium"
          onClick={() => onMetadataValueRemoved(fieldMetadataValue)}
          disabled={!isEditMode}
        />
      </span>
    );
  };

  return (
    <div key={fieldMetadataValue.tempId}>
      <div className={Style.rowWrapperType}>
        <div
          style={getFieldColumnWStyle(isEditMode, tableHeaderKeyValueFields.field.label, isNested)}
          className={Style.inputWrapper}>
          <FormInput
            value={fieldMetadataValue.title}
            placeholder="Enter Metadata Value"
            required
            error={fieldMetadataValue.errors.title}
            onChange={(value) => {
              handleSetMetadataValueTitle(value);
            }}
          />
          {!fieldMetadataValue.errors.title && (
            <span className={Style.charactersLeft}>
              {maxFieldValueTitleLength - (fieldMetadataValue.title?.length ?? 0)} characters left
            </span>
          )}
        </div>
        <div
          style={getFieldColumnWStyle(isEditMode, tableHeaderKeyValueFields.type.label, isNested)}
          className={classNames(Style.row, Style.textOverflow)}></div>
        <div
          style={getFieldColumnWStyle(isEditMode, tableHeaderKeyValueFields.abbreviation.label, isNested)}
          className={Style.inputWrapper}>
          <FormInput
            value={fieldMetadataValue.code}
            placeholder="Enter Abbreviation"
            required
            error={fieldMetadataValue.errors.code}
            onChange={(value) => {
              handleSetMetadataValueCode(value);
            }}
          />
          {!fieldMetadataValue.errors.code && (
            <span className={Style.charactersLeft}>
              {maxFieldValueCodeLength - (fieldMetadataValue.code?.length ?? 0)} characters left
            </span>
          )}
        </div>
        {isNested && fieldValues ? (
          <div
            style={getFieldColumnWStyle(isEditMode, tableHeaderKeyValueFields.parentCode.label, isNested)}
            className={Style.inputWrapper}>
            <ComboBox
              placeholder="-"
              showIcon={false}
              options={parentFieldValues}
              selected={fieldMetadataValue.parentCode}
              onSelect={(value) => {
                if (!value) return;
                setMetadataChildParentCode(value.value.toString());
              }}
              onClear={() => setMetadataChildParentCode('')}
            />
            {fieldMetadataValue.errors.parentCode && !fieldMetadataValue.parentCode && (
              <span className={Style.inputError}>{fieldMetadataValue.errors.parentCode}</span>
            )}
          </div>
        ) : (
          <div
            style={getFieldColumnWStyle(isEditMode, tableHeaderKeyValueFields.parentCode.label, isNested)}
            className={classNames(Style.row, Style.textOverflow)}></div>
        )}

        {isEditMode && (
          <div className={classNames(Style.row, Style.actionWrapper, Style.textOverflow, Style.archiveButton)}>
            {renderMetadataValuesActionButtons(fieldMetadataValue)}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AddProjectFileMetadataValue);
