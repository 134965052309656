import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Icon } from '@aurecon-creative-technologies/styleguide';

export interface IAppointingPartyTagProps {
  appointingPartyTitle: string;
  appointingPartyCode: string;
}

const AppointingPartyTag: FC<IAppointingPartyTagProps> = ({ appointingPartyTitle, appointingPartyCode }) => {
  return (
    <span>
      <Icon type="folder" /> {appointingPartyTitle} <b>({appointingPartyCode})</b>
    </span>
  );
};

export default observer(AppointingPartyTag);
