import { serverAxios } from '../api';
import { IArchiveProjectRequest } from './models/projectModel';

export async function archiveOrUnArchiveProject(
  request: IArchiveProjectRequest,
  abortSignal?: AbortSignal
): Promise<void> {
  const result = await serverAxios.put('api/config/projects/archive', request, {
    signal: abortSignal,
  });
  return result.data;
}
