import { ComboBox, DateInput, FormInput } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import GoBackButton from '../shared/GoBackButton';
import Icon from '../shared/Icon';
import PageHeading from '../shared/PageHeading';
import AddTransmittalMessageVisibilitySelector from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageVisibilitySelector';
import AddTransmittalMessageNotifySelector from '../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageNotifySelector';
import Styles from '../transmittals/styles/CreateTransmittal.module.scss';
import CreateTransmittalActions from '../transmittals/CreateTransmittalActions';
import CreateTransmittalStore from '../transmittals/CreateTransmittalStore';
import PageNotFound from '../shared/PageNotFound';
import CreateTransmittalFile from './CreateTransmittalFile';
import CreateTransmittalContentFile from './CreateTransmittalContentFile';
import AppStore from '../../stores/AppStore';
import SupportingFilesUploadModal from './supportingFilesUpload/SupportingFilesUploadModal';
import {
  IAppointingParty,
  IDeliveryTeam,
  IDistributionList,
  ITaskTeam,
} from '../../api/authenticated/um/getProjectMembersAndTeams';
import { IOpenPillProps, SupportFileMode, TransmittalFileType, TransmittalTeamChartType } from './Types';
import TransmittalSupportingFiles from './TransmittalSupportingFiles';
import LayoutStore from '../layout/LayoutStore';
import FilesStore from '../files/FilesStore';
import ErrorModal from '../shared/ErrorModal';
import SecondaryButton from '../shared/SecondaryButton';
import TeamChart from '../TeamChart/TeamChart';
import { ITeamChartResult } from '../TeamChart/interface/TeamChart.interface';
import { TeamChartTitle } from '../../common/constants/TeamChartTitle';
import { appInsightsTrackEvent, getAppInsightsInstance } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { TaskTeamItem } from './navBarSelector/ItemType';
import ContentFilesUploadModal from './ContentFileUpload/ContentFilesUploadModal';
import TransmittalUserListModal from './TransmittalUserListModal';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';
import ConfirmationModal from '../shared/ConfirmationModal';
import SupportingFilesUploadStore from './supportingFilesUpload/SupportingFilesUploadStore';
import { toJS } from 'mobx';
import JoditTextEditor from '../shared/JoditTextEditor';

const defaultOption = 0;
const CreateTransmittal: FC = () => {
  const [showTeamChart, setShowTeamChart] = useState(false);
  const [transmittalTeamChartType, setTransmittalTeamChartType] = useState<TransmittalTeamChartType>('');
  const [openSelectPillModal, setOpenSelectPillModal] = useState(false);
  const [selectPillItem, setSelectPillItem] = useState<IOpenPillProps>();

  useEffect(() => {
    CreateTransmittalStore.init();
    CreateTransmittalStore.getOptionUsers();
    CreateTransmittalStore.getTypes();
    CreateTransmittalStore.getReasons();
    AppStore.setDisableNavigation(false);

    const appInsights = getAppInsightsInstance();
    if (appInsights)
      appInsights.trackPageView({
        name: AppInsightPageView.CREATE_NEW_TRANSMITTAL,
        isLoggedIn: true,
      });

    return () => CreateTransmittalStore.clear();
  }, []);

  const selectItem = NavBarSelectorStore.selectedItem;
  useEffect(() => {
    const taskTeamItem = toJS(NavBarSelectorStore.selectedItem) as TaskTeamItem;
    if (taskTeamItem?.taskTeam && AppStore.selectedTaskTeamId !== taskTeamItem.taskTeam.id) {
      CreateTransmittalStore.setInitiatorTaskTeamId(taskTeamItem.taskTeam.id);
    }
  }, [selectItem]);

  const onCloseUploadFileModal = async (uploadSucceeded: boolean, errorMessage?: string) => {
    CreateTransmittalStore.toggleUploadFileModal(false, uploadSucceeded, errorMessage);
  };
  const handleRemoveContentFile = async (id: number, filetype: TransmittalFileType) => {
    CreateTransmittalStore.removeContentFile(id, filetype);
    return CreateTransmittalStore.contentFiles;
  };

  const handleShowTeamChart = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_TEAM_CHART);
    setShowTeamChart(true);
  };

  const onTeamChartUserSelected = (teamChartData: ITeamChartResult) => {
    if (transmittalTeamChartType === 'Notify') {
      CreateTransmittalStore.setNotifierUsersFromTeamChart(teamChartData);
    } else if (transmittalTeamChartType === 'Visible') {
      CreateTransmittalStore.setVisibilityUsersFromTeamChart(teamChartData);
    }
    setShowTeamChart(false);
    setTransmittalTeamChartType('');
  };

  const onCloseUserListModal = () => {
    setSelectPillItem(undefined);
    setOpenSelectPillModal(false);
  };
  const handleUserListModal = (item: IOpenPillProps) => {
    setSelectPillItem(item);
    setOpenSelectPillModal(true);
  };
  const handleConfirmation = () => {
    if (!CreateTransmittalStore.tempNavBarSelectItem) return;
    NavBarSelectorStore.setSelectedSelectorItem(CreateTransmittalStore.tempNavBarSelectItem);
    CreateTransmittalStore.onNavBarChange();
    SupportingFilesUploadStore.clear();
  };

  if (!CreateTransmittalStore.createTransmittal || AppStore.isTemporaryAccess) return <PageNotFound />;
  if (CreateTransmittalStore.isOpenFiles)
    return (
      <CreateTransmittalFile
        isOpenFiles={CreateTransmittalStore.isOpenFiles}
        setIsOpenFiles={(value) => CreateTransmittalStore.setIsOpenFiles(value)}
        onAddFilesToTransmittal={(selectedFiles) => {
          CreateTransmittalStore.setSelectedContentFiles(selectedFiles);
          CreateTransmittalStore.toggleContentUploadFileModal(true);
          CreateTransmittalStore.setIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
          selectedFiles.length
            ? LayoutStore.displayToast('success', 'Files added to Content Files successfully.')
            : LayoutStore.displayToast(
                'error',
                'Files cannot be added to Content Files at the moment. Please try again later.'
              );
          FilesStore.cleanup();
        }}
        onCancelAddFilesToTransmittal={() => {
          CreateTransmittalStore.setIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
          FilesStore.cleanup();
        }}
      />
    );
  const selectedItem = NavBarSelectorStore.selectedItem as TaskTeamItem;
  return (
    <>
      <GoBackButton />
      <div className={Styles.heading}>
        <PageHeading>New Transmittal</PageHeading>
      </div>
      <CreateTransmittalActions />
      <div className={Styles.panel}>
        <div className={Styles.taskTeamInfo}>
          {selectedItem.project?.projectNumber && selectedItem.project?.title && (
            <>
              {<Icon name="folder" className={Styles.projectIcon}></Icon>}
              <div>
                {`${selectedItem.project.title} | `}
                <strong>{selectedItem.project.projectNumber}</strong>
              </div>
            </>
          )}
          {selectedItem.taskTeam?.title && selectedItem.taskTeam.deliveryTeamTitle && (
            <>
              {<Icon className={Styles.taskTeamIcon} name="people" />}
              <div>
                {`${selectedItem.taskTeam.deliveryTeamTitle} | `}
                <strong>{selectedItem.taskTeam.title}</strong>
              </div>
            </>
          )}
        </div>

        <div className={Styles.row}>
          <div className={Styles.userSelectorLabel}>
            <div className={Styles.labelIconWrapper}>
              <Icon className={Styles.labelIcon} name="notifications" />
              <span>
                Notify <span className={Styles.requiredSymbol}>*</span>
              </span>
            </div>
          </div>
          <div className={Styles.userSelector}>
            <AddTransmittalMessageNotifySelector
              required
              selectedNotifyItems={{
                users: CreateTransmittalStore.createTransmittal.notifyToUsers.map((user) => ({
                  userId: user.userId,
                  userName: user.name,
                  userEmail: user.email,
                  taskTeamId: user.taskTeamId,
                  taskTeamTitle: user.taskTeamTitle,
                  taskTeamCode: user.taskTeamCode,
                  deliveryTeamId: user.deliveryTeamId,
                  deliveryTeamTitle: user.deliveryTeamTitle,
                  isExternal: user.isExternal,
                })),
                distributionList: CreateTransmittalStore.createTransmittal
                  .notifyToDistributionList as IDistributionList[],
                appointingPartyUsers: CreateTransmittalStore.createTransmittal.notifyToAppointingPartyUsers.map(
                  (user) => ({
                    userId: user.userId,
                    appointingPartyId: user.appointingPartyId,
                    userName: user.name,
                    userEmail: user.email,
                  })
                ),
              }}
              getUsers={CreateTransmittalStore.getMatchedNotifiedUsers}
              onSelectedUserUpdated={CreateTransmittalStore.onSelectedNotifiedUsersUpdated}
              openSelectPillModal={handleUserListModal}
              isMultiUser={true}
              disabled={false}
              searchPlaceholder={'Enter user name'}
            />
          </div>
          <div className={Styles.teamChartBtnWrapper}>
            <SecondaryButton
              className={Styles.teamChartBtn}
              onClick={() => {
                handleShowTeamChart();
                setTransmittalTeamChartType('Notify');
              }}>
              Team Chart
            </SecondaryButton>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.userSelectorLabel}>
            <div className={Styles.labelIconWrapper}>
              <Icon className={Styles.labelIcon} name="visibility" />
              <span>Visible to</span>
            </div>
          </div>
          <div className={Styles.userSelector}>
            <AddTransmittalMessageVisibilitySelector
              required
              selectedVisibleTos={{
                users: CreateTransmittalStore.createTransmittal.visibleToTaskTeamUsers.map((user) => ({
                  userId: user.userId,
                  userName: user.name,
                  userEmail: user.email,
                  taskTeamId: user.taskTeamId,
                  taskTeamTitle: user.taskTeamTitle,
                  taskTeamCode: user.taskTeamCode,
                  deliveryTeamId: user.deliveryTeamId,
                  deliveryTeamTitle: user.deliveryTeamTitle,
                  isExternal: user.isExternal,
                })),
                taskTeams: CreateTransmittalStore.createTransmittal.visibleToTaskTeams as ITaskTeam[],
                appointingParties: CreateTransmittalStore.createTransmittal
                  .visibleToAppointingParties as IAppointingParty[],
                deliveryTeams: CreateTransmittalStore.createTransmittal.visibleToDeliveryTeams as IDeliveryTeam[],
                distributionList: CreateTransmittalStore.createTransmittal
                  .visibleToDistributionList as IDistributionList[],
                appointingPartyUsers: CreateTransmittalStore.createTransmittal.visibleToAppointingPartyUsers.map(
                  (user) => ({
                    userId: user.userId,
                    appointingPartyId: user.appointingPartyId,
                    userName: user.name,
                    userEmail: user.email,
                  })
                ),
              }}
              getItems={CreateTransmittalStore.getMatchedVisibilityItems}
              openSelectPillModal={handleUserListModal}
              onSelectedItemUpdated={CreateTransmittalStore.onSelectedVisibilityItemsUpdated}
              isMultiUser={true}
              disabled={false}
              searchPlaceholder={'Enter user name'}
            />
          </div>
          <div className={Styles.teamChartBtnWrapper}>
            <SecondaryButton
              className={Styles.teamChartBtn}
              onClick={() => {
                handleShowTeamChart();
                setTransmittalTeamChartType('Visible');
              }}>
              Team Chart
            </SecondaryButton>
          </div>
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Type <span className={Styles.requiredSymbol}>*</span>
          </div>
          <ComboBox
            placeholder="Select a Transmittal Type"
            showIcon={false}
            selected={
              CreateTransmittalStore.createTransmittal.type === defaultOption
                ? ''
                : CreateTransmittalStore.createTransmittal.type.toString()
            }
            onSelect={(item) => {
              CreateTransmittalStore.handleTypeChange(Number(item?.id));
            }}
            onClear={CreateTransmittalStore.handleTypeChange}
            options={CreateTransmittalStore.typeItems}></ComboBox>
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Reason <span className={Styles.requiredSymbol}>*</span>
          </div>
          <ComboBox
            placeholder="Select a Reason for Issue"
            showIcon={false}
            options={CreateTransmittalStore.reasonItems}
            selected={
              CreateTransmittalStore.createTransmittal.reason === defaultOption
                ? ''
                : CreateTransmittalStore.createTransmittal.reason.toString()
            }
            onSelect={(item) => {
              CreateTransmittalStore.handleReasonChange(Number(item?.id));
            }}
            onClear={CreateTransmittalStore.handleReasonChange}></ComboBox>
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>Due Date</div>
          <DateInput
            dates={{
              startDate: CreateTransmittalStore.createTransmittal.dueDate,
              endDate: null,
            }}
            onDateChange={CreateTransmittalStore.onDueDateChange}
            error={CreateTransmittalStore.dueDateErrorMessage}
            cssClass={Styles.date}
          />
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Subject <span className={Styles.requiredSymbol}>*</span>
          </div>
          <FormInput
            cssClass={Styles.formInput}
            value={CreateTransmittalStore.createTransmittal.subject}
            onChange={CreateTransmittalStore.onSubjectChange}
            error={CreateTransmittalStore.subjectErrorMessage}
          />
        </div>
        <div className={Styles.row}>
          <div className={Styles.label}>
            Message <span className={Styles.requiredSymbol}>*</span>
          </div>
          <JoditTextEditor
            onChanged={CreateTransmittalStore.onMessageChange}
            content={CreateTransmittalStore.createTransmittal.message}></JoditTextEditor>
        </div>
        {!!CreateTransmittalStore.contentFiles.length && (
          <div className={Styles.row}>
            <CreateTransmittalContentFile
              contentFiles={CreateTransmittalStore.contentFiles}
              onRemoveContentFile={(id, filetype) => handleRemoveContentFile(id, filetype)}
            />
          </div>
        )}
        <div className={Styles.row}>
          <TransmittalSupportingFiles
            openPanelIds={CreateTransmittalStore.openPanelIds}
            onPanelToggle={CreateTransmittalStore.panelToggle}
            supportingFiles={CreateTransmittalStore.getUploadSupportingFiles}
          />
        </div>
      </div>

      {CreateTransmittalStore.showContentUploadFileModal && (
        <ContentFilesUploadModal
          isRespond={false}
          selectedContentContainers={CreateTransmittalStore.selectedContentFiles}
          onAddContentFilesToTransmittal={(containerFileIds) => {
            CreateTransmittalStore.handleUploadContentFiles(containerFileIds);
            CreateTransmittalStore.contentFiles.length
              ? LayoutStore.displayToast(
                  'success',
                  `${
                    CreateTransmittalStore.contentFiles.flatMap((m) => m.containerFiles).length
                  } Files added to Transmittal successfully.`
                )
              : LayoutStore.displayToast(
                  'error',
                  `${
                    CreateTransmittalStore.contentFiles.flatMap((m) => m.containerFiles).length
                  } Files cannot be added to Transmittal at the moment. Please try again later.`
                );
            CreateTransmittalStore.toggleContentUploadFileModal(false);
            CreateTransmittalStore.clearTransmittalContentFile();
          }}
          onCancelAddSelectedContentFiles={() => {
            CreateTransmittalStore.toggleContentUploadFileModal(false);
            CreateTransmittalStore.clearTransmittalContentFile();
          }}></ContentFilesUploadModal>
      )}
      {CreateTransmittalStore.showUploadSupportFileModal && (
        <SupportingFilesUploadModal
          mode={SupportFileMode.CreateTransmittal}
          payload={CreateTransmittalStore.getCreateTransmittal}
          projectNumber={CreateTransmittalStore.getNavBarSelectorProjectNumber}
          closeModal={(uploadSucceeded, errorMessage) =>
            onCloseUploadFileModal(uploadSucceeded, errorMessage)
          }></SupportingFilesUploadModal>
      )}
      {CreateTransmittalStore.showUploadSupportFileErrorModal && (
        <ErrorModal
          closeModal={() => CreateTransmittalStore.setShowUploadSupportFileErrorModal(false)}
          errorCode={CreateTransmittalStore.errorCode}
          errorMessage={CreateTransmittalStore.uploadSupportFileErrorMessage}
        />
      )}

      {showTeamChart && CreateTransmittalStore.projectNumber && (
        <TeamChart
          closeModal={() => setShowTeamChart(false)}
          projectNumber={CreateTransmittalStore.projectNumber}
          onSelect={onTeamChartUserSelected}
          headerTitle={TeamChartTitle.transmittalTitle}
          selectedUsers={CreateTransmittalStore.selectedUsersForTeamChart(transmittalTeamChartType)}
        />
      )}
      {openSelectPillModal && selectPillItem && (
        <TransmittalUserListModal
          item={selectPillItem}
          projectMembersAndTeams={CreateTransmittalStore.projectMembersAndTeams}
          closeModal={onCloseUserListModal}
        />
      )}
      <ConfirmationModal
        showModal={CreateTransmittalStore.showNavBarConfirmationModal}
        heading="Transmittal not saved"
        message={
          <>
            Please review that information contained in the Subject and the Message is relevant to the new project.{' '}
            <br />
            Changing to a task team from a different project will erase the following fields:
            <ul>
              <li>Notify</li>
              <li>Visible to</li>
              <li>Type</li>
              <li>Reason</li>
              <li>Content Files/Supporting Files</li>
            </ul>
            <br />
            Your progress will not be saved.Are you sure you want to continue?
          </>
        }
        confirmText="Yes"
        cancelText="No"
        onCancel={() => CreateTransmittalStore.toggleshowNavBarConfirmationModal()}
        onConfirm={() => {
          CreateTransmittalStore.toggleshowNavBarConfirmationModal();
          handleConfirmation();
        }}
      />
    </>
  );
};

export default observer(CreateTransmittal);
