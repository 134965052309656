import { serverAxios } from '../api';

export interface ITransmittalTypesBeingUsed {
  transmittalTypeId: number;
}

export async function getTransmittalTypesBeingUsed(
  projectNumber: string,
  abortSignal?: AbortSignal
): Promise<ITransmittalTypesBeingUsed[]> {
  const result = await serverAxios.get<{
    transmittalTypesBeingUsed: ITransmittalTypesBeingUsed[];
  }>('api/transmittals/gettransmittaltypesbeingused', {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.transmittalTypesBeingUsed;
}
