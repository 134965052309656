import { serverAxios } from '../api';

export interface ITransmittalTypeResult {
  type: IType[];
}

export interface IType {
  id: number;
  title: string;
  namingCode: string;
  order: number;
  archived: boolean;
}

export async function getTransmittalTypes(projectNumber: string, abortSignal?: AbortSignal): Promise<IType[]> {
  const result = await serverAxios.get<{
    transmittalTypes: IType[];
  }>('api/transmittals/gettransmittaltypes', {
    params: {
      projectNumber: projectNumber,
    },
    signal: abortSignal,
  });
  return result.data.transmittalTypes;
}
