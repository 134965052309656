import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import { Links } from '../../common/constants/Links';
import PrimaryButton from '../shared/PrimaryButton';
import SecondaryButton from '../shared/SecondaryButton';

import LayoutStore from '../layout/LayoutStore';

import Style from './styles/SupportHub.module.scss';

const SupportHub: FC = () => {
  const handleButtonClick = (url: string): void => {
    window.open(url, '_blank', 'noopener');
  };
  LayoutStore.showSidebar && LayoutStore.toggleSidebar();
  return (
    <div className={Style.pageContent}>
      <div className={Style.content}>
        <h1>Tucana Support Hub</h1>
        <h2>You don&apos;t have permission to access any projects.</h2>
        <div className={Style.buttons}>
          <PrimaryButton onClick={() => handleButtonClick(Links.requestAccess)}>Request Access</PrimaryButton>
          <SecondaryButton className={Style.textOnly} onClick={() => handleButtonClick(Links.contactPortal)}>
            Other Request
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default observer(SupportHub);
